<template>
  <div style="padding: 10px;margin: 10px">
    <div style="background: rgba(255, 255, 255, 1);padding: 10px">
      <div style="color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 500;">提现数据</div>
      <div style="display: flex;margin-top: 10px">
        <div :index="index"  v-for="(item,index) in statistical" :key="index" class="statisticalItem">
          <div>
            {{item.value}}
          </div>
          <div>
            {{item.label}}
          </div>
        </div>
      </div>
    </div>

   <div class="tab_info">
     <el-tabs v-model="queryParams.auditStatus" @tab-click="handleClick">
       <el-tab-pane label="待审核"   name="1" value="1"></el-tab-pane>
       <el-tab-pane label="打款中" name="5" value="5"></el-tab-pane>
       <el-tab-pane label="打款失败" name="2" value="2"></el-tab-pane>
       <el-tab-pane label="打款成功" name="3" value="3"></el-tab-pane>
       <el-tab-pane label="审核驳回" name="4" value="4"></el-tab-pane>
     </el-tabs>

    <div v-if="queryParams.auditStatus==1 || queryParams.auditStatus==5 || queryParams.auditStatus==2">
      <el-button type="primary" size="small" @click="batchWeChatPass">批量微信打款</el-button>
      <el-button type="success" size="small" @click="batchOfflinePass">批量线下打款</el-button>
      <el-button type="danger" size="small" @click="batchRejected">批量驳回</el-button>
    </div>

     <el-table
         :data="tableData"
         :loading="listLoading"
         border
         row-key="id"
         ref="multipleTable"
         @selection-change="handleSelectionChange"
         @select="selectOne"
         @select-all="selectAll"
         style="margin-top: 10px;"
         :max-height="autoHeight"
     >
       <el-table-column
           :selectable="handleDisable"
           type="selection"
           width="55">
       </el-table-column>
       <el-table-column align="center" prop="id" label="提现单号" >
       </el-table-column>
       <el-table-column align="center" label="用户" >
         <template slot-scope="scope">
           <div v-if="scope.row.avatar_url !== ''" style="display: flex;justify-content: space-around">
             <el-image
                 style="width: 50px;height: 50px"
                 :src="scope.row.avatar_url"
                 fit="cover">
             </el-image>
              <div>
                <div>{{scope.row.nick_name}}</div>
                <div>{{scope.row.phone_number}}</div>
              </div>
           </div>
           <div v-else>
             尚未授权
           </div>
         </template>
       </el-table-column>
       <el-table-column align="center" prop="user_name" label="真实姓名" >
       </el-table-column>
       <el-table-column align="center" prop="amount" label="提现金额(元)" >
       </el-table-column>
       <el-table-column align="center" prop="service_charge" label="服务费(元)" >
       </el-table-column>
       <el-table-column align="center" prop="arrival_amount" label="实际到账(元)" >
       </el-table-column>
       <el-table-column align="center" prop="create_time" label="发起提现时间" >
       </el-table-column>

       <el-table-column v-if="queryParams.auditStatus!=1" align="center" prop="audit_time" label="审核时间" >
       </el-table-column>
       <el-table-column v-if="queryParams.auditStatus==2" align="center" prop="reason_failure" label="打款失败原因" >
       </el-table-column>
       <el-table-column v-if="queryParams.auditStatus==3" align="center" prop="accounting_date" label="打款时间" >
       </el-table-column>
       <el-table-column v-if="queryParams.auditStatus==3" align="center" prop="money_type" label="打款类型" >
         <template slot-scope="scope">
           <div v-if="scope.row.money_type == 1" >
             微信打款
           </div>
           <div v-else>
             线下打款
           </div>
         </template>
       </el-table-column>
       <el-table-column v-if="queryParams.auditStatus==3" align="center" prop="batch_id" label="业务单号" >
       </el-table-column>
       <el-table-column v-if="queryParams.auditStatus==4" align="center" prop="reason_rejection" label="驳回理由" >
       </el-table-column>
       <el-table-column v-if="queryParams.auditStatus==5" align="center" prop="reason_rejection" label="打款进度" >
         <template slot-scope="scope">
           <div v-if="scope.row.batch_status== 'CLOSED' " >
             {{scope.row.reason_failure}}
           </div>
           <div v-if="scope.row.batch_status== 'WAIT_PAY' ">
             待付款，商户员工确认付款阶段
           </div>
         </template>
       </el-table-column>

       <el-table-column align="center" label="操作" v-if="queryParams.auditStatus==1 || queryParams.auditStatus==2 || queryParams.auditStatus==5">
         <template slot-scope="scope">
           <div v-if="handleDisable(scope.row)">
             <el-button
                 @click.native.prevent="weChatPass(scope.row)"
                 type="text"
                 size="small">
               微信打款
             </el-button>
             <el-button
                 @click.native.prevent="offlinePass(scope.row)"
                 type="text"
                 size="small">
               线下打款
             </el-button>
             <el-button
                 @click.native.prevent="rejected(scope.row)"
                 type="text"
                 size="small">
               审核驳回
             </el-button>
           </div>
         </template>
       </el-table-column>

     </el-table>

     <el-row style="margin-top: 24px;margin-right: 20px">
       <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
       </el-col>
     </el-row>

   </div>

    <el-dialog
        title="提示"
        :visible.sync="weChatPassVisible"
        width="30%"
        >
      <div style="display: flex;justify-content: center">
        <div><i class="el-icon-warning" style="font-size: 40px;color: gold"></i></div>
        <div style="margin-left: 10px; color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 400;">
          <div>是否执行微信打款？</div>
          <div>点击“确定”，发起对该用户的打款操作！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="weChatPassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doWeChatPass">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示-批量微信打款"
        :visible.sync="batchWeChatPassVisible"
        width="30%"
    >
      <div style="display: flex;justify-content: center">
        <div><i class="el-icon-warning" style="font-size: 40px;color: gold"></i></div>
        <div style="margin-left: 10px; color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 400;">
          <div>是否<span style="color: green">对{{selectionIds.length}}条数据</span>执行批量微信打款？</div>
          <div>点击“确定”，发起对选中用户的打款操作！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchWeChatPassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchWeChatPass">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog
        title="提示"
        :visible.sync="offlinePassVisible"
        width="30%"
    >
      <div style="display: flex;justify-content: center">
        <div><i class="el-icon-warning" style="font-size: 40px;color: gold"></i></div>
        <div style="margin-left: 10px; color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 400;">
          <div>请确认是否已执行线下打款？</div>
          <div>点击“确定”，提现申请将变更为打款成功！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offlinePassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doOfflinePass">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示-批量线下打款"
        :visible.sync="batchOfflinePassVisible"
        width="30%"
    >
      <div style="display: flex;justify-content: center">
        <div><i class="el-icon-warning" style="font-size: 40px;color: gold"></i></div>
        <div style="margin-left: 10px; color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 400;">
          <div>请确认<span style="color: green">{{selectionIds.length}}条数据</span>是否已批量执行线下打款？</div>
          <div>点击“确定”，提现申请将变更为打款成功！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchOfflinePassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchOfflinePass">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示"
        :visible.sync="rejectedVisible"
        width="30%"
    >
      <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入驳回原因"
          v-model="reasonRejection">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectedVisible = false">取 消</el-button>
        <el-button type="primary" @click="doRejected">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示-批量驳回"
        :visible.sync="batchRejectedVisible"
        width="30%"
    >
      <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入驳回原因"
          v-model="reasonRejection">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchRejectedVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchRejected">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { statistical ,withdrawalList ,batchWeChatPass ,batchOfflinePass ,batchRejected} from "../../api/financial_center";
export default {
  name: "withdrawal",
  data(){
    return {
      obj:{},
      weChatPassVisible:false,
      batchWeChatPassVisible:false,
      offlinePassVisible:false,
      batchOfflinePassVisible:false,
      rejectedVisible:false,
      batchRejectedVisible:false,
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        auditStatus: "1",
        page: 1,
        limit: 5,
        sidx: 'swr.create_time',
        order: 'desc'
      },
      total:0,
      listLoading:false,
      tableData:[],
      statistical:[],
      reasonRejection:'',
      selectionIds:[],
      multipleSelection:[]
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted() {
    this.initStatistical();
    this.initData()
  },
  methods: {
    rejected(row){
      this.obj = row
      this.reasonRejection = ""
      this.rejectedVisible = true
    },
    batchRejected(){
      console.log(JSON.stringify(this.selectionIds))
      if (this.selectionIds.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.reasonRejection = ""
      this.batchRejectedVisible = true
    },
    doRejected(){
      if (this.reasonRejection == "") {
        this.$message.error("请输入正确的驳回原因!");
        return;
      }
      let body = {
        ids : [this.obj.id],
        reasonRejection: this.reasonRejection
      }
      batchRejected(body).then((res) => {
        if(res.code==0) {
          this.initStatistical();
          this.initData()
          this.rejectedVisible = false
          this.$message.success({message: "操作成功"});
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    doBatchRejected(){
      console.log(JSON.stringify(this.selectionIds))
      if (this.reasonRejection == "") {
        this.$message.error("请输入正确的驳回原因!");
        return;
      }
      console.log(JSON.stringify(this.selectionIds))
      let body = {
        ids : this.selectionIds,
        reasonRejection: this.reasonRejection
      }
      batchRejected(body).then((res) => {
        if(res.code==0) {
          this.selectionIds = []
          this.initStatistical();
          this.initData()
          this.batchRejectedVisible = false
          this.$message.success({message: "操作成功"});
        }else{
          this.$message.error({message: res.message});
        }
      });
    },

    offlinePass(row){
      this.obj = row
      this.offlinePassVisible = true
    },
    batchOfflinePass(){
      console.log(JSON.stringify(this.selectionIds))
      if (this.selectionIds.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchOfflinePassVisible = true
    },
    doOfflinePass(){
      let body = {
        ids : [this.obj.id]
      }
      batchOfflinePass(body).then((res) => {
        if(res.code==0) {
          this.initStatistical();
          this.initData()
          this.offlinePassVisible = false
          this.$message.success({message: "操作成功"});
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    doBatchOfflinePass(){
      console.log(JSON.stringify(this.selectionIds))
      let body = {
        ids : this.selectionIds
      }
      batchOfflinePass(body).then((res) => {
        if(res.code==0) {
          this.selectionIds = []
          this.initStatistical();
          this.initData()
          this.batchOfflinePassVisible = false
          this.$message.success({message: "操作成功"});
        }else{
          this.$message.error({message: res.message});
        }
      });
    },

    weChatPass(row){
      this.obj = row
      this.weChatPassVisible = true
    },
    batchWeChatPass(){
      console.log(JSON.stringify(this.selectionIds))
      if (this.selectionIds.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchWeChatPassVisible = true
    },
    doWeChatPass(){
      let body = {
        ids : [this.obj.id]
      }
      batchWeChatPass(body).then((res) => {
        if(res.code==0) {
          this.initStatistical();
          this.initData()
          this.weChatPassVisible = false
          this.$message.success({message: "操作成功"});
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    doBatchWeChatPass(){
      console.log(JSON.stringify(this.selectionIds))
      let body = {
        ids : this.selectionIds
      }
      batchWeChatPass(body).then((res) => {
        if(res.code==0) {
          this.selectionIds = []
          this.initStatistical();
          this.initData()
          this.batchWeChatPassVisible = false
          this.$message.success({message: "操作成功"});
        }else{
          this.$message.error({message: res.message});
        }
      });
    },

    cellClass(){
      if (this.queryParams.auditStatus != "1") {
        return 'DisableSelection'
      }
    },
    handleDisable(row){
      console.log(JSON.stringify(row))
      if (this.queryParams.auditStatus == "1" || this.queryParams.auditStatus == "2") {
        return true
      }else if(this.queryParams.auditStatus == "5"){
        if(row.batch_status == "CLOSED"){
          return true
        }else{
          return false
        }
      }else{
        return false
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    remove(selectionIds,val) {
      let index = selectionIds.indexOf(val);
      if (index > -1) {
        selectionIds.splice(index, 1);
      }
      console.log("remove:",val)
      console.log("selectionIds:",this.selectionIds)
    },
    selectOne(selection, row) {
      let check = null;
      selection.map((item) => {
        if(item.id === row.id){
          check = true;
        }
      })
      if(check){
        console.log("选中:",row.id)
        this.selectionIds.push(row.id)
      }else{
        console.log("取消选中:",row.id)
        this.remove(this.selectionIds,row.id)
      }
    },
    selectAll(selection) {
      let _this = this;
      console.log(selection)
      if (selection.length == 0){
        console.log("取消选择")
        _this.tableData.map((item) => {
          _this.remove(_this.selectionIds,item.id)
        })
      }else {
        console.log("全部选择")
        _this.tableData.forEach((item) => {
          if(this.handleDisable(item)==true){
            let index = _this.selectionIds.indexOf(item.id);
            if (index > -1) {
              console.log("已经含有啦")
            }else{
              _this.selectionIds.push(item.id)
            }
          }
        });
      }
    },
    selectCheck(){
      let _this = this;
      _this.$nextTick(()=>{
        console.log("_this.$refs",_this.$refs)
        _this.tableData.map((item) => {
          _this.selectionIds.find(value => {
            if(value == item.id){
              _this.$refs.multipleTable.toggleRowSelection(item, true)
            }
          })
        });
      })
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) -390) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      console.log(JSON.stringify(this.selectionIds))
      this.queryParams.page = val;
      this.initData();
    },
      initData(){
        withdrawalList(this.queryParams).then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total_count
          this.selectCheck()
        });
      },
      handleClick(tab, event) {
        console.log(tab, event);
        this.selectionIds = []
        this.queryParams.page = 1
        this.total = 0
        this.tableData= []
        this.initData()
      },
      initStatistical(){
        statistical({}).then((res) => {
          if(res.data != null){
            this.statistical = res.data;
          }
        });
      }
  }
  }
</script>

<style scoped>
 .statisticalItem{
   padding: 20px;
   margin-right: 10px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   background: rgba(245, 245, 245, 1);border-radius: 6px;
}
 .tab_info{
   margin-top: 10px;
   background: rgba(255, 255, 255, 1);
   padding: 10px;
 }

</style>
