import service from "../request";

export function postersList(query) {
    return service({
        url: '/api/merchants/posters/list',
        method: 'get',
        params: query
    })
}

export function saveOrUpdate(data) {
    return service({
        url: '/api/merchants/posters/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function postersDel(query) {
    return service({
        url: '/api/merchants/posters/del',
        method: 'get',
        params: query
    })
}
