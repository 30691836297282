import CourseLayout from "../views/layout/CourseLayout";
import course_situation from "../views/course/overview";
import video_guest from "../views/course/video_guest";
import video_guest_info from "../views/course/video_guest_info";
import course_class_hour from "../views/course/course_class_hour";
import teacher from "../views/course/teacher";
import order_list from "../views/course/order_list.vue";
import refund_order from "../views/course/refund_order.vue";
import student_data from "../views/course/student_data.vue";
import course_data from "../views/course/course_data.vue";

const template = [
    {
        path:"/course_situation",
        name:'概况',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: CourseLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/course_situation",
                name:'概况',
                component: course_situation,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            }
        ]
    },
    {
        path:"/",
        name:'课程管理',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: CourseLayout,
        isMenu:true,
        children:[
            {
                path:"/video_guest",
                name:'视频课',
                component: video_guest,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            },
            {
                path:"/video_guest_info",
                isHidden : true,
                name:'视频课',
                component: video_guest_info,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            },
            {
                path:"/course_class_hour",
                isHidden : true,
                name:'课时管理',
                component: course_class_hour,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            },
            {
                path:"/teacher",
                name:'授课老师',
                component: teacher,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            }
        ]
    },
    {
        path:"/",
        name:'订单管理',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: CourseLayout,
        isMenu:true,
        children:[
            {
                path:"/order_list",
                name:'订单列表',
                component: order_list,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            },
            {
                path:"/refund_order",
                name:'退款订单管理',
                component: refund_order,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            }
        ]
    },
    {
        path:"/",
        name:'学习数据',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: CourseLayout,
        isMenu:true,
        children:[
            {
                path:"/course_data",
                name:'课程数据',
                component: course_data,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            },
            {
                path:"/student_data",
                name:'学生数据',
                component: student_data,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'course'
                    }
                }
            }
        ]
    },
]

export default template;
