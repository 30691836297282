import service from "../request";

export function updateWechat (data) {
    return service({
        url: '/api/merchants/sklmUser/updateWechat',
        method: 'post',
        data: data
    })
}

export function updateWxCustomer (data) {
    return service({
        url: '/api/merchants/sklmUser/updateWxCustomer',
        method: 'post',
        data: data
    })
}
// 设置备注
export function updateRemarkCustomer (data) {
    return service({
        url: '/api/merchants/sklmUser/updateRemark',
        method: 'post',
        data: data
    })
}

export function ratioAgentUpdate (data) {
    return service({
        url: '/api/merchants/sklmUser/agentRatioUpdate',
        method: 'post',
        data: data
    })
}

export function ratioAgentInfo (query) {
    return service({
        url: '/api/merchants/sklmUser/agentRatio',
        method: 'get',
        params: query
    })
}


export function commission (data) {
    return service({
        url: '/api/merchants/sklmUser/commission',
        method: 'post',
        data: data
    })
}

export function exchangeCodeCancel (data) {
    return service({
        url: '/api/merchants/exchangeCode/cancel',
        method: 'post',
        data: data
    })
}

export function exchangeCodeAdd (data) {
    return service({
        url: '/api/merchants/exchangeCode/add',
        method: 'post',
        data: data
    })
}

export function exchangeCodeList (query) {
    return service({
        url: '/api/merchants/exchangeCode/list',
        method: 'get',
        params: query
    })
}

export function sklmBalanceResList (query) {
    return service({
        url: '/api/merchants/sklmBalanceRes/list',
        method: 'get',
        params: query
    })
}

export function generateLimitGet (query) {
    return service({
        url: '/api/merchants/generateLimit/get',
        method: 'get',
        params: query
    })
}

export function generateLimitSaveOrUpdate (data) {
    return service({
        url: '/api/merchants/generateLimit/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function memberInfo (query) {
    return service({
        url: '/api/merchants/sklmMembersLevel/info',
        method: 'get',
        params: query
    })
}

export function memberInfoAdd (data) {
    return service({
        url: '/api/merchants/sklmMembersLevel/info',
        method: 'post',
        data: data
    })
}

export function sklmUserList (query) {
    return service({
        url: '/api/merchants/sklmUser/list',
        method: 'get',
        params: query
    })
}

export function wechatPayResList (query) {
    return service({
        url: '/api/merchants/wechatPayRes/list',
        method: 'get',
        params: query
    })
}

export function sklmMembersLevelList (query) {
    return service({
        url: '/api/merchants/sklmMembersLevel/list',
        method: 'get',
        params: query
    })
}

export function sklmMembersLevelSaveOrUpdate (query, data) {
    return service({
        url: '/api/merchants/sklmMembersLevel/saveOrUpdate',
        method: 'post',
        params: query,
        data: data
    })
}

export function sklmMembersLevelDel (query) {
    return service({
        url: '/api/merchants/sklmMembersLevel/del',
        method: 'get',
        params: query
    })
}


export function sklmMembersAuditList (query) {
    return service({
        url: '/api/merchants/sklmMembersAudit/list',
        method: 'get',
        params: query
    })
}

export function pass (query, data) {
    return service({
        url: '/api/merchants/sklmMembersAudit/pass',
        method: 'post',
        params: query,
        data: data
    })
}

export function reject (query, data) {
    return service({
        url: '/api/merchants/sklmMembersAudit/reject',
        method: 'post',
        params: query,
        data: data
    })
}

export function userTimeCard (query, data) {
    return service({
        url: '/api/merchants/sklmUser/userTimeCard',
        method: 'post',
        params: query,
        data: data
    })
}

export function balanceUpdate (data) {
    return service({
        url: '/api/merchants/sklmUser/balanceUpdate',
        method: 'post',
        data: data
    })
}

export function userTimeCardResd (query) {
    return service({
        url: '/api/merchants/sklmUser/userTimeCardResd',
        method: 'get',
        params: query
    })
}

export function sklmUserUpdate (query, data) {
    return service({
        url: '/api/merchants/sklmUser/update',
        method: 'post',
        params: query,
        data: data
    })
}

export function updateAgent (query, data) {
    return service({
        url: '/api/merchants/sklmUser/updateAgent',
        method: 'post',
        params: query,
        data: data
    })
}

export function setBroker (query, data) {
    return service({
        url: '/api/merchants/sklmUser/setBroker',
        method: 'post',
        params: query,
        data: data
    })
}

export function updateChannel (query, data) {
    return service({
        url: '/api/merchants/sklmUser/updateChannel',
        method: 'post',
        params: query,
        data: data
    })
}

export function agentRulesInfo (query) {
    return service({
        url: '/api/merchants/agentRules/info',
        method: 'get',
        params: query
    })
}

export function agentRulesUpdate (data) {
    return service({
        url: '/api/merchants/agentRules/update',
        method: 'post',
        data: data
    })
}

// 随心推余额 记录
export function merchantsResList (query) {
    return service({
        url: '/api/merchants/memberRes/resList',
        method: 'get',
        params: query
    })
}

// 保存
export function merchantsResSave (query, data) {
    return service({
        url: '/api/merchants/memberRes/resSave',
        method: 'post',
        params: query,
        data: data
    })
}


// 获取抖客分成比例
export function dkRatioinfo (query) {
    return service({
        url: '/api/merchants/dkRatio/info',
        method: 'get',
        params: query
    })
}


// 更新抖客比例
export function dkRatioUpdate (data) {
    return service({
        url: '/api/merchants/dkRatio/update',
        method: 'post',
        data: data
    })
}


export function agentbalanceUpdate (data) {
    return service({
        url: '/api/merchants/sklmUser/agent/balanceUpdate',
        method: 'post',
        data: data
    })
}

