<template>
  <div class="main_container">
    <div class="main_info">
      <div class="main_info_left">
        <div class="info_item" style="padding: 10px">
          <div class="item_name">我的权益</div>
          <div style="display: flex;justify-content: space-around">
            <div class="left_item_view" style="display: flex;align-items: center">
              <img
                  style="width: 80px;height: 80px;"
                  src="../../assets/homeInfoCustom/cloud_storage.png"
                  fit="cover"/>
              <div style="margin-left: 10px;width: 100%">
                <div>
                  <span style="font-size: 18px">云空间</span>
                  <span @click="my_cloud_info" style="font-size: 12px;font-weight: 500;color: rgba(0, 119, 255, 1);float: right;cursor: pointer;">去扩容</span>
                </div>
                <el-progress :percentage="cloudInfo.percentage"></el-progress>
                <div>{{cloudInfo.useCloudSize | formatFileSize }}/{{ cloudInfo.cloudSize | formatFileSize }}</div>
              </div>
            </div>
            <div class="left_item_view" style="display: flex;align-items: center">
              <img
                  style="width: 50px;height: 50px;margin: 10px;"
                  src="../../assets/homeInfoCustom/membershipcard.png"
                  fit="cover"/>
              <div style="margin-left: 10px;width: 100%">
                <div>
                  <span style="font-size: 18px">视频生成卡</span>
                  <span @click="cardResdCustom" style="font-size: 12px;font-weight: 500;color: rgba(0, 119, 255, 1);float: right;cursor: pointer;">去充值</span>
                </div>
                <div style="font-size: 30px;font-weight: 500;">{{userInfo.gen_card_num}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="home_container">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">达人矩阵</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in matrixList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
        </div>

        <div class="home_container" style="background: rgba(255, 255, 255, 1);">
            <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">云快剪小程序</span>
            <div class="flex_container">
              <div class="flex_chid" v-for="(item,index) in quickShearList" :key="index">
                <span class="flex_lable">{{item.label}}</span>
                <span class="flex_value">{{item.value}}</span>
              </div>
            </div>
        </div>
      </div>
      <div class="main_info_right">
        <div class="info_item" style="display: flex;flex-direction: column;justify-content: space-around">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div class="item_name">系统到期时间</div>
            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 500;">{{clipGeneral.expire_time}}</div>
            <div  style="font-size: 14px;font-weight: 400;display: flex;align-items: center" >
              <span @click="renewalShow" style="color: rgba(0, 119, 255, 1);font-size: 14px;margin-left: 10px;cursor: pointer">立即续期</span>
              <img src="../../assets/home/right.png" style="width: 20px;height: 20px" />
            </div>
          </div>
        </div>

        <div class="info_item" style="margin-top: 10px">
            <div class="item_name">云快剪小程序</div>
            <div class="invitation">
              <img :src="creatorUserOld.qr_code" class="qr_code" />
              <div class="share_tips">
                扫码进入「云快剪」官方小程序
              </div>
              <div class="share_tips">
                加入团队，即可在小程序生成视频进行测试
              </div>
              <div class="invitation_div">
                团队邀请码：<span class="invitation_code">{{creatorUserOld.invitation_code}}</span>
              </div>
            </div>
        </div>

        <div class="info_item" style="margin-top: 10px">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div class="item_name">教程与帮助</div>
          </div>
          <div class="right_item_view" @click="ai_document">
            <div>Ai云剪辑系统使用文档</div>
            <div style="margin-top: 10px; font-size: 12px;font-weight: 400;color: rgba(166, 166, 166, 1);">15分钟学会制作视频模版，日产1000条短视频，涉及系统功能使用和剪辑案例教学</div>
          </div>
        </div>


      </div>
    </div>
    <ClipGeneralSituation ref="renewal"></ClipGeneralSituation>
  </div>
</template>

<script>
import {merchantsInfo, creatorUser, cloudInfo ,enterpriseCertInfo} from "../../api/merchantsUser";
import { homeClipInfo ,homeExpertInfo ,homeProfileData } from "../../api/statistics";
import { matrix ,quickShear ,clipGeneralInfo } from "../../api/clip_general_situation";
import store from "../../store";
import ClipGeneralSituation from "../../components/clip_general_situation";

export default {
  name: "clip_general_situation",
  components: {
    ClipGeneralSituation
  },
  filters:{
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else if(fileSize < (1024 * 1024 * 1024 * 1024)){
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'TB';
      }
    },
    phoneDesensitization(value){
      if(value==null){
        value = ''
      }
      let char = '*'
      return value.replace(/(\d{3})\d*(\d{4})/, `$1${new Array(5).join(char)}$2`)
    },
    numFilter (value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    },
    numFilterOne (value) {
      let realVal = parseFloat(value).toFixed(1)
      return realVal
    },
    numFilterZeo (value) {
      let realVal = parseFloat(value).toFixed(0)
      return Number(realVal)
    }
  },
  data(){
    return{
      matrixList:[],
      quickShearList:[],
      enterpriseCertInfo:{
        certification_status:null,
        company_name:''
      },
      surveyList:[],
      queryDate: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      clipList:[],
      expertList:[],
      profileList:[],
      tab_type:'1',
      userInfo:{},
      memberInfo: null,
      creatorUser:{},
      creatorUserOld:{},
      invitationCode:'',
      percentage:0,
      cloudInfo:{
        videoCloudSize: 0,
        materialCloudSize: 0,
        percentage: 0,
        useCloudSize: 0,
        cloudSize: 0
      },
      clipGeneral:{
        is_expire:false,
        expire_time: ''
      }
    }
  },
  created(){
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.queryDate= [
      start,end
    ]
  },
  mounted(){
    this.doMatrix()
    this.doQuickShear()
    this.doClipGeneralInfo()
    this.initCloudInfo()
    this.initData()
    this.homeClipInfo()
    this.homeExpertInfo()
    this.homeProfileData()
    this.doEnterpriseCertInfo()
  },
  methods:{
    renewalShow(){
      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data))
        let certification_status = res.data.certification_status
        if(certification_status ===2 || certification_status ===3){
          this.$refs.renewal.show(2)
        }else{
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/authentication_protocol");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    doClipGeneralInfo(){
      clipGeneralInfo().then((res) => {
        this.clipGeneral = res.data;
      });
    },
    doMatrix(){
      matrix().then((res) => {
        this.matrixList = res.data.list;
      });
    },
    doQuickShear(){
      quickShear().then((res) => {
        this.quickShearList = res.data.list;
      });
    },
    checkPermissions(id){
      let state = false
      if (store.state.ACCOUNT_CENTER_MENU != null){
        let children = store.state.ACCOUNT_CENTER_MENU.children
        children.forEach(function (item, index) {
          if(item.id === id){
            state = true
          }
        });
      }
      return  state;
    },
    merchantsInfoCustom(){
      this.$router.push("/merchantsInfoCustom");
    },
    authenticationProtocol(){
      this.$router.push("/authentication_protocol");
    },
    certificationDetails(){
      this.$router.push("/certification_details");
    },
    recertification(){
      this.$router.push("/upload_information");
    },
    doEnterpriseCertInfo(){
      enterpriseCertInfo({}).then((res) => {
        this.enterpriseCertInfo = res.data;
      });
    },
    datePickerChange(val) {
      console.log("datePickerChange")
      console.log(JSON.stringify(val))
      this.queryDate = val
      this.homeProfileData()
    },
    homeClipInfo(){
      homeClipInfo().then((res) => {
        this.clipList = res.data.list;
      });
    },
    homeExpertInfo(){
      homeExpertInfo().then((res) => {
        this.expertList = res.data.list;
      });
    },
    homeProfileData(){
      homeProfileData({
        queryDate: this.queryDate
      }).then((res) => {
        this.profileList = res.data.resDtoList;
        let legendData = res.data.legendData
        let xAxisData = res.data.xAxisData
        let seriesInfoDtoList = res.data.seriesInfoDtoList
        this.drawLine(legendData,xAxisData,seriesInfoDtoList);
      });
    },
    rulesCenter(){
      this.$router.push("/rules_center_user_agreement");
    },
    ai_document(){
      window.open('https://f0gc6xt9dy.feishu.cn/docx/doxcnJua3gf8N6HkKH5uuO4HaPc?from=from_copylink', '_blank');
    },
    jz_document(){
      window.open('https://f0gc6xt9dy.feishu.cn/docx/doxcnMvuCsKmD7hEHSD6tDBEcag?from=from_copylink', '_blank');
    },
    my_cloud_info(){
      this.$router.push("/my_cloud_info");
    },
    initCloudInfo(){
      cloudInfo().then((res)=>{
        this.cloudInfo = res.data
      })
    },
    cardResdCustom(){
      this.$router.push("/cardResdCustom");
    },
    initData(){
      merchantsInfo({}).then((res) => {
        this.creatorUserOld = res.data.creatorUser;
        this.userInfo = res.data.merchantsInfo;
        this.memberInfo = res.data.memberInfo;
      });
      creatorUser().then((res)=>{
        this.creatorUser = res.data
        this.invitationCode = 'https://vodmapi.paitk.com/api/creator/creatorUser/invitationCode?id=' + res.data.creatorUser.id + "&times=" + new Date().getTime()
        this.percentage = res.data.storageSpaceInfo.gFileSize/ res.data.storageSpaceInfo.storageSpace  * 100
      })
    },
    changeTabType(tab, event){
      console.log(tab, event);
    },
    drawLine(legendData,xAxisData,seriesInfoDtoList){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        title: { text: '' },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: seriesInfoDtoList
      });
    }
  }
}
</script>

<style scoped>


.main_container{
  height: 100%;
  padding: 10px;
}

.main_info{
  display: flex;
}

.main_info_left{
  width: 60%;
}

.main_info_right{
  width: 40%;
  margin-left: 10px;
}

.info_item{
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}


.item_name{
  font-size: 20px;
  font-weight: 500;
}

.left_item_view{
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  margin: 5px;
  padding: 10px;
}

.right_item_view{
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  padding: 10px;
  margin-top: 10px;
}


.home_container {
  background: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.flex_container {
  display: flex;
  flex-wrap: wrap;
}

.flex_chid {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.flex_lable {
  color: rgba(128, 128, 128, 1);
  font-size: 14px;
}

.flex_value {
  color: rgba(56, 56, 56, 1);
  font-size: 36px;
  font-weight: bold;
}



.invitation{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}

.qr_code{
  width: 100px;
  height: 100px;
}

.share_tips{
  margin-top: 5px;
  font-size: 10px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
}

.invitation_div{
  margin-top: 10px;
}

.invitation_code{
  background: rgba(0, 119, 255, 1);
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  border-radius: 5px;
}

</style>
