import service from '../request'

export function wechatPayList(query) {
    return service({
        url: '/api/merchants/wechatPay/list',
        method: 'get',
        params: query
    })
}

export function wechatPaySaveOrUpdate(data) {
    return service({
        url: '/api/merchants/wechatPay/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function del(data) {
    return service({
        url: '/api/merchants/wechatPay/del',
        method: 'post',
        data: data
    })
}
