<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <el-button type="primary" size="small" @click="handleAdd">新建标签</el-button>

    <el-table
        size="small"
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="label_name"
          label="标签名称"
          align="center">
      </el-table-column>
      <el-table-column
          prop="generated_num"
          label="视频生成卡配额"
          align="center">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="创建时间"
          align="center">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="remark"-->
<!--          label="当前标签人数"-->
<!--          align="center">-->
<!--      </el-table-column>-->
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
      >
        <template slot-scope="scope">
          <div>
            <el-button  @click="handleUpdate(scope.row)" type="text" size="small"
            >编辑</el-button>
            <el-button  @click="handleDel(scope.row)" v-show="scope.row.people_num==0" type="text" size="small"
            >删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog :title="title" :visible.sync="open" width="30%" size="small">
      <el-form
          size="small"
          ref="form"
          :model="form"
          label-width="160px">
        <el-form-item label="标签名称:" prop="name">
          <el-input v-model="form.label_name" placeholder="请输入标签名称" clearable/>
        </el-form-item>
        <el-form-item label="视频生成卡配额:" prop="name">
          <el-input v-model="form.generated_num" type="number" placeholder="请输入视频生成卡数量" clearable/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" size="small">确 认</el-button>
        <el-button @click="cancel" size="small">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {wxUserLabelList ,wxUserLabelAdd ,wxUserLabelUpdate ,wxUserLabelDel} from "../../api/userManage";

export default {
  name: "clip_user_label",
  data(){
    return{
      open: false,
      title: "",
      form: {},
      queryParams:{
        page: 1,
        limit: 10
      },
      total:0,
      tableData: [],
    }
  },
  created() {
    this.initData();
  },
  methods:{
    handleDel(val) {
      this.$confirm("确认删除该标签?", "删除标签", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            wxUserLabelDel({ id: val.id }).then((res) => {
              if (res.code == "0") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.initData();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    handleUpdate(row) {
      this.open = true;
      this.title = "编辑标签";
      this.form = row;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    submitForm(){
      console.log(JSON.stringify(this.form));
      if (this.form.id) {
        wxUserLabelUpdate(this.form).then((res) => {
          if (res.code == "0") {
            this.open = false;
            this.form = {}
            this.$message.success("编辑成功");
            this.initData();
          }
        });
      } else {
        wxUserLabelAdd(this.form).then((res) => {
          if (res.code == "0") {
            this.open = false;
            this.form = {}
            this.$message.success("添加成功");
            this.initData();
          }
        });
      }
    },
    cancel() {
      this.open = false;
      this.form = {}
    },
    handleAdd() {
      this.open = true;
      this.title = "新建标签";
      this.form = {
        generated_num: ''
      };
    },
    initData(){
      wxUserLabelList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
      });
    }
  }
}
</script>

<style scoped>

</style>
