import service from "../request";

export function iceGet(query) {
    return service({
        url: '/ice/open/openApi',
        method: 'get',
        params: query
    })
}

export function icePost(data) {
    return service({
        url: '/ice/open/openApiPost',
        method: 'post',
        data: data
    })
}

export function updateTemplateRequest(data) {
    return service({
        url: '/api/creator/template/updateTemplateRequest',
        method: 'post',
        data: data
    })
}

export function batchGetMediaInfos(data) {
    return service({
        url: '/api/creator/template/batchGetMediaInfos',
        method: 'post',
        data: data
    })
}

export function submitAudioProduceJob(data) {
    return service({
        url: '/api/creator/template/submitAudioProduceJob',
        method: 'post',
        data: data
    })
}
