<template>
  <div style="padding: 20px">
  <div><p style=" margin:0pt; text-align:center"><span
      style=" color:#333333; font-family:黑体; font-size:14.5pt; font-weight:bold">视客联盟</span><span
      style=" color:#333333; font-family:黑体; font-size:14.5pt; font-weight:bold">隐私</span><span
      style=" color:#333333; font-family:黑体; font-size:14.5pt; font-weight:bold">声明</span>
  </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">引言</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">欢迎你使用我们的产品和服务！我们深知个人信息对你的重要性，你的信任对我们非常重要，我们将根据法律法规要求并参照行业最佳实践为你的个人信息安全提供充分保障。鉴于此，</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">制定本《隐私</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">声明</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">》（下称“本声明/本隐私权声明”）以帮助你充分了解在你使用我们的产品与/或服务时，我们如何收集、使用、保存、共享和转让这些信息，以及我们为你提供的访问、更新、删除和保护这些信息的方式。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">本隐私声明将帮助你了解以下内容：</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">一、我们如何收集和使用你的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">二、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们如何使用Cookies和同类技术</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">三、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们如何“共享”“转让”“公开披露”你的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">四、我们如何保护和保存你的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">五、你如何管理个人信息</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">六、未成年人的个人信息保护</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">七、通知和修订</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">八、如何联系我们</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">本声明适用于</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">提供的所有产品和服务。如</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">及关联公司（范围详见定义部分）的产品或服务中使用了</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">提供的产品或服务（例如使用</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">帐号登录）但未设独立隐私权声明的，则本声明同样适用于该部分产品或服务。我们及关联公司就其向你提供的产品或服务单独设立有隐私权声明的，则相应产品或服务适用相应隐私声明。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">需要特别说明的是，本隐私声明不适用于其他第三方通过前述网页或客户端或者小程序或公众号等向你提供的服务。例如你通过</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">使用第三方应用（如</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">应用市场）或者服务，或第三方服务和产品依托</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">产品向你提供服务时，你向第三方提供的信息不适用本声明。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">在使用</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">各项产品或服务前，请你务必仔细阅读和理解对应的隐私声明，特别是</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold; text-decoration:underline">以粗体</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold; text-decoration:underline">/</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold; text-decoration:underline">粗体下划线</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">标识的条款，你应重点阅读，确认你充分理解并同意后再开始使用。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">如对本声明内容有任何疑问、意见或建议，你可与我们联系。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:黑体; font-size:11.5pt">我们将担任“个人信息处理者”，亦即我们将决定“为何”和“如何”收集和使用你的个人信息”。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:黑体; font-size:11.5pt">为提供我们的产品及服务给你，我们需要和我们的“个人信息受托者”，即认可的商业伙伴或我们的关联合作伙伴共享你的个人信息。他们均负有采用适当安全措施以保护所持个人信息的义务，受严格的保密义务所规范，且“为何”和“如何”可以代替我们处理你的个人信息均受相关合同条款所约束。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:黑体; font-size:11.5pt">请注意部分认可的商业伙伴，如被授权商或社交媒体，也可以决定“为何”和“如何”使用你的个人信息。他们有各自的隐私和cookies政策，故请谨记他们使用你提供给他们的任何个人信息的方式应适用他们的规定，而不适用我们的规定。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:黑体; font-size:11.5pt">如你作为消费者，你授权给商</span><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">户</span><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">的个人信息，我们仅作为商</span><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">户</span><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">的个人信息受托者。</span></p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:黑体; font-size:11.5pt">为了解我们的角色，请注意我们“为何”和“如何”收集和使用你的个人信息，来决定处理你个人信息的目的和方式。我们的“个人信息受托者”则是由我们指示“为何”和“如何”可以代替我们处理你个人信息的机构。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">第一部分定义</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">一、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">【</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">】</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">指研发并提供</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">产品和服务的杭州</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">知视云</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">科技有限公司，及现在或未来设立的相关关联公司的单称或合称。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">二、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">【关联公司】</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">关联公司指</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">杭州</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">知视云</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">科技有限公司</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">披露的</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">服务提供者的关联公司</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">三、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">【</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">服务】</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">服务指由</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">开发和服务的</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">达人矩阵分发系统、云快剪系统软件</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">等服务，以及将</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">部分功能/服务作为第三方服务集成在</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">关联公司（见定义条款）和/或其他第三方软件而提供的服务。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">四、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">【个人信息删除】</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">个人信息删除指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">五、除另有约定外，本声明所用定义与</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">《用户协议》</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">中的定义具有相同的涵义。</span></p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">第二部分</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold"> </span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">隐私声明</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">一、我们如何收集和使用你的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">个人信息</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">个人敏感信息</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇的个人信息。本隐私声明中涉及的个人信息包括：基本信息（包括个人姓名、个人电话号码、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">微信昵称、微信头像、微信</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">openid</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">）；网络身份标识信息（包括个人信息主体帐号、IP地址、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">企业信息</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">等）；</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">个人财产信息（包括交易和消费记录、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">生成卡、云空间等</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">虚拟财产信息）</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">；个人上网记录（包括软件使用记录、点击记录）；</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">其中，字体加粗部分为涉及的个人敏感信息</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们会通过如下三种渠道获取你的个人信息：</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（一）你直接提供给我们及我们收集的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">请你注意，如果你提供的是他人个人信息，请你确保已取得相关主体的授权。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">1.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">消费者角色</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">在你未创建</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">小程序系统</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">或在商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">户小程序系统</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">进行网上交易时，你的用户角色为消费者。</span>
    </p>
    <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt; width:415.25pt">
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">我们收集的个人信息</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">使用目的</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">收集场景</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">法律依据</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">帐号注册信息及联系方式</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">微信授权昵称</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、手机号码以及你在某活动中主动向我们提供的任何信息。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供新用户注册</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供老用户登录</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">注册/登录</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p>
          <p style="margin:0pt; text-align:justify"><span
              style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span></p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">支付信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">订单号、交易金额信息、有效期在内的支付必要信息。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">确认支付指令并完成支付</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">商品付款</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p>
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行法定职责</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">或法定义务</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">交易信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">你在</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">商户小程序系统</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">所生成的消费历史记录、订单信息、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">会员</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">信息以及你的其他个人信息。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供订单展示</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">产品</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">交付</span></p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供权益保护</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">商户小程序系统</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">下单</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行法定职责或法定义务</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">身份信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">真实姓名</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提现功能</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提现</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">连接和使用信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">系统</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">浏览记录</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、软件使用记录（点击记录</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视频</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">生成记录、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">参与</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">任务记录</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、交易</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">记录</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提现记录、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">你与内容的交互行为、日志以及其他类似信息）。</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">如前述事件发生时你处于脱机状态，相关信息将被记录并在你下次连接至网络时传输给我们。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提升用户体验</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">保障帐号安全性</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供多业务的无缝体验</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">产品使用、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视频生成</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">交易</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">下单</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、余额提现</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">取得个人的同意</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行法定职责或法定义务</span>
          </p></td>
      </tr>
    </table>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">2.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">角色</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">当你在使用</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">的产品（如</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">达人矩阵系统</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">）进行经营活动时，你的用户角色为商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">。</span></p>
    <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt; width:415.25pt">
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">我们收集的个人信息</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">使用目的</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">收集场景</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">法律依据</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">帐号注册信息及联系方式</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">帐号名、密码、手机号码以及你在某活动中主动向我们提供的任何信息。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供新用户注册</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供老用户登录</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">与你取得联系</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">建立业务合作管理</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">发送营销信息</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">注册/登录</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">系统</span></p>
          <p style="margin:0pt; text-align:center"><span
              style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">企业</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">认证信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">企业</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">法人姓名、手机号码、身份证号、身份证正反面照片、营业执照以及其他工商要求信息。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">完成</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">系统</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">认证</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">系统</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">认证</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行法定职责</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">或法定义务</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">企业</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">联系信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">经营地址、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">联系人姓名、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">电话、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">微信号</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">与你取得联系</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">建立业务合作管理</span>
          </p>
          <p style="margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">企业认证</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行法定职责或法定义务</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">支付信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">订单号、交易金额信息、银行卡号、有效期在内的支付必要信息。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">确认支付指令并完成支付</span>
          </p>
          <p style="margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">商品付款、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">服务付款</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p>
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行法定职责或法定义务</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">交易信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">你在</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">服务</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">商市场所生成的消费历史记录、订单信息以及你的其他个人信息。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">服务商</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">市场</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">服务</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">交付</span></p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">服务商</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">市场权益保护</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">服务商</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">市场</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">服务</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">下单</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">反馈信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">你通过“在线客服”和电子邮件、电话以及其他形式调查提交给我们的信息。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提升用户体验</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">解决交易纠纷</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">联系</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">客服</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">取得个人的同意</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">音视频信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">你通过在线客服语音信息、客服电话的录音或当你进入我们办公区所录制的音视频。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提升用户体验</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">解决交易纠纷</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">保障消费者个人权益</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">联系客服、到访办公区</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">取得个人的同意</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">连接和使用信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：网页浏览记录</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">域名、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">软件使用记录（点击记录、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视频模版制作记录</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">视频生成记录、云快剪系统使用记录、交易记录</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、日志以及其他类似信息）。</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">如前述事件发生时你处于脱机状态，相关信息将被记录并在你下次连接至网络时传输给我们。</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提升用户体验</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">保障帐号安全性</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供多业务的无缝体验</span>
          </p>
          <p style="margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">网页及</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">小程序</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">使用</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">取得个人的同意</span>
          </p></td>
      </tr>
    </table>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（二）</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">我们从第三方收集的信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">1.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">消费者角色</span>
    </p>
    <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt; width:415.25pt">
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">我们收集的个人信息</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">使用目的</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">信息来源</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">法律依据</span>
          </p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">第三方帐号信息</span>
          </p>
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">包括：微信（</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">头像、昵称、地区、性别）、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">抖音</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">（头像、昵称</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、地区、粉丝、抖音ID等</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">）、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">快手</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">（头像、昵称</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">、地区、粉丝、</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">快手</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">ID</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">等</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">）</span><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">。</span></p>
        </td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">提供第三方帐号登录</span>
          </p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">第三方合作方</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.88pt; vertical-align:middle">
          <p style="margin:0pt; text-align:justify"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">履行合同</span>
          </p>
          <p style="margin:0pt; text-align:center"><span
              style=" color:#333333; font-family:黑体; font-size:11.5pt">取得个人的同意</span>
          </p></td>
      </tr>
    </table>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（三）你可以选择授权提供个人信息的场景</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">为向你提供更优质的服务，我们为你提供移动应用程序，你可以选择使用我们提供的功能，为确保相关业务功能的正常实现，我们需要根据具体的使用场景调用对应的必要权限，并在调用前向你弹窗询问</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（四）征得同意的例外</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">你应充分知晓，以下情形中，我们收集、使用个人信息无需征得你的授权同意：</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">1.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与国家安全、国防安全有关的；</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">2.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与公共安全、公共卫生、重大公共利益有关的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">3.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与犯罪侦查、起诉、审判和判决执行等有关的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">4.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">5.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">所收集的个人信息是个人信息主体自行向社会公众公开的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">6.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">从合法公开披露的信息中收集的你的个人信息的，如合法的新闻报道、政府信息公开等渠道；但是你明确拒绝或者处理该信息侵害你重大利益的除外。</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">7.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">根据你的要求签订合同所必需的；</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">8.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">9.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">为合法的新闻报道所必需的；</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">10.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">11.</span><span
        style="color:#333333; font-family:Calibri; font-size:11.5pt">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">法律法规规定的其他情形。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（五）个人信息使用规则</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">1.我们会根据本隐私声明的约定，为实现我们的产品与/或服务功能而对所收集的个人信息进行使用。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">2.我们会基于</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">商户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">的授权，通过短信服务商为</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">商户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">发送营销短信给你，如你想退订某一商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">的短信，可以根据短信内容所提示的退订方式退订。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">3.在收集你的个人信息后，我们将通过技术手段对信息进行去标识化处理，去标识化处理的信息将无法识别主体。</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">请你了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露你个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">4.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">请你了解并同意，对于你在使用我们的产品与</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">/</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">或服务时所提供的所有个人信息，除非你删除或通过系统设置拒绝我们收集，否则将被视为在你使用我们的产品与</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">/</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">或服务期间持续授权同意我们使用。在你注销帐号时，我们将停止使用并删除你的个人信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">5.我们会将业务中收集的个人信息用于技术改造、网络维护、故障排除、内部政策与流程制定、生成内部报告，将已经去标识化无法识别你身份且不能复原的信息用于建立数据库并进行商业化利用。但这些统计信息不包含你的任何身份识别信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">6.当我们展示你的个人信息时，我们会采用包括内容替换、泛化处理方式对你的信息进行脱敏，以保护你的信息安全。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">7.当我们要将你的个人信息用于本隐私声明未载明的其它用途时，或基于特定目的收集而来的个人信息用于其他目的时，会事先征求你的明示同意。</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">请你理解，我们向你提供的产品与</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">/</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">或服务将不断更新变化。如果你选择使用本隐私声明中尚未列明的其他产品与</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">/</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">或服务时，我们会在收集你的个人信息前通过协议、页面提示等方式向你详细说明信息收集的目的、方式、范围并征求你的同意。若你不同意提供前述信息，你将无法使用该项产品与</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">/</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">或服务，但不影响你使用现有产品与</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">/</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">或服务。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">二、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">我们如何使用</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">Cookies</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">和同类技术</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">对于</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">提供的服务，我们与合作伙伴和</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">服务</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">商一起，使用各种技术来了解人们如何使用相关服务。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">（一）</span><span
        style=" color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">Cookies</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">的使用</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt">为确保网站正常高效运转，使你获得更轻松的访问体验，我们会在你的设备终端/系统上存储名为 Cookies的小数据文件（包括HTTP Cookies、flash Cookies、HTML5和Flash本地存储、网络信标/GIF、嵌入式脚本、电子标签/缓存浏览器和软件开发工具包（“SDK”）</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt">），指定给你的</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt">Cookiess</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt"> 是唯一的，它只能被将</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt">Cookiess</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt">发布给你的域中的Web服务器读取。Cookies会帮助你在后续访问我们网站时调用你的信息，简化你重复登录或重新填写信息的步骤；为你提供安全购物的设置；帮助你优化对广告的选择与互动；保护你的数据安全等。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt">我们不会将 Cookies用于本隐私政策所述目的之外的任何用途。你可根据自己的管理或删除 Cookies。你可以清除计算机或移动设备上保存的所有 Cookies，你有权接受或拒绝Cookies。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt">大多数浏览器会自动接受Cookies，但你通常可根据自己的需要来修改浏览器的设置以拒绝Cookies；另外，你也可以清除软件内保存的所有Cookies。但你将因此无法完全体验我们某些便捷性和安全性的服务功能。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">（二）网络</span><span
        style=" color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">Beacon</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">和同类技术的使用</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt">除Cookies外，我们网页上常会包含一些电子图象(称为"单像素"GIF文件或"网络Beacon")等其他同类技术，它们可以帮助网站计算浏览网页的用户或访问某些Cookies。我们使用网络Beacon的方式有:</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt">1.我们通过在我们网站上使用网络Beacon，计算用户访问数量，并通过访问Cookies辨认注册用户。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt">2.我们通过得到的Cookies信息，可以提供更优质的服务。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:Helvetica; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">&#xa0;</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">三、</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">我们如何共享、转让、公开披露你的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（一）共享</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们不会与</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">服务提供者以外的公司、组织和个人共享你的个人信息，但以下情况除外：</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">1.在你主动选择情况下共享：若你主动签署“第三方商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">隐私声明”，我们会根据你的选择，将你的个人信息、订单信息及交易有关的必要信息与相关商品和服务的提供者共享来实现你向其购买商品或服务的需求，并促使其可以完成后续的售后服务。你可以在相关商品和服务的提供者从事经营活动的主页面查询其营业执照登载的信息，以辨别你的交易对象身份。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">2.在获取明确同意情况下共享：获得你的明确同意后，我们会与其他方共享你的个人信息。例如，如果我们委托第三方向你提供产品或服务时，我们会在征得你同意后将上述信息共享给第三方，如果你拒绝提供此类信息，我们将无法完成相关交付服务。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">3.在法定情形下共享：我们会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享你的个人信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">4.与关联公司共享：为便于我们基于</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">帐号向你提供服务，保护</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">关联公司或其他用户或公众的人身财产安全免遭侵害，在你同意的情况下，你的个人信息会与我们的关联公司共享。我们只会共享必要的个人信息，且受本隐私声明中所声明目的的约束，如果我们共享你的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求你的授权同意。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">5.与授权合作伙伴共享：我们会委托授权合作伙伴为你提供某些服务或代表我们履行职能，在此之前，我们会和他们通过不限于签订协议的方式约定双方的权利和义务。我们仅会出于合法、正当、必要、特定、明确的目的处理你的信息，授权合作伙伴只能接触到为其履行职责所需信息，且不能将此信息用于任何其他目的。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">目前，我们的授权合作伙伴包括以下类型：</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">1）广告、分析服务类的授权合作伙伴。除非得到你的许可，否则我们不会将你的个人身份信息（指可以识别你身份的信息，例如姓名或电子邮箱，通过这些信息可以联系到你或识别你的身份）与提供广告、分析服务的合作伙伴共享。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">其他共享形式</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">（1）基于合理商业习惯：例如在我们计划与其他公司进行资本市场活动（包括但不限于IPO，债券发行）接受尽职调查时。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">（2）基于应用程序接口（API）、软件工具开发包（SDK）与第三方进行必要的数据共享，不同版本的第三方SDK会有所不同，一般包括第三方帐号登录类、分享类、第三方支付类、厂商推送类、统计类等，我们会对第三方进行严格的安全检测并约定数据保护措施。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">关于第三方如何收集、使用你的个人信息，建议你参考第三方SDK的相关服务协议及个人信息保护声明。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">请你知晓，对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的数据保护协议，要求他们按照我们的说明、本隐私声明以及其他任何相关的保密和安全措施来处理个人信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（二）转让</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们不会将你的个人信息转让给任何公司、组织和个人，但以下情况除外：</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">1.事先获得你明确的同意或授权；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">2.根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">3.符合与你签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">4.随着我们业务的发展，我们及我们的关联方有可能进行合并、收购、资产转让或类似的交易，</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">如涉及个人信息的转让，我们会要求受让你个人信息的公司、组织</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">继续接受本隐私声明的约束，否则</span><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">,</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">我们将要求该公司、组织重新征求你的授权同意。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（三）公开披露</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们仅会在以下情况下，公开披露你的个人信息：</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">1.获得你明确同意或基于你的主动选择，我们会公开披露你的个人信息；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">2.根据法律、法规的要求、强制性的行政执法或司法要求所必须提供你个人信息的情况下，我们会依据所要求的个人信息类型和披露方式公开披露你的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函，并且我们披露的文件均是加密后提供。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">3.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">当你为商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">角色时，如果我们确定你出现违反法律法规或严重违反</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">相关协议规则的情况，或为保护</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">及其关联公司用户或公众的人身财产安全免遭侵害，我们会依据法律法规或</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">相关协议规则通过短信通知或者弹窗等方式征得你同意的情况下披露关于你的个人信息</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">，包括相关违规行为以及</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">已对你采取的措施。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">以下情形中，共享、转让、公开披露你的个人信息无需事先征得你的授权同意：</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">1.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与我们履行法律法规规定的义务相关的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">2.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">根据法律法规的规定或行政、司法机构、国家部门等有权机关的要求依法提供的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">3.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">出于维护你或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">4.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">你自行向社会公众公开的个人信息；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">5.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与国家安全、国防安全直接相关的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">6.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">根据法律规定，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向你通知并征得你的同意。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">四、我们如何保护和保存你的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（一）我们保护你个人信息的技术与措施</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">我们非常重视个人信息安全，并采取一切合理可行的措施，保护</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">消费者、商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">及商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">消费者的个人信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">1.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">数据安全技术措施</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">为保障上述人员的信息安全，我们努力采取各种符合业界标准的安全防护措施，包括建立合理的制度规范、实施合宜的安全技术来防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们会使用受信赖的保护机制防止数据遭到恶意攻击，例如在浏览器与“服务”之间交换数据（如信用卡信息）时采用SSL加密保护技术，同时对网站本身提供https安全浏览方式，以保障数据在传输过程中的安全。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">使用加密技术对个人信息加密保存，确保数据的保密性。在个人信息使用时，例如订单查询、展示时，我们会采用多种脱敏技术增强个人信息在使用中的安全性。我们会采用严格的数据访问控制权限控制和多重身份验证机制，确保只有授权人员才</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">可访问个人信息。同时，在数据使用过程中，我们提供数据安全审计，避免数据被违规使用。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">对于我们作为中立技术服务提供方的商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">所存储于</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">的个人信息，我们也将使用不低于以上的数据安全技术措施进行保护。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">2.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">为保护个人系信息采取的其他安全措施</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们建立了数据分类分级制度、数据安全管理规范来管理及规范个人信息的存储和使用，并通过审计机制对数据安全进行全面检查。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们会采取一切合理可行的措施，确保未收集无关的个人信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们定期进行安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议你使用此类工具时请使用复杂密码，并注意保护你的个人信息安全。在通过</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与第三方进行网上产品与/或服务的交易时，你不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请你妥善保护自己的个人信息，仅在必要的情形下向他人提供。如你发现自己的个人信息尤其是你的帐号或密码发生泄露，请你立即通过本隐私声明【八、如何联系我们】中约定的联系方式联络我们，以便我们采取相应措施。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:inherit; font-size:11.5pt; font-weight:bold">3.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">安全事件处理</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">为应对个人信息泄露、损毁和丢失等可能出现的风险，</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施并联合相关部门进行溯源和打击。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致你的合法权益受损的，我们会及时启动应急预案，采取合理救济措施，以尽可能降低对你个人信息和其他权益的影响。一旦发生个人信息安全事件，我们将按照法律法规的要求，及时向你告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、你可自主防范和降低风险的建议、对你的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知你，当难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">互联网环境并非百分之百安全，我们将尽力确保或担保你发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致你的合法权益受损，我们将承担相应的法律责任。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（二）你个人信息的保存</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">1.存储地点：你的个人信息将存储于中华人民共和国境内。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">2.存储期限：我们只会在达成本声明所述目的所需的期限内保留你的个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">（1）完成与你相关的交易目的、维护相应交易及业务记录、应对你可能的查询或投诉；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">（2）保证我们为你提供服务的安全和质量；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">（3）你是否同意更长的留存期间；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">（4）是否存在保留期限的其他特别约定。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">在你的个人信息超出保留期间后，我们会根据适用法律的要求删除你的个人信息，或使其匿名化处理。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">请你注意，当你成功注销</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">帐号后，我们将对你的个人信息进行删除或匿名化处理。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">若你为消费者角色，当你或我们协助你删除相关信息后，非必要信息，我们将在15天内自动删除。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">若你为商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">角色，我们将根据与你另行签订的合同处理。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">因为适用的法律和安全技术限制，我们无法立即从备份系统中删除相应的信息，我们将安全地存储你的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">如果我们终止服务或运营，我们会至少提前三十日向你通知，并在终止服务或运营后对你的个人信息进行删除或匿名化处理。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">五、你如何管理个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（一）查询、更正和补充你的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">你有权查询、或补充你的信息。你可以通过以下方式自行进行：</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">1.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">消费者角色</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">：</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">进入</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">商户小程序</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">，选择“我的-个人信息”后对个人资料、个人帐号相关信息进行查询、更正或补充。</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">2.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">商</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">户</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">角色</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">：</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">登录</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">商户后台</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">，进入</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">账户中心</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">，进行查询、更正或补充。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">其他你无法查看或更正的信息请联系</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">客服寻求帮助，协助你查询、更正或补充你的信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（二）删除你的个人信息</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">在以下情形中，你可以向我们提出删除个人信息的请求：</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">1.如果我们处理个人信息的行为违反法律法规；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">2.如果我们收集、使用你的个人信息，却未征得你的明确同意；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">3.如果我们处理个人信息的行为严重违反了与你的约定；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">4.如果你不再使用我们的产品或服务；</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">5.如果我们停止为你提供产品或服务。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">若我们决定响应你的删除请求，我们还将同时尽可能通知从我们处获得你的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得你的授权）。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">当你或我们协助你删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储你的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">（三）</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">改变你授权同意的范围或撤回你的授权</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权声明“第一部分”）。除此之外，你可以在“个人信息”页面中设置或与客服联系等方式给予或收回你的授权同意。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">请你理解，每个业务功能需要一些基本的个人信息才能得以完成，当你撤回同意或授权后，我们无法继续为你提供撤回同意或授权所对应的服务，也不再处理你相应的个人信息。但你撤回同意或授权的决定，不会影响此前基于你的授权而开展的个人信息处理。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（四）注销帐号</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">你可以</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">联系我们的客服人员</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">申请注销帐号</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">（需满足注销条件）。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">你注销帐号后，我们将停止为你提供产品与/或服务，并依据你的要求，除法律法规另有规定外，我们将删除你的个人信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（五）拒绝自动化决策</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">在某些业务功能中，我们会依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响你的合法权益，你有权要求我们做出解释，我们也将在不侵害</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（六）</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">获取你的个人信息副本</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">你有权获取你的个人信息副本。如你需要获取我们收集的你的个人信息副本，你可通过本隐私声明【八、如何联系我们】中约定的联系方式联络我们。在符合相关法律规定且技术可行的前提下，我们将根据你的要求向您提供你的个人信息副本。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（七）其他</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">若出于你的合法、正当利益，你需要对已死亡用户相关的个人信息行使前述权利，你需要向我们提供你是其近亲属或执行人的证明。我们将依据个人信息保护相关法律，尽可能支持你的要求。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">（八）响应你的请求</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">为保障安全，你可能需要提供书面请求，或以其他方式证明你的身份。我们将会先要求你验证自己的身份，然后再处理你的请求。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们将在15天内做出答复。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">对于你合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与你的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">在以下情形中，按照法律法规要求，我们将无法响应你的请求：</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">1.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与我们履行法律法规规定的义务相关的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">2.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与国家安全、国防安全有关的；</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">3.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与公共安全、公共卫生、重大公共利益有关的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">4.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与犯罪侦查、起诉、审判和执行判决等有关的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">5.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">有充分证据表明个人信息主体存在主观恶意或滥用权利的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">6.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">响应你的请求将导致你或其他个人、组织的合法权益受到严重损害的；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">7.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">涉及商业秘密的。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">六、未成年人的个人信息保护</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">本声明所指的儿童是指不满14周岁的个人，如果没有父母或监护人的同意，儿童不得创建自己的用户帐号。如你为未成年人的，建议你请你的父母或监护人仔细阅</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">读本隐私声明，并在征得你的父母或监护人同意的前提下使用我们的服务或向我们提供信息。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，则会设法尽快删除相关数据。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">七、通知和修订</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们的隐私权声明可能变更。</span></p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">未经你明确同意，我们不会限制你按照本隐私权声明所应享有的权利。我们会在</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">规则中心专门页面上发布对隐私权声明所做的任何变更。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">对于重大变更，我们还会提供更为显著的通知（包括我们会通过在</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">规则中心公示的方式进行通知甚至向你提供弹窗提示）。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">如你在本声明更新生效后继续使用我们的服务，即代表你已充分阅读、理解并接受更新后的声明并愿意受更新后的声明约束。我们鼓励你在每次使用我们服务时都查阅本声明。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">本声明所指的重大变更包括但不限于：</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">1.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">2.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">3.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">个人信息共享、转让或公开披露的主要对象发生变化；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">4.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">你参与个人信息处理方面的权利及其行使方式发生重大变化；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">5.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:黑体; font-size:11.5pt">6.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">个人信息安全影响评估报告表明存在高风险。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:inherit; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt; font-weight:bold">八、如何联系我们</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">我们设立了个人信息保护部门，如对本声明内容有任何疑问、意见或建议，或发现你的个人信息可能被泄露或你觉得需要撤回、更正、删除你的个人信息授权，你可以发邮件到</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">zhishiyunkj</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">@163.</span><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">com</span><a
        href="mailto:privacy@youzan.com" target="_blank"></a><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">与我们联系，我们将在15天内回复你的请求。</span>
    </p>
    <p style=" margin:0pt"><span
        style=" color:#333333; font-family:黑体; font-size:11.5pt">如果你对我们的回复不满意，特别是你认为我们的个人信息处理行为损害了你的合法权益，你还可以通过被告住所地有管辖权的法院提起诉讼来寻求解决方案。</span>
    </p></div>
</div>
</template>

<script>
export default {
  name: "privacy"
}
</script>

<style scoped>

</style>
