import service from "../request";

export function pullBalanceList(query) {
    return service({
        url: '/api/merchants/pullBalance/list',
        method: 'get',
        params: query
    })
}

export function getPayImg(data) {
    return service({
        url: '/api/merchants/pullBalance/getPayImg',
        method: 'post',
        data: data
    })
}

export function check(query) {
    return service({
        url: '/api/merchants/pullBalance/check',
        method: 'get',
        params: query
    })
}
