<template>
<div style="padding: 20px">
  <div><p style="line-height:24.75pt; margin:0pt; text-align:center"><span
      style="font-family:黑体; font-size:13.5pt; font-weight:bold">视客联盟</span><span
      style="font-family:黑体; font-size:13.5pt; font-weight:bold">用户协议</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt; font-weight:bold">一、导语</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">一</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">) </span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【审慎阅读】</span><span
        style="font-family:黑体; font-size:9pt">你在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请你务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">免除或者限制责任的条款将以粗体下划线标识，你应重点阅读。如你对协议有任何疑问，可向客服咨询。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(二)</span><span
        style="font-family:Calibri; font-size:9pt">&#xa0;</span><span style="font-family:Calibri; font-size:9pt">&#xa0;</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【签约动作】</span><span
        style="font-family:黑体; font-size:9pt">当你按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后或以其他方式选择接受本协议或直接使用了本协议相关服务，即表示你已充分阅读、理解并接受本协议的全部内容，并与</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">达成一致，成</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">“用户”。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">阅读本协议的过程中，如果你不同意本协议或其中任何条款约定，你应立即停止注册程序。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt; font-weight:bold">二、定义</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(一)</span><span
        style="font-family:Calibri; font-size:9pt">&#xa0;</span><span style="font-family:Calibri; font-size:9pt">&#xa0;</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">】</span><span style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">指研发并提供</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">产品和服务的杭州</span><span
        style="font-family:黑体; font-size:9pt">知视云</span><span style="font-family:黑体; font-size:9pt">科技有限公司，以及其现在或未来设立的相关关联公司的单称或合称，以下称为“</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">”或“我们”。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">二</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">) </span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">软件服务】</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">软件服务指SaaS软件服务，包括</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">开发和服务的</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">达人矩阵分发系统、云快剪系统</span><span style="font-family:黑体; font-size:9pt">等软件产品，以及将</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">部分功能/服务作为第三方服务集成在</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">关联公司或其他第三方软件而提供的服务。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">三</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">) </span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">关联经营主体】</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">关联经营主体是指和</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">一起共同提供服务的各法律主体，</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">可能根据业务调整而变更经营主体，变更后的经营主体与你共同履行本协议并向你提供产品或服务，经营主体的变更不会影响你本协议项下的权益。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">为了更好的为你提供服务，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">可能会依据市场变化或经营便利自主安排或指定其关联公司、控股公司或</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">认可的第三方公司提供部分或全部的相关服务</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(四)</span><span
        style="font-family:Calibri; font-size:9pt">&#xa0;</span><span style="font-family:Calibri; font-size:9pt">&#xa0;</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【用户（以下或称为</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">“</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">你</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">”</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">）】</span><span
        style="font-family:黑体; font-size:9pt">用户是指在</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">创建</span><span
        style="font-family:黑体; font-size:9pt">账号</span><span
        style="font-family:黑体; font-size:9pt">的公司、个人以及其他合法组织。</span></p>
    <p style="margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span><span
        style=" color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style=" color:#333333; font-family:黑体; font-size:9pt; font-weight:bold">五</span><span
        style=" color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style=" color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">&#xa0;</span><span
        style=" color:#333333; font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">【视客联盟规则】视客联盟规则是指视客联盟规则中心列明的全部规则、细则、解读、公告等内容，详见视客联盟</span><span
        style=" color:#333333; font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">规则中心。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">三、协议范围</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(一)</span><span
        style="font-family:Calibri; font-size:9pt">&#xa0;</span><span
        style="font-family:黑体; font-size:9pt"> 【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">平等主体</span><span
        style="font-family:黑体; font-size:9pt">】本协议由你与</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">共同缔结，本协议对你与</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">均具有合同效力。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(二)</span><span
        style="font-family:Calibri; font-size:9pt">&#xa0;</span><span style="font-family:Calibri; font-size:9pt">&#xa0;</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【协议变更】</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">对本协议内容进行变更的，会按照法定程序在</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">规则中心</span><span style="font-family:黑体; font-size:9pt">进行公示，若你在本协议内容公示生效后继续使用本服务的，表示你已充分阅读、理解并接受变更后的协议内容，也将遵循变更后的协议内容继续使用</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">服务；</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">若你不同意变更后的协议内容，你应立即停止使用本服务。</span><span
        style="font-family:黑体; font-size:9pt">如您对协议有任何疑问，可向</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">客服</span><span
        style="font-family:黑体; font-size:9pt">进行</span><span style="font-family:黑体; font-size:9pt">咨询。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">三</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">) </span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【补充协议】</span><span
        style="font-family:黑体; font-size:9pt">由于互联网高速发展，你与</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">签署的本协议列明的条款并不能完整罗列并覆盖你与</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">所有权利与义务，现有的约定也不能保证完全符合未来发展的需求，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">因此，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">规规则中心</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">公示的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">《隐私政策》</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">等相关协议以及规则</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">规范均为本协议的补充协议，与本协议不可分割且具有同等法律效力，若你使用本服务，视为你同意上述补充协议。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">四、帐号注册与使用规范</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">一</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">) </span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【用户资格】</span><span
        style="font-family:黑体; font-size:9pt">你确认在你注册</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">帐号并使用</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">产品或服务前，你是具有法律规定的完全民事权利能力和民事行为能力，能够独立承担民事责任的自然人、法人或其他组织。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">如果你因年龄、智力等因素而不具有相应的民事行为能力，请在法定监护人的陪同下阅读和判断是否同意本协议。如果你是非中国大陆地区的用户，你订立或履行本协议以及使用本服务的行为还需要同时遵守你所属和</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">或所处国家或地区的法律。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(二)</span><span
        style="font-family:Calibri; font-size:9pt">&#xa0;</span><span style="font-family:Calibri; font-size:9pt">&#xa0;</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">帐号注册</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">1.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【帐号获得】你可通过下述方式获得你的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">帐号：</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">当你按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，你可获得</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">帐号并成为</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">用户。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【帐号使用】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">为方便用户登录，你可通过你的帐号</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">登录</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">域名下的其他服务，如有特殊情况，具体以实际</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">登录</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">情况为准。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">3.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【同一用户】</span><span
        style="font-family:黑体; font-size:9pt">使用同一身份认证信息、同一手机号或经</span><span style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">排查认定多个</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">帐号的实际控制人为同一人的，均视为同一用户。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">4.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【信息真实】</span><span
        style="font-family:黑体; font-size:9pt">你应当通过真实身份信息认证注册帐号，且你提交的帐号名称、头像、 简介等注册信息中不得出现违法和不良信息，如存在上述情况，</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">将不予注册；</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">同时，在注册后，如发现你以虚假信息骗取帐号名称注册，或其帐号头像、简介等注册信息存在违法</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">不良信息的，或侵犯他人合法权益的，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">可采取限期改正、暂停使用、注销登记、收回等措施。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">三</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">) </span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">帐号安全</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">1.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【安全保管义务】你有权使用手机号登录</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">，获得账号并自行设置符合安全要求的密码，</span><span
        style="font-family:黑体; font-size:9pt">你的帐号由你自行保管，</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">任何时候均不会主动要求你提供你的帐号密码。因此，请你务必保管好你的帐号，并确保你在每个上网时段结束时退出登录并以正确步骤离开</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">帐号因你主动泄露或因你遭受他人攻击、诈骗等行为导致的损失及后果，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">并不承担责任，你应通过司法、行政等救济途径向侵权行为人追偿。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【帐号行为责任自负】由于您的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">账号关联您的个人信息及商业信息，请确保您</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">账号仅限您本人使用，以保证账号的安全性和私密性。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你应对你帐号项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买品</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务及披露信息等）负责，但除</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">存在过错的除外。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">3.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">如发现任何未经授权使用你帐号登录</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">或其他可能导致你帐号遭窃、遗失的情况，建议你立即通知</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">。你理解</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">对你的任何请求采取行动均需要合理时间，且</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">应你请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大，除</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">存在法定过错外，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">不承担责任。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">四</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【帐号转让】</span><span
        style="font-family:黑体; font-size:9pt">由于用户帐号关联用户信用信息，仅当有法律明文规定、司法裁定或经</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">同意，并符合</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">用户帐号转让流程的情况下，你可进行帐号的转让。你的帐号一经转让，该帐号项下权利义务一并转移。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">除此外，你的帐号不得以任何方式转让，否则</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">有权追究你的违约责任，且由此产生的责任及后果均由你自行承担。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">五</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【资料更新】</span><span
        style="font-family:黑体; font-size:9pt">你应当及时更新你提供的信息，在法律有明确规定要求</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">作为技术服务提供者必须对部分用户的信息进行核实的情况下，</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">将依法不时地对你的信息进行检查核实，你应当配合提供最新、真实、完整、有效的信息。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(六)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【帐号找回】</span><span
        style="font-family:黑体; font-size:9pt">因你个人原因导致的帐号信息遗失，如需找回帐号信息，请按照帐号找回要求提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求，无法通过安全验证，</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">有权拒绝提供帐号找回服务；若帐号的唯一凭证不再有效，</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">有权拒绝支持帐号找回，例如手机号二次出售，</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">可拒绝支持帮助找回原手机号绑定的帐号。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">七</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【帐号注销】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">在需要终止使用帐号服务时，符合以下条件的，你可以申请注销你的帐号：</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">1.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你申请注销的是你本人的帐号，并依照</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">的相关流程提交相应的资料进行注销；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你应对你在注销帐号前的使用行为承担相应责任；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">3.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">注销成功后，你的帐号信息、个人身份信息、交易记录、权益</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">内容</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">等将无法恢复。你应自行备份存储在本软件和</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">或本服务中的数据、信息或与本软件和</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">或本服务相关的数据、信息。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">五、</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">服务内容及使用规范</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">一</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【服务概况】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你可以通过</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">提供的软件服务</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">创建达人矩阵小程序</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">、</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">以</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">商户</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">身份上架</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">产品</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">并与买家达成交易，但具体的交易及经营行为由你自行决定。在此过程中，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">不提供任何交易撮合帮助行为，你应自行对你的经营行为负责。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">二</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">产品</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">服务的购买】</span><span
        style="font-family:黑体; font-size:9pt">当你购买</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">所上架的</span><span style="font-family:黑体; font-size:9pt">权益</span><span
        style="font-family:黑体; font-size:9pt">及/或服务时，请你务必仔细确认所购</span><span
        style="font-family:黑体; font-size:9pt">权益</span><span style="font-family:黑体; font-size:9pt">的</span><span
        style="font-family:黑体; font-size:9pt">相应内容</span><span style="font-family:黑体; font-size:9pt">或服务的时间、内容、限制性要求等重要事项，并在下单时核实你的</span><span
        style="font-family:黑体; font-size:9pt">账号</span><span style="font-family:黑体; font-size:9pt">信息。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你的购买行为应当基于真实的消费需求，不得存在对</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">权益</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">及</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">或服务实施恶意购买、恶意维权等扰乱</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">正常交易秩序的行为。基于维护交易秩序及交易安全的需要，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">发现上述情形时可主动执行关闭订单、限制购买、锁定</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">冻结帐号等操作。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">六、用户的违约及处理</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">一</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">) </span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">违约的认定</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">1.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">使用</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务时违反有关法律法规规定的；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">违反本协议或</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">规则（即本协议第二条第</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">五</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">款）约定的；</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">3.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">为适应</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">互联网</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">发展，为广大用户提供高效优质服务，你理解并同意，其他情节影响严重，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">有合理理由认定你违约的，如你不能提供充分举证和合理解释，则视为你违约。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">二</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【禁止性信息】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你在使用</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务时须遵守法律法规，不得</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">开发、</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">制作、复制、发布、传播含有下列内容的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">产品</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">、</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视频、</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">、系统</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">或从事相关行为，也不得为</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">开发、</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">制作、复制、发布、传播含有下列内容的信息或从事相关行为提供便利：</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">1.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">违反国家法律法规禁止性规定的；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">3.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">欺诈、虚假、不准确或存在误导性的；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">4.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">5.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">6.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">存在可能破坏、篡改、删除、影响</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">任何系统正常运行或未经授权秘密获取</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">7.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">其他违背社会公共利益或公共道德或依据相关</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">协议、规则的规定不适合在</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">上发布的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">；</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">8</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">模仿开发视客联盟系统或给他人模仿开发</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">系统提供便利的行为。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(三)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【违反约定】</span><span
        style="font-family:黑体; font-size:9pt">如你的行为使</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">及其关联公司遭受损失（包括直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），你应赔偿</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">及其关联公司的上述全部损失；</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">如你的行为使</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">及其关联公司遭受第三人主张权利，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">及其关联公司可在对第三人承担金钱给付等义务后就全部损失向你追偿。如你违反本协议或</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">规则约定的，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">有权经综合评估后视你的行为性质，采取包括但不限于删除你发布的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">产品</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">、视频或</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务、暂停使用许可、终止服务、限制使用、回收帐号、追究法律责任等措施。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">如你违反本协议约定，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">模仿开发视客联盟系统或给他人模仿开发</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">系统提供便利</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">有权向你索要</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">1</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">00</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">万元（人民币壹佰万元整）的赔偿金。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(四)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【服务终止】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你需要对自己在服务使用过程中的行为负责。即使你已经终止使用该服务或完成了相应的交易或删除你所拥有的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">小程序</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">，你仍应对你的之前的行为负责，</span><span
        style="font-family:黑体; font-size:9pt">你承担责任的形式包括但不限于：对受到侵害者进行赔偿，以及</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">承担了因你的行为导致的行政处罚或侵权损害赔偿责任后，你应给予</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">等额的赔偿（如有）。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">五</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【关联处理】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">如因你严重违约导致</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">终止本协议时，出于维护</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">秩序及保护消费者权益的目的，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">及</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">或其关联公司可对与你在其他协议项下的合作采取中止甚或终止协议的措施，并以本协议第十一条约定的方式通知你。如</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">与你签署的其他协议及</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">及</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">或其关联公司与你签署的协议中明确约定了对你在本协议项下合作进行关联处理的情形，则</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">出于维护</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">秩序的目的，可在收到指令时中止甚至终止协议，并以本协议第十一条约定的方式通知你。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">七、交易争议处理</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">一</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">介入】</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">可根据实际情况介入处理你与其他用户之间的纠纷。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">1)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你在交易过程中与其他用户发生争议的，你或其他用户中任何一方可以自行协商解决，也可以通过</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">介入处理，若你觉得以上的方式均无法达到你要的结果，你可以寻求司法机关或其他机关的协助。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2)</span><span
        style="font-family:黑体; font-size:9pt">你选择</span><span style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">介入的方式处理相关问题，那么你需要遵守和执行</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">的处理结果。若你对</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">的处理结果不满意，你仍有权采取其他争议处理途径解决争议，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">但通过其他争议处理途径未取得终局决定前，你仍应先履行</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">的处理结果。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">3)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【调处决定】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你理解并同意，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">进行交易争议介入的，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">的客服及维权人员并非专业人士，仅能以普通人的认知对用户提交的凭证进行判断。因此，除</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">存在故意或重大过失外，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">对争议调处决定免责。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">二</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【赔偿限制】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">如根据</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">维权介入后判定</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">商</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">户</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">承担责任的，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">商</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">户</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">微信商户号</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">余</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">额不足致使无法退款或赔付的，由你自行和</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">商</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">户</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">协商或通过其他途径解决。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">八、免责声明</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">一</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">无需为此承担任何责任：</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">1.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，在</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令、疫情等不可抗力因素。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">3.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">基础运营商或主管部门故障、调整导致之影响。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">4.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">因政府管制而造成的暂时性关闭、服务调整等。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">5.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你的电脑软硬件和通信线路、供电线路出现故障的。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">6.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">微信原因、</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">第三方服务瑕疵或政府行为等原因。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">7.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">尽管有前款约定，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">将采取合理行动积极促使服务恢复正常。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">二</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【海量信息】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">根据本协议向你提供</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">软件服务，你了解</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">商户</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">产品</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">以及</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视频模版</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">系</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">商户</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">自行发布，且可能存在风险和瑕疵。</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">将通过依法建立相关检查监控制度尽可能保障你的合法权益及良好体验。同时，鉴于网络服务具备存在海量信息及信息网络环境下信息与实物相分离的特点，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">无法逐一审查</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">产品</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视频模版</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">的信息，无法逐一审查交易所涉及的</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">产品</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold; text-decoration:underline">/</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务的质量、安全以及合法性、真实性、准确性，对此你应谨慎判断。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">九、服务终止</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">一</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【终止的情形】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">本服务可能会因为下述情形终止：</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">1.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">变更本服务内容而你明示不愿接受变更事项的。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你明示不愿继续使用</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务，且符合</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">终止服务条件的。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">3.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你违反本协议约定，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">依据违约条款或</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">规则终止本协议的。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">4.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">除上述情形外，因你多次违反</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">规则相关规定且情节严重，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">依据本协议或</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">规则停止向你提供服务的。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">5.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">其他</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">基于合理理由认为应当终止服务的情况。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">二</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【用户信息披露】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">本协议终止后，除法律有明确规定外，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">无义务向你或你指定的第三方披露你帐户中的任何信息。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">三</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">权利】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">本协议终止后，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">仍</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">享有下列权利：</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">1.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">继续保存你留存于</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">域内，根据法律法规的要求应当保存的信息；</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">2.</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">对于你过往的违约行为，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">仍可依据本协议向你追究违约责任。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">(</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">四</span><span
        style="font-family:inherit; font-size:9pt; font-weight:bold">)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【交易争议处理】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">本协议终止后，对于你在本协议存续期间产生的交易订单，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单；如交易相对方要求继续履行的，则你应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">十、知识产权</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(一)</span><span
        style="font-family:等线; font-size:10.5pt"> </span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">或其关联方拥有、使用、许可、控制的或者</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">或其关联方对之享有其他权利的所有知识产权、工业产权和专有权利，如你需要使用，可向</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">申请，非经</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">或其关联公司书面同意，任何人不得擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表在</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">网站上的程序或内容。你不得恶意申请和注册与</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">及</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">关联方相同或近似的商标、名称、标识、标志、微信公众号、域名、网页等，用于开展与</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">及</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">关联方实质相同或近似的业务。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(二)如你需使用著作权非</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">所有的内容的，你应获得具体内容的著作权所有者的合法授权才能使用，</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">如因你私自使用非自己所有的、且未经他人合法授权的著作、图片、档案、资讯、资料等内容的，由你自行承担责任，包括但不限于你自行对其侵权行为产生的纠纷进行处理，并对其侵权行为承担法律责任，且就由此给乙方造成的损失（包括但不限于乙方声誉的影响、乙方由此承担的连带责任（如有）等）进行赔偿。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">十一、通知与送达</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(一)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【通知实时关注】</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">根据你提供的任一联系方式送达各类通知，而此类通知的内容可能对你的权利义务产生重大的有利或不利影响，请你务必及时关注。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(二)【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">通知的送达】</span><span
        style="font-family:黑体; font-size:9pt">本协议签订或履行过程中，</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">向你指定的邮寄地址寄送的书面通知，在交邮后第五个自然日即视为送达。书面通知形式还包括但不限于在</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">后台</span><span
        style="font-family:黑体; font-size:9pt">公告、向你发送电子邮件、站内</span><span style="font-family:黑体; font-size:9pt">信、系统信息、手机短信、微信消息等电子方式，在采用电子方式进行通知的情况下，发送当日即视为送达。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(三)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【法律文书送达】</span><span
        style="font-family:黑体; font-size:9pt">对于在</span><span style="font-family:黑体; font-size:9pt">视客联盟</span><span
        style="font-family:黑体; font-size:9pt">域内因交易活动引起的任何纠纷，你同意司法机关及监管部门（包括但不限于人民法院、市场监督管理局）可以通过你在</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">注册</span><span
        style="font-family:黑体; font-size:9pt">账号</span><span
        style="font-family:黑体; font-size:9pt">，享受其他服务时提供的手机号码、</span><span
        style="font-family:黑体; font-size:9pt">微信号</span><span style="font-family:黑体; font-size:9pt">联系方式以及在注册</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">用户时生成的帐号进行送达，司法机关向上述联系方式发出法律文书即视为送达。你指定的邮寄地址为你的法定身份证地址、注册地址或你提供的有效联系地址。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(四)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【联系方式实时更新】</span><span
        style="font-family:黑体; font-size:9pt">你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不正确，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由你自行承担由此可能产生的法律后果。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt; font-weight:bold">十二、法律适用、管辖与其他</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(一)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【法律适用</span><span
        style="font-family:黑体; font-size:9pt">】本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(二)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【管辖法院</span><span
        style="font-family:黑体; font-size:9pt">】</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">你因使用</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务所产生及与</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">服务有关的争议，由</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">视客联盟</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold; text-decoration:underline">与你协商解决。协商不成时，任何一方均可向杭州互联网法院提起诉讼。</span>
    </p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(三)</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">【条款独立性</span><span
        style="font-family:黑体; font-size:9pt">】如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。</span></p>
    <p style="line-height:24.75pt; margin:0pt"><span style="font-family:黑体; font-size:9pt">(四)【</span><span
        style="font-family:黑体; font-size:9pt; font-weight:bold">权利放弃</span><span
        style="font-family:黑体; font-size:9pt">】本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。</span><span
        style="font-family:黑体; font-size:9pt">视客联盟</span><span style="font-family:黑体; font-size:9pt">未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。</span>
    </p>
    <p style="margin:0pt 0pt 12pt"><span style="font-family:inherit; font-size:12pt">&#xa0;</span></p>
    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span
        style="font-family:等线; font-size:10.5pt">&#xa0;</span></p></div>
</div>
</template>

<script>
export default {
  name: "user"
}
</script>

<style scoped>

</style>
