<template>
  <div  style="background: white;border-radius: 10px;padding: 20px;margin-top: 10px">

    <el-button size="small" type="primary" @click="doAdd">添加成员</el-button>

    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%;margin-top: 10px">
      <el-table-column
          align="center"
          prop="name"
          label="姓名">
      </el-table-column>
      <el-table-column
          align="center"
          prop="mobile_phone"
          label="手机号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="role_name"
          label="身份">
      </el-table-column>
      <el-table-column
          align="center"
          prop="create_time"
          label="添加时间">
      </el-table-column>

      <el-table-column align="center" label="操作" >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="edit(scope.row)">
            编辑
          </el-button>
          <el-button type="text" size="small" @click="delRow(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="40%">

      <div style="width: 100%">
        <el-form size="small" :label-position="labelPosition" label-width="100px" :model="dialogForm" :rules="rules" ref="ruleForm">
          <el-form-item label="身份:" prop="role_id">
            <el-select style="width: 100%" v-model="dialogForm.role_id" placeholder="请选择身份">
              <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名:" prop="name">
            <el-input v-model="dialogForm.name" clearable placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号:" prop="mobile_phone">
            <el-input v-model="dialogForm.mobile_phone" clearable placeholder="请输入登录手机号"></el-input>
          </el-form-item>
          <el-form-item label="密码:" prop="password">
            <el-input v-model="dialogForm.password" clearable placeholder="请输入密码" show-password></el-input>
          </el-form-item>
        </el-form>

      </div>

      <span slot="footer" class="dialog-footer">
       <el-button @click="dialogVisible = false">取 消</el-button>
       <el-button type="primary" @click="addInfo('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import {listInfo ,del ,add} from "../../api/merchantsMembers";
import { list} from "../../api/merchantsRole";

export default {
  name: "MemberManagement",
  data() {
    return {
      dialogTitle:"",
      labelPosition: 'right',
      dialogVisible: false,
      dialogForm:{
      },
      rules: {
        role_id: [
          { required: true, message: '请选择角色', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        mobile_phone: [
          { required: true, message: '请输入登录手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ]
      },
      queryParams:{
        serviceStates:0,
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading: false,
      tableData: [],
      roleList:[]
    }
  },
  mounted(){
    this.initRoleList()
    this.initData()
  },
  methods: {
    addInfo(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const regMobile = /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/ ;
          if(!(regMobile.test(this.dialogForm.mobile_phone))){
            this.$message.error("手机号码有误,请重新输入");
            return false;
          }
          add(this.dialogForm).then((res) => {
            if(res.code==0) {
              this.$message.success({
                duration: 1000,
                message: '操作成功'
              });
              this.dialogForm = {}
              this.initData()
              this.dialogVisible = false
            }else{
              this.$message.error({message: res.message});
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    doAdd(){
      this.dialogForm = {}
      this.dialogTitle = "新增成员"
      this.dialogVisible = true
    },
    edit(row){
      this.dialogForm = {}
      this.dialogTitle = "编辑成员信息"
      this.dialogForm = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    delRow(row){
      this.$confirm("确定删除 " + row.name + " 成员?", "删除成员", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        del({id : row.id}).then((res) => {
          if(res.code==0) {
            this.$message.success({
              duration: 1000,
              message: '操作成功'
            });
            this.initData()
          }else{
            this.$message.error({message: res.message});
          }
        });
      }).catch(() => {});
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      listInfo(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
    initRoleList(){
      this.listLoading = true
      let queryParams = {
        page: 1,
        limit: 999
      }
      list(queryParams).then((response) => {
        this.roleList = response.data.list;
      });
    },
  }
}
</script>

<style scoped>

</style>
