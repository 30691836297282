import service from "../../request";

export function tutorialInfo(query) {
    return service({
        url: '/api/merchants/videoJobTutorial/info',
        method: 'get',
        params: query
    })
}

export function tutorialUpdate(data) {
    return service({
        url: '/api/merchants/videoJobTutorial/info',
        method: 'post',
        data: data
    })
}
