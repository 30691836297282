import service from "../request";

export function getMediaProducingJob(query) {
    return service({
        url: '/api/creator/template/getMediaProducingJob',
        method: 'get',
        params: query
    })
}

export function isRecycleBin(query) {
    return service({
        url: '/api/creator/template/isRecycleBin',
        method: 'get',
        params: query
    })
}

export function submitMediaProducingJobRequest(data) {
    return service({
        url: '/api/creator/template/SubmitMediaProducingJobRequest',
        method: 'post',
        data: data
    })
}

export function submitMediaProducingJobRequestByMerchants(data) {
    return service({
        url: '/api/creator/template/submitMediaProducingJobRequestByMerchants',
        method: 'post',
        data: data
    })
}

export function submitMediaProducingJobRequestByConfig(data) {
    return service({
        url: '/api/creator/template/submitMediaProducingJobRequestByConfig',
        method: 'post',
        data: data
    })
}

export function submitJobRequestByConfigByMerchants(data) {
    return service({
        url: '/api/creator/template/submitJobRequestByConfigByMerchants',
        method: 'post',
        data: data
    })
}

export function submitBatch(data) {
    return service({
        url: '/api/creator/template/submitBatch',
        method: 'post',
        data: data
    })
}

export function submitBatchByMerchants(data) {
    return service({
        url: '/api/creator/template/submitBatchByMerchants',
        method: 'post',
        data: data
    })
}
