<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <div>
      自建商品
      <div style="float: right">
<!--        <el-button size="small" style="margin-top: 10px" @click="goBack">取消</el-button>-->
        <el-button size="small" style="margin-top: 10px" type="primary" @click="saveGoods('ruleForm')">保存</el-button>
        <el-button size="small" style="margin-top: 10px" type="primary" @click="saveShelves('ruleForm')">保存并上架</el-button>
      </div>
    </div>

    <div style="margin: 20px">
      <div style="color: rgba(56, 56, 56, 1);font-size: 14px;margin: 20px">基本信息</div>
      <div style="display: flex;justify-content: center">
        <iframe :src="trillGoodsUrl" style="height: 60vh;"></iframe>
        <el-form :model="goodsWarehousingForm" :rules="rules" ref="ruleForm" :label-position="labelPosition" label-width="160px">
          <el-form-item label="商品来源：" required>
            <el-radio-group v-model="goodsWarehousingForm.product_type" @change="productTypeChange">
              <el-radio :label="1">抖音</el-radio>
<!--              <el-radio :label="2">快手</el-radio>-->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商品链接：" required>
            <div>
              <div style="display: flex;align-items: center">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    v-model="trillGoodsUrl" placeholder="请粘贴商品链接" clearable></el-input>
                <el-button size="small" :loading="product_loading" style="margin-left: 10px" type="primary" @click="doTrillProductInfo">抓取商品信息</el-button>
              </div>
              <div style="display: flex;align-items: center;">
                <div style="color: red;font-size: 20px;">*</div>
                <div style="margin-left: 5px; color: rgba(166, 166, 166, 1);font-size: 12px;">输入商品链接,点击抓取即可更新商品</div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="佣金率：" required prop="commission_rate">
            <div style="display: flex;">
              <el-input size="small" style="width: 30%" v-model="goodsWarehousingForm.commission_rate" placeholder="请输入佣金率" clearable></el-input>
              <div style="margin-left: 10px">%</div>
            </div>
          </el-form-item>
<!--          <el-form-item size="small" label="公开佣金率：" required prop="open_commission_rate">-->
<!--            <div style="display: flex;">-->
<!--              <el-input size="small" style="width: 30%" v-model="goodsWarehousingForm.open_commission_rate" placeholder="请输入公开佣金率" clearable></el-input>-->
<!--              <div style="margin-left: 10px">% <span style="color: rgba(166, 166, 166, 1);font-size: 12px;">不填时，默认公开佣金率为1%</span></div>-->
<!--            </div>-->
<!--          </el-form-item>-->
          <el-form-item label="商品推广结束时间：" required prop="activity_end_time">
            <el-date-picker
                size="small"
                style="width: 50%"
                v-model="goodsWarehousingForm.activity_end_time"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="排序：" required prop="custom_sort">
            <div style="display: flex;">
              <el-input size="small" style="width: 30%"  v-model.number="goodsWarehousingForm.custom_sort" placeholder="请输入排序" clearable></el-input>
              <div style="margin-left: 10px"> <span style="color: rgba(166, 166, 166, 1);font-size: 12px;">数字越大排序越靠前，0为默认排序（按照上架时间倒叙排序）</span></div>
            </div>
          </el-form-item>

          <el-form-item label="商品分类：" required prop="classify_id">
            <el-select v-model="goodsWarehousingForm.classify_id" placeholder="请选择商品分类">
              <el-option
                  v-for="item in classifyOptions"
                  :key="item.id"
                  :label="item.classify_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

        </el-form>
      </div>
    </div>
  </div>
</template>

<script>

import {trillProductInfo, goodsWarehousing, ksProductInfo} from "../../api/trill_goods"
import { queryUsePage } from "../../api/product_classify";

export default {
  name: "add",
  data(){
    return {
      classifyOptions:[],
      product_loading:false,
      labelPosition: 'right' ,
      trillGoodsUrl: '',
      product_id: '',
      goodsWarehousingForm:{
        product_type:1,
        open_commission_rate:1,
        custom_sort: 0
      },
      rules:{
        commission_rate: [
          { required: true, message: '请填写佣金率', trigger: 'blur' }
        ],
        open_commission_rate: [
          { required: true, message: '请填写公开佣金率', trigger: 'blur' }
        ],
        activity_end_time: [
          { required: true, message: '请选择招商活动结束时间', trigger: 'blur' }
        ],
        custom_sort: [
          { required: true, message: '请填写排序', trigger: 'blur' },
          { type: 'number', message: '排序必须为数字值'}
        ],
        classify_id: [
          { required: true, message: '请选择分类', trigger: 'change' }
        ]
      }
    }
  },
  mounted(){
    this.initClassifyOptions()
  },
  methods:{
    productTypeChange(value){
      this.initClassifyOptions()
    },
    initClassifyOptions(){
      let queryParams ={
        plate: this.goodsWarehousingForm.product_type,
        page: 1,
        limit: 999999,
        sidx: "create_time",
        order: "desc"
      }
      queryUsePage(queryParams).then((res) => {
        this.classifyOptions = res.data.list;
      });
    },
    saveGoods(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('submit!!');
          if(this.product_id == ''){
            this.$message.error({message: '商品信息有误'});
            return
          }
          this.goodsWarehousingForm.product_id = this.product_id
          this.goodsWarehousingForm.item_status = 0
          goodsWarehousing({},this.goodsWarehousingForm).then(res=> {
            if(res.code==0) {
              this.$message.success({
                duration: 1000,
                message: '商品入库成功'
              });
            }else{
              this.$message.error({message: res.message});
            }
          })

          } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    saveShelves(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log('submit!!');
          if(this.product_id == ''){
            this.$message.error({message: '商品信息有误'});
            return
          }
          this.goodsWarehousingForm.product_id = this.product_id
          this.goodsWarehousingForm.item_status = 1
          goodsWarehousing({},this.goodsWarehousingForm).then(res=> {
            if(res.code==0) {
              this.$message.success({
                duration: 1000,
                message: '商品入库上架成功'
              });
            }else{
              this.$message.error({message: res.message});
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goBack(){
      this.$router.go(-1);
    },
    doTrillProductInfo(){
      if(this.trillGoodsUrl != ''){
        this.product_loading = true
        let body = {
          product_url: this.trillGoodsUrl
        }
        if(this.goodsWarehousingForm.product_type == 1){
          trillProductInfo({},body).then(res=> {
            this.product_loading = false
            if(res.code==0){
              this.product_id = res.data.productId
              this.$message.success({
                duration: 1000,
                message: '抖音商品信息获取成功'
              });
            }else{
              this.$message.error({message: '抖音商品链接有误'});
            }
          })
        }else{
          ksProductInfo({},body).then(res=> {
            this.product_loading = false
            if(res.code==0){
              this.product_id = res.data.productId
              this.$message.success({
                duration: 1000,
                message: '快手商品信息获取成功'
              });
            }else{
              this.$message.error({message: '快手商品链接有误'});
            }
          })
        }
      }else{
        this.$message.error({message: '请输入正确的商品链接'});
      }
    },
  }
}
</script>

<style scoped>

</style>
