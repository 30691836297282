<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div style="margin-top: 10px;text-align:left;">
      <el-input
          size="small"
          placeholder="微信昵称"
          v-model="queryParams.nick_name"
          clearable
          style="width: 160px;"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="微信手机号"
          v-model="queryParams.phone_number"
          clearable
          style="width: 160px;margin-left: 10px"
      >
      </el-input>

      <el-select size="small" clearable v-model="queryParams.user_label" placeholder="请选择成员标签" style="margin-left: 10px">
        <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label_name"
            :value="item.id">
        </el-option>
      </el-select>

      <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="search()"
      >搜索</el-button>
    </div>

    <el-table
        size="small"
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="基本信息"
          align="center"
          width="360"
      >
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <img :src="scope.row.avatar_url" style="width: 50px;height: 50px;border-radius: 50%" />
            <div style="display: flex;flex-direction: column;justify-content: space-around;text-align: left;margin-left: 10px">
              <div>{{scope.row.nick_name}}</div>
              <div>手机号: {{scope.row.phone_number}}</div>
              <div>openId: {{scope.row.vodm_wx_user_id}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="remark"
          label="成员标签"
          align="center">
        <template slot-scope="scope">
          <el-tag>{{scope.row.userLabel!=null ? scope.row.userLabel.label_name : '暂未设定'}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="balance"
          label="生成卡余额"
          align="center">
      </el-table-column>
      <el-table-column
          prop="useBalance"
          label="总消耗生成卡"
          align="center">
      </el-table-column>
      <el-table-column
          prop="update_time"
          label="加入时间"
          align="center"
          width="200">
      </el-table-column>
<!--      <el-table-column-->
<!--          prop="account_status"-->
<!--          label="是否禁用"-->
<!--          align="center">-->
<!--        <template slot-scope="scope">-->
<!--          <el-switch-->
<!--              disabled-->
<!--              v-model="scope.row.account_status"-->
<!--              active-color="#ff4949"-->
<!--              inactive-color="#13ce66">-->
<!--          </el-switch>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
      >
        <template slot-scope="scope">
          <div>
            <el-button  @click="handleUpdate(scope.row)" type="text" size="small"
            >修改</el-button>
            <el-button  @click="timeCard(scope.row)" type="text" size="small"
            >生成卡管理</el-button>
            <el-button  @click="userTimeCardResd(scope.row)" type="text" size="small"
            >生成卡记录</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog title="修改信息" :visible.sync="updateVisible" width="30%">
      <div>
        <el-form
            size="small"
            ref="form"
            :model="form"
            label-width="160px"
            :label-position="labelPosition"
            style="padding-right: 20px"
        >
          <el-form-item label="微信昵称/手机号:">
            <span style="">{{form.nick_name + " " + form.phone_number}}</span>
          </el-form-item>
          <el-form-item label="成员标签:">
            <el-select v-model="form.user_label" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.label_name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
<!--          <el-form-item label="是否禁用:">-->
<!--            <el-switch-->
<!--                v-model="form.account_status"-->
<!--                active-color="#ff4949"-->
<!--                inactive-color="#13ce66">-->
<!--            </el-switch>-->
<!--          </el-form-item>-->
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateSubmitForm" size="small">确 认</el-button>
        <el-button @click="updateCancel" size="small">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="生成卡充值/扣减" :visible.sync="timeCardStatus" width="40%"  :before-close="cancelSubmitTimeRow" style="text-align: center">
      <el-form ref="form" :model="timeRow" label-width="50px" size="small">
        <el-radio-group  v-model="timeRow.radio">
          <el-radio :label="1">充值</el-radio>
          <el-radio :label="2">扣减</el-radio>
        </el-radio-group>
        <el-form-item style="margin-top: 20px;" label="数量:">
          <el-input v-model="timeRow.quantity" type="number"  placeholder="请输入数量" clearable></el-input>
        </el-form-item>
        <el-form-item style="margin-top: 20px;" label="备注:">
          <el-input v-model="timeRow.details" placeholder="请输入备注" clearable></el-input>
        </el-form-item>
        <div>
          <el-button size="small" @click="cancelSubmitTimeRow()">取消</el-button>
          <el-button size="small" type="primary" @click="onSubmitTimeRow">确定</el-button>
        </div>
      </el-form>
    </el-dialog>

    <UserTimeCardResd style="width: 100%;height: 100%" ref="mychild"> </UserTimeCardResd>

  </div>
</template>

<script>

import { userList ,userUpdate ,wxUserLabelList ,userTimeCardByMerchants} from  "../../api/userManage";
import  UserTimeCardResd from "./UserTimeCardResd"

export default {
  name: "clip_user_list",
  components:{
    UserTimeCardResd
  },
  data(){
    return{
      timeRow:{
        radio : 1,
        quantity : 0,
        details : ""
      },
      timeCardStatus:false,
      options:[],
      labelPosition: 'right',
      form: {},
      updateVisible:false,
      queryParams:{
        page: 1,
        limit: 10,
        sidx : "create_time",
        order : "desc",
      },
      total:0,
      tableData: [],
    }
  },
  created() {
    this.initOptions()
    this.initData();
  },
  methods:{
    search(){
      this.queryParams.page = 1
      this.initData()
    },
    userTimeCardResd(row){
      this.$refs.mychild.childClick(row)
    },
    cancelSubmitTimeRow(){
      this.timeRow = {
        radio : 1,
        quantity : 0,
        details : ""
      }
      this.timeCardStatus = false
    },
    onSubmitTimeRow(){
      console.log(JSON.stringify(this.timeRow))
      if(this.timeRow.quantity<=0){
        this.$message.error("请输入正确的生成卡数量");
        return
      }
      userTimeCardByMerchants(this.timeRow).then((res) => {
        if(res.status == 1){
          this.timeRow = {
            radio : 1,
            quantity : 0,
            details : ""
          }
          this.timeCardStatus = false
          this.$message.success("操作成功")
          this.initData();
        }else {
          this.$message.error(res.message);
        }
      });

    },
    timeCard(row){
      this.timeRow.vodmUserId = row.vodm_wx_user_id
      this.timeCardStatus = true
    },
    initOptions(){
      let queryParams = {
        page: 1,
        limit: 999,
        sidx: "create_time",
        order: "desc",
      };
      wxUserLabelList(queryParams).then((response) => {
        this.options = response.data.list;
      });
    },
    updateSubmitForm(){
      console.log(JSON.stringify(this.form))
      userUpdate(this.form).then((res) => {
        if(res.status == 1){
          this.form = {}
          this.updateVisible = false
          this.$message.success("操作成功")
          this.initData();
        }else {
          this.$message.error(res.message);
        }
      });
    },
    updateCancel(){
      this.updateVisible = false
    },
    handleUpdate(row){
      this.form = row
      console.log(JSON.stringify(this.form))
      this.updateVisible = true
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.tableData = []
      this.initData();
    },
    initData(){
      userList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
      });
    },
  }
}
</script>

<style scoped>

</style>
