<template>
  <div class="menu_view">
    <div class="menu_info">
      <el-menu
          @select="handleSelect"
          :default-active="activeIndex"
          :unique-opened = true
          class="el-menu-vertical-demo">
        <div v-for="(item,index) in routes" :key="index">
          <el-menu-item :index="item.path"  :key="index"  v-if="item.isMenu && item.isSingleMenu">
            <template slot="title">
              <span>{{item.name}}</span>
            </template>
          </el-menu-item>

          <el-submenu :index="item.path"  :key="index" v-if="item.isMenu && !item.isSingleMenu">
            <template slot="title">
              <span>{{item.name}}</span>
            </template>
            <el-menu-item :index="childItem.path" v-for="(childItem,childIndex) in item.children" :key="childIndex"  v-show="childItem.isHidden != true">
              {{childItem.name}}
            </el-menu-item>
          </el-submenu>
        </div>
      </el-menu>
    </div>
  </div>
</template>

<script>

import routes from "../router/rulesCenter";
import {creatorUser, merchantsInfo ,cloudInfo} from "../api/merchantsUser";

export default {
  name: "RulesCenterMenuInfo",
  filters: {
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else if(fileSize < (1024 * 1024 * 1024 * 1024)){
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'TB';
      }
    },
    numberFormat: function (value) {
      return value.toFixed(2)
    },
    numFilterZeo (value) {
      let realVal = parseFloat(value).toFixed(0)
      return Number(realVal)
    }
  },
  data(){
    return{
      userInfo:{},
      creatorUser:{},
      percentage:0,
      activeIndex:'',
      routes:routes,
      cloudInfo:{
        videoCloudSize: 0,
        materialCloudSize: 0,
        percentage: 0,
        useCloudSize: 0,
        cloudSize: 0
      }
    }
  },
  mounted() {
    this.initCloudInfo()
    this.initBaseData()
    console.log("routes:",JSON.stringify(this.routes))
    this.activeIndex=this.$route.path
  },
  methods:{
    initCloudInfo(){
      cloudInfo().then((res)=>{
        this.cloudInfo = res.data
      })
    },
    my_cloud_info(){
      this.$router.push("/my_cloud_info");
    },
    initBaseData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
      creatorUser().then((res)=>{
        this.creatorUser = res.data
        this.percentage = res.data.storageSpaceInfo.gFileSize/ res.data.storageSpaceInfo.storageSpace  * 100
      })
    },
    handleSelect(index, indexPath) {
      console.log(index, indexPath);
      this.$router.push(index);
    }
  }
}
</script>

<style scoped>

.menu_view{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

</style>
