<template>
  <!-- 系统公告 -->
  <div class="message">
    <div v-if="flage === 1">
      <el-button type="primary" size="small" @click="addMessage()"
        >新建公告</el-button
      >

      <el-tabs v-model="tabName">
        <el-tab-pane label="已推送" :name="'1'"></el-tab-pane>
        <!-- <el-tab-pane label="等待推送" :name="'2'"></el-tab-pane> -->
        <el-tab-pane label="草稿箱" :name="'3'"></el-tab-pane>
      </el-tabs>
      <component
        :is="componentName"
        @flageFn="flageFn"
        @addMessage="addMessage"
      ></component>
    </div>

    <!-- 消息详情 -->
    <div v-else-if="flage === 2">
      <DetailPage @bagck="bagck" :MessagePusId="MessagePusId"></DetailPage>
    </div>

    <!-- 消息新建 -->
    <div v-else>
      <AddMessage @bagck="bagck" :editItem="editItem"></AddMessage>
    </div>
  </div>
</template>
 
<script>
import Pushed from "./system_component/pushed.vue";
import Drafts from "./system_component/drafts.vue";
import DetailPage from "./system_component/component/index.vue";
import AddMessage from "./system_component/component/addMessage.vue";

export default {
  name: "push_message",
  components: {
    Pushed,
    Drafts,
    DetailPage,
    AddMessage,
  },

  data() {
    return {
      tabName: "1",
      flage: 1,
      MessagePusId: "", //详情id
      editItem: "", //编辑当前行内容
    };
  },
  computed: {
    componentName() {
      if (this.tabName === "1") {
        return "Pushed";
      }  else {
        return "Drafts";
      }
    },
  },

  created() {},
  methods: {
    addMessage(item) {
      this.editItem = item; //编辑当前行内容
      this.flage = 3;
    },
    // 返回
    bagck() {
      this.flage = 1;
    },

    // 详情
    flageFn(MessagePusId) {
      console.log("chufa", MessagePusId);
      this.MessagePusId = MessagePusId;
      this.flage = 2;
    },
  },
};
</script>

<style scoped>
.message {
  padding: 10px;
  margin: 10px;
  background-color: #fff;
}
</style>