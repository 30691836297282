<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <el-tabs
      v-model="queryParams.audit_status"
      @tab-click="handleClick"
      style="width: 100%"
    >
      <el-tab-pane
        v-for="item in tabs"
        :key="item.value"
        :label="item.label"
        :name="item.value + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <el-table
      border
      size="small"
      :data="tableData"
      style="width: 100%; margin-top: 15px"
      :max-height="autoHeight"
    >
      <el-table-column label="抖音昵称" prop="nickname" width="220">
      </el-table-column>

      <el-table-column label="抖音信息" min-width="220">
        <template slot-scope="scope">
          <div>抖音账号：{{ scope.row.trill_account }}</div>
          <div>抖音手机号：{{ scope.row.trill_phone }}</div>
          <div>抖音uid：{{ scope.row.trill_uid }}</div>
          <div>抖音openId：{{ scope.row.open_id }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="fans_number" label="IP信息" min-width="220">
        <template slot-scope="scope">
          <div style="display: flex; flex-direction: row; align-items: center">
            <el-image
              style="width: 50px; height: 50px; border-radius: 50%"
              :src="scope.row.classify_icon"
              fit="cover"
            >
            </el-image>
            <!-- {{ scope.row.classify_name }} -->
            <span style="margin-left: 5px">{{ scope.row.classify_name }}</span>
          </div>
        </template>
      </el-table-column>

      <!-- fixed="right" -->
      <el-table-column
        align="center"
        label="操作"
        width="160"
        v-if="queryParams.audit_status == 0 || queryParams.audit_status == 4"
      >
        <template slot-scope="scope">
          <el-button
            v-if="queryParams.audit_status == 0"
            @click.native.prevent="doAuditDismiss(scope.row)"
            type="text"
            size="small"
          >
            审核通过
          </el-button>

          <el-button
            v-if="queryParams.audit_status == 0"
            @click.native.prevent="doAuditPass(scope.row)"
            type="text"
            size="small"
          >
            审核驳回
          </el-button>

          <el-button
            v-if="queryParams.audit_status == 4"
            @click.native.prevent="doAuditCancel(scope.row)"
            type="text"
            size="small"
          >
            确定取消
          </el-button>

          <el-button
            v-if="queryParams.audit_status == 4"
            @click.native.prevent="cancelReject(scope.row)"
            type="text"
            size="small"
          >
            取消驳回
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px; margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="queryParams.page"
            :page-size="queryParams.limit"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog title="审核驳回" :visible.sync="rejectDialogVisible" width="30%">
      <el-input
        type="textarea"
        :rows="6"
        maxlength="100"
        show-word-limit
        placeholder="请输入驳回原因"
        v-model="reason"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doReject">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import {
  trillMcnMarkList,
  cancelSure,
  applyReject,
  applySure,
  cancelReject,
} from "@/api/mcn_identification.js";
export default {
  name: "mcn_identification",
  components: {},
  props: {},
  data() {
    return {
      autoHeight: "",
      rejectDialogVisible: false,
      tabs: [
        { value: 0, label: "待审核" },
        { value: 1, label: "已通过" },
        { value: 2, label: "已驳回" },
        { value: 3, label: "待确认" },
        { value: 4, label: "取消申请中" },
        { value: -1, label: "已取消" },
      ],
      tableData: [],
      reason: "", //驳回理由
      open_id: "",
      queryParams: {
        audit_status: 0,
        page: 1,
        limit: 10,
        // sidx: "tmi.applay_time",
        // order: "asc",
      },
      total: 0,
    };
  },

  created() {
    this.initData();
  },
  methods: {
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 260 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },

    cancelReject(row) {
      let open_id = row.open_id;
      this.$confirm("确认取消驳回?", "取消驳回", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancelReject({ open_id: open_id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.initData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    handleClick() {
      this.tableData = [];
      this.queryParams.page = 1;
      console.log(this.queryParams.audit_status);
      this.initData();
    },
    // 通过
    doAuditDismiss(row) {
      let open_id = row.open_id;
      this.$confirm("确认通过?", "确定通过", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          applySure({ open_id: open_id }).then((res) => {
            console.log(res);
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.initData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    // 驳回
    doAuditPass(row) {
      this.open_id = row.open_id;
      this.reason="请完善四件套,发布6条ip相关视频后再提交申请", //驳回理由
      this.rejectDialogVisible = true;
    },

    doReject() {
      if (this.reason == null || this.reason == "") {
        this.$message.error("请输入有效的驳回理由");
        return;
      }

      applyReject({ open_id: this.open_id, reason: this.reason }).then(
        (res) => {
          console.log(res);
          if (res.code == "0") {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.initData();
            this.rejectDialogVisible = false;
          } else {
            this.$message.error({ message: res.message });
          }
        }
      );
    },

    // 取消通过
    doAuditCancel(row) {
      let open_id = row.open_id;
      this.$confirm("确认取消通过?", "取消通过", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          cancelSure({ open_id: open_id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.initData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    initData() {
      this.listLoading = true;
      trillMcnMarkList(this.queryParams).then((res) => {
        console.log(res);
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
  },
};
</script>

<style scoped lang='scss'>
</style>