import service from "../request";

export function list(query) {
    return service({
        url: '/api/merchants/productMaterial/queryPage',
        method: 'get',
        params: query
    })
}

export function saveOrUpdate(data) {
    return service({
        url: '/api/merchants/productMaterial/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function del(data) {
    return service({
        url: '/api/merchants/productMaterial/del',
        method: 'post',
        data: data
    })
}
