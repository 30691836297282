<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <el-tabs v-model="activeName">
      <el-tab-pane label="IP名额" name="first">
        <LimitList ref="limitList" ></LimitList>
      </el-tab-pane>
      <el-tab-pane label="调整记录" name="second">
        <LimitListRes ref="limitListRes" ></LimitListRes>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LimitList from "../merchants_components/MerchantLimitList.vue";
import LimitListRes from "../merchants_components/MerchantLimitListRes.vue";
export default {
  name: "ClassifyLimit",
  components: {
    LimitList, LimitListRes
  },
  data(){
    return{
      activeName: 'first'
    }
  },
  mounted(){
  },
  methods:{

  }
}
</script>

<style scoped>
/deep/ .el-dialog__body{
  padding-top: 0px;
}
</style>
