<template>
  <!-- App 钱包提现 -->
  <div style="padding: 10px; margin: 10px">
    <div class="headManey flex-between">
      <div class="head-left flex-left">
        <div class="left1 left-div">
          <div class="fs14">待审核总金额(元)</div>
          <div class="fs18">{{ list[0].value }}</div>
        </div>
        <div class="left2 left-div">
          <div class="fs14">已提现总金额(元)</div>
          <div class="fs18">{{ list[1].value }}</div>
        </div>
      </div>
      <el-button class="btn1" type="primary" size="small" @click="setmoney()"
        >提现设置</el-button
      >
    </div>

    <div class="my-body">
      <!-- @tab-click="handleClick()" -->
      <el-tabs v-model="tabName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in stautList"
          :key="index"
          :label="item.label"
          :name="item.value.toString()"
        ></el-tab-pane>
      </el-tabs>

      <component :is="componentName" :getTab="withdrawList"></component>
    </div>

    <el-dialog
      :before-close="off"
      title="提现设置"
      :visible.sync="depositDialogVisible"
      width="70%"
      center
    >
      <el-form
        size="small"
        :model="setAdd.config_value"
        ref="setAdd.config_value"
        class="demo-ruleForm"
      >
        <span>自动审核打款</span>
        <el-switch
          class="mag15"
          v-model="setAdd.config_value.autoDebit"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>

        <div class="flex-left">
          <span>单笔最高可申请提现金额为</span>
          <el-form-item
            class="mag15"
            prop="singleMax"
            :rules="{
              required: true,
              message: '请输入金额',
              trigger: 'change',
            }"
          >
            <el-input
              style="width: 155px"
              clearable
              size="small "
              v-model="setAdd.config_value.singleMax"
              placeholder="请输入金额"
            ></el-input>
          </el-form-item>
          <span>元</span>
        </div>

        <div class="flex-left">
          <span>每次提现系统将会收取</span>
          <el-form-item
            class="mag15"
            prop="serChaRatio"
            :rules="{
              required: true,
              message: '请输入服务费',
              trigger: 'change',
            }"
          >
            <el-input
              style="width: 120px"
              clearable
              size="small "
              v-model="setAdd.config_value.serChaRatio"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <span>%的提现服务费</span>
        </div>

        <div style="display: flex">
          <span>可提现时间：</span>
          <div>
            <el-form-item>
              <el-radio-group v-model="setAdd.config_value.extTimeType">
                <el-radio class="mag_bt10" :label="1">
                  <span>每月</span>

                  <el-input
                    class="magzy"
                    style="width: 120px"
                    clearable
                    size="small "
                    v-model="setAdd.config_value.beforeExtTime"
                    placeholder="请输入内容"
                  ></el-input>

                  <span>号前可发起提现</span>
                </el-radio>
                <br />

                <el-radio class="mag_bt10" :label="2">
                  <span>每月</span>

                  <el-input
                    class="magzy"
                    style="width: 120px"
                    clearable
                    size="small "
                    v-model="setAdd.config_value.timeStart"
                    placeholder="请输入内容"
                  ></el-input>

                  <span>至</span>

                  <el-input
                    class="magzy"
                    style="width: 120px"
                    clearable
                    size="small "
                    v-model="setAdd.config_value.timeEnd"
                    placeholder="请输入内容"
                  ></el-input>
                  <span>号可发起提现</span>
                </el-radio>
                <br />
                <el-radio :label="3">随时可以发起提现</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <div>
        提现规则
        <div
          ref="editor"
          @input="editorChange"
          style="margin-top: 20px; width: 100%"
        />
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="off()">取 消</el-button>
        <el-button type="primary" @click="update()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  withdrawListState,
  withdrawStatisticsApi,
  appMoneyApi,
  updateApi,
} from "@/api/app_wallet_withdrawal.js";

import ReviewedComp from "./component/reviewed";
import remitComp from "./component/remit";
import frustratedComp from "./component/frustrated.vue";
import succeedComp from "./component/succeed.vue";
import turnDownComp from "./component/turn_down.vue";

import { getOssAccessInfo } from "@/api/common.js";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");
import E from "wangeditor";

export default {
  name: "appWalletWithdrawal",

  components: {
    ReviewedComp,
    remitComp,
    frustratedComp,
    succeedComp,
    turnDownComp,
  },

  data() {
    return {
      editor: "",
      curitem: null,
      depositDialogVisible: false,
      queryParams: {
        active: 0,
      },
      radio: "1",
      tabName: 0,
      // 弹层
      setAdd: {
        config_value: {
          autoDebit: "", //自动审核打款
          singleMax: "",
          serChaRatio: "",
          beforeExtTime: "",
          timeStart: "",
          timeEnd: "",
          extTimeType: "",
          rule: "",
        },
      },

      bannerForm: {
        img_url: "",
        page_name: "",
        platform: 1,
        custom_sort: 0,
        whether_show: false,
        plate_type: 1,
        banner_type: 1,
      },

      stautList: [],
      list: [
        {
          value: 0,
        },
        {
          value: 0,
        },
      ],
    };
  },

  computed: {
    componentName() {
      if (this.tabName === "0") {
        return "ReviewedComp";
      } else if (this.tabName === "1") {
        console.log("0saljdf");
        return "remitComp";
      } else if (this.tabName === "2") {
        return "frustratedComp";
      } else if (this.tabName === "3") {
        return "succeedComp";
      } else {
        return "turnDownComp";
      }
    },
  },

  created() {
    this.withdrawList();
    this.withdrawStatistics();
  },

  methods: {
    off() {
      this.close();
    },

    close() {
      this.depositDialogVisible = false;
      this.editor.destroy();
    },

    // 弹层确定
    async update() {
      const res = await updateApi({ ...this.setAdd });
      console.log(res);
      if (res.status == 1) {
        this.close();
        this.$message.success("提现设置成功");
      } else {
        this.close();
        this.$message.error(res.message);
      }
    },

    // 弹层+富文本
    setmoney() {
      this.depositDialogVisible = true;
      this.initData();
      this.appMoney();

      // this.$nextTick(() => {
      //   if (this.editor == null) {
      //     this.initData();
      //   } else {
      //     editor.destroy(); //这里做了一次判断，判断编辑器是否被创建，如果创建了就先销毁。
      //     this.initData();
      //   }
      // });
    },

    // 弹层内容接口
    async appMoney() {
      const res = await appMoneyApi();
      console.log(res);
      // .config_value;
      this.setAdd = res.data;
    },

    // 第一行数据
    async withdrawStatistics() {
      const res = await withdrawStatisticsApi();
      // console.log(res.data.list);
      this.list = res.data.list;
      // if (this.list == null) {
      // }
    },

    handleClick(tab, event) {
      // console.log(tab.index);
      this.tabName = tab.index;
    },

    // 头部标签
    async withdrawList() {
      const res = await withdrawListState();
      console.log("头部标签", res);
      const resList = res.data;
      this.stautList = resList;
    },

    // 富文本
    initData(id) {
      let _this = this;

      setTimeout(() => {
        const editor = new E(_this.$refs.editor);
        this.editor = editor;
        editor.config.zIndex = 10;
        editor.config.customUploadImg = function (files, insert) {
          files.forEach((image) => {
            getOssAccessInfo().then((res) => {
              const client = new OSS({
                region: "oss-cn-hangzhou",
                accessKeyId: res.data.access_key_id,
                accessKeySecret: res.data.access_key_secret,
                bucket: "cjfx-res",
                stsToken: res.data.security_token,
              });
              console.log(client);
              let randomStr = "";
              for (var i = 0; i < 9; i++) {
                randomStr =
                  randomStr + Math.floor(Math.random() * 10).toString();
              }
              let fileName =
                "/merchantscenter/banner/" +
                Sha1(
                  _this.$store.state.token + new Date().getTime() + randomStr
                ) +
                image.type;
              console.log(fileName);
              client.put(fileName, image).then((res) => {
                console.log(JSON.stringify(res));
                let imgUrl =
                  "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
                insert(imgUrl);
              });
            });
          });
        };
        editor.config.onchange = (html) => {
          this.setAdd.config_value.rule = html; //富文本内容
        };
        editor.create();
        editor.txt.html(this.setAdd.config_value.rule);
      }, 100);
    },

    editorChange(e) {
      this.htmlContent = e.target.innerHTML;
    },
  },
};
</script>

<style scoped lang="scss" >
.magzy {
  margin: 0 10px;
}
.mag_bt10 {
  margin-bottom: 10px;
}
.mag15 {
  margin: 9px 15px;
}

.fs14 {
  font-size: 14px;
}

.fs18 {
  font-size: 18px;
  line-height: 35px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headManey {
  // background-color: pink;

  .head-left {
    width: 50%;
    .left-div {
      height: 80px;
      width: 250px;
      min-width: 250px;
      font-size: 14px;
      padding: 15px 20px;
      border-radius: 5px;
      margin-right: 12px;
      box-sizing: border-box;
      color: #fff;
    }

    .left1 {
      background: linear-gradient(
        90deg,
        rgba(16, 177, 254, 1) 0%,
        rgba(9, 186, 255, 1) 100%
      );
    }
    .left2 {
      background: linear-gradient(
        90deg,
        rgba(13, 193, 217, 1) 0%,
        rgba(0, 213, 224, 1) 100%
      );
    }
  }
}

.my-body {
  height: 100%;
  margin-top: 10px;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #fff;
  background-color: #fff;
}

// tabs
.el-tabs ::v-deep .el-tabs__nav-wrap::after {
  bottom: -2px;
}
::v-deep .el-tabs__content {
  overflow: visible;
}
::v-deep .el-tabs__item {
  color: #666666;
}

// 选中状态
::v-deep .el-tabs__item.is-active {
  color: #0077ff;
  font-size: 16px;
  font-weight: 600;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}

// 查询按钮
.btn1 {
  background-color: #0077ff;

  border-color: #0077ff;
  font-size: 14px;
}
.btn1:hover {
  background: #66b1ff;
  color: #fff;
  border-color: #66b1ff;
}
</style>
