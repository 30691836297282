<template>
<div class="content">
  <div class="head-info">
    <img src="../../assets/head_img.jpg" class="head_img" />
    <span class="app_head_name">商户后台</span>
  </div>
  <div class="options">
    <div class="tips">请选择您要进入的商户后台</div>
    <div class="item" v-for="(item,index) in LOGIN_OPTION" :key="index" @click="choseItem(item)">
      <div>
        <img :src="item.headPortrait" class="item_img" />
        <span class="item_name">{{item.name}}</span>
      </div>
      <i class="el-icon-arrow-right item_icon"></i>
    </div>
  </div>
</div>
</template>

<script>

import { mapState } from 'vuex'
import {mapMutations} from 'vuex';
import { optionLogin } from "../../api/merchantsUser.js"

export default {
  name: "LoginOptions",
  data(){
    return{
    }
  },
  methods:{
    ...mapMutations(['INIT_DATA']),
    choseItem(item){
      optionLogin(item).then(res=>{
        console.log(JSON.stringify(res))
        if (res.code == "0") {
          this.INIT_DATA(res.data)
        }else{
          this.$message.error(res.message);
        }
      })
    }
  },
  computed: {
    ...mapState(['LOGIN_OPTION'])
  }
}
</script>

<style scoped>

.content{
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.head-info{
  padding: 20px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 1);
}

.head_img{
  height: 36px;
}

.app_head_name{
  margin-left: 20px;
  font-size: 16px;
  color: black;
}


.options{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(246, 246, 246, 1);
  flex: 1;
}

.tips{
  font-size: 24px;
  font-weight: 700;
  color: rgba(56, 56, 56, 1);
  margin-top: 30px;
}

.item{
  cursor: pointer;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  margin-top: 20px;
  width: 560px;
  height: 106px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.item:hover{
  border: 1px solid rgba(0, 119, 255, 1);
}

.item:hover > .item_icon{
  color: rgba(0, 119, 255, 1);
}

.item_img{
  width: 46px;
  height: 46px;
}

.item_name{
  margin-left: 20px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
}

</style>
