import Layout from "../views/layout/Layout";
import Home from "../views/home/newHome";
import homeInfoCustom from "../views/home/homeInfoCustom";
const template = [
    {
        path:"/homeInfo",
        name:'首页',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/home.png',
        component: Layout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/homeInfo",
                name:'首页',
                component: Home
            },
            {
                path:"/homeInfoCustom",
                name:'首页',
                component: homeInfoCustom,
                isHidden : true,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:false,
                        showUserInfo:false,
                        menu_item_active: 'home'
                    }
                }
            }
        ]
    }
]

export default template;
