import service from '../request'

export function trillProductOneInfo(query,data) {
    return service({
        url: '/api/merchants/trillProduct/oneInfo',
        method: 'get',
        params: query,
        data: data
    })
}

export function trillProductInfo(query,data) {
    return service({
        url: '/api/merchants/trillProduct/info',
        method: 'post',
        params: query,
        data: data
    })
}

export function ksProductInfo(query,data) {
    return service({
        url: '/api/merchants/trillProduct/qsInfo',
        method: 'post',
        params: query,
        data: data
    })
}


export function goodsWarehousing(query,data) {
    return service({
        url: '/api/merchants/trillProduct/goodsWarehousing',
        method: 'post',
        params: query,
        data: data
    })
}


export function trillProductList(query) {
    return service({
        url: '/api/merchants/trillProduct/list',
        method: 'get',
        params: query
    })
}

export function ipGoodsList(query) {
    return service({
        url: '/api/merchants/trillProduct/ipList',
        method: 'get',
        params: query
    })
}

export function ipGoodsAllList(query) {
    return service({
        url: '/api/merchants/trillProduct/allList',
        method: 'get',
        params: query
    })
}

export function trillProductDel(query) {
    return service({
        url: '/api/merchants/trillProduct/del',
        method: 'get',
        params: query
    })
}

export function productState(query) {
    return service({
        url: '/api/merchants/trillProduct/productState',
        method: 'get',
        params: query
    })
}

export function trillProductUpdate(query,data) {
    return service({
        url: '/api/merchants/trillProduct/update',
        method: 'post',
        params: query,
        data: data
    })
}

export function batchShelf(data) {
    return service({
        url: '/api/merchants/trillProduct/batchShelf',
        method: 'post',
        data: data
    })
}

export function batchOffshelf(data) {
    return service({
        url: '/api/merchants/trillProduct/batchOffshelf',
        method: 'post',
        data: data
    })
}


export function batchDel(data) {
    return service({
        url: '/api/merchants/trillProduct/batchDel',
        method: 'post',
        data: data
    })
}
