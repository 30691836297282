import { render, staticRenderFns } from "./clip_tem.vue?vue&type=template&id=21df3437&scoped=true"
import script from "./clip_tem.vue?vue&type=script&lang=js"
export * from "./clip_tem.vue?vue&type=script&lang=js"
import style0 from "./clip_tem.vue?vue&type=style&index=0&id=21df3437&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21df3437",
  null
  
)

export default component.exports