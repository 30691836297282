<template>
  <div>
    <div class="info_head">
      <div class="info_head_title">结算后退款订单</div>
      <div style="margin-left: 20px">
        <div style="display: flex;align-items: center;line-height: 40px">
          <el-upload
              class="upload-demo"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="upload"
          >
            <el-button size="small" type="primary">上传</el-button>
          </el-upload>
          <div class="template" @click="downloadTemplate">下载结算后退款订单文件模版</div>
        </div>
        <div class="tips">请下载模板，按照模板要求填写结算后退款订单信息，并上传。</div>
      </div>
    </div>
    <el-divider></el-divider>
    <TrillRefundOrdersList ref="child"/>
    <ElectricitySituation ref="renewal"></ElectricitySituation>
  </div>
</template>

<script>
import {trillRefundOrdersUpload} from "../../api/easyExcel";
import TrillRefundOrdersList from "./trillRefund_orders_list";
import {electricityInfo} from "../../api/electricity_situation";
import ElectricitySituation from "../../components/electricity_situation";

export default {
  components: {
    TrillRefundOrdersList,
    ElectricitySituation
  },
  name: "trillRefund_orders",
  methods:{
    upload(res){
      let newFile = res.file
      let formData = new FormData()
      formData.append('file', newFile)
      trillRefundOrdersUpload(formData).then((res) => {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '文件上传成功'
          });
          this.$refs.child.initData();
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res))
      console.log(JSON.stringify(file))
    },
    beforeAvatarUpload(file) {
      return new Promise((resolve,reject) => {
        electricityInfo({}).then((res) => {
          let data = res.data
          console.log(JSON.stringify(data))
          let is_expire = data.is_expire
          if(is_expire == false){
            console.log("fileType:" + file.type)
            const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (!isExcel) {
              this.$message.error("上传文件非模板格式!");
              return reject(false)
            }
            return resolve(true)
          }else{
            this.$confirm("电商MCN系统到期，请续费!", "提示", {
              confirmButtonText: "去续费",
              cancelButtonText: "取消",
              type: "warning",
            }).then(() => {
              this.$refs.renewal.show(2)
            }).catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              })
            })
          }
        });
      })
    },
    downloadTemplate(){
      window.open("https://merchantscenter.paitk.com/excel_template/%E7%BB%93%E7%AE%97%E5%90%8E%E9%80%80%E6%AC%BE%E8%AE%A2%E5%8D%95%E6%A8%A1%E7%89%88.xlsx",'_blank');
    }
  }
}
</script>

<style scoped>
.info_head{
  display: flex;
}

.info_head_title{
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(56, 56, 56, 1);
  vertical-align: center;
}

.template{
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 119, 255, 1);
}

.tips{
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}
</style>
