<template>
  <div>
    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%">
      <el-table-column
          align="center"
          prop="id"
          label="开票申请单号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="ticket_type"
          label="发票类型">
        <template slot-scope="scope">
          <span v-if="scope.row.ticket_type === 1">增值税电子普通发票</span>
          <span v-if="scope.row.ticket_type === 2">增值税电子专用发票</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="company_name"
          label="发票抬头">
      </el-table-column>
      <el-table-column
          align="center"
          prop="credit_code"
          label="公司税号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="total_amount"
          label="申请总金额">
        <template slot-scope="scope">
          ¥ {{scope.row.total_amount | numFilter }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="create_time"
          label="申请时间">
      </el-table-column>
      <el-table-column
          align="center"
          prop="order_num"
          label="内含订单数">
      </el-table-column>
      <el-table-column align="center" label="操作" >
        <template slot-scope="scope">
          <el-button type="text" size="small" v-if="serviceStates === 0" @click="seeTicket(scope.row)">
            查看详情
          </el-button>
          <el-button type="text" size="small" v-if="serviceStates === 1" @click="seeTicket(scope.row)">
            查看发票
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        size="small"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        @opened="opened"
        width="80%">
      <div>
        <span class="tips">开票信息</span>
        <div style="display: flex">
          <div style="flex: 1">
            <el-form size="small" ref="form" :model="dialogForm" label-width="125px">
              <el-form-item label="开票申请单号:">
                {{dialogForm.id}}
              </el-form-item>
              <el-form-item label="发票类型:">
                <span v-if="dialogForm.ticket_type === 1">增值税电子普通发票</span>
                <span v-if="dialogForm.ticket_type === 2">增值税电子专用发票</span>
              </el-form-item>
              <el-form-item label="开票金额:">
                {{dialogForm.total_amount | numFilter}}
              </el-form-item>
              <el-form-item label="发票抬头:">
                {{dialogForm.company_name}}
              </el-form-item>
              <el-form-item label="公司税号:">
                {{dialogForm.credit_code}}
              </el-form-item>
              <el-form-item label="公司地址:">
                {{dialogForm.company_address}}
              </el-form-item>
              <el-form-item label="公司电话:">
                {{dialogForm.company_phone}}
              </el-form-item>
              <el-form-item label="公司开户行:">
                {{dialogForm.company_open_bank}}
              </el-form-item>
              <el-form-item label="开户行账号:">
                {{dialogForm.company_open_bank_no}}
              </el-form-item>
              <el-form-item label="申请时间:">
                {{dialogForm.create_time}}
              </el-form-item>
            </el-form>
          </div>
          <div style="flex: 1;text-align: right">
            <el-button size="small" type="primary" style="margin-top: 10px" v-if="serviceStates === 1" @click="downTicket()">下载发票</el-button>
          </div>
        </div>
        <span class="tips">订单信息 ({{dialogForm.order_num}})</span>
        <TicketOrdersInfo ref="child" :recId="this.dialogForm.id"></TicketOrdersInfo>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { ticketOrderRecList } from "../../../api/merchants_ticket_order";
import TicketOrdersInfo  from "./TicketOrdersInfo.vue"


export default {
  name: "TicketOrderRecList",
  components:{
    TicketOrdersInfo
  },
  props: ['serviceStates'],
  filters:{
    numFilter (value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    },
  },
  data() {
    return {
      dialogTitle:'开票详情',
      dialogVisible: false,
      dialogForm: {},
      queryParams:{
        serviceStates: this.serviceStates,
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading:false,
      tableData: []
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    downTicket(){
      window.open(this.dialogForm.ticket_url, "_blank");
    },
    seeTicket(row){
        if(this.serviceStates === 0){
          this.dialogTitle = "查看详情"
        } else if (this.serviceStates === 1)
        {
          this.dialogTitle = "下载发票"
        }
        this.dialogForm = row
        this.dialogVisible = true
    },
    opened(){
      console.log("dialogOpen")
      this.$refs.child.initData(this.dialogForm.id);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      ticketOrderRecList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

/deep/ .el-dialog__body{
  padding-top: 0px;
}

.el-form-item{
  margin: 0;
}
</style>
