<template>
  <div v-loading="loading">
    <el-form
      size="small"
      :model="formInline"
      class="demo-form-inline"
      :inline="true"
    >
      <el-form-item>
        <el-input
          clearable
          style="width: 320px; margin-right: 10px"
          v-model="formInline.messageTitle"
          placeholder="请输入标题"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-select v-model="formInline.messageType" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-button class="btn1" type="primary" size="mini" @click="Submit()"
        >查询</el-button
      >
    </el-form>

    <!-- 表格 -->
    <div class="my-table" v-for="item in list" :key="item.android_message_id">
      <div class="flex-between">
        <div class="flex-left">
          <div class="fs14">{{ item.message_title }}</div>
          <div class="stu">{{ item.message_type === 1 ? "系统消息" : "" }}</div>
        </div>
        <!-- #default="{ row }" -->

        <div>
          <el-button
            class="btn1"
            type="primary"
            size="mini"
            @click="look(item.message_push_id)"
            >查看详情</el-button
          >

          <el-button
            class="btn1"
            type="danger"
            size="mini"
            @click="offMasg(item.message_push_id)"
            >取消推送</el-button
          >
        </div>
      </div>
      <div class="fs12 col_a6">推送时间：{{ item.notice_time }}</div>
      <div class="fs12 col_66">
        {{ item.message_content }}
      </div>
    </div>

    <!-- a分页 -->
    <div class="page">
      <div class="box" />
      <div class="box" />
      <div class="box box-right">
        <el-pagination
          class="page"
          background
          :current-page="formInline.pageNum"
          :page-sizes="[10, 20, 30]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formInline.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- b分页 -->
  </div>
</template>
 
<script>
import { messageList, cancelPushApi } from "@/api/push_message.js";

export default {
  name: "await",
  components: {},
  props: {},
  data() {
    return {
      loading: true,
      options: [
        {
          value: "1",
          label: "系统消息",
        },
      ],

      // 查询
      formInline: {
        message_title: "",
        pushStatus: "1", //推送状态  0：草稿箱  1：等待推送   2：已推送
        messageType: "1", //消息类型 1：系统消息
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },

      list: [],
    };
  },

  created() {
    this.getList();
  },

  methods: {
    //  取消推送
    async offMasg(id) {
      const res = await cancelPushApi({ message_push_id: id });
      if (res.status == 1) {
        this.getList();
        this.$message.success("取消成功");
      } else {
        this.$message.error(res.message);
      }
    },

    async getList() {
      this.loading = true
      const res = await messageList({ ...this.formInline });
      this.list = res.data.list;
      this.formInline.total = res.data.total_count;
      console.log("消息列表", res);
      this.loading = false;
    },

    Submit() {
      console.log("查询");
      this.formInline.pageNum = 1;
      this.getList()
    },

    // 查看详情
    look(MessagePusId) {
      console.log(MessagePusId);
      this.$emit("flageFn", MessagePusId);
    },

    // a分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.formInline.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.formInline.pageNum = val;
      this.getList();
    },
    // b分页
  },
};
</script>

<style scoped lang='scss'>
.col_a6 {
  color: #a6a6a6;
}
.col_66 {
  color: #666666;
}
.fs14 {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
}

.fs12 {
  font-size: 12px;
  line-height: 30px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.stu {
  width: 69px;
  height: 23px;
  margin-left: 10px;
  color: #ffffff;
  font-size: 14px;
  align-items: center;
  text-align: center;
  background-color: #0077ff;
  border-radius: 20px;
}

.sold_box {
  width: 720px;
  padding: 12px 40px;
  border: 1px solid #e5e5e5;
}

.my-table {
  padding: 20px 10px;
  border-bottom: 1px solid #e8e8e8;
}

// 分页
.page {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    border-radius: 5px;
    background-color: #fff; // 进行修改未选中背景和字体
    border: 1px solid #e5e5e5;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0077ff; // 进行修改选中项背景和字体
    color: #fff;
  }
}
</style>