import service from "../request";

export function bannerList (query) {
    return service({
        url: '/api/merchants/banner/list',
        method: 'get',
        params: query
    })
}

export function bannerDel (query) {
    return service({
        url: '/api/merchants/banner/del',
        method: 'get',
        params: query
    })
}

export function bannerInfo (query) {
    return service({
        url: '/api/merchants/banner/info',
        method: 'get',
        params: query
    })
}

export function bannerSaveOrUpdate (query, data) {
    return service({
        url: '/api/merchants/banner/saveOrUpdate',
        method: 'post',
        params: query,
        data: data
    })
}


// 路由列表
export function webContListApi (query) {
    return service({
        url: '/api/merchants/banner/webContList',
        method: 'get',
        params: query
    })
}