<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div style="display: flex;justify-content: space-between">
      <el-tabs v-model="productState" @tab-click="handleClick" style="width: 100%">
        <el-tab-pane
            v-for="(item) in tabs"
            :key="item.value"
            :label="item.label"
            :name="item.value + ''"
        >
        </el-tab-pane>
      </el-tabs>
      <div style="display: flex;justify-content: center;align-items: center;padding: 20px">
        <el-button  type="primary" size="small"  @click="addGoods">自建商品</el-button>
        <el-button  type="primary" size="small"  @click="addCommodityModule">创建商品模块</el-button>
        <el-button  type="primary" size="small"  @click="addMaterialModule" v-if="userInfo.material == true">创建素材模块</el-button>
      </div>
    </div>

    <el-button type="primary" size="small" @click="batchPass" v-if="productState==3">批量上架</el-button>
    <el-button type="warning" size="small" @click="batchRejected" v-if="productState==1">批量下架</el-button>
    <el-button type="danger" size="small" @click="batchDelShow" v-if="productState !=1 && productState !=2">批量删除</el-button>

    <div style="margin-top: 10px; text-align:left;padding-right: 10px">
      <el-select @change="productTypeChange" v-model="queryParams.productType" placeholder="请选择商品来源" size="small">
        <el-option
            v-for="item in productTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>

      <el-select @change="productTypeChange" v-model="queryParams.classify_id" placeholder="请选择商品分类" size="small" style="margin-left: 10px">
        <el-option
            v-for="item in classifyOptions"
            :key="item.id"
            :label="item.classify_name"
            :value="item.id">
        </el-option>
      </el-select>

      <el-input
          size="small"
          placeholder="商品名称"
          v-model="queryParams.productName"
          clearable
          style="width: 160px;margin-left: 10px"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="到手价"
          v-model="queryParams.minDiscountPrice"
          clearable
          style="width: 100px;margin-left: 20px"
      >
      </el-input>
      <i style="margin-left: 5px" class="el-icon-minus"></i>
      <el-input
          size="small"
          placeholder="到手价"
          v-model="queryParams.maxDiscountPrice"
          clearable
          style="width: 100px;margin-left: 5px"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="佣金率"
          v-model="queryParams.minCommissionRate"
          clearable
          style="width: 100px;margin-left: 20px"
      >
      </el-input>
      <i style="margin-left: 5px" class="el-icon-minus"></i>
      <el-input
          size="small"
          placeholder="佣金率"
          v-model="queryParams.maxCommissionRate"
          clearable
          style="width: 100px;margin-left: 5px"
      >
      </el-input>
      <el-button
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="productTypeChange"
      >搜索</el-button>
    </div>

    <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
      :loading="listLoading"
      border
      style="margin-top: 10px;"
      :max-height="autoHeight"
  >
      <el-table-column
          :selectable="selectable"
          type="selection"
          width="55">
      </el-table-column>
    <el-table-column align="center" label="基本信息" width="300">
      <template slot-scope="scope">
        <div style="display:flex;" v-if="scope.row.commodity_module === 1">
          <el-image
              style="width: 30%; height: 100px"
              :src="scope.row.img"
              fit="cover">
          </el-image>
          <div style="width: 70%;padding:5px;display: flex;flex-direction: column;justify-content: center;text-align: left">
            <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{scope.row.name}}</div>
            <div>到手价: {{ formatNumber(scope.row.discount_price) }}</div>
            <div>总销量: {{scope.row.sell_num}}</div>
<!--            <div>库存: {{scope.row.total_stock_num}}</div>-->
          </div>
        </div>
        <div style="display:flex;" v-else>
          <el-image
              style="width: 30%; height: 100px"
              :src="scope.row.product_img"
              fit="cover">
          </el-image>
          <div style="width: 70%;text-align: left;margin: 5px;">
            <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{scope.row.product_name}}</div>
          </div>
        </div>
      </template>
    </el-table-column>

      <el-table-column align="center" label="佣金率/公开佣金率" >
        <template slot-scope="scope">
          <div v-if="scope.row.commodity_module === 1">
            <div>{{scope.row.commission_rate}} %</div>
            <div>{{scope.row.open_commission_rate}} %</div>
          </div>
          <div v-else>
              -
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="classify_name" label="商品分类" >
      </el-table-column>

      <el-table-column align="center" label="商品来源" >
        <template slot-scope="scope">
          <img
              v-if="scope.row.product_type == 1"
              style="width: 50px;height: 50px;border-radius: 50%"
              src="../../assets/dy.png"
              fit="cover"/>
          <img
              v-if="scope.row.product_type == 2"
              style="width: 50px;height: 50px;border-radius: 50%"
              src="../../assets/ks.png"
              fit="cover"/>
        </template>
      </el-table-column>

      <el-table-column align="center" label="排序" >
        <template slot-scope="scope">
          <div @click="editCustomSort(scope.row,scope.$index)" v-show="customSortIndex!= scope.$index">
            {{scope.row.custom_sort}}
            <i class="el-icon-edit" style="color: #409EFF;margin: 5px"></i>
          </div>
          <div v-show="customSortIndex == scope.$index" style="display: flex;justify-content: center">
            <el-input style="width: 120px" v-model="scope.row.custom_sort" placeholder="请输入排序"></el-input>
            <el-button style="margin-left: 5px" type="primary" size="small" round @click="updateCustomSort(scope.row)">保存</el-button>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="时间信息" >
        <template slot-scope="scope">
          <div v-if="scope.row.commodity_module === 1">
            <div>商品推广结束时间: {{ parseDateFull(scope.row.activity_end_time)}}</div>
            <div>商品信息更新时间: {{ parseDateFull(scope.row.goods_update_time) }}</div>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" v-if="productState !=2 && productState !=4">
        <template slot-scope="scope">
          <el-button
              v-if="userInfo.material == true"
              @click.native.prevent="productMaterial(scope.row)"
              type="text"
              size="small">
            商品素材
          </el-button>
          <el-button
              v-if="userInfo.material == true"
              @click.native.prevent="productScript(scope.row)"
              type="text"
              size="small">
            创作脚本
          </el-button>
          <el-button
              @click.native.prevent="edit(scope.row)"
              type="text"
              size="small">
              编辑
          </el-button>
          <el-button
              v-show="productState==3 || productState==4"
              @click.native.prevent="shelves(scope.row)"
              type="text"
              size="small">
             上架
          </el-button>
          <el-button
              v-show="productState==1 || productState==2"
              @click.native.prevent="finish(scope.row)"
              type="text"
              size="small">
              下架
          </el-button>
          <el-button
              v-show="productState==3 || productState==4 || productState==5 || productState==6"
              @click.native.prevent="rowDel(scope.row)"
              type="text"
              size="small">
              删除
          </el-button>
        </template>
      </el-table-column>

  </el-table>

      <el-row style="margin-top: 24px;margin-right: 20px">
        <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page_no"
                    :page-size="page_size"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
        </el-col>
      </el-row>


    <el-dialog
        title="提示-批量上架"
        :visible.sync="batchPassVisible"
        width="30%"
    >
      <div style="display: flex;justify-content: center">
        <div><i class="el-icon-warning" style="font-size: 40px;color: gold"></i></div>
        <div style="margin-left: 10px; color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 400;">
          <div>是否<span style="color: green">对{{ids.length}}条数据</span>执行上架操作？</div>
          <div>点击“确定”，将对选中数据进行操作！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchPassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchPass">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog
        title="提示-批量下架"
        :visible.sync="batchRejectedVisible"
        width="30%"
    >
      <div style="display: flex;justify-content: center">
        <div><i class="el-icon-warning" style="font-size: 40px;color: gold"></i></div>
        <div style="margin-left: 10px; color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 400;">
          <div>是否<span style="color: green">对{{ids.length}}条数据</span>执行下架操作？</div>
          <div>点击“确定”，将对选中数据进行操作！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchRejectedVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchRejected">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
        title="提示-批量删除"
        :visible.sync="batchDelVisible"
        width="30%"
    >
      <div style="display: flex;justify-content: center">
        <div><i class="el-icon-warning" style="font-size: 40px;color: gold"></i></div>
        <div style="margin-left: 10px; color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 400;">
          <div>是否<span style="color: green">对{{ids.length}}条数据</span>执行删除操作？</div>
          <div>点击“确定”，将对选中数据进行操作！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchDelVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchDel">确 定</el-button>
      </span>
    </el-dialog>

</div>
</template>

<script>

import {trillProductList, productState, trillProductUpdate, trillProductDel ,batchShelf ,batchOffshelf ,batchDel} from "../../api/trill_goods"
import { merchantsInfo } from "../../api/merchantsUser";
import {queryUsePage} from "../../api/product_classify";

export default {
  name: "index",
  data(){
    return{
      classifyOptions:[],
      userInfo: {
        material: true
      },
      productTypeOptions:[
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '抖音'
        },
        // {
        //   value: 2,
        //   label: '快手'
        // }
      ],
      productState: '',
      tabs:[],
      customSortIndex: null,
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        productType: '',
        classify_id: ''
      },
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 100,
      multipleSelection: [],
      ids: [],
      batchPassVisible: false,
      batchRejectedVisible: false,
      batchDelVisible:false
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initClassifyOptions()
    this.doProductState();
    this.getMerchantsInfo()
  },
  activated(){
    console.log("activated")
    this.doProductState();
  },
  methods:{
    doBatchDel(){
      let body = {
        ids: this.ids
      }
      console.log(JSON.stringify(body))
      batchDel(body).then((res) => {
        if (res.code == "0") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.doProductState()
          this.batchDelVisible = false
        }
      });
    },
    doBatchPass(){
      let body = {
        ids: this.ids
      }
      console.log(JSON.stringify(body))
      batchShelf(body).then((res) => {
        if (res.code == "0") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.doProductState()
          this.batchPassVisible = false
        }
      });
    },
    doBatchRejected(){
      let body = {
        ids: this.ids,
        refuseCause: this.reasonRejection
      }
      console.log(JSON.stringify(body))
      batchOffshelf(body).then((res) => {
        if (res.code == "0") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.doProductState()
          this.batchRejectedVisible = false
        }
      });
    },
    batchPass(){
      console.log(JSON.stringify(this.ids))
      if (this.ids.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchPassVisible = true
    },
    batchRejected(){
      console.log(JSON.stringify(this.ids))
      if (this.ids.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchRejectedVisible = true
    },
    batchDelShow(){
      console.log(JSON.stringify(this.ids))
      if (this.ids.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchDelVisible = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let ids = val.map((item, index) => {
        return item.id;
      });
      this.ids = ids
      console.log(JSON.stringify(ids))
    },
    selectable(){
      if(this.productState != 2){
        return true
      }
      return false
    },
    initClassifyOptions(){
      let queryParams ={
        plate: this.queryParams.productType,
        page: 1,
        limit: 999999,
        sidx: "create_time",
        order: "desc"
      }
      queryUsePage(queryParams).then((res) => {
        let classifyOptions = [
          {
            "id":"",
            "classify_name": "全部"
          }
        ]
        classifyOptions = classifyOptions.concat(res.data.list)
        this.classifyOptions = classifyOptions;
      });
    },
    getMerchantsInfo() {
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
    },
    productTypeChange(){
      this.initClassifyOptions()
      this.page_no = 1;
      this.initData();
    },
    rowDel(row){
          this.$confirm("确认删除该商品?", "删除商品", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            let query = {
              id: row.id
            }
            trillProductDel(query).then(res=> {
              this.$message.success({
                duration: 1000,
                message: '商品删除成功'
              });
              this.doProductState();
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    finish(row){
          this.$confirm("确认下架该商品?", "下架商品", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            let body = {
              id: row.id,
              item_status: 0
            }
            trillProductUpdate({},body).then(res=> {
              this.$message.success({
                duration: 1000,
                message: '商品下架成功'
              });
              this.doProductState();
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    shelves(row){
      this.$confirm("确认上架该商品?", "上架商品", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
       .then(() => {
         let body = {
           id: row.id,
           item_status: 1
         }
         trillProductUpdate({},body).then(res=> {
           this.$message.success({
             duration: 1000,
             message: '商品上架成功'
           });
           this.doProductState();
           })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
       });
    },
    edit(row){
      console.log(JSON.stringify(row))
      if(row.commodity_module === 1){
        // this.$router.push("/editAdd?id=" + row.id)
        const {href} = this.$router.resolve({
          path: "/editAdd?id=" + row.id
        });
        window.open(href, href);
      }else if(row.commodity_module === 2){
        // this.$router.push("/editCommodityModule?id=" + row.id)
        const {href} = this.$router.resolve({
          path: "/editCommodityModule?id=" + row.id
        });
        window.open(href, href);
      }else{
        // this.$router.push("/material_module_edit?id=" + row.id)
        const {href} = this.$router.resolve({
          path: "/material_module_edit?id=" + row.id
        });
        window.open(href, href);
      }
    },
    updateCustomSort(row){
      console.log(JSON.stringify(row))
      let body = {
        id: row.id,
        custom_sort: row.custom_sort
      }
      trillProductUpdate({},body).then(res=> {
        this.$message.success({
          duration: 1000,
          message: '序号修改成功'
        });
        this.customSortIndex = null
      })
    },
    handleClick(tab, event) {
      this.tableData = []
      this.page_no = 1;
      this.initData();
    },
    doProductState(){
      productState({}).then((response) => {
        console.log(response)
        console.log("productState:" + this.productState)
        this.tabs = response.data
        if(this.productState == '' || this.productState == 0){
          this.productState = this.tabs[0].value + ''
        }
        console.log("productState:" + this.productState)
        this.initData();
      });
    },
    editCustomSort(row,index){
      this.customSortIndex = index
    },
    formatNumber(val) {
      return (val/100).toFixed(2)
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m ;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams
          queryParams.productState = this.productState,
          queryParams.page = this.page_no,
          queryParams.limit = this.page_size,
          queryParams.sidx = "mtp.custom_sort"
          queryParams.order = "desc"
      trillProductList(queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 345) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    addGoods(){
      // this.$router.push("/goodsAdd")
      const {href} = this.$router.resolve({
        path: "/goodsAdd"
      });
      window.open(href, href);
    },
    addCommodityModule(){
      // this.$router.push("/commodity_module")
      const {href} = this.$router.resolve({
        path: "/commodity_module"
      });
      window.open(href, href);
    },
    addMaterialModule(){
      // this.$router.push("/material_module")
      const {href} = this.$router.resolve({
        path: "/material_module"
      });
      window.open(href, href);
    },
    productMaterial(row){
      // this.$router.push("/productMaterial?id=" + row.id)
      const {href} = this.$router.resolve({
        path: "/productMaterial?id=" + row.id
      });
      window.open(href, href);
    },
    productScript(row){
      // this.$router.push("/productScript?id=" + row.id)
      const {href} = this.$router.resolve({
        path: "/productScript?id=" + row.id
      });
      window.open(href, href);
    },
  }
}
</script>

<style scoped>

</style>
