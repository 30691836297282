<template>
  <div class="menu_view">
  <div class="menu_info">
    <el-menu
        v-if="CLOUD_MENU"
        @select="handleSelect"
        :default-active="activeIndex"
        :unique-opened = true
        class="el-menu-vertical-demo">
      <div v-for="(item,index) in CLOUD_MENU.children" :key="index">
        <el-menu-item :index="item.path"  :key="index" v-if="item.has_children == false">
          <template slot="title">
            <el-image
                style="width: 20px;height: 20px;margin: 10px"
                :src="item.icon_img"
                fit="cover">
            </el-image>
            <span>{{item.name}}</span>
          </template>
        </el-menu-item>
        <el-submenu :index="item.path" :key="index" v-else>
          <template slot="title">
            <el-image
                style="width: 20px;height: 20px;margin: 10px"
                :src="item.icon_img"
                fit="cover">
            </el-image>
            <span>{{item.name}}</span>
          </template>
          <el-menu-item :index="childItem.path" v-for="(childItem,childIndex) in item.children" :key="childIndex">
            {{childItem.name}}
          </el-menu-item>
        </el-submenu>
      </div>
    </el-menu>
  </div>
    <div class="invitation">
      <div><el-progress :percentage="cloudInfo.percentage"></el-progress></div>
      <div style="margin-top: 5px">
        <span>{{cloudInfo.useCloudSize | formatFileSize }} / {{ cloudInfo.cloudSize | formatFileSize }}</span>
        <span class="kr_btn" @click="my_cloud_info">扩容</span>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import {creatorUser, merchantsInfo ,cloudInfo} from "../api/merchantsUser";

export default {
  name: "MenuInfo",
  computed: {
    ...mapState(['CLOUD_MENU'])
  },
  filters: {
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else if(fileSize < (1024 * 1024 * 1024 * 1024)){
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'TB';
      }
    },
    numberFormat: function (value) {
      return value.toFixed(2)
    },
    numFilterZeo (value) {
      let realVal = parseFloat(value).toFixed(0)
      return Number(realVal)
    }
  },
  data(){
    return{
      userInfo:{},
      creatorUser:{},
      percentage:0,
      activeIndex:'',
      cloudInfo:{
        videoCloudSize: 0,
        materialCloudSize: 0,
        percentage: 0,
        useCloudSize: 0,
        cloudSize: 0
      }
    }
  },
  mounted() {
    this.initCloudInfo()
    this.initBaseData()
    console.log("routes:",JSON.stringify(this.routes))
    this.activeIndex=this.$route.path
  },
  methods:{
    initCloudInfo(){
      cloudInfo().then((res)=>{
        this.cloudInfo = res.data
      })
    },
    my_cloud_info(){
      this.$router.push("/my_cloud_info");
    },
    initBaseData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
      creatorUser().then((res)=>{
        this.creatorUser = res.data
        this.percentage = res.data.storageSpaceInfo.gFileSize/ res.data.storageSpaceInfo.storageSpace  * 100
      })
    },
    handleSelect(index, indexPath) {
      console.log(index, indexPath);
      this.$router.push(index);
    }
  }
}
</script>

<style scoped>

.menu_view{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.invitation{
  padding: 10px;
  font-size: 12px;
  font-weight: 400;
}


.kr_btn{
  cursor: pointer;
  float: right;
  color: rgba(0, 119, 255, 1);
}

.kr_btn:hover{
  opacity: 0.8;
  color: dodgerblue;
}

</style>
