<template>
  <div style="padding: 10px; margin: 10px; background-color: #fff">
    <div class="flex-between">
      <h2>{{ title }}</h2>
      <el-button class="btn1" type="primary" size="small" @click="bagck()"
        >返回</el-button
      >
    </div>

    <el-form
      size="small"
      label-position="left"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="140px"
      class="demo-ruleForm"
    >
      <el-form-item label="请输入公告标题" prop="title">
        <el-input
          clearable
          style="width: 380px"
          placeholder="请输入公告标题"
          v-model="ruleForm.title"
        ></el-input>
      </el-form-item>

      <el-form-item label="请输入公告描述" prop="description">
        <el-input
          style="width: 500px"
          placeholder="请输入公告描述"
          :autosize="{ minRows: 6, maxRows: 100 }"
          type="textarea"
          v-model="ruleForm.description"
        ></el-input>
      </el-form-item>

      <el-form-item label="公告详情" prop="push_user_type">
        <div>
          <div
            ref="editor"
            @input="editorChange"
            style="margin-top: 20px; width: 100%; height: 100%"
          />
        </div>
      </el-form-item>

      <el-form-item label="">
        <el-button type="info" @click="bagck()">取消</el-button>
        <el-button type="info" @click="submitStore()">保存到仓库</el-button>
        <el-button type="primary" @click="submit()">保存并发布</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
 
<script>
import { saveOrUpdate, pushNotice } from "@/api/system_notice.js";

import { getOssAccessInfo } from "@/api/common.js";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");
import E from "wangeditor";

export default {
  name: "addMessage",
  components: {},
  props: {
    editItem: {
      type: Object,
      default: function () {
        return { id: null };
      },
    },
  },

  data() {
    return {
      title: "",

      options: [
        {
          value: 1,
          label: "系统消息",
        },
      ],

      ruleForm: {
        title: "",
        description: "",
        content: "", //富文本
        status: "", //0：草稿箱 2：已推送
        type: 0, //类型 默认0：全部用户
      },

      rules: {
        title: [{ required: true, message: "请输入公告标题", trigger: "blur" }],

        description: [
          { required: true, message: "请输入公告描述", trigger: "blur" },
        ],
      },
      editor: "",
      startTime: "",
    };
  },
  computed: {},
  watch: {},

  created() {
    this.editShow();
    this.initData();
  },
  methods: {
    // 编辑回显 register_start_time register_end_time
    editShow() {
      if (this.editItem.id) {
        this.title = "编辑公告";
        this.ruleForm = this.editItem;
        console.log("编辑公告", this.ruleForm.content);
      } else {
        this.title = "新增公告";
      }
    },

    // 保存到仓库
    async submitStore() {
      await this.$refs.ruleForm.validate();
      this.ruleForm.status = 0;

      console.log("保存到仓库", this.ruleForm);
      // 保存
      const res = await saveOrUpdate({
        ...this.ruleForm,
      });

      console.log("保存到仓库", res);

      if (res.code == 0) {
        this.bagck();
        this.$message.success("保存并发布成功");
      } else {
        this.$message.error(res.message);
      }
    },

    // 保存+推送
    async submit() {
      await this.$refs.ruleForm.validate();
      this.ruleForm.status = 2;
      // 保存
      const res = await saveOrUpdate({
        ...this.ruleForm,
      });

      console.log("保存+推送", res);

      // 推送

      const res2 = await pushNotice({ id: res.data });
      if (res.status == 1 && res2.status == 1) {
        this.bagck();
        this.$message.success("保存并发布成功");
      } else {
        this.$message.error(res2.message);
        this.$message.error(res.message);
      }
    },

    // 富文本
    initData(id) {
      let _this = this;

      setTimeout(() => {
        const editor = new E(_this.$refs.editor);
        this.editor = editor;
        editor.config.zIndex = 10;
        editor.config.customUploadImg = function (files, insert) {
          files.forEach((image) => {
            getOssAccessInfo().then((res) => {
              const client = new OSS({
                region: "oss-cn-hangzhou",
                accessKeyId: res.data.access_key_id,
                accessKeySecret: res.data.access_key_secret,
                bucket: "cjfx-res",
                stsToken: res.data.security_token,
              });
              console.log(client);
              let randomStr = "";
              for (var i = 0; i < 9; i++) {
                randomStr =
                  randomStr + Math.floor(Math.random() * 10).toString();
              }
              let fileName =
                "/merchantscenter/banner/" +
                Sha1(
                  _this.$store.state.token + new Date().getTime() + randomStr
                ) +
                image.type;
              console.log(fileName);
              client.put(fileName, image).then((res) => {
                console.log(JSON.stringify(res));
                let imgUrl =
                  "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
                insert(imgUrl);
              });
            });
          });
        };
        editor.config.onchange = (html) => {
          this.ruleForm.content = html; //富文本内容
        };
        editor.create();
        editor.txt.html(this.ruleForm.content);
      }, 100);
    },
    editorChange(e) {
      this.ruleForm.content = e.target.innerHTML;
      // console.log(e.target.innerHTML);
    },

    // 返回
    bagck() {
      this.$emit("bagck");
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

<style>
.el-picker-panel .el-picker-panel__footer .el-button--text {
  display: none;
}
</style>