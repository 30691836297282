<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div style="background: white;">
      <el-input
          size="small"
          placeholder="商户单号/交易单号"
          v-model="queryParams.query_one"
          clearable
          style="width: 200px;"/>

      <el-input
          size="small"
          placeholder="微信昵称/手机号/openid"
          v-model="queryParams.wx_user"
          clearable
          style="width: 200px;margin-left: 5px"/>

      <el-input
          size="small"
          placeholder="抖音昵称/抖音号"
          v-model="queryParams.trill_user"
          clearable
          style="width: 220px;margin-left: 5px"/>

      <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="queryParamsChange()"
      >查询</el-button>
    </div>

    <el-table
        :data="tableData"
        :loading="listLoading"
        border
        style="width: 100%;margin-top: 10px"
        :max-height="autoHeight"
    >
      <el-table-column  label="商户号信息" >
        <template slot-scope="scope">
          <div>{{scope.row.pay_merchant_id}}</div>
          <div>{{scope.row.pay_merchant_name}}</div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="out_trade_no" label="商户单号" >
      </el-table-column>

      <el-table-column align="center" prop="transaction_id" label="交易单号" >
      </el-table-column>

      <el-table-column
          align="center"
          label="商户信息" width="180">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.merchant.head_portrait"
                fit="cover">
            </el-image>
            <div style="margin-left: 10px; text-align: left">
              <div>{{scope.row.merchant.id}}</div>
              <div>{{scope.row.merchant.name}}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          prop="address"
          label="用户信息" width="180">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.wxUser.avatar_url"
                fit="cover">
            </el-image>
            <div style="margin-left: 10px; text-align: left">
              <div>{{scope.row.wxUser.nick_name}}</div>
              <div>{{scope.row.wxUser.phone_number}}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          label="抖音信息" width="160">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.trillUserInfo.avatar"
                fit="cover">
            </el-image>
            <span style="margin-left: 5px">{{scope.row.trillUserInfo.nickname}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="total" label="实付款(元)" >
        <template slot-scope="scope">
          {{scope.row.total | numberFormat }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="success_time" label="支付时间" >
        <template slot-scope="scope">
          {{scope.row.success_time | formatDate("yyyy-MM-dd HH:mm:ss") }}
        </template>
      </el-table-column>

    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page_no"
                    :page-size="page_size"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {list} from "../../api/douyin_member_res";

export default {
  name: "douyin_member_res",
  filters: {
    numberFormat: function (value) {
      return value.toFixed(2)
    },
    formatDate: function(value,args) {
      var dt = new Date(value);
      if(args == 'yyyy-M-d') {// yyyy-M-d
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let date = dt.getDate();
        return `${year}-${month}-${date}`;
      } else if(args == 'yyyy-M-d H:m:s'){// yyyy-M-d H:m:s
        let year = dt.getFullYear();
        let month = dt.getMonth() + 1;
        let date = dt.getDate();
        let hour = dt.getHours();
        let minute = dt.getMinutes();
        let second = dt.getSeconds();
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
      } else if(args == 'yyyy-MM-dd') {// yyyy-MM-dd
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2,'0');
        let date = dt.getDate().toString().padStart(2,'0');
        return `${year}-${month}-${date}`;
      } else {// yyyy-MM-dd HH:mm:ss
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2,'0');
        let date = dt.getDate().toString().padStart(2,'0');
        let hour = dt.getHours().toString().padStart(2,'0');
        let minute = dt.getMinutes().toString().padStart(2,'0');
        let second = dt.getSeconds().toString().padStart(2,'0');
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
      }
    }
  },
  data(){
    return{
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        query_one:'',
        query_mt: '',
        wx_user: '',
        trill_user: '',
        sidx: "sdmr.create_time",
        order: "desc"
      },
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 0,
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
  },
  methods:{
    queryParamsChange(){
      this.page_no = 1;
      this.initData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams
      queryParams.page = this.page_no,
          queryParams.limit = this.page_size,
          list(queryParams).then((response) => {
            this.tableData = response.data.list;
            this.total = response.data.total_count;
            this.listLoading = false;
          });
    }
  }
}
</script>

<style scoped>

</style>
