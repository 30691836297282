<template>
  <div style="padding: 10px;">
      <div class="container">
        <div class="container_title">精选联盟佣金收益</div>
        <div style="display: flex;padding: 10px;">
          <div class="item_info">
            <div style="color: rgba(56, 56, 56, 1);font-size: 24px;font-weight: bold">{{list[0].value | numberFormat }}</div>
            <div class="item_text">
              {{list[0].label}}
              <span @click="withdraw" style="margin-left: 10px; font-size: 12px;font-weight: 500;color: rgba(0, 119, 255, 1);cursor: pointer;">申请提现</span>
              <span @click="doJxlmBalance" style="margin-left: 10px; font-size: 12px;font-weight: 500;color: rgba(0, 119, 255, 1);cursor: pointer;">余额记录</span>
            </div>
          </div>

          <div class="item_info">
            <div style="color: rgba(56, 56, 56, 1);font-size: 24px;font-weight: bold">{{list[1].value | numberFormat }}</div>
            <div class="item_text">{{list[1].label}}</div>
          </div>

          <div class="item_info">
            <div style="color: rgba(56, 56, 56, 1);font-size: 24px;font-weight: bold">{{list[2].value | numberFormat }}</div>
            <div class="item_text">{{list[2].label}}</div>
          </div>
        </div>
      </div>
      <div class="container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="结算订单" name="first">
            <TrillSettleOrders/>
          </el-tab-pane>
          <el-tab-pane label="结算后退款订单" name="second">
            <TrillRefundOrders/>
          </el-tab-pane>
        </el-tabs>
      </div>



    <el-dialog
        size="small"
        title="余额记录"
        :visible.sync="jxlmBalanceVisible"
        width="60%">
      <el-table
          :data="jxlmBalanceRes"
          style="width: 100%">
        <el-table-column
            align="center"
            prop="create_time"
            label="时间">
        </el-table-column>
        <el-table-column
            prop="gen_card_quota"
            label="余额(元)"
            align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.balance > 0"> +{{scope.row.balance | numberFormat}}</div>
            <div v-else>{{scope.row.balance | numberFormat}}</div>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="rec_type"
            label="方式">
        </el-table-column>
        <el-table-column
            align="center"
            prop="remark"
            label="备注">
        </el-table-column>
      </el-table>
      <el-row style="margin-top: 24px">
        <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="jxlmBalanceChange"
                    :current-page="jxlmBalanceParams.page"
                    :page-size="jxlmBalanceParams.limit"
                    :total="jxlmBalanceParams.total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
        title="申请提现"
        :visible.sync="withdrawVisible"
        width="30%">
      <div>
        <div style="font-size: 14px;font-weight: 500;">提现流程：</div>
        <div style="font-size: 14px;font-weight: 400;color: rgba(56, 56, 56, 1);">
          <div>
            1、线下联系官方运营发起提现申请；
          </div>
          <div>
            2、官方运营会提供对公开票信息，您需要进行开票并将发票给到官方运营；
          </div>
          <div>
            3、官方财务打款进入您指定的对公帐户并在系统上扣减您的可提现金额；
          </div>
          <div>
            4、提现完成
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="withdrawVisible = false">我知道了</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import TrillRefundOrders from "./trillRefund_orders";
import TrillSettleOrders from "./trillSettle_orders";
import {merchantStatistical} from "../../api/easyExcel";
import { jxlmBalanceList } from "../../api/jxlmBalance";

export default {
  components: {
    TrillRefundOrders,
    TrillSettleOrders
  },
  name: "index",
  filters:{
    numberFormat: function (value) {
      return value.toFixed(2)
    }
  },
  data(){
    return{
      withdrawVisible: false,
      jxlmBalanceVisible:false,
      jxlmBalanceTitele: '',
      jxlmBalanceParams:{
        page:1,
        limit:10,
        total:0,
      },
      jxlmBalanceRes:[],
      list:[],
      activeName:'first'
  }},
  mounted(){
    this.initData()
  },
  methods:{
    withdraw(){
      this.withdrawVisible = true
    },
    doJxlmBalance(){
      let query = {
        sidx: "create_time",
        order: "desc",
        page: 1,
        limit: 10,
        total:0
      }
      this.jxlmBalanceParams = query
      this.doJxlmBalanceList();
      this.jxlmBalanceVisible = true
    },
    doJxlmBalanceList(){
      jxlmBalanceList(this.jxlmBalanceParams).then((response) => {
        this.jxlmBalanceRes = response.data.list
        this.jxlmBalanceParams.total = response.data.total_count
      });
    },
    jxlmBalanceChange(val){
      console.log(`当前页: ${val}`);
      this.jxlmBalanceParams.page = val;
      this.doJxlmBalanceList();
    },
    initData(){
      merchantStatistical().then((res) => {
        this.list = res.data.list;
      });
    }
  }
}
</script>

<style scoped>

.container{
  margin-top: 10px;
  background: rgba(255, 255, 255, 1);
  padding: 10px;
}

.container_title{
  font-size: 16px;
  font-weight: 500;
}

.item_info{
  background: rgba(245, 245, 245, 1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  margin-right: 10px;
}
.item_text{
  margin-top: 5px;
  color: rgba(166, 166, 166, 1);
  font-size: 14px;
}

</style>
