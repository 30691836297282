<template>
  <div style="padding: 10px; margin: 10px; background-color: #fff">
    <div class="flex-between">
      <h3>公告详情</h3>
      <el-button class="btn1" type="primary" size="small" @click="bagck()"
        >返回</el-button
      >
    </div>

    <div class="flex-left">
      <div class="fs_14_33">公告标题</div>
      <div class="fs_14_00">{{ message.title }}</div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">通知时间</div>
      <div class="fs_14_00">{{ message.update_time }}</div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">公告描述</div>
      <div class="fs_14_00 ovhead">
        {{ message.description }}
      </div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">公告详情</div>
      <div class="fs_14_00 ovhead" v-html="message.content">
        <!-- 详情内容。。。 -->
        <!-- {{ message.message_content }} -->
      </div>
    </div>
  </div>
</template>
 
<script>
import { noticeDetail } from "@/api/system_notice.js";
export default {
  name: "particulars",
  components: {},
  props: {
    MessagePusId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      message: {
        message_title: "重要通知",
        notice_time: "2023-10-11 10:00:00",
        message_content: "内容。。。。。",
      },
    };
  },

  created() {
    this.getList();
  },
  methods: {
    // 返回
    bagck() {
      this.$emit("bagck");
    },

    async getList() {
      const res = await noticeDetail({ id: this.MessagePusId });
      console.log(res);
      this.message = res.data;
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fs_14_33 {
  margin-right: 100px;
  font-size: 14px;
  color: #333333;
  line-height: 40px;
}

.fs_14_00 {
  font-size: 14px;
  color: #000000;
}

.ovhead {
  width: 400px;
  word-wrap: break-word;
  margin: 10px 0;
}
</style>