import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';

import '../static/icon/iconfont.css'

Vue.use(ElementUI);
import store from './store'
import router from "./router"

import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
});
