import Layout from "../views/layout/Layout";
import TrillGoods from "../views/trill_goods/index";
import IpGoods from "../views/trill_goods/IpGoods";
import Classify from "../views/trill_goods/classify";
import TrillGoodsAdd from "../views/trill_goods/add";
import TrillGoodsEdit from "../views/trill_goods/edit";
import CommodityModule from "../views/trill_goods/CommodityModule";
import editCommodityModule from "../views/trill_goods/EditCommodityModule.vue";
import ProductMaterial from "../views/trill_goods/ProductMaterial.vue";
import productScript from "../views/trill_goods/productScript.vue";
import material_module from "../views/trill_goods/material_module.vue";
import material_module_edit from "../views/trill_goods/material_module_edit.vue";
const router = [
    {
        path:"/list",
        name:'商品管理',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/zjsp.png',
        component: Layout,
        isMenu:true,
        isSingleMenu:false,
        children:[
            {
                path:"/list",
                name:'商品列表',
                component: TrillGoods,
                meta: {
                    keepAlive: true
                }
            },
            {
                path:"/classify",
                name:'商品分类',
                component: Classify,
                meta: {
                    keepAlive: true
                }
            },
            {
                path:"/goodsAdd",
                name:'自建商品',
                isHidden : true,
                component: TrillGoodsAdd
            },
            {
                path:"/editAdd",
                name:'编辑商品',
                isHidden : true,
                component: TrillGoodsEdit
            },
            {
                path:"/commodity_module",
                name:'创建商品模块',
                isHidden : true,
                component: CommodityModule
            },
            {
                path:"/editCommodityModule",
                name:'编辑商品模块',
                isHidden : true,
                component: editCommodityModule
            },
            {
                path:"/material_module",
                name:'创建素材模块',
                isHidden : true,
                component: material_module
            },
            {
                path:"/material_module_edit",
                name:'编辑素材模块',
                isHidden : true,
                component: material_module_edit
            },
            {
                path:"/productMaterial",
                name:'商品素材',
                isHidden : true,
                component: ProductMaterial
            },
            {
                path:"/productScript",
                name:'商品脚本',
                isHidden : true,
                component: productScript
            }
        ]
    }
]

export default router;
