<template>
  <div class="main_container">
    <div class="content">
        <div class="content_name">发票中心</div>
        <el-tabs v-model="serviceStates" @tab-click="handleClick">
          <el-tab-pane label="未开票" name="-1">
            <TicketOrderList ref="child1"></TicketOrderList>
          </el-tab-pane>
          <el-tab-pane label="开票中" name="0">
            <TicketOrderRecList ref="child2" :serviceStates="0"></TicketOrderRecList>
          </el-tab-pane>
          <el-tab-pane label="开票历史" name="1">
            <TicketOrderRecList ref="child3" :serviceStates="1"></TicketOrderRecList>
          </el-tab-pane>
        </el-tabs>
    </div>
  </div>
</template>

<script>

import TicketOrderList from "./TicketOrderList.vue"
import TicketOrderRecList from "./TicketOrderRecList.vue"

export default {
  name: "index",
  components:{
    TicketOrderList,
    TicketOrderRecList
  },
  data() {
    return {
      serviceStates: "-1",
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      this.$refs.child1.initData();
      this.$refs.child2.initData();
      this.$refs.child3.initData();
    }
  }
}
</script>

<style scoped>

.main_container{
  padding: 10px;
}

.content{
  background: rgba(255, 255, 255, 1);
  padding: 20px;
}

.content_name{
  font-size: 28px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

</style>
