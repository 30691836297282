<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <el-tabs v-model="plate_type" @tab-click="plateTypeChange">
      <el-tab-pane label="抖音板块" name="1"></el-tab-pane>
<!--      <el-tab-pane label="快手板块" name="2"></el-tab-pane>-->
    </el-tabs>

    <el-button type="primary" @click="addBanner" size="small">新增banner</el-button>

    <el-select style="margin-left: 20px"
               v-model="queryParams.platform"
               size="small"
               placeholder="请选择"
               @change="initData"
    >
      <el-option
          v-for="item in plaOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>

    <el-table
      :data="tableData"
      :loading="listLoading"
      border
      style="margin-top: 10px;"
      :max-height="autoHeight"
  >
    <el-table-column align="center" prop="page_name" label="页面名称" >
    </el-table-column>

      <el-table-column align="center" label="所属平台" >
        <template slot-scope="scope">
          <span v-if="scope.row.platform == 1">小程序</span>
          <span v-if="scope.row.platform == 2">app</span>
        </template>
      </el-table-column>

    <el-table-column align="center" label="Banner图片" >
      <template slot-scope="scope">
        <el-image
            :src="scope.row.img_url"
            fit="cover">
        </el-image>
      </template>
    </el-table-column>

    <el-table-column align="center" prop="custom_sort" label="排序" >
    </el-table-column>

    <el-table-column align="center" prop="whether_show" label="是否显示" >
      <template slot-scope="scope">
      <el-switch
          v-model="scope.row.whether_show"
          active-color="#13ce66"
          inactive-color="#ff4949"
          disabled>
      </el-switch>
      </template>
    </el-table-column>

     <el-table-column align="center" prop="banner_type" label="跳转类型" >
        <template slot-scope="scope">
         <span v-if="scope.row.banner_type == 1">富文本</span>
         <span v-if="scope.row.banner_type == 2">跳转h5</span>
         <span v-if="scope.row.banner_type == 3">app内部导航</span>
        </template>
      </el-table-column>

    <el-table-column align="center" prop="create_time" label="创建时间" >
    </el-table-column>

    <el-table-column align="center" label="操作" >
      <template slot-scope="scope">
        <el-button
            @click.native.prevent="editBanner(scope.row)"
            type="text"
            size="small">
          编辑
        </el-button>
        <el-button
            @click.native.prevent="handleDel(scope.row)"
            type="text"
            size="small">
          删除
        </el-button>
      </template>
    </el-table-column>

  </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page_no"
                    :page-size="page_size"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

</div>
</template>

<script>
import { bannerList ,bannerDel } from "../../api/marketing";

export default {
  name: "bannerList",
  data(){
    return{
      plate_type: '1',
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        platform: ''
      },
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 100,
      plaOptions:[
        {
          value: '',
          label: '全部'
        },
        {
          value: '1',
          label: '小程序'
        }, {
          value: '2',
          label: 'app'
        }
      ]
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
  },
  activated(){
    this.initData()
  },
  methods:{
    plateTypeChange(tab, event){
      console.log(tab, event);
      this.page_no = 1;
      this.initData();
    },
    handleDel(val) {
      this.$confirm("确认删除该banner?", "删除banner", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            bannerDel({ id: val.id }).then((res) => {
              if (res.code == "0") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.initData();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    addBanner(){
      this.$router.push(
          {
            path:"addBanner",
            query: {
              plate_type: this.plate_type
            }
          }
      )
    },
    editBanner(row){
      this.$router.push("/editBanner?id=" + row.id)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 260) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams
          queryParams.page = this.page_no,
          queryParams.limit = this.page_size,
          queryParams.plate_type = this.plate_type
      bannerList(queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
