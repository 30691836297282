import { render, staticRenderFns } from "./templateList.vue?vue&type=template&id=64186c64&scoped=true"
import script from "./templateList.vue?vue&type=script&lang=js"
export * from "./templateList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64186c64",
  null
  
)

export default component.exports