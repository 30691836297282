import service from "../request";

export function templateList(query) {
    return service({
        url: '/api/merchants/template/list',
        method: 'get',
        params: query
    })
}


export function templateState(query) {
    return service({
        url: '/api/merchants/template/templateState',
        method: 'get',
        params: query
    })
}

export function moveWarehouse(data) {
    return service({
        url: '/api/merchants/template/moveWarehouse',
        method: 'post',
        data: data
    })
}

export function shiftOutWarehouse(data) {
    return service({
        url: '/api/merchants/template/shiftOutWarehouse',
        method: 'post',
        data: data
    })
}

