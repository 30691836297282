import service from "../request";

// 获取用户标签
export function staffLabelListApi (query) {
    return service({
        url: '/api/merchants/staffLabel/list',
        method: 'get',
        params: query
    })
}

// 删除
export function staffLabelDelApi (query) {
    return service({
        url: '/api/merchants/staffLabel/del',
        method: 'get',
        params: query
    })
}

// 新增更新用户标签
export function saveOrUpdateApi (query, data) {
    return service({
        url: '/api/merchants/staffLabel/saveOrUpdate',
        method: 'post',
        params: query,
        data: data
    })
}


