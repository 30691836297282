<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <!-- 数据总览
    <div class="statistics">
      <div
        v-for="(item, index) in statisticsList"
        :key="index"
        class="statistics_item"
      >
        <div class="item_value">{{ item.value }}</div>
        <div class="item_lable">{{ item.label }}</div>
      </div>
    </div> -->

    <div style="background: white; padding: 10px; margin: 10px">
      <el-input
        size="small"
        placeholder="ID"
        v-model="queryParams.id"
        clearable
        style="width: 120px"
      />
      <el-input
        size="small"
        placeholder="抖音昵称/抖音号/uid/授权编号"
        v-model="queryParams.query_one"
        clearable
        style="width: 220px; margin-left: 10px"
      />

      <el-select
        clearable
        @change="queryParamsChange()"
        style="margin-left: 10px; width: 120px"
        v-model="queryParams.expire"
        placeholder="请选择"
        size="small"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item.label"
          :value="index"
        >
        </el-option>
      </el-select>

      <el-input
        size="small"
        placeholder="所属用户昵称/手机号"
        v-model="queryParams.query_two"
        clearable
        style="width: 200px; margin-left: 10px"
      />

      <el-input
        size="small"
        placeholder="所属经纪人昵称/手机号"
        v-model="queryParams.query_tre"
        clearable
        style="width: 200px; margin-left: 5px"
      >
      </el-input>

      <el-select
        clearable
        @change="queryParamsChange()"
        style="margin-left: 10px; width: 120px"
        v-model="queryParams.mcn"
        placeholder="请选择"
        size="small"
      >
        <el-option
          v-for="item in mcnOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        v-if="SynergyMerchants == true"
        clearable
        style="width: 200px; margin-left: 5px"
        @change="queryParamsChange"
        v-model="queryParams.marketing_ip"
        placeholder="请选择IP"
        size="small"
      >
        <el-option
          v-for="item in ipList"
          :key="item.id"
          :label="item.classify_name"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <!-- @change="queryParamsChange()" -->
      <el-select
        clearable
        style="margin-left: 10px; width: 180px"
        v-model="queryParams.live_cert"
        placeholder="请选择直播权限"
        size="small"
      >
        <el-option
          v-for="(item, index) in liveOptions"
          :key="index"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-button
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="queryParamsChange()"
        >查询</el-button
      >
    </div>

    <el-table
      border
      size="small"
      :data="tableData"
      style="width: 100%; margin-top: 15px"
      :max-height="autoHeight"
    >
      <el-table-column prop="id" label="绑定ID"> </el-table-column>
      <el-table-column prop="change_tie" label="是否支持换绑">
        <template slot-scope="scope">
          <el-switch
            disabled
            v-model="scope.row.change_tie"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="avatar" label="抖音头像昵称" width="160">
        <template slot-scope="scope">
          <div style="display: flex; flex-direction: row; align-items: center">
            <el-image
              style="width: 50px; height: 50px; border-radius: 50%"
              :src="scope.row.avatar"
              fit="cover"
            >
            </el-image>
            <span style="margin-left: 5px">{{ scope.row.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="抖音信息" width="220">
        <template slot-scope="scope">
          <div>
            <div>粉丝数:{{ scope.row.fans_number }}</div>
            <div>抖音号:{{ scope.row.trill_account }}</div>
            <div>抖音绑定的手机:{{ scope.row.trill_phone }}</div>
            <div>抖音uid:{{ scope.row.trill_uid }}</div>
            <div>授权编号:{{ scope.row.auth_number }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="fans_number" label="抖音主页截图" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.home_img">
            <el-image
              :src="scope.row.home_img"
              :preview-src-list="getSrcList(scope.row.home_img)"
              fit="cover"
            >
            </el-image>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column prop="fans_number" label="抖音橱窗截图" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.showcase_img">
            <el-image
              :src="scope.row.showcase_img"
              :preview-src-list="getSrcList(scope.row.showcase_img)"
              fit="cover"
            >
            </el-image>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="expiration_time"
        label="授权到期时间"
        width="160"
      >
      </el-table-column>
      <el-table-column align="center" label="是否绑定MCN" width="160">
        <template slot-scope="scope">
          <div v-if="scope.row.audit_status == 1">
            <div>已绑定</div>
            <div>绑定时间 {{ scope.row.pass_time | formatDate }}</div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="SynergyMerchants == true"
        prop="fans_number"
        label="所属IP"
        width="220"
      >
        <template slot-scope="scope">
          <div
            style="display: flex; flex-direction: row; align-items: center"
            v-if="scope.row.merchantsProductClassify != null"
          >
            <el-image
              style="width: 50px; height: 50px; border-radius: 50%"
              :src="scope.row.merchantsProductClassify.classify_icon"
              fit="cover"
            >
            </el-image>
            <span style="margin-left: 5px">{{
              scope.row.merchantsProductClassify.classify_name
            }}</span>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>

      <el-table-column label="联系人信息" width="200">
        <template slot-scope="scope">
          <div style="text-align: left">姓名：{{ scope.row.apply_name }}</div>
          <div style="text-align: left">
            手机号：{{ scope.row.apply_phone }}
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column
        align="center"
        prop="rewards"
        label="精选联盟收益(元)"
        width="140"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="sumSales"
        label="精选联盟销售额(元)"
        width="140"
      >
      </el-table-column> -->

      <el-table-column
        align="center"
        prop="address"
        label="所属用户"
        width="260"
      >
        <template slot-scope="scope">
          <div style="display: flex; flex-direction: row; align-items: center">
            <el-image
              style="width: 50px; height: 50px; border-radius: 50%"
              :src="scope.row.avatar_url"
              fit="cover"
            >
            </el-image>
            <div style="margin-left: 10px; text-align: left">
              <div>{{ scope.row.nick_name }}</div>
              <div>{{ scope.row.phone_number }}</div>
            </div>
          </div>
          <div style="margin-top: 5px">{{ scope.row.wx_open_id }}</div>
        </template>
      </el-table-column>

      <el-table-column label="会员信息" width="200">
        <template slot-scope="scope">
          <div
            v-if="scope.row.sklmMembersLevel != null"
            style="display: flex; flex-direction: column"
          >
            <span style="margin-left: 5px"
              >用户等级:{{ scope.row.sklmMembersLevel.members_name }}</span
            >
            <span style="margin-left: 5px"
              >会员到期时间:{{
                scope.row.sklmMembersAudit.expiration_time | formatDate
              }}</span
            >
          </div>
          <div v-else>暂无会员信息</div>
        </template>
      </el-table-column>

      <el-table-column label="所属经纪人" width="200">
        <template slot-scope="scope">
          <div
            v-if="scope.row.agentInfo != null"
            style="display: flex; flex-direction: row; align-items: center"
          >
            <el-image
              style="width: 50px; height: 50px; border-radius: 50%"
              :src="scope.row.agentInfo.avatar_url"
              fit="cover"
            >
            </el-image>
            <div style="display: flex; flex-direction: column">
              <span style="margin-left: 5px">{{
                scope.row.agentInfo.nick_name
              }}</span>
              <span style="margin-left: 5px">{{
                scope.row.agentInfo.phone_number
              }}</span>
            </div>
          </div>
          <div v-else>暂无经纪人信息</div>
        </template>
      </el-table-column>

      <el-table-column label="所属合伙人" width="200">
        <template slot-scope="scope">
          <div style="display: flex; flex-direction: row; align-items: center">
            <el-image
              style="width: 50px; height: 50px; border-radius: 50%"
              :src="scope.row.cAva"
              fit="cover"
            >
            </el-image>
            <div style="display: flex; flex-direction: column">
              <span style="margin-left: 5px">{{ scope.row.cName }}</span>
              <span style="margin-left: 5px">{{ scope.row.cPhone }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column label="抖音用户标签" width="200">
        <template slot-scope="scope">
          <el-select v-model="value" disabled placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column> -->

      <el-table-column fixed="right" align="center" label="操作" width="200px">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="doRatioInfo(scope.row)"
            type="text"
            size="small"
          >
            APP推广配置
          </el-button>
          <el-button
            @click.native.prevent="changeTie(scope.row)"
            type="text"
            size="small"
          >
            抖音换绑
          </el-button>
          <el-button
            @click.native.prevent="commission(scope.row)"
            type="text"
            size="small"
          >
            精选联盟分佣设置
          </el-button>
          <el-button
            v-if="scope.row.auth_number != null"
            @click.native.prevent="doUnbindCheck(scope.row)"
            type="text"
            size="small"
          >
            解除mcn绑定
          </el-button>

          <el-button
            v-if="
              scope.row.is_synchronous == 0 &&
              (scope.row.marketing_ip != null || scope.row.marketing_ip == 1)
            "
            @click.native.prevent="synchronMcn(scope.row)"
            type="text"
            size="small"
          >
            同步至mcn机构
          </el-button>

          <el-button
            v-if="
              scope.row.is_synchronous == 1 &&
              (scope.row.marketing_ip != null || scope.row.marketing_ip == 1)
            "
            @click.native.prevent="synchronMcOff(scope.row)"
            type="text"
            size="small"
          >
            取消同步至mcn机构
          </el-button>

          <el-button
            @click.native.prevent="TiktokUsers(scope.row)"
            type="text"
            size="small"
          >
            抖音用户标签
          </el-button>

          <el-button
            @click.native.prevent="updateInfo(scope.row)"
            type="text"
            size="small"
          >
            更新抖音信息
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px; margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="queryParams.page"
            :page-size="queryParams.limit"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog
      title="精选联盟分佣设置"
      :visible.sync="commissionVisible"
      width="30%"
    >
      <div>
        <el-form ref="form" :model="commissionForm" label-width="100px">
          <el-form-item label="类型：">
            <el-radio-group v-model="commissionForm.com_type">
              <el-radio :label="1">跟随系统</el-radio>
              <el-radio :label="2">自定义</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="达人:" prop="talent_proportion">
            <el-input-number
              style="width: 100%"
              :controls="false"
              :precision="2"
              :min="0"
              :max="100"
              v-model="commissionForm.talent_proportion"
              placeholder="请输入达人分佣比例"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="commissionVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureCommission">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="抖音换绑" :visible.sync="changeTieVisible" width="30%">
      <div>
        <el-form ref="form" :model="changeTieForm" label-width="100px">
          <el-form-item label="用户状态：">
            <el-radio-group v-model="changeTieForm.change_tie">
              <el-radio :label="true">开放</el-radio>
              <el-radio :label="false">禁止</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="商户状态：">
            <el-radio-group v-model="changeTieForm.change_mt">
              <el-radio :label="true">开放</el-radio>
              <el-radio :label="false">禁止</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeTieVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureChangeTie">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      size="mini"
      title="APP推广设置"
      :visible.sync="appRatioInfo"
      width="30%"
    >
      <div>
        <el-form
          size="mini"
          ref="form"
          :model="appRatioForm"
          label-width="100px"
        >
          <div>非直播订单推广设置</div>
          <el-radio-group
            v-model="appRatioForm.others.lever_source"
            style="display: flex; flex-direction: column; padding: 20px"
          >
            <el-radio :label="1">跟随系统</el-radio>
            <el-radio :label="2"
              >自定义推广等级
              <el-select
                size="mini"
                v-model="appRatioForm.others.custom_lever"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in othersList"
                  :key="item.master_lever_id"
                  :label="item.lever_name"
                  :value="item.master_lever_id"
                >
                </el-option>
              </el-select>
            </el-radio>
          </el-radio-group>

          <div>直播订单推广设置</div>
          <div
            style="
              display: flex;
              flex-direction: column;
              flex-direction: column;
              padding: 20px;
            "
          >
            <div>
              <el-radio-group v-model="appRatioForm.live.lever_source">
                <el-radio :label="1">跟随系统</el-radio>
                <el-radio :label="2"
                  >自定义推广等级
                  <el-select
                    size="mini"
                    v-model="appRatioForm.live.custom_lever"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in liveList"
                      :key="item.master_lever_id"
                      :label="item.lever_name"
                      :value="item.master_lever_id"
                    >
                    </el-option>
                  </el-select>
                </el-radio>
              </el-radio-group>
            </div>
            <div style="margin-top: 10px">
              直播资质
              <el-switch
                v-model="appRatioForm.others.live_cert"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
            <div style="margin-top: 10px">
              资质到期时间
              <el-date-picker
                size="mini"
                v-model="appRatioForm.others.live_cert_exp_time"
                value-format="yyyy-MM-dd HH:mm:ss"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </div>
          </div>

          <el-divider></el-divider>
          非直播订单提成明细
          <div class="detail_info">
            <div class="detail_info_left">
              用户提成比例：{{
                appRatioForm.others.master_ratio | percentFormat
              }}
              %
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.others.ext_obj.userInfo.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.others.ext_obj.userInfo.name }}
            </div>
          </div>
          <div class="detail_info">
            <div class="detail_info_left">
              经纪人提成比例：{{
                appRatioForm.others.agent_ratio | percentFormat
              }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.others.ext_obj.agentInfo.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.others.ext_obj.agentInfo.name }}
            </div>
          </div>
          <div class="detail_info">
            <div class="detail_info_left">
              合作伙伴提成比例：{{
                appRatioForm.others.channel_ratio | percentFormat
              }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.others.ext_obj.chaInfo.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.others.ext_obj.chaInfo.name }}
            </div>
          </div>
          <div class="detail_info">
            <div class="detail_info_left">
              本商户提成比例：{{
                appRatioForm.others.merchant_ratio | percentFormat
              }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.others.ext_obj.merchant.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.others.ext_obj.merchant.name }}
            </div>
          </div>
          <div class="detail_info">
            <div class="detail_info_left">
              IP提成比例：{{ appRatioForm.others.ip_ratio | percentFormat }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.others.ext_obj.ipInfo.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.others.ext_obj.ipInfo.name }}
            </div>
          </div>

          直播订单提成明细
          <div class="detail_info">
            <div class="detail_info_left">
              用户提成比例：{{
                appRatioForm.live.master_ratio | percentFormat
              }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.live.ext_obj.userInfo.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.live.ext_obj.userInfo.name }}
            </div>
          </div>
          <div class="detail_info">
            <div class="detail_info_left">
              经纪人提成比例：{{
                appRatioForm.live.agent_ratio | percentFormat
              }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.live.ext_obj.agentInfo.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.live.ext_obj.agentInfo.name }}
            </div>
          </div>
          <div class="detail_info">
            <div class="detail_info_left">
              合作伙伴提成比例：{{
                appRatioForm.live.channel_ratio | percentFormat
              }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.live.ext_obj.chaInfo.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.live.ext_obj.chaInfo.name }}
            </div>
          </div>
          <div class="detail_info">
            <div class="detail_info_left">
              本商户：{{ appRatioForm.live.merchant_ratio | percentFormat }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.live.ext_obj.merchant.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.live.ext_obj.merchant.name }}
            </div>
          </div>
          <div class="detail_info">
            <div class="detail_info_left">
              IP提成比例：{{ appRatioForm.live.ip_ratio | percentFormat }}%
            </div>
            <div class="detail_info_right">
              <el-image
                class="detail_img"
                :src="appRatioForm.live.ext_obj.ipInfo.img"
                fit="cover"
              >
              </el-image>
              {{ appRatioForm.live.ext_obj.ipInfo.name }}
            </div>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appRatioInfo = false">取 消</el-button>
        <el-button type="primary" @click="sureAppRatio">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="抖音用户标签"
      :visible.sync="TiktokUsersVisible"
      width="30%"
    >
      <el-form ref="form" :model="TiktokUsersForm" label-width="120px">
        <el-form-item label="抖音用户标签">
          <el-select
            v-model="TiktokUsersForm.content"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in TiktokUsersOptions"
              :key="item.id"
              :label="item.content"
              :value="item.content"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="绑定创作者:">
          <el-select
            size="small"
            v-model="framerList.creator_user_id"
            filterable
            placeholder="请选择"
          >
            <el-option
              v-for="item in framerOptions"
              :key="item.id"
              :label="item.creator_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="TiktokUsersVisible = false">取 消</el-button>
        <el-button type="primary" @click="TiktokUsersSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  statistics,
  list,
  del,
  commission,
  changeTie,
  ratioInfo,
  ratioUpdate,
  staffLabelInfoApi,
  infoupdateApi,
  staffLabelCtListApi,
} from "../../api/trillBinding";
import { marketingIp, synergyMerchantsCheck } from "../../api/product_classify";
import {
  unbindPass,
  unbindCheck,
  synchronous,
  updateUserInfo,
} from "../../api/trill_mcn_info";
import { user_commission } from "../../api/user_commission";

import { staffLabelListApi } from "@/api/custom_label.js";

export default {
  name: "TiktokList",
  filters: {
    percentFormat: function (value) {
      let val = value * 100;
      return val.toFixed(2);
    },
    numberFormat: function (value) {
      return value.toFixed(2);
    },
    formatDate(val) {
      var date = new Date(val);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      return Y + M + D;
    },
  },
  data() {
    return {
      othersList: [],
      liveList: [],
      appRatioInfo: false,
      appRatioForm: {
        others: {
          ext_obj: {
            userInfo: {
              img: "",
              name: "",
            },
            agentInfo: {
              img: "",
              name: "",
            },
            chaInfo: {
              img: "",
              name: "",
            },
            merchant: {
              img: "",
              name: "",
            },
            ipInfo: {
              img: "",
              name: "",
            },
          },
        },
        live: {
          ext_obj: {
            userInfo: {
              img: "",
              name: "",
            },
            agentInfo: {
              img: "",
              name: "",
            },
            chaInfo: {
              img: "",
              name: "",
            },
            merchant: {
              img: "",
              name: "",
            },
            ipInfo: {
              img: "",
              name: "",
            },
          },
        },
      },
      autoHeight: "",
      autoWidth: "",
      statisticsList: [],
      options: [
        {
          label: "授权到期",
          value: 1,
        },
        {
          label: "授权未到期",
          value: 2,
        },
      ],
      liveOptions: [
        {
          label: "已获取直播权限",
          value: 1,
        },
        {
          label: "未获取直播权限",
          value: 2,
        },
      ],

      mcnOptions: [
        {
          label: "已绑定mcn",
          value: 1,
        },
        {
          label: "未绑定mcn",
          value: 2,
        },
      ],
      queryParams: {
        page: 1,
        limit: 10,
        sidx: "stb.create_time",
        order: "desc",
        nickname: "",
        trill_account: "",
        expire: "",
        nick_name: "",
        phone_number: "",
        query_one: "",
        query_two: "",
        marketing_ip: "",
        query_tre: "",
        live_cert: "",
      },
      total: 0,
      listLoading: false,
      tableData: [],
      ipList: [],
      SynergyMerchants: false,
      commissionForm: {},
      commissionVisible: false,
      changeTieForm: {},
      changeTieVisible: false,

      TiktokUsersVisible: false,
      TiktokUsersForm: {
        content: [],
      },
      TiktokUsersOptions: [],
      open_id: "",

      framerOptions: [],
      queryOptions: {
        page: 1,
        limit: 99999,
      },
      framerList: {
        creator_user_id: "",
      },
    };
  },
  created() {
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.doSynergyMerchantsCheck();
    this.statistics();
    this.doMarketingIp();
    this.getUserCommission();
    this.initData();
    this.staffLabelCtList();
  },

  methods: {
    // 更新抖音信息
    updateInfo(row) {
      this.$confirm("更新抖音信息？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          updateUserInfo({ trill_open_id: row.trill_open_id })
            .then((res) => {
              if (res.code == "0") {
                this.$message.success("更新成功");
                this.initData();
              } else {
                this.$message.error("更新失败!");
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    // 同步至MCN机构
    synchronMcn(row) {
      this.$confirm("同步至MCN机构？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("确定", row.auth_number);

          synchronous({ auth_number: row.auth_number, is_synchronous: true })
            .then((res) => {
              if (res.code == "0") {
                this.$message.success("操作成功");
                this.initData();
              } else {
                this.$message.error("操作失败!");
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    synchronMcOff(row) {
      this.$confirm("取消同步至MCN机构？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("确定", row.auth_number);

          synchronous({ auth_number: row.auth_number, is_synchronous: false })
            .then((res) => {
              if (res.code == "0") {
                this.$message.success("操作成功");
                this.initData();
              } else {
                this.$message.error("操作失败!");
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    // 绑定创作者
    async staffLabelCtList() {
      const res = await staffLabelCtListApi({
        ...this.queryOptions,
        ...this.framerList,
      });
      let list = [
        {
          id: -1,
          creator_name: "待设定",
        },
      ];

      this.framerOptions = [...list, ...res.data.list];
      console.log(res);
    },

    // 修改标签
    async TiktokUsersSubmit() {
      console.log("提交", this.TiktokUsersForm.content);
      let newContent = this.TiktokUsersForm.content;
      const res = await infoupdateApi(
        {},
        {
          open_id: this.open_id,
          content: newContent,
          ...this.framerList,
        }
      );

      // const res2 = this.staffLabelCtList();
      // console.log("hsmmm-",res2);
      this.initData();
      this.$message.success("修改成功");
      this.TiktokUsersVisible = false;
    },

    // 抖音用户标签列表
    async staffLabelList() {
      const res = await staffLabelListApi({ page: 1, limit: 9999 });
      console.log(res);
      this.TiktokUsersOptions = res.data.list;
    },

    // 抖音用户标签 按钮
    async TiktokUsers(row) {
      this.TiktokUsersVisible = true;
      this.staffLabelList(); //所有标签
      this.open_id = row.open_id;
      const res = await staffLabelInfoApi({ openId: row.open_id }); //获取当前用户
      console.log("获取当前用户", res);
      this.TiktokUsersForm.content = res.data.content;
      this.framerList.creator_user_id = res.data.creator_user_id;
      // console.log("弹框");
    },

    getUserCommission() {
      user_commission({}).then((res) => {
        this.othersList = res.data.others;
        this.liveList = res.data.live;
      });
    },
    doRatioInfo(row) {
      ratioInfo({ id: row.id }).then((res) => {
        if (res.status == 1) {
          this.appRatioForm = res.data;
          this.appRatioInfo = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    sureAppRatio() {
      let otherLev = this.appRatioForm.others.custom_lever;
      for (let i = 0; i < this.othersList.length; i++) {
        if (this.othersList[i].master_lever_id === otherLev) {
          this.appRatioForm.others.custom_lever_index = i + 1;
          break;
        }
      }
      let liveLev = this.appRatioForm.live.custom_lever;
      for (let i = 0; i < this.liveList.length; i++) {
        if (this.liveList[i].master_lever_id === liveLev) {
          this.appRatioForm.live.custom_lever_index = i + 1;
          break;
        }
      }
      ratioUpdate(this.appRatioForm).then((res) => {
        if (res.status == 1) {
          this.appRatioInfo = false;
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    sureCommission() {
      console.log(JSON.stringify(this.commissionForm));
      commission(this.commissionForm).then((res) => {
        if (res.status == 1) {
          this.commissionVisible = false;
          this.initData();
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    commission(row) {
      this.commissionForm = {
        id: row.id,
        com_type: row.com_type,
        talent_proportion: row.talent_proportion,
      };
      console.log(JSON.stringify(this.commissionForm));
      this.commissionVisible = true;
    },
    sureChangeTie() {
      console.log(JSON.stringify(this.changeTieForm));
      changeTie(this.changeTieForm).then((res) => {
        if (res.status == 1) {
          this.changeTieVisible = false;
          this.initData();
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    changeTie(row) {
      this.changeTieForm = {
        id: row.id,
        change_tie: row.change_tie,
        change_mt: row.change_mt,
      };
      console.log(JSON.stringify(this.changeTieForm));
      this.changeTieVisible = true;
    },
    doUnbindCheck(row) {
      console.log(JSON.stringify(row));
      let _this = this;
      unbindCheck({ open_id: row.trill_open_id })
        .then((res) => {
          if (res.code == "0") {
            if (res.data.haveDeposit == true) {
              console.log("还有保证金不能解绑");
              this.$message.error("还有保证金不能解绑!");
            } else {
              console.log("解绑操作");
              _this.doMcnUnbind(row);
            }
          } else {
            this.$message.error("操作失败!");
          }
        })
        .catch(() => {});
    },
    doMcnUnbind(row) {
      this.$confirm("确定解除mcn绑定？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          unbindPass({ auth_number: row.auth_number })
            .then((res) => {
              if (res.code == "0") {
                this.$message.success("操作成功");
                this.initData();
              } else {
                this.$message.error("操作失败!");
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    doSynergyMerchantsCheck() {
      synergyMerchantsCheck({}).then((res) => {
        this.SynergyMerchants = res.data;
      });
    },
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 380 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },
    unbundling(row) {
      this.$confirm("确定解除 '" + row.nickname + "' 抖音号?", "解除绑定", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({ id: row.id }).then((response) => {
            this.statistics();
            this.doMarketingIp();
            this.initData();
          });
        })
        .catch(() => {});
    },
    doMarketingIp() {
      marketingIp({}).then((res) => {
        this.ipList = res.data.list;
      });
    },
    queryParamsChange() {
      this.queryParams.page = 1;
      this.initData();
    },
    getSrcList(src) {
      let srcList = [];
      srcList.push(src);
      return srcList;
    },
    statistics() {
      statistics({}).then((response) => {
        this.statisticsList = response.data.list;
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    async initData() {
      console.log("查询", this.queryParams);
      this.listLoading = true;
      list(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.statistics {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.statistics_item {
  border-radius: 6px;
  background: rgba(245, 245, 245, 1);
  margin-right: 10px;
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.item_value {
  font-size: 24px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
}

.item_lable {
  font-size: 14px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}

.detail_info {
  display: flex;
  align-items: center;
}

.detail_info_left {
  flex: 1;
  text-align: right;
  padding-right: 50px;
}

.detail_info_right {
  flex: 1;
  display: flex;
  align-items: center;
}

.detail_img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}
</style>
