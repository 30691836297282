import streamSaver from "streamsaver"
import '@/utils/zip-stream.js'


export const downloadFile = (data, filename) => {
    data = data.replace(/^http/, 'https')
    return fetch(data).then(res => {
        // 创建写入流 filename为下载的文件名 
        const fileStream = streamSaver.createWriteStream(filename, {
            size: res.headers.get("content-length")
        })
        const readableStream = res.body
        if (window.WritableStream && readableStream.pipeTo) {
            return readableStream.pipeTo(fileStream).then(() => {
                console.log('完成写入')
            })
        }
        window.writer = fileStream.getWriter()
        const reader = res.body.getReader()
        const pump = () => reader.read().then(res => res.done ?
            window.writer.close() : window.writer.write(res.value).then(pump))
        pump()
    })
}

/**  
 * 同步下载打包
 * @param zipName 压缩包文件名  
 * @param files 文件列表，格式：[{"name":"文件名", "url":"文件下载地址"},……]  
 */
export function zipFiles(zipName, files) {
    // 创建压缩文件输出流  
    const zipFileOutputStream = streamSaver.createWriteStream(zipName)
    // 创建下载文件流  
    const fileIterator = files
    // eslint-disable-next-line no-undef
    const readableZipStream = new ZIP({
        async pull(ctrl) {
            if (fileIterator.length === 0) { //迭代终止  
                ctrl.close()
            } else {
                const fileInfo = fileIterator.shift()
                const { name } = fileInfo
                let url = fileInfo.url
                if(!url.startsWith('https')) {
                    url = url.replace(/^http/, 'https')
                }
                return fetch(url).then(res => {
                    ctrl.enqueue({
                        name,
                        stream: () => res.body
                    })
                })
            }
        }
    })
    if (window.WritableStream && readableZipStream.pipeTo) {
        // 开始下载  
        readableZipStream
            .pipeTo(zipFileOutputStream)
            .then(() => console.log("同步下载打包结束时间：" + new Date()))
    }
}