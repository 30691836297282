<template>
<div class="content">
  <div>
    <p class="tips">欢迎你使用视客联盟系统！</p>
    <p class="tips">申请企业认证是一项严肃的法律行为，拟认证主体特此申请系统账户（系统ID） {{userInfo.id}} 认证服务，已仔细阅读并同意以下内容及《视客联盟认证服务协议》。</p>
    <p class="tips">1. 在帐号资质审核成功之后，帐号的使⽤权属于通过资质审核的拟认证主体，该帐号⾃注册时其产⽣的⼀切权利义务均由该主体承担，该帐号所获得的所有收益、权限均归认证后的主体享有，且所有运营活动都必须以该主体对外开展。</p>
    <p class="tips">2. 拟认证主体提交给视客联盟的认证资料真实⽆误，并不可撤销地授权视客联盟及其委托的任何第三⽅审核机构对提交的资料进⾏甄别核实。</p>
    <p class="tips">3. 拟认证主体在申请企业认证服务过程中填写并向视客联盟提交的认证联系⼈（包括后续不时变更的⼈员）为拟认证主体指定的认证联系⼈员，并特此授权由该指定⼈员以拟认证主体的名义负责该系统帐号的内容生产及运营管理。认证联系人的所有操作行为，均代表拟认证主体，拟认证主体均需承担一切责任。</p>
    <p class="tips">4. 本认证服务仅对该帐号所提交的认证资料的真实性、合法性进⾏书⾯甄别核实，其功能、权限是否开通等均需遵守视客联盟的规则（详细规则内容请查看规则中心），⽽不与认证审核结果存在直接关联。</p>
    <p class="tips">5. 拟认证主体如违反上述承诺，均需承担一切责任，对视客联盟或第三方造成损失的，须赔偿损失。在视客联盟同意通过新的认证服务之前，本公函持续有效。</p>
  </div>
  <div style="display: flex">
    <el-checkbox v-model="checked">
    </el-checkbox>
    <div style="font-size: 14px;font-weight: 400;margin-left: 10px">我已阅读并同意上述内容及
      <span class="agreement" @click="authentication_protocol_info">《视客联盟认证服务协议》</span>
    </div>
  </div>

  <div style="text-align: center">
    <el-button  size="small" type="primary" @click="recertification">下一步</el-button>
  </div>

</div>
</template>

<script>
import {merchantsInfo} from "../../api/merchantsUser";

export default {
  name: "AuthenticationProtocol",
  data(){
    return{
      checked:false,
      userInfo:{
        id: "000000"
      }
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    initData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
    },
    authentication_protocol_info(){
      const routeUrl = this.$router.resolve({
        path: "/authentication_protocol_info"
      });
      window.open(routeUrl.href, "_blank");
    },
    recertification(){
      if(this.checked == false){
        this.$message.error("请确认已阅读视客联盟认证服务协议!");
        return
      }
      this.$router.push("/upload_information");
    },
  }
}
</script>

<style scoped>

.content{
  height: 100%;
  background: rgba(255, 255, 255, 1);
  padding-left: 210px;
  padding-right: 210px;
  padding-top: 50px;
}

.tips{
  font-size: 16px;
  font-weight: 400;
  color: rgba(56, 56, 56, 1);
}

.agreement{
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 119, 255, 1);
}

</style>
