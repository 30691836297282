import Layout from "../views/layout/Layout";
import templateList from "../views/template/templateList";
import build_rec_feedback from "../views/template/build_rec_feedback";
import templateClassify from "../views/template/templateClassify";
const template = [
    {
        path:"/templateList",
        name:'模板列表',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/spmb.png',
        component: Layout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/templateList",
                name:'模板列表',
                component: templateList
            },
            {
                path:"/build_rec_feedback",
                name:'模版问题反馈',
                component: build_rec_feedback
            },
            // {
            //     path:"/templateClassify",
            //     name:'模板分类',
            //     component: templateClassify,
            // }
        ]
    }
]

export default template;
