<template>
  <div style="padding: 10px; margin: 10px; background-color: #fff">
    <div class="flex-between">
      <h3>消息详情</h3>
      <el-button class="btn1" type="primary" size="small" @click="bagck()"
        >返回</el-button
      >
    </div>

    <div class="flex-left">
      <div class="fs_14_33">消息类型</div>
      <div class="fs_14_00">
        {{ message.message_type == 1 ? "系统消息" : "" }}
      </div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">消息标题</div>
      <div class="fs_14_00">{{ message.message_title }}</div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">消息简介</div>
      <div class="fs_14_00 ovhead">
        {{ message.message_content }}
      </div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">通知时间</div>
      <div class="fs_14_00">{{ message.notice_time }}</div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">推送用户</div>
      <div
        class="fs_14_00"
        style="margin-right:5px"
        v-for="(item, index) in message.push_user_type_name"
        :key="index"
      >
        {{item}}
        <!-- {{
          ["", "所有用户", "注册时间", "所属IP", "指定用户"][
            message.push_user_type
          ]
        }} -->
      </div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">跳转类型</div>
      <!-- v-html="1" -->
      <div class="fs_14_00">{{ message.jump_type_name }}</div>
    </div>

    <div class="flex-left">
      <div class="fs_14_33">跳转内容</div>
      <!-- v-html="1" -->
      <div class="fs_14_00" v-html="message.jump_content"></div>
    </div>
  </div>
</template>
 
<script>
import { messageDetail } from "@/api/push_message.js";
export default {
  name: "particulars",
  components: {},
  props: {
    MessagePusId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      message: {},
    };
  },

  created() {
    this.getList();
  },
  methods: {
    // 返回
    bagck() {
      this.$emit("bagck");
    },

    async getList() {
      const res = await messageDetail({ messagePushId: this.MessagePusId });
      console.log(res);
      this.message = res.data;
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fs_14_33 {
  margin-right: 100px;
  font-size: 14px;
  color: #333333;
  line-height: 40px;
}

.fs_14_00 {
  font-size: 14px;
  color: #000000;
}

.ovhead {
  width: 400px;
  word-wrap: break-word;
  margin: 10px 0;
}
</style>