<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <div style="display: flex">
      <div>
        <div style="font-size: 16px; font-weight: 500">
          是否开启会员邀请会员自动升级为经纪人?
          <el-switch
            style="margin-left: 5px"
            v-model="agent_rules.state"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>

        <div style="display: flex; align-items: center">
          <div>
            <div style="margin-top: 10px">
              <el-select
                v-model="agent_rules.members_level_parent"
                placeholder="会员等级"
                size="small"
              >
                <el-option
                  v-for="item in membersLevelList"
                  :key="item.id"
                  :label="item.members_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              邀请
              <el-input
                size="small"
                v-model="agent_rules.quantity"
                clearable
                style="width: 120px"
              >
              </el-input>
              位
              <el-select
                v-model="agent_rules.members_level_child"
                placeholder="会员等级"
                size="small"
              >
                <el-option
                  v-for="item in membersLevelList"
                  :key="item.id"
                  :label="item.members_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              即可自动成为经纪人
            </div>
            <div style="margin-top: 10px; display: flex; align-items: center">
              <div>
                默认经纪人邀请码:
                <el-input
                  size="small"
                  v-model="agent_rules.default_agent_code"
                  clearable
                  style="width: 120px"
                >
                </el-input>
              </div>

              <div style="margin-left: 10px">
                邀请码开关:
                <el-switch
                  v-model="agent_rules.invite"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                >
                </el-switch>
              </div>
            </div>
          </div>
          <el-button
            style="margin-left: 100px"
            type="primary"
            size="small"
            @click="sureAgentRules"
            >确认修改</el-button
          >
        </div>
      </div>
    </div>

    <el-divider></el-divider>

    <div style="margin-bottom: 20px">
      <div style="font-size: 16px; font-weight: 500">经纪人名称</div>
      <div style="margin-top: 10px">
        在小程序中，经纪人的名称显示为：
        <el-input
          size="small"
          v-model="merchantConfig.agent_label"
          clearable
          style="width: 120px"
        >
        </el-input>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="merchantConfigUpdate"
          >确认修改</el-button
        >
      </div>
    </div>

    <div>
      <el-input
        size="small"
        placeholder="openId"
        v-model="queryParams.open_id"
        clearable
        style="width: 280px"
      >
      </el-input>
      <el-input
        size="small"
        placeholder="昵称"
        v-model="queryParams.nick_name"
        clearable
        style="width: 120px; margin-left: 10px"
      >
      </el-input>
      <el-input
        size="small"
        placeholder="手机号"
        v-model="queryParams.phone_number"
        clearable
        style="width: 120px; margin-left: 10px"
      >
      </el-input>

      <el-input
        size="small"
        placeholder="经纪人昵称"
        v-model="queryParams.agentNickName"
        clearable
        style="width: 120px; margin-left: 10px"
      >
      </el-input>

      <el-select
        v-model="queryParams.channel_user_id"
        placeholder="合作伙伴"
        size="small"
        clearable
        style="margin-left: 10px"
      >
        <el-option
          v-for="item in channelList"
          :key="item.id"
          :label="item.user_name"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-button
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="search()"
        >搜索</el-button
      >
    </div>

    <el-table
      :data="tableData"
      :loading="listLoading"
      border
      style="width: 100%; margin-top: 10px"
      :max-height="autoHeight"
    >
      <el-table-column
        align="center"
        prop="open_id"
        label="经纪人信息"
        width="300"
      >
        <template slot-scope="scope">
          <el-image
            style="width: 50px; height: 50px"
            :src="scope.row.avatar_url"
            fit="cover"
          >
          </el-image>
          <div>{{ scope.row.nick_name }}</div>
          <div>{{ scope.row.phone_number }}</div>
          <div>{{ scope.row.open_id }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="open_id" label="企微二维码">
        <template slot-scope="scope">
          <img
            v-if="scope.row.we_chat_img"
            :src="scope.row.we_chat_img"
            class="avatar"
          />
          <i
            v-else
            class="el-icon-plus avatar-uploader-icon"
            @click="doUpdateWxImg(scope.row)"
          ></i>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="remark" label="备注">
        <template slot-scope="scope">
          {{ scope.row.remark ? scope.row.remark : "-" }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="invitation_code" label="邀请码">
      </el-table-column>
      <el-table-column align="center" prop="promoting_num" label="团队人数">
      </el-table-column>
      <el-table-column
        align="center"
        prop="agent_proportion"
        label="精选联盟分佣比例"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.com_type == 1">跟随系统</div>
          <div v-else>
            {{ scope.row.agent_proportion | numberFormat }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="channel_user_id"
        label="合作伙伴"
        width="200px"
      >
        <template slot-scope="scope">
          <el-select
            v-model="scope.row.channel_user_id"
            placeholder="请选择合作伙伴"
            disabled
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.user_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="dkRatio(scope.row)"
            >抖客运营配置</el-button
          >

          <el-button
            @click.native.prevent="doRatioInfo(scope.row)"
            type="text"
            size="small"
          >
            APP运营配置
          </el-button>
          <el-button
            @click.native.prevent="commission(scope.row)"
            type="text"
            size="small"
          >
            精选联盟分佣设置
          </el-button>
          <el-button
            v-if="synergyMerchants == true"
            type="text"
            size="small"
            @click="doClassify(scope.row)"
            >MCN佣金IP分成管理</el-button
          >
          <el-button
            v-if="synergyMerchants == true"
            type="text"
            size="small"
            @click="classifyLimit(scope.row)"
            >IP名额管理</el-button
          >
          <el-button
            v-if="scope.row.agent == 1"
            type="text"
            size="small"
            @click="doSetBroker(scope.row)"
            >取消经纪人</el-button
          >
          <el-button
            v-if="scope.row.agent == 1"
            type="text"
            size="small"
            @click="doUpdateChannel(scope.row)"
            >更新渠道合作伙伴</el-button
          >
          <el-button
            v-if="scope.row.agent == 1"
            type="text"
            size="small"
            @click="doUpdateWxImg(scope.row)"
            >设置企微二维码</el-button
          >
          <el-button
            v-if="scope.row.agent == 1"
            type="text"
            size="small"
            @click="doUpdateWxCustomer(scope.row)"
            >设置企微客服url</el-button
          >
          <el-button
            v-if="scope.row.agent == 1"
            type="text"
            size="small"
            @click="doUpdateRemarkCustomer(scope.row)"
            >设置备注</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page_no"
            :page-size="page_size"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog
      v-if="classifyLimitDialogVisible"
      :title="classifyLimitTitle"
      :visible.sync="classifyLimitDialogVisible"
      width="60%"
    >
      <ClassifyLimit :agentInfo="agentInfo"></ClassifyLimit>
    </el-dialog>

    <el-dialog
      v-if="classifyFcDialogVisible"
      :title="classifyFcTitle"
      :visible.sync="classifyFcDialogVisible"
      width="60%"
    >
      <ClassifyFc :agentInfo="agentInfo"></ClassifyFc>
    </el-dialog>

    <el-dialog
      v-if="channelVisible"
      :title="channelTitle"
      :visible.sync="channelVisible"
      width="60%"
    >
      <el-form :model="channelForm" label-width="200px">
        <el-form-item label="渠道合作伙伴：">
          <el-select
            clearable
            v-model="channelForm.channel_user_id"
            placeholder="请选择渠道合作伙伴"
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.user_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="channelVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureChannelUpdate">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="精选联盟分佣设置"
      :visible.sync="commissionVisible"
      width="30%"
    >
      <div>
        <el-form ref="form" :model="commissionForm" label-width="100px">
          <el-form-item label="类型：">
            <el-radio-group v-model="commissionForm.com_type">
              <el-radio :label="1">跟随系统</el-radio>
              <el-radio :label="2">自定义</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="经纪人:" prop="agent_proportion">
            <el-input-number
              style="width: 100%"
              :controls="false"
              :precision="2"
              :min="0"
              :max="100"
              v-model="commissionForm.agent_proportion"
              placeholder="请输入经纪人分佣比例"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="commissionVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureCommission">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      size="mini"
      title="APP-经纪人推广设置"
      :visible.sync="appRatioInfo"
      width="576"
    >
      <div>
        <el-form
          size="mini"
          ref="form"
          :model="appRatioForm"
          label-width="100px"
        >
          <div>非直播订单推广设置</div>
          <el-radio-group
            v-model="appRatioForm.others.lever_source"
            @input="radioChange($event, 'others')"
            style="display: flex; flex-direction: column; padding: 20px"
          >
            <el-radio :label="1">跟随系统</el-radio>
            <el-radio :label="2" style="margin-top: 5px"
              >自定运营等级
              <el-select
                size="mini"
                v-model="appRatioForm.others.custom_lever"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in agentLevList"
                  :key="item.agent_lever_id"
                  :label="item.lever_name"
                  :value="item.agent_lever_id"
                >
                </el-option>
              </el-select>
            </el-radio>
            <template v-if="appRatioForm.others.lever_source == 2">
              <div class="flex_left" style="margin: 10px 0">
                <span class="expire_text">是否过期：</span>
                <el-switch
                  v-model="appRatioForm.others.cus_lev_exp"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="switchChange('others')"
                >
                </el-switch>
                <el-date-picker
                  style="margin-left: 10px"
                  v-show="appRatioForm.others.cus_lev_exp"
                  v-model="appRatioForm.others.cus_lev_exp_time"
                  size="small"
                  type="date"
                  value-format="yyyy-MM-dd 23:59:59"
                  placeholder="选择过期日期"
                >
                </el-date-picker>
              </div>
            </template>
            <el-radio :label="3" style="margin-top: 5px"
              >固定分成比例
              <el-input-number
                style="width: 180px"
                size="mini"
                :controls="false"
                :precision="2"
                :min="0"
                :max="100"
                v-model="appRatioForm.others.agent_ratio"
                placeholder="请输入佣金率"
              ></el-input-number>
              <span style="margin-left: 10px">%</span>
            </el-radio>

            <el-radio :label="4" style="margin-top: 5px"
              >固定升级比例
              <el-input-number
                style="width: 180px"
                size="mini"
                :controls="false"
                :precision="2"
                :min="0"
                :max="100"
                v-model="appRatioForm.others.agent_ratio_up"
                placeholder="请输入佣金率"
              ></el-input-number>
              <span style="margin-left: 10px">%</span>
            </el-radio>
          </el-radio-group>

          <div>直播订单推广设置</div>
          <div>
            <el-radio-group
              v-model="appRatioForm.live.lever_source"
              @input="radioChange($event, 'live')"
              style="display: flex; flex-direction: column; padding: 20px"
            >
              <el-radio :label="1">跟随系统</el-radio>
              <el-radio :label="2" style="margin-top: 5px"
                >自定运营等级
                <el-select
                  size="mini"
                  v-model="appRatioForm.live.custom_lever"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in agentLevList"
                    :key="item.agent_lever_id"
                    :label="item.lever_name"
                    :value="item.agent_lever_id"
                  >
                  </el-option>
                </el-select>
              </el-radio>
              <template v-if="appRatioForm.live.lever_source == 2">
                <div class="flex_left" style="margin: 10px 0">
                  <span class="expire_text">是否过期：</span>
                  <el-switch
                    v-model="appRatioForm.live.cus_lev_exp"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    @change="switchChange('live')"
                  >
                  </el-switch>
                  <el-date-picker
                    style="margin-left: 10px"
                    v-show="appRatioForm.live.cus_lev_exp"
                    v-model="appRatioForm.live.cus_lev_exp_time"
                    value-format="yyyy-MM-dd 23:59:59"
                    size="small"
                    type="date"
                    placeholder="选择过期日期"
                  >
                  </el-date-picker>
                </div>
              </template>
              <el-radio :label="3" style="margin-top: 5px"
                >固定分成比例
                <el-input-number
                  style="width: 180px"
                  size="mini"
                  :controls="false"
                  :precision="2"
                  :min="0"
                  :max="100"
                  v-model="appRatioForm.live.agent_ratio"
                  placeholder="请输入佣金率"
                ></el-input-number>
                <span style="margin-left: 10px">%</span>
              </el-radio>

              <el-radio :label="4" style="margin-top: 5px"
                >固定升级比例
                <el-input-number
                  style="width: 180px"
                  size="mini"
                  :controls="false"
                  :precision="2"
                  :min="0"
                  :max="100"
                  v-model="appRatioForm.live.agent_ratio_up"
                  placeholder="请输入佣金率"
                ></el-input-number>
                <span style="margin-left: 10px">%</span>
              </el-radio>
            </el-radio-group>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="appRatioInfo = false">取 消</el-button>
        <el-button type="primary" @click="sureAppRatio">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      v-if="wxImgVisible"
      :title="wxImgTitle"
      :visible.sync="wxImgVisible"
      width="40%"
    >
      <el-form :model="wxImgForm" label-width="200px">
        <el-form-item label="企微二维码">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadImg"
          >
            <img
              v-if="wxImgForm.we_chat_img"
              :src="wxImgForm.we_chat_img"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="wxImgVisible = false">取 消</el-button>
        <el-button type="primary" @click="sureWxImg">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      v-if="wxCustomerVisible"
      :title="wxCustomerTitle"
      :visible.sync="wxCustomerVisible"
      width="40%"
      :before-close="closeWxDialog"
    >
      <el-form :model="wxCustomerForm" label-width="150px">
        <el-form-item label="客服配置" prop="wx_customer" v-if="!settingRemark">
          <el-input
            v-model="wxCustomerForm.wx_customer"
            clearable
            placeholder="请输入微信客服地址"
          />
        </el-form-item>

        <el-form-item label="设置备注" prop="wx_customer" v-else>
          <el-input
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 8 }"
            placeholder="请输入备注"
            clearable
            v-model="remark"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeWxDialog">取 消</el-button>
        <el-button type="primary" @click="sureWxCustomer">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="抖客运营配置" :visible.sync="dkRatioVisible" width="40%">
      <el-radio-group v-model="dkForm.ratioInfo.lever_source">
        <el-radio :label="1">跟随系统</el-radio>
        <el-radio :label="2">
          自定义推广等级
          <el-select
            style="margin-top: 20px; width: 140px"
            v-model="dkForm.ratioInfo.user_lever"
            placeholder="选择推广等级"
            size="small"
          >
            <el-option
              v-for="item in dkForm.ratioBase"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dkRatioVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="dkEdit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ClassifyLimit from "./components/ClassifyLimit.vue";
import ClassifyFc from "./components/ClassifyFc.vue";

import {
  sklmMembersLevelList,
  sklmUserList,
  sklmUserUpdate,
  agentRulesInfo,
  agentRulesUpdate,
  commission,
  setBroker,
  updateChannel,
  ratioAgentInfo,
  ratioAgentUpdate,
  updateWechat,
  updateWxCustomer,
  updateRemarkCustomer,
  dkRatioinfo,
  dkRatioUpdate,
} from "../../api/sklmUser";
import {
  merchantsConfig,
  merchantsConfigUpdate,
} from "../../api/merchant_config";
import { synergyMerchantsCheck } from "../../api/product_classify";
import { channelList } from "../../api/channel_user";
import { user_commission } from "../../api/user_commission";
import { getOssAccessInfo } from "../../api/common";

import Sha1 from "js-sha1";
var OSS = require("ali-oss");

export default {
  name: "agent",
  components: {
    ClassifyLimit,
    ClassifyFc,
  },
  filters: {
    numberFormat: function (value) {
      return value.toFixed(2);
    },
  },
  data() {
    return {
      dkRatioVisible: false,
      dkForm: {
        ratioInfo: {
          lever_source: 1,
        },
        ratioBase: [],
      },

      dkInfo: {
        userId: "",
        userType: "TEAM", //用户: USER 经纪人:TEAM 合伙人:CHA
      },

      agentLevList: [],
      appRatioInfo: false,
      appRatioForm: {
        others: {},
        live: {},
      },
      classifyFcDialogVisible: false,
      classifyFcTitle: "",
      classifyLimitTitle: "",
      agentInfo: {},
      classifyLimitDialogVisible: false,
      merchantConfig: {
        agent_label: "",
      },
      agent_rules: {
        members_level_parent: -1,
        quantity: 0,
        members_level_child: -1,
        state: false,
      },
      membersLevelList: [],
      queryParams: {},
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 100,
      commissionForm: {},
      commissionVisible: false,
      synergyMerchants: false,
      channelTitle: "",
      channelForm: {},
      channelVisible: false,
      channelList: [],
      wxImgTitle: "",
      wxImgForm: {
        we_chat_img: "",
      },
      wxImgVisible: false,
      wxCustomerTitle: "",
      wxCustomerForm: {
        remark: "",
      },
      remark: "",
      settingRemark: false,
      wxCustomerVisible: false,
    };
  },
  created() {
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.doSynergyMerchantsCheck();
    this.getChannelList();
    this.getUserCommission();
    this.initData();
    this.sklmMembersLevelList();
    this.doAgentRulesInfo();
    this.merchantConfigInfo();
  },
  methods: {
    dkRatio(row) {
      this.dkRatioVisible = true;
      // console.log("dk id", row);
      this.dkInfo.userId = row.open_id;
      this.getDkRatioinfo();
    },
    getDkRatioinfo() {
      dkRatioinfo(this.dkInfo).then((res) => {
        console.log("抖客", res);
        this.dkForm = res.data;
      });
    },

    dkEdit() {
      console.log("抖客提交", this.dkForm.ratioInfo);

      dkRatioUpdate(this.dkForm.ratioInfo).then((res) => {
        if (res.code == 0) {
          this.$message.success("保存成功");
          this.dkRatioVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    radioChange(value, type) {
      if (value != 2) {
        this.appRatioForm[type].cus_lev_exp = false;
        this.appRatioForm[type].cus_lev_exp_time = null;
      }
    },
    switchChange(type) {
      this.appRatioForm[type].cus_lev_exp_time = null;
    },
    closeWxDialog() {
      this.remark = "";
      this.wxCustomerVisible = false;
      this.settingRemark = false;
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchants/agent/we_chat_img" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.wxImgForm.we_chat_img =
            "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
          this.$message.success("头像上传成功");
        });
      });
    },
    sureWxImg() {
      updateWechat(this.wxImgForm).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
          this.initData();
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    sureWxCustomer() {
      // 设置备注
      if (this.settingRemark) {
        this.wxCustomerForm.remark = this.remark;
        updateRemarkCustomer(this.wxCustomerForm).then((res) => {
          if (res.code == 0) {
            this.$message.success({
              duration: 1000,
              message: "操作成功",
            });
            this.initData();
            this.closeWxDialog();
          } else {
            this.$message.error({ message: res.message });
          }
        });
      } else {
        // 设置企微客服url
        updateWxCustomer(this.wxCustomerForm).then((res) => {
          if (res.code == 0) {
            this.$message.success({
              duration: 1000,
              message: "操作成功",
            });
            this.initData();
          } else {
            this.$message.error({ message: res.message });
          }
        });
      }
    },
    search() {
      this.page_no = 1;
      this.initData();
    },
    getUserCommission() {
      user_commission({}).then((res) => {
        this.agentLevList = res.data.agentLevList;
      });
    },
    doRatioInfo(row) {
      ratioAgentInfo({ agentId: row.open_id }).then((res) => {
        if (res.status == 1) {
          this.appRatioForm = res.data;
          this.appRatioInfo = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    sureAppRatio() {
      let otherLev = this.appRatioForm.others.custom_lever;
      for (let i = 0; i < this.agentLevList.length; i++) {
        if (this.agentLevList[i].agent_lever_id === otherLev) {
          this.appRatioForm.others.custom_lever_index = i + 1;
          break;
        }
      }
      let liveLev = this.appRatioForm.live.custom_lever;
      for (let i = 0; i < this.agentLevList.length; i++) {
        if (this.agentLevList[i].agent_lever_id === liveLev) {
          this.appRatioForm.live.custom_lever_index = i + 1;
          break;
        }
      }
      if (
        (this.appRatioForm.others.cus_lev_exp &&
          !this.appRatioForm.others.cus_lev_exp_time) ||
        (this.appRatioForm.live.cus_lev_exp &&
          !this.appRatioForm.live.cus_lev_exp_time)
      ) {
        this.$message.error("请选择过期时间");
        return;
      }
      ratioAgentUpdate(this.appRatioForm).then((res) => {
        if (res.status == 1) {
          this.appRatioInfo = false;
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    getChannelList() {
      let queryParams = {};
      (queryParams.page = 1),
        (queryParams.limit = 999999),
        channelList(queryParams).then((response) => {
          let channelList = [];
          let item = {
            id: -1,
            user_name: "待定",
          };
          channelList.push(item);
          this.channelList = channelList.concat(response.data.list);
        });
    },
    sureChannelUpdate() {
      console.log(JSON.stringify(this.channelForm));
      updateChannel({}, this.channelForm).then((res) => {
        if (res.status == 1) {
          this.initData();
          this.channelVisible = false;
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    doSynergyMerchantsCheck() {
      synergyMerchantsCheck({}).then((res) => {
        this.synergyMerchants = res.data;
      });
    },
    sureCommission() {
      console.log(JSON.stringify(this.commissionForm));
      commission(this.commissionForm).then((res) => {
        if (res.status == 1) {
          this.initData();
          this.commissionVisible = false;
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.message);
        }
      });
    },
    commission(row) {
      this.commissionForm = {
        open_id: row.open_id,
        com_type: row.com_type,
        agent_proportion: row.agent_proportion,
      };
      console.log(JSON.stringify(this.commissionForm));
      this.commissionVisible = true;
    },
    doUpdateChannel(row) {
      console.log(JSON.stringify(row));
      let info = row;
      this.channelTitle = "渠道合作伙伴设置-" + info.nick_name;
      this.channelForm = row;
      this.channelVisible = true;
    },
    doUpdateWxImg(row) {
      let info = row;
      this.wxImgTitle = "设置企微二维码-" + info.nick_name;
      this.wxImgForm = row;
      this.wxImgVisible = true;
    },
    doUpdateWxCustomer(row) {
      let info = row;
      this.wxCustomerTitle = "设置企微客服url-" + info.nick_name;
      this.wxCustomerForm = row;
      this.wxCustomerVisible = true;
    },
    doUpdateRemarkCustomer(row) {
      this.settingRemark = true;
      let info = row;
      this.wxCustomerTitle = "设置备注-" + info.nick_name;
      this.wxCustomerForm = row;
      this.remark = this.wxCustomerForm.remark;
      this.wxCustomerVisible = true;
    },
    doClassify(row) {
      console.log(JSON.stringify(row));
      let merchantInfo = row;
      this.classifyFcTitle = "MCN佣金分成管理-" + merchantInfo.nick_name;
      this.agentInfo = merchantInfo;
      this.classifyFcDialogVisible = true;
    },
    classifyLimit(row) {
      console.log(JSON.stringify(row));
      let merchantInfo = row;
      this.classifyLimitTitle = "IP名额管理-" + merchantInfo.nick_name;
      this.agentInfo = merchantInfo;
      this.classifyLimitDialogVisible = true;
    },
    merchantConfigUpdate() {
      merchantsConfigUpdate(this.merchantConfig).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    merchantConfigInfo() {
      merchantsConfig().then((res) => {
        this.merchantConfig = res.data;
      });
    },
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 362 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },
    sureAgentRules() {
      agentRulesUpdate(this.agent_rules).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
          this.doAgentRulesInfo();
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    doAgentRulesInfo() {
      agentRulesInfo().then((response) => {
        if (response.data != null) {
          this.agent_rules = response.data;
        }
      });
    },
    sklmMembersLevelList() {
      let queryParams = {
        page: 1,
        limit: 100,
      };
      sklmMembersLevelList(queryParams).then((response) => {
        let membersLevelList = response.data.list;
        let item = {
          id: -1,
          members_name: "所有等级",
        };
        membersLevelList.push(item);
        this.membersLevelList = membersLevelList;
      });
    },
    doSetBroker(row) {
      this.$confirm(
        "确定取消 " + row.nick_name + " 经纪人身份吗?",
        "取消经纪人",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          let body = {
            open_id: row.open_id,
          };
          setBroker({}, body).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.initData();
            } else {
              this.$message.error({ message: res.message });
            }
          });
        })
        .catch(() => {});
    },
    cancelAgent(row) {
      this.editForm = row;
      this.$confirm(
        "确定取消 " + row.nick_name + " 经纪人身份吗?",
        "取消经纪人",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.editForm.agent = 2;
          sklmUserUpdate({}, this.editForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.initData();
            } else {
              this.$message.error({ message: res.message });
            }
          });
        })
        .catch(() => {});
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams;
      (queryParams.page = this.page_no),
        (queryParams.limit = this.page_size),
        (queryParams.sidx = "swu.create_time"),
        (queryParams.order = "desc");
      queryParams.agent = 1;
      sklmUserList(queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style scoped>
/* ::v-deep .el-dialog__body {
  padding-top: 0px;
} */

.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.flex_left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.expire_text {
  font-size: 14px;
  margin-right: 4px;
}

.el-radio-group {
  display: flex;
  flex-direction: column;
}
</style>
