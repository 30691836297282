<template>
  <div class="content">
    <div style="width: 800px">
      <el-form
        size="small"
        :rules="rules"
        ref="ruleForm"
        :model="enterpriseCertInfo"
        v-model="labelPosition"
        label-width="180px"
      >
        <div class="content_title">企业资质信息</div>
        <el-form-item
          label="企业全称"
          style="margin-top: 20px"
          prop="company_name"
        >
          <el-input
            placeholder="请输入企业全称"
            v-model="enterpriseCertInfo.company_name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="营业执照" prop="business_license_img">
          <div class="my_form_content">
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadBusinessLicenseImg"
            >
              <img
                v-if="enterpriseCertInfo.business_license_img"
                :src="enterpriseCertInfo.business_license_img"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon">
                <div class="upload_tips">上传营业执照照片</div>
              </i>
            </el-upload>

            <el-image
              ref="previewImg"
              style="margin-left: 50px; width: 100px"
              :src="license_example"
              fit="cover"
              :preview-src-list="getSrcList(license_example)"
            />
            <div class="opt_btn" @click="viewBigPicture('previewImg')">
              示例
            </div>
          </div>
          <span class="img_rules"
            >格式要求：原件照片、扫描件或者加盖公章的复印件，支持.jpg .jpeg .bmp
            .gif .png格式照片，大小不超过10M。</span
          >
        </el-form-item>
        <el-form-item label="统一社会信用编码" prop="credit_code">
          <el-input
            placeholder="请输入统一社会信用编码"
            v-model="enterpriseCertInfo.credit_code"
            clearable
          ></el-input>
          <span class="img_rules"
            >务必与营业执照上的注册号或统一社会信用代码一致</span
          >
        </el-form-item>
        <div class="content_title">法人信息</div>
        <el-form-item
          label="法人姓名"
          style="margin-top: 20px"
          prop="legal_person_name"
        >
          <el-input
            placeholder="请输入法人姓名"
            v-model="enterpriseCertInfo.legal_person_name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证号码" prop="legal_person_num">
          <el-input
            placeholder="请输入法人身份证号码"
            v-model="enterpriseCertInfo.legal_person_num"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="法人身份证照片" prop="legal_person_id_img_front">
          <div style="display: flex">
            <div class="my_form_content">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :http-request="uploadPersobImgFront"
              >
                <div>
                  <img
                    v-if="enterpriseCertInfo.legal_person_id_img_front"
                    :src="enterpriseCertInfo.legal_person_id_img_front"
                    class="avatar"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon">
                    <div class="upload_tips">上传身份证正面照片</div>
                  </i>
                </div>
              </el-upload>
            </div>
            <div class="my_form_content" style="margin-left: 20px">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :http-request="uploadPersobImgBack"
              >
                <img
                  v-if="enterpriseCertInfo.legal_person_id_img_back"
                  :src="enterpriseCertInfo.legal_person_id_img_back"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon">
                  <div class="upload_tips">上传身份证背面照片</div>
                </i>
              </el-upload>
            </div>
          </div>
          <span class="img_rules"
            >格式要求：原件照片，支持.jpg .png格式照片，大小不超过10M。</span
          >
        </el-form-item>
        <div class="content_title">联系人信息</div>
        <el-form-item
          label="联系人姓名"
          style="margin-top: 20px"
          prop="contact_name"
        >
          <el-input
            placeholder="请输入联系人姓名"
            v-model="enterpriseCertInfo.contact_name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人手机号" prop="contact_phone_no">
          <el-input
            placeholder="请输入联系人手机号"
            v-model="enterpriseCertInfo.contact_phone_no"
            clearable
          >
            <template slot="append">
              <el-button
                type="primary"
                @click="getSms()"
                size="mini"
                :disabled="disable"
                >{{ buttonName }}</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="短信验证码"
          style="margin-top: 20px"
          prop="security_code"
        >
          <el-input
            placeholder="请输入短信验证码"
            v-model="enterpriseCertInfo.security_code"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人微信号" prop="contact_wechat">
          <el-input
            placeholder="请输入联系人微信号"
            v-model="enterpriseCertInfo.contact_wechat"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        style="
          display: flex;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: 100px;
        "
      >
        <el-button size="small" @click="back">上一步</el-button>
        <el-button
          type="primary"
          size="small"
          style="margin-left: 100px"
          @click="doMerchantsUpdate('ruleForm')"
          >提交审核</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import {
  enterpriseCertInfo,
  enterpriseCertUpdate,
} from "../../api/merchantsUser";
import { getMobileCode, getOssAccessInfo } from "../../api/common";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");

export default {
  name: "UploadInformation",
  data() {
    return {
      license_example:
        "https://merchants-center.oss-cn-hangzhou.aliyuncs.com/img/license_example.jpg",
      security_code: "",
      disable: false,
      count: 60,
      buttonName: "获取验证码",
      labelPosition: "right",
      enterpriseCertInfo: {
        security_code: "",
        business_license_img: "",
      },
      rules: {
        company_name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        credit_code: [
          {
            required: true,
            message: "请输入统一社会信用编码",
            trigger: "blur",
          },
        ],
        business_license_img: [
          { required: true, message: "请上传营业执照", trigger: "change" },
        ],
        legal_person_name: [
          { required: true, message: "请输入法人姓名", trigger: "blur" },
        ],
        legal_person_num: [
          { required: true, message: "请输入法人身份证号码", trigger: "blur" },
        ],
        legal_person_id_img_front: [
          { required: true, message: "请上传法人身份证照片", trigger: "blur" },
        ],
        legal_person_id_img_back: [
          { required: true, message: "请上传法人身份证照片", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" },
        ],
        contact_phone_no: [
          { required: true, message: "请输入联系人手机号", trigger: "blur" },
        ],
        security_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        contact_wechat: [
          { required: true, message: "请输入联系人微信号", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.doEnterpriseCertInfo();
  },
  methods: {
    getSrcList(src) {
      let srcList = [];
      srcList.push(src);
      return srcList;
    },
    viewBigPicture(previewImg) {
      this.$refs[previewImg].showViewer = true;
    },
    back() {
      this.$router.go(-1);
    },
    handleAvatarSuccess(res, file) {
      console.log("handleAvatarSuccess");
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      console.log("beforeAvatarUpload");
      return true;
    },
    uploadBusinessLicenseImg(res) {
      this.uploadFile(res, 1);
    },
    uploadPersobImgFront(res) {
      this.uploadFile(res, 2);
    },
    uploadPersobImgBack(res) {
      this.uploadFile(res, 3);
    },
    uploadFile(res, type) {
      console.log("uploadFile");
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/identity/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          let fileUrl =
            "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
          console.log("fileUrl:" + fileUrl);
          if (type === 1) {
            this.enterpriseCertInfo.business_license_img = fileUrl;
          }
          if (type === 2) {
            this.enterpriseCertInfo.legal_person_id_img_front = fileUrl;
          }
          if (type === 3) {
            this.enterpriseCertInfo.legal_person_id_img_back = fileUrl;
          }
        });
      });
    },
    doMerchantsUpdate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          enterpriseCertUpdate(this.enterpriseCertInfo).then((res) => {
            if (res.code == 0) {
              this.$message.success("信息提交成功");
              this.$router.push("/account");
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getSms() {
      let phone = this.enterpriseCertInfo.contact_phone_no;
      console.log("phone:" + phone);
      const regMobile =
        /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/;
      if (!regMobile.test(phone)) {
        this.$message.error("手机号码有误,请重新输入");
        return false;
      }
      let query = {
        mobile: phone,
        scene: "auth_check",
      };
      getMobileCode(query).then((res) => {
        if (res.code == 0) {
          var timeout = setInterval(() => {
            if (this.count < 1) {
              this.disable = false;
              this.buttonName = "获取验证码";
              this.count = 120;
              clearInterval(timeout);
            } else {
              this.disable = true;
              this.buttonName = this.count-- + "s后重发";
            }
          }, 1000);
          this.$message.success("验证码发送成功");
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    doEnterpriseCertInfo() {
      enterpriseCertInfo({}).then((res) => {
        this.enterpriseCertInfo = res.data;
      });
    },
  },
};
</script>

<style scoped>
.content {
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content_title {
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.my_form_content {
  display: flex;
  align-items: center;
}

.opt_btn {
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 119, 255, 1);
}

.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 20px;
  color: #8c939d;
}

.upload_tips {
  margin-top: 10px;
  font-size: 10px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.img_rules {
  font-size: 12px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}
</style>
