import Layout from "../views/layout/Layout";
import merchantsInfo from "../views/merchants/merchantsInfo";
import merchantsInfoCustom from "../views/merchants/merchantsInfoCustom";
import CardResd from "../views/merchants/CardResd";
import AuthenticationProtocol from "../views/merchants/AuthenticationProtocol";
import UploadInformation from "../views/merchants/UploadInformation";
import CertificationDetails from "../views/merchants/CertificationDetails";

const template = [
    {
        path:"/merchants",
        name:'账户中心',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/zhzx.png',
        component: Layout,
        isHidden : true,
        isMenu:false,
        children:[
            {
                path:"/merchantsInfo",
                name:'账户信息',
                isHidden : true,
                component: merchantsInfo
            },
            {
                path:"/merchantsInfoCustom",
                name:'账户中心',
                component: merchantsInfoCustom,
                isHidden : true,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:false,
                        showUserInfo:false,
                        menu_item_active: 'other'
                    }
                }
            },
            {
                path:"/authentication_protocol",
                name:'企业认证协议',
                component: AuthenticationProtocol,
                isHidden : true,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:false,
                        showUserInfo:false,
                        menu_item_active: 'other'
                    }
                }
            },
            {
                path:"/upload_information",
                name:'上传信息',
                component: UploadInformation,
                isHidden : true,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:false,
                        showUserInfo:false,
                        menu_item_active: 'other'
                    }
                }
            },
            {
                path:"/certification_details",
                name:'认证详情',
                component: CertificationDetails,
                isHidden : true,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:false,
                        showUserInfo:false,
                        menu_item_active: 'other'
                    }
                }
            },
            {
                path:"/cardResd",
                name:'生成卡管理',
                isHidden : true,
                component: CardResd,
            },
            {
                path:"/cardResdCustom",
                name:'生成卡管理',
                component: CardResd,
                isHidden : true,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:false,
                        showUserInfo:false,
                        menu_item_active: 'other'
                    }
                }
            },
        ]
    }
]

export default template;
