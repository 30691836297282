import MaterialLayout from "../views/layout/MaterialLayout";
import material_config from "../views/material/material_config";
import material_gen_video from "../views/material/material_gen_video";
const template = [
    {
        path:"/material_config",
        name:'素材配置',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/scpz.png',
        component: MaterialLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/material_config",
                name:'素材配置',
                component: material_config,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            }
        ]
    },
    {
        path:"/material_gen_video",
        name:'测试模板生成',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/csmbsc.png',
        component: MaterialLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/material_gen_video",
                name:'测试模板生成',
                component: material_gen_video,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            }
        ]
    }
]

export default template;
