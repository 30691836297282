<template>
  <div class="content">
    <div style="width: 600px;">
      <el-form size="small" ref="form" :model="enterpriseCertInfo"  v-model="labelPosition">
        <div class="content_title">
          企业资质信息
        </div>
        <el-form-item label="企业全称" style="margin-top: 20px">
          {{enterpriseCertInfo.company_name}}
        </el-form-item>
        <el-form-item label="营业执照">
          <div class="my_form_content">
            <el-image
                ref="previewImg"
                style="width: 100px;"
                :src="enterpriseCertInfo.business_license_img"
                fit="cover"
                :preview-src-list="getSrcList(enterpriseCertInfo.business_license_img)"
            />
            <div class="opt_btn" @click="viewBigPicture('previewImg')">查看大图</div>
          </div>
        </el-form-item>
        <el-form-item label="统一社会信用编码">
          {{enterpriseCertInfo.credit_code}}
        </el-form-item>
        <div class="content_title">
          法人信息
        </div>
        <el-form-item label="法人姓名" style="margin-top: 20px">
          {{enterpriseCertInfo.legal_person_name}}
        </el-form-item>
        <el-form-item label="法人身份证号码">
          {{enterpriseCertInfo.legal_person_num}}
        </el-form-item>
        <el-form-item label="法人身份证照片">
            <div style="display: flex">
              <div class="my_form_content">
                <el-image
                    ref="previewImg2"
                    style="width: 100px;"
                    :src="enterpriseCertInfo.legal_person_id_img_front"
                    fit="cover"
                    :preview-src-list="getSrcList(enterpriseCertInfo.legal_person_id_img_front)"
                />
                <div class="opt_btn" @click="viewBigPicture('previewImg2')">查看大图</div>
              </div>
              <div class="my_form_content" style="margin-left: 20px">
                <el-image
                    ref="previewImg3"
                    style="width: 100px;"
                    :src="enterpriseCertInfo.legal_person_id_img_back"
                    fit="cover"
                    :preview-src-list="getSrcList(enterpriseCertInfo.legal_person_id_img_back)"
                />
                <div class="opt_btn" @click="viewBigPicture('previewImg3')">查看大图</div>
              </div>
            </div>
        </el-form-item>
        <div class="content_title">
          联系人信息
        </div>
        <el-form-item label="联系人姓名" style="margin-top: 20px">
          {{enterpriseCertInfo.contact_name}}
        </el-form-item>
        <el-form-item label="联系人手机号">
          {{enterpriseCertInfo.contact_phone_no}}
        </el-form-item>
        <el-form-item label="联系人微信号">
          {{enterpriseCertInfo.contact_wechat}}
        </el-form-item>
      </el-form>
    </div>

    <el-button style="margin-bottom: 100px" size="small" type="primary" @click="authenticationProtocol">重新认证</el-button>
  </div>
</template>

<script>
import {enterpriseCertInfo} from "../../api/merchantsUser";

export default {
  name: "CertificationDetails",
  data(){
    return{
      labelPosition: 'right',
      enterpriseCertInfo:{}
    }
  },
  mounted(){
    this.doEnterpriseCertInfo()
  },
  methods:{
    authenticationProtocol(){
      this.$router.push("/authentication_protocol");
    },
    recertification(){
      this.$router.push("/upload_information");
    },
    viewBigPicture(previewImg){
      this.$refs[previewImg].showViewer = true;
    },
    getSrcList(src){
      let srcList = []
      srcList.push(src)
      return srcList
    },
    doEnterpriseCertInfo(){
      enterpriseCertInfo({}).then((res) => {
        this.enterpriseCertInfo = res.data;
      });
    },
  }
}
</script>

<style scoped>

.content{
  width: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content_title{
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}


.el-form-item{
  display: flex;
  align-items: center;
}

/deep/ .el-form-item__label{
  width: 180px;
}

.my_form_content{
  display: flex;
  align-items: center;
}

.opt_btn{
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 119, 255, 1);
}

</style>
