<template>
<div>
  订单列表
</div>
</template>

<script>
export default {
  name: "order_list"
}
</script>

<style scoped>

</style>
