<template>
<div>
  <div class="head-info">
    <img src="../../assets/head_img.jpg" class="head_img" />
    <span class="app_head_name">商户后台</span>
  </div>

  <div style="display: flex;justify-content: center">
    <el-form size="small" ref="form" :model="form" :rules="rules" style="width: 30%;">
      <span class="form_head">注册</span>
      <el-form-item style="margin-top: 10px" prop="name">
        <el-input v-model="form.name" placeholder="商户名"></el-input>
      </el-form-item>
<!--      <el-form-item prop="contact">-->
<!--        <el-input v-model="form.contact" placeholder="联系人"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item prop="mobile_phone">
        <el-input v-model="form.mobile_phone" placeholder="登录手机号">
          <template slot="append">
            <el-button
                type="primary"
                @click="registerCheck()"
                size="mini"
                :disabled="disable"
            >{{ buttonName }}</el-button
            >
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="form.code" placeholder="验证码"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="form.password" placeholder="请输入密码" autocomplete="off"></el-input>
        <span class="password_tips">8-16位，可由任意大小写字母、特殊字符和数字组成</span>
      </el-form-item>
      <el-form-item prop="sure_password">
        <el-input type="password" v-model="form.sure_password" placeholder="确认密码" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width: 100%" @click="submitForm('form')">注册</el-button>
      </el-form-item>
      <el-form-item>
        <div style="display: flex">
          <el-checkbox v-model="form.checked">
          </el-checkbox>
          <div style="font-size: 16px;font-weight: 400;margin-left: 10px">我已阅读并同意
            <span class="tips" @click="user_agreement">服务协议</span>
            和
            <span class="tips" @click="privacy_agreement">隐私政策</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <div style="width: 100%; text-align: center;font-size: 16px;font-weight: 400;">已有账号？
          <span class="tips" @click="login">去登录</span>
        </div>
      </el-form-item>
    </el-form>
  </div>


</div>
</template>

<script>
import {getMobileCode} from "../../api/common";
import { registerCheck ,register } from "../../api/merchantsUser.js"

export default {
  name: "index",
  data(){
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        var matcher = new RegExp("^[\\S]{8,16}$");
        if (matcher.test(value)) {
          callback();
        } else {
          callback(new Error("密码为8-16位，可由任意大小写字母、特殊字符和数字组成"));
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        var matcher = new RegExp("^[\\S]{8,16}$");
        if (matcher.test(value)) {
          callback();
        } else {
          callback(new Error("密码为8-16位，可由任意大小写字母、特殊字符和数字组成"));
        }
      }
    };
    return{
      disable:false,
      count: 60,
      buttonName: "获取验证码",
      form:{
        name:'',
        contact:'',
        mobile_phone:'',
        code:'',
        password:'',
        sure_password:'',
        checked:false,
      },
      rules: {
        name: [
          { required: true, message: '请输入商户名', trigger: 'blur' }
        ],
        // contact: [
        //   { required: true, message: '请输入联系人', trigger: 'blur' }
        // ],
        mobile_phone: [
          { required: true, message: '请输入登录手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { validator: validatePass, trigger: 'blur' }
        ],
        sure_password: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  methods:{
    login(){
      this.$router.push("/login");
    },
    privacy_agreement(){
      const routeUrl = this.$router.resolve({
        path: "/privacy_agreement"
      });
      window.open(routeUrl.href, "_blank");
    },
    user_agreement(){
      const routeUrl = this.$router.resolve({
        path: "/user_agreement"
      });
      window.open(routeUrl.href, "_blank");
    },
    registerCheck(){
      let phone = this.form.mobile_phone
      console.log("phone:" + phone)
      const regMobile = /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/ ;
      if(!(regMobile.test(phone))){
        this.$message.error("登录手机号有误,请重新输入");
        return false;
      }
      registerCheck(this.form).then((res) => {
        if(res.code==0) {
          this.getMobileCode()
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    getMobileCode(){
      console.log("获取验证码")
      let phone = this.form.mobile_phone
      console.log("phone:" + phone)
      const regMobile = /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/ ;
      if(!(regMobile.test(phone))){
        this.$message.error("登录手机号有误,请重新输入");
        return false;
      }
      let query = {
        mobile: phone,
        scene: 'register'
      }
      getMobileCode(query).then((res) => {
        if(res.code==0) {
          var timeout= setInterval(() => {
            if (this.count < 1) {
              this.disable = false;
              this.buttonName = "获取验证码";
              this.count = 120;
              clearInterval(timeout);
            } else {
              this.disable = true;
              this.buttonName = this.count-- + "s后重发";
            }
          }, 1000);
          this.$message.success("验证码发送成功");
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    submitForm(formName) {
      if(this.form.checked === false){
        this.$message.error({message: "请确认已阅读相关协议"});
        return
      }
      let phone = this.form.mobile_phone
      console.log("phone:" + phone)
      const regMobile = /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/ ;
      if(!(regMobile.test(phone))){
        this.$message.error("登录手机号有误,请重新输入");
        return false;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let query = {
            security_code: this.form.code
          }
          register(query,this.form).then((res) => {
            if(res.code==0) {
              this.$message.success("注册成功!");
              this.$router.push("/login");
            }else{
                this.$message.error({message: res.message});
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style scoped>
.head-info {
  display: flex;
  align-items: center;
  padding: 20px;
}

.head_img{
  height: 36px;
}

.app_head_name{
  margin-left: 20px;
  font-size: 16px;
  color: black;
}

.form_head{
  font-size: 24px;
  font-weight: 700;
}


.tips{
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 119, 255, 1);
}


.password_tips{
  font-size: 14px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}

</style>
