import AccountCenterMenuLayout from "../views/layout/AccountCenterMenuLayout";
import account from "../views/accountCenter/account.vue";
import memberManage from "../views/merchants/MemberManagement.vue";
import membership from "../views/merchants/Collaboration.vue";
import OrderCenter from "../views/merchants/OrderCenter";
import TicketCenter from "../views/merchants/ticket_center";
const template = [
    {
        path:"/",
        name:'帐户中心',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: AccountCenterMenuLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/account",
                name:'账号管理',
                component: account,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'accountCenter'
                    }
                }
            },
            {
                path:"/membership",
                name:'概况',
                component: membership,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'accountCenter'
                    }
                }
            },
            {
                path:"/memberManage",
                name:'成员管理',
                component: memberManage,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'accountCenter'
                    }
                }
            }
        ]
    },
    {
        path:"/order_center",
        name:'概况',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: AccountCenterMenuLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/order_center",
                name:'概况',
                component: OrderCenter,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'accountCenter'
                    }
                }
            }
        ]
    },
    {
        path:"/ticket_center",
        name:'概况',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: AccountCenterMenuLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/ticket_center",
                name:'概况',
                component: TicketCenter,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'accountCenter'
                    }
                }
            }
        ]
    }
]

export default template;
