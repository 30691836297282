import service from "../request";

export function listInfo(query) {
    return service({
        url: '/api/merchants/members/list',
        method: 'get',
        params: query
    })
}

export function add(data) {
    return service({
        url: '/api/merchants/members/add',
        method: 'post',
        data: data
    })
}

export function del(query) {
    return service({
        url: '/api/merchants/members/del',
        method: 'get',
        params: query
    })
}
