import service from "../request";

export function getConf(query) {
    return service({
        url: '/api/merchants/classify/getConf',
        method: 'get',
        params: query
    })
}

export function saveConf(data) {
    return service({
        url: '/api/merchants/classify/saveConf',
        method: 'post',
        data: data
    })
}


export function queryPage(query) {
    return service({
        url: '/api/merchants/classify/queryPage',
        method: 'get',
        params: query
    })
}

export function queryUsePage(query) {
    return service({
        url: '/api/merchants/classify/queryUsePage',
        method: 'get',
        params: query
    })
}
export function marketingIp(query) {
    return service({
        url: '/api/merchants/classify/marketingIp',
        method: 'get',
        params: query
    })
}

export function synergyMerchantsCheck(query) {
    return service({
        url: '/api/merchants/synergyMerchants/check',
        method: 'get',
        params: query
    })
}


export function update(data) {
    return service({
        url: '/api/merchants/classify/update',
        method: 'post',
        data: data
    })
}

export function del(data) {
    return service({
        url: '/api/merchants/classify/del',
        method: 'post',
        data: data
    })
}
