import Layout from "../views/layout/Layout";
import userList from "../views/user/userList";
import TiktokList from "../views/user/TiktokList";
import TrillMcn from "../views/user/TrillMcn";
import userCheck from "../views/user/userCheck";
import memberOrder from "../views/user/memberOrder";
import WechatPayRefund from "../views/user/WechatPayRefund";
import douyin_member_res from "../views/user/douyin_member_res";
import userLeaver from "../views/user/userLeaver";
import exchangeCode from "../views/user/exchangeCode";
import douyin_member from "../views/user/douyin_member";
import userBalance from "../views/user/userBalance";
import channel_user from "../views/user/channel_user";
import agent from "../views/user/agent";
import ClassifyLimit from "../views/user/merchants_components/MerchantClassifyLimit";
const template = [
    {
        path:"/user",
        name:'用户管理',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/user.png',
        component: Layout,
        isMenu:true,
        children:[
            {
                path:"/userList",
                name:'用户列表',
                component: userList,
            },
            {
                path:"/userCheck",
                name:'会员审核',
                component: userCheck,
            },
            {
                path:"/memberOrder",
                name:'会员订单',
                component: memberOrder,
            },
            {
                path:"/wechatPayRefund",
                name:'退款订单管理',
                component: WechatPayRefund,
            },
            {
                path:"/userLeaver",
                name:'等级管理',
                component: userLeaver,
            },
            {
                path:"/exchangeCode",
                name:'会员兑换码',
                component: exchangeCode,
            },
            {
                path:"/agent",
                name:'经纪人管理',
                component: agent,
            },
            {
                path:"/userBalance",
                name:'用户余额调整',
                component: userBalance,
            },
            {
                path:"/channel_user",
                name:'渠道合作伙伴',
                component: channel_user,
            }
        ]
    }
]

export default template;
