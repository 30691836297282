<template>
  <div style="padding: 60px">
    <div style="font-size: 28px; font-weight: 500">账户中心</div>
    <el-tabs
      v-model="activeName"
      @tab-click="handleClick"
      style="margin-top: 20px"
    >
      <el-tab-pane label="账号管理" name="first">
        <div
          style="
            background: white;
            border-radius: 10px;
            padding: 20px;
            margin-top: 10px;
          "
        >
          <div style="font-size: 20px; font-weight: 500">基本信息</div>

          <el-form
            class="my_form"
            style="margin-top: 20px"
            size="small"
            :model="userInfo"
          >
            <el-form-item label="头像">
              <div class="my_form_content">
                <el-image
                  style="width: 100px"
                  :src="userInfo.head_portrait"
                  fit="cover"
                />
                <div class="opt_btn" @click="upHeadImgDialogVisible = true">
                  修改
                </div>
              </div>
            </el-form-item>
            <el-form-item label="用户名">
              <div class="my_form_content">
                <div>{{ userInfo.name }}</div>
                <div class="opt_btn" @click="nameDialogVisible = true">
                  修改
                </div>
              </div>
            </el-form-item>
            <el-form-item label="登录帐号">
              <div class="my_form_content">
                <div>{{ userInfo.mobile_phone | phoneDesensitization }}</div>
                <div class="opt_btn" @click="phoneCheckDialogVisible = true">
                  修改
                </div>
              </div>
            </el-form-item>
            <el-form-item label="登录密码">
              <div class="my_form_content">
                <div>********</div>
                <div class="opt_btn" @click="paswdDialogVisible = true">
                  修改
                </div>
              </div>
            </el-form-item>
            <el-form-item label="系统ID">
              <div>{{ userInfo.id }}</div>
            </el-form-item>
            <el-form-item label="注册时间">
              <div>{{ userInfo.create_time }}</div>
            </el-form-item>
          </el-form>
        </div>
        <div
          style="
            background: white;
            border-radius: 10px;
            padding: 20px;
            margin-top: 10px;
          "
        >
          <div style="font-size: 20px; font-weight: 500">身份信息</div>
          <el-form
            class="my_form"
            style="margin-top: 20px"
            size="small"
            :model="userInfo"
          >
            <el-form-item label="认证企业">
              <div class="my_form_content">
                <span v-if="enterpriseCertInfo.certification_status === 0">
                  <span style="font-size: 14px; font-weight: 500">未认证</span>
                  <span
                    style="
                      margin-left: 10px;
                      font-size: 14px;
                      font-weight: 500;
                      color: rgba(0, 119, 255, 1);
                      cursor: pointer;
                    "
                    @click="authenticationProtocol"
                    >去认证</span
                  >
                </span>

                <span v-if="enterpriseCertInfo.certification_status === 2">
                  <span style="font-size: 14px; font-weight: 500">{{
                    enterpriseCertInfo.company_name
                  }}</span>
                  <span
                    style="
                      margin-left: 10px;
                      font-size: 14px;
                      font-weight: 500;
                      color: rgba(128, 128, 128, 1);
                    "
                    >机审通过，等待人审</span
                  >
                  <span
                    style="
                      margin-left: 10px;
                      font-size: 14px;
                      font-weight: 500;
                      color: rgba(0, 119, 255, 1);
                      cursor: pointer;
                    "
                    @click="certificationDetails"
                    >查看详情</span
                  >
                </span>

                <span v-if="enterpriseCertInfo.certification_status === 3">
                  <span style="font-size: 14px; font-weight: 500">{{
                    enterpriseCertInfo.company_name
                  }}</span>
                  <span
                    style="
                      margin-left: 10px;
                      font-size: 14px;
                      font-weight: 500;
                      color: rgba(0, 119, 255, 1);
                      cursor: pointer;
                    "
                    @click="certificationDetails"
                    >查看详情</span
                  >
                  <span
                    style="
                      margin-left: 10px;
                      font-size: 14px;
                      font-weight: 500;
                      color: rgba(0, 119, 255, 1);
                      cursor: pointer;
                    "
                    @click="authenticationProtocol"
                    >重新认证</span
                  >
                </span>

                <span v-else-if="enterpriseCertInfo.certification_status === 4">
                  <span style="font-size: 14px; font-weight: 500">{{
                    enterpriseCertInfo.company_name
                  }}</span>
                  <span
                    style="
                      margin-left: 10px;
                      font-size: 14px;
                      font-weight: 500;
                      color: rgba(255, 87, 51, 1);
                    "
                    >认证失败</span
                  >
                  <span
                    style="
                      margin-left: 10px;
                      font-size: 14px;
                      font-weight: 500;
                      color: rgba(0, 119, 255, 1);
                      cursor: pointer;
                    "
                    @click="authenticationProtocol"
                    >重新认证</span
                  >
                </span>
              </div>
            </el-form-item>
            <el-form-item
              label="失败原因"
              v-if="enterpriseCertInfo.certification_status === 4"
            >
              <div class="my_form_content">
                <span
                  style="
                    font-size: 12px;
                    font-weight: 500;
                    color: rgba(56, 56, 56, 1);
                  "
                  >{{ enterpriseCertInfo.cause_failure }}</span
                >
              </div>
            </el-form-item>
            <el-form-item
              label="认证时间"
              v-if="enterpriseCertInfo.certification_status !== 0"
            >
              <div class="my_form_content">
                {{ enterpriseCertInfo.update_time }}
              </div>
            </el-form-item>
          </el-form>

          <div
            v-if="
              enterpriseCertInfo.certification_status === 2 ||
              enterpriseCertInfo.certification_status === 3
            "
          >
            <el-form
              class="my_form"
              style="margin-top: 20px"
              size="small"
              :model="userInfo"
            >
              <el-form-item label="联系人姓名">
                <div class="my_form_content">
                  <div v-if="userInfo.contact">{{ userInfo.contact }}</div>
                  <div v-else>暂未设置</div>
                  <div class="opt_btn" @click="contactNameDialogVisible = true">
                    修改
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="联系人手机号">
                <div class="my_form_content">
                  <div v-if="userInfo.contact_phone">
                    {{ userInfo.contact_phone }}
                  </div>
                  <div v-else>暂未设置</div>
                  <div
                    class="opt_btn"
                    @click="contactPhoneDialogVisible = true"
                  >
                    修改
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="联系人微信号">
                <div class="my_form_content">
                  <div v-if="userInfo.contact_we_chat">
                    {{ userInfo.contact_we_chat }}
                  </div>
                  <div v-else>暂未设置</div>
                  <div
                    class="opt_btn"
                    @click="contactWechatDialogVisible = true"
                  >
                    修改
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="成员身份" name="second">
        <Collaboration v-if="activeName == 'second'"></Collaboration>
      </el-tab-pane>

      <el-tab-pane label="成员管理" name="three">
        <MemberManagement v-if="activeName == 'three'"></MemberManagement>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="修改头像"
      :visible.sync="upHeadImgDialogVisible"
      width="30%"
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :http-request="uploadImg"
        >
          <div>
            <img
              v-if="updateUserInfo.head_portrait"
              :src="updateUserInfo.head_portrait"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon">
              <div class="tips">上传头像</div>
            </i>
          </div>
        </el-upload>
        <div class="tips">建议上传的头像尺寸为1:1</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="upHeadImgDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="upHeadImg"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="修改用户名"
      :visible.sync="nameDialogVisible"
      width="30%"
    >
      <el-input
        clearable
        size="small"
        v-model="updateUserInfo.name"
        placeholder="请输入用户名"
      ></el-input>
      <div class="tips">
        4-25位字符，可由任意数字、大小写字母、下划线或短横线组成
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="nameDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="nameUpdate"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="手机号验证"
      :visible.sync="phoneCheckDialogVisible"
      width="30%"
    >
      <div class="tips">
        已绑定手机号
        {{ userInfo.mobile_phone | phoneDesensitization }}
        （更换手机号需要先验证身份）
      </div>
      <el-input
        clearable
        size="small"
        v-model="updateUserInfo.security_code"
        placeholder="请输入验证码"
      >
        <template slot="append">
          <el-button
            type="primary"
            @click="getMobileCode(1)"
            size="mini"
            :disabled="disable"
            >{{ buttonName }}</el-button
          >
        </template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="phoneCheckDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="phoneCheck"
          >下一步</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="验证新手机号"
      :visible.sync="newPhoneCheckDialogVisible"
      width="30%"
    >
      <el-input
        maxlength="11"
        clearable
        size="small"
        v-model="updateUserInfo.contact_phone"
        placeholder="请输入新手机号"
      ></el-input>
      <el-input
        clearable
        size="small"
        v-model="updateUserInfo.security_code"
        placeholder="请输入验证码"
      >
        <template slot="append">
          <el-button
            type="primary"
            @click="getMobileCode(2)"
            size="mini"
            :disabled="disable"
            >{{ buttonName }}</el-button
          >
        </template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="newPhoneCheckDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="newPhoneCheck"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="修改登录密码"
      :visible.sync="paswdDialogVisible"
      width="30%"
    >
      <el-form ref="passform" :model="updateUserInfo" :rules="paswdRules">
        <el-form-item>
          <div class="tips">
            已绑定手机号
            {{ userInfo.mobile_phone | phoneDesensitization }}
            （修改登录密码需要先验证身份）
          </div>
        </el-form-item>
        <el-form-item>
          <el-input
            clearable
            v-model="updateUserInfo.security_code"
            placeholder="请输入验证码"
          >
            <template slot="append">
              <el-button
                type="primary"
                @click="getMobileCode(1)"
                size="mini"
                :disabled="disable"
                >{{ buttonName }}</el-button
              >
            </template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            clearable
            v-model="updateUserInfo.password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>
        <div class="tips">8-16位，可由任意大小写字母、特殊字符和数字组成</div>
        <el-form-item prop="sure_password">
          <el-input
            clearable
            v-model="updateUserInfo.sure_password"
            placeholder="确认新密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paswdDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="paswdUpdate('passform')"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="修改联系人姓名"
      :visible.sync="contactNameDialogVisible"
      width="30%"
    >
      <el-input
        clearable
        size="small"
        v-model="updateUserInfo.contact"
        placeholder="请输入联系人姓名"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="contactNameDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="contactName"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="修改联系人手机号"
      :visible.sync="contactPhoneDialogVisible"
      width="30%"
    >
      <el-input
        maxlength="11"
        clearable
        size="small"
        v-model="updateUserInfo.contact_phone"
        placeholder="请输入新手机号"
        @click="getMobileCode(2)"
      ></el-input>
      <el-input
        clearable
        size="small"
        v-model="updateUserInfo.security_code"
        placeholder="请输入验证码"
      >
        <template slot="append">
          <el-button
            type="primary"
            @click="getMobileCode(2)"
            size="mini"
            :disabled="disable"
            >{{ buttonName }}</el-button
          >
        </template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="contactPhoneDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="contactPhone"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      title="修改联系人微信号"
      :visible.sync="contactWechatDialogVisible"
      width="30%"
    >
      <el-input
        clearable
        size="small"
        v-model="updateUserInfo.contact_we_chat"
        placeholder="请输入联系人微信号"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="contactWechatDialogVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="contactWechat"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Sha1 from "js-sha1";
var OSS = require("ali-oss");
import {
  merchantsInfo,
  merchantsUpdate,
  authCheck,
  phoneCheck,
  updateAccount,
  enterpriseCertInfo,
} from "../../api/merchantsUser";
import { getOssAccessInfo, getMobileCode } from "../../api/common";

import Collaboration from "./Collaboration";
import MemberManagement from "./MemberManagement";

export default {
  name: "merchantsInfoCustom",
  components: {
    Collaboration,
    MemberManagement,
  },
  filters: {
    phoneDesensitization(value) {
      let char = "*";
      return value.replace(
        /(\d{3})\d*(\d{4})/,
        `$1${new Array(5).join(char)}$2`
      );
    },
  },
  watch: {
    contactWechatDialogVisible(newValue, oldValue) {
      this.initializeUpdateUserInfo();
    },
    contactPhoneDialogVisible(newValue, oldValue) {
      this.initializeUpdateUserInfo();
    },
    contactNameDialogVisible(newValue, oldValue) {
      this.initializeUpdateUserInfo();
    },
    paswdDialogVisible(newValue, oldValue) {
      this.initializeUpdateUserInfo();
    },
    newPhoneCheckDialogVisible(newValue, oldValue) {
      this.initializeUpdateUserInfo();
    },
    phoneCheckDialogVisible(newValue, oldValue) {
      this.initializeUpdateUserInfo();
    },
    nameDialogVisible(newValue, oldValue) {
      this.initializeUpdateUserInfo();
    },
    upHeadImgDialogVisible(newValue, oldValue) {
      this.initializeUpdateUserInfo();
    },
    "updateUserInfo.mobile_phone"(curVal, oldVal) {
      if (!curVal) {
        this.updateUserInfo.mobile_phone = "";
        return false;
      }
      this.updateUserInfo.mobile_phone = curVal.match(/\d/gi)
        ? curVal.match(/\d/gi).join("")
        : "";
    },
    "updateUserInfo.contact_phone"(curVal, oldVal) {
      if (!curVal) {
        this.updateUserInfo.contact_phone = "";
        return false;
      }
      this.updateUserInfo.contact_phone = curVal.match(/\d/gi)
        ? curVal.match(/\d/gi).join("")
        : "";
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        var matcher = new RegExp("^[\\S]{8,16}$");
        if (matcher.test(value)) {
          callback();
        } else {
          callback(
            new Error("密码为8-16位，可由任意大小写字母、特殊字符和数字组成")
          );
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.updateUserInfo.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        var matcher = new RegExp("^[\\S]{8,16}$");
        if (matcher.test(value)) {
          callback();
        } else {
          callback(
            new Error("密码为8-16位，可由任意大小写字母、特殊字符和数字组成")
          );
        }
      }
    };
    return {
      enterpriseCertInfo: {
        certification_status: null,
        company_name: "",
        cause_failure: "",
      },
      paswdRules: {
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        sure_password: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      buttonName: "获取验证码",
      count: 60,
      disable: false,
      timeInterval: null,
      contactWechatDialogVisible: false,
      contactPhoneDialogVisible: false,
      contactNameDialogVisible: false,
      paswdDialogVisible: false,
      newPhoneCheckDialogVisible: false,
      phoneCheckDialogVisible: false,
      nameDialogVisible: false,
      upHeadImgDialogVisible: false,
      activeName: "first",
      userInfo: {},
      updateUserInfo: {
        head_portrait: "",
        name: "",
        mobile_phone: "",
        security_code: "",
        password: "",
        sure_password: "",
        contact: "",
        contact_phone: "",
        contact_we_chat: "",
      },
    };
  },
  mounted() {
    this.initData();
    this.doEnterpriseCertInfo();
  },
  methods: {
    authenticationProtocol() {
      this.$router.push("/authentication_protocol");
    },
    certificationDetails() {
      this.$router.push("/certification_details");
    },
    recertification() {
      this.$router.push("/upload_information");
    },
    doEnterpriseCertInfo() {
      enterpriseCertInfo({}).then((res) => {
        this.enterpriseCertInfo = res.data;
      });
    },
    getMobileCode(type) {
      console.log("获取验证码");
      let phone = "";
      if (type == 1) {
        //商户信息修改验证码
        phone = this.userInfo.mobile_phone;
      } else {
        //更换联系人验证码
        phone = this.updateUserInfo.contact_phone;
      }
      console.log("phone:" + phone);
      const regMobile =
        /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/;
      if (!regMobile.test(phone)) {
        this.$message.error("手机号码有误,请重新输入");
        return false;
      }
      let query = {
        mobile: phone,
        scene: "auth_check",
      };
      getMobileCode(query).then((res) => {
        if (res.code == 0) {
          this.timeInterval = setInterval(() => {
            if (this.count < 1) {
              this.resetMobileCode();
            } else {
              this.disable = true;
              this.buttonName = this.count-- + "s后重发";
            }
          }, 1000);
          this.$message.success("验证码发送成功");
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    resetMobileCode() {
      this.disable = false;
      this.buttonName = "获取验证码";
      this.count = 120;
      clearInterval(this.timeInterval);
    },
    initializeUpdateUserInfo() {
      this.updateUserInfo = {
        head_portrait: "",
        name: "",
        mobile_phone: "",
        security_code: "",
        password: "",
        sure_password: "",
        contact: "",
        contact_phone: "",
        contact_we_chat: "",
      };
    },
    contactWechat() {
      let body = {
        id: this.userInfo.id,
        contact_we_chat: this.updateUserInfo.contact_we_chat,
      };
      console.log(JSON.stringify(body));
      merchantsUpdate({}, body).then((res) => {
        if (res.code == 0) {
          this.contactWechatDialogVisible = false;
          this.$message.success("联系人微信修改成功");
          this.initData();
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    contactPhone() {
      let query = {
        security_code: this.updateUserInfo.security_code,
        scene: "auth_check",
        phone: this.updateUserInfo.contact_phone,
      };
      phoneCheck(query).then((res) => {
        if (res.code == 0) {
          let body = {
            id: this.userInfo.id,
            contact_phone: this.updateUserInfo.contact_phone,
          };
          console.log(JSON.stringify(body));
          merchantsUpdate({}, body).then((res) => {
            if (res.code == 0) {
              this.contactPhoneDialogVisible = false;
              this.$message.success("联系人手机号修改成功");
              this.initData();
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    contactName() {
      let body = {
        id: this.userInfo.id,
        contact: this.updateUserInfo.contact,
      };
      console.log(JSON.stringify(body));
      merchantsUpdate({}, body).then((res) => {
        if (res.code == 0) {
          this.contactNameDialogVisible = false;
          this.$message.success("联系人姓名修改成功");
          this.initData();
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    paswdUpdate(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let query = {
            security_code: this.updateUserInfo.security_code,
            scene: "auth_check",
          };
          authCheck(query).then((res) => {
            if (res.code == 0) {
              if (this.updateUserInfo.password == "") {
                this.$message.error("密码不能为空!");
                return;
              }
              if (
                this.updateUserInfo.password !==
                this.updateUserInfo.sure_password
              ) {
                this.$message.error("两次密码输入不一致!");
                return;
              }
              let body = {
                id: this.userInfo.id,
                password: this.updateUserInfo.password,
              };
              console.log(JSON.stringify(body));
              merchantsUpdate({}, body).then((res) => {
                if (res.code == 0) {
                  this.paswdDialogVisible = false;
                  this.$message.success("商户登录密码修改成功");
                  this.initData();
                } else {
                  this.$message.error({ message: res.message });
                }
              });
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    newPhoneCheck() {
      //更新新手机号
      let query = {
        security_code: this.updateUserInfo.security_code,
        scene: "auth_check",
        phone: this.updateUserInfo.contact_phone,
      };
      phoneCheck(query).then((res) => {
        if (res.code == 0) {
          let body = {
            id: this.userInfo.id,
            mobile_phone: this.updateUserInfo.contact_phone,
          };
          console.log(JSON.stringify(body));
          updateAccount({}, body).then((res) => {
            if (res.code == 0) {
              this.newPhoneCheckDialogVisible = false;
              this.$message.success("商户登录账号修改成功");
              this.initData();
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    phoneCheck() {
      let query = {
        security_code: this.updateUserInfo.security_code,
        scene: "auth_check",
      };
      authCheck(query).then((res) => {
        if (res.code == 0) {
          let body = {
            id: this.userInfo.id,
            security_code: this.updateUserInfo.security_code,
          };
          console.log(JSON.stringify(body));
          this.phoneCheckDialogVisible = false;
          this.resetMobileCode();
          this.newPhoneCheckDialogVisible = true;
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    nameUpdate() {
      let body = {
        id: this.userInfo.id,
        name: this.updateUserInfo.name,
      };
      console.log(JSON.stringify(body));
      merchantsUpdate({}, body).then((res) => {
        if (res.code == 0) {
          this.nameDialogVisible = false;
          this.$message.success("用户名修改成功");
          this.initData();
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    upHeadImg() {
      let body = {
        id: this.userInfo.id,
        head_portrait: this.updateUserInfo.head_portrait,
      };
      console.log(JSON.stringify(body));
      merchantsUpdate({}, body).then((res) => {
        if (res.code == 0) {
          this.upHeadImgDialogVisible = false;
          this.$message.success("头像修改成功");
          this.initData();
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/banner/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.updateUserInfo.head_portrait =
            "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
          this.$message.success("头像上传成功");
        });
      });
    },
    initData() {
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },
};
</script>

<style scoped>
.el-form-item--small {
  display: flex;
  align-items: center;
}

/deep/ .el-form-item__label {
  width: 100px !important;
  text-align: left;
}
.el-form-item__content {
  margin-left: 100px;
}

.my_form_content {
  display: flex;
  align-items: center;
}

.opt_btn {
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 119, 255, 1);
}

.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.tips {
  font-size: 12px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
  margin-top: 5px;
}

.el-input--small {
  margin-top: 10px;
}
</style>
