import service from "../../request";

export function courseTeacherList(query) {
    return service({
        url: '/api/merchants/courseTeacher/list',
        method: 'get',
        params: query
    })
}

export function saveOrUpdate(query,data) {
    return service({
        url: '/api/merchants/courseTeacher/saveOrUpdate',
        method: 'post',
        data: data
    })
}
