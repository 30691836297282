<template>
  <!-- 帮助中心 -->
  <div style="padding: 10px; margin: 10px; background-color: #fff">
    <div v-if="flage === 1">
      <el-button class="btn1" type="primary" size="small" @click="sort()"
        >分类管理</el-button
      >
      <el-button class="btn1" type="primary" size="small" @click="essay()"
        >新建问答</el-button
      >

      <el-tabs v-model="tabName" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in titleList"
          :label="item.name"
          :name="item.id.toString()"
          :key="index"
        >
        </el-tab-pane>
      </el-tabs>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        row-key="id"
        :data="tableData"
        style="width: 100%; margin: 20px 0px"
        :header-cell-style="tableHeaderCellStyle"
        :cell-style="tableCellStyle"
      >
        <el-table-column
          header-align="center"
          align="center"
          prop="title"
          label="问题标题"
        >
        </el-table-column>

        <el-table-column
          header-align="center"
          align="center"
          prop="isOpen"
          label="是否显示"
        >
          <!-- #default="{ row }" -->
          <template #default="{ row }">
            <div>{{ row.is_open === true ? "是" : "否" }}</div>
          </template>
        </el-table-column>

        <el-table-column
          header-align="center"
          align="center"
          prop="create_time"
          label="创建时间"
        >
        </el-table-column>

        <el-table-column
          header-align="center"
          align="center"
          prop="out_trade_no"
          label="操作"
        >
          <!-- #default="{ row }" -->
          <template #default="{ row }">
            <el-link :underline="false" type="primary" @click="essay(row)"
              >编辑</el-link
            >

            <el-link
              style="margin: 0 10px"
              :underline="false"
              type="primary"
              @click="delet(row.sklm_help_center_id)"
              >删除</el-link
            >
          </template>
        </el-table-column>

        <el-table-column
          header-align="center"
          align="center"
          prop="out_trade_no"
          label="推拽"
        >
          <template>
            <img
              style="width: 16px; height: 16px"
              src="@/assets/helpCenter/pull.png"
              alt=""
            />
          </template>
        </el-table-column>
      </el-table>

      <!-- a分页 -->
      <div class="page">
        <div class="box" />
        <div class="box" />
        <div class="box box-right">
          <el-pagination
            class="page"
            background
            :current-page="formInline.pageNum"
            :page-sizes="[10, 20, 30]"
            :page-size="100"
            layout="total, sizes, prev, pager, next, jumper"
            :total="formInline.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <!-- b分页 -->
    </div>

    <!-- 新增分类2-->
    <div v-else-if="flage === 2">
      <AddType @bagck="bagck"></AddType>
    </div>

    <!-- 新增+编辑问答3 -->
    <div v-else>
      <HelpComp @bagck="bagck" :row="row"></HelpComp>
    </div>
  </div>
</template>
 
<script>
import HelpComp from "./help_component/index.vue";
import AddType from "./help_component/addType.vue";
import {
  typeListApi,
  CenterListApi,
  sklmHelpCenterApi,
  klmHelpCenterApi,
} from "@/api/help_component.js";

// 拖拽插件
import Sortable from "sortablejs";

export default {
  name: "helpCenter",
  components: {
    HelpComp,
    AddType,
  },
  props: {},
  data() {
    return {
      title: "",
      AdddialogVisible: false,
      tabName: "1",
      flage: 1,

      formInline: {
        type: 1,
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },

      // tab+新增分类
      titleList: [],
      // 表格
      tableData: [],
      sortData: "", //排序列表

      // 弹窗
      ruleForm: {
        name: "",
        is_open: false,
      },

      row: {
        sklm_help_center_id: null,
      },
    };
  },

  watch: {},
  created() {
    this.typeList();
    this.CenterList();
  },

  mounted() {
    this.$nextTick(() => {
      this.rowDrop();
    });
  },

  methods: {
    // 表格拖拽
    rowDrop() {
      const tbody = this.$refs.multipleTable.$el.querySelector(
        ".el-table__body-wrapper > table > tbody"
      );
      const _this = this;
      Sortable.create(tbody, {
        async onEnd(event) {
          const { newIndex, oldIndex } = event;
          _this.sortData = _this.tableData[oldIndex];
          // _this.tableData.splice(oldIndex, 1);
          // _this.tableData.splice(newIndex+1, 0, _this.sortData);
          _this.sortData.sort = newIndex+1;
          console.log("newIndex", newIndex+1, "oldIndex", oldIndex);
          console.log("currRow", _this.sortData);
          const res = await klmHelpCenterApi(_this.sortData);
          console.log("排序", res);
          _this.CenterList()
        },
      });
    },

    // 删除id
    async delet(id) {
      const res = await sklmHelpCenterApi({ sklm_help_center_id: id });

      if (res.status == 1) {
        this.CenterList();
        this.$message.success("删除成功");
      } else {
        this.$message.error(res.message);
      }
    },

    // 分类列表
    async CenterList() {
      const res = await CenterListApi({ ...this.formInline });
      console.log("分类列表",res);
      this.tableData = res.data.list;
      this.formInline.total = res.data.total_count;
    },

    // 头部tab
    async typeList() {
      const res = await typeListApi({ pageSize: "999" });
      console.log("头部tab", res);
      this.titleList = res.data.list;
    },

    // tabid
    handleClick(tab, event) {
      console.log("tab", tab._props.name);
      this.formInline.type = tab._props.name;
      this.CenterList();
      // console.log("event", event);
    },

    // 新增分类管理
    sort() {
      this.flage = 2;
    },

    // 新建+编辑问答
    essay(row) {
      this.flage = 3;
      this.row = row;
      console.log(this.row);
    },

    // 返回
    bagck() {
      this.flage = 1;
      this.typeList();
      this.CenterList();
    },

    // a分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.formInline.pageSize = val;
      this.CenterList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.formInline.pageNum = val;
      this.CenterList();
    },
    // b分页

    // a表头样式 01
    tableHeaderCellStyle({ columnIndex, rowIndex }) {
      // console.log(columnIndex)
      // console.log(rowIndex)
      if (columnIndex === 0) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "15px 0px  0 15px",
        };
      } else if (columnIndex === 4) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "0px 15px  15px 0",
        };
      } else {
        return { backgroundColor: "#f7f7f7" };
      }
    },

    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          // backgroundColor: 'red',
          borderRadius: "20px 0px  0 20px",
        };
      } else if (columnIndex === 4) {
        return {
          // backgroundColor: '#red',
          borderRadius: "0px 20px  20px 0",
        };
      }
    },

    // a表头样式 02
    tableHeaderCellStyle2({ columnIndex, rowIndex }) {
      // console.log(columnIndex)
      // console.log(rowIndex)
      if (columnIndex === 0) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "15px 0px  0 15px",
        };
      } else if (columnIndex === 5) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "0px 15px  15px 0",
        };
      } else {
        return { backgroundColor: "#f7f7f7" };
      }
    },

    tableCellStyle2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          // backgroundColor: 'red',
          borderRadius: "20px 0px  0 20px",
        };
      } else if (columnIndex === 5) {
        return {
          // backgroundColor: '#red',
          borderRadius: "0px 20px  20px 0",
        };
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 分页
.page {
  display: flex;
  justify-content: flex-end;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    border-radius: 5px;
    background-color: #fff; // 进行修改未选中背景和字体
    border: 1px solid #e5e5e5;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0077ff; // 进行修改选中项背景和字体
    color: #fff;
  }
}
</style>