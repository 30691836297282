<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <div>
      <el-button size="small" type="primary" @click="add()">添加课时</el-button>
    </div>

    <el-table
      border
      size="small"
      :data="tableData"
      style="width: 100%; margin-top: 10px"
    >
      <el-table-column
        align="center"
        prop="name"
        label="课时名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="class_duration"
        label="时长"
      ></el-table-column>
      <el-table-column align="center" prop="try_watch" label="是否试看">
        <template slot-scope="scope">
          <span v-if="scope.row.try_watch == true"> 是 </span>
          <span v-else> 否 </span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="create_time"
        label="添加时间"
      ></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row)" size="small">
            编辑
          </el-button>
          <el-button type="text" @click="del(scope.row)" size="small">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px; margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="queryParams.page"
            :page-size="queryParams.limit"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog
      :before-close="batchViewClose"
      size="small"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form
        :model="form"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        size="small"
      >
        <el-form-item label="课时名称:">
          <el-input
            v-model="form.name"
            placeholder="请输入课时名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="课时视频: ">
          <div
            v-if="form.material_address"
            style="display: flex; flex-direction: row"
          >
            <video
              style="width: 60%"
              :src="form.material_address"
              controls="controls"
            ></video>
            <div
              style="
                width: 40%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <i class="el-icon-delete del_item" @click="delVideo()"></i>
            </div>
          </div>
          <el-upload
            v-else
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadImg"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否试看:">
          <el-radio-group v-model="form.try_watch">
            <el-radio :label="false">否</el-radio>
            <el-radio :label="true">是</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  courseClassHourList,
  saveOrUpdate,
  courseClassHourDel,
} from "../../api/course/courseClassHour";
import { getOssAccessInfo } from "../../api/common";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");
import $ from "jquery";

export default {
  name: "teacher",
  data() {
    return {
      dialogTitle: "",
      dialogVisible: false,
      form: {
        material_address: "",
        try_watch: false,
      },
      rules: {},
      queryParams: {
        nick_name: "",
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc",
      },
      total: 0,
      listLoading: false,
      tableData: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    batchViewClose() {
      this.pauseAll();
      this.dialogVisible = false;
    },
    pauseAll() {
      $("video").trigger("pause"); //关闭所有的视频播放
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/course/teacher/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.form.material_address = "https://store.paitk.com" + fileName;
          console.log(JSON.stringify(this.form));
          this.$message.success("上传成功");
        });
      });
    },
    delVideo() {
      this.form.material_address = "";
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    add() {
      this.dialogTitle = "添加课时";
      this.dialogVisible = true;
      this.form = {
        material_address: "",
        try_watch: false,
      };
    },
    del(row) {
      this.$confirm("确认删除该课时?", "删除课时", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          courseClassHourDel({ id: row.course_class_hour_id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.initData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    edit(row) {
      this.dialogTitle = "修改课时";
      this.dialogVisible = true;
      this.form = JSON.parse(JSON.stringify(row));
    },
    onLabelSubmit() {
      this.form.label.push(this.form.label_input);
      this.form.label_input = "";
    },
    labelDel(index) {
      this.form.label.splice(index, 1);
    },
    submit() {
      saveOrUpdate({}, this.form).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
          this.initData();
          this.dialogVisible = false;
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    initData() {
      this.listLoading = true;
      courseClassHourList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}

.del_item {
  font-size: 40px;
}
</style>
