import service from "../request";

export function wechatPayRefundList(query) {
    return service({
        url: '/api/merchants/wechatPayRefund/list',
        method: 'get',
        params: query
    })
}

export function wechatPayRefundInfo(query) {
    return service({
        url: '/api/merchants/wechatPayRefund/info',
        method: 'get',
        params: query
    })
}

export function pass(data) {
    return service({
        url: '/api/merchants/wechatPayRefund/pass',
        method: 'post',
        data: data
    })
}
export function rejected(data) {
    return service({
        url: '/api/merchants/wechatPayRefund/rejected',
        method: 'post',
        data: data
    })
}


