import service from "../request";

export function list(query) {
    return service({
        url: '/api/merchants/sklmCustomerService/list',
        method: 'get',
        params: query
    })
}

export function saveOrUpdate(query,data) {
    return service({
        url: '/api/merchants/sklmCustomerService/saveOrUpdate',
        method: 'post',
        params: query,
        data: data
    })
}

export function del(query) {
    return service({
        url: '/api/merchants/sklmCustomerService/del',
        method: 'get',
        params: query
    })
}
