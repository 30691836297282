<template>
  <div class="menu_info">
    <el-menu
        v-if="VIDEO_JOB_MENU"
        @select="handleSelect"
        :default-active="activeIndex"
        :unique-opened = true
        class="el-menu-vertical-demo">
      <div v-for="(item,index) in VIDEO_JOB_MENU.children" :key="index">
        <el-menu-item :index="item.path"  :key="index" v-if="item.has_children == false">
          <template slot="title">
            <el-image
                style="width: 20px;height: 20px;margin: 10px"
                :src="item.icon_img"
                fit="cover">
            </el-image>
            <span>{{item.name}}</span>
          </template>
        </el-menu-item>
        <el-submenu :index="item.path" :key="index" v-else>
          <template slot="title">
            <el-image
                style="width: 20px;height: 20px;margin: 10px"
                :src="item.icon_img"
                fit="cover">
            </el-image>
            <span>{{item.name}}</span>
          </template>
          <el-menu-item :index="childItem.path" v-for="(childItem,childIndex) in item.children" :key="childIndex">
            {{childItem.name}}
          </el-menu-item>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: "VideoJobMenu",
  computed: {
    ...mapState(['VIDEO_JOB_MENU'])
  },
  data(){
    return{
      activeIndex:'',
    }
  },
  mounted() {
    console.log("routes:",JSON.stringify(this.routes))
    this.activeIndex=this.$route.path
  },
  methods:{
    handleSelect(index, indexPath) {
      console.log(index, indexPath);
      this.$router.push(index);
    }
  }
}
</script>

<style scoped>

</style>
