<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <el-button @click="handleAdd" round size="small" type="primary"
      ><i class="el-icon-document-add el-icon--left"></i>新建视频模板
    </el-button>

    <el-tabs v-model="templateState" @tab-click="handleClick">
      <el-tab-pane
        v-for="item in tabs"
        :key="item.value"
        :label="item.label"
        :name="item.value + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <el-row style="margin-right: 20px">
      <!--      <el-col :span="12" style="text-align: left">-->
      <!--        <el-button round size="small" type="plain" style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1);"><i class="el-icon-delete el-icon&#45;&#45;left"></i>下架模板</el-button>-->
      <!--        <el-button round size="small" type="plain" style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1);"><i class="el-icon-delete el-icon&#45;&#45;left"></i>恢复模板</el-button>-->
      <!--        <el-button round size="small" type="plain" style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1);"><i class="el-icon-delete el-icon&#45;&#45;left"></i>彻底删除</el-button>-->
      <!--        <el-button round size="small" type="plain" style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1);"><i class="el-icon-delete el-icon&#45;&#45;left"></i>放入回收站</el-button>-->
      <!--      </el-col>-->
      <el-col :span="24" style="text-align: right">
        <!--        <el-select clearable size="small" v-model="queryParams.class_tem_id" placeholder="请选择模板分类"  style="margin-left: 10px">-->
        <!--          <el-option-->
        <!--              v-for="item in classTemInfo"-->
        <!--              :key="item.id"-->
        <!--              :label="item.systematic_name"-->
        <!--              :value="item.id">-->
        <!--          </el-option>-->
        <!--        </el-select>-->
        <!--        <el-input size="small" placeholder="请输入模板ID" v-model="queryParams.templateId"  style="width: 230px;margin-left: 10px">-->
        <!--          <el-button slot="append" icon="el-icon-search"></el-button>-->
        <!--        </el-input>-->

        <el-input
          size="small"
          placeholder="请输入模板ID"
          v-model="queryParams.id"
          clearable
          style="width: 160px"
        >
        </el-input>

        <el-input
          size="small"
          placeholder="请输入模板名称"
          v-model="queryParams.name"
          clearable
          style="width: 160px; margin-left: 10px"
        >
        </el-input>

        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="search()"
          >搜索
        </el-button>
      </el-col>
    </el-row>

    <el-table
      size="small"
      :data="tableData"
      style="width: 100%"
      :max-height="autoHeight"
      :max-width="autoWidth"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="date" label="模板信息" align="center" width="220">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <div style="width: 50px">
              <el-image
                @click="view(scope.row)"
                v-if="scope.row.media_producing_job_entity"
                style="width: 50px"
                :src="getImgUrl(scope.row.media_producing_job_entity.media_url)"
              >
                <div slot="placeholder" class="image-slot">
                  <el-image
                    style="width: 50px"
                    :src="require('../../assets/template/defaut.jpg')"
                  />
                </div>
              </el-image>
              <el-image
                v-else
                style="width: 50px"
                :src="require('../../assets/template/defaut.jpg')"
              />
            </div>
            <div
              style="
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                text-align: left;
              "
            >
              <div>{{ scope.row.name }}</div>
              <div>{{ scope.row.create_time }}</div>
              <div>ID {{ scope.row.id }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="update_time"
        label="更新时间"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center" width="100">
      </el-table-column>
      <el-table-column prop="dismiss_number" align="center" width="100">
        <template slot="header">
          可生成视频数
          <el-tooltip
            class="item"
            effect="dark"
            content="云快剪系统和达人矩阵系统可生成的视频数量，不包含Ai云剪辑的生成数量"
            placement="bottom"
          >
            <i class="el-icon-info" style="color: rgba(0, 119, 255, 1)"></i>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <div class="dismiss_number" @click="dismissNumberDialog(scope.row)">
            <span>{{ scope.row.dismiss_number }}</span>
            <i class="el-icon-edit" style="margin-left: 5px"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="320">
        <template slot-scope="scope">
          <div>
            <el-button
              v-show="templateState != 3 && templateState != 1"
              @click="clipTem(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(0, 119, 255, 1);
                color: white;
                margin: 5px;
              "
              >编辑创意
            </el-button>

            <el-button
              v-show="templateState != 3"
              @click="materialConfig(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 141, 26, 1);
                color: white;
                margin: 5px;
              "
              >素材配置
            </el-button>

            <el-button
              v-show="templateState != 3"
              @click="handleUpdate(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 119, 255, 1);
                border: 1px solid rgba(0, 119, 255, 1);
                margin: 5px;
              "
              >修改信息
            </el-button>

            <el-button
              v-show="templateState != 3"
              @click="copyTemplate(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 119, 255, 1);
                border: 1px solid rgba(0, 119, 255, 1);
                margin: 5px;
              "
              >复制模板
            </el-button>

            <el-button
              v-show="templateState == 0 || templateState == 2"
              @click="templateOn(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 119, 255, 1);
                border: 1px solid rgba(0, 119, 255, 1);
                margin: 5px;
              "
              >上架模板
            </el-button>

            <el-button
              v-show="templateState == 1"
              @click="templateOn(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 119, 255, 1);
                border: 1px solid rgba(0, 119, 255, 1);
                margin: 5px;
              "
              >上架修改
            </el-button>

            <el-button
              v-show="templateState == 1"
              @click="handleShelves(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 119, 255, 1);
                border: 1px solid rgba(0, 119, 255, 1);
                margin: 5px;
              "
              >下架模板
            </el-button>

            <el-button
              v-show="templateState != 3 && templateState != 1"
              @click="handleRecycle(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 119, 255, 1);
                border: 1px solid rgba(0, 119, 255, 1);
                margin: 5px;
              "
              >放入回收站
            </el-button>

            <el-button
              v-show="templateState == 3"
              @click="handleRecycleCancel(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 119, 255, 1);
                border: 1px solid rgba(0, 119, 255, 1);
                margin: 5px;
              "
              >恢复模板
            </el-button>
            <el-button
              v-show="templateState == 3"
              @click="handleDel(scope.row)"
              round
              size="small"
              type="plain"
              style="
                background: rgba(255, 255, 255, 1);
                color: rgba(0, 119, 255, 1);
                border: 1px solid rgba(0, 119, 255, 1);
                margin: 5px;
              "
              >彻底删除
            </el-button>
          </div>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="联盟">
        <template slot-scope="scope">
          <el-button
            style="
              background: rgba(121, 72, 234, 1);
              color: rgba(255, 255, 255, 1);
            "
            v-if="scope.row.is_shelves == false"
            @click="templatePush(scope.row)"
            round
            type="plain"
            size="small"
            >交付至达人矩阵
          </el-button>
          <el-button
            style="color: rgba(121, 72, 234, 1)"
            v-else
            round
            type="plain"
            size="small"
            @click="templatePushCancel(scope.row)"
            >从达人矩阵删除
          </el-button>
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="运营中心">
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.is_operation"
            @click="templatePushOperation(scope.row)"
            round
            :type="scope.row.is_operation == false ? 'primary' : 'success'"
            size="small"
          >
            <div>
              {{
                scope.row.is_operation == false
                  ? "交付至运营中心"
                  : "已交付至运营中心"
              }}
            </div>
          </el-button>

          <!-- <el-button
            v-else
            style="
              background: rgba(255, 255, 255, 1);
              color: rgba(64, 158, 255, 1);
            "
            @click="templatePushCancelOperation(scope.row)"
            round
            type="plain"
            size="small"
            >从运营中心删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px; margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            size="small"
            background
            @current-change="handleCurrentChange"
            :current-page="queryParams.page"
            :page-size="queryParams.limit"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <!-- 编辑修改 -->
    <el-dialog :title="title" :visible.sync="open" width="30%">
      <el-form
        size="small"
        ref="form"
        :model="form"
        label-width="100px"
        style="padding-right: 20px"
      >
        <el-form-item label="模板名称" prop="name">
          <el-input
            size="small"
            v-model="form.name"
            placeholder="请输入模板名称"
          />
        </el-form-item>

        <el-form-item label="模板备注" prop="remark">
          <div class="desc">
            <el-input
              size="small"
              type="textarea"
              v-model="form.remark"
            ></el-input>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submitForm"
          >确 认</el-button
        >
        <el-button size="small" @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 上架模板 -->
    <el-dialog
      :title="templateState == 1 ? '上架信息修改' : '上架模板'"
      :visible.sync="shelves"
      width="50%"
    >
      <el-form
        size="small"
        ref="shelvesForm"
        :model="shelvesForm"
        label-width="200px"
        style="padding-right: 20px"
      >
        <el-row><span style="font-weight: bold">基本信息</span></el-row>
        <el-form-item label="模板分类:" prop="dismiss_number">
          <el-select v-model="shelvesForm.class_tem_id" placeholder="请选择">
            <el-option
              v-for="item in classTemInfo"
              :key="item.id"
              :label="item.systematic_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="模板自动下架:" prop="dismiss_number">-->
        <!--          <span>当模板生成总数达到-->
        <!--            <el-input style="width: 20%" v-model="shelvesForm.gen_limit" clearable></el-input>-->
        <!--            时,自动下架-->
        <!--          </span>-->
        <!--          <span style="margin-left: 20px;color: #8c939d">-->
        <!--            注: 填0为不自动下架-->
        <!--          </span>-->
        <!--        </el-form-item>-->
        <el-form-item label="排序:" prop="dismiss_number">
          <el-input
            style="width: 20%"
            v-model="shelvesForm.tem_sort"
            clearable
          ></el-input>
          <span> 数字越大,排序越靠前,0为默认排序(按上架时间)</span>
        </el-form-item>

        <!--        <el-row>-->
        <!--          <span style="font-weight: bold">推广平台</span>-->
        <!--          <el-checkbox style="margin-left: 10px" v-model="checked">抖音</el-checkbox>-->
        <!--        </el-row>-->
        <!--        <el-form-item label="视频发布推荐标题:" prop="dismiss_number">-->
        <!--          <div style="display: flex;align-items: center;margin-top: 10px"-->
        <!--               v-for="(item,index) in shelvesForm.recommend_title" :key="index">-->
        <!--            <el-input-->
        <!--                style="width: 80%"-->
        <!--                type="textarea"-->
        <!--                placeholder="请输入内容"-->
        <!--                v-model="shelvesForm.recommend_title[index]"-->
        <!--                maxlength="55"-->
        <!--                show-word-limit-->
        <!--            >-->
        <!--            </el-input>-->
        <!--            <i style="font-size:40px;margin-left: 20px" class="el-icon-delete" v-show="index != 0"-->
        <!--               @click="recommendDel(index)"></i>-->
        <!--            <i style="font-size:40px;margin-left: 10px" class="el-icon-circle-plus-outline"-->
        <!--               v-show="shelvesForm.recommend_title.length == (index+1)" @click="recommendAdd()"></i>-->
        <!--          </div>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="补充说明:" prop="dismiss_number">-->
        <!--          <el-input-->
        <!--              type="textarea"-->
        <!--              placeholder="请输入内容"-->
        <!--              v-model="shelvesForm.added"-->
        <!--              maxlength="100"-->
        <!--              show-word-limit-->
        <!--          >-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="shelves = false" size="small">取 消</el-button>
        <el-button type="primary" @click="doSaveShelves" size="small"
          >保存</el-button
        >
        <el-button
          type="primary"
          @click="doShelves"
          v-show="templateState != 1"
          size="small"
          >保存并上架</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="可生成数量修改"
      :visible.sync="dismiss_number_visible"
      width="50%"
    >
      <el-form
        size="small"
        ref="shelvesForm"
        :model="dismiss_number_form"
        label-width="200px"
        style="padding-right: 20px"
      >
        <el-form-item label="可生成视频数量:" prop="dismiss_number">
          <el-input
            style="width: 200px"
            v-model="dismiss_number_form.dismiss_number"
            clearable
          ></el-input>
          <div style="color: #8c939d; margin-top: 5px">
            注: 填0为不限制生成数量；
          </div>
          <div style="color: #8c939d">
            当模版达到设置的生成数量时，云快剪小程序会自动将该模版下架，达人矩阵小程序会提示「该模版已达到生成上限」。
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="dismiss_number_visible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="sureDismissNumber"
          >修改</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      align="center"
      title="视频预览"
      :visible.sync="dialogVisible"
      :before-close="closeView"
      width="30%"
    >
      <video
        id="videoInfo"
        style="width: 80%"
        :src="videoUrl"
        controls="controls"
      ></video>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeView">取 消</el-button>
        <el-button type="primary" @click="closeView">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 交付至运营中心 -->
    <el-dialog title="交付运营中心" :visible.sync="dialOperation" width="30%">
      <el-form
        size="small"
        :model="form"
        label-width="100px"
        style="padding-right: 20px"
      >
        <el-radio-group v-model="type">
          <el-radio :label="0">达人端</el-radio>
          <el-radio :label="1">团长端</el-radio>
        </el-radio-group>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submitOperation()"
          >确 认</el-button
        >
        <el-button size="small" @click="offOperation">取 消</el-button>
      </div>
    </el-dialog>

    <ClipGeneralSituation ref="renewal"></ClipGeneralSituation>
  </div>
</template>

<script>
import {
  templateState,
  stencilList,
  addStencil,
  updateStencil,
  copyTemplate,
  recycle,
  recycleCancel,
  delStencil,
  getCreatorTemInside,
  addCreatorTemInside,
  shelves,
  noShelves,
  templatePush,
  templatePushCancel,
  templatePushOperation,
  templatePushCancelOperation,
} from "../../api/clip_tem_list";
import { mapMutations } from "vuex";
import { ctrClaifitionTemList } from "../../api/tem_class";
import { enterpriseCertInfo } from "../../api/merchantsUser";
import { clipGeneralInfo } from "../../api/clip_general_situation";
import ClipGeneralSituation from "../../components/clip_general_situation";

export default {
  name: "clip_tem_list",
  components: {
    ClipGeneralSituation,
  },
  data() {
    return {
      dialOperation: false,
      type: 0,
      template_id: "",

      autoHeight: "",
      autoWidth: "",
      dismiss_number_visible: false,
      dismiss_number_form: {},
      shelvesForm: {},
      shelves: false,
      open: false,
      title: "",
      form: {},
      tabs: [],
      templateState: "",
      multipleSelection: [],
      queryParams: {
        page: 1,
        limit: 5,
        sidx: "create_time",
        order: "desc",
      },
      total: 0,
      tableData: [],
      classTemInfo: [],
      value: "",
      videoUrl: "",
      dialogVisible: false,
    };
  },
  created() {
    this.getHeight();
    this.initClassTem();
    this.doTemplateState();
  },
  methods: {
    closeView() {
      document.getElementById("videoInfo").pause();
      this.dialogVisible = false;
    },
    view(row) {
      this.videoUrl = row.media_producing_job_entity.media_url;
      this.dialogVisible = true;
    },
    getImgUrl(url) {
      return url + "?x-oss-process=video/snapshot,t_0,f_png,m_fast,w_360,h_640";
    },
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 275 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },
    ...mapMutations(["GLOBAL_TEM_ID"]),
    sureDismissNumber() {
      updateStencil(this.dismiss_number_form).then((res) => {
        this.open = false;
        if (res.code == "0") {
          this.form = {};
          this.$message.success("编辑成功");
          this.dismiss_number_visible = false;
          this.doTemplateState();
        }
      });
    },
    dismissNumberDialog(row) {
      this.dismiss_number_visible = true;
      this.dismiss_number_form = {
        id: row.id,
        template_id: row.template_id,
        dismiss_number: row.dismiss_number,
      };
    },
    templatePush(row) {
      clipGeneralInfo({}).then((res) => {
        let data = res.data;
        console.log(JSON.stringify(data));
        let is_expire = data.is_expire;
        if (is_expire == false) {
          console.log(JSON.stringify(row));
          this.$confirm("确定将模版交付至达人矩阵?", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              templatePush(row).then((res) => {
                if (res.code == "0") {
                  this.initData();
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                } else {
                  this.$message.error(res.message);
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          this.$confirm("Ai云剪辑系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$refs.renewal.show(2);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    templatePushCancel(row) {
      console.log(JSON.stringify(row));
      this.$confirm("确定将模版从达人矩阵删除？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          templatePushCancel(row).then((res) => {
            if (res.code == "0") {
              this.initData();
              this.$message({
                type: "success",
                message: "操作成功!",
              });
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    offOperation() {
      this.dialOperation = false;
      this.type = 0;
    },

    submitOperation() {
      const from = {
        template_id: this.template_id,
        type: this.type,
      };
      console.log("提交", from);
      templatePushOperation(from).then((res) => {
        if (res.code == "0") {
          this.initData();
          this.dialOperation = false;
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 交付至运营中心
    templatePushOperation(row) {
      this.dialOperation = true;
      this.template_id = row.template_id;
      // this.$confirm("确定将模版交付至运营中心?", "提示", {
      //   confirmButtonText: "确认",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // })
      //   .then(() => {
      //     templatePushOperation({ template_id: row.template_id }).then(
      //       (res) => {
      //         if (res.code == "0") {
      //           this.initData();
      //           this.$message({
      //             type: "success",
      //             message: "操作成功!",
      //           });
      //         } else {
      //           this.$message.error(res.message);
      //         }
      //       }
      //     );
      //   })
      //   .catch(() => {
      //     this.$message({
      //       type: "info",
      //       message: "已取消",
      //     });
      //   });
    },

    // 删除至运营中心
    templatePushCancelOperation(row) {
      this.$confirm("确定将模版从运营中心删除？", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          templatePushCancelOperation({ template_id: row.template_id }).then(
            (res) => {
              if (res.code == "0") {
                this.initData();
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              } else {
                this.$message.error(res.message);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    search() {
      this.queryParams.page = 1;
      this.initData();
    },
    initClassTem: function () {
      let queryParams = {
        page: 1,
        limit: 999999,
        sidx: "create_time",
        order: "desc",
      };
      ctrClaifitionTemList(queryParams).then((response) => {
        this.classTemInfo = response.data.list;
      });
    },
    //保存上架信息
    doSaveShelves: function () {
      console.log("doSaveShelves:" + JSON.stringify(this.shelvesForm));
      if (!this.shelvesForm.class_tem_id) {
        this.$message.error("模板分类不能为空!");
        return;
      }
      addCreatorTemInside(this.shelvesForm).then((res) => {
        this.shelves = false;
        if (res.code == "0") {
          this.$message.success("上架信息保存成功");
          this.doTemplateState();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 上架模板
    doShelves: function () {
      console.log(JSON.stringify(this.shelvesForm));
      if (!this.shelvesForm.class_tem_id) {
        this.$message.error("模板分类不能为空!");
        return;
      }
      addCreatorTemInside(this.shelvesForm).then((res) => {
        this.shelves = false;
        if (res.code == "0") {
          shelves(this.shelvesForm).then((res) => {
            this.shelves = false;
            if (res.code == "0") {
              this.$message.success("模板上架成功");
              this.doTemplateState();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //模板下架
    handleShelves(val) {
      this.$confirm("确认下架该模板?", "下架模板", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          noShelves({ id: val.id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.doTemplateState();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //上架模板
    templateOn(val) {
      clipGeneralInfo({}).then((res) => {
        let data = res.data;
        console.log(JSON.stringify(data));
        let is_expire = data.is_expire;
        if (is_expire == false) {
          console.log("templateOn:" + JSON.stringify(val));
          getCreatorTemInside({
            id: val.id,
          }).then((res) => {
            if (res.code == "0") {
              let shelvesForm = res.data;
              shelvesForm.template_id = val.template_id;
              this.shelvesForm = shelvesForm;
              this.shelves = true;
            }
          });
        } else {
          this.$confirm("Ai云剪辑系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$refs.renewal.show(2);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    //删除模板
    handleDel(val) {
      this.$confirm("确认删除该模板?", "删除模板", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delStencil({ id: val.id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.doTemplateState();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleRecycleCancel(val) {
      this.$confirm("确认恢复模板?", "恢复模板", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          recycleCancel({ id: val.id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "模板恢复成功!",
              });
              this.doTemplateState();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleRecycle(val) {
      this.$confirm("确认放入回收站?", "放入回收站", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          recycle({ id: val.id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.doTemplateState();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    copyTemplate(row) {
      clipGeneralInfo({}).then((res) => {
        let data = res.data;
        console.log(JSON.stringify(data));
        let is_expire = data.is_expire;
        if (is_expire == false) {
          copyTemplate(row).then((res) => {
            if (res.code == "0") {
              this.form = {};
              this.$message.success({
                duration: 1000,
                message: "模板复制成功",
              });
              this.doTemplateState();
            }
          });
        } else {
          this.$confirm("Ai云剪辑系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$refs.renewal.show(2);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 修改或添加模板
    submitForm: function () {
      console.log(JSON.stringify(this.form));
      if (this.form.id) {
        updateStencil(this.form).then((res) => {
          this.open = false;
          if (res.code == "0") {
            this.form = {};
            this.$message.success("编辑成功");
            this.doTemplateState();
          }
        });
      } else {
        this.form.source = "OpenAPI";
        this.form.type = "Timeline";
        this.form.config = JSON.stringify({});
        this.form.related_mediaids = JSON.stringify({});
        addStencil(this.form).then((res) => {
          this.open = false;
          if (res.code == "0") {
            this.form = {};
            this.$message.success("添加成功");
            this.doTemplateState();
          }
        });
      }
    },
    // 关闭弹窗
    cancel() {
      this.open = false;
    },
    // 添加模板
    handleAdd() {
      clipGeneralInfo({}).then((res) => {
        let data = res.data;
        console.log(JSON.stringify(data));
        let is_expire = data.is_expire;
        if (is_expire == false) {
          this.open = true;
          this.title = "新建模板";
          this.form = {};
        } else {
          this.$confirm("Ai云剪辑系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$refs.renewal.show(2);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    // 编辑模板
    handleUpdate(row) {
      this.open = true;
      this.title = "编辑模板";
      this.form = row;
    },
    clipTem(row) {
      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data));
        let certification_status = res.data.certification_status;
        if (certification_status === 2 || certification_status === 3) {
          clipGeneralInfo({}).then((res) => {
            let data = res.data;
            console.log(JSON.stringify(data));
            let is_expire = data.is_expire;
            if (is_expire == false) {
              this.GLOBAL_TEM_ID(row.template_id);
              const { href } = this.$router.resolve({
                path: "/clip_tem",
              });
              window.open(href, href);
            } else {
              this.$confirm("Ai云剪辑系统到期，请续费!", "提示", {
                confirmButtonText: "去续费",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$refs.renewal.show(2);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消",
                  });
                });
            }
          });
        } else {
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$router.push("/authentication_protocol");
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    materialConfig(row) {
      clipGeneralInfo({}).then((res) => {
        let data = res.data;
        console.log(JSON.stringify(data));
        let is_expire = data.is_expire;
        if (is_expire == false) {
          this.GLOBAL_TEM_ID(row.template_id);
          const { href } = this.$router.resolve({
            path: "/material_config",
          });
          window.open(href, href);
        } else {
          this.$confirm("Ai云剪辑系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$refs.renewal.show(2);
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      });
    },
    doTemplateState() {
      templateState({}).then((response) => {
        console.log(response);
        this.tabs = response.data;
        if (this.templateState == "") {
          this.templateState = this.tabs[0].value + "";
        }
        console.log(this.tabs[0].value);
        this.tableData = [];
        this.queryParams.page = 1;
        this.initData();
      });
    },
    handleClick(tab, event) {
      this.tableData = [];
      this.queryParams.page = 1;
      this.initData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData() {
      let queryParams = this.queryParams;
      queryParams.templateState = this.templateState;
      stencilList(queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.dismiss_number {
  color: dodgerblue;
  cursor: pointer;
}

.dismiss_number:hover {
  opacity: 0.5;
}
</style>
