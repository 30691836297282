import service from "../request";

export function getOssAccessInfo(query) {
    return service({
        url: '/api/operation/common/getOssAccessInfo',
        method: 'get',
        params: query
    })
}

export function getMobileCode(query) {
    return service({
        url: '/api/common/getMobileCode',
        method: 'get',
        params: query
    })
}
