import service from "../request";

export function templateState(query) {
    return service({
        url: '/api/creator/template/templateState',
        method: 'get',
        params: query
    })
}

export function stencilList(query) {
    return service({
        url: '/api/creator/template/list',
        method: 'get',
        params: query
    })
}

export function getTemplateRequest(data) {
    return service({
        url: '/api/creator/template/getTemplateRequest',
        method: 'post',
        data: data
    })
}

//创作者创建模板工程
export function addStencil(data) {
    return service({
        url: '/api/creator/template/add',
        method: 'post',
        data: data
    })
}

//创作者修改模板
export function updateStencil(data) {
    return service({
        url: '/api/creator/template/update',
        method: 'post',
        data: data
    })
}

//创作者复制模板
export function copyTemplate(data) {
    return service({
        url: '/api/creator/template/copy',
        method: 'post',
        data: data
    })
}


//创作者模板进入回收站
export function recycle(data) {
    return service({
        url: '/api/creator/template/recycle',
        method: 'post',
        data: data
    })
}

//创作者模板移出回收站
export function recycleCancel(data) {
    return service({
        url: '/api/creator/template/recycleCancel',
        method: 'post',
        data: data
    })
}

//创作者删除模板
export function delStencil(data) {
    return service({
        url: '/api/creator/template/delete',
        method: 'post',
        data: data
    })
}

//查看模板上架信息
export function getCreatorTemInside(query) {
    return service({
        url: '/api/creator/template/creatorTemInside',
        method: 'get',
        params: query
    })
}

//修改模板上架信息
export function addCreatorTemInside(data) {
    return service({
        url: '/api/creator/template/creatorTemInside',
        method: 'post',
        data: data
    })
}

export function shelves(data) {
    return service({
        url: '/api/creator/template/shelves',
        method: 'post',
        data: data
    })
}

export function noShelves(data) {
    return service({
        url: '/api/creator/template/noShelves',
        method: 'post',
        data: data
    })
}

export function templatePush(data) {
    return service({
        url: '/api/creator/template/templatePush',
        method: 'post',
        data: data
    })
}

export function templatePushCancel(data) {
    return service({
        url: '/api/creator/template/templatePushCancel',
        method: 'post',
        data: data
    })
}

// 交付至运营中心
export function templatePushOperation(data) {
    return service({
        url: '/api/creator/template/templatePushOperation',
        method: 'post',
        data: data
    })
}

// 删除至运营中心
export function templatePushCancelOperation(data) {
    return service({
        url: '/api/creator/template/templatePushCancelOperation',
        method: 'post',
        data: data
    })
}
