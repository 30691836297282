import service from "../request";

export function matrix(query) {
    return service({
        url: '/api/merchants/overview/clipGeneral/matrix',
        method: 'get',
        params: query
    })
}

export function quickShear(query) {
    return service({
        url: '/api/merchants/overview/clipGeneral/quickShear',
        method: 'get',
        params: query
    })
}

export function clipGeneralInfo(query) {
    return service({
        url: '/api/merchants/overview/clipGeneral/info',
        method: 'get',
        params: query
    })
}

export function getPayImg(data) {
    return service({
        url: '/api/merchants/overview/clipGeneral/getPayImg',
        method: 'post',
        data: data
    })
}

export function check(query) {
    return service({
        url: '/api/merchants/overview/clipGeneral/check',
        method: 'get',
        params: query
    })
}
