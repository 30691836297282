<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <div>
      编辑素材模块
      <div style="float: right">
        <!--        <el-button size="small" style="margin-top: 10px" @click="goBack">取消</el-button>-->
        <el-button
          size="small"
          style="margin-top: 10px"
          type="primary"
          @click="saveGoods('ruleForm')"
          >保存</el-button
        >
      </div>
    </div>

    <div style="margin: 20px">
      <div style="color: rgba(56, 56, 56, 1); font-size: 14px; margin: 20px">
        基本信息
      </div>
      <div style="display: flex">
        <el-form
          :model="goodsWarehousingForm"
          :rules="rules"
          ref="ruleForm"
          :label-position="labelPosition"
          label-width="160px"
        >
          <el-form-item label="素材来源：" required>
            <el-radio-group
              v-model="goodsWarehousingForm.product_type"
              @change="productTypeChange"
            >
              <el-radio :label="1">抖音</el-radio>
              <!--              <el-radio :label="2">快手</el-radio>-->
            </el-radio-group>
          </el-form-item>
          <el-form-item label="素材模块主图：" required>
            <div style="display: flex; align-items: center">
              <el-upload
                class="avatar-uploader"
                action=""
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :http-request="uploadImg"
              >
                <img
                  v-if="goodsWarehousingForm.product_img"
                  :src="goodsWarehousingForm.product_img"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <div
                style="
                  padding: 10px;
                  color: rgba(166, 166, 166, 1);
                  font-size: 12px;
                "
              >
                建议上传尺寸为600*600px的jpg、png图片
              </div>
            </div>
          </el-form-item>

          <el-form-item label="素材模块标题：" required>
            <el-input
              size="small"
              type="textarea"
              :rows="2"
              placeholder="请输入素材模块标题"
              v-model="goodsWarehousingForm.product_name"
            >
            </el-input>
          </el-form-item>

          <el-form-item label="排序：" required prop="custom_sort">
            <div style="display: flex">
              <el-input
                size="small"
                style="width: 30%"
                v-model.number="goodsWarehousingForm.custom_sort"
                placeholder="请输入排序"
                clearable
              ></el-input>
              <div style="margin-left: 10px">
                <span style="color: rgba(166, 166, 166, 1); font-size: 12px"
                  >数字越大排序越靠前，0为默认排序（按照上架时间倒叙排序）</span
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item label="素材分类：" required prop="classify_id">
            <el-select
              v-model="goodsWarehousingForm.classify_id"
              placeholder="请选择素材分类"
            >
              <el-option
                v-for="item in classifyOptions"
                :key="item.id"
                :label="item.classify_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { trillProductUpdate, trillProductOneInfo } from "../../api/trill_goods";
import { templateList } from "../../api/template";
import {
  templateProductInfo,
  templateProductDel,
  templateProductAdd,
} from "../../api/templateProduct";
import { queryUsePage } from "../../api/product_classify";
import { getOssAccessInfo } from "../../api/common";

import Sha1 from "js-sha1";
var OSS = require("ali-oss");

export default {
  name: "material_module_edit",
  data() {
    return {
      classifyOptions: [],
      product_loading: false,
      merchantsProductId: "",
      queryParams: {},
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 5,
      total: 100,
      addTemplateVisible: false,
      labelPosition: "right",
      stock: {},
      goodsWarehousingForm: {
        open_commission_rate: 1,
        custom_sort: 0,
      },
      templateProductList: [],
      multipleSelection: [],
      selectionIds: [],
      rules: {
        commission_rate: [
          { required: true, message: "请填写佣金率", trigger: "blur" },
        ],
        open_commission_rate: [
          { required: true, message: "请填写公开佣金率", trigger: "blur" },
        ],
        activity_end_time: [
          {
            required: true,
            message: "请选择招商活动结束时间",
            trigger: "blur",
          },
        ],
        custom_sort: [
          { required: true, message: "请填写排序", trigger: "blur" },
          { type: "number", message: "排序必须为数字值" },
        ],
      },
    };
  },
  mounted() {
    this.merchantsProductId = this.$route.query.id;
    this.oneInfo();
    this.doTemplateProductInfo();
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/goods/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.goodsWarehousingForm.product_img =
            "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
          console.log(JSON.stringify(this.goodsWarehousingForm));
          this.$message.success("上传成功");
        });
      });
    },
    productTypeChange(value) {
      this.initClassifyOptions();
    },
    initClassifyOptions() {
      let queryParams = {
        plate: this.goodsWarehousingForm.product_type,
        page: 1,
        limit: 999999,
        sidx: "create_time",
        order: "desc",
      };
      queryUsePage(queryParams).then((res) => {
        this.classifyOptions = res.data.list;
      });
    },
    addTemplate() {
      this.addTemplateVisible = true;
      this.initData();
    },
    doTemplateProductAdd() {
      console.log("当前选中selectionIds:", JSON.stringify(this.selectionIds));
      let body = {
        merchantsProductId: this.merchantsProductId,
        templateIdList: this.selectionIds,
      };
      templateProductAdd({}, body).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "模板挂载成功",
          });
          this.addTemplateVisible = false;
          this.doTemplateProductInfo();
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("当前选中:", JSON.stringify(val));
    },
    remove(selectionIds, val) {
      let index = selectionIds.indexOf(val);
      if (index > -1) {
        selectionIds.splice(index, 1);
      }
      console.log("remove:", val);
      console.log("selectionIds:", this.selectionIds);
    },
    selectOne(selection, row) {
      let check = null;
      selection.map((item) => {
        if (item.id === row.id) {
          check = true;
        }
      });
      if (check) {
        console.log("选中:", row.id);
        this.selectionIds.push(row.id);
      } else {
        console.log("取消选中:", row.id);
        this.remove(this.selectionIds, row.id);
      }
    },
    selectAll(selection) {
      let _this = this;
      console.log(selection);
      if (selection.length == 0) {
        console.log("取消选择");
        _this.tableData.map((item) => {
          _this.remove(_this.selectionIds, item.id);
        });
      } else {
        console.log("全部选择");
        _this.tableData.forEach((item) => {
          let index = _this.selectionIds.indexOf(item.id);
          if (index > -1) {
            console.log("已经含有啦");
          } else {
            _this.selectionIds.push(item.id);
          }
        });
      }
    },
    selectCheck() {
      let _this = this;
      _this.$nextTick(() => {
        console.log("_this.$refs", _this.$refs);
        _this.tableData.map((item) => {
          _this.selectionIds.find((value) => {
            if (value == item.id) {
              _this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      });
    },
    deleteItem(item) {
      templateProductDel({ id: item.id }).then((response) => {
        this.$message.success({
          duration: 1000,
          message: "模板取消挂载成功",
        });
        this.doTemplateProductInfo();
      });
    },
    doTemplateProductInfo() {
      templateProductInfo(
        {
          page: 1,
          limit: 999,
          sidx: "mtp.create_time",
          order: "desc",
          merchantsProductId: this.merchantsProductId,
        },
        null
      ).then((response) => {
        this.templateProductList = response.data.records;
        this.selectionIds = response.data.records.map((x) => {
          return x.template_id;
        });
      });
    },
    oneInfo() {
      trillProductOneInfo({ id: this.merchantsProductId }).then((response) => {
        this.goodsWarehousingForm = response.data;
        this.initClassifyOptions();
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams;
      (queryParams.templateState = this.templateState),
        (queryParams.page = this.page_no),
        (queryParams.limit = this.page_size),
        (queryParams.order = "desc");
      templateList(queryParams).then((response) => {
        this.tableData = response.data.list;
        console.log(JSON.stringify(this.selectionIds));
        this.total = response.data.total_count;
        this.listLoading = false;
        this.selectCheck();
      });
    },
    saveGoods(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit!!");
          trillProductUpdate({}, this.goodsWarehousingForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "商品更新成功",
              });
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveShelves(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit!!");
          this.goodsWarehousingForm.item_status = 1;
          trillProductUpdate({}, this.goodsWarehousingForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "商品更新上架成功",
              });
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m;
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
