import service from "../../request";

export function baseInfo(query) {
    return service({
        url: '/api/merchants/overview/videoJob/baseInfo',
        method: 'get',
        params: query
    })
}

export function account(query) {
    return service({
        url: '/api/merchants/overview/videoJob/account',
        method: 'get',
        params: query
    })
}


export function videoJobInfo(query) {
    return service({
        url: '/api/merchants/overview/videoJob/info',
        method: 'get',
        params: query
    })
}

export function getPayImg(data) {
    return service({
        url: '/api/merchants/overview/videoJob/getPayImg',
        method: 'post',
        data: data
    })
}

export function check(query) {
    return service({
        url: '/api/merchants/overview/videoJob/check',
        method: 'get',
        params: query
    })
}
