import service from "../request";

export function grassTaskInfo(query) {
    return service({
        url: '/api/merchants/grassTask/excludeInfo',
        method: 'get',
        params: query
    })
}

export function grassTaskUserList(query) {
    return service({
        url: '/api/merchants/grassTaskUser/excludeList',
        method: 'get',
        params: query
    })
}
