<template>
  <div class="login-container">
    <div class="login_main_container">
      <div class="login_left">
        <div class="head-info">
          <img src="../../assets/head_img.jpg" class="head_img" />
          <span class="app_head_name">商户后台</span>
        </div>
        <div style="flex: 1;display: flex;flex-direction: column;justify-content: center">
          <div class="login_form_container">
            <div class="login_form">
              <div style="color: rgba(56, 56, 56, 1);font-size: 24px;font-weight: bold;margin-right: auto">视客联盟</div>

              <el-tabs v-model="loginType">
                <el-tab-pane label="密码登录" name="1">
                  <el-form ref="form" :model="form" label-width="80px">
                    <el-input  style="margin-top: 20px;" v-model="form.mobile_phone"  placeholder="请输入登录手机号" clearable></el-input>
                    <el-input style="margin-top: 20px;" v-model="form.password" show-password  placeholder="请输入登录密码" clearable></el-input>
                    <div style="margin-top: 20px;width: 100%; display: flex;align-items: center;justify-content: space-between">
                      <el-input v-model="form.verificatio_code" placeholder="请输入图形验证码" clearable></el-input>
                      <img :src="codeImg" style="width: 160px;height: 40px;margin-left: 10px"/>
                      <span style="width: 100px;color: rgba(0, 119, 255, 1);font-size: 14px;" @click="replaceImg">换一张</span>
                    </div>
                  </el-form>
                  <el-button  type="warning" style="width: 100%;margin-top: 30px" @click="doLogin">登录</el-button>
                </el-tab-pane>
                <el-tab-pane label="验证码登录" name="2">
                  <el-form  ref="form" :model="smsLoginForm" label-width="80px">
                    <el-input  style="margin-top: 20px;" v-model="smsLoginForm.mobile_phone" placeholder="请输入登录手机号" clearable></el-input>
                    <el-input style="margin-top: 20px;" v-model="smsLoginForm.security_code" placeholder="请输入登录验证码" clearable>
                      <template slot="append">
                        <el-button
                            type="primary"
                            @click="loginCheck()"
                            size="mini"
                            :disabled="disable"
                        >{{ buttonName }}</el-button
                        >
                      </template>
                    </el-input>
                  </el-form>
                  <el-button  type="warning" style="width: 100%;margin-top: 30px" @click="smsLogin">登录</el-button>
                </el-tab-pane>
              </el-tabs>

              <div style="margin-top: 20px; margin-right: auto">
                <el-checkbox v-model="read"></el-checkbox>
                <span style="margin-left: 5px">
             我已阅读并同意
            <span style="cursor: pointer;color: rgba(0, 119, 255, 1);" @click="user_agreement">服务协议</span>
            和
            <span style="cursor: pointer;color: rgba(0, 119, 255, 1)" @click="privacy_agreement">隐私政策</span>
            </span>
              </div>
            </div>
            <div class="login_tips">还没有加入？ <span style="color: rgba(0, 119, 255, 1);cursor: pointer;" @click="register">立即注册</span></div>
          </div>
        </div>
      </div>
      <div class="login_right">
        <div class="right_info">
          集视频Ai云剪辑、素材存储、商品管理、团队管理、视频分发等功能于一体，打造高效视频营销方式
        </div>
        <img src="../../assets/login/login_main.png" class="login_main_img" />
      </div>
    </div>
  </div>
</template>

<script>

import { mapMutations } from 'vuex';
import {
  membersLogin,
  getImage,
  registerCheck,
  membersSmsLogin,
  merchantsSmsLogin,
  optionLogin
} from "../../api/merchantsUser.js"
import {getMobileCode} from "../../api/common";

export default {
  name: "index",
  data() {
    return {
      smsLoginForm:{
        mobile_phone:"",
        security_code:""
      },
      disable:false,
      count: 60,
      buttonName: "获取验证码",
      loginType: "1",
      read:false,
      uuid:'',
      codeImg:'',
      form: {
        mobile_phone: "",
        password: "",
        verificatio_code: ''
      },
      capsTooltip: false
    };
  },
  mounted(){
    this.getImage()
  },
  methods: {
    ...mapMutations(['INIT_DATA','LOGIN_OPTION_INIT']),
    checkLogin(list) {
      if(list.length == 0){
        this.$message.error("登录失败");
        return
      }
      if(list.length == 1){
        console.log("single login")
        optionLogin(list[0]).then(res=>{
          console.log(JSON.stringify(res))
          if (res.code == "0") {
            this.INIT_DATA(res.data)
          }else{
            this.$message.error(res.message);
          }
        })
      }else {
        this.LOGIN_OPTION_INIT(list)
        this.$router.push("/login_options");
      }
    },
    doLogin(){
      if(this.read == false){
        this.$message.error("请确认已阅读服务协议及隐私政策!");
        return
      }
      let query = {
        verificatio_code: this.form.verificatio_code,
        uuid: this.uuid
      }
      membersLogin(query,this.form).then(res=>{
        console.log(JSON.stringify(res))
        if (res.code == "0") {
          this.checkLogin(res.data)
        }else{
          this.getImage()
          this.$message.error(res.message);
        }
      })
    },
    smsLogin(){
      let phone = this.smsLoginForm.mobile_phone
      console.log("phone:" + phone)
      const regMobile = /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/ ;
      if(!(regMobile.test(phone))){
        this.$message.error("登录手机号有误,请重新输入");
        return false;
      }
      if(this.read == false){
        this.$message.error("请确认已阅读服务协议及隐私政策!");
        return
      }
      membersSmsLogin(this.smsLoginForm).then(res=>{
        console.log(JSON.stringify(res))
        if (res.code == "0") {
          this.checkLogin(res.data)
        }else{
          this.$message.error(res.message);
        }
      })

    },
    loginCheck(){
      let phone = this.smsLoginForm.mobile_phone
      console.log("phone:" + phone)
      const regMobile = /^(0|86|17951)?(13[0-9]|15[0-9]|17[0-9]|18[0-9]|14[0-9]|19[0-9]|16[0-9])[0-9]{8}$/ ;
      if(!(regMobile.test(phone))){
        this.$message.error("登录手机号有误,请重新输入");
        return false;
      }
      registerCheck(this.smsLoginForm).then((res) => {
        if(res.code==0) {
          this.$message.error({message: "账号不存在!"});
        }else{
          this.doGetMobileCode(phone)
        }
      });
    },
    doGetMobileCode(phone){
      console.log("获取验证码")
      let query = {
        mobile: phone,
        scene: 'login'
      }
      getMobileCode(query).then((res) => {
        if(res.code==0) {
          var timeout= setInterval(() => {
            if (this.count < 1) {
              this.disable = false;
              this.buttonName = "获取验证码";
              this.count = 120;
              clearInterval(timeout);
            } else {
              this.disable = true;
              this.buttonName = this.count-- + "s后重发";
            }
          }, 1000);
          this.$message.success("验证码发送成功");
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    register(){
      this.$router.push("/register");
    },
    privacy_agreement(){
      const routeUrl = this.$router.resolve({
        path: "/privacy_agreement"
      });
      window.open(routeUrl.href, "_blank");
    },
    user_agreement(){
      const routeUrl = this.$router.resolve({
        path: "/user_agreement"
      });
      window.open(routeUrl.href, "_blank");
    },
    replaceImg(){
      this.getImage()
    },
    getImage(){
      getImage().then(res=>{
        console.log(res)
        this.codeImg = 'data:image/png;base64,'+ res.data.imageBase64Data
        this.uuid = res.data.uuid
      })
    }
  }
}
</script>

<style scoped>

.login-container{
  height: 100vh;
}
.head-info{
  padding: 20px;
  display: flex;
  align-items: center;
}

.head_img{
  height: 36px;
}

.app_head_name{
  margin-left: 20px;
  font-size: 16px;
  color: black;
}

.login_main_container{
  height: 100vh;
  display: flex;
}


.login_left{
  width: 60%;
  display: flex;
  flex-direction: column;
}

.login_form_container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login_form{
  width: 486px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  justify-content: center;
}

.login_tips{
  margin-top: 20px;
}

.login_right{
  width: 40%;
  background: rgba(244.8, 244.8, 244.8, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.right_info{
  color: rgba(56, 56, 56, 1);
  font-size: 28px;
  text-align: left;
  padding: 6%;
}

.login_main_img{
   width: 80%;
}

</style>
