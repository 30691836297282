<template>
  <el-dialog
      class="my_dialog"
      title="生成卡记录"
      :visible.sync="dialogVisible"
      width="80%"
  >
    <el-divider></el-divider>
    <div class="info_view">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="充值记录" name="first">
          <div style="text-align: center">
            <el-table
                :data="tableDataOne"
                style="width: 100%">
              <el-table-column
                  prop="create_time"
                  label="时间"
                  align = "center">
              </el-table-column>
              <el-table-column
                  prop="quantity"
                  label="生成卡数量"
                  align = "center">
              </el-table-column>
              <el-table-column
                  prop="details"
                  label="备注"
                  align = "center">
              </el-table-column>
            </el-table>
            <el-pagination
                style="padding-top: 20px"
                layout="prev, pager, next"
                background
                @current-change="handleCurrentChangeOne"
                :current-page="page_no_one"
                :page-size="page_size_one"
                :total="total_count_one">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="扣减记录" name="second">
          <div style="text-align: center">
            <el-table
                :data="tableDataTwo"
                style="width: 100%">
              <el-table-column
                  prop="template_id"
                  label="内容"
                  align = "center">
                <template slot-scope="scope">
                  {{scope.row.template_id != '' ? scope.row.template_id : scope.row.title}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="template_name"
                  label="备注"
                  width="180"
                  align = "center">
                <template slot-scope="scope">
                  {{scope.row.template_name != '' ? scope.row.template_name : scope.row.details}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="quantity"
                  label="生成卡数量"
                  align = "center">
              </el-table-column>
              <el-table-column
                  prop="create_time"
                  label="时间"
                  align = "center">
              </el-table-column>
            </el-table>
            <el-pagination
                style="padding-top: 20px"
                layout="prev, pager, next"
                background
                @current-change="handleCurrentChangeTwo"
                :current-page="page_no_two"
                :page-size="page_size_two"
                :total="total_count_two">
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="消耗记录" name="tree">
          <div style="text-align: center">
            <el-table
                :data="tableDataTre"
                style="width: 100%">
              <el-table-column
                  prop="template_id"
                  label="模板ID"
                  align = "center">
                <template slot-scope="scope">
                  {{scope.row.template_id != '' ? scope.row.template_id : scope.row.title}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="template_name"
                  label="模板标题"
                  width="180"
                  align = "center">
                <template slot-scope="scope">
                  {{scope.row.template_name != '' ? scope.row.template_name : scope.row.details}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="quantity"
                  label="生成卡数量"
                  align = "center">
              </el-table-column>
              <el-table-column
                  prop="create_time"
                  label="生成时间"
                  align = "center">
              </el-table-column>
            </el-table>
            <el-pagination
                style="padding-top: 20px"
                layout="prev, pager, next"
                background
                @current-change="handleCurrentChangeTre"
                :current-page="page_no_tre"
                :page-size="page_size_tre"
                :total="total_count_tre">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </el-dialog>

</template>

<script>

import { userTimeCardResd } from  "../../api/userManage";


export default {
  name: "UserTimeCardResd",
  data() {
    return{
      vodmUserId:"",
      dialogVisible:false,
      activeName: 'first',
      page_no_one:1,
      page_size_one:10,
      total_count_one:0,
      tableDataOne: [],
      page_no_two:1,
      page_size_two:10,
      total_count_two:0,
      tableDataTwo: [],
      page_no_tre:1,
      page_size_tre:10,
      total_count_tre:0,
      tableDataTre: []
    }
  },
  methods: {
    handleCurrentChangeOne(val){
      this.page_no_one = val;
      this.initDataOne()
    },
    handleCurrentChangeTwo(val){
      this.page_no_two = val;
      this.initDataTwo()
    },
    handleCurrentChangeTre(val){
      this.page_no_tre = val;
      this.initDataTre()
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    childClick(row){
      this.vodmUserId = row.vodm_wx_user_id
      console.log(JSON.stringify(row))
      this.activeName = 'first',
          this.page_no_one=1,
          this.page_size_one=10,
          this.total_count_one=0,
          this.tableDataOne= [],
          this.page_no_two=1,
          this.page_size_two=10,
          this.total_count_two=0,
          this.tableDataTwo= []
           this.page_no_tre=1,
          this.page_size_tre=10,
          this.total_count_tre=0,
          this.tableDataTre= []
      this.initDataOne()
      this.initDataTwo()
      this.initDataTre()
      this.dialogVisible = true
    },
    initDataOne(){
      let queryParams = {
        page: this.page_no_one,
        limit: this.page_size_one,
        vodmUserId: this.vodmUserId,
        operationType: 1,
        sidx : "create_time",
        order : "desc",
      };
      userTimeCardResd(queryParams).then((response) => {
        this.tableDataOne = response.data.data.pageInfo.list;
        console.log(JSON.stringify(response.data.data.pageInfo.list))
        this.total_count_one = response.data.data.pageInfo.total_count;
      });
    },
    initDataTwo(){
      let queryParams = {
        page: this.page_no_two,
        limit: this.page_size_two,
        vodmUserId: this.vodmUserId,
        operationType: 2,
        sidx : "create_time",
        order : "desc",
      };
      userTimeCardResd(queryParams).then((response) => {
        this.tableDataTwo = response.data.data.pageInfo.list;
        console.log(JSON.stringify(response.data.data.pageInfo.list))
        this.total_count_two = response.data.data.pageInfo.total_count;
      });
    },
    initDataTre(){
      let queryParams = {
        page: this.page_no_tre,
        limit: this.page_size_tre,
        vodmUserId: this.vodmUserId,
        operationType: 3,
        sidx : "create_time",
        order : "desc",
      };
      userTimeCardResd(queryParams).then((response) => {
        this.tableDataTre = response.data.data.pageInfo.list;
        console.log(JSON.stringify(response.data.data.pageInfo.list))
        this.total_count_tre = response.data.data.pageInfo.total_count;
      });
    },
  }
}
</script>

<style scoped>

/deep/ .el-dialog__body {
  padding: 0;
}

.el-divider--horizontal {
  margin: 0;
}

.info_view{
  padding: 20px;
}
</style>
