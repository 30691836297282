<template>
  <div style="padding: 10px;margin: 10px; background: white;">

    <el-tabs v-model="queryParams.internal_state" @tab-click="changeTab">
      <el-tab-pane
          v-for="(item) in tabs"
          :key="item.value"
          :label="item.label"
          :name="item.value + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <div>
      <el-input
          @clear="doSearch"
          size="small"
          placeholder="用户昵称/手机号/ID"
          v-model="queryParams.query1"
          clearable
          style="width: 160px;margin-left: 10px">
      </el-input>
      <el-input
          @clear="doSearch"
          size="small"
          placeholder="抖音昵称"
          v-model="queryParams.query2"
          clearable
          style="width: 140px;margin-left: 10px">
      </el-input>
      <el-input
          @clear="doSearch"
          size="small"
          placeholder="经纪人昵称/手机号/ID"
          v-model="queryParams.query3"
          clearable
          style="width: 180px;margin-left: 10px">
      </el-input>
      <el-input
          @clear="doSearch"
          size="small"
          placeholder="模板ID/名称"
          v-model="queryParams.query4"
          clearable
          style="width: 140px;margin-left: 10px">
      </el-input>
      <el-input
          @clear="doSearch"
          size="small"
          placeholder="关联的商品名称"
          v-model="queryParams.query5"
          clearable
          style="width: 140px;margin-left: 10px">
      </el-input>
      <el-button
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="doSearch"
      >搜索</el-button>
    </div>

    <div style="margin-top: 10px" v-if="queryParams.internal_state == 0">
      <el-button type="primary" size="small" @click="batchPass">批量通过</el-button>
      <el-button type="danger" size="small" @click="batchRejected">批量驳回</el-button>
    </div>

    <el-table
        @selection-change="handleSelectionChange"
        :data="tableData"
        :loading="listLoading"
        border
        :max-height="autoHeight"
        style="margin-top: 10px"
    >
      <el-table-column
          :selectable="selectable"
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column align="center" label="用户信息" width="220">
        <template slot-scope="scope">
          <div style="display: flex">
            <el-image
                style="width: 50px;height: 50px"
                :src="scope.row.extend.userInfo.avatar_url"
                fit="cover">
            </el-image>
            <div style="flex: 1">
              <div>{{scope.row.extend.userInfo.nick_name}}</div>
              <div>{{scope.row.extend.userInfo.phone_number}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="抖音信息" width="220">
        <template slot-scope="scope">
          <div style="display: flex">
            <el-image
                style="width: 50px;height: 50px"
                :src="scope.row.extend.dyInfo.avatar"
                fit="cover">
            </el-image>
            <div style="flex: 1">
              <div>{{scope.row.extend.dyInfo.nickname}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="时间信息" width="260">
        <template slot-scope="scope">
          <div>领取时间: {{scope.row.create_time}}</div>
          <div v-if="queryParams.internal_state != -1 && queryParams.internal_state != 3">提交时间: {{scope.row.submit_time}}</div>
          <div v-if="queryParams.internal_state != 0 && queryParams.internal_state != -1  && queryParams.internal_state != 3">审核时间: {{scope.row.audit_time}}</div>
          <div v-if="queryParams.internal_state == -1 || queryParams.internal_state == 3">超时时间: {{scope.row.delay_submit_time}}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="模板信息" width="300">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <img style="width: 100px"  :src="scope.row.extend.template.preview" model="widthFix"/>
            <div style="margin-left: 10px">
              <div>{{scope.row.extend.template.name}}</div>
              <div>ID: {{scope.row.extend.template.id}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="关联的商品" width="300">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <img style="width: 100px"  :src="scope.row.extend.productInfo.img" model="widthFix"/>
            {{scope.row.extend.productInfo.name}}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="达人/经纪人收益" width="220">
        <template slot-scope="scope">
          <span class="money_tips">¥</span><span>{{scope.row.master_reward | numberFormat}}</span> / <span class="money_tips">¥</span><span>{{scope.row.broker_reward | numberFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="经纪人信息" width="220">
        <template slot-scope="scope">
          <div style="display: flex">
            <el-image
                style="width: 50px;height: 50px"
                :src="scope.row.extend.agentUserInfo.avatar_url"
                fit="cover">
            </el-image>
            <div style="flex: 1">
              <div>{{scope.row.extend.agentUserInfo.nick_name}}</div>
              <div>{{scope.row.extend.agentUserInfo.phone_number}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="dy_video_url" label="抖音链接" width="300" v-if="queryParams.internal_state != -1 && queryParams.internal_state != 3">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            {{scope.row.dy_video_url}}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="截图凭证" width="230" v-if="queryParams.internal_state != -1 && queryParams.internal_state != 3">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center;justify-content: center; flex-flow:row wrap">
            <el-image
                v-for="(item,index) in scope.row.screen_cert" :key="index"
                :src="item"
                style="width: 50px;margin: 5px"
                :preview-src-list="getSrcList(item)"
                fit="cover">
            </el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="备注信息" width="300" v-if="queryParams.internal_state != -1 && queryParams.internal_state != 3">
        <template slot-scope="scope">
          {{scope.row.remark}}
        </template>
      </el-table-column>
      <el-table-column v-if="queryParams.internal_state == 2" align="center" label="驳回原因" width="300">
        <template slot-scope="scope">
          {{scope.row.refuse_cause}}
        </template>
      </el-table-column>

      <el-table-column align="center" label="操作" fixed="right" v-if="queryParams.internal_state == 0">
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click.native.prevent="doAuditPass(scope.row)">
            审核通过
          </el-button>
          <el-button
              type="text"
              size="small"
              @click.native.prevent="doAuditRefuse(scope.row)">
            审核驳回
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 24px;text-align: right">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="queryParams.page"
          :page-size="queryParams.limit"
          :total="total"
          layout="total, prev, pager, next"
      ></el-pagination>
    </div>


    <el-dialog size="small" title="审核驳回" :visible.sync="auditRefuseVisible" width="30%">
      <el-form
          ref="form"
          :model="auditRefuseForm"
          label-width="120px"
          style="padding-right: 20px"
      >
        <el-input
            style="margin-top: 20px"
            type="textarea"
            maxlength="30"
            show-word-limit
            placeholder="请输入驳回原因,最多可输入30个汉字"
            v-model="auditRefuseForm.refuseCause">
        </el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="auditRefuseSubmit" size="small">确 认</el-button>
        <el-button @click="auditRefuseVisible = false" size="small">取 消</el-button>
      </div>
    </el-dialog>


    <el-dialog
        title="提示-批量通过操作"
        :visible.sync="batchPassVisible"
        width="30%"
    >
      <div style="display: flex;justify-content: center">
        <div><i class="el-icon-warning" style="font-size: 40px;color: gold"></i></div>
        <div style="margin-left: 10px; color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 400;">
          <div>是否<span style="color: green">对{{ids.length}}条数据</span>执行审核通过操作？</div>
          <div>点击“确定”，将对选中数据进行操作！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchPassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchPass">确 定</el-button>
      </span>
    </el-dialog>


    <el-dialog
        title="提示-批量驳回"
        :visible.sync="batchRejectedVisible"
        width="30%"
    >
      <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入驳回原因"
          v-model="reasonRejection">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchRejectedVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchRejected">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

import { list ,tabs ,auditPass ,auditRefuse} from "../../api/video_job/video_job_res";

export default {
  name: "sh",
  filters:{
    numberFormat: function (value) {
      return value.toFixed(2)
    },
  },
  data(){
    return{
      tabVal: '1',
      tabs:[],
      queryParams:{
        internal_state: '0',
        query1: '',
        query2: '',
        query3: '',
        query4: '',
        query5: '',
        page:1,
        limit:5,
        sidx:'vjr.create_time',
        order:"desc"
      },
      tableData:[],
      listLoading: false,
      autoHeight:'',
      autoWidth:'',
      total:0,
      auditRefuseVisible: false,
      auditRefuseForm:{
        ids:[],
        refuseCause:''
      },
      multipleSelection:[],
      ids:[],
      batchPassVisible: false,
      batchRejectedVisible: false,
      reasonRejection:''
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initTabs()
  },
  methods:{
    getSrcList(src){
      let srcList = []
      srcList.push(src)
      return srcList
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 260) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    selectable(){
      if(this.queryParams.internal_state == 0){
        return true
      }
      return false
    },
    doBatchPass(){
      let body = {
        ids: this.ids
      }
      console.log(JSON.stringify(body))
      auditPass(body).then((res) => {
        if (res.code == "0") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.initTabs()
          this.batchPassVisible = false
        }
      });
    },
    doBatchRejected(){
      let body = {
        ids: this.ids,
        refuseCause: this.reasonRejection
      }
      console.log(JSON.stringify(body))
      auditRefuse(body).then((res) => {
        if (res.code == "0") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.initTabs()
          this.batchRejectedVisible = false
        }
      });
    },
    batchPass(){
      console.log(JSON.stringify(this.ids))
      if (this.ids.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchPassVisible = true
    },
    batchRejected(){
      console.log(JSON.stringify(this.ids))
      if (this.ids.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchRejectedVisible = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let ids = val.map((item, index) => {
        return item.id;
      });
      this.ids = ids
      console.log(JSON.stringify(ids))
    },
    doAuditPass(row){
      let ids = [ row.id ]
      this.$confirm("确认审核通过?", "审核通过", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
            auditPass({ ids: ids }).then((res) => {
              if (res.code == "0") {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.initTabs()
              }
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
      });
    },
    doAuditRefuse(row){
        let ids = [ row.id ]
        this.auditRefuseForm.ids = ids;
        this.auditRefuseVisible = true
    },
    auditRefuseSubmit(){
      auditRefuse(this.auditRefuseForm).then((res) => {
        if (res.code == "0") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.initTabs()
          this.auditRefuseVisible = false
        }
      });
    },
    doSearch(){
      this.queryParams.page = 1;
      this.initData();
    },
    changeTab(tab, event){
      this.initData();
    },
    initTabs(){
      tabs().then((res) => {
        this.tabs = res.data.list;
      });
      this.initData();
    },
    initData(){
      this.listLoading = true
      let queryParams = this.queryParams;
      if(queryParams.internal_state == -1){
        console.log("待提交")
        queryParams.sidx = 'create_time'
        queryParams.order = 'desc'
      }
      if(queryParams.internal_state == 0){
        console.log("待审核")
        queryParams.sidx = 'submit_time'
        queryParams.order = 'desc'
      }
      if(queryParams.internal_state == 1){
        console.log("审核通过")
        queryParams.sidx = 'audit_time'
        queryParams.order = 'desc'
      }
      if(queryParams.internal_state == 2){
        console.log("审核驳回")
        queryParams.sidx = 'audit_time'
        queryParams.order = 'desc'
      }
      if(queryParams.internal_state == 3){
        console.log("超时未交")
        queryParams.sidx = 'delay_submit_time'
        queryParams.order = 'desc'
      }
      list(queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
  }
}
</script>

<style scoped>


.money_tips{
  font-size: 8px;
}

</style>
