<template>
<div class="menu_info">
  <el-menu
      @select="handleSelect"
      :default-active="activeIndex"
      :unique-opened = true
      class="el-menu-vertical-demo">
    <div v-for="(item,index) in routes" :key="index">
      <el-menu-item :index="item.path"  :key="index"  v-if="item.isMenu && item.isSingleMenu">
        <template slot="title">
          <el-image
              style="width: 20px;height: 20px;margin: 10px"
              :src="item.iconImg"
              fit="cover">
          </el-image>
          <span>{{item.name}}</span>
        </template>
      </el-menu-item>

      <el-submenu :index="item.path"  :key="index" v-if="item.isMenu && !item.isSingleMenu">
        <template slot="title">
          <el-image
              style="width: 20px;height: 20px;margin: 10px"
              :src="item.iconImg"
              fit="cover">
          </el-image>
          <span>{{item.name}}</span>
        </template>
        <el-menu-item :index="childItem.path" v-for="(childItem,childIndex) in item.children" :key="childIndex"  v-show="childItem.isHidden != true">
          {{childItem.name}}
        </el-menu-item>
      </el-submenu>
    </div>
  </el-menu>

</div>
</template>

<script>

import material from "../router/material";

export default {
  name: "MenuInfo",
  data(){
    return{
      activeIndex:'',
      routes: material
    }
  },
  mounted() {
      console.log("routes:",JSON.stringify(this.routes))
      this.activeIndex=this.$route.path
  },
  methods:{
    handleSelect(index, indexPath) {
      console.log(index, indexPath);
      this.$router.push(index);
    }
  }
}
</script>

<style scoped>

/*/deep/ .el-menu{*/
/*  height: 100%;*/
/*}*/

/*/deep/ .el-submenu__title{*/
/*  padding-left: 0 !important;*/
/*}*/

/*/deep/ .el-submenu .el-menu-item{*/
/*  min-width:100px*/
/*}*/
</style>
