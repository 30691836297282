import service from "../request";


/**
 * 精选联盟统计
 * @param query
 * @returns {*}
 */
export function merchantStatistical(query) {
    return service({
        url: '/api/merchants/easyExcel/merchantStatistical',
        method: 'get',
        params: query
    })
}

//结算订单插入
export function trillSettleOrdersUpload(data) {
    return service({
        url: '/api/merchants/easyExcel/trillSettleOrders/upload',
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' }
    })
}
//结算订单列表
export function trillSettleOrdersList(query) {
    return service({
        url: '/api/merchants/easyExcel/trillSettleOrders/list',
        method: 'get',
        params: query
    })
}

//结算后订单插入
export function trillRefundOrdersUpload(data) {
    return service({
        url: '/api/merchants/easyExcel/trillRefundOrders/upload',
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' }
    })
}

//退款订单列表查看
export function trillRefundOrdersList(query) {
    return service({
        url: '/api/merchants/easyExcel/trillRefundOrders/list',
        method: 'get',
        params: query
    })
}

//精选联盟分佣更新
export function selectionAllianceUpdate(data) {
    return service({
        url: '/api/merchants/selectionAlliance/update',
        method: 'post',
        data: data
    })
}

//精选联盟分佣查看
export function selectionAllianceInfo(query) {
    return service({
        url: '/api/merchants/selectionAlliance/info',
        method: 'get',
        params: query
    })
}


export function trillRefundOrdersReload(query) {
    return service({
        url: '/api/merchants/easyExcel/trillRefundOrders/reload',
        method: 'get',
        params: query
    })
}

export function trillSettleOrdersReload(query) {
    return service({
        url: '/api/merchants/easyExcel/trillSettleOrders/reload',
        method: 'get',
        params: query
    })
}
