import service from "../request";

export function ticketOrderList(query) {
    return service({
        url: '/api/merchants/ticketOrder/list',
        method: 'get',
        params: query
    })
}

export function ticketOrderRecList(query) {
    return service({
        url: '/api/merchants/ticketOrder/recList',
        method: 'get',
        params: query
    })
}

export function submitApplications(data) {
    return service({
        url: '/api/merchants/ticketOrder/submitApplications',
        method: 'post',
        data: data
    })
}
