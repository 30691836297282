<template>
  <div style="background: white;padding: 10px;margin: 10px">
  <div style="display: flex;justify-content: space-between;align-items: center">
    <span class="head">订单中心</span>
    <div>
      <el-button size="small" type="primary" @click="ticket_center">去开票
      </el-button></div>
  </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="生成卡订单" name="1">
        <GencardRecord></GencardRecord>
      </el-tab-pane>
      <el-tab-pane label="扩容订单/记录" name="2">
        <CloudRecord></CloudRecord>
      </el-tab-pane>
      <el-tab-pane label="系统订单/记录" name="3">
        <MasterMatrix></MasterMatrix>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import GencardRecord from "./list/gen_card_record.vue"
import CloudRecord from "./list/cloud_record"
import MasterMatrix from "./list/master_matrix.vue"

export default {
  name: "OrderCenter",
  components:{
    GencardRecord,
    CloudRecord,
    MasterMatrix
  },
  data() {
    return {
      activeName: "1"
    };
  },
  methods: {
    ticket_center(){
      this.$router.push("/ticket_center");
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style scoped>
  .head{
    font-size: 28px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
  }
</style>
