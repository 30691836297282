<template>
  <div style="background: white; padding: 10px; margin: 10px">
    员工标签
    <br />
    <el-button size="small" type="primary" @click="doAddRole"
      >添加用户标签</el-button
    >

    <!-- :max-height="autoHeight" -->
    <el-table
      border
      size="small"
      :data="tableData"
      style="width: 100%; margin-top: 15px"
    >
      <el-table-column prop="id" label="id"> </el-table-column>
      <el-table-column prop="content" label="标签名"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间"> </el-table-column>
      <el-table-column prop="update_time" label="更新时间"> </el-table-column>

      <el-table-column fixed="right" align="center" label="操作" width="200px">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="handleEdit(scope.row)"
            type="text"
            size="small"
          >
            编辑
          </el-button>

          <el-button
            @click.native.prevent="commission(scope.row)"
            type="text"
            size="small"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px; margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="queryParams.page"
            :page-size="queryParams.limit"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="标签名:">
          <el-input size="small" v-model="form.content"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import {
  staffLabelListApi,
  staffLabelDelApi,
  saveOrUpdateApi,
} from "@/api/custom_label.js";

export default {
  name: "customLabel",
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogTitle: "",
      tableData: [],
      form: {
        content: "",
      },
      // sidx: "stb.create_time",
      // order: "desc",
      queryParams: {
        page: 1,
        limit: 10,
      },
      total: 0,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.staffLabelList();
  },
  methods: {
    submit() {
      if (this.form.content == "") {
        this.$message.error("标签名不能空!");
        return;
      }
      let form = this.form;
      saveOrUpdateApi({}, form).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
          this.staffLabelList();
          this.dialogVisible = false;
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },

    doAddRole() {
      this.dialogTitle = "新增用户标签";
      this.form = {
        content: "",
      };
      this.dialogVisible = true;
    },

    handleEdit(row) {
      this.form = row;
      this.dialogTitle = "编辑用户标签";
      this.dialogVisible = true;
    },

    // 删除
    async commission(row) {
      this.$confirm("确定删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log("确定删除");
          staffLabelDelApi({ id: row.id }).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.staffLabelList();
            } else {
              this.$message.error({ message: res.message });
            }
          });
        })
        .catch(() => {});
    },

    async staffLabelList() {
      const res = await staffLabelListApi({ ...this.queryParams });
      console.log(res);
      this.tableData = res.data.list;
      this.total = res.data.total_count;
    },

    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.staffLabelList();
    },
  },
};
</script>

<style scoped lang='scss'>
</style>