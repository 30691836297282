import service from "../request";
// APP提现状态汇总
export function withdrawListState(query) {
    return service({
        url: '/api/merchants/userMoney/withdrawListState ',
        method: 'get',
        params: query
    })
}

// APP提现记录
export function withdrawListApi(query) {
    return service({
        url: '/api/merchants/userMoney/withdrawList',
        method: 'get',
        params: query
    })
}

// APP提现统计
export function withdrawStatisticsApi(query) {
    return service({
        url: '/api/merchants/userMoney/withdrawStatistics',
        method: 'get',
        params: query
    })
}

// 支付宝打款
export function batchAlipayApi(data) {
    return service({
        url: '/api/merchants/userMoney/batchAlipay',
        method: 'post',
        data: data
    })
}

// 批量线下打款
export function batchOfflineApi(data) {
    return service({
        url: '/api/merchants/userMoney/batchOffline',
        method: 'post',
        data: data
    })
}


// APP批量审核驳回
export function batchRejectApi(data) {
    return service({
        url: '/api/merchants/userMoney/batchReject',
        method: 'post',
        data: data
    })
}

// app提现设置内容
export function appMoneyApi(query) {
    return service({
        url: '/api/merchants/sys_config/info/app_money',
        method: 'get',
        params: query
    })
}

// app提现规则修改
export function updateApi(data) {
    return service({
        url: '/api/merchants/sys_config/update',
        method: 'post',
        data: data
    })
}