<template>
  <div style="background: white;height:100%;padding: 10px;margin: 10px">

    <div style="height: 100%;" v-if="USER_INFO.task_center_sw == true">

<!--      <div>-->
<!--        <div style="font-size: 16px;font-weight: 500;">-->
<!--          种草任务是否开启-->
<!--        </div>-->
<!--        <div style="margin-top: 10px;display: flex;align-items: center;justify-content: space-between">-->
<!--          开启后，用户可以在小程序上看到种草任务栏目-->
<!--          <el-switch-->
<!--              @change="merchantConfigUpdate"-->
<!--              style="margin-left: 5px"-->
<!--              v-model="merchantConfig.task_center_sw"-->
<!--              active-color="#13ce66"-->
<!--              inactive-color="#ff4949">-->
<!--          </el-switch>-->
<!--        </div>-->
<!--      </div>-->
<!--      <el-divider></el-divider>-->

      <el-tabs v-model="queryParams.taskState" @tab-click="handleClick" style="width: 100%;float: left">
        <el-tab-pane
            v-for="(item) in tabs"
            :key="item.value"
            :label="item.label"
            :name="item.value + ''"
        >
        </el-tab-pane>
      </el-tabs>

      <div style="text-align:left;padding-right: 10px">
        <el-input
            size="small"
            placeholder="任务商品名称"
            v-model="queryParams.name"
            clearable
            style="width: 160px;"
        >
        </el-input>

        <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="queryParams.task_start_time_start"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="任务开始时间">
        </el-date-picker>
        <i  style="margin-left: 5px" class="el-icon-minus"></i>
        <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="queryParams.task_start_time_end"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="任务开始时间">
        </el-date-picker>


        <!--      <span style="margin-left: 10px; color: rgba(166, 166, 166, 1);">结算状态:</span>-->
        <!--      <el-select v-model="value" placeholder="请选择" style="margin-left: 5px">-->
        <!--        <el-option-->
        <!--            v-for="item in options"-->
        <!--            :key="item.value"-->
        <!--            :label="item.label"-->
        <!--            :value="item.value">-->
        <!--        </el-option>-->
        <!--      </el-select>-->


        <el-button
            size="small"
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-search"
            @click="initData"
        >查询</el-button>

        <el-button style="float: right;margin-right: 30px" type="primary" size="small"  @click="addGrassTask" >新增种草任务</el-button>
      </div>


      <el-table
          :data="tableData"
          :loading="listLoading"
          border
          style="margin-top: 10px;"
          :max-height="autoHeight"
      >
        <el-table-column align="center" label="图片" >
          <template slot-scope="scope">
            <el-image
                style="width: 100px; height: 100px"
                :src="scope.row.img"
                fit="cover">
            </el-image>
          </template>
        </el-table-column>

        <el-table-column align="center" label="任务商品名" prop="name" >
        </el-table-column>

        <el-table-column align="center" label="任务时间" >
          <template slot-scope="scope">
            {{ parseDateFull(scope.row.task_start_time) }} 至  {{ parseDateFull(scope.row.task_end_time)}}
          </template>
        </el-table-column>

        <el-table-column align="center" label="可参与人数" prop="limit_num" >
        </el-table-column>

        <el-table-column align="center" label="达人/经纪人收益" >
          <template slot-scope="scope">
            ¥{{scope.row.quest_rewards}} / ¥{{scope.row.agent_earnings}}
          </template>
        </el-table-column>

        <el-table-column align="center" label="序号" prop="custom_sort">
        </el-table-column>

        <el-table-column align="center" label="结算状态" >
        </el-table-column>

        <el-table-column align="center" label="操作" >
          <template slot-scope="scope">
            <el-button
                v-show="queryParams.taskState==1 || queryParams.taskState==4 || queryParams.taskState==5"
                @click.native.prevent="taskDetails(scope.row)"
                type="text"
                size="small">
              任务详情
            </el-button>
            <el-button
                v-show="queryParams.taskState==1 || queryParams.taskState==4 || queryParams.taskState==5"
                @click.native.prevent="dataReport(scope.row)"
                type="text"
                size="small">
              数据报表
            </el-button>
            <el-button
                v-show="queryParams.taskState==1 || queryParams.taskState==2 || queryParams.taskState==3"
                @click.native.prevent="grass_task_edit(scope.row)"
                type="text"
                size="small">
              编辑
            </el-button>
            <el-button
                v-show="queryParams.taskState==1"
                @click.native.prevent="finish(scope.row)"
                type="text"
                size="small">
              结束
            </el-button>
            <el-button
                v-show="queryParams.taskState==2"
                @click.native.prevent="undercarriage(scope.row)"
                type="text"
                size="small">
              下架
            </el-button>
            <el-button
                v-show="queryParams.taskState==3"
                @click.native.prevent="shelves(scope.row)"
                type="text"
                size="small">
              上架
            </el-button>
            <el-button
                v-show="queryParams.taskState==4 || queryParams.taskState==5"
                @click.native.prevent="see(scope.row)"
                type="text"
                size="small">
              查看
            </el-button>
          </template>
        </el-table-column>

      </el-table>

      <el-row style="margin-top: 24px;margin-right: 20px">
        <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="this.queryParams.page"
                    :page-size="this.queryParams.limit"
                    :total="this.total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
        </el-col>
      </el-row>
    </div>
    <div v-else style="height: 100%; display: flex;justify-content: center;align-items: center">
      <el-empty description="种草任务功能未开启，请联系服务商开启"></el-empty>
    </div>
    <GrassTaskSituation ref="renewal"></GrassTaskSituation>
  </div>
</template>

<script>
import {grassTaskList, grassTaskEdit, taskState, grassTaskFinish} from "../../api/grass_task";
import { mapState } from 'vuex'
import {merchantsConfig, merchantsConfigUpdate} from "../../api/merchant_config";
import {copyTemplate} from "../../api/clip_tem_list";
import GrassTaskSituation from "../../components/grass_task_situation";
import { grassTaskInfo } from "../../api/grass_task_situation";

export default {
  name: "index",
  components: {
    GrassTaskSituation
  },
  computed: {
    ...mapState(['USER_INFO'])
  },
  data (){
    return{
      merchantConfig:{},
      autoHeight:'',
      autoWidth:'',
      tableData:[],
      listLoading: false,
      total:0,
      queryParams:{
        name:"",
        task_start_time_start:"",
        task_start_time_end:"",
        taskState: '999',
        page: 1,
        limit: 5
      },
      tabs:[],
      options: [{
        value: 0,
        label: '全部'
      }]
    }
  },
  created() {
    console.log("created")
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    console.log("mounted")
    this.merchantConfigInfo();
    this.getTaskState();
  },
  activated(){
    console.log("activated")
    this.getTaskState();
  },
  methods:{
    merchantConfigUpdate(){
      merchantsConfigUpdate(this.merchantConfig).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '操作成功'
          });
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    merchantConfigInfo(){
      merchantsConfig().then((res) => {
        this.merchantConfig = res.data
      });
    },
    finish(row){
      this.$confirm("确认结束该任务?", "结束任务", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let body = {
              id: row.id,
              task_status : 3
            }
            grassTaskFinish(body).then(res=> {
              if(res.code==0) {
                this.$message.success({
                  duration: 1000,
                  message: '任务结束成功'
                });
                this.getTaskState();
              }else{
                this.$message.error({message: res.message});
              }
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    shelves(row){
        this.$confirm("确认上架该任务?", "上架任务", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let body = {
              id: row.id,
              task_status : 2
            }
            grassTaskEdit(body).then(res=> {
              if(res.code==0) {
                this.$message.success({
                  duration: 1000,
                  message: '商品上架成功'
                });
                this.getTaskState();
              }else{
                this.$message.error({message: res.message});
              }
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    undercarriage(row){
      this.$confirm("确认下架该任务?", "下架任务", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            let body = {
              id: row.id,
              task_status : 1
            }
            grassTaskEdit(body).then(res=> {
              if(res.code==0) {
                this.$message.success({
                  duration: 1000,
                  message: '商品上架成功'
                });
                this.getTaskState();
              }else{
                this.$message.error({message: res.message});
              }
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    handleClick(tab, event) {
      this.tableData = []
      this.initData();
    },
    getTaskState(){
      taskState({}).then((response) => {
        this.tabs = response.data
        if(this.queryParams.taskState == '999'){
          this.queryParams.taskState = this.tabs[0].value + ''
        }
        this.initData();
      });
    },
    taskDetails(row){
      this.$router.push(
          {
            path:"task_details",
            query: {
              id: row.id
            }
          }
          )
    },
    dataReport(row){
      this.$router.push(
      {
        path:"/data_report",
        query: {
            id: row.id
        }
      }
      )
    },
    addGrassTask(){
      grassTaskInfo({}).then((res) => {
        let data = res.data
        console.log(JSON.stringify(data))
        let is_expire = data.is_expire
        if(is_expire == false){
          this.$router.push(
              {
                path : "/add_grass_task",
                query: {
                  type: 1
                }
              }
          )
        }else{
          this.$confirm("种草任务系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$refs.renewal.show(2)
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    grass_task_edit(row){
      this.$router.push(
          {
            path : "/add_grass_task",
            query: {
              type: 2,
              id: row.id
            }
          }
      )
    },
    see(row){
      this.$router.push(
          {
            path : "/grassTaskInfo",
            query: {
              id: row.id
            }
          }
      )
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 300) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams
      grassTaskList(queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d;
    },
  }
}
</script>

<style scoped>

</style>
