<template>
<div>
  <el-table
      border
      size="small"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
    <el-table-column align="center" prop="create_time" label="时间"></el-table-column>
    <el-table-column align="center" label="名额数量">
      <template slot-scope="scope">
        <span style="color: red" v-if="scope.row.operation_type == 1">+{{scope.row.quantity}}</span>
        <span v-if="scope.row.operation_type == 2">-{{scope.row.quantity}}</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="classify_name" label="所属IP">
      <template slot-scope="scope">
        <div style="display: flex;align-items: center;" v-if="scope.row.classify != null">
          <img
              style="width: 50px;height: 50px;border-radius: 50%"
              :src="scope.row.classify.classify_icon"
              fit="cover"/>
          <div style="margin-left: 10px">{{scope.row.classify.classify_name}}</div>
        </div>
        <div v-else>
          --
        </div>
      </template>
    </el-table-column>
  </el-table>

  <el-row style="margin-top: 24px;margin-right: 20px">
    <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
    </el-col>
  </el-row>

</div>
</template>

<script>
import { merchantEquipRes } from "../../../api/classify_limit";

export default {
  name: "LimitListRes",
  data(){
    return{
      queryParams:{
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading:false,
      tableData: []
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      merchantEquipRes(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
