import service from "../request";

export function folderList(query) {
    return service({
        url: '/api/creator/creatorFileManagement/list',
        method: 'get',
        params: query
    })
}

export function folderType(query) {
    return service({
        url: '/api/creator/folder/folderType',
        method: 'get',
        params: query
    })
}

export function addFolder(data) {
    return service({
        url: '/api/creator/creatorFileManagement/add',
        method: 'post',
        data: data
    })
}

export function folderRename(data) {
    return service({
        url: '/api/creator/creatorFileManagement/rename',
        method: 'post',
        data: data
    })
}

export function delFolder(data) {
    return service({
        url: '/api/creator/creatorFileManagement/del',
        method: 'post',
        data: data
    })
}


export function temReplaceMediaCheck(data) {
    return service({
        url: '/api/creator/temReplaceMedia/check',
        method: 'post',
        data: data
    })
}

export function temReplaceMediaAdd(data) {
    return service({
        url: '/api/creator/temReplaceMedia/add',
        method: 'post',
        data: data
    })
}

export function temReplaceMediaDel(data) {
    return service({
        url: '/api/creator/temReplaceMedia/del',
        method: 'post',
        data: data
    })
}

export function creatorUser(query) {
    return service({
        url: '/api/creator/creatorUser/info',
        method: 'get',
        params: query
    })
}
