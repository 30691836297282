import service from '../request'

export function grassTask(query) {
    return service({
        url: '/api/merchants/overview/grassTask/grassTask',
        method: 'get',
        params: query
    })
}

export function account(query) {
    return service({
        url: '/api/merchants/overview/grassTask/account',
        method: 'get',
        params: query
    })
}

export function douyinMember(data) {
    return service({
        url: '/api/merchants/overview/grassTask/douyinMember',
        method: 'post',
        data: data
    })
}

export function grassTaskInfo(query) {
    return service({
        url: '/api/merchants/overview/grassTask/info',
        method: 'get',
        params: query
    })
}

export function getPayImg(data) {
    return service({
        url: '/api/merchants/overview/grassTask/getPayImg',
        method: 'post',
        data: data
    })
}

export function check(query) {
    return service({
        url: '/api/merchants/overview/grassTask/check',
        method: 'get',
        params: query
    })
}
