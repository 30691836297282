<template>
  <div style="background: white;padding: 10px;margin: 10px">
    数据总览
    <div class="statistics">
      <div v-for="(item,index) in statisticsList" :key="index" class="statistics_item">
        <div class="item_value">{{item.value}}</div>
        <div class="item_lable">{{item.label}}</div>
      </div>
    </div>

    <div style="background: white;padding: 10px;margin: 10px">
      <el-input
          size="small"
          placeholder="ID"
          v-model="queryParams.id"
          clearable
          style="width: 120px;"/>
      <el-input
          size="small"
          placeholder="抖音昵称/抖音号"
          v-model="queryParams.query_one"
          clearable
          style="width: 220px;margin-left: 10px"/>

      <!--          <el-select clearable @change="queryParamsChange()" style="margin-left: 10px;width: 120px" v-model="queryParams.expire" placeholder="请选择" size="small">-->
      <!--            <el-option-->
      <!--                v-for="item in options"-->
      <!--                :key="item.value"-->
      <!--                :label="item.label"-->
      <!--                :value="item.value">-->
      <!--            </el-option>-->
      <!--          </el-select>-->

      <el-input
          size="small"
          placeholder="所属用户昵称/手机号"
          v-model="queryParams.query_two"
          clearable
          style="width: 200px;margin-left: 10px"/>

      <el-input
          size="small"
          placeholder="所属经纪人昵称/手机号"
          v-model="queryParams.query_tre"
          clearable
          style="width: 200px;margin-left: 5px"
      >
      </el-input>

      <!--          <el-select clearable @change="queryParamsChange()" style="margin-left: 10px;width: 120px" v-model="queryParams.mcn" placeholder="请选择" size="small">-->
      <!--            <el-option-->
      <!--                v-for="item in mcnOptions"-->
      <!--                :key="item.value"-->
      <!--                :label="item.label"-->
      <!--                :value="item.value">-->
      <!--            </el-option>-->
      <!--          </el-select>-->

      <!--            <el-select v-if="SynergyMerchants==true" clearable style="width: 200px;margin-left: 5px" @change="queryParamsChange" v-model="queryParams.marketing_ip" placeholder="请选择IP" size="small">-->
      <!--              <el-option-->
      <!--                  v-for="item in ipList"-->
      <!--                  :key="item.id"-->
      <!--                  :label="item.classify_name"-->
      <!--                  :value="item.id">-->
      <!--              </el-option>-->
      <!--            </el-select>-->

      <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="queryParamsChange()"
      >查询</el-button>
    </div>

    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%;margin-top: 15px"
        :max-height="autoHeight">>
      <el-table-column
          prop="id"
          label="绑定ID"
          width="100"
      >
      </el-table-column>
      <el-table-column
          prop="avatar"
          label="抖音头像昵称" width="160">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.avatar"
                fit="cover">
            </el-image>
            <span style="margin-left: 5px">{{scope.row.nickname}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="抖音信息" width="220">
        <template slot-scope="scope">
          <div>
            <div>粉丝数:{{scope.row.fans_number}}</div>
            <!--            <div>抖音号:{{scope.row.trill_account}}</div>-->
            <!--            <div>抖音绑定的手机:{{scope.row.trill_phone}}</div>-->
            <!--            <div>抖音uid:{{scope.row.trill_uid}}</div>-->
            <!--            <div>授权编号:SKLM{{scope.row.auth_number}}</div>-->
          </div>
        </template>
      </el-table-column>
      <!--      <el-table-column  prop="fans_number" label="抖音主页截图" width="100">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div v-if="scope.row.home_img">-->
      <!--            <el-image-->
      <!--                :src="scope.row.home_img"-->
      <!--                :preview-src-list="getSrcList(scope.row.home_img)"-->
      <!--                fit="cover">-->
      <!--            </el-image>-->
      <!--          </div>-->
      <!--          <div v-else>-->
      <!--            - -->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column  prop="fans_number" label="抖音橱窗截图" width="100">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div v-if="scope.row.showcase_img">-->
      <!--            <el-image-->
      <!--                :src="scope.row.showcase_img"-->
      <!--                :preview-src-list="getSrcList(scope.row.showcase_img)"-->
      <!--                fit="cover">-->
      <!--            </el-image>-->
      <!--          </div>-->
      <!--          <div v-else>-->
      <!--            - -->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--          align="center"-->
      <!--          prop="expiration_time"-->
      <!--          label="授权到期时间" width="160">-->
      <!--      </el-table-column>-->
      <!--      <el-table-column-->
      <!--          align="center"-->
      <!--          label="是否绑定MCN" width="160">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div v-if="scope.row.audit_status == 1 ">-->
      <!--            <div>已绑定</div>-->
      <!--            <div>绑定时间 {{scope.row.pass_time | formatDate }}</div>-->
      <!--          </div>-->
      <!--          <div v-else>-->
      <!--            - -->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <!--      <el-table-column v-if="SynergyMerchants==true" prop="fans_number" label="所属IP" width="220">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div style="display: flex;flex-direction: row;align-items: center" v-if="scope.row.merchantsProductClassify !=null">-->
      <!--            <el-image-->
      <!--                style="width: 50px;height: 50px;border-radius: 50%"-->
      <!--                :src="scope.row.merchantsProductClassify.classify_icon"-->
      <!--                fit="cover">-->
      <!--            </el-image>-->
      <!--            <span style="margin-left: 5px">{{scope.row.merchantsProductClassify.classify_name}}</span>-->
      <!--          </div>-->
      <!--          <div v-else>-->
      <!--            - -->
      <!--          </div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column label="联系人信息" width="200">-->
      <!--        <template slot-scope="scope">-->
      <!--          <div style="text-align: left">姓名：{{scope.row.apply_name}}</div>-->
      <!--          <div style="text-align: left">手机号：{{scope.row.apply_phone}}</div>-->
      <!--        </template>-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--          align="center"-->
      <!--          prop="rewards"-->
      <!--          label="精选联盟收益(元)" width="140">-->
      <!--      </el-table-column>-->

      <!--      <el-table-column-->
      <!--          align="center"-->
      <!--          prop="sumSales"-->
      <!--          label="精选联盟销售额(元)" width="140">-->
      <!--      </el-table-column>-->

      <el-table-column
          align="center"
          prop="address"
          label="所属用户" width="180">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.avatar_url"
                fit="cover">
            </el-image>
            <div style="margin-left: 10px; text-align: left">
              <div>{{scope.row.nick_name}}</div>
              <div>{{scope.row.phone_number}}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column  label="会员信息" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.sklmMembersLevel != null" style="display: flex;flex-direction: column">
            <span style="margin-left: 5px">用户等级:{{scope.row.sklmMembersLevel.members_name}}</span>
            <span style="margin-left: 5px">会员到期时间:{{scope.row.sklmMembersAudit.expiration_time | formatDate}}</span>
          </div>
          <div v-else>
            暂无会员信息
          </div>
        </template>
      </el-table-column>

      <el-table-column  label="所属经纪人" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.agentInfo != null" style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.agentInfo.avatar_url"
                fit="cover">
            </el-image>
            <div style="display: flex;flex-direction: column">
              <span style="margin-left: 5px">{{scope.row.agentInfo.nick_name}}</span>
              <span style="margin-left: 5px">{{scope.row.agentInfo.phone_number}}</span>
            </div>
          </div>
          <div v-else>
            暂无经纪人信息
          </div>
        </template>
      </el-table-column>

      <!--      <el-table-column fixed="right" align="center" label="操作" >-->
      <!--        <template slot-scope="scope">-->
      <!--&lt;!&ndash;          <el-button&ndash;&gt;-->
      <!--&lt;!&ndash;              @click.native.prevent="unbundling(scope.row)"&ndash;&gt;-->
      <!--&lt;!&ndash;              type="text"&ndash;&gt;-->
      <!--&lt;!&ndash;              size="small">&ndash;&gt;-->
      <!--&lt;!&ndash;              解除绑定&ndash;&gt;-->
      <!--&lt;!&ndash;          </el-button>&ndash;&gt;-->
      <!--          <el-button-->
      <!--              v-if="scope.row.auth_number != null"-->
      <!--              @click.native.prevent="doMcnUnbind(scope.row)"-->
      <!--              type="text"-->
      <!--              size="small">-->
      <!--            解除mcn绑定-->
      <!--          </el-button>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import { statistics ,list ,del} from "../../api/trillBinding";
import {marketingIp, synergyMerchantsCheck} from "../../api/product_classify";
import {unbindPass} from "../../api/trill_mcn_info";

export default {
  name: "TiktokList",
  filters:{
    formatDate(val) {
      var date = new Date(val);
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      return Y + M + D
    }
  },
  data(){
    return{
      autoHeight:'',
      autoWidth:'',
      statisticsList:[],
      options:[
        {
          label:"授权到期",
          value:1
        },
        {
          label:"授权未到期",
          value:2
        }
      ],
      mcnOptions:[
        {
          label:"已绑定mcn",
          value:1
        },
        {
          label:"未绑定mcn",
          value:2
        }
      ],
      queryParams:{
        page: 1,
        limit: 10,
        sidx: "stb.create_time",
        order: "desc",
        nickname:'',
        trill_account:'',
        expire: '',
        nick_name:'',
        phone_number:'',
        query_one: '',
        query_two: '',
        marketing_ip: '',
        query_tre: ''
      },
      total:0,
      listLoading: false,
      tableData: [],
      ipList:[],
      SynergyMerchants: false
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.doSynergyMerchantsCheck()
    this.statistics()
    this.doMarketingIp()
    this.initData()
  },
  methods:{
    doMcnUnbind(row){
      this.$confirm("确定解除mcn绑定？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        unbindPass({ auth_number : row.auth_number }).then((res) => {
          if (res.code == "0") {
            this.$message.success("操作成功");
            this.initData()
          }else{
            this.$message.error("操作失败!");
          }
        }).catch(() => {});
      }).catch(() => {});
    },
    doSynergyMerchantsCheck(){
      synergyMerchantsCheck({}).then((res) => {
        this.SynergyMerchants = res.data;
      });
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 350) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    unbundling(row){
      this.$confirm("确定解除 '"+ row.nickname +"' 抖音号?", "解除绑定", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        del({id: row.id}).then((response) => {
          this.statistics()
          this.doMarketingIp()
          this.initData()
        });
      }).catch(() => {});
    },
    doMarketingIp(){
      marketingIp({}).then((res) => {
        this.ipList = res.data.list;
      });
    },
    queryParamsChange(){
      this.queryParams.page = 1
      this.initData()
    },
    getSrcList(src){
      let srcList = []
      srcList.push(src)
      return srcList
    },
    statistics(){
      statistics({}).then((response) => {
        let list = response.data.list;
        let statisticsList = []
        statisticsList.push(list[0])
        statisticsList.push(list[1])
        this.statisticsList = statisticsList;
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      list(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    }
  }
}
</script>

<style scoped>


.statistics{
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.statistics_item{
  border-radius: 6px;
  background: rgba(245, 245, 245, 1);
  margin-right: 10px;
  width: 200px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.item_value{
  font-size: 24px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
}

.item_lable{
  font-size: 14px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}

</style>
