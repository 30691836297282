import service from '../request'

export function templateAddproduct(data) {
    return service({
        url: '/api/merchants/templateProduct/templateAddproduct',
        method: 'post',
        data: data
    })
}

export function goodsByTemplate(query) {
    return service({
        url: '/api/merchants/templateProduct/goodsByTemplate',
        method: 'get',
        params: query
    })
}

export function templateProductInfo(query,data) {
    return service({
        url: '/api/merchants/templateProduct/pageInfo',
        method: 'get',
        params: query,
        data: data
    })
}

export function temAddGood(data) {
    return service({
        url: '/api/merchants/templateProduct/temAddGood',
        method: 'post',
        data: data
    })
}

export function temDelGood(data) {
    return service({
        url: '/api/merchants/templateProduct/temDelGood',
        method: 'post',
        data: data
    })
}

export function temDelGoodAll(query) {
    return service({
        url: '/api/merchants/templateProduct/temDelGoodAll',
        method: 'get',
        params: query
    })
}

export function templateProductDel(query) {
    return service({
        url: '/api/merchants/templateProduct/del',
        method: 'get',
        params: query
    })
}

export function templateProductAdd(query,data) {
    return service({
        url: '/api/merchants/templateProduct/add',
        method: 'post',
        params: query,
        data: data
    })
}
