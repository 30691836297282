<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <div class="plate_type_info">
      <div>
        <div class="plate_type_name">抖音板块</div>
        <div class="plate_type_tips">开启后，用户在小程序首页可以看到抖音板块相关内容</div>
      </div>
      <el-switch
          @change="plateChange"
          v-model="userInfo.trill_plate"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>

<!--    <el-divider></el-divider>-->
<!--    <div class="plate_type_info">-->
<!--      <div>-->
<!--        <div class="plate_type_name">快手板块</div>-->
<!--        <div class="plate_type_tips">开启后，用户在小程序首页可以看到快手板块相关内容</div>-->
<!--      </div>-->
<!--      <el-switch-->
<!--          @change="plateChange"-->
<!--          v-model="userInfo.ks_plate"-->
<!--          active-color="#13ce66"-->
<!--          inactive-color="#ff4949">-->
<!--      </el-switch>-->
<!--    </div>-->

    <el-divider></el-divider>
    <div class="plate_type_info">
      <div>
        <div class="plate_type_name">创意模版</div>
        <div class="plate_type_tips">开启后，用户在小程序可以使用创意模版生成视频且可以看到“素材”板块内容</div>
      </div>
      <el-switch
          @change="merchantConfigUpdate"
          v-model="merchantConfig.creative_template"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>

    <el-divider></el-divider>
    <div class="plate_type_info">
      <div>
        <div class="plate_type_name">视频生成卡</div>
        <div class="plate_type_tips">开启后，用户在小程序我的页面可以看到视频生成卡板块（该功能的开启/关闭，建议与创意模版保持一致）</div>
      </div>
      <el-switch
          @change="merchantConfigUpdate"
          v-model="merchantConfig.gen_card"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>

    <el-divider></el-divider>
    <div class="plate_type_info">
      <div>
        <div class="plate_type_name">创作素材</div>
        <div class="plate_type_tips">开启后，用户在小程序商品详情页面可以看到创作素材板块（创作素材板块包含“商品素材”和“创作脚本”）</div>
      </div>
      <el-switch
          @change="merchantConfigUpdate"
          v-model="merchantConfig.material"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>

    <el-divider></el-divider>
    <div class="plate_type_info">
      <div>
        <div class="plate_type_name">会员制系统</div>
        <div class="plate_type_tips">开启后，用户必须成为会员后才能看到商品、领取视频（IP切片业务不受影响）</div>
      </div>
      <el-switch
          @change="merchantConfigUpdate"
          v-model="merchantConfig.member_sw"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>
    <div style="margin-top: 10px">
      <div style="display: flex;align-items: center">
          <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadImg"
          >
            <img v-if="merchantConfig.member_img" :src="merchantConfig.member_img" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div style="margin-left: 20px">
            <el-button size="small" type="primary" @click="merchantConfigUpdate">保存</el-button>
          </div>
      </div>
      <span style="margin-top: 5px;color: rgba(166, 166, 166, 1);font-size: 12px;">会员制系统开启时，请务必上传引导用户购买会员的图片，图片将显示在非会员用户的小程序首页，支持jpg、png格式</span>
    </div>

  </div>
</template>

<script>
import {merchantsInfo, merchantsUpdate} from "../../api/merchantsUser";
import {merchantsConfig, merchantsConfigUpdate} from "../../api/merchant_config";
import {getOssAccessInfo} from "../../api/common";

import Sha1 from "js-sha1";
var OSS = require("ali-oss");

export default {
  name: "plate_type",
  data(){
    return{
      merchantConfig:{
        creative_template: false,
        gen_card: false,
        material: true,
        member_sw: false,
        member_img: ''
      },
      userInfo: {
        trill_plate:1,
        ks_plate:1
      }
    }
  },
  mounted(){
    this.initData()
    this.merchantConfigInfo()
  },
  methods:{
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res))
      console.log(JSON.stringify(file))
    },
    beforeAvatarUpload(file) {
      return true
    },
    uploadImg(res){
      console.log(res)
      console.log(res.file)
      let newFile = res.file
      getOssAccessInfo().then(res => {
        console.log(JSON.stringify(res))
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token
        });
        console.log(client)
        let randomStr = "" ;
        for (var i = 0; i < 9; i++) {
          randomStr =randomStr +Math.floor(Math.random() * 10).toString();
        }
        let fileName =  "/merchantscenter/home/plate_type/" + Sha1(this.$store.state.token + new Date().getTime() + randomStr) + newFile.type;
        console.log(fileName)
        client.put(fileName, newFile).then(res =>{
          console.log(JSON.stringify(res));
          this.merchantConfig.member_img = "https://store.paitk.com" + fileName;
          console.log(JSON.stringify(this.form))
          this.$message.success("上传成功");
        })
      })
    },
    plateChange(){
      console.log(this.userInfo.trill_plate +"<>"+ this.userInfo.ks_plate)
      let userInfo = {
        id: this.userInfo.id,
        trill_plate: this.userInfo.trill_plate,
        ks_plate: this.userInfo.ks_plate
      }
      merchantsUpdate({},userInfo).then((res) => {
        this.$message.success("状态修改成功");
      });
    },
    merchantConfigUpdate(val){
      merchantsConfigUpdate(this.merchantConfig).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '操作成功'
          });
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    merchantConfigInfo(){
      merchantsConfig().then((res) => {
        this.merchantConfig = res.data
      });
    },
    initData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
        console.log(JSON.stringify(this.userInfo))
      });
    },
  }
}
</script>

<style scoped>
/* el-divider 修改高度&虚线效果 */
.el-divider--horizontal{
  margin: 8px 0;
  background: 0 0;
  border-top: 2px dashed #e8eaec;
}
.plate_type_info{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .plate_type_name{
    font-size: 16px;
    font-weight: 500;
    color: rgba(56, 56, 56, 1);
  }
  .plate_type_tips{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(56, 56, 56, 1);
  }

.avatar-uploader{
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
