<template>
  <div style="background: white;padding: 10px;margin: 10px">

  <el-table
      size="small"
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange">
    <el-table-column
        label="原素材信息"
        align="center">
      <template slot-scope="scope">
        <img v-if="scope.row.Type == 'Image'" src="../../assets/material/img.png" style="width: 30px;height: 30px" />
        <img v-if="scope.row.Type == 'Audio'" src="../../assets/material/mp3.png" style="width: 30px;height: 30px" />
        <img v-if="scope.row.Type == 'Text'" src="../../assets/material/text.png" style="width: 30px;height: 30px" />
        <img v-if="scope.row.Type == 'Video'" src="../../assets/material/video.png" style="width: 30px;height: 30px" />
        {{
          scope.row.Title ? scope.row.Title : scope.row.Content
        }}
      </template>
    </el-table-column>
    <el-table-column
        prop="Duration"
        label="有效时常(s)"
        align="center">
    </el-table-column>
    <el-table-column
        prop="TemplateMaterialId"
        label="ID命名"
        align="center">
    </el-table-column>
    <el-table-column
        prop="TemplateRemark"
        label="备注"
        align="center">
    </el-table-column>
    <el-table-column
        prop="mediasCount"
        label="生成视频有效素材数量"
        align="center">
    </el-table-column>
    <el-table-column
        prop="mediasCutCount"
        label="智能截取有效素材数量"
        align="center">
    </el-table-column>
    <el-table-column
        fixed="right"
        label="操作"
        align="center"
        width="200">
      <template slot-scope="scope">
        <el-button v-if="scope.row.TemplateReplaceable == true" type="text" size="small" @click="doBindingInfo(scope.row)">绑定素材</el-button>
        <el-button v-if="scope.row.TemplateReplaceable == true && scope.row.mediasCount > 0" type="text" size="small" @click="doBindingDel(scope.row)">移除所有绑定</el-button>
      </template>
    </el-table-column>
  </el-table>

    <BindingMedia ref="bindingMedia"></BindingMedia>

  </div>
</template>

<script>
import { getTemplateRequest } from "../../api/clip_tem_list";
import { mapState } from 'vuex'
import BindingMedia from "./BindingMedia";

import { temReplaceMediaDel } from "../../api/material";

export default {
  name: "material_config",
  components: {
    BindingMedia
  },
  data(){
    return{
      multipleSelection:[],
      queryParams:{
        page: 1,
        limit: 5
      },
      total:0,
      tableData: [],
      templateInfo: {},
      templateId: ''
    }
  },
  computed: {
    ...mapState(['GLOBAL_TEM_ID'])
  },
  mounted(){
    let templateId = this.$store.state.GLOBAL_TEM_ID
    this.templateId = templateId
    this.initData();
  },
  methods:{
    doBindingDel(row){
      this.$confirm("确定移除所有绑定的素材?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
            console.log(JSON.stringify(row))
            temReplaceMediaDel(row.check)
                .then((res) => {
                  console.log(res);
                  if (res.code == "0") {
                    this.initData();
                  }else{
                    this.$message.error(res.message);
           }
        })
     }).catch(() => {});
    },
    doBindingInfo(row){
      row.templateId = this.templateId
      console.log(JSON.stringify(row))
      this.$refs.bindingMedia.initData(row)
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      let body = {
        template_id: this.templateId,
        related_mediaid_flag: 1
      };
      getTemplateRequest(body).then((response) => {
        console.log(response)
        this.templateInfo = response.data.template
        let replaceMedia = response.data.replaceMedia;
        this.tableData = replaceMedia
      });
    },
  }
}
</script>

<style scoped>

</style>
