import service from "../request";

export function list(query) {
    return service({
        url: '/api/merchants/role/list',
        method: 'get',
        params: query
    })
}

export function add(data) {
    return service({
        url: '/api/merchants/role/add',
        method: 'post',
        data: data
    })
}

export function del(query) {
    return service({
        url: '/api/merchants/role/del',
        method: 'get',
        params: query
    })
}


export function menusAll(query) {
    return service({
        url: '/api/merchants/menus/all',
        method: 'get',
        params: query
    })
}
