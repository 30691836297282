<template>
  <el-container class="my_container">
    <el-header v-show="HEAD_INFO.showHeader==true">
      <HeadInfo/>
    </el-header>
    <el-container>
      <el-aside width="200px" v-show="HEAD_INFO.showMenu==true">
        <MyCloudMenuInfo/>
      </el-aside>
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import MyCloudMenuInfo from "../../components/MyCloudMenuInfo";
import HeadInfo from "../../components/HeadInfo";

import { mapState } from 'vuex'
import { mapMutations } from 'vuex';

export default {
  name: "Layout",
  components: {
    MyCloudMenuInfo ,HeadInfo
  },
  computed: {
    ...mapState(['USER_INFO','HEAD_INFO','ACTIVE_PATH'])
  }
}
</script>

<style scoped>

.el-header {
  height: 60px !important;
}

.el-aside {
  height: calc(100vh - 60px);
}

.el-main {
  padding: 0;
  height: calc(100vh - 60px);
  background-color: whitesmoke;
}

</style>
