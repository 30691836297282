<template>
<div class="menu_view">
  <div class="menu_info">
    <el-menu
        v-if="CLOUD_CLIP_MENU"
        @select="handleSelect"
        :default-active="activeIndex"
        :unique-opened = true
        class="el-menu-vertical-demo">
      <div v-for="(item,index) in CLOUD_CLIP_MENU.children" :key="index">
        <el-menu-item :index="item.path"  :key="index" v-if="item.has_children == false">
          <template slot="title">
            <el-image
                style="width: 20px;height: 20px;margin: 10px"
                :src="item.icon_img"
                fit="cover">
            </el-image>
            <span>{{item.name}}</span>
          </template>
        </el-menu-item>
        <el-submenu :index="item.path" :key="index" v-else>
          <template slot="title">
            <el-image
                style="width: 20px;height: 20px;margin: 10px"
                :src="item.icon_img"
                fit="cover">
            </el-image>
            <span>{{item.name}}</span>
          </template>
          <el-menu-item :index="childItem.path" v-for="(childItem,childIndex) in item.children" :key="childIndex">
            {{childItem.name}}
          </el-menu-item>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</div>
</template>

<script>

import { mapState } from 'vuex'
import {merchantsInfo} from "../api/merchantsUser";

export default {
  name: "MenuInfo",
  computed: {
    ...mapState(['CLOUD_CLIP_MENU'])
  },
  data(){
    return{
      activeIndex:'',
      creatorUser:{},
      merchantsInfo:{}
    }
  },
  mounted() {
      console.log("routes:",JSON.stringify(this.routes))
      this.activeIndex=this.$route.path
      this.initData()
  },
  methods:{
    initData(){
      merchantsInfo({}).then((res) => {
        this.creatorUser = res.data.creatorUser;
        this.merchantsInfo = res.data.merchantsInfo;
      });
    },
    handleSelect(index, indexPath) {
      console.log(index, indexPath);
      this.$router.push(index);
    }
  }
}
</script>

<style scoped>

.menu_view{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

</style>
