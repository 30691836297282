<template>
  <div>
    <div id="aliyun-video-editor" style="height:100vh"></div>
    <el-drawer
        title=""
        :visible.sync="showSearchMediaModal"
        :with-header="false">
      <div>
        <div class="head_info">
          <span class="add_media">添加素材</span>
          <i class="el-icon-close" @click="cancelSubit"></i>
        </div>

        <div class="media_info">

          <el-button
              class="filter-item"
              type="primary"
              icon="el-icon-back"
              size="mini"
              @click="previousPage"
              v-show="superiorId!==-1"
          >返回上级</el-button>

          <div class="btn_info_new">
            <el-button  type="primary" size="mini" @click="sureSubit">确定</el-button>
          </div>

          <el-table
              ref="multipleTable"
              :data="tableData"
              v-loading="listLoading"
              border
              @selection-change="handleSelectionChange"
              style="width: 100%;margin-top: 10px"
          >
            <el-table-column type="selection" :selectable="selectInit" width="50"> </el-table-column>
            <el-table-column prop="name" label="名称">
              <template slot-scope="scope">
                <span v-if="scope.row.file_type == 1" @click="nextFolder(scope.row)" class="row_name">{{scope.row.name}}</span>
                <span v-if="scope.row.file_type == 2" class="row_name_file">{{scope.row.name!=''?scope.row.name:scope.row.media_id}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="media_type" label="类型">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.file_type == 1">
                  {{
                    scope.row.media_type == "image"
                        ? "图片文件夹"
                        : scope.row.media_type == "video"
                        ? "视频文件夹"
                        : scope.row.media_type == "audio"
                            ? "音频文件夹"
                            : scope.row.media_type == "text"
                                ? "文字文件夹" : "其他文件夹"
                  }}
                </el-tag>

                <el-tag type="success" v-if="scope.row.file_type == 2">
                  {{
                    scope.row.media_type == "image"
                        ? "图片"
                        : scope.row.media_type == "video"
                        ? "视频"
                        : scope.row.media_type == "audio"
                            ? "音频"
                            : scope.row.media_type == "text"
                                ? "文字" : "其他"
                  }}
                </el-tag>

              </template>
            </el-table-column>
          </el-table>

          <el-row style="margin-top: 24px">
            <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="limit"
              :total="totalCount"
              layout="total, prev, pager, next"
          ></el-pagination>
        </span>
            </el-col>
          </el-row>

        </div>
      </div>
    </el-drawer>
  </div>

</template>

<script>

import { iceGet,updateTemplateRequest ,batchGetMediaInfos ,submitAudioProduceJob } from "../../api/ice";
import { folderList, folderType } from "../../api/material";
const OSS = require('ali-oss')
let obj = {}
import { mapState } from 'vuex'

export default {
  name: "templateDetail",
  data() {
    return {
      superiorIdArr:[],
      superiorId : -1,
      templateId:'',
      mediaType:'video',
      showSearchMediaModal: false,
      radioGroup: [],
      tableData: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      multipleSelection: [],
      listLoading: false
    };
  },
  computed: {
    ...mapState(['GLOBAL_TEM_ID'])
  },
  methods: {
    selectInit(row,index){
      if(row.file_type == 2 && row.media_type !== "text" && row.media_type !== "other"){
        return true
      }
      return false
    },
    previousPage(){
      this.superiorId = this.superiorIdArr.pop()
      this.page = 1
      console.log("previousPage:",this.superiorId)
      this.getList()
    },
    nextFolder(row){
      this.page = 1
      this.superiorIdArr.push(this.superiorId)
      this.superiorId = row.id
      console.log("superiorIdArr:",this.superiorIdArr)
      this.getList()
    },
    //获取文件夹类型
    getFolderType() {
      folderType().then((res) => {
        this.radioGroup = res.data
      });
    },
    //分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      console.log("handleSelectionChange:",val)
      this.multipleSelection = val
    },
    // 获取列表数据
    getList() {
      this.listLoading = true;
      let queryParams = {
        superiorId: this.superiorId,
        page: this.page,
        limit: this.limit,
        sidx: "create_time",
        order: "desc",
      };
      folderList(queryParams).then((response) => {
        console.log(response)
        this.tableData = response.data.list;
        this.totalCount = response.data.total_count;
        this.listLoading = false;
      });
    },
    sureSubit() {
      let _this = this
      return  iceGet(
          {
            "Action":"GetTemplate",
            "TemplateId":this.templateId,
            "RelatedMediaidFlag":1
          }
      ).then((res) => {
        console.log(res)
        const MediaIdsMap = JSON.parse(res.data.Template.RelatedMediaids);
        const addMediaIds = []
        const  multipleSelection = _this.multipleSelection
        multipleSelection.forEach(function(item){
          let type = item.media_type
          let mediaId = item.media_id
          if (!MediaIdsMap[type]) {
            MediaIdsMap[type] = [];
          }
          if (!MediaIdsMap[type].includes(mediaId)) {
            MediaIdsMap[type].push(mediaId);
            addMediaIds.push(mediaId);
          }
        });
        const updateParams = {
          action:"UpdateTemplate",
          template_id: _this.templateId,
          related_mediaids: JSON.stringify(MediaIdsMap)
        };
        updateTemplateRequest(
            updateParams
        ).then((res) => {
          console.log(res)
          _this.$message.success({
            duration: 1000,
            message: '素材添加成功'
          });
          _this.$refs.multipleTable.clearSelection();
          _this.multipleSelection = []
          obj.ok(Promise.resolve(this.doBatchGetMediaInfos(addMediaIds)))
          _this.showSearchMediaModal = false
        })
      })

    },
    cancelSubit() {
      this.showSearchMediaModal = false
    },
    getEditingProject(){
      return  iceGet(
          {
            "Action":"GetTemplate",
            "TemplateId":this.templateId
          }
      ).then((res) => {
        const config = res.data.Template.Config;
        return {
          timeline: config ? JSON.parse(config) : undefined
        };
      })
    },
    getStickerCategories(){
      return  iceGet(
          {
            "Action":"ListAllPublicMediaTags",
            "BusinessType":"sticker"
          }
      ).then((res) => {
        console.log("getStickerCategories:",JSON.stringify(res))
        const stickerCategories = res.data.MediaTagList.map(item => ({
          id: item.MediaTagId,
          name: item.MediaTagNameChinese
        }));
        return stickerCategories;
      })
    },
    getStickers({ categoryId, page, size }){
      return  iceGet(
          {
            "Action":"ListPublicMediaBasicInfos",
            "PageNo": page,
            "PageSize": size,
            "IncludeFileBasicInfo": true,
            "MediaTagId": categoryId
          }
      ).then((res) => {
        console.log("getStickers:",JSON.stringify(res))
        const fileList = res.data.MediaInfos.map(item => ({
          mediaId: item.MediaId,
          src: item.FileInfoList[0].FileBasicInfo.FileUrl
        }));

        return {
          total: res.data.TotalCount,
          stickers: fileList
        };
      })
    },
    getEditingProjectMaterials(){
      return  iceGet(
          {
            "Action":"GetTemplate",
            "TemplateId":this.templateId,
            "RelatedMediaidFlag":1
          }
      ).then((res) => {
        console.log(res)
        const RelatedMediaids = res.data.Template.RelatedMediaids;
        console.log("RelatedMediaids:",RelatedMediaids)
        if (RelatedMediaids == "{}"){
          console.log("relatedMediaids数组为空")
          return Promise.resolve([]);
        }else {
          console.log("relatedMediaids数组不为空")
          let relatedMediaids = JSON.parse(RelatedMediaids);
          const MediaIds = Object.values(relatedMediaids).reduce(
              (acc, cur) => acc.concat(cur),
              []
          );
          return Promise.resolve(this.doBatchGetMediaInfos(MediaIds));
        }
      })
    },
    doBatchGetMediaInfos(MediaIds){
      console.log("MediaIds:",MediaIds)
      return  batchGetMediaInfos(MediaIds).then((res) => {
        console.log(res.data)
        const  info = this.transMediaList(res.data);
        console.log(JSON.stringify(info))
        return Promise.resolve(info);
      });
    },
    deleteEditingProjectMaterials(mediaId,mediaType){
      let _this = this;
      return  iceGet(
          {
            "Action":"GetTemplate",
            "TemplateId":this.templateId,
            "RelatedMediaidFlag":1
          }
      ).then((res) => {
        const RelatedMediaids = res.data.Template.RelatedMediaids;
        console.log("RelatedMediaids:",RelatedMediaids)
        let MediaIdsMap = JSON.parse(RelatedMediaids);
        // 剔除要删除的媒资
        if (MediaIdsMap[mediaType] && MediaIdsMap[mediaType].includes(mediaId)) {
          MediaIdsMap[mediaType].splice(MediaIdsMap[mediaType].indexOf(mediaId), 1);
          console.log("剔除要删除的媒资:",JSON.stringify(MediaIdsMap))
          this.relatedMediaids = MediaIdsMap;
          const updateParams = {
            action:"UpdateTemplate",
            template_id: _this.templateId,
            related_mediaids: JSON.stringify(MediaIdsMap)
          };
          updateTemplateRequest(
              updateParams
          ).then((res) => {
            console.log(res)
            console.log("更新媒资成功～")
          })
          return true;
        }
        return false;
      })
    },
    async submitAudioProduceJob(text, voice){
      let _this = this;
      const bucket = 'ice-media';
      const path = 'autoProducedAudios/';
      const filename = `${text.slice(0, 10)}${Date.now()}`;

      let newAudio = {};
      let res = await submitAudioProduceJob(
          {
            "editing_config": JSON.stringify({
              voice,
              format: 'mp3'
            }),
            "input_config": text,
            "output_config": JSON.stringify({
              "bucket" : bucket,
              "object": `${path}${filename}`
            })
          }
      ).then((res) => {
        console.log(JSON.stringify(res))
        return res;
      })

      let mediaIds = []
      let mediaId = res.data
      mediaIds.push(mediaId)
      const  result = await this.doBatchGetMediaInfos(mediaIds);
      newAudio = result[0];

      iceGet(
          {
            "Action":"GetTemplate",
            "TemplateId":this.templateId,
            "RelatedMediaidFlag":1
          }
      ).then((res) => {
        const RelatedMediaids = res.data.Template.RelatedMediaids;
        console.log("RelatedMediaids:", RelatedMediaids)
        let MediaIdsMap = JSON.parse(RelatedMediaids);
        let type = "audio";
        if (!MediaIdsMap[type]) {
          MediaIdsMap[type] = [];
        }
        MediaIdsMap[type].push(mediaId);
        const updateParams = {
          action:"UpdateTemplate",
          template_id: _this.templateId,
          related_mediaids: JSON.stringify(MediaIdsMap)
        };
        updateTemplateRequest(
            updateParams
        ).then((res) => {
          console.log(res)
        })
      })
      console.log("newAudio:" + JSON.stringify(newAudio))
      return newAudio;
    },
    initAliyunVideoEditor(){
      console.log("initAliyunVideoEditor")
      window.AliyunVideoEditor.init({
        container: document.getElementById('aliyun-video-editor'),
        locale: 'zh-CN',
        mode: 'template',
        useDynamicSrc: true,
        defaultSubtitleText: 'VODM',
        defaultAspectRatio: '9:16',
        customFontList:[
          'alibaba-sans', // 阿里巴巴普惠体
          'fangsong', // 仿宋字体
          'kaiti', // 楷体
          'SimSun', // 宋体
          'siyuan-heiti', // 思源黑体
          'siyuan-songti', // 思源宋体
          'wqy-zenhei-mono', // 文泉驿等宽正黑
          'wqy-zenhei-sharp', // 文泉驿点阵正黑
          'wqy-microhei', // 文泉驿微米黑
          'zcool-gaoduanhei', // 站酷高端黑体
          'zcool-kuaile', // 站酷快乐体
          'zcool-wenyiti', // 站酷文艺体
          {
            key: '锐字真言体',
            name: '锐字真言体',
            url: 'https://ice-media.oss-cn-hangzhou.aliyuncs.com/typeface/%E9%94%90%E5%AD%97%E7%9C%9F%E8%A8%80%E4%BD%93.ttf',
          },
          {
            key: '方正楷体',
            name: '方正楷体',
            url: 'https://ice-media.oss-cn-hangzhou.aliyuncs.com/typeface/%E6%96%B9%E6%AD%A3%E6%A5%B7%E4%BD%93%E7%AE%80%E4%BD%93_%E7%8C%AB%E5%95%83%E7%BD%91.TTF',
          },
          {
            key: '优设标题黑',
            name: '优设标题黑',
            url: 'https://ice-media.oss-cn-hangzhou.aliyuncs.com/typeface/%E4%BC%98%E8%AE%BE%E6%A0%87%E9%A2%98%E9%BB%912.ttf',
          },
          {
            key: '文悦后现代体',
            name: '文悦后现代体',
            url: 'https://ice-media.oss-cn-hangzhou.aliyuncs.com/typeface/WenYue-HouXianDaiTi-W4-75-J-2.otf',
          },
          {
            key: '风楷',
            name: '风楷',
            url: 'https://ice-media.oss-cn-hangzhou.aliyuncs.com/typeface/B.ttf',
          },
          {
            key: '文悦新青年体',
            name: '文悦新青年体',
            url: 'https://ice-media.oss-cn-hangzhou.aliyuncs.com/typeface/%E6%96%87%E6%82%A6%E6%96%B0%E9%9D%92%E5%B9%B4%E4%BD%93.otf',
          },
          {
            key: '后现代细体',
            name: '后现代细体',
            url: 'https://ice-media.oss-cn-hangzhou.aliyuncs.com/typeface/%E5%90%8E%E7%8E%B0%E4%BB%A3%E7%BB%86%E4%BD%93.otf',
          },
          {
            key: '微软雅黑',
            name: '微软雅黑',
            url: 'https://ice-media.oss-cn-hangzhou.aliyuncs.com/typeface/msyh.ttf',
          }
        ],
        getDynamicSrc: (mediaId, mediaType, mediaOrigin, InputURL) => new Promise((resolve, reject) => {
          console.log("getDynamicSrc")

          console.log(mediaType + "<>" + InputURL)
          if (mediaType === 'font') {
            return resolve(InputURL);
          }

          return  iceGet(
              {
                "Action":"GetMediaInfo",
                "MediaId":mediaId
              }
          ).then((res) => {
            resolve(res.data.MediaInfo.FileInfoList[0].FileBasicInfo.FileUrl);
          })
        }),
        getStickerCategories:() => {
          console.log("getStickerCategories:列出公共素材库媒资的所有标签")
          return this.getStickerCategories()
        },
        getStickers: ({ categoryId, page, size }) => {
          console.log("getStickers:获取工程贴纸")
          return this.getStickers({ categoryId, page, size })
        },
        getEditingProjectMaterials: () => {
          console.log("getEditingProjectMaterials:获取工程关联素材")
          return this.getEditingProjectMaterials()
        },
        getEditingProject: async () => {
          console.log("getEditingProject:获取工程的时间线")
          return this.getEditingProject()
        },
        deleteEditingProjectMaterials: async (mediaId, mediaType) => {
          console.log("deleteEditingProjectMaterials:删除工程关联素材")
          this.deleteEditingProjectMaterials(mediaId,mediaType);
        },
        submitAudioProduceJob: async (text, voice) => {
          console.log("submitAudioProduceJob:智能生成配音")
          return this.submitAudioProduceJob(text, voice);
        },
        updateTemplate: async ({ coverUrl, timeline, isAuto }) => {
          let _this = this;
          console.log("UpdateTemplate:保存模版的时间线")
          const updateParams = {
            action:"UpdateTemplate",
            template_id: this.templateId,
            cover_url: coverUrl,
            config: JSON.stringify(timeline)
          };
          console.log(updateParams)
          updateTemplateRequest(
              updateParams
          ).then((res) => {
            console.log("模板更新成功:",res)
            _this.$message.success({
              duration: 1000,
              message: '模板更新成功'
            });
          })
        },
        searchMedia: mediaType => {
          return new Promise((resolve) => {
            this.showSearchMediaModal = true;
            this.mediaType = mediaType;
            obj.ok = resolve;
          })
        }
      })
    },
    /**
     * 将服务端的素材信息转换成 WebSDK 需要的格式
     */
    transMediaList(data) {
      if (!data) return [];

      if (Array.isArray(data)) {
        return data.map(item => {
          const basicInfo = item.MediaBasicInfo;
          let fileBasicInfo = {
            FileName: '文件异常',
            Duration:0,
            Width:0,
            Height:0,
            Bitrate:0
          };
          if(item.FileInfoList.length !== 0){
            fileBasicInfo = item.FileInfoList[0].FileBasicInfo;
          }else {
            console.log("文件异常:{}",item)
          }

          const mediaId = basicInfo.MediaId;
          const result = {
            mediaId
          };
          const mediaType = basicInfo.MediaType;
          result.mediaType = mediaType;

          if (mediaType === 'video') {
            result.video = {
              title: fileBasicInfo.FileName,
              duration: Number(fileBasicInfo.Duration),
              // 源视频的宽高、码率等数据，用于推荐合成数据，不传入或是0时无推荐数据
              width: Number(fileBasicInfo.Width) || 0,
              height: Number(fileBasicInfo.Height) || 0,
              bitrate: Number(fileBasicInfo.Bitrate) || 0,
              coverUrl: basicInfo.CoverURL
            };
            const spriteImages = basicInfo.SpriteImages;
            if (spriteImages) {
              try {
                const spriteArr = JSON.parse(spriteImages);
                const sprite = spriteArr[0];
                const config = JSON.parse(sprite.Config);
                result.video.spriteConfig = {
                  num: config.Num,
                  lines: config.SpriteSnapshotConfig.Lines,
                  cols: config.SpriteSnapshotConfig.Columns,
                  cellWidth: config.SpriteSnapshotConfig.CellWidth,
                  cellHeight: config.SpriteSnapshotConfig.CellHeight
                };
                result.video.sprites = sprite.SnapshotUrlList;
              } catch (e) {
                console.log(e);
              }
            }
          } else if (mediaType === 'audio') {
            result.audio = {
              title: fileBasicInfo.FileName,
              duration: Number(fileBasicInfo.Duration),
              coverURL: '' // 您可以给音频文件一个默认的封面图
            };
          } else if (mediaType === 'image') {
            result.image = {
              title: fileBasicInfo.FileName,
              coverUrl: fileBasicInfo.FileUrl,
              // 图片的宽高等数据，用于推荐合成数据，不传入或是0时无推荐数据
              width: Number(fileBasicInfo.Width) || 0,
              height: Number(fileBasicInfo.Height) || 0
            };
          }

          return result;
        });
      } else {
        return [data];
      }
    },
  },
  created() {
    this.getFolderType();
    this.getList()
  },
  mounted(){
    this.templateId = this.$store.state.GLOBAL_TEM_ID
    console.log("templateId:"+ this.templateId)
    this.initAliyunVideoEditor();
  },
  destroyed(){
    window.AliyunVideoEditor.destroy()
    console.log("destroyed")
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-drawer{
  background-color: #3e3c43;
}

.head_info{
  padding:  20px;
}

.add_media{
  color: hsla(0,0%,100%,.5);
  font-size: 20px;
}

::v-deep .el-icon-close{
  color: #0070cc;
  float: right;
  font-size: 30px;
}

.btn_info{
  padding-left: 10px;
  padding-bottom: 10px;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.btn_info_new{
  float: right;
  margin-bottom: 10px;
}

.media_info{
  padding: 10px;
}

.row_name{
  color: #1482f0;
}

.row_name_file{
  color: #8c939d;
}


.app-head {
  background: #2c3e50;
  height: 80px;
  line-height: 80px;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;

  .head-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.app-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.app-title {
  color: white;
  font-size: 20px;
  font-style: oblique;
  margin-left: 15px;
}
</style>
