import service from "../request";

export function cloudPackageList(query) {
    return service({
        url: '/api/merchants/cloudPackage/list',
        method: 'get',
        params: query
    })
}

export function cloudPackageRecharge(data) {
    return service({
        url: '/api/merchants/cloudPackage/recharge',
        method: 'post',
        data: data
    })
}

export function cloudPackageCheck(query) {
    return service({
        url: '/api/merchants/cloudPackage/check',
        method: 'get',
        params: query
    })
}

export function cloudPackageRecord(query) {
    return service({
        url: '/api/merchants/cloudPackage/record',
        method: 'get',
        params: query
    })
}
