<template>
  <div class="main_container">
    <div class="main_info">
      <div class="main_info_left">

        <div class="home_container_one">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">视频作业</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in video_job_info" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
        </div>

        <div class="home_container">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">账号数据</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in account_info" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
        </div>

        <div class="home_container">
          <div style="display: flex;align-items: center;justify-content: space-between">
            <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">运营配置</span>
            <el-button style="margin-left: auto" size="small" type="primary" @click="merchantConfigUpdate">保存</el-button>
          </div>
          <div class="op_cfg">
            <div class="op_cfg_row">用户视频领取后需在
              <el-input style="width: 80px" size="mini" v-model="merchantConfig.submit_time" placeholder="请输入内容"></el-input>
              天内提交作业，否则作业会变更为超时未交</div>
            <div class="op_cfg_row">用户提交作业后
              <el-input style="width: 80px" size="mini" v-model="merchantConfig.audit_time" placeholder="请输入内容"></el-input>
              天内运营未审核，系统将自动审核通过</div>
            <div class="op_cfg_row">用户
              <el-input style="width: 80px" size="mini" v-model="merchantConfig.timeout_count" placeholder="请输入内容"></el-input>
              次超时未交作业将无法领视频</div>
            <div class="op_cfg_row">用户
              <el-input style="width: 80px" size="mini" v-model="merchantConfig.reject_count" placeholder="请输入内容"></el-input>
              次作业审核驳回将无法领视频</div>
          </div>
        </div>
      </div>

      <div class="main_info_right">

<!--        <div class="info_item" style="display: flex;flex-direction: column;justify-content: space-around">-->
<!--          <div style="display: flex;justify-content: space-between;align-items: center">-->
<!--            <div class="item_name">系统到期时间</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 500;">{{videoIobInfo.expire_time}}</div>-->
<!--            <div  style="font-size: 14px;font-weight: 400;display: flex;align-items: center" >-->
<!--              <span @click="renewalShow" style="color: rgba(0, 119, 255, 1);font-size: 14px;margin-left: 10px;cursor: pointer">立即续期</span>-->
<!--              <img src="../../assets/home/right.png" style="width: 20px;height: 20px" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->


        <div class="info_item" style="margin-top: 10px">
          <div class="item_name">功能设置</div>
          <div style="display: flex;justify-content: space-between;margin-top: 5px">
            <div style="font-size: 14px;font-weight: 500;color: rgba(0, 0, 0, 1);">领视频交作业功能是否开启</div>
            <el-switch
                @change="merchantConfigUpdate"
                style="margin-left: 5px"
                v-model="merchantConfig.video_job_sw"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </div>
          <div style="font-size: 12px;font-weight: 400;color: rgba(166, 166, 166, 1);">开启后，用户可以在小程序上看到领视频模块</div>
        </div>


        <div class="info_item" style="margin-top: 10px" @click="gk_document">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div class="item_name">教程与帮助</div>
          </div>
          <div class="right_item_view">
            <div>领视频交作业系统使用文档</div>
            <div style="margin-top: 10px; font-size: 12px;font-weight: 400;color: rgba(166, 166, 166, 1);">创建作业、用户参与、生成数据报表、发放奖励，全流程指导说明</div>
          </div>
        </div>

      </div>
    </div>

    <VideoJobSituation ref="renewal"></VideoJobSituation>

  </div>
</template>

<script>

import { baseInfo ,account ,videoJobInfo } from "../../api/video_job/video_job_situation";
import {merchantsConfig, merchantsConfigUpdate} from "../../api/merchant_config";
import VideoJobSituation from "../../components/VideoJobSituation";
import {enterpriseCertInfo} from "../../api/merchantsUser";

export default {
  name: "gk",
  components: {
    VideoJobSituation
  },
  filters:{
  },
  data(){
    return{
      merchantConfig:{
        video_job_sw: false,
        submit_time: 2,
        audit_time:10,
        timeout_count: 3,
        reject_count: 3
      },
      video_job_info:[
      ],
      account_info:[
      ],
      videoIobInfo:{
        is_expire:false,
        expire_time: ''
      }
    }
  },
  created(){
  },
  mounted(){
    this.doVideoJobInfo()
    this.doBaseInfo()
    this.doAccount()
    this.merchantConfigInfo()
  },
  methods:{
    doVideoJobInfo(){
      videoJobInfo().then((res) => {
        this.videoIobInfo = res.data;
      });
    },
    renewalShow(){
      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data))
        let certification_status = res.data.certification_status
        if(certification_status ===2 || certification_status ===3){
          this.$refs.renewal.show(2)
        }else{
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/authentication_protocol");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    gk_document(){
      window.open('https://vodm2020.feishu.cn/docx/Wr12ddPCSo08r5xqEIzcvkftnug', '_blank');
    },
    merchantConfigUpdate(){
      merchantsConfigUpdate(this.merchantConfig).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '操作成功'
          });
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    merchantConfigInfo(){
      merchantsConfig().then((res) => {
        this.merchantConfig = res.data
      });
    },
    doBaseInfo(){
      baseInfo().then((res) => {
        this.video_job_info = res.data.list;
      });
    },
    doAccount(){
      account().then((res) => {
        this.account_info = res.data.list;
      });
    }
  }
}
</script>

<style scoped>


.main_container{
  height: 100%;
  padding: 10px;
}

.main_info{
  display: flex;
}

.main_info_left{
  width: 60%;
}

.main_info_right{
  width: 40%;
  margin-left: 10px;
}

.info_item{
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}


.item_name{
  font-size: 20px;
  font-weight: 500;
}

.left_item_view{
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  margin: 5px;
  padding: 10px;
}

.right_item_view{
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  padding: 10px;
  margin-top: 10px;
}

.home_container_one{
  background: white;
  padding: 10px;
  border-radius: 10px;
}

.home_container {
  background: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.op_cfg{
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.op_cfg_row{
  margin-top: 5px;
}

.flex_container {
  display: flex;
  flex-wrap: wrap;
}

.flex_chid {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.flex_lable {
  color: rgba(128, 128, 128, 1);
  font-size: 14px;
}

.flex_value {
  color: rgba(56, 56, 56, 1);
  font-size: 36px;
  font-weight: bold;
}


</style>
