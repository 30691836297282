<template>
  <div>
    <el-dialog title="传输列表" width="60%" :visible.sync="dialogVisible" :before-close="canCel">
      <div style="display: flex;justify-content: space-between;">
        <div>未完成数 / 总数：（<span style="color: #f56c6c">{{ completedNum }}</span> / {{ totalNum }}）</div>
        <el-button v-if="UPLOAD_LIST.length > 0" type="primary" @click="UPLOAD_LIST_DEL_ALL" size="small">全部清除</el-button>
      </div>

      <div class="upload_info">
        <div class="upload_item" v-for="item in UPLOAD_LIST" :key="item.value">
          <img v-if="item.media_type == 'image'" src="../../assets/material/img.png" style="width: 50px" />
          <img v-if="item.media_type == 'audio'" src="../../assets/material/mp3.png" style="width: 50px" />
          <img v-if="item.media_type == 'text'" src="../../assets/material/text.png" style="width: 50px" />
          <img v-if="item.media_type == 'video'" src="../../assets/material/video.png" style="width: 50px" />
          <div class="upload_item_right">
            <div>{{ item.file_all_name }}</div>
            <el-progress :percentage="item.percentage"></el-progress>
            <div>
              <span class="file_size">{{ item.fileSize | formatFileSize }}</span>
              <span v-show="item.percentage != 100" class="part_size" style="float: right">{{ (item.transmissionSpeed || 0) |
                formatFileSize  }}/s</span>
            </div>
          </div>
          <div @click="delItem(item)"><i class="el-icon-delete" style="font-size: 30px"></i></div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex';

export default {
  name: "upload_list",
  props: {
    dialogVisible: {}
  },
  filters: {
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      }
    }
  },
  data() {
    return {
      list: [

      ]
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['UPLOAD_LIST_DEL_ITEM', 'UPLOAD_LIST_DEL_ALL']),
    delItem(item) {
      this.UPLOAD_LIST_DEL_ITEM(item)
    },
    canCel() {
      this.$emit("close");
    },
  },
  computed: {
    ...mapState(['UPLOAD_LIST', 'USER_INFO']),
    // 未完成数量
    completedNum() {
      return this.UPLOAD_LIST.filter(item => item.percentage < 100).length
    },
    // 总数
    totalNum() {
      return this.UPLOAD_LIST.length
    }
  }
}
</script>

<style scoped>
.upload_info {
  height: 400px;
  overflow: auto;
}

.upload_item {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #EBEEF5;
  padding: 5px;
}

.upload_item_right {
  flex: 1;
  padding: 10px;
}

.file_size {
  font-size: 12px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
}

.part_size {
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 119, 255, 1);
}
</style>
