<template>
  <div style="padding: 10px;margin: 10px; background: white;">

    <el-button size="small" type="primary" @click="add">新建视频作业</el-button>

    <el-tabs v-model="queryParams.internal_state" @tab-click="changeTab">
      <el-tab-pane
          v-for="(item) in tabs"
          :key="item.value"
          :label="item.label"
          :name="item.value + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <div>
      <el-input
          @clear="doSearch"
          size="small"
          placeholder="模板ID/名称"
          v-model="queryParams.query1"
          clearable
          style="width: 140px;margin-left: 10px">
      </el-input>
      <el-input
          @clear="doSearch"
          size="small"
          placeholder="关联的商品名称"
          v-model="queryParams.query2"
          clearable
          style="width: 140px;margin-left: 10px">
      </el-input>
      <el-button
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="doSearch"
      >搜索</el-button>
    </div>

    <el-table
        :data="tableData"
        :loading="listLoading"
        border
        :max-height="autoHeight"
        style="margin-top: 10px"
    >
      <el-table-column align="center" label="模板信息" width="300">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <img style="width: 100px"  :src="scope.row.extend.temInfo.preview" model="widthFix"/>
            <div style="margin-left: 10px">
              <div>{{scope.row.extend.temInfo.name}}</div>
              <div>ID: {{scope.row.extend.temInfo.id}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="关联的商品" width="300">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <img style="width: 100px"  :src="scope.row.extend.productInfo.img" model="widthFix"/>
            {{scope.row.extend.productInfo.name}}
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="剩余可生成/已生成视频数" width="200">
        <template slot-scope="scope">
          {{scope.row.extend.temInfo.can_receive_num}} / {{scope.row.extend.temInfo.union_gen_num}}
        </template>
      </el-table-column>
      <el-table-column align="center" label="达人/经纪人收益" width="150">
        <template slot-scope="scope">
          <span class="money_tips">¥</span><span>{{scope.row.master_reward | numberFormat}}</span> / <span class="money_tips">¥</span><span>{{scope.row.broker_reward | numberFormat}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="排序" prop="custom_sort">
      </el-table-column>
      <el-table-column align="center" label="创建时间" prop="create_time" width="180">
      </el-table-column>
      <el-table-column align="center" label="上架时间" prop="shelf_time" width="180" v-if="queryParams.internal_state == 1">
      </el-table-column>
      <el-table-column align="center" label="下架时间" prop="down_time" width="180" v-if="queryParams.internal_state == 2">
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" >
        <template slot-scope="scope">
          <el-button
              type="text"
              size="small"
              @click.native.prevent="edit(scope.row)">
              编辑
          </el-button>
          <el-button
              v-if="queryParams.internal_state != 1"
              type="text"
              size="small"
              @click.native.prevent="shelf(scope.row)">
              上架
          </el-button>
          <el-button
              v-if="queryParams.internal_state == 1"
              type="text"
              size="small"
              @click.native.prevent="down(scope.row)">
              下架
          </el-button>
          <el-button
              v-if="queryParams.internal_state == 0"
              type="text"
              size="small"
              @click.native.prevent="handleDel(scope.row)">
              删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 24px;text-align: right">
      <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="queryParams.page"
          :page-size="queryParams.limit"
          :total="total"
          layout="total, prev, pager, next"
      ></el-pagination>
    </div>

  </div>
</template>

<script>
import {tabs, list, saveOrUpdate ,del} from "../../api/video_job/video_job";
import moment from 'moment';

export default {
  name: "zy",
  filters:{
    numberFormat: function (value) {
      return value.toFixed(2)
    },
  },
  data(){
    return{
      tabVal: '1',
      tabs:[],
      queryParams:{
        internal_state: '1',
        query1: '',
        query2: '',
        page:1,
        limit:5,
        sidx:'custom_sort',
        order:"desc"
      },
      tableData:[],
      listLoading: false,
      autoHeight:'',
      autoWidth:'',
      total:0
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initTabs()
    this.initData()
  },
  methods:{
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 290) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    handleDel(val) {
      this.$confirm("确认删除该视频任务?", "删除", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        del({id: val.id}).then((res) => {
              if (res.code == "0") {
                this.initTabs()
                this.initData()
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    shelf(row){
      this.$confirm("确认上架该视频任务?", "上架视频任务", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let form = {
          id : row.id,
          internal_state : 1,
          shelf_time : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }
        saveOrUpdate(form).then(res=> {
          if(res.code==0) {
            this.initTabs()
            if(this.tableData.length <= 1){
              this.queryParams.page = this.queryParams.page -1
            }
            this.initData()
            this.$message.success({
              duration: 1000,
              message: '上架成功'
            });
          }else{
            this.$message.error({message: res.message});
          }
        })
      }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
      });
    },
    down(row){
      this.$confirm("确认下架该视频任务?", "下架视频任务", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let form = {
          id : row.id,
          internal_state : 2,
          down_time : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }
        saveOrUpdate(form).then(res=> {
          if(res.code==0) {
            this.initTabs()
            if(this.tableData.length <= 1){
              this.queryParams.page = this.queryParams.page -1
            }
            this.initData()
            this.$message.success({
              duration: 1000,
              message: '下架成功'
            });
          }else{
            this.$message.error({message: res.message});
          }
        })
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    add(){
      this.$router.push(
          {
            path : "/video_job_add",
            query: {
              type: 1
            }
          }
      )
    },
    edit(row){
      this.$router.push(
          {
            path : "/video_job_add",
            query: {
              type: 2,
              id: row.id
            }
          }
      )
    },
    changeTab(tab, event){
      this.initData();
    },
    doSearch(){
      this.queryParams.page = 1;
      this.initData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initTabs(){
      tabs().then((res) => {
        this.tabs = res.data.list;
      });
    },
    initData(){
      this.listLoading = true
      let queryParams = this.queryParams
      if(queryParams.internal_state == 1){
        console.log("已上架")
        queryParams.sidx = 'shelf_time'
        queryParams.order = 'desc'
      }
      if(queryParams.internal_state == 2){
        console.log("已下架")
        queryParams.sidx = 'down_time'
        queryParams.order = 'desc'
      }
      if(queryParams.internal_state == 0){
        console.log("仓库中")
        queryParams.sidx = 'create_time'
        queryParams.order = 'desc'
      }
      list(queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

  .money_tips{
    font-size: 8px;
  }

</style>
