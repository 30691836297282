import Login from  "../views/login/index";
import LoginOptions from  "../views/login/LoginOptions";
import Register from  "../views/register/index";
import dataReport from  "../views/grass_task/dataReport";
import RechargeAgreement from  "../views/agreement/recharge";
import UserAgreement from  "../views/agreement/user";
import PrivacyAgreement from  "../views/agreement/privacy";
import TalentMatrixAgreement from  "../views/agreement/talent_matrix";
import AuthenticationProtocolInfo from "../views/agreement/AuthenticationProtocolInfo";

const common = [
    {
        path:"*",
        name:'首页',
        redirect: '/homeInfo',
    },
    {
        path:"/login",
        name:'登录',
        component: Login,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    },
    {
        path:"/login_options",
        name:'登录选项',
        component: LoginOptions,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    },
    {
        path:"/register",
        name:'注册',
        component: Register,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    },
    {
        path:"/data_report",
        name:'数据报表',
        component: dataReport,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    },
    {
        path:"/recharge_agreement",
        name:'充值协议',
        component: RechargeAgreement,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    },
    {
        path:"/privacy_agreement",
        name:'隐私协议',
        component: PrivacyAgreement,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    },
    {
        path:"/user_agreement",
        name:'用户协议',
        component: UserAgreement,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    },
    {
        path:"/talent_matrix_agreement",
        name:'达人矩阵营销系统年度合作协议',
        component: TalentMatrixAgreement,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    },
    {
        path:"/authentication_protocol_info",
        name:'视客联盟认证服务协议',
        component: AuthenticationProtocolInfo,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:false
            }
        }
    }
]

export default common;
