export class Queue {
    constructor(queryList, limit = 3, callback) {
        // 待执行的任务
        this.promises = queryList.slice()
        // 并行数
        this.limit = limit
        // 全部执行完成之后，回调
        this.callback = callback
        // 当前执行的数量
        this.concurrencyNum = 0
        // 开始最小的并行数量
        this.startConcurrencyCount = Math.min(this.limit, this.promises.length)
        this.startRunTask()
    }

    add(list) {
        if (!list.length) return
        list.forEach(item => {
            this.promises.push(item)
        })
        if(this.concurrencyNum <  this.startConcurrencyCount) {
            let i = 0
            while (i < this.startConcurrencyCount - this.concurrencyNum) {
                i++
                this.runTask()
            }
        }
    }

    // 开始执行的任务
    startRunTask() {
        let i = 0
        while (i < this.startConcurrencyCount) {
            i++
            this.runTask()
        }
    }

    // 取出任务，推送到执行器
    runTask() {
        const task = this.promises.shift()
        
        task && this.runner(task)
    }
    // 执行器
    async runner(task) {
        try {
            this.concurrencyNum++
            await task()
        } catch (error) {
            console.log('执行队列报错', error)
        } finally {
            this.concurrencyNum--
            this.picker()
        }
    }

    // 执行下一个任务
    picker() {
        if (this.concurrencyNum < this.limit && this.promises.length > 0) {
            this.runTask()
        } else if (!this.promises.length && this.concurrencyNum === 0) {
            this.callback && this.callback()
        }
    }

}