<template>
  <div>
    <el-button size="mini" type="primary" @click="dialogVisible =true">调整IP名额</el-button>
    <el-tabs v-model="activeName">
      <el-tab-pane label="IP名额" name="first">
        <LimitList ref="limitList" :agentInfo="agentInfo"></LimitList>
      </el-tab-pane>
      <el-tab-pane label="调整记录" name="second">
        <LimitListRes ref="limitListRes" :agentInfo="agentInfo"></LimitListRes>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
        title="调整IP名额"
        :visible.sync="dialogVisible"
        width="40%" append-to-body>
      <el-form  :model="form" :rules="rules" ref="ruleForm" label-width="100px" size="mini">
        <el-form-item label="请选择IP:" prop="classify_id">
          <el-select v-model="form.classify_id" placeholder="请选择" style="width: 100%">
            <el-option
                v-for="item in options"
                :key="item.classify_id"
                :label="item.classify_name"
                :value="item.classify_id">
              <div style="display: flex;align-items: center;justify-content: space-between">
                <div>{{ item.classify_name }}</div>
                <img
                    style="width: 20px;height: 20px;border-radius: 50%"
                    :src="item.classify_icon"
                    fit="cover"/>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型:" prop="operation_type">
          <el-radio-group v-model="form.operation_type">
            <el-radio :label="1">增加名额</el-radio>
            <el-radio :label="2">减少名额</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="数量:" prop="quota">
          <el-input-number
              style="width: 100%"
              :controls="true" :min="1"
              v-model="form.quota" placeholder="请输入名额数量"></el-input-number>
        </el-form-item>
      </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="subitForm('ruleForm')">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import LimitList from "../components/LimitList.vue";
import LimitListRes from "../components/LimitListRes.vue";
import {classifyLimit, equip} from "../../../api/classify_limit";
export default {
  name: "ClassifyLimit",
  components: {
    LimitList, LimitListRes
  },
  props: {
    agentInfo: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data(){
    return{
      queryParams:{
        agent_id: '',
        page: 1,
        limit: 99999
      },
      options: [],
      rules:{
        classify_id: [
          { required: true, message: '请选择IP', trigger: 'change' }
        ],
        operation_type: [
          { required: true, message: '请选择操作类型', trigger: 'change' }
        ],
        quota: [
          { required: true, message: '请输入限制数量', trigger: 'blur' },
        ]
      },
      form:{
        agent_id: '',
        classify_id: '',
        operation_type: 1,
        quota: 0
      },
      dialogVisible:false,
      activeName: 'first'
    }
  },
  mounted(){
    this.queryParams.agent_id = this.agentInfo.open_id
    this.form.agent_id = this.agentInfo.open_id
    this.initOptions()
  },
  methods:{
    subitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(JSON.stringify(this.form))
          equip(this.form).then((res) => {
            if(res.status == 1){
              this.$message.success("操作成功")
              this.$refs.limitList.initData()
              this.$refs.limitListRes.initData()
              this.dialogVisible = false
            }else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    initOptions(){
      classifyLimit(this.queryParams).then((res) => {
        this.options = res.data.list;
      });
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog__body{
  padding-top: 0px;
}
</style>
