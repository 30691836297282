<template>
  <div style="background: white;padding: 10px;margin: 10px">

  <el-tabs v-model="queryParams.queryStatus" @tab-click="handleClick" style="width: 100%">
    <el-tab-pane
        v-for="(item) in tabs"
        :key="item.value"
        :label="item.label"
        :name="item.value + ''"
    >
    </el-tab-pane>
  </el-tabs>

    <div style="display: flex;flex-direction: row;align-items: center">

      <el-input
          size="small"
          placeholder="请输入ID"
          v-model="queryParams.auth_number"
          clearable
          style="width: 200px;"
      >
      </el-input>

      <el-input
          size="small"
          placeholder="抖音昵称/抖音号/uid"
          v-model="queryParams.query_one"
          clearable
          style="width: 200px;margin-left: 5px"
      >
      </el-input>

      <el-select v-if="SynergyMerchants==true" clearable style="width: 200px;margin-left: 5px" @change="queryParamsChange" v-model="queryParams.marketing_ip" placeholder="请选择IP" size="small">
        <el-option
            v-for="item in ipList"
            :key="item.id"
            :label="item.classify_name"
            :value="item.id">
        </el-option>
      </el-select>

      <el-input
          size="small"
          placeholder="所属用户昵称/手机号"
          v-model="queryParams.query_two"
          clearable
          style="width: 200px;margin-left: 5px"
      >
      </el-input>

      <el-input
          size="small"
          placeholder="所属经纪人昵称/手机号"
          v-model="queryParams.query_tre"
          clearable
          style="width: 200px;margin-left: 5px"
      >
      </el-input>

      <el-button
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="queryParamsChange"
      >搜索</el-button>
    </div>

    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%;margin-top: 15px"
        :max-height="autoHeight">
      <el-table-column prop="auth_number" label="ID"></el-table-column>
      <el-table-column label="抖音头像昵称" width="220">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.avatar"
                fit="cover">
            </el-image>
            <span style="margin-left: 5px">{{scope.row.nickname}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column  label="抖音信息" width="220">
        <template slot-scope="scope">
              <div style="text-align: left">粉丝数：{{scope.row.fans_number}}</div>
              <div style="text-align: left">抖音号：{{scope.row.trill_account}}</div>
              <div style="text-align: left">抖音绑定的手机：{{scope.row.trill_phone}}</div>
              <div style="text-align: left">抖音uid：{{scope.row.trill_uid}}</div>
        </template>
      </el-table-column>
      <el-table-column  prop="fans_number" label="抖音主页截图" width="100">
        <template slot-scope="scope">
          <el-image
              :src="scope.row.home_img"
              :preview-src-list="getSrcList(scope.row.home_img)"
              fit="cover">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column  prop="fans_number" label="抖音橱窗截图" width="100">
        <template slot-scope="scope">
          <el-image
              :src="scope.row.showcase_img"
              :preview-src-list="getSrcList(scope.row.showcase_img)"
              fit="cover">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column v-if="SynergyMerchants==true" prop="fans_number" label="所属IP" width="220">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.classify_icon"
                fit="cover">
            </el-image>
            <span style="margin-left: 5px">{{scope.row.classify_name}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="SynergyMerchants==true && (queryParams.queryStatus == 4 || queryParams.queryStatus == 5)" label="更换IP" width="220">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.changeIpInfo.classify_icon"
                fit="cover">
            </el-image>
            <span style="margin-left: 5px">{{scope.row.changeIpInfo.classify_name}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="联系人信息" width="200">
        <template slot-scope="scope">
          <div style="text-align: left">姓名：{{scope.row.apply_name}}</div>
          <div style="text-align: left">手机号：{{scope.row.apply_phone}}</div>
        </template>
      </el-table-column>
      <el-table-column  prop="fans_number" label="所属用户" width="260">
        <template slot-scope="scope">
        <div style="display: flex;flex-direction: row;align-items: center">
          <el-image
              style="width: 50px;height: 50px;border-radius: 50%"
              :src="scope.row.avatar_url"
              fit="cover">
          </el-image>
          <div style="display: flex;flex-direction: column">
            <span style="margin-left: 5px">{{scope.row.nick_name}}</span>
            <span style="margin-left: 5px">{{scope.row.phone_number}}</span>
          </div>
        </div>
        <div style="margin-top: 5px">{{scope.row.wx_open_id}}</div>
        </template>
      </el-table-column>
      <el-table-column  label="会员信息" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.sklmMembersLevel != null" style="display: flex;flex-direction: column">
            <span style="margin-left: 5px">用户等级:{{scope.row.sklmMembersLevel.members_name}}</span>
            <span style="margin-left: 5px">会员到期时间:{{scope.row.sklmMembersAudit.expiration_time | formatDate}}</span>
          </div>
          <div v-else>
            暂无会员信息
          </div>
        </template>
      </el-table-column>
      <el-table-column  label="所属经纪人" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.agentInfo != null" style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.agentInfo.avatar_url"
                fit="cover">
            </el-image>
            <div style="display: flex;flex-direction: column">
              <span style="margin-left: 5px">{{scope.row.agentInfo.nick_name}}</span>
              <span style="margin-left: 5px">{{scope.row.agentInfo.phone_number}}</span>
            </div>
          </div>
          <div v-else>
            暂无经纪人信息
          </div>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" prop="applay_time" width="160" v-if="queryParams.queryStatus == 0"></el-table-column>
      <el-table-column label="申请时间" prop="applay_unbind_time" width="160" v-if="queryParams.queryStatus == 2"></el-table-column>
<!--      <el-table-column  prop="pass_time" label="绑定MCN时间" width="160"></el-table-column>-->
      <el-table-column  v-if="queryParams.queryStatus == 1"  prop="dismiss_time" label="驳回时间" width="160"></el-table-column>
      <el-table-column  v-if="queryParams.queryStatus == 1"  prop="dismiss_reason" label="驳回理由" width="160"></el-table-column>
      <el-table-column  v-if="queryParams.queryStatus == 3" prop="unbind_dismiss_time" label="驳回时间" width="160"></el-table-column>
      <el-table-column  v-if="queryParams.queryStatus == 3" prop="unbind_reason" label="驳回理由" width="160"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="160" v-if="queryParams.queryStatus == 0">
        <template slot-scope="scope">
            <el-button
                @click.native.prevent="doAuditPass(scope.row)"
                type="text"
                size="small">
              审核通过
            </el-button>
            <el-button
                @click.native.prevent="doAuditDismiss(scope.row)"
                type="text"
                size="small">
              审核驳回
            </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="160" v-if="queryParams.queryStatus == 4">
        <template slot-scope="scope">
          <el-button
              @click.native.prevent="doChangeIpPass(scope.row)"
              type="text"
              size="small">
            审核通过
          </el-button>
          <el-button
              @click.native.prevent="doChangeIpDismis(scope.row)"
              type="text"
              size="small">
            审核驳回
          </el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="160" v-if="queryParams.queryStatus == 2">
        <template slot-scope="scope">
          <el-button
              @click.native.prevent="doUnbindPass(scope.row)"
              type="text"
              size="small">
            审核通过
          </el-button>
          <el-button
              @click.native.prevent="doUnbindDismiss(scope.row)"
              type="text"
              size="small">
            审核驳回
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

    <el-dialog size="small" title="MCN绑定驳回" :visible.sync="auditDismissVisible" width="30%">
      <el-form
          ref="form"
          :model="auditDismissForm"
          label-width="120px"
          style="padding-right: 20px"
      >
        <el-input
            style="margin-top: 20px"
            type="textarea"
            maxlength="30"
            show-word-limit
            placeholder="请输入驳回原因,最多可输入30个汉字"
            v-model="auditDismissForm.dismiss_reason">
        </el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="auditDismissFormSubmit" size="small">确 认</el-button>
        <el-button @click="auditDismissVisible = false" size="small">取 消</el-button>
      </div>
    </el-dialog>

    <el-dialog size="small" title="MCN解绑驳回" :visible.sync="unbindPassVisible" width="30%">
      <el-form
          ref="form"
          :model="unbindPassForm"
          label-width="120px"
          style="padding-right: 20px"
      >
        <el-input
            style="margin-top: 20px"
            type="textarea"
            maxlength="30"
            show-word-limit
            placeholder="请输入驳回原因,最多可输入30个汉字"
            v-model="unbindPassForm.unbind_reason">
        </el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="unbindPassFormSubmit" size="small">确 认</el-button>
        <el-button @click="unbindPassVisible = false" size="small">取 消</el-button>
      </div>
    </el-dialog>


    <el-dialog size="small" title="更换IP驳回" :visible.sync="changeIpVisible" width="30%">
      <el-form
          ref="form"
          :model="changeIpForm"
          label-width="120px"
          style="padding-right: 20px"
      >
        <el-input
            style="margin-top: 20px"
            type="textarea"
            maxlength="30"
            show-word-limit
            placeholder="请输入驳回原因,最多可输入30个汉字"
            v-model="changeIpForm.change_dismiss_reason">
        </el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="changeIpFormSubmit" size="small">确 认</el-button>
        <el-button @click="changeIpVisible = false" size="small">取 消</el-button>
      </div>
    </el-dialog>

    <ElectricitySituation ref="renewal"></ElectricitySituation>

  </div>
</template>

<script>
import { state ,list ,auditPass ,auditDismiss ,unbindPass ,unbindDismiss ,changeIpPass ,changeIpDismiss} from "../../api/trill_mcn_info";
import {marketingIp, synergyMerchantsCheck} from "../../api/product_classify";
import ElectricitySituation from "../../components/electricity_situation";
import { electricityInfo } from "../../api/electricity_situation";

export default {
  name: "TrillMcn",
  components: {
    ElectricitySituation
  },
  filters:{
    formatDate(val) {
      var date = new Date(val);
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      return Y + M + D
    }
  },
  data(){
    return{
      auditDismissVisible: false,
      auditDismissForm:{
        auth_number:'',
        dismiss_reason:''
      },
      unbindPassVisible: false,
      unbindPassForm:{
        auth_number:'',
        unbind_reason:''
      },
      ipList:[],
      tabs:[],
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        auth_number:'',
        query_one:'',
        marketing_ip: '',
        query_two: '',
        queryStatus:0,
        page: 1,
        limit: 5,
        sidx: "tmi.applay_time",
        order: "asc",
        query_tre: ''
      },
      total:0,
      listLoading: false,
      tableData: [],
      SynergyMerchants:false,
      changeIpForm: {},
      changeIpVisible: false
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.doSynergyMerchantsCheck()
    this.doMarketingIp()
    this.doState();
  },
  methods:{
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 260) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    getSrcList(src){
      let srcList = []
      srcList.push(src)
      return srcList
    },
    doMarketingIp(){
      marketingIp({}).then((res) => {
        this.ipList = res.data.list;
      });
    },
    doSynergyMerchantsCheck(){
      synergyMerchantsCheck({}).then((res) => {
        this.SynergyMerchants = res.data;
      });
    },
    doAuditPass(row){
      electricityInfo({}).then((res) => {
        let data = res.data
        console.log(JSON.stringify(data))
        let is_expire = data.is_expire
        if(is_expire == false){
          this.$confirm("确定MCN绑定审核通过？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            auditPass({ auth_number : row.auth_number }).then((res) => {
              if (res.code == "0") {
                this.$message.success("操作成功");
                this.doState()
              }else{
                this.$message.error("操作失败!");
              }
            }).catch(() => {});
          }).catch(() => {});
        }else{
          this.$confirm("电商MCN系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$refs.renewal.show(2)
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    doAuditDismiss(row){
      electricityInfo({}).then((res) => {
        let data = res.data
        console.log(JSON.stringify(data))
        let is_expire = data.is_expire
        if(is_expire == false){
          this.auditDismissForm.auth_number = row.auth_number
          this.auditDismissVisible = true
        }else{
          this.$confirm("电商MCN系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$refs.renewal.show(2)
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    auditDismissFormSubmit(){
      auditDismiss(this.auditDismissForm).then((res) => {
        if (res.code == "0") {
          this.$message.success("操作成功");
          this.auditDismissVisible = false
          this.doState()
        }else{
          this.$message.error("操作失败!");
        }
      }).catch(() => {});
    },
    doChangeIpPass(row){
      electricityInfo({}).then((res) => {
        let data = res.data
        console.log(JSON.stringify(data))
        let is_expire = data.is_expire
        if(is_expire == false){
          this.$confirm("确定更换ip审核通过？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            changeIpPass({ auth_number : row.auth_number }).then((res) => {
              if (res.code == "0") {
                this.$message.success("操作成功");
                this.doState()
              }else{
                this.$message.error("操作失败!");
              }
            }).catch(() => {});
          }).catch(() => {});
        }else{
          this.$confirm("电商MCN系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$refs.renewal.show(2)
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    doUnbindPass(row){
      electricityInfo({}).then((res) => {
        let data = res.data
        console.log(JSON.stringify(data))
        let is_expire = data.is_expire
        if(is_expire == false){
          this.$confirm("确定MCN解绑审核通过？", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            unbindPass({ auth_number : row.auth_number }).then((res) => {
              if (res.code == "0") {
                this.$message.success("操作成功");
                this.doState()
              }else{
                this.$message.error("操作失败!");
              }
            }).catch(() => {});
          }).catch(() => {});
        }else{
          this.$confirm("电商MCN系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$refs.renewal.show(2)
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    doChangeIpDismis(row){
      electricityInfo({}).then((res) => {
        let data = res.data
        console.log(JSON.stringify(data))
        let is_expire = data.is_expire
        if(is_expire == false){
          this.changeIpForm.auth_number = row.auth_number
          this.changeIpVisible = true
        }else{
          this.$confirm("电商MCN系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$refs.renewal.show(2)
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    doUnbindDismiss(row){
      electricityInfo({}).then((res) => {
        let data = res.data
        console.log(JSON.stringify(data))
        let is_expire = data.is_expire
        if(is_expire == false){
          this.unbindPassForm.auth_number = row.auth_number
          this.unbindPassVisible = true
        }else{
          this.$confirm("电商MCN系统到期，请续费!", "提示", {
            confirmButtonText: "去续费",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$refs.renewal.show(2)
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    changeIpFormSubmit(){
      changeIpDismiss(this.changeIpForm).then((res) => {
        if (res.code == "0") {
          this.$message.success("操作成功");
          this.changeIpVisible = false
          this.doState()
        }else{
          this.$message.error("操作失败!");
        }
      }).catch(() => {});
    },
    unbindPassFormSubmit(){
      unbindDismiss(this.unbindPassForm).then((res) => {
        if (res.code == "0") {
          this.$message.success("操作成功");
          this.unbindPassVisible = false
          this.doState()
        }else{
          this.$message.error("操作失败!");
        }
      }).catch(() => {});
    },
    queryParamsChange(){
        this.queryParams.page = 1
        this.initData()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    doState(){
      state({}).then((res) => {
        this.tabs = res.data
        this.initData();
      });
    },
    handleClick(tab, event) {
      this.tableData = []
      this.queryParams.page = 1;
      if(this.queryParams.queryStatus==0){
        console.log("MCN绑定-申请中")
        this.queryParams.sidx='tmi.applay_time'
        this.queryParams.order='asc'
      }
      if(this.queryParams.queryStatus==1){
        console.log("MCN绑定-已驳回")
        this.queryParams.sidx='tmi.dismiss_time'
        this.queryParams.order='desc'
      }
      if(this.queryParams.queryStatus==2){
        console.log("MCN解绑-申请中")
        this.queryParams.sidx='tmi.applay_unbind_time'
        this.queryParams.order='asc'
      }
      if(this.queryParams.queryStatus==3){
        console.log("MCN解绑-已驳回")
        this.queryParams.sidx='tmi.unbind_dismiss_time'
        this.queryParams.order='desc'
      }
      this.initData();
    },
    initData(){
      this.listLoading = true
      list(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
