import service from "../request";

export function userInfo(query) {
    return service({
        url: '/api/merchants/statistics/userInfo',
        method: 'get',
        params: query
    })
}

export function survey(data) {
    return service({
        url: '/api/merchants/statistics/survey',
        method: 'post',
        data: data
    })
}

export function genCard(query) {
    return service({
        url: '/api/merchants/statistics/genCard',
        method: 'get',
        params: query
    })
}

export function tem(query) {
    return service({
        url: '/api/merchants/statistics/tem',
        method: 'get',
        params: query
    })
}

export function task(query) {
    return service({
        url: '/api/merchants/statistics/task',
        method: 'get',
        params: query
    })
}

export function withdrawal(query) {
    return service({
        url: '/api/merchants/statistics/withdrawal',
        method: 'get',
        params: query
    })
}


export function homeClipInfo(query) {
    return service({
        url: '/api/merchants/statistics/homeClipInfo',
        method: 'get',
        params: query
    })
}

export function homeExpertInfo(query) {
    return service({
        url: '/api/merchants/statistics/homeExpertInfo',
        method: 'get',
        params: query
    })
}

export function homeProfileData(data) {
    return service({
        url: '/api/merchants/statistics/homeProfileData',
        method: 'post',
        data: data
    })
}
