<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div>
      <el-input
          size="small"
          placeholder="商户单号"
          v-model="queryParams.out_trade_no"
          clearable
          style="width: 120px;"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="交易单号"
          v-model="queryParams.transaction_id"
          clearable
          style="width: 120px;margin-left: 10px"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="微信昵称"
          v-model="queryParams.nick_name"
          clearable
          style="width: 120px;margin-left: 10px"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="手机号"
          v-model="queryParams.phone_number"
          clearable
          style="width: 120px;margin-left: 10px"
      >
      </el-input>

      <el-input
          size="small"
          placeholder="经纪人姓名"
          v-model="queryParams.agent_nick_name"
          clearable
          style="width: 130px;margin-left: 10px"
      >
      </el-input>

      <el-input
          size="small"
          placeholder="经纪人手机号"
          v-model="queryParams.agent_phone_number"
          clearable
          style="width: 130px;margin-left: 10px"
      >
      </el-input>

      <el-select @change="selectTrigger()" style="margin-left: 10px;width: 100px" v-model="queryParams.agent" placeholder="经纪人" size="small">
        <el-option
            v-for="item in agentOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>

      <el-select @change="selectTrigger()" style="margin-left: 10px;width: 150px" v-model="queryParams.refundStatus" placeholder="订单状态" size="small">
        <el-option
            v-for="item in refundOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>

      <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="search()"
      >搜索</el-button>
    </div>

    <el-table
        :data="tableData"
        :loading="listLoading"
        border
        style="width: 100%;margin-top: 10px"
        :max-height="autoHeight"
    >
      <el-table-column align="center" prop="out_trade_no" label="商户单号" width="200">
      </el-table-column>
      <el-table-column align="center" prop="transaction_id" label="交易单号" width="200">
      </el-table-column>
      <el-table-column align="center" prop="open_id" label="用户信息" width="200">
        <template slot-scope="scope">
          <div style="display: flex">
            <el-image
                style="width: 50px;height: 50px"
                :src="scope.row.avatar_url_one"
                fit="cover">
            </el-image>
            <div style="flex: 1">
              <div>{{scope.row.nick_name_one}}</div>
              <div>{{scope.row.phone_number_one}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="description" label="购买内容" >
      </el-table-column>
      <el-table-column align="center" prop="total" label="实付款" >
      </el-table-column>
      <el-table-column align="center" prop="trade_type" label="支付方式" >
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="支付时间" width="200">
      </el-table-column>
      <el-table-column align="center" prop="open_id" label="推荐人" width="200">
        <template slot-scope="scope">
          <div style="display: flex" v-show="scope.row.avatar_url_tre">
            <el-image
                style="width: 50px;height: 50px"
                :src="scope.row.avatar_url_tre"
                fit="cover">
            </el-image>
            <div style="flex: 1">
              <div>{{scope.row.nick_name_tre}}</div>
              <div>{{scope.row.phone_number_tre}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="referrer_earnings" label="推荐人收益" width="200">
      </el-table-column>
      <el-table-column align="center" prop="open_id" label="经纪人" width="200">
        <template slot-scope="scope">
          <div style="display: flex" v-show="scope.row.avatar_url_two">
            <el-image
                style="width: 50px;height: 50px"
                :src="scope.row.avatar_url_two"
                fit="cover">
            </el-image>
            <div style="flex: 1">
              <div>{{scope.row.nick_name_two}}</div>
              <div>{{scope.row.phone_number_two}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="agent_earnings" label="经纪人收益" width="200">
      </el-table-column>
      <el-table-column align="center" prop="plan_settlemen_time" label="结算时间" width="200">
      </el-table-column>
      <el-table-column align="center" label="订单状态" >
        <template slot-scope="scope">
          <div v-if="scope.row.refundCount === 0">支付成功</div>
          <div v-else>
            <div v-if="scope.row.refunding > 0">申请退款中</div>
            <div v-else>
              <div v-if="scope.row.sumRefund === 0">支付成功</div>
              <div v-else>
                <div v-if="scope.row.sumRefund < scope.row.selling_price">部分退款完成</div>
                <div v-else>全部退款完成</div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button v-if="scope.row.refundCount > 0" type="text" size="small" @click="detail(scope.row)">退款详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

    <el-dialog
        size="small"
        title="退款详情"
        :visible.sync="dialogVisible"
        width="60%">
      <div>
        <div>
          <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">源订单ID：{{wechatPay.out_trade_no}}</div>
          <div style="display: flex;justify-content: space-between">
            <div style="font-size: 14px;font-weight: 500;color: rgba(56, 56, 56, 1);">{{wechatPay.members_name}}</div>
            <div style="font-size: 14px;font-weight: 500;color: rgba(56, 56, 56, 1);">支付金额：¥{{wechatPay.total | numberFormat}}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">会员有效期：{{wechatPay.effective_days | formattingEffectiveDays}}</div>
            <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">生成卡数量：{{wechatPay.gen_card_quota}}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">支付时间：{{wechatPay.success_time}}</div>
            <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">交易单号：{{wechatPay.transaction_id}}</div>
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <span style="font-size: 14px;font-weight: 500;color: rgba(56, 56, 56, 1);">退款详情</span>
          <div style="padding: 10px">
            <div style="margin-bottom: 10px;" v-for="(item,index) in refundList" :key="index">
              <div class="refund_item">退款ID：{{item.out_refund_no}}</div>
              <div class="refund_item">申请退款时间：{{item.create_time}}</div>
              <div class="refund_item">申请退款金额：¥{{item.refund | numberFormat }}</div>
              <div class="refund_item" v-if="item.executing_state === 1">
                <div>已退款金额：¥{{item.refund | numberFormat }}</div>
                <div>审核通过时间：{{item.success_time}}</div>
                <div>退款完成时间：{{item.success_time}}</div>
              </div>
              <div class="refund_item" v-if="item.executing_state === 2">
                <div>审核驳回时间：{{item.refuse_time}}</div>
                <div>驳回理由：{{item.refuse_reason}}</div>
              </div>
<!--              <div class="refund_item" v-if="item.executing_state === 0">-->
<!--                <el-form size="small" ref="ruleForm" :rules="rules" :model="operationForm" label-width="100px">-->
<!--                  <el-form-item label="退款审核:" required prop="type">-->
<!--                    <el-radio-group v-model="operationForm.type">-->
<!--                      <el-radio :label="1">审核通过</el-radio>-->
<!--                      <el-radio :label="2">审核驳回</el-radio>-->
<!--                    </el-radio-group>-->
<!--                  </el-form-item>-->
<!--                  <el-form-item label="驳回原因:" required prop="refuseReason" v-if="operationForm.type===2">-->
<!--                    <el-input-->
<!--                        maxlength="100"-->
<!--                        show-word-limit-->
<!--                        type="textarea"-->
<!--                        :autosize="{ minRows: 3, maxRows: 10}"-->
<!--                        placeholder="请输入驳回原因"-->
<!--                        v-model="operationForm.refuseReason">-->
<!--                    </el-input>-->
<!--                  </el-form-item>-->
<!--                </el-form>-->
<!--              </div>-->

<!--              <div style="text-align: right">-->
<!--                <el-button v-if="index === (refundList.length - 1)" size="small" @click="dialogVisible = false">取 消</el-button>-->
<!--                <el-button v-if="item.executing_state === 0" size="small" type="primary" @click="confirm(item)">确 定</el-button>-->
<!--              </div>-->

            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { wechatPayResList } from "../../api/sklmUser";
import {wechatPayRefundInfo} from "../../api/wechatPayRefund";

export default {
  name: "memberOrder",
  data() {
    return {
      ruleForm: null,
      wechatPay:{
        total: 0.00
      },
      refundList:[],
      rules: {},
      operationForm:{
        type: 1,
        outRefundNo: '',
        refuseReason: ''
      },
      dialogVisible:false,
      agentOptions:[
        {
          value: '',
          label: '全部'
        }, {
          value: '1',
          label: '有经纪人'
        }, {
          value: '2',
          label: '无经纪人'
        }
      ],
      refundOptions:[
        {
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '支付成功'
        }, {
          value: '2',
          label: '申请退款中'
        }, {
          value: '3',
          label: '部分退款完成'
        }, {
          value: '4',
          label: '全部退款完成'
        }
      ],
      tableData:[],
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        refundStatus: '0',
        agent_nick_name: '',
        agent_phone_number: '',
        agent: '',
        transaction_id: "",
        out_trade_no: "",
        nick_name: "",
        phone_number: "",
        page:1,
        limit:10,
        sidx: 'create_time',
        order: 'desc'
      },
      total:0,
      listLoading:false
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
  },
  methods:{
    detail(row){
      console.log(JSON.stringify(row))
      wechatPayRefundInfo({id : row.out_trade_no}).then((res) => {
        this.wechatPay = res.data.wechatPay
        this.refundList = res.data.refundList
        this.operationForm.outRefundNo = row.id
        this.dialogVisible = true
      });
    },
    search(){
      this.queryParams.page = 1
      this.initData()
    },
    selectTrigger(){
      this.queryParams.page = 1
      this.initData()
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 200) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true;
      wechatPayResList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
