import ElectricityMcnLayout from "../views/layout/ElectricityMcnLayout";
import electricity_situation from "../views/electricityMcn/electricity_situation";
import TrillMcn from "../views/user/TrillMcn";
import mcn_tiktok_list from "../views/user/mcn_tiktok_list";
import SelectionAlliance from "../views/selection_alliance";
import IpGoods from "../views/trill_goods/IpGoods";
import SelectionAllianceIp from "../views/selection_alliance_ip";
import ClassifyLimit from "../views/user/merchants_components/MerchantClassifyLimit";
import ip_service from "../views/electricityMcn/ip_service";
import page_select from "../views/electricityMcn/page_select";
import ele_fun_config from "../views/electricityMcn/ele_fun_config";

import Usercommission from '@/views/electricityMcn/user_commission.vue'
import customLabel from '@/views/user/custom_label.vue'
import mcnIdentification  from '@/views/user/mcn_identification.vue'
const template = [
    {
        path: "/electricity_situation",
        name: '概况',
        iconClass: 'el-icon-s-platform',
        iconImg: 'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: ElectricityMcnLayout,
        isMenu: true,
        isSingleMenu: true,
        children: [
            {
                path: "/electricity_situation",
                name: '概况',
                component: electricity_situation,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            }
        ]
    },
    {
        path: "/",
        name: 'mcn管理',
        iconClass: 'el-icon-s-platform',
        iconImg: 'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: ElectricityMcnLayout,
        isMenu: true,
        isSingleMenu: false,
        children: [
            {
                path: "/mcn_tiktok_list",
                name: 'MCN绑定/解绑',
                component: mcn_tiktok_list,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/trill_mcn",
                name: 'MCN绑定/解绑',
                component: TrillMcn,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/custom_label",
                name: '用户标签',
                component: customLabel,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/selection_alliance",
                name: '精选联盟分佣管理',
                component: SelectionAlliance,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/mcn_identification",
                name: 'MCN标识',
                component: mcnIdentification,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
        ]
    },
    {
        path: "/",
        name: 'IP项目',
        iconClass: 'el-icon-s-platform',
        iconImg: 'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: ElectricityMcnLayout,
        isMenu: true,
        isSingleMenu: false,
        children: [
            {
                path: "/ip_goods",
                name: 'IP商品',
                component: IpGoods,
                meta: {
                    keepAlive: true,
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/selection_alliance_ip",
                name: '精选联盟分佣管理(IP切片)',
                component: SelectionAllianceIp,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/user_commission",
                name: '用户提成设置',
                component: Usercommission,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/classify_limit",
                name: 'IP名额',
                component: ClassifyLimit,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/ip_service",
                name: 'IP授权客服',
                component: ip_service,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/page_select",
                name: '页面装修',
                component: page_select,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            },
            {
                path: "/ele_fun_config",
                name: '功能配置',
                component: ele_fun_config,
                meta: {
                    headInfo: {
                        showHeader: true,
                        showMenu: true,
                        showUserInfo: true,
                        menu_item_active: 'electricityMcn'
                    }
                }
            }
        ]
    },



]

export default template;
