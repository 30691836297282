<template>
  <div style="padding: 10px">

    <div style="background: white;padding: 10px">

      <el-tabs v-model="queryType" @tab-click="changeType">
        <el-tab-pane
            v-for="(item) in templateStateList"
            :key="item.value"
            :label="item.label"
            :name="item.value + ''"
        >
        </el-tab-pane>
      </el-tabs>

      <div>
        <el-input
            size="small"
            placeholder="模板编号"
            v-model="queryParams.tem_id"
            clearable
            style="width: 120px;margin-left: 10px"
        >
        </el-input>
        <el-input
            size="small"
            placeholder="模板名称"
            v-model="queryParams.tem_name"
            clearable
            style="width: 120px;margin-left: 10px"
        >
        </el-input>


        <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="queryParams.storageTimeStart"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="入库时间">
        </el-date-picker>
        <i  style="margin-left: 5px" class="el-icon-minus"></i>
        <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="queryParams.storageTimeEnd"
            type="date"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="入库时间">
        </el-date-picker>

        <el-select style="margin-left: 10px" @change="buildLimitChange" v-model="queryParams.buildLimit" placeholder="请选择商品来源" size="small">
          <el-option
              v-for="item in buildLimitOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>

        <el-button
            size="small"
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-search"
            @click="searchData()"
        >搜索</el-button>
      </div>


      <el-table
          :data="tableData"
          :loading="listLoading"
          border
          style="width: 100%;margin-top: 10px"
          :max-height="autoHeight"
      >
        <el-table-column align="center" prop="id" label="编号" >
        </el-table-column>

        <el-table-column align="center" prop="name" label="模板预览">
          <template slot-scope="scope">
            <div style="position: relative;text-align: center" @click="preview(scope.row)">
              <el-image
                  style="width: 100px;"
                  :src="scope.row.mediaProducing.media_id"
                  >
                <div slot="placeholder" class="image-slot">
                  <el-image
                      style="width: 100px;"
                      src="../../assets/template/defaut.jpg"
                  />
                </div>
              </el-image>
            </div>

          </template>
        </el-table-column>

        <el-table-column align="center" prop="name" label="标题">
        </el-table-column>

        <el-table-column align="center" prop="storageTime" label="入库时间">
        </el-table-column>

        <el-table-column align="center" prop="buildNum" label="已生成视频总数">
        </el-table-column>

        <el-table-column align="center" prop="dismiss_number" label="模板生成视频数上限">
          <template slot-scope="scope">
            <div v-if="scope.row.dismiss_number==0">不限制</div>
            <div v-else>{{scope.row.dismiss_number}}</div>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="2" label="挂载状态">
          <template slot-scope="scope">
            <div v-if="scope.row.templateProductList.length !=0 ">
              <div>
                <el-image
                    v-for='item in scope.row.templateProductList'
                    :key='item.img'
                    style="width: 40px;"
                    :src="item.img"
                    lazy>
                </el-image>
              </div>
              <div>
                已挂载 {{scope.row.templateProductList.length}} 款商品
              </div>
            </div>
            <div v-else>未挂载商品</div>
          </template>
        </el-table-column>

        <el-table-column align="center" label="操作" >
          <template slot-scope="scope">
            <el-button
                v-if="queryType != 2"
                @click.native.prevent="doMountGoods(scope.row)"
                type="text"
                size="small">
              <span v-if="queryType == 0">挂载商品</span>
              <span v-if="queryType == 1">编辑挂载</span>
            </el-button>
            <el-button
                v-if="queryType == 1"
                @click.native.prevent="temDelGoodAll(scope.row)"
                type="text"
                size="small">
              取消挂载
            </el-button>
            <el-button
                v-if="queryType != 2"
                @click.native.prevent="moveWarehouse(scope.row)"
                type="text"
                size="small">
              移入仓库
            </el-button>
            <el-button
                v-if="queryType == 2"
                @click.native.prevent="shiftOutWarehouse(scope.row)"
                type="text"
                size="small">
              移出仓库
            </el-button>
          </template>
        </el-table-column>


      </el-table>

      <div>
        <el-row style="margin-top: 24px">
          <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page_no"
                    :page-size="page_size"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
          </el-col>
        </el-row>
      </div>

    </div>

    <el-dialog title="模板预览" :visible.sync="previewVisible"
               :before-close="batchViewClose" width="30%" style="text-align: center">
      <video
          style="width: 90%"
          :src="previewRow.media_url"
          controls="controls"
      ></video>
    </el-dialog>


    <el-dialog title="挂载商品" :visible.sync="mountGoodsVisible"
               :before-close="mountGoodsClose"
               :close="mountGoodsClose">

    <div>
      <el-tabs v-model="queryGoodsParams.productType" @tab-click="productTypeChange">
        <el-tab-pane label="抖音商品" name="1"></el-tab-pane>
<!--        <el-tab-pane label="快手商品" name="2"></el-tab-pane>-->
      </el-tabs>
    </div>

      <div style="text-align:left;">
        <el-input
            size="small"
            placeholder="商品名称"
            v-model="queryGoodsParams.productName"
            clearable
            style="width: 160px;"
        >
        </el-input>
        <el-button
            size="small"
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-search"
            @click="searchInitGoods"
        >搜索</el-button>
      </div>

      <el-table
          style="margin-top: 10px"
          :data="goodsTableData"
          :loading="goodsListLoading"
          border
          row-key="id"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          @select="selectOne"
          @select-all="selectAll"
      >
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column align="center" prop="name" label="商品来源" >
          <template slot-scope="scope">
            <span v-if="scope.row.merchants_id === -1">运营中心</span>
            <span v-else>自建</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="商品主图" >
          <template slot-scope="scope">
            <div style="display:flex;justify-content: center; text-align: center" v-if="scope.row.commodity_module === 1">
              <el-image
                  style="width: 60px; height: 60px"
                  :src="scope.row.img"
                  fit="cover">
              </el-image>
            </div>
            <div style="display:flex;justify-content: center; text-align: center" v-else>
              <el-image
                  style="width: 60px; height: 60px"
                  :src="scope.row.product_img"
                  fit="cover">
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="商品名称" >
          <template slot-scope="scope">
            <div style="display:flex;" v-if="scope.row.commodity_module === 1">
              {{scope.row.name}}
            </div>
            <div style="display:flex;" v-else>
              {{scope.row.product_name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="到手价" >
          <template slot-scope="scope">
              <div v-if="scope.row.commodity_module === 1">
                {{ formatNumber(scope.row.discount_price) }}
              </div>
              <div v-else>
                -
              </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="库存" >
          <template slot-scope="scope">
            <div v-if="scope.row.commodity_module === 1">
              {{scope.row.total_stock_num}}
            </div>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="佣金率/公开佣金率" >
          <template slot-scope="scope">
            <div v-if="scope.row.commodity_module === 1">
              <div>{{scope.row.commission_rate}} %</div>
              <div>{{scope.row.open_commission_rate}} %</div>
            </div>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-row style="margin-top: 24px">
        <el-col :span="18" style="text-align: left">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="goodsHandleCurrentChange"
                    :current-page="queryGoodsParams.page"
                    :page-size="queryGoodsParams.limit"
                    :total="goodsTotal"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
        </el-col>
        <el-col :span="6" style="text-align: right;display: flex;justify-content: right">
          <el-button @click="mountGoodsClose">关闭窗口</el-button>
<!--          <el-button type="primary" @click="mountGoods">确定</el-button>-->
        </el-col>
      </el-row>
    </el-dialog>

  </div>
</template>

<script>

import { templateState, templateList ,moveWarehouse ,shiftOutWarehouse } from "../../api/template.js"
import $ from 'jquery'
import { ipGoodsAllList } from "../../api/trill_goods";
import {goodsByTemplate, templateAddproduct ,temAddGood ,temDelGood ,temDelGoodAll} from "../../api/templateProduct";

export default {
  name: "templateList",
  data() {
    return {
      buildLimitOptions:[
        {
          value: '0',
          label: '全部'
        },
        {
          value: "1",
          label: '已达生成数上限'
        }, {
          value: "2",
          label: '未达生成数上限'
        }
      ],
      templateStateList:[],
      queryType: "0",
      mountGoodsVisible: false,
      autoHeight:'',
      autoWidth:'',
      previewRow:{},
      previewVisible:false,
      queryParams:{
        buildLimit: "0",
        storageTimeStart:'',
        storageTimeEnd:'',
        tem_id:"",
        tem_name: "",
        sidx:'mt.create_time',
        order:'desc'
      },
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 5,
      total: 100,
      tabs: [],
      queryGoodsParams:{
        productType: "1",
        productName:'',
        productState:1,
        page:1,
        limit:5,
        sidx:'mtp.custom_sort',
        order:"desc"
      },
      selectRow:{},
      selectionIds:[],
      goodsListLoading:false,
      goodsTableData:[],
      goodsTotal:0
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData();
  },
  methods:{
    buildLimitChange(){
      this.page_no = 1;
      this.initData();
    },
    temDelGoodAll(row){
      let query = {
        templateId: row.id
      }
      temDelGoodAll(query).then(res=> {
        if(res.code==0) {
          this.$message.success({message: '取消挂载成功'});
          this.initData()
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    moveWarehouse(row){
      let body = {
        "template_id": row.id
      }
      moveWarehouse(body).then(res=> {
        if(res.code==0) {
          this.$message.success({message: '操作成功'});
          this.initData()
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    shiftOutWarehouse(row){
      let body = {
        "template_id": row.id
      }
      shiftOutWarehouse(body).then(res=> {
        if(res.code==0) {
          this.$message.success({message: '操作成功'});
          this.initData()
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    initTemplateState(){
      templateState({}).then((response) => {
        this.templateStateList = response.data;
      });
    },
    changeType(){
      this.page_no = 1;
      this.initData();
    },
    searchData(){
      this.page_no = 1;
      this.initData();
    },
    searchInitGoods(){
      this.queryGoodsParams.page = 1
      this.initGoods()
    },
    productTypeChange(tab, event){
      console.log(tab, event);
      console.log(this.queryGoodsParams.productType)
      this.queryGoodsParams.page = 1
      this.initGoods()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("当前选中:",JSON.stringify(val))
    },
    remove(selectionIds,val) {
      let index = selectionIds.indexOf(val);
      if (index > -1) {
        selectionIds.splice(index, 1);
      }
      console.log("remove:",val)
      console.log("selectionIds:",this.selectionIds)
    },
    selectOne(selection, row) {
      let check = null;
      selection.map((item) => {
        if(item.id === row.id){
          check = true;
        }
      })
      if(check){
        console.log("选中:",row.id)
        this.selectionIds.push(row.id)
        this.doTemAddGood(row.id)
      }else{
        console.log("取消选中:",row.id)
        this.remove(this.selectionIds,row.id)
        this.doTemDelGood(row.id)
      }
    },
    selectAll(selection) {
      let _this = this;
      console.log(selection)
      if (selection.length == 0){
        console.log("取消选择")
        _this.goodsTableData.map((item) => {
          _this.remove(_this.selectionIds,item.id)
          this.doTemDelGood(item.id)
        })
      }else {
        console.log("全部选择")
        _this.goodsTableData.forEach((item) => {
          let index = _this.selectionIds.indexOf(item.id);
          if (index > -1) {
            console.log("已经含有啦")
          }else{
            _this.selectionIds.push(item.id)
            this.doTemAddGood(item.id)
          }
        });
      }
    },
    selectCheck(){
      let _this = this;
      _this.$nextTick(()=>{
        console.log("_this.$refs",_this.$refs)
        _this.goodsTableData.map((item) => {
          _this.selectionIds.find(value => {
            if(value == item.id){
              _this.$refs.multipleTable.toggleRowSelection(item, true)
            }
          })
        });
      })
    },
    formatNumber(val) {
      return (val/100).toFixed(2)
    },
    goodsHandleCurrentChange(val){
      console.log(`当前页: ${val}`);
      this.queryGoodsParams.page = val;
      this.initGoods();
    },
    initGoods(){
      this.goodsListLoading = true
      ipGoodsAllList(this.queryGoodsParams).then((response) => {
        this.goodsTableData = response.data.list;
        this.goodsTotal = response.data.total_count;
        this.goodsListLoading = false
        this.checkSelectionIds()
      });
    },
    doTemAddGood(merchantsProductId){
      let body = {
        templateId:this.selectRow.id,
        merchantsProductId:merchantsProductId
      }
      temAddGood(body).then((res) => {
        this.$message.success({
          duration: 1000,
          message: '新增单品成功'
        });
      });
    },
    doTemDelGood(merchantsProductId){
      let body = {
        templateId:this.selectRow.id,
        merchantsProductId:merchantsProductId
      }
      temDelGood(body).then((res) => {
        this.$message.success({
          duration: 1000,
          message: '取消单品成功'
        });
      });
    },
    mountGoodsClose(){
      this.mountGoodsVisible = false;
      this.initData();
    },
    mountGoods(){
      console.log("当前选中selectionIds:",JSON.stringify(this.selectionIds))
      let body = {
        templateId:this.selectRow.id,
        productList:this.selectionIds
      }
      templateAddproduct(body).then((res) => {
        this.$message.success({
          duration: 1000,
          message: '商品挂载成功'
        });
        this.mountGoodsVisible = false;
        this.initData();
      });

    },
    doMountGoods(row){
      this.queryGoodsParams.productName = ""
      this.queryGoodsParams.page = 1
      this.selectRow = row
      this.initGoods();
      this.checkSelectionIds()
      this.mountGoodsVisible = true
    },
    checkSelectionIds(){
      goodsByTemplate({
        page: 1,
        limit: 999,
        templateId: this.selectRow.id
      },null).then((response) => {
        this.selectionIds = response.data.records.map(x=>{
          return x.merchants_product_id
        })
        this.selectCheck()
      });
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    batchViewClose(){
      this.pauseAll()
      this.previewVisible = false;
    },
    playAll(){
      $('video').trigger('play'); //打开所有的视频播放
    },
    pauseAll(){
      $('video').trigger('pause'); //关闭所有的视频播放
    },
    preview(row){
      this.previewVisible = true
      this.previewRow = row.mediaProducing
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams
          queryParams.templateState = this.templateState,
          queryParams.page = this.page_no,
          queryParams.limit = this.page_size,
          queryParams.queryType = this.queryType
      templateList(queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
        this.initTemplateState()
      });
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m ;
    }
  }
}
</script>

<style scoped>

</style>
