import service from '../request'

export function updateAccount(query,data) {
    return service({
        url: '/api/merchants/merchantsUser/updateAccount',
        method: 'post',
        params: query,
        data: data
    })
}

export function authCheck(query) {
    return service({
        url: '/api/merchants/merchantsUser/authCheck',
        method: 'get',
        params: query
    })
}

export function phoneCheck(query) {
    return service({
        url: '/api/merchants/merchantsUser/phoneCheck',
        method: 'get',
        params: query
    })
}

export function genCardRechargeCheck(query) {
    return service({
        url: '/api/merchants/merchantsUser/genCardRecharge/check',
        method: 'get',
        params: query
    })
}

export function genCardRecharge(data) {
    return service({
        url: '/api/merchants/merchantsUser/genCardRecharge',
        method: 'post',
        data: data
    })
}


export function genPackRecharge(data) {
    return service({
        url: '/api/merchants/merchantsUser/genPackRecharge',
        method: 'post',
        data: data
    })
}

export function genCardRechargeRecord(query) {
    return service({
        url: '/api/merchants/merchantsUser/genCardRecharge/record',
        method: 'get',
        params: query
    })
}

export function merchantsLogin(query,data) {
    return service({
        url: '/api/merchants/merchantsUser/login',
        method: 'post',
        params: query,
        data: data
    })
}

export function membersLogin(query,data) {
    return service({
        url: '/api/merchants/members/login',
        method: 'post',
        params: query,
        data: data
    })
}

export function merchantsSmsLogin(data) {
    return service({
        url: '/api/merchants/merchantsUser/smsLogin',
        method: 'post',
        data: data
    })
}

export function membersSmsLogin(data) {
    return service({
        url: '/api/merchants/members/smsLogin',
        method: 'post',
        data: data
    })
}

export function optionLogin(data) {
    return service({
        url: '/api/merchants/members/optionLogin',
        method: 'post',
        data: data
    })
}

export function optionMenu(query) {
    return service({
        url: '/api/merchants/members/info',
        method: 'get',
        params: query
    })
}

export function registerCheck(data) {
    return service({
        url: '/api/merchants/merchantsUser/registerCheck',
        method: 'post',
        data: data
    })
}

export function register(query,data) {
    return service({
        url: '/api/merchants/merchantsUser/register',
        method: 'post',
        params: query,
        data: data
    })
}


export function getImage(query) {
    return service({
        url: '/api/merchants/merchantsUser/getImage',
        method: 'get',
        params: query
    })
}

export function merchantsInfo(query) {
    return service({
        url: '/api/merchants/merchantsUser/info',
        method: 'get',
        params: query
    })
}

export function memberPull(query) {
    return service({
        url: '/api/merchants/memberPull/info',
        method: 'get',
        params: query
    })
}

export function cloudInfo(query) {
    return service({
        url: '/api/merchants/merchantsUser/cloudInfo',
        method: 'get',
        params: query
    })
}

export function creatorUser(query) {
    return service({
        url: '/api/creator/creatorUser/info',
        method: 'get',
        params: query
    })
}

export function merchantsUpdate(query,data) {
    return service({
        url: '/api/merchants/merchantsUser/update',
        method: 'post',
        params: query,
        data: data
    })
}

export function merchantsCardResd(query) {
    return service({
        url: '/api/merchants/merchantsUser/merchantsCardResd',
        method: 'get',
        params: query
    })
}

export function genPackageList(query) {
    return service({
        url: '/api/merchants/genPackage/list',
        method: 'get',
        params: query
    })
}

export function enterpriseCertInfo(query) {
    return service({
        url: '/api/merchants/enterpriseCert/info',
        method: 'get',
        params: query
    })
}

export function enterpriseCertUpdate(data) {
    return service({
        url: '/api/merchants/enterpriseCert/update',
        method: 'post',
        data: data
    })
}

