import { render, staticRenderFns } from "./frustrated.vue?vue&type=template&id=a812d938&scoped=true"
import script from "./frustrated.vue?vue&type=script&lang=js"
export * from "./frustrated.vue?vue&type=script&lang=js"
import style0 from "./frustrated.vue?vue&type=style&index=0&id=a812d938&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a812d938",
  null
  
)

export default component.exports