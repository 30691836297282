import service from '../request'

export function grassTaskList(query) {
    return service({
        url: '/api/merchants/grassTask/list',
        method: 'get',
        params: query
    })
}

export function grassTaskOne(query) {
    return service({
        url: '/api/merchants/grassTask/baseInfo',
        method: 'get',
        params: query
    })
}

export function grassTaskEdit(data) {
    return service({
        url: '/api/merchants/grassTask/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function grassTaskFinish(data) {
    return service({
        url: '/api/merchants/grassTask/finish',
        method: 'post',
        data: data
    })
}


export function grassTaskTemList(query) {
    return service({
        url: '/api/merchants/grassTaskTem/list',
        method: 'get',
        params: query
    })
}

export function grassTaskTemAdd(data) {
    return service({
        url: '/api/merchants/grassTaskTem/add',
        method: 'post',
        data: data
    })
}

export function grassTaskTemDel(query) {
    return service({
        url: '/api/merchants/grassTaskTem/del',
        method: 'get',
        params: query
    })
}


export function taskState(query) {
    return service({
        url: '/api/merchants/grassTask/state',
        method: 'get',
        params: query
    })
}

export function grassTaskInfo(query) {
    return service({
        url: '/api/merchants/grassTask/info',
        method: 'get',
        params: query
    })
}

export function grassTaskUserList(query) {
    return service({
        url: '/api/merchants/grassTaskUser/list',
        method: 'get',
        params: query
    })
}

export function grassTaskUserPass(data) {
    return service({
        url: '/api/merchants/grassTaskUser/pass',
        method: 'post',
        data: data
    })
}

export function batchPass(data) {
    return service({
        url: '/api/merchants/grassTaskUser/batchPass',
        method: 'post',
        data: data
    })
}

export function grassTaskUserRejected(data) {
    return service({
        url: '/api/merchants/grassTaskUser/rejected',
        method: 'post',
        data: data
    })
}

export function batchRejected(data) {
    return service({
        url: '/api/merchants/grassTaskUser/batchRejected',
        method: 'post',
        data: data
    })
}
