import service from "../request";

export function buildTemList(query) {
    return service({
        url: '/api/creator/mediaProducing/buildTemList',
        method: 'get',
        params: query
    })
}

export function createMode(query) {
    return service({
        url: '/api/creator/mediaProducing/createMode',
        method: 'get',
        params: query
    })
}

export function videoList(query) {
    return service({
        url: '/api/creator/mediaProducing/videoList',
        method: 'get',
        params: query
    })
}
