import Layout from "../views/layout/Layout";
import BannerList from "../views/marketing/bannerList";
import PlateType from "../views/marketing/plate_type";
import AddBanner from "../views/marketing/addBanner";
import EditBanner from "../views/marketing/editBanner";
import grassTask from "../views/grass_task";
import addGrassTask from "../views/grass_task/add";
import grassTaskInfo from "../views/grass_task/info";
import taskDetails from "../views/grass_task/taskDetails";
import SelectionAlliance from "../views/selection_alliance/index";
import TrillRefundOrdersList from "../views/selection_alliance/trillRefund_orders_list";
import TrillSettleOrdersList from "../views/selection_alliance/trillSettle_orders_list";
import SelectionAllianceIp from "../views/selection_alliance_ip/index";
import TrillRefundOrdersListIp from "../views/selection_alliance_ip/trillRefund_orders_list";
import TrillSettleOrdersListIp from "../views/selection_alliance_ip/trillSettle_orders_list";
const router = [
    {
        path:"/marketing",
        name:'营销配置',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/marker.png',
        component: Layout,
        isMenu:true,
        children:[
            {
                path:"/addBanner",
                name:'新增banner',
                isHidden : true,
                component: AddBanner
            },
            {
                path:"/editBanner",
                name:'修改banner',
                isHidden : true,
                component: EditBanner
            },
            {
                path:"/grassTaskInfo",
                name:'种草任务查看',
                isHidden : true,
                component: grassTaskInfo
            },
            {
                path:"/trillSettle_orders_list",
                name:'结算订单查看',
                isHidden : true,
                component: TrillSettleOrdersList
            },
            {
                path:"/trillRefund_orders_list",
                name:'退款订单列表查看',
                isHidden : true,
                component: TrillRefundOrdersList
            },
            {
                path:"/trillSettle_orders_list",
                name:'结算订单查看',
                isHidden : true,
                component: TrillSettleOrdersListIp
            },
            {
                path:"/trillRefund_orders_list",
                name:'退款订单列表查看',
                isHidden : true,
                component: TrillRefundOrdersListIp
            }
        ]
    }
]

export default router;
