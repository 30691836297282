import service from '../request'

export function getPayImg(data) {
    return service({
        url: '/api/merchants/masterMatrix/getPayImg',
        method: 'post',
        data: data
    })
}

export function check(query) {
    return service({
        url: '/api/merchants/masterMatrix/check',
        method: 'get',
        params: query
    })
}

export function record(query) {
    return service({
        url: '/api/merchants/masterMatrix/record',
        method: 'get',
        params: query
    })
}

