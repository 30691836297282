import service from "../request";

// 消息列表
export function messageList(query) {
    return service({
        url: '/api/merchants/messagePush/messageList',
        method: 'get',
        params: query
    })
}


// 消息推送详情
export function messageDetail(query) {
    return service({
        url: '/api/merchants/messagePush/messageDetail',
        method: 'get',
        params: query
    })
}

// 保存或更新消息推送
export function  UpdateMessageApi(data) {
    return service({
        url: '/api/merchants/messagePush/saveOrUpdateMessage',
        method: 'post',
        data: data
    })
}

// 归属IP列表
export function marketingIpApi(query) {
    return service({
        url: '/api/merchants/messagePush/marketingIp',
        method: 'get',
        params: query
    })
}

// 推送
export function  pushMessageApi(data) {
    return service({
        url: '/api/merchants/messagePush/pushMessage',
        method: 'post',
        data: data
    })
}

//删除
export function  deleteMessageApi(data) {
    return service({
        url: '/api/merchants/messagePush/deletePush',
        method: 'post',
        data: data
    })
}


// 取消推送
export function  cancelPushApi(data) {
    return service({
        url: '/api/merchants/messagePush/cancelPush',
        method: 'post',
        data: data
    })
}

