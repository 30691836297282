import service from "../../request";

export function courseClassState(query) {
    return service({
        url: '/api/merchants/courseClass/state',
        method: 'get',
        params: query
    })
}

export function courseClassList(query) {
    return service({
        url: '/api/merchants/courseClass/list',
        method: 'get',
        params: query
    })
}

export function courseClassInfo(query) {
    return service({
        url: '/api/merchants/courseClass/info',
        method: 'get',
        params: query
    })
}

export function saveOrUpdate(data) {
    return service({
        url: '/api/merchants/courseClass/saveOrUpdate',
        method: 'post',
        data: data
    })
}
