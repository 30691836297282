<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: left">
        <el-button
            class="filter-item"
            type="text"
            icon="el-icon-back"
            @click="previousPage"
        >返回上级</el-button>
      </el-col>
    </el-row>


    <el-table
        size="small"
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="视频"
          align="center">
        <template slot-scope="scope">
            <img
                @click="preview(scope.row)"
                style="width: 60px;"
                :src="scope.row.previewImage"
               />
        </template>
      </el-table-column>
      <el-table-column
          prop="job_id"
          label="任务ID"
          align="center">
      </el-table-column>
      <el-table-column
            prop="create_mode"
            label="合成端口"
            align="center">
        <template slot-scope="scope">
            <span v-if="scope.row.create_mode == 1">AI云剪辑</span>
            <span v-if="scope.row.create_mode == 2">云快剪</span>
            <span v-if="scope.row.create_mode == 3">达人矩阵-带货</span>
            <span v-if="scope.row.create_mode == 4">达人矩阵-种草</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="clips_param"
          label="合成类型"
          align="center">
        <template slot-scope="scope">
          <span style="color: rgba(255, 141, 26, 1)" v-if="scope.row.clips_param" >生成视频</span>
          <span style="color: rgba(0, 119, 255, 1)" v-else>智能截取</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="file_size"
          label="文件大小"
          align="center">
        <template slot-scope="scope">
          {{scope.row.file_size | formatFileSize}}
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="合成时间"
          align="center">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="用户信息"
          align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.create_mode == 1">AI云剪辑</div>
          <div v-if="scope.row.create_mode == 2">
            <div style="display: flex;align-items: center;justify-content: center">
              <img :src="scope.row.wxUserInfo.avatar_url" style="width: 50px;height: 50px;border-radius: 50%" />
              <div style="display: flex;flex-direction: column;justify-content: space-around;text-align: left;margin-left: 10px">
                <div>{{scope.row.wxUserInfo.nick_name}}</div>
                <div>{{scope.row.wxUserInfo.phone_number}}</div>
              </div>
            </div>
          </div>
          <div v-if="scope.row.create_mode == 3">
            <div style="display: flex;align-items: center;justify-content: center">
              <img :src="scope.row.wxUserInfo.avatar_url" style="width: 50px;height: 50px;border-radius: 50%" />
              <div style="display: flex;flex-direction: column;justify-content: space-around;text-align: left;margin-left: 10px">
                <div>{{scope.row.wxUserInfo.nick_name}}</div>
                <div>{{scope.row.wxUserInfo.phone_number}}</div>
              </div>
            </div>
          </div>
          <div v-if="scope.row.create_mode == 4">
            <div style="display: flex;align-items: center;justify-content: center">
              <img :src="scope.row.wxUserInfo.avatar_url" style="width: 50px;height: 50px;border-radius: 50%" />
              <div style="display: flex;flex-direction: column;justify-content: space-around;text-align: left;margin-left: 10px">
                <div>{{scope.row.wxUserInfo.nick_name}}</div>
                <div>{{scope.row.wxUserInfo.phone_number}}</div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

    <el-dialog title="模板预览" :visible.sync="previewVisible"
               :before-close="batchViewClose" width="30%" style="text-align: center">
      <video
          style="width: 90%"
          :src="previewRow.media_id"
          controls="controls"
      ></video>
    </el-dialog>

  </div>
</template>

<script>
import { createMode ,videoList } from "../../api/mediaProducing";
import { mapState } from 'vuex'
import $ from 'jquery'

export default {
  name: "tem_video_list",
  data(){
    return{
      previewVisible:false,
      previewRow:{},
      createModeOption:[],
      multipleSelection:[],
      queryParams:{
        page: 1,
        limit: 5,
        sidx: 'create_time',
        order: 'desc',
        template_id: ''
      },
      total:0,
      tableData: []
    }
  },
  filters:{
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024*1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024*1024*1024)) {
        temp = fileSize / (1024*1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else {
        temp = fileSize / (1024*1024*1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      }
    }
  },
  computed: {
    ...mapState(['GLOBAL_TEM_ID'])
  },
  mounted(){
    let templateId = this.$store.state.GLOBAL_TEM_ID
    this.queryParams.template_id = templateId
    this.initCreateMode()
    this.initData()
  },
  methods:{
    playAll(){
      $('video').trigger('play'); //打开所有的视频播放
    },
    pauseAll(){
      $('video').trigger('pause'); //关闭所有的视频播放
    },
    batchViewClose(){
      this.pauseAll();
      this.previewVisible = false;
    },
    preview(row){
      this.previewVisible = true
      this.previewRow = row
    },
    previousPage(){
      this.$router.go(-1);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initCreateMode(){
      createMode().then((response) => {
        this.createModeOption = response.data.list;
      });
    },
    initData(){
      this.listLoading = true;
      videoList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
