<template>
  <div class="app-head" v-show="HEAD_INFO.showHeader!=false">
    <div class="head-left">
<!--      <img src="../assets/logo.jpg" class="app-img" />-->
<!--      <span class="app-title">视客联盟</span>-->
        <img src="../assets/head_img.jpg" class="head_img" />
        <span class="app_head_name">商户后台</span>
    </div>
    <div class="head_right">
      <div class="menu_head">
        <span v-if="MATRIX_MENU" class="menu_item" :class= "{'menu_item_active': HEAD_INFO.menu_item_active == null }" @click="productList">达人矩阵</span>
        <span v-if="CLOUD_CLIP_MENU" class="menu_item" :class= "{'menu_item_active': HEAD_INFO.menu_item_active == 'clip' }" @click="clip">Ai云剪辑</span>
        <span v-if="VIDEO_JOB_MENU" class="menu_item"  :class= "{'menu_item_active': HEAD_INFO.menu_item_active == 'video_job' }" @click="video_job">视频作业</span>
        <span v-if="GRASS_TASK_MENU" class="menu_item"  :class= "{'menu_item_active': HEAD_INFO.menu_item_active == 'grassTask' }" @click="grassTask">种草任务</span>
        <span v-if="ELECTRICTY_MCN_MENU" class="menu_item"  :class= "{'menu_item_active': HEAD_INFO.menu_item_active == 'electricityMcn' }" @click="electricityMcn">电商MCN</span>
<!--        <span v-if="COURSE_MENU" class="menu_item"  :class= "{'menu_item_active': HEAD_INFO.menu_item_active == 'course' }" @click="course">课程</span>-->
        <span v-if="ACCOUNT_CENTER_MENU" class="menu_item"  :class= "{'menu_item_active': HEAD_INFO.menu_item_active == 'accountCenter' }" @click="accountCenter">账户中心</span>
        <span  class="menu_item"  :class= "{'menu_item_active': HEAD_INFO.menu_item_active == 'rulesCenter' }" @click="rulesCenter()"><i class="el-icon-info"></i></span>

        <el-popover
            placement="left"
            width="200"
            trigger="hover">
          <div style="text-align: center">
            <img src="../assets/home/wechat_service.jpg" style="width: 134px;height: 134px" />
            <div style="font-size: 14px;font-weight: 400;color: rgba(0, 0, 0, 1);">打开「微信」扫一扫</div>
            <div style="font-size: 12px;font-weight: 400;color: rgba(128, 128, 128, 1);margin-top: 5px">添加客服微信</div>
          </div>
          <span  class="menu_item" slot="reference"><i class="el-icon-s-custom"></i></span>
        </el-popover>
      </div>

      <el-popover
          placement="bottom-start"
          width="200"
          trigger="hover"
          @show="popoverShow"
          @hide="popoverHide"
      >
        <div>
          <div style="font-size: 12px;font-weight: 500;">
            <div>当前账号</div>
            <div v-if="memberInfo" style="margin-top: 5px;display: flex;align-items: center; justify-content: space-between">
              <span>{{ memberInfo.mobile_phone | phoneDesensitization}}</span>
              <span style="padding: 5px; border-radius: 8px; background: rgba(0, 119, 255, 1);color: rgba(255, 255, 255, 1);">{{memberInfo.role_name}}</span>
            </div>
            <div v-else style="margin-top: 5px;display: flex;align-items: center; justify-content: space-between">
              <span >{{ userInfo.mobile_phone | phoneDesensitization}}</span>
              <span style="padding: 5px; border-radius: 8px; background: rgba(0, 119, 255, 1);color: rgba(255, 255, 255, 1);">超级管理员</span>
            </div>
          </div>
          <div style="margin-top: 10px">
            <el-divider></el-divider>
          </div>
          <div class="menu_info_item_out" @click="logOut">退出登录</div>
        </div>
        <div slot="reference" class="login_info"   v-show="USER_INFO.name">
          <div style="display: flex;align-items: center">
            <img :src="USER_INFO.head_portrait" class="app-img" />
            <span style="margin-left: 10px;color: black">{{ USER_INFO.name }}</span>
            <div style="margin-left: 10px;color: black;">
              <i v-if="menu_status == false" class="el-icon-arrow-down"></i>
              <i v-else class="el-icon-arrow-up"></i>
            </div>
          </div>
        </div>
      </el-popover>


      <!-- 用户信息 -->
<!--      <div class="login_info"  v-show="USER_INFO.name" @click="menu_status = !menu_status" @mouseenter="menu_status=true" @mouseleave="menu_status = false">-->
<!--        <div style="display: flex;align-items: center">-->
<!--          <img :src="USER_INFO.head_portrait" class="app-img" />-->
<!--          <span style="margin-left: 10px;color: black">{{ USER_INFO.name }}</span>-->
<!--          <div style="margin-left: 10px;color: black;">-->
<!--            <i v-if="menu_status == false" class="el-icon-arrow-down"></i>-->
<!--            <i v-else class="el-icon-arrow-up"></i>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-if="menu_status == true" class="menu_info">-->
<!--          <div v-if="checkPermissions(1)" class="menu_info_item" @click="merchantsInfoCustom">账户中心</div>-->
<!--          <div v-if="checkPermissions(16)" class="menu_info_item" @click="cardResdCustom">视频生成卡</div>-->
<!--          <div v-if="checkPermissions(32)" class="menu_info_item" @click="order_center">订单中心</div>-->
<!--          <div class="menu_info_item_out" @click="logOut">退出登录</div>-->
<!--        </div>-->
<!--      </div>-->


    </div>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import { mapMutations } from 'vuex';
import store from "../store";
import router from "../router";
import {creatorUser, merchantsInfo} from "../api/merchantsUser";

export default {
  name: "HeadInfo",
  filters:{
    phoneDesensitization(value){
      if(value==null){
        value = ''
      }
      let char = '*'
      return value.replace(/(\d{3})\d*(\d{4})/, `$1${new Array(5).join(char)}$2`)
    },
  },
  computed: {
    ...mapState(['USER_INFO','HEAD_INFO','ACTIVE_PATH','HOME_PAGE_MENU','MATRIX_MENU','GRASS_TASK_MENU','ELECTRICTY_MCN_MENU','ACCOUNT_CENTER_MENU','CLOUD_CLIP_MENU','CLOUD_MENU','VIDEO_JOB_MENU','COURSE_MENU'])
  },
  mounted(){
    this.initData()
  },
  data(){
    return{
      menu_status : false,
      memberInfo: {
        mobile_phone: '',
        role_name: ''
      },
      userInfo: {
        mobile_phone: ''
      }
    }
  },
  methods: {
    ...mapMutations(['DEL_TOKEN' ,'UPLOAD_LIST_DEL_ALL']),
    initData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
        this.memberInfo = res.data.memberInfo;
      });
      creatorUser().then((res)=>{
        this.creatorUser = res.data
        this.invitationCode = 'https://vodmapi.paitk.com/api/creator/creatorUser/invitationCode?id=' + res.data.creatorUser.id + "&times=" + new Date().getTime()
        this.percentage = res.data.storageSpaceInfo.gFileSize/ res.data.storageSpaceInfo.storageSpace  * 100
      })
    },
    popoverShow(){
      this.menu_status = true
    },
    popoverHide(){
      this.menu_status = false
    },
    rulesCenter(){
      this.$router.push("/rules_center_user_agreement");
    },
    checkPermissions(id){
      let state = false
      if (store.state.ACCOUNT_CENTER_MENU != null){
        let children = store.state.ACCOUNT_CENTER_MENU.children
        children.forEach(function (item, index) {
          if(item.id === id){
            state = true
          }
        });
      }
      return  state;
    },
    productList(){
      let menu = store.state.MATRIX_MENU
      console.log(JSON.stringify(menu))
      let path = ''
      if(menu.children[0].has_children === false){
        path = menu.children[0].path
      }else{
        path = menu.children[0].children[0].path
      }
      this.$router.push(path);
    },
    my_cloud(){
      let menu = store.state.CLOUD_MENU
      console.log(JSON.stringify(menu))
      let path = ''
      if(menu.children[0].has_children === false){
        path = menu.children[0].path
      }else{
        path = menu.children[0].children[0].path
      }
      this.$router.push(path);
    },
    clip(){
      let menu = store.state.CLOUD_CLIP_MENU
      console.log(JSON.stringify(menu))
      let path = ''
      if(menu.children[0].has_children === false){
        path = menu.children[0].path
      }else{
        path = menu.children[0].children[0].path
      }
      this.$router.push(path);
    },
    grassTask(){
      let menu = store.state.GRASS_TASK_MENU
      console.log(JSON.stringify(menu))
      let path = ''
      if(menu.children[0].has_children === false){
        path = menu.children[0].path
      }else{
        path = menu.children[0].children[0].path
      }
      this.$router.push(path);
    },
    video_job(){
      let menu = store.state.VIDEO_JOB_MENU
      console.log(JSON.stringify(menu))
      let path = ''
      if(menu.children[0].has_children === false){
        path = menu.children[0].path
      }else{
        path = menu.children[0].children[0].path
      }
      this.$router.push(path);
    },
    course(){
      let menu = store.state.COURSE_MENU
      console.log(JSON.stringify(menu))
      let path = ''
      if(menu.children[0].has_children === false){
        path = menu.children[0].path
      }else{
        path = menu.children[0].children[0].path
      }
      this.$router.push(path);
    },
    electricityMcn(){
      let menu = store.state.ELECTRICTY_MCN_MENU
      console.log(JSON.stringify(menu))
      let path = ''
      if(menu.children[0].has_children === false){
        path = menu.children[0].path
      }else{
        path = menu.children[0].children[0].path
      }
      this.$router.push(path);
    },
    accountCenter(){
      let menu = store.state.ACCOUNT_CENTER_MENU
      console.log(JSON.stringify(menu))
      let path = ''
      if(menu.children[0].has_children === false){
        path = menu.children[0].path
      }else{
        path = menu.children[0].children[0].path
      }
      this.$router.push(path);
    },
    homeInfoCustom(){
      this.$router.push(store.state.HOME_PAGE_MENU.path);
    },
    merchantsInfoCustom(){
      this.$router.push("/merchantsInfoCustom");
    },
    cardResdCustom(){
      this.$router.push("/cardResdCustom");
    },
    order_center(){
      this.$router.push("/order_center");
    },
    logOut(){
      this.$confirm("确认退出登录吗?", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
          })
          .then(() => {
            this.DEL_TOKEN()
            this.UPLOAD_LIST_DEL_ALL()
            this.$router.push("/login");
          })
          .catch(() => {});
    }
  }
}
</script>

<style scoped>
.app-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head-left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.head_img{
  height: 36px;
}

.app-title {
  color: black;
  font-size: 20px;
  margin-left: 15px;
  font-weight: bold;
}

.app_head_name{
  margin-left: 20px;
  font-size: 16px;
  color: black;
}

.head_right{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu_head{
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.menu_item{
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
  margin: 20px;
}
.menu_item:hover{
  color: dodgerblue;
}

.menu_item_active{
  color: dodgerblue;
}

.login_info{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: white;
}

.login_info_active{
  color: dodgerblue;
}

.menu_info{
  border-radius: 10px;
  z-index: 999;
  position: absolute;
  top: 55px;
  width: 120px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  font-size: 12px;
  font-weight: 400;
  color: rgba(56, 56, 56, 1);
  text-align: left;
}

.menu_info_item{
  padding-left: 15px;
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.menu_info_item:hover{
  opacity: 0.5;
}

.menu_info_item_out{
  text-align: center;
  margin-top: 10px;
  color: rgba(255, 87, 51, 1);
}

.menu_info_item_out:hover{
  opacity: 0.5;
}

.el-divider--horizontal {
  margin: 0;
}

</style>
