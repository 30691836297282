<template>
<div>
  <el-dialog
      :title="dialogTitle"
      :visible.sync="talent_matrix_visible"
      width="50%">
    <div style="padding-left: 50px;padding-right: 50px">
      <div class="talent_matrix_title">达人矩阵系统</div>
      <div class="talent_matrix_item">
        <div>系统有效期1年</div>
        <div style="color: rgba(255, 87, 51, 1);">¥50,000</div>
      </div>

<!--      <div class="talent_matrix_title" style="margin-top: 10px">赠送</div>-->
<!--      <div style="display: flex">-->
<!--        <div class="talent_matrix_item">-->
<!--          <div>云空间容量</div>-->
<!--          <div style="color: rgba(255, 87, 51, 1);">1T</div>-->
<!--        </div>-->
<!--        <div class="talent_matrix_item">-->
<!--          <div>视频生成卡</div>-->
<!--          <div style="color: rgba(255, 87, 51, 1);">1000</div>-->
<!--        </div>-->
<!--      </div>-->

      <div class="talent_matrix_title" style="margin-top: 10px">支付方式</div>
      <div style="display: flex;margin-top: 10px">
        <div  @click="changePayType(1)" class="check_type" :class="{'active_check_type' : talentForm.pay_way === 1}">
          <img src="../assets/pay/wechat.png" class="pay-img" />
          <span style="margin-left: 5px">微信支付</span>
        </div>
        <div  @click="changePayType(2)" class="check_type" style="margin-left: 20px" :class="{'active_check_type' : talentForm.pay_way === 2}">
          <img src="../assets/pay/alipay.png" class="pay-img" />
          <span style="margin-left: 5px">支付宝</span>
        </div>
      </div>
<!--      <div style="margin-top: 10px">-->
<!--        <el-checkbox v-model="talentForm.checked"></el-checkbox><span style="margin-left: 10px">同意</span><span style="cursor: pointer; margin-left: 5px;color: rgba(0, 119, 255, 1);" @click="talent_matrix_agreement">《达人矩阵营销系统年度合作协议》</span>-->
<!--      </div>-->
      <div style="margin-top: 20px;display: flex;flex-direction: column;align-items: center;">
        <div style="display: flex;align-items: center">
          <img v-if="talentForm.pay_way === 1" src="../assets/pay/wechat.png" class="pay-img" />
          <img v-if="talentForm.pay_way === 2" src="../assets/pay/alipay.png" class="pay-img" />
          <div style="margin-left: 5px">请使用
            <span v-if="talentForm.pay_way === 1">微信</span>
            <span v-if="talentForm.pay_way === 2">支付宝</span>
            扫码，支付
            <span style="color: rgba(255, 87, 51, 1);">50,000</span>元
          </div>
        </div>
        <div style="margin-top: 20px" v-if="!payImg">
          <el-button  type="primary" size="small" round  :disabled="talentForm.checked == false" @click="getPayImg">获取支付二维码</el-button>
        </div>
        <div style="margin-top: 20px" v-else>
          <el-image
              style="width: 180px; height: 180px"
              :src="payImg"
              fit="fill"></el-image>
        </div>
      </div>
    </div>
  </el-dialog>

  <el-dialog
      width="30%"
      :title="successTitle"
      :visible.sync="dialogSuccessVisible"
      append-to-body
      center>
    <div style="font-size: 14px;display: flex;align-items: center;justify-content: center">
      <i class="el-icon-success" style="font-size: 30px;color: #13ce66"></i>
      {{successTitle}}
    </div>
    <div style="text-align: center;margin-top: 10px;font-size: 14px">
      支付方式:
      <span v-if="talentForm.pay_way == 1">微信支付</span>
      <span v-if="talentForm.pay_way == 2">支付宝支付</span>
    </div>
    <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="success()">确 定</el-button>
          </span>
  </el-dialog>

</div>
</template>

<script>
import {merchantsInfo} from "../api/merchantsUser";
import { getPayImg ,check} from "../api/masterMatrix";

export default {
  name: "talent_matrix",
  data(){
    return{
      dialogTitle:"开通系统",
      successTitle:"开通成功",
      interval: null,
      talent_matrix_visible: false,
      payImg:'',
      talentForm:{
        checked: true,
        pay_way: 1,
      },
      userInfo:{
        talent_matrix: true
      },
      dialogSuccessVisible:false,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    talent_matrix_agreement(){
      const routeUrl = this.$router.resolve({
        path: "/talent_matrix_agreement"
      });
      window.open(routeUrl.href, "_blank");
    },
    success(){
      this.dialogSuccessVisible = false
      this.$parent.initData();
    },
    getPayImg(){
      console.log(JSON.stringify(this.talentForm))
      getPayImg(this.talentForm).then(res=>{
        console.log(JSON.stringify(res))
        let _this = this
        if (res.code == "0") {
          _this.payImg = res.data.base64
          _this.out_trade_no = res.data.out_trade_no
          clearInterval(_this.interval);
          _this.interval = setInterval(function(){
            _this.doCheck()
          }, 1000);
        }else{
          this.$message.error(res.message);
        }
      })
    },
    doCheck(){
      console.log("定时检查订单")
      let _this = this
      let parms = {
        outTradeNo : this.out_trade_no
      }
      check(parms).then(res=> {
        if (res.code == "0") {
          console.log(JSON.stringify(res.data))
          _this.outTradeInfo = res.data
          if(res.data.trade_status == "SUCCESS"){
            clearInterval(_this.interval);
            _this.payImg = ''
            _this.talent_matrix_visible = false
            _this.dialogSuccessVisible = true
          }
        }
      })
    },
    show(type){
      if(type===2){
        this.dialogTitle = "续费系统"
        this.successTitle = "续费成功"
      }
      this.talent_matrix_visible = true
    },
    changePayType(type) {
      this.talentForm.pay_way = type
      this.payImg = ''
    },
    initData() {
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
    }
  }
}
</script>

<style scoped>
.talent_matrix_title{
  font-size: 16px;
  font-weight: 500;
}

.talent_matrix_item{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 300px;
  height: 50px;
  margin-top: 10px;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 87, 51, 1);
  margin-right: auto;
}


.check_type{
  width: 146px;
  height: 50px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.pay-img{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.active_check_type{
  border: 1px solid rgba(255, 87, 51, 1);
}
</style>
