<template>
  <div style="margin: 10px;padding:10px;background-color: white">
<!--    <el-tag size="small" :effect="getEffect(1)" @click="changeTag(1)">匹配成功</el-tag>-->
<!--    <el-tag size="small" :effect="getEffect(0)" @click="changeTag(0)" type="danger" style="margin-left: 20px">匹配失败</el-tag>-->

    <div style="margin-top: 10px;text-align:left;">
      <el-input
          size="mini"
          placeholder="订单ID"
          v-model="queryParams.order_id"
          clearable
          style="width: 120px">
      </el-input>
      <el-input
          size="mini"
          placeholder="达人抖音号"
          v-model="queryParams.trill_account"
          clearable
          style="width: 120px;margin-left: 10px">
      </el-input>
      <el-input
          size="mini"
          placeholder="达人微信昵称"
          v-model="queryParams.wx_nick_name"
          clearable
          style="width: 120px;margin-left: 10px">
      </el-input>
      <el-input
          size="mini"
          placeholder="经纪人微信昵称"
          v-model="queryParams.agent_nick_name"
          clearable
          style="width: 120px;margin-left: 10px">
      </el-input>

      <el-date-picker
          size="mini"
          style="margin-left: 10px"
          v-model="queryParams.min_refund_time"
          default-time="00:00:00"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="退款完成时间">
      </el-date-picker>
      <i  style="margin-left: 5px" class="el-icon-minus"></i>
      <el-date-picker
          size="mini"
          style="margin-left: 10px"
          v-model="queryParams.max_refund_time"
          default-time="23:59:59"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="退款完成时间">
      </el-date-picker>

      <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          size="mini"
          @click="search()"
      >搜索</el-button>
    </div>

    <el-table
        :data="tableData"
        border
        style="width: 100%;margin-top: 10px"
        :max-height="autoHeight"
    >
      <el-table-column
          align="center"
          prop="order_id"
          label="订单id">
      </el-table-column>
      <el-table-column
          align="center"
          prop="product_name"
          label="商品名称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="trill_account"
          label="达人抖音号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="refund_amount"
          label="用户退款金额">
      </el-table-column>
      <el-table-column
          align="center"
          prop="refund_amount_organization"
          label="机构应返还商家佣金">
      </el-table-column>

      <el-table-column
          align="center"
          label="分佣比例">
        <template slot-scope="scope">
          <div>达人: {{scope.row.intelligent_commission_rate}}%</div>
          <div>经纪人: {{scope.row.agent_commission_rate}}%</div>
          <div>商户: {{scope.row.merchant_proportion}}%</div>
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          prop="quest_rewards"
          label="达人退款收益">
        <template slot-scope="scope">
          {{scope.row.quest_rewards | numberFormat}}
        </template>
      </el-table-column>

      <el-table-column
          width="300"
          align="center"
          label="达人信息">
        <template slot-scope="scope">
          <div v-if="scope.row.data_state !=0 " style="display: flex;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.avatar_url"
                fit="cover">
            </el-image>
            <div style="margin-left: 10px; display: flex;flex-direction: column;justify-content: space-around;align-items: flex-start">
              {{scope.row.nick_name}}
              <div style="margin-top: 10px; display: flex;justify-content: left;align-items: center">
                <div>抖音号:</div>
                <el-image
                    style="margin-left: 5px;width: 30px;height: 30px;border-radius: 50%"
                    :src="scope.row.avatar"
                    fit="cover">
                </el-image>
                <div style="margin-left: 10px">{{scope.row.nickname}}</div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          prop="agent_earnings"
          label="经纪人退款佣金">
        <template slot-scope="scope">
          {{scope.row.agent_earnings | numberFormat}}
        </template>
      </el-table-column>

      <el-table-column
          width="200"
          align="center"
          label="经纪人信息">
        <template slot-scope="scope">
          <div v-if="scope.row.agent_id !== ''" style="display: flex;justify-content: center;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.agent_user.avatar_url"
                fit="cover">
            </el-image>
            <div style="margin-left: 10px">{{scope.row.agent_user.nick_name}}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          prop="merchant_earnings"
          label="商户佣金收益">
        <template slot-scope="scope">
          {{scope.row.merchant_earnings | numberFormat}}
        </template>
      </el-table-column>

      <el-table-column
          width="200"
          align="center"
          label="商户信息">
        <template slot-scope="scope">
          <div v-if="scope.row.merchant !=null " style="display: flex;justify-content: center;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.merchant.head_portrait"
                fit="cover">
            </el-image>
            <div style="margin-left: 10px">{{scope.row.merchant.name}}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          align="center"
          prop="refund_time"
          label="退款完成时间">
      </el-table-column>

    </el-table>
    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="this.queryParams.page"
                    :page-size="this.queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { trillRefundOrdersList } from "../../api/easyExcel";

export default {
  name: "trillRefund_orders_list",
  filters:{
    numberFormat: function (value) {
      return value.toFixed(2)
    }
  },
  data(){
    return{
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        page:1,
        limit:10,
        data_state:1,
        source: 1,
        sidx: "mtro.refund_time",
        order: "desc"
      },
      tableData:[],
      total:0
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
  },
  methods:{
    changeTag(btn){
      console.log("changeTag")
      this.queryParams.data_state = btn
      this.queryParams.page = 1
      this.tableData = []
      this.initData()
    },
    getEffect(btn){
      if(this.queryParams.data_state == 1) {
        if(btn==1){
          return "dark";
        }else{
          return "plain";
        }
      }else{
        if(btn==1){
          return "plain";
        }else{
          return "dark";
        }
      }
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      trillRefundOrdersList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
      });
    }
  }
}
</script>

<style scoped>

</style>
