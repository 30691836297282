<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <div>
      {{ title }}领视频交作业
      <div style="float: right">
        <el-button size="small" style="margin-top: 10px" @click="goBack"
          >取消</el-button
        >
        <el-button
          size="small"
          style="margin-top: 10px"
          type="primary"
          @click="save('ruleForm')"
          >保存</el-button
        >
        <el-button
          v-if="form.internal_state != 1"
          size="small"
          style="margin-top: 10px"
          type="primary"
          @click="saveShelf('ruleForm')"
          >保存并上架</el-button
        >
      </div>
    </div>
    <div style="margin: 20px">
      <div style="display: flex; align-items: center">
        <div class="tem_info" v-if="form.tem_id">
          <i class="el-icon-error product_info_tips" @click="delTem"></i>
          <img
            v-if="form.extend.temInfo.preview"
            style="width: 100%"
            :src="form.extend.temInfo.preview"
            model="widthFix"
          />
          <span v-else>暂无预览</span>
        </div>
        <div class="tem_info" v-else>
          <div style="text-align: center" @click="doAddTem">
            <i class="el-icon-plus"></i>
            <div>添加视频模板</div>
          </div>
        </div>
        <el-form
          size="small"
          :model="form"
          :rules="rules"
          ref="ruleForm"
          :label-position="labelPosition"
          label-width="180px"
        >
          <el-form-item label="模板剩余可生成视频数：">
            <el-input
              style="width: 160px"
              v-model="form.extend.temInfo.can_receive_num"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="关联商品：" required>
            <div class="product_info" v-if="form.product_id">
              <i
                class="el-icon-error product_info_tips"
                @click="delProduct"
              ></i>
              <div style="display: flex; align-items: center">
                <div>
                  <img
                    style="width: 58px"
                    :src="form.extend.productInfo.img"
                    model="widthFix"
                  />
                </div>
                <div
                  style="width: 300px; padding-left: 5px; padding-right: 5px"
                >
                  <div style="display: flex; align-items: center">
                    <img
                      v-if="form.extend.productInfo.product_type == 1"
                      style="width: 16px; height: 16px; border-radius: 2px"
                      src="../../assets/dy.png"
                      fit="cover"
                    />
                    <img
                      v-if="form.extend.productInfo.product_type == 2"
                      style="width: 16px; height: 16px; border-radius: 2px"
                      src="../../assets/ks.png"
                      fit="cover"
                    />
                    <div
                      style="
                        margin-left: 2px;
                        font-size: 12px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ form.extend.productInfo.name }}
                    </div>
                  </div>
                  <div style="display: flex; justify-content: space-between">
                    <div style="font-size: 10px">
                      到手价 ¥{{
                        formatNumber(form.extend.productInfo.discount_price)
                      }}
                    </div>
                    <div>
                      <span style="font-size: 10px; color: rgba(255, 87, 51, 1)"
                        >佣金率
                        {{ form.extend.productInfo.commission_rate }}%</span
                      >
                      <span style="font-size: 10px"
                        >｜公开
                        {{
                          form.extend.productInfo.open_commission_rate
                        }}%</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <el-button v-else @click="doAddGoods">请选择</el-button>
          </el-form-item>
          <el-form-item label="排序：" required>
            <el-input
              style="width: 100px"
              v-model.number="form.custom_sort"
              clearable
            ></el-input>
            <span
              style="
                margin-left: 5px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(166, 166, 166, 1);
              "
              >数字越大排序越靠前，0为默认排序（按照上架时间倒叙排序）</span
            >
          </el-form-item>
          <el-form-item label="达人完成作业奖励：" required>
            <el-input-number
              style="width: 100px"
              :controls="false"
              :precision="2"
              :min="0"
              v-model="form.master_reward"
              placeholder="请输入达人完成作业奖励"
            ></el-input-number>
            <span style="margin-left: 5px">元</span>
          </el-form-item>
          <el-form-item label="上级经纪人奖励：" required>
            <el-input-number
              style="width: 100px"
              :controls="false"
              :precision="2"
              :min="0"
              v-model="form.broker_reward"
              placeholder="请输入上级经纪人奖励"
            ></el-input-number>
            <span style="margin-left: 5px">元</span>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-divider></el-divider>
    <div style="margin: 20px">
      领视频要求
      <div style="margin: 10px; display: flex">
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleFormTwo"
          :label-position="labelPosition"
          label-width="160px"
        >
          <el-form-item label="抖音粉丝量" required>
            <div>
              <el-radio v-model="form.fan_limit" :label="false"
                >不限制</el-radio
              >
            </div>
            <div>
              <el-radio v-model="form.fan_limit" :label="true">不低于</el-radio>
              <el-input
                size="small"
                style="width: 160px"
                v-model.number="form.fan_limit_num"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="微信会员限制" required>
            <div>
              <el-radio v-model="form.member_limit" :label="false"
                >所有人可参与</el-radio
              >
              <el-radio v-model="form.member_limit" :label="true"
                >仅限会员参加</el-radio
              >
            </div>
          </el-form-item>
          <el-form-item label="IP限制" required v-if="synergyMerchants == true">
            <el-radio v-model="form.ip_limit" :label="false">不限制</el-radio>
            <div>
              <el-radio v-model="form.ip_limit" :label="true">绑定</el-radio>
              <el-select
                multiple
                clearable
                style="width: 200px"
                v-model="form.marketing_ip"
                placeholder="请选择IP"
                size="small"
              >
                <el-option
                  v-for="item in ipList"
                  :key="item.id"
                  :label="item.classify_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              的抖音号,不可参与
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <el-divider></el-divider>
    <div style="margin: 20px">
      作业要求
      <div style="margin: 10px; display: flex">
        <el-checkbox-group
          v-model="form.check_item"
          style="display: flex; flex-direction: column"
        >
          <el-checkbox style="margin: 5px" :label="1"
            >添加指定话题
            <el-input
              size="small"
              style="width: 160px"
              clearable
              v-model="form.topic"
            ></el-input>
          </el-checkbox>
          <el-checkbox style="margin: 5px" :label="2">
            <span
              >@指定账号<el-input
                size="small"
                style="width: 160px; margin-left: 10px"
                placeholder="请输入账号名称"
                clearable
                v-model="form.account"
              ></el-input
            ></span>
            <span style="margin-left: 20px"
              >抖音号:<el-input
                size="small"
                style="width: 160px; margin-left: 10px"
                placeholder="请输入抖音号"
                clearable
                v-model="form.account_id"
              ></el-input
            ></span>
          </el-checkbox>
          <!--          <el-checkbox  style="margin: 5px" :label="3">视频发布后在-->
          <!--            <el-date-picker-->
          <!--                size="small"-->
          <!--                style="margin-left: 10px"-->
          <!--                v-model="form.no_del_date"-->
          <!--                value-format="yyyy-MM-dd HH:mm:ss"-->
          <!--                type="date"-->
          <!--                placeholder="请选择日期">-->
          <!--            </el-date-picker>-->
          <!--            前不可删除-->
          <!--          </el-checkbox>-->
        </el-checkbox-group>
      </div>
      详细介绍
      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="position: relative; margin-top: 10px"
          :index="index"
          v-for="(item, index) in form.introduction"
          :key="index"
        >
          <img :src="item" class="avatar" />
          <i class="el-icon-minus del_item" @click="delItem(index)"></i>
        </div>
        <div style="margin-top: 10px">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadImg"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
    </div>

    <el-dialog title="添加模板" :visible.sync="addTemplateVisible">
      <div style="text-align: left">
        <el-input
          @clear="searchInitTem"
          size="small"
          placeholder="请输入模板id"
          v-model="temQueryParams.tem_id"
          clearable
          style="width: 160px"
        >
        </el-input>
        <el-input
          @clear="searchInitTem"
          size="small"
          placeholder="请输入模板名称"
          v-model="temQueryParams.tem_name"
          clearable
          style="width: 160px; margin-left: 10px"
        >
        </el-input>
        <el-button
          @click="searchInitTem"
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>
      <el-table
        style="margin-top: 10px"
        class="table-style"
        :data="temTableData"
        :loading="temListLoading"
        border
        row-key="id"
        ref="temMultipleTable"
        @selection-change="temSelectionChange"
        @select="temSelectOne"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="id" label="编号">
        </el-table-column>
        <el-table-column align="center" prop="name" label="模板预览">
          <template slot-scope="scope">
            <div style="position: relative; text-align: center">
              <el-image
                style="width: 100px"
                :src="scope.row.mediaProducing.media_id"
                lazy
              >
              </el-image>
            </div>
            <div
              style="
                position: absolute;
                text-align: center;
                left: 40%;
                top: 50%;
                transform: translateX(-40%);
                transform: translateY(-50%);
              "
              @click="preview(scope.row)"
            >
              <i class="el-icon-video-play" style="font-size: 50px"></i>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="标题">
        </el-table-column>
        <el-table-column align="center" prop="update_time" label="入库时间">
          <template slot-scope="scope">
            <span v-if="scope.row.update_time != null">{{
              parseDateFull(scope.row.update_time)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="buildNum" label="已生成视频总数">
        </el-table-column>
        <el-table-column
          align="center"
          prop="dismiss_number"
          label="模板生成视频数上限"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.dismiss_number == -1">不限制</div>
            <div v-else>{{ scope.row.dismiss_number }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-row style="margin-top: 24px">
        <el-col :span="18" style="text-align: left">
          <span class="page-tool">
            <el-pagination
              background
              @current-change="temCurrentChange"
              :current-page="temQueryParams.page"
              :page-size="temQueryParams.limit"
              :total="temTotal"
              layout="total, prev, pager, next"
            ></el-pagination>
          </span>
        </el-col>
        <el-col
          :span="6"
          style="text-align: right; display: flex; justify-content: right"
        >
          <el-button size="small" @click="addTemplateVisible = false"
            >取消</el-button
          >
          <el-button size="small" type="primary" @click="sureTem"
            >添加</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="添加商品" :visible.sync="goodsVisible">
      <div>
        <el-tabs
          v-model="goodsQueryParams.productType"
          @tab-click="productTypeChange"
        >
          <el-tab-pane label="抖音商品" name="1"></el-tab-pane>
          <!--          <el-tab-pane label="快手商品" name="2"></el-tab-pane>-->
        </el-tabs>
      </div>
      <div style="text-align: left">
        <el-input
          @clear="searchInitGoods"
          size="small"
          placeholder="商品名称"
          v-model="goodsQueryParams.productName"
          clearable
          style="width: 160px"
        >
        </el-input>
        <el-button
          @click="searchInitGoods"
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          >搜索</el-button
        >
      </div>

      <el-table
        style="margin-top: 10px"
        class="table-style"
        :data="goodsTableData"
        :loading="goodsListLoading"
        border
        row-key="id"
        ref="goodsMultipleTable"
        @selection-change="goodsSelectionChange"
        @select="goodsSelectOne"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" prop="name" label="商品来源">
          <template slot-scope="scope">
            <span v-if="scope.row.merchants_id === -1">运营中心</span>
            <span v-else>自建</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="商品主图">
          <template slot-scope="scope">
            <div
              style="display: flex; justify-content: center; text-align: center"
              v-if="scope.row.commodity_module === 1"
            >
              <el-image
                style="width: 60px; height: 60px"
                :src="scope.row.img"
                fit="cover"
              >
              </el-image>
            </div>
            <div
              style="display: flex; justify-content: center; text-align: center"
              v-else
            >
              <el-image
                style="width: 60px; height: 60px"
                :src="scope.row.product_img"
                fit="cover"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="name" label="商品名称">
          <template slot-scope="scope">
            <div style="display: flex" v-if="scope.row.commodity_module === 1">
              {{ scope.row.name }}
            </div>
            <div style="display: flex" v-else>
              {{ scope.row.product_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="到手价">
          <template slot-scope="scope">
            <div v-if="scope.row.commodity_module === 1">
              {{ formatNumber(scope.row.discount_price) }}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="库存">
          <template slot-scope="scope">
            <div v-if="scope.row.commodity_module === 1">
              {{ scope.row.total_stock_num }}
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="id" label="佣金率/公开佣金率">
          <template slot-scope="scope">
            <div v-if="scope.row.commodity_module === 1">
              <div>{{ scope.row.commission_rate }} %</div>
              <div>{{ scope.row.open_commission_rate }} %</div>
            </div>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
      <el-row style="margin-top: 24px">
        <el-col :span="18" style="text-align: left">
          <span class="page-tool">
            <el-pagination
              background
              @current-change="goodsHandleCurrentChange"
              :current-page="goodsQueryParams.page"
              :page-size="goodsQueryParams.limit"
              :total="goodsTotal"
              layout="total, prev, pager, next"
            ></el-pagination>
          </span>
        </el-col>
        <el-col
          :span="6"
          style="text-align: right; display: flex; justify-content: right"
        >
          <el-button size="small" @click="goodsVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="sureGoods"
            >添加</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { getOssAccessInfo } from "../../api/common";
import Sha1 from "js-sha1";
var OSS = require("ali-oss");
import moment from "moment";
import { info, saveOrUpdate } from "../../api/video_job/video_job";
import { templateList } from "../../api/template";
import { ipGoodsAllList } from "../../api/trill_goods";
import { marketingIp, synergyMerchantsCheck } from "../../api/product_classify";

export default {
  name: "add",
  data() {
    return {
      synergyMerchants: false,
      goodObj: {},
      goodsVisible: false,
      goodsQueryParams: {
        productType: "1",
        productName: "",
        productState: 1,
        page: 1,
        limit: 5,
        sidx: "mtp.custom_sort",
        order: "desc",
      },
      goodsTotal: 0,
      goodsTableData: [],
      goodsListLoading: false,
      temObj: {},
      temQueryParams: {
        tem_id: "",
        tem_name: "",
        page: 1,
        limit: 5,
      },
      temTotal: 0,
      addTemplateVisible: false,
      temTableData: [],
      temListLoading: false,
      operationType: 1,
      labelPosition: "right",
      title: "",
      form: {
        tem_id: "",
        product_id: "",
        surplus_quantity: 0,
        custom_sort: 0,
        master_reward: 0.0,
        broker_reward: 0.0,
        fan_limit: false,
        fan_limit_num: 0,
        member_limit: false,
        ip_limit: false,
        marketing_ip: [],
        check_item: [],
        topic: "",
        account: "",
        account_id: "",
        no_del_date: "",
        introduction: [],
        extend: {
          temInfo: {
            can_receive_num: 0,
          },
          productInfo: {
            img: "",
          },
        },
      },
      rules: {},
      ipList: [],
    };
  },
  mounted() {
    this.doSynergyMerchantsCheck();
    this.doMarketingIp();
    let type = this.$route.query.type;
    this.operationType = type;
    if (type == 1) {
      this.title = "新增";
    } else {
      this.title = "编辑";
      let id = this.$route.query.id;
      this.getInfo(id);
    }
  },
  methods: {
    doSynergyMerchantsCheck() {
      synergyMerchantsCheck({}).then((res) => {
        this.synergyMerchants = res.data;
      });
    },
    doMarketingIp() {
      marketingIp({}).then((res) => {
        this.ipList = res.data.list;
      });
    },
    searchInitTem() {
      this.temQueryParams.page = 1;
      this.initTemData();
    },
    searchInitGoods() {
      this.goodsQueryParams.page = 1;
      this.initGoods();
    },
    sureTem() {
      console.log("temObj:" + JSON.stringify(this.temObj));
      let form = this.form;
      form.tem_id = this.temObj.id;
      form.extend.temInfo = this.temObj;
      this.form = form;
      this.addTemplateVisible = false;
    },
    sureGoods() {
      console.log("goodObj:" + JSON.stringify(this.goodObj));
      let form = this.form;
      form.product_id = this.goodObj.id;
      form.extend.productInfo = this.goodObj;
      this.form = form;
      this.goodsVisible = false;
    },
    doAddGoods() {
      this.initGoods();
      this.goodsVisible = true;
    },
    goodsSelectionChange(val) {
      console.log("商品当前选中:", JSON.stringify(val));
    },
    goodsSelectOne(selection, row) {
      this.$refs.goodsMultipleTable.clearSelection();
      this.goodObj = {};
      if (selection.length == 0) return;
      this.$refs.goodsMultipleTable.toggleRowSelection(row, true);
      this.goodObj = row;
    },
    goodsHandleCurrentChange(val) {
      this.goodsQueryParams.page = val;
      this.initGoods();
    },
    productTypeChange(tab, event) {
      this.goodsQueryParams.page = 1;
      this.initGoods();
    },
    initGoods() {
      this.goodsListLoading = true;
      ipGoodsAllList(this.goodsQueryParams).then((response) => {
        this.goodsTableData = response.data.list;
        this.goodsTotal = response.data.total_count;
        this.goodsListLoading = false;
      });
    },
    initTemData() {
      this.temListLoading = true;
      let queryParams = this.temQueryParams;
      templateList(queryParams).then((response) => {
        this.temTableData = response.data.list;
        this.temTotal = response.data.total_count;
        this.temListLoading = false;
      });
    },
    doAddTem() {
      this.initTemData();
      this.addTemplateVisible = true;
    },
    temCurrentChange(val) {
      this.temQueryParams.page = val;
      this.initTemData();
    },
    temSelectionChange(val) {
      console.log("模板当前选中:", JSON.stringify(val));
    },
    temSelectOne(selection, row) {
      this.$refs.temMultipleTable.clearSelection();
      this.temObj = {};
      if (selection.length == 0) return;
      this.$refs.temMultipleTable.toggleRowSelection(row, true);
      this.temObj = row;
    },
    delTem() {
      let form = this.form;
      form.tem_id = "";
      form.extend.temInfo.can_receive_num = 0;
      this.form = form;
    },
    delProduct() {
      let form = this.form;
      form.product_id = "";
      this.form = form;
    },
    goBack() {
      this.$router.go(-1);
    },
    save(formName) {
      console.log(JSON.stringify(this.form));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit!!");
          if (this.form.tem_id == "") {
            this.$message.error({ message: "请添加正确的模板" });
            return;
          }
          if (this.form.product_id == "") {
            this.$message.error({ message: "请选择正确的商品" });
            return;
          }
          saveOrUpdate(this.form).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "保存成功",
              });
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveShelf(formName) {
      console.log(JSON.stringify(this.form));
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log("submit!!");
          if (this.form.tem_id == "") {
            this.$message.error({ message: "请添加正确的模板" });
            return;
          }
          if (this.form.product_id == "") {
            this.$message.error({ message: "请选择正确的商品" });
            return;
          }
          this.form.internal_state = 1;
          this.form.shelf_time = moment(new Date()).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          saveOrUpdate(this.form).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "上架成功",
              });
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getInfo(id) {
      info({ id: id }).then((res) => {
        this.form = res.data;
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/video_job/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          let url = "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
          console.log(url);
          this.form.introduction.push(url);
          this.$message.success("上传成功");
        });
      });
    },
    delItem(index) {
      this.form.introduction.splice(index, 1);
    },
    formatNumber(val) {
      return (val / 100).toFixed(2);
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m;
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 351px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 351px;
  display: block;
}

.del_item {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  border: 1px dashed #757070;
  border-radius: 6px;
  position: absolute;
  left: 360px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tem_info {
  width: 180px;
  height: 320px;
  opacity: 1;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.product_info {
  margin-top: 10px;
  opacity: 1;
  border-radius: 6px;
  background: rgba(246, 246, 246, 1);
  position: relative;
}

.product_info_tips {
  font-size: 20px;
  position: absolute;
  width: 20px;
  height: 20px;
  top: -10px;
  right: -10px;
  z-index: 100;
}

.table-style /deep/ .el-table-column--selection.is-leaf .el-checkbox {
  display: none;
}
</style>
