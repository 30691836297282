<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div>
      <div style="display: flex;justify-content: space-between;padding: 10px">
        <div style="display: flex">
          <el-image
              style="width: 80px; height: 80px"
              :src="grassTaskInfo.trill_product.img"
              fit="cover">
          </el-image>
          <div style="margin-left: 10px; display: flex;flex-direction: column;justify-content: space-between">
            <div>{{grassTaskInfo.trill_product.name}}</div>
<!--            <div>{{grassTaskInfo.task_start_time }} 至 {{grassTaskInfo.task_end_time }}</div>-->
          </div>
        </div>

<!--        <div>-->
<!--          <el-button size="small" v-if="grassTaskInfo.task_state == 1" type="success">进行中</el-button>-->
<!--          <div v-if="grassTaskInfo.task_state == 2" style="text-align: right">-->
<!--            <el-button size="small"  type="warning">已结束</el-button>-->
<!--            <div style="margin-top: 10px">{{grassTaskInfo.task_end_time}}</div>-->
<!--          </div>-->
<!--          <div v-if="grassTaskInfo.task_state == 3" style="text-align: right">-->
<!--            <el-button size="small"  type="warning">强制结束</el-button>-->
<!--            <div style="margin-top: 10px">{{parseDateFull(grassTaskInfo.finish_time)}}</div>-->
<!--          </div>-->
<!--        </div>-->

      </div>
      <div style="display: flex;margin-top: 20px">
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.limit_num}}</div>
          <div class="info_item_text">可参与人数</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.participation}}</div>
          <div class="info_item_text">已报名人数</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.linkCount}}</div>
          <div class="info_item_text">已回传链接</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.playCount}}</div>
          <div class="info_item_text">总播放量</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.diggCount}}</div>
          <div class="info_item_text">总点赞数</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.commentCount}}</div>
          <div class="info_item_text">总评论数</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.shareCount}}</div>
          <div class="info_item_text">总分享数</div>
        </div>
      </div>
    </div>

    <el-divider></el-divider>
    <div>

      <el-table
          :data="tableData"
          :loading="listLoading"
          border
          style="margin-top: 10px;width: 100%"
          :max-height="autoHeight"
          :max-width="autoWidth"
      >
        <el-table-column align="center" label="抖音号">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: center; align-items: center">
              <el-image
                  style="width: 48px; height: 48px;border-radius: 50%;"
                  :src="scope.row.trill_user_info.avatar"
                  fit="cover">
              </el-image>
              <div style="margin-left: 10px; text-align: left">
                <div>{{scope.row.trill_user_info.nickname}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="抖音粉丝量">
          <template slot-scope="scope">
            {{scope.row.trill_user_info.fans_number}}
          </template>
        </el-table-column>
        <el-table-column align="center" label="发布详情" >
          <template slot-scope="scope">
            <div v-if="scope.row.tiktokVideo !== null" style="display: flex;">
              <img :src="scope.row.tiktokVideo.cover" width="100px"/>
              <div style="padding: 10px; display: flex;flex-direction: column;justify-content: space-between;text-align: left">
                <div>{{scope.row.tiktokVideo.title}}</div>
                <div><span style="color: rgba(67, 207, 124, 1);">已发布</span> {{ parseFull(scope.row.tiktokVideo.create_time * 1000)}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发布详情" >
          <template slot-scope="scope">
            <div class="issue_bottom">
              <div class="issue_item">
                <img class="issue_icon" src="../../assets/grass_task/look.png"/>
                <div>
                  {{scope.row.tiktokVideo.play_count}}
                </div>
              </div>
              <div class="issue_item">
                <img class="issue_icon" src="../../assets/grass_task/good.png" />
                <div>{{scope.row.tiktokVideo.digg_count}}
                </div>
              </div>

              <div class="issue_item">
                <img class="issue_icon" src="../../assets/grass_task/commit.png" />
                <div>
                  {{scope.row.tiktokVideo.comment_count}}
                </div>
              </div>

              <div class="issue_item">
                <img class="issue_icon" src="../../assets/grass_task/share.png" />
                <div>
                  {{scope.row.tiktokVideo.share_count}}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作"  width="250">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.tiktokVideo !=null"
                @click.native.prevent="jumpDouyin(scope.row)"
                type="text"
                size="small">
              跳转到抖音
            </el-button>
          </template>
        </el-table-column>

      </el-table>

      <el-row style="margin-top: 24px;margin-right: 20px">
        <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="this.queryParams.page"
                    :page-size="this.queryParams.limit"
                    :total="this.total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
        </el-col>
      </el-row>
    </div>

  </div>
</template>

<script>
import {
  grassTaskInfo,
  grassTaskUserList,
} from "../../api/dataReport";

export default {
  name: "taskDetails",
  data(){
    return{
      generate_options:[
        {
          value: 1,
          label: '全部'
        },
        {
          value: 2,
          label: '已生成'
        },
        {
          value: 3,
          label: '未生成'
        }
      ],
      comes_back_options:[
        {
          value: 1,
          label: '全部'
        },
        {
          value: 2,
          label: '已回传'
        },
        {
          value: 3,
          label: '未回传'
        }
      ],
      dismiss_reason_options: [],
      dismiss_reason: '',
      dialogObj: {},
      passVisible:false,
      rejectedVisible:false,
      grassTaskInfo:{},
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        sidx : "sgtu.create_time",
        order : "desc",
        page: 1,
        limit: 5,
        grass_task_id:"",
        generate:1,
        comes_back:2
      },
      total:0,
      tableData:[
      ]
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted() {
    let id = this.$route.query.id
    this.queryParams.grass_task_id = id
    this.doGrassTaskInfo()
    this.initData();
  },
  methods:{
    jumpDouyin(row){
      console.log(JSON.stringify(row))
      window.open(row.tiktokVideo.share_url , "_blank");
    },
    parseFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m ;
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m ;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    doGrassTaskInfo(){
      let queryParams = {
        id : this.queryParams.grass_task_id
      }
      grassTaskInfo(queryParams).then((response) => {
        this.grassTaskInfo = response.data;
      });
    },
    initData() {
      this.listLoading = true;
      grassTaskUserList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
  }
}
</script>

<style scoped>
.info_item{
  border-radius:50%;
  width: 110px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(245, 245, 245, 1);
}
.info_item_value{
  color: rgba(0, 119, 255, 1);
  font-size: 18px;
  font-weight: 500;
}
.info_item_text{
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 400;
}

.issue_item{
  display: flex;
}

.issue_icon {
  width: 20px;
  height: 20px;
}

.issue_bottom {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  color: #333333;
}

</style>
