<template>
  <div>
    <el-dialog title="添加素材" :visible.sync="dialogVisible" width="60%" :before-close="canCel">
      <div style="text-align: center; margin-top: 20px">
        <el-upload multiple :file-list="fileList" action="" ref="uploadRef" :before-upload="beforeUpload"
          :http-request="acceptUpload" drag>
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--        <el-button size="small" @click="canCel">取 消</el-button>-->
      <!--        <el-button size="small" type="primary" @click="sureSubit">确 定</el-button>-->
      <!--      </span>-->
    </el-dialog>

  </div>
</template>

<script>
import { merchantsInfo } from "../../api/merchantsUser";
import { mapState } from 'vuex'
const OSS = require('ali-oss')
import { mapMutations } from 'vuex';
import { addFolder, folderType } from "../../api/material";
import { getOssAccessInfo } from "../../api/common";
import { Queue } from '@/utils/queue.js'

export default {
  props: {
    dialogVisible: {},
    superiorId: {}
  },
  data() {
    return {
      creatorUser: {},
      merchantsInfo: {},
      fileList: [],
      mediaId: '',
      inputUrl: '',
      radioGroup: {},
      ossInfo: '', // oss信息
      client: '', // new OSS 
      uploadTimer: '',
      getUploadList: [], // 上传的列表
      queue: '',
      uploadNum: 0 // 每次上传的数量
    };
  },
  computed: {
    ...mapState(['UPLOAD_LIST']),
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapMutations(['UPLOAD_LIST_PUSH_ITEM', 'UPLOAD_PROGRESS']),
    initData() {
      merchantsInfo({}).then((res) => {
        this.creatorUser = res.data.creatorUser;
        this.merchantsInfo = res.data.merchantsInfo;
      });
    },
    beforeUpload(file) {
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const MEDIA_TYPE = ['video', 'audio', 'image']
      if (MEDIA_TYPE.indexOf(this.getMediaType(fileType)) === -1) {
        this.$message.warning('文件暂不支持,请立即联系技术人员调整;')
        return false
      }
    },
    getMediaType(fileType) {
      const VIDEO_ALLOWD = ['MP4', 'mp4', 'm4v', '3gp', 'mpg', 'flv', 'f4v', 'swf', 'avi', 'gif', 'wmv', 'rmvb', 'mts', 'm2t', 'webm', 'ogg', 'mkv', 'MOV', 'mov']
      if (VIDEO_ALLOWD.indexOf(fileType) != -1) {
        return "video"
      }
      const AUDIO_ALLOWD = ['mp3', 'aac', 'ape', 'flac', 'wav', 'wma', 'amr', 'mid']
      if (AUDIO_ALLOWD.indexOf(fileType) != -1) {
        return "audio"
      }
      const IMG_ALLOWD = ['jpeg', 'jpg', 'JPG', 'gif', 'png', 'PNG', 'bmp', 'ico', 'tif', 'tiff', 'psd', 'psb', 'WebP', 'RAW', 'pdf', 'DCM', 'sai', 'rif']
      if (IMG_ALLOWD.indexOf(fileType) != -1) {
        return "image"
      }
    },
    // 接受上传列表
    acceptUpload(res) {
      this.getUploadList.push(res)
      if (this.uploadTimer) {
        clearTimeout(this.uploadTimer)
      }
      this.uploadTimer = setTimeout(async () => {
        this.$emit('showDot', true)
        const client = await this.getOssInfo()
        let getUploadList = []
        if (this.uploadNum === 0) {
          // 第一次添加
          getUploadList = this.getUploadList
        } else {
          // 之后添加
          getUploadList = this.getUploadList.slice(this.uploadNum)
        }
        this.uploadNum = this.getUploadList.length
        let queryList = getUploadList.map((item, index) => {
          const uploadObj = this.setAddUploadList(item, index)
          return () => this.uploadMethods({ client, ...uploadObj })
        })
        if (this.queue) {
          this.queue.add(queryList)
        } else {
          this.queue = new Queue(queryList, 3, () => {
            console.log('list执行完成----xxx')
            this.$emit('closeDot', false)
            this.getUploadList = []
            this.uploadNum = 0
            this.$message.success('上传完成')
            this.$parent.getList()
          })
        }
      }, 800)
    },
    getOssInfo() {
      return getOssAccessInfo().then(res => {
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "ice-media",
          stsToken: res.data.security_token
        });
        return client
      })
    },
    setAddUploadList(res) {
      const newFile = res.file
      const fileSize = newFile.size
      let fileAllName = newFile.name;
      let fileName = fileAllName.substr(0, fileAllName.lastIndexOf("."));
      let fileType = fileAllName.substr(fileAllName.lastIndexOf(".") + 1);
      let mediaType = this.getMediaType(fileType)
      let upName = "/videos/" + fileName + '-' + mediaType + '-' + new Date().getTime() + '.' + fileType;

      const progress = (p, _checkpoint, res) => {
        let progressInfo = {
          percentage: p,
          uid: newFile.uid,
          checkpoint: _checkpoint
        }
        this.UPLOAD_PROGRESS(progressInfo)
      };

      let resource = {
        "superior_id": this.superiorId,
        "name": fileName,
        "file_all_name": fileAllName,
        "file_suffix": fileType,
        "input_url": "http://ice-media.oss-cn-hangzhou.aliyuncs.com" + upName,
        "file_size": fileSize,
        "creator_user_id": this.creatorUser.id,
        "media_type": mediaType,
        "uid": newFile.uid,
        "percentage": 0,
        "fileSize": 0,
        "partSize": 0,
        "timestamp": Date.now()
      }
      this.UPLOAD_LIST_PUSH_ITEM(resource)
      return { upName, newFile, progress, resource }
    },
    uploadMethods({ client, upName, newFile, progress, resource }) {
      return new Promise((resolve, reject) => {
        // 开始分片上传。
        async function multipartUpload() {
          try {
            const result = await client.multipartUpload(upName, newFile, {
              progress,
              meta: {
                year: 2020,
                people: 'test',
              },
              callback: {
                // 设置回调请求的服务器地址，例如http://oss-demo.aliyuncs.com:23450。
                url: 'https://vodmapi.paitk.com/api/creator/template/uploadCallback',
                //（可选）设置回调请求消息头中Host的值，即您的服务器配置Host的值。
                //host: 'yourCallbackHost',
                // 设置发起回调时请求body的值。
                body: 'bucket=${bucket}&object=${object}' +
                  '&resource=${x:resource}'
                ,
                // 设置发起回调请求的Content-Type。
                contentType: 'application/x-www-form-urlencoded',
                // 设置发起回调请求的自定义参数。
                customValue: {
                  resource: JSON.stringify(resource)
                }
              }
            });
            resolve(result)
          } catch (e) {
            // 捕获超时异常。
            if (e.code === 'ConnectionTimeoutError') {
              console.log('TimeoutError');
            }
            console.log(e);
          }
        }
        multipartUpload();
      })
    },
    canCel() {
      this.$refs.uploadRef.clearFiles()
      this.$emit("close");
    },
    sureSubit() {
      this.fileList = []
      this.$emit("sureClose");
    },
  },

};
</script>
