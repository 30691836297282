<template>
<div>
  课程数据
</div>
</template>

<script>
export default {
  name: "course_data"
}
</script>

<style scoped>

</style>
