<template>
<div>

  <div v-if="userInfo.talent_matrix === true">
    <div class="home_top" v-if="memberPullInfo.pullSwitch == true">
      <div style="display: flex">
        <div style="width: 40%;display: flex;flex-direction: column;align-items: center;justify-content: center">
          <div style="display: flex;justify-content: space-between; width: 100%">
            <div style="flex: 1">
              <div class="flex_lable">实收款总额(元)</div>
              <div style="color: rgba(56, 56, 56, 1);font-size: 36px;;font-weight: bold;">{{memberPullInfo.totalCollection | numberFormat}}</div>
            </div>
            <div style="flex: 1">
            </div>
            <div style="flex: 1">
              <div class="flex_lable">平台总抽成(元)</div>
              <div style="color: rgba(56, 56, 56, 1);font-size: 36px;;font-weight: bold;">{{memberPullInfo.drawingAmount | numberFormat}}</div>
            </div>
          </div>
          <div style="margin-top: 10px; display: flex;justify-content: space-between;width: 100%">
            <div style="flex: 1;">
              <div class="flex_lable">平台抽成详情</div>
              <el-button size="mini" style="margin-left: 10px; margin-top: 10px" type="primary" @click="pullPercent">查看</el-button>
<!--              <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">{{memberPullInfo.pullPercent | numberFormat}}%</div>-->
            </div>
            <div style="flex: 1">
              <div class="flex_lable">授权额度上限</div>
              <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">￥{{memberPullInfo.authQuota | numberFormat}}</div>
            </div>
            <div style="flex: 1">
              <div class="flex_lable">补款期限</div>
              <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">{{memberPullInfo.paymentPeriod}}天</div>
            </div>
          </div>
        </div>
        <div style="width: 60%;padding-left: 10%">
          <div class="flex_lable">当前余额</div>
          <div style="display: flex;align-items: center">
            <div style="color: rgba(56, 56, 56, 1);font-size: 36px;;font-weight: bold;">{{memberPullInfo.sysBalance | numberFormat}}</div>
            <el-button size="mini" style="margin-left: 10px" type="primary" @click="pullBalanceAdd">充值</el-button>
            <el-button size="mini" style="margin-left: 10px" @click="pullBalance">记录</el-button>
          </div>
          <div class="flex_lable" v-if="memberPullInfo.sysBalance < 0">
            <div>温馨提醒：</div>
            <div>1、您需在{{memberPullInfo.endTime}}前补足余额至≥0元，否则系统将被停用；</div>
            <div>2、当余额＜负{{memberPullInfo.authQuota | numberFormat}}时，系统将被立即停用。</div>
          </div>
        </div>
      </div>
      <el-divider></el-divider>
      <div>
        <div style="font-size: 16px;font-weight: 700;">
          收款总览
        </div>
        <div style="display: flex;flex-wrap: wrap;width: 100%">
          <div style="flex-basis: 16%;display: flex;flex-direction: column;align-items: center;margin-top: 10px" v-for="(item,index) in memberPullInfo.overview" :key="index">
            <span class="flex_lable">{{item.label}}</span>
            <span style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">{{item.value}}</span>
          </div>
        </div>
<!--        <div style="display: flex;width: 100%">-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">会员订单实收款(元)</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">10000.00</div>-->
<!--          </div>-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">会员销售额(元)</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">10000.00</div>-->
<!--          </div>-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">订单数</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">10</div>-->
<!--          </div>-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">会员退款金额(元)</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">0.00</div>-->
<!--          </div>-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">退款订单数</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">0</div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div style="display: flex;width: 100%;margin-top: 10px">-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">会员兑换码实收款(元)</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">0.00</div>-->
<!--          </div>-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">兑换码销售额(元)</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">0.00</div>-->
<!--          </div>-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">兑换码数量</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">10</div>-->
<!--          </div>-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">作废兑换码金额(元)</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">0.00</div>-->
<!--          </div>-->
<!--          <div style="flex: 1">-->
<!--            <div class="flex_lable">作废兑换码数量</div>-->
<!--            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;;font-weight: bold;">0</div>-->
<!--          </div>-->
<!--        </div>-->

      </div>
    </div>
    <div class="home_info">
      <div class="home_left">
        <div class="home_container">
          <div v-if="authorizerInfo.authorizer == true">
            <div style="display: flex;padding: 10px;align-items: center">
              <img :src="authorizerInfo.authorizer_info.head_img" class="head_portrait" />
              <div style="display: flex;flex-direction: column;margin-left: 10px">
                <div style="color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: bold">{{authorizerInfo.authorizer_info.nick_name}}</div>
                <div style="color: rgba(128, 128, 128, 1);font-size: 14px;">{{authorizerInfo.authorizer_info.principal_name}}</div>
              </div>
            </div>
            <div class="flex_container">
              <div class="flex_chid" v-for="(item,index) in userInfoList" :key="index">
                <span class="flex_lable">{{item.label}}</span>
                <span class="flex_value">{{item.value}}</span>
              </div>
            </div>
          </div>
          <div v-if="authorizerInfo.authorizer == false" style="display: flex;flex-direction: column;justify-content: center;align-items: center;padding: 10%">
            <div class="item_info_text">尚未绑定微信小程序</div>
            <el-button type="primary" style="width: 60%" round @click="toMminiProgram">授权微信小程序</el-button>
          </div>
        </div>
        <div class="home_container">
          <div style="display: flex;align-items: center;justify-content: space-between">
            <div style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">数据概况</div>
            <div>
              当前周期
              <el-date-picker
                  @change="datePickerChange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  size="small"
                  v-model="queryDate"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </div>

          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in surveyList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
          <div id="myChart" style="width: 100%;height: 400px;margin-top: 50px"></div>
        </div>
      </div>
      <div class="home_right">
        <div class="home_container">
          <div style="width: 100%; border-radius: 6px;height: 56px;display: flex;justify-content: space-between;padding-left: 5%;padding-right: 5%;">
            <div style="display: flex;align-items: center; color: rgba(56, 56, 56, 1);">
            <span style="font-size: 18px;font-weight: 700;">
              达人矩阵系统到期时间：{{ formatDate(userInfo.talent_matrix_exp_time,null)}}
            </span>
            </div>
            <div style="display: flex;align-items: center;">
              <span style="color: rgba(0, 119, 255, 1);font-size: 14px;margin-left: 10px;cursor: pointer" @click="talentMatrix(2)">立即续费</span>
              <img src="../../assets/home/right.png" style="width: 20px;height: 20px" />
            </div>
          </div>
        </div>
        <div class="home_container">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">生成卡</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in genCardList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
            <div style="width: 100%; background: rgba(245, 245, 245, 1);border-radius: 6px;height: 56px;display: flex;justify-content: space-between;padding-left: 5%;padding-right: 5%; margin-top: 35px;">
              <div style="display: flex;align-items: center">
                <img src="../../assets/home/video.png" style="width: 30px;height: 30px" />
                <span style="margin-left: 10px;color: rgba(56, 56, 56, 1);font-size: 14px;">充值视频生成卡，邀请更多会员加入</span>
              </div>
              <div style="display: flex;align-items: center;">
                <span style="color: rgba(0, 119, 255, 1);font-size: 14px;margin-left: 10px;cursor: pointer" @click="cardResdCustom">充值</span>
                <img src="../../assets/home/right.png" style="width: 20px;height: 20px" />
              </div>
            </div>
          </div>
        </div>
        <div class="home_container">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">视频模板</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in temList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
        </div>

        <!--      <div class="home_container">-->
        <!--        <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">种草任务</span>-->
        <!--        <div class="flex_container">-->
        <!--          <div class="flex_chid" v-for="(item,index) in taskList" :key="index">-->
        <!--            <span class="flex_lable">{{item.label}}</span>-->
        <!--            <span class="flex_value">{{item.value}}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->

        <div class="home_container">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">提现数据</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in withdrawalList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
        </div>

        <div class="home_container">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">教程与帮助</span>
          <div class="right_item_view" @click="jz_document">
            <div>达人矩阵系统使用文档</div>
            <div style="margin-top: 10px; font-size: 12px;font-weight: 400;color: rgba(166, 166, 166, 1);">从系统搭建到上线运营，更有会员分销系统、裂变系统、任务系统等多场景的触达到用户</div>
          </div>
        </div>


      </div>
    </div>
  </div>

  <div class="talent_matrix" v-if="userInfo.talent_matrix === false">
    <div class="home_banner">
      <img
          style="margin-top: 60px; width: 500px;"
          src="../../assets/talent_matrix/banner_title.png"
      />
      <div style="font-size: 24px;font-weight: 400;color: rgba(255, 255, 255, 1);">更简单、快速的搭建自己的达人带货团队</div>
      <el-button size="small" style="margin-top: 20px"><span style="font-size: 18px;font-weight: 500;color: rgba(0, 119, 255, 1);" @click="talentMatrix(1)">立即开通</span></el-button>
    </div>

    <div style="padding: 20px;padding-bottom: 60px; text-align: center;background: rgba(255, 255, 255, 1);">
      <div style="margin-top: 20px;font-size: 36px;font-weight: 500;">达人矩阵系统能力</div>
      <div style="margin-top: 20px; font-size: 20px;font-weight: 400;color: rgba(128, 128, 128, 1);">让0基础小白也能成为带货达人</div>
      <div style="display: flex;flex-direction: row;margin-top: 30px">
        <div style="width: 35%">
          <img style="width: 226px;float: right"
               src="../../assets/talent_matrix/phone_info.png"/>
        </div>
        <div style="width: 65%;padding: 50px">
          <div class="flex_container">
            <div class="flex_chid_jz">
              <img style="height: 70px;"
                   src="../../assets/talent_matrix/jz1.png"
              />
              <div class="flex_chid_jz_name">商品管理</div>
            </div>
            <div class="flex_chid_jz">
              <img style="height: 70px;"
                      src="../../assets/talent_matrix/jz2.png"
              />
              <div class="flex_chid_jz_name">达人管理</div>
            </div>
            <div class="flex_chid_jz">
              <img style="height: 70px;"
                   src="../../assets/talent_matrix/jz3.png"
              />
              <div class="flex_chid_jz_name">分销体系</div>
            </div>
            <div class="flex_chid_jz">
              <img style="height: 70px;"
                   src="../../assets/talent_matrix/jz4.png"
              />
              <div class="flex_chid_jz_name">视频生成</div>
            </div>
            <div class="flex_chid_jz">
              <img style="height: 70px;"
                   src="../../assets/talent_matrix/jz5.png"
              />
              <div class="flex_chid_jz_name">任务系统</div>
            </div>
            <div class="flex_chid_jz">
              <img style="height: 70px;"
                   src="../../assets/talent_matrix/jz6.png"
              />
              <div class="flex_chid_jz_name">独立小程序</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 20px;padding-bottom: 60px; background: rgba(247, 248, 250, 1);">
      <div style="text-align: center;margin-top: 30px">
        <div style="font-size: 36px;font-weight: 500;">一个好的系统将成为您生意增长的新引擎</div>
        <div style="margin-top: 10px; font-size: 20px;font-weight: 400;color: rgba(128, 128, 128, 1);">提供从商品管理到视频分发到获客成交的全链路服务</div>
      </div>
      <div style="margin-top: 10px; display: flex;justify-content: space-around;padding-left: 10%;padding-right: 10%">
        <div class="item_info">
          <div style="background: rgba(219, 236, 255, 1);border-radius: 50%;width: 50px;height: 50px;display: flex;justify-content: center;align-items: center">
            <img style="width: 30px;height: 26px"
                 src="../../assets/talent_matrix/item1.png"
            />
          </div>
          <div class="item_name">高效引流获客</div>
          <div class="item_tips">短视频智能生产引擎，赋能达人全网分发视频，规模化获取流量</div>
        </div>
        <div class="item_info">
          <div style="background: rgba(219, 236, 255, 1);border-radius: 50%;width: 50px;height: 50px;display: flex;justify-content: center;align-items: center">
            <img style="width: 30px;height: 26px"
                 src="../../assets/talent_matrix/item2.png"
            />
          </div>
          <div class="item_name">达人自裂变</div>
          <div class="item_tips">借助分销分利玩法，达人变渠道，快速裂变团队</div>
        </div>
        <div class="item_info">
          <div style="background: rgba(219, 236, 255, 1);border-radius: 50%;width: 50px;height: 50px;display: flex;justify-content: center;align-items: center">
            <img style="width: 30px;height: 26px"
                 src="../../assets/talent_matrix/item3.png"
            />
          </div>
          <div class="item_name">智能化数据分析</div>
          <div class="item_tips">实时监测推广数据，指导经营决策，帮助更好的运营</div>
        </div>
      </div>
    </div>

    <div style="padding: 20px;background: rgba(255, 255, 255, 1);">
      <div style="font-size: 36px;font-weight: 500; text-align: center;margin-top: 20px">接入步骤</div>

      <div style="margin-top: 20px; display: flex;justify-content: space-around">
        <div class="step_item">
          <div class="step_item_title">01</div>
          <div class="step_item_right">
             <div class="step_name">开通</div>
            <div class="step_tips">开通达人矩阵系统</div>
          </div>
        </div>
        <div class="step_item">
          <div class="step_item_title">02</div>
          <div class="step_item_right">
            <div class="step_name">注册</div>
            <div class="step_tips">注册微信小程序</div>
          </div>
        </div>
        <div class="step_item">
          <div class="step_item_title">03</div>
          <div class="step_item_right">
            <div class="step_name">接入系统</div>
            <div class="step_tips">授权接入达人矩阵系统</div>
          </div>
        </div>
        <div class="step_item">
          <div class="step_item_title">04</div>
          <div class="step_item_right">
            <div class="step_name">开展业务</div>
            <div class="step_tips">招募达人进行视频分发</div>
          </div>
        </div>
      </div>

      <el-steps  align-center>
        <el-step >
          1
        </el-step>
        <el-step >
          2
        </el-step>
        <el-step >
          3
        </el-step>
        <el-step >
          4
        </el-step>
      </el-steps>

      <div style="text-align: center;margin-top: 30px;margin-bottom: 300px">
        <el-button size="small" type="primary" @click="talentMatrix(1)">立即开通</el-button>
      </div>

    </div>

  </div>

  <TalentMatrix ref="talentMatrix"></TalentMatrix>

  <el-dialog
      size="small"
      title="平台抽成余额管理"
      :visible.sync="pullBalanceVisible"
      width="60%">

    <el-table
        :data="pullBalanceRes"
        style="width: 100%">
      <el-table-column
          align="center"
          prop="create_time"
          label="时间">
      </el-table-column>
      <el-table-column
          prop="gen_card_quota"
          label="余额(元)"
          align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.balance > 0"> +{{scope.row.balance | numberFormat}}</div>
          <div v-else>{{scope.row.balance | numberFormat}}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="rec_type"
          label="方式">
      </el-table-column>
      <el-table-column
          align="center"
          prop="remark"
          label="备注">
      </el-table-column>
    </el-table>
    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="pullBalanceChange"
                    :current-page="pullBalanceParams.page"
                    :page-size="pullBalanceParams.limit"
                    :total="pullBalanceParams.total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>
  </el-dialog>

  <el-dialog
      size="small"
      title="平台抽成详情"
      :visible.sync="pullPercentVisible"
      width="60%">

    <el-table
        :data="pullPercentData"
        style="width: 100%">
      <el-table-column
          align="center"
          prop="members_name"
          label="会员名称">
      </el-table-column>
      <el-table-column
          align="center"
          label="售价(元)">
        <template slot-scope="scope">
          {{scope.row.selling_price | numberFormat}}
        </template>
      </el-table-column>
      <el-table-columnf
          align="center"
          label="平台抽成比例">
        <template slot-scope="scope">
          {{scope.row.pull_percent | numberFormat }}%
        </template>
      </el-table-columnf>
      <el-table-column
          align="center"
          prop="create_time"
          label="平台抽成(元)">
        <template slot-scope="scope">
          {{ (scope.row.selling_price * scope.row.pull_percent /100) | numberFormat}}
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>

  <MemberPullBlance ref="renewal"></MemberPullBlance>

</div>
</template>

<script>

import { mapState } from 'vuex'
import {authorizerInfo} from "../../api/mini_program";
import {enterpriseCertInfo, merchantsInfo ,memberPull} from "../../api/merchantsUser";
import TalentMatrix from "../../components/talent_matrix";
import { userInfo, survey ,genCard ,tem ,task ,withdrawal} from "../../api/statistics";
import { pullBalanceList } from "../../api/pullBalance.js"
import {sklmMembersLevelList} from "../../api/sklmUser";

import MemberPullBlance from "../../components/member_pull_blance";


export default {
  name: "newHome",
  components: {
    TalentMatrix,
    MemberPullBlance
  },
  filters:{
    numberFormat: function (value) {
      return value.toFixed(2)
    },
  },
  data(){
    return{
      pullPercentVisible: false,
      pullPercentData:[],
      pullBalanceVisible: false,
      pullBalanceParams:{
        merchantId:'',
        sidx: "create_time",
        order: "desc",
        page: 1,
        limit: 10,
        total:0
      },
      pullBalanceRes:[],
      memberPullInfo:{
        pullSwitch: false,
        sysBalance: 0.00,
        totalCollection:0,
        paymentPeriod:0,
        pullPercent:0.00,
        authQuota:0,
        drawingAmount:0,
        overview:[],
      },
      queryDate: null,
      value2:'',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      userInfo: {
        talent_matrix: null
      },
      authorizerInfo: {
        authorizer_info: {},
        authorization_info: {},
      },
      userInfoList:[],
      surveyList:[],
      genCardList:[],
      temList:[],
      taskList:[],
      withdrawalList:[]
    }
  },
  created(){
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.queryDate= [
      start,end
    ]
  },
  mounted(){
    this.initData()
    this.getMemberPull()
    this.statisticsUserInfo()
    this.statisticsSurvey()
    this.statisticsGenCard()
    this.statisticsTem()
    this.statisticsTask()
    this.statisticsWithdrawal()
    this.doAuthorizerInfo();
  },
  methods: {
    pullBalanceAdd(){
      this.$refs.renewal.show(1)
    },
    pullPercent(){
      let queryParams = {
          page:1,
          limit: 100
      }
      sklmMembersLevelList(queryParams).then((response) => {
        this.pullPercentData = response.data.list;
        this.pullPercentVisible = true
      });
    },
    pullBalanceChange(val){
      console.log(`当前页: ${val}`);
      this.pullBalanceParams.page = val;
      this.doPullBalanceList();
    },
    doPullBalanceList(){
      pullBalanceList(this.pullBalanceParams).then((response) => {
        this.pullBalanceRes = response.data.list
        this.pullBalanceParams.total = response.data.total_count
      });
    },
    pullBalance(){
      this.pullBalanceVisible = true
      let query = {
        sidx: "create_time",
        order: "desc",
        page: 1,
        limit: 10,
        total:0
      }
      this.pullBalanceParams = query
      this.doPullBalanceList();
    },
    jz_document(){
      window.open('https://f0gc6xt9dy.feishu.cn/docx/doxcnMvuCsKmD7hEHSD6tDBEcag?from=from_copylink', '_blank');
    },
    formatDate(millisecond, template) {
      var res = "";
      try {
        var date = new Date(millisecond);
        var opt = {
          "Y+": date.getFullYear().toString(), // 年
          "m+": (date.getMonth() + 1).toString(), // 月
          "d+": date.getDate().toString(), // 日
          "H+": date.getHours().toString(), // 时
          "M+": date.getMinutes().toString(), // 分
          "S+": date.getSeconds().toString(), // 秒
        };
        template = template || "YYYY-mm-dd";
        for (var k in opt) {
          var ret = new RegExp("(" + k + ")").exec(template);
          if (ret) {
            template = template.replace(
                ret[1],
                ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
            );
          }
        }
        res = template;
      } catch (error) {
        console.warn("ERROR formatDate", error);
      }
      return res;
    },
    statisticsUserInfo(){
      userInfo({}).then((res) => {
        this.userInfoList = res.data.list;
      });
    },
    statisticsSurvey(){
      survey({
        queryDate:this.queryDate
      }).then((res) => {
        this.surveyList = res.data.resDtoList;
        let legendData = res.data.legendData
        let xAxisData = res.data.xAxisData
        let seriesInfoDtoList = res.data.seriesInfoDtoList
        this.drawLine(legendData,xAxisData,seriesInfoDtoList);
      });
    },
    statisticsGenCard(){
      genCard({}).then((res) => {
        this.genCardList = res.data.list;
      });
    },
    statisticsTem(){
      tem({}).then((res) => {
        this.temList = res.data.list;
      });
    },
    statisticsTask(){
      task({}).then((res) => {
        this.taskList = res.data.list;
      });
    },
    statisticsWithdrawal(){
      withdrawal({}).then((res) => {
        this.withdrawalList = res.data.list;
      });
    },
    cardResdCustom(){
      this.$router.push("/cardResdCustom");
    },
    datePickerChange(val){
      console.log("datePickerChange")
      console.log(JSON.stringify(val))
      this.queryDate = val
      this.statisticsSurvey()
    },
    initData() {
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
    },
    getMemberPull() {
      memberPull({}).then((res) => {
        this.memberPullInfo = res.data;
      });
    },
    talentMatrix(type){
      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data))
        let certification_status = res.data.certification_status
        if(certification_status ===2 || certification_status ===3){
          this.$refs.talentMatrix.show(type)
        }else{
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/authentication_protocol");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    toMminiProgram(){
      if(this.userInfo.talent_matrix === false){
        this.$confirm("你还未开通达人矩阵系统，无法绑定小程序?", "提示", {
          confirmButtonText: "去开通",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              enterpriseCertInfo({}).then((res) => {
                console.log(JSON.stringify(res.data))
                let certification_status = res.data.certification_status
                if(certification_status ===2 || certification_status ===3){
                  this.$refs.talentMatrix.show(1)
                }else{
                  this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
                    confirmButtonText: "去认证",
                    cancelButtonText: "取消",
                    type: "warning",
                  }).then(() => {
                    this.$router.push("/authentication_protocol");
                  }).catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消",
                    })
                  })
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
      }else{
        this.$router.push('/miniProgram')
      }
    },
    doAuthorizerInfo(){
      authorizerInfo({}).then((response) => {
        let authorizerInfo = response.data;
        if(authorizerInfo.authorizer_info == null){
          console.log('没有授权信息')
          this.authorizer = false
          authorizerInfo.authorizer = false
        }else {
          console.log('展示展示授权信息')
          this.authorizer = true
          authorizerInfo.authorizer = true
        }
        this.authorizerInfo = authorizerInfo
        console.log(JSON.stringify(authorizerInfo))
      });
    },
    drawLine(legendData,xAxisData,seriesInfoDtoList){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        title: { text: '' },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: seriesInfoDtoList
      });
    }
  },
  computed: {
    ...mapState(['USER_INFO'])
  }
}
</script>

<style scoped>

.home_info {
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
}

.home_left {
  flex: 1;
  margin-right: 5px;
}

.home_right {
  flex: 1;
  margin-left: 5px;
}

.home_container {
  background: white;
  padding: 10px;
  margin-top: 10px;
}

.head_portrait{
  width: 50px;
  height: 50px;
}

.flex_container {
  display: flex;
  flex-wrap: wrap;
}

.flex_chid {
  flex-basis: 33%;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
}

.flex_chid_jz{
  margin-top: 20px;
  flex-basis: 33%;
}

.flex_chid_jz_name{
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.flex_lable {
  color: rgba(128, 128, 128, 1);
  font-size: 14px;
}

.flex_value {
  color: rgba(56, 56, 56, 1);
  font-size: 36px;
  font-weight: bold;
}

.talent_matrix{
  padding: 10px;
}

.home_banner{
  background-image: url("../../assets/talent_matrix/banner.png");
  background-size:100% 100%;
  height: 350px;
  padding-left: 10%;
}

.item_info{
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  padding: 20px;
  margin-top: 20px;
  width: 270px;
  height: 220px;
}

.item_name{
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
}

.item_tips{
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
}

.phone{
  background-image: url("../../assets/talent_matrix/iphone.png");
  background-size:100% 100%;
  width: 226px;
  height: 466px;
  float: right;
}

.step_item{
    display: flex;
    flex-direction: row;
   align-items: center;
}

.step_item_title{
  font-size: 48px;
  font-weight: 600;
  color: rgba(0, 119, 255, 1);
}

.step_item_right{
  margin-left: 10px;
}

.step_name{
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 1);
}

.step_tips{
  font-size: 12px;
  font-weight: 400;
  color: rgba(128, 128, 128, 1);
}


.info_item{
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  margin-bottom: 50px;
}


.item_name_two{
  font-size: 20px;
  font-weight: 500;
}

.right_item_view{
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  padding: 10px;
  margin-top: 10px;
}

.home_top{
  margin: 10px;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 30px;
}

</style>
