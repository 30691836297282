<template>
<div class="main_container">
  <div class="main_info">
    <div class="main_info_left">
      <div class="info_item" style="padding: 10px">
        <div class="item_name">我的权益</div>
        <div style="display: flex;justify-content: space-around">
          <div class="left_item_view" style="display: flex;align-items: center">
            <img
                style="width: 80px;height: 80px;"
                src="../../assets/homeInfoCustom/cloud_storage.png"
                fit="cover"/>
            <div style="margin-left: 10px;width: 100%">
              <div>
                <span style="font-size: 18px">云空间</span>
                <span @click="my_cloud_info" style="font-size: 12px;font-weight: 500;color: rgba(0, 119, 255, 1);float: right;cursor: pointer;">去扩容</span>
              </div>
              <el-progress :percentage="cloudInfo.percentage"></el-progress>
              <div>{{cloudInfo.useCloudSize | formatFileSize }}/{{ cloudInfo.cloudSize | formatFileSize }}</div>
            </div>
          </div>
          <div class="left_item_view" style="display: flex;align-items: center">
            <img
                style="width: 50px;height: 50px;margin: 10px;"
                src="../../assets/homeInfoCustom/membershipcard.png"
                fit="cover"/>
            <div style="margin-left: 10px;width: 100%">
              <div>
                <span style="font-size: 18px">视频生成卡</span>
                <span @click="cardResdCustom" style="font-size: 12px;font-weight: 500;color: rgba(0, 119, 255, 1);float: right;cursor: pointer;">去充值</span>
              </div>
              <div style="font-size: 30px;font-weight: 500;">{{userInfo.gen_card_num}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="home_container">
        <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">Ai云剪辑</span>
        <div class="flex_container">
          <div class="flex_chid" v-for="(item,index) in clipList" :key="index">
            <span class="flex_lable">{{item.label}}</span>
            <span class="flex_value">{{item.value}}</span>
          </div>
        </div>
      </div>

      <div class="info_item" style="margin-top: 10px" v-if="userInfo.talent_matrix === false">
        <div class="item_name">达人矩阵</div>
        <div style="margin-top: 20px">
          <el-steps  finish-status="success">
            <el-step>
              <template slot="description">
                <div style="margin-top: 10px">
                  <div style="color: rgba(0, 0, 0, 1);">注册微信小程序</div>
                  <div>前往微信公众平台完成微信小程序注册和微信认证</div>
                </div>
              </template>
            </el-step>
            <el-step>
              <template slot="description">
                <div style="margin-top: 10px">
                  <div style="color: rgba(0, 0, 0, 1);">开通达人矩阵系统</div>
                  <div>自行付费开通达人矩阵系统或联系商务人员线下开通</div>
                </div>
              </template>
            </el-step>
            <el-step>
              <template slot="description">
                <div style="margin-top: 10px">
                  <div style="color: rgba(0, 0, 0, 1);">提交审核与发布</div>
                  <div>小程序授权视客联盟系统，提交代码发布上线</div>
                </div>
              </template>
            </el-step>
          </el-steps>
        </div>
      </div>

      <div v-if="userInfo.talent_matrix === true">
        <div class="home_container" style="background: rgba(255, 255, 255, 1);">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">达人矩阵</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in expertList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
          <el-divider></el-divider>
          <div style="display: flex;align-items: center;justify-content: space-between">
            <div style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">数据概况</div>
            <div>
              当前周期
              <el-date-picker
                  @change="datePickerChange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  size="small"
                  v-model="queryDate"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :picker-options="pickerOptions">
              </el-date-picker>
            </div>
          </div>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in profileList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
          <div id="myChart" style="width: 100%;height: 400px;margin-top: 50px"></div>
        </div>
      </div>


    </div>
    <div class="main_info_right">
      <div class="info_item" style="display: flex;flex-direction: column;justify-content: space-around">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div class="item_name">账号信息</div>
            <div  v-if="checkPermissions(1)" style="font-size: 14px;font-weight: 400;display: flex;align-items: center"  @click="merchantsInfoCustom">
               <span style="color: rgba(0, 119, 255, 1);font-size: 14px;margin-left: 10px;cursor: pointer">详情</span>
               <img src="../../assets/home/right.png" style="width: 20px;height: 20px" />
            </div>
          </div>
          <div style="margin-top:10px;font-size: 14px;font-weight: 500;">认证企业
            <span v-if="enterpriseCertInfo.certification_status === 0">
               <span style="margin-left: 10px; font-size: 14px;font-weight: 500;">未认证</span>
               <span style="margin-left: 10px; font-size: 14px;font-weight: 500;color: rgba(0, 119, 255, 1);cursor: pointer" @click="authenticationProtocol">去认证</span>
            </span>
            <span v-else-if="enterpriseCertInfo.certification_status === 4">
               <span style="margin-left: 10px; font-size: 14px;font-weight: 500;">认证失败</span>
               <span style="margin-left: 10px; font-size: 14px;font-weight: 500;color: rgba(0, 119, 255, 1);cursor: pointer" @click="merchantsInfoCustom">查看详情</span>
            </span>
            <span style="margin-left: 10px; font-size: 14px;font-weight: 500;" v-else>
                {{ enterpriseCertInfo.company_name }}
            </span>
          </div>
          <div style="margin-top:10px;font-size: 14px;font-weight: 500;">当前账号
            <span v-if="memberInfo">
              <span style="margin-left: 10px;">{{ memberInfo.mobile_phone | phoneDesensitization}}</span>
              <span style="margin-left: 10px;padding:5px;border-radius: 8px; background: rgba(0, 119, 255, 1);color: rgba(255, 255, 255, 1);font-size: 12px;font-weight: 400;">{{memberInfo.role_name}}</span>
            </span>
            <span v-else>
              <span style="margin-left: 10px;">{{ userInfo.mobile_phone | phoneDesensitization}}</span>
              <span style="margin-left: 10px;padding:5px;border-radius: 8px; background: rgba(0, 119, 255, 1);color: rgba(255, 255, 255, 1);font-size: 12px;font-weight: 400;">超级管理员</span>
            </span>
          </div>
      </div>

      <div class="info_item" style="margin-top: 10px">
        <div style="width: 100%; border-radius: 6px;height: 56px;display: flex;justify-content: space-between;padding-left: 5%;padding-right: 5%;">
          <div style="display: flex;align-items: center; color: rgba(56, 56, 56, 1);">
            <span class="item_name">
              公告
            </span>
          </div>
          <div style="display: flex;align-items: center;">
            <span style="color: rgba(0, 119, 255, 1);font-size: 14px;margin-left: 10px;cursor: pointer" @click="rulesCenter()">规则中心</span>
            <img src="../../assets/home/right.png" style="width: 20px;height: 20px" />
          </div>
        </div>

        <el-empty description="暂无公告～"></el-empty>
      </div>

      <div class="info_item" style="margin-top: 10px">
        <div style="display: flex;justify-content: space-between;align-items: center">
          <div class="item_name">教程与帮助</div>
          <div style="font-size: 14px;font-weight: 400;">
            <el-popover
                placement="left"
                width="200"
                trigger="hover">
              <div style="text-align: center">
                <img src="../../assets/home/wechat_service.jpg" style="width: 134px;height: 134px" />
                <div style="font-size: 14px;font-weight: 400;color: rgba(0, 0, 0, 1);">打开「微信」扫一扫</div>
                <div style="font-size: 12px;font-weight: 400;color: rgba(128, 128, 128, 1);margin-top: 5px">添加客服微信</div>
              </div>
              <el-button slot="reference" type="text">联系客服</el-button>
            </el-popover>
          </div>
        </div>
        <div class="right_item_view" @click="ai_document">
          <div>Ai云剪辑系统使用文档</div>
          <div style="margin-top: 10px; font-size: 12px;font-weight: 400;color: rgba(166, 166, 166, 1);">15分钟学会制作视频模版，日产1000条短视频，涉及系统功能使用和剪辑案例教学</div>
        </div>
        <div class="right_item_view" @click="jz_document">
          <div>达人矩阵系统使用文档</div>
          <div style="margin-top: 10px; font-size: 12px;font-weight: 400;color: rgba(166, 166, 166, 1);">从系统搭建到上线运营，更有会员分销系统、裂变系统、任务系统等多场景的触达到用户</div>
        </div>
      </div>


    </div>
  </div>
</div>
</template>

<script>
import {merchantsInfo, creatorUser, cloudInfo ,enterpriseCertInfo} from "../../api/merchantsUser";
import { homeClipInfo ,homeExpertInfo ,homeProfileData } from "../../api/statistics";
import store from "../../store";

export default {
  name: "homeInfoCustom",
  filters:{
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else if(fileSize < (1024 * 1024 * 1024 * 1024)){
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'TB';
      }
    },
    phoneDesensitization(value){
      if(value==null){
        value = ''
      }
      let char = '*'
      return value.replace(/(\d{3})\d*(\d{4})/, `$1${new Array(5).join(char)}$2`)
    },
    numFilter (value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    },
    numFilterOne (value) {
      let realVal = parseFloat(value).toFixed(1)
      return realVal
    },
    numFilterZeo (value) {
      let realVal = parseFloat(value).toFixed(0)
      return Number(realVal)
    }
  },
  data(){
    return{
      enterpriseCertInfo:{
        certification_status:null,
        company_name:''
      },
      surveyList:[],
      queryDate: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      clipList:[],
      expertList:[],
      profileList:[],
      tab_type:'1',
      userInfo:{},
      memberInfo: null,
      creatorUser:{},
      invitationCode:'',
      percentage:0,
      cloudInfo:{
        videoCloudSize: 0,
        materialCloudSize: 0,
        percentage: 0,
        useCloudSize: 0,
        cloudSize: 0
      }
    }
  },
  created(){
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.queryDate= [
      start,end
    ]
  },
  mounted(){
    this.initCloudInfo()
    this.initData()
    this.homeClipInfo()
    this.homeExpertInfo()
    this.homeProfileData()
    this.doEnterpriseCertInfo()
  },
  methods:{
    checkPermissions(id){
      let state = false
      if (store.state.ACCOUNT_CENTER_MENU != null){
        let children = store.state.ACCOUNT_CENTER_MENU.children
        children.forEach(function (item, index) {
          if(item.id === id){
            state = true
          }
        });
      }
      return  state;
    },
    merchantsInfoCustom(){
      this.$router.push("/merchantsInfoCustom");
    },
    authenticationProtocol(){
      this.$router.push("/authentication_protocol");
    },
    certificationDetails(){
      this.$router.push("/certification_details");
    },
    recertification(){
      this.$router.push("/upload_information");
    },
    doEnterpriseCertInfo(){
      enterpriseCertInfo({}).then((res) => {
        this.enterpriseCertInfo = res.data;
      });
    },
    datePickerChange(val) {
      console.log("datePickerChange")
      console.log(JSON.stringify(val))
      this.queryDate = val
      this.homeProfileData()
    },
    homeClipInfo(){
      homeClipInfo().then((res) => {
        this.clipList = res.data.list;
      });
    },
    homeExpertInfo(){
      homeExpertInfo().then((res) => {
        this.expertList = res.data.list;
      });
    },
    homeProfileData(){
      homeProfileData({
        queryDate: this.queryDate
      }).then((res) => {
        this.profileList = res.data.resDtoList;
        let legendData = res.data.legendData
        let xAxisData = res.data.xAxisData
        let seriesInfoDtoList = res.data.seriesInfoDtoList
        this.drawLine(legendData,xAxisData,seriesInfoDtoList);
      });
    },
    rulesCenter(){
      this.$router.push("/rules_center_user_agreement");
    },
    ai_document(){
      window.open('https://f0gc6xt9dy.feishu.cn/docx/doxcnJua3gf8N6HkKH5uuO4HaPc?from=from_copylink', '_blank');
    },
    jz_document(){
      window.open('https://f0gc6xt9dy.feishu.cn/docx/doxcnMvuCsKmD7hEHSD6tDBEcag?from=from_copylink', '_blank');
    },
    my_cloud_info(){
      this.$router.push("/my_cloud_info");
    },
    initCloudInfo(){
      cloudInfo().then((res)=>{
        this.cloudInfo = res.data
      })
    },
    cardResdCustom(){
      this.$router.push("/cardResdCustom");
    },
    initData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
        this.memberInfo = res.data.memberInfo;
      });
      creatorUser().then((res)=>{
        this.creatorUser = res.data
        this.invitationCode = 'https://vodmapi.paitk.com/api/creator/creatorUser/invitationCode?id=' + res.data.creatorUser.id + "&times=" + new Date().getTime()
        this.percentage = res.data.storageSpaceInfo.gFileSize/ res.data.storageSpaceInfo.storageSpace  * 100
      })
    },
    changeTabType(tab, event){
      console.log(tab, event);
    },
    drawLine(legendData,xAxisData,seriesInfoDtoList){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        title: { text: '' },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: seriesInfoDtoList
      });
    }
  }
}
</script>

<style scoped>


.main_container{
  height: 100%;
  padding-left: 100px;
  padding-top: 10px;
  padding-right: 100px;
}

.main_info{
  display: flex;
}

.main_info_left{
  width: 60%;
}

.main_info_right{
  width: 40%;
  margin-left: 10px;
}

.info_item{
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}


.item_name{
  font-size: 20px;
  font-weight: 500;
}

.left_item_view{
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  margin: 5px;
  padding: 10px;
}

.right_item_view{
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  padding: 10px;
  margin-top: 10px;
}


.home_container {
  background: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.flex_container {
  display: flex;
  flex-wrap: wrap;
}

.flex_chid {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.flex_lable {
  color: rgba(128, 128, 128, 1);
  font-size: 14px;
}

.flex_value {
  color: rgba(56, 56, 56, 1);
  font-size: 36px;
  font-weight: bold;
}


</style>
