<template>
  <div style="padding: 10px;">
      <div style="background: rgba(255, 255, 255, 1);padding: 10px">
        精选联盟分佣设置
        <el-form style="margin-top: 10px" :inline="true"  ref="form" :model="selectionAlliance">
        <el-form-item label="达人分佣比例:">
          <el-input size="small" v-model.number="selectionAlliance.intelligent_commission_rate"></el-input>
        </el-form-item>
          <el-form-item>%</el-form-item>
         <el-form-item style="margin-left: 20px" label="经纪人分佣比例:">
            <el-input size="small" v-model.number="selectionAlliance.agent_commission_rate"></el-input>
          </el-form-item>
          <el-form-item>%</el-form-item>
          <el-form-item style="margin-left: 20px">
            <el-button size="small" type="primary" @click="update">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div style="margin-top: 10px;background: rgba(255, 255, 255, 1);padding: 10px;">
        <el-tabs v-model="activeName">
          <el-tab-pane label="结算订单" name="first">
            <TrillSettleOrders/>
          </el-tab-pane>
          <el-tab-pane label="结算后退款订单" name="second">
            <TrillRefundOrders/>
          </el-tab-pane>
        </el-tabs>
      </div>
  </div>
</template>

<script>
import TrillRefundOrders from "./trillRefund_orders";
import TrillSettleOrders from "./trillSettle_orders";
import {selectionAllianceInfo, selectionAllianceUpdate} from "../../api/easyExcel";

export default {
  components: {
    TrillRefundOrders,
    TrillSettleOrders
  },
  name: "index",
  data(){
    return{
      selectionAlliance:{
        intelligent_commission_rate: 0,
        agent_commission_rate: 0,
      },
      activeName:'first'
  }},
  mounted(){
    this.initData()
  },
  methods:{
    initData(){
      selectionAllianceInfo().then((res) => {
        this.selectionAlliance = res.data;
      });
    },
    update(){
      selectionAllianceUpdate(this.selectionAlliance).then(res=>{
        console.log(JSON.stringify(res))
        if (res.code == "0") {
          this.$message.success({
            duration: 1000,
            message: '分佣设置更新成功'
          });
        }else{
          this.$message.error(res.message);
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
