<template>
  <div class="content" style="background: white;padding: 10px;margin: 10px">
    <div>
      <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
      >新增创作脚本</el-button>
    </div>

    <el-table
        style="margin-top: 10px"
        :data="tableData"
        :loading="listLoading"
        border
    >
      <el-table-column align="center" prop="caption" label="标题"></el-table-column>
      <el-table-column align="center" prop="content" label="内容"></el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>
      <el-table-column align="center" prop="custom_sort" label="排序"></el-table-column>
      <el-table-column align="center" prop="is_show" label="是否展示">
        <template slot-scope="scope">
          <el-switch
              disabled
              v-model="scope.row.is_show"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="doDel(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="30%">

      <el-form  :model="form" :rules="rules" ref="ruleForm" label-width="100px" size="mini">
        <el-form-item label="脚本标题:" prop="caption">
          <el-input v-model="form.caption" placeholder="请输入脚本标题" ></el-input>
        </el-form-item>
        <el-form-item label="脚本内容:" prop="content">
          <el-input :autosize="autosize" type="textarea" v-model="form.content" placeholder="请输入脚本内容" clearable></el-input>
        </el-form-item>
        <el-form-item label="排序:" prop="content">
          <el-input-number
              style="width: 100%"
              :controls="true" :min="0"
              v-model="form.custom_sort" placeholder="请输入排序"></el-input-number>
        </el-form-item>
        <el-form-item label="是否展示:" prop="content">
          <el-switch
              v-model="form.is_show"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="subitForm('ruleForm')">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
import { list, saveOrUpdate ,del} from "../../api/product_material";

export default {
  name: "productScript",
  data(){
    return{
      autosize:{
        minRows: 1, maxRows: 10
      },
      queryParams:{
        content_type: 2,
        merchants_product_id:'',
        page: 1,
        limit: 5,
        sidx: "custom_sort",
        order: "desc"
      },
      tableData:[],
      total:0,
      listLoading: false,
      dialogTitle: '',
      dialogVisible: false,
      form: {
        content_type: 2
      }
    }
  },
  mounted() {
    this.queryParams.merchants_product_id  =  this.$route.query.id
    this.initData()
  },
  methods:{
    subitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let body = this.form
          body.merchants_product_id = this.queryParams.merchants_product_id
          body.content_type = 2
          saveOrUpdate(body).then((res) => {
            if(res.status == 1){
              this.form = {}
              this.$message.success("操作成功");
              this.initData();
              this.dialogVisible = false;
            }else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleAdd(){
      this.dialogTitle = "新增创作脚本"
      this.form = {
        is_show: true,
        content_type: 2
      }
      this.dialogVisible = true
    },
    edit(row){
      this.dialogTitle = "编辑创作脚本"
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    doDel(row){
      this.$confirm("确认删除该脚本?", "删除脚本", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        del({ id: row.id }).then((res) => {
          if(res.status == 1){
            this.form = {}
            this.dialogVisible = false;
            this.initData();
            this.$message.success("删除成功");
          }else {
            this.$message.error(res.message);
          }
        });
      })
          .catch(() => {});
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      list(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
