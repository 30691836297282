import service from "../request";

export function ctrClaifitionTemList(query) {
    return service({
        url: '/api/creator/ctrClaifitionTem/list',
        method: 'get',
        params: query
    })
}

export function ctrClaifitionTemAdd(data) {
    return service({
        url: '/api/creator/ctrClaifitionTem/add',
        method: 'post',
        data: data
    })
}

export function ctrClaifitionTemUpdate(data) {
    return service({
        url: '/api/creator/ctrClaifitionTem/update',
        method: 'post',
        data: data
    })
}

export function ctrClaifitionTemDel(data) {
    return service({
        url: '/api/creator/ctrClaifitionTem/del',
        method: 'post',
        data: data
    })
}
