<template>
  <div>
    <div class="flex-between">
      <el-button class="btn1" type="primary" size="small" @click="add()"
        >新增分类</el-button
      >
      <el-button class="btn1" type="primary" size="small" @click="bagck()"
        >返回</el-button
      >
    </div>

    <el-table
      ref="multipleTable"
      :data="titleList"
      style="width: 100%; margin: 20px 0px"
      :header-cell-style="tableHeaderCellStyle2"
      :cell-style="tableCellStyle2"
      @close="offDialog"
    >
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="名称"
      >
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="is_open"
        label="是否显示"
      >
        <!-- #default="{ row }" -->
        <template #default="{ row }">
          <div>{{ row.is_open === true ? "是" : "否" }}</div>
        </template>
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="create_time"
        label="创建时间"
      >
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="num"
        label="分类内容数"
      >
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="out_trade_no"
        label="操作"
      >
        <template #default="{ row }">
          <el-link :underline="false" type="primary" @click="edit(row)"
            >编辑</el-link
          >

          <el-link
            style="margin: 0 10px"
            :underline="false"
            type="primary"
            @click="delet(row.id)"
            >删除</el-link
          >
        </template>
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="out_trade_no"
        label="推拽"
      >
        <template>
          <img
            style="width: 16px; height: 16px"
            src="@/assets/helpCenter/pull.png"
            alt=""
          />
        </template>
      </el-table-column>
    </el-table>

    <!-- a分页 -->
    <div class="page">
      <div class="box" />
      <div class="box" />
      <div class="box box-right">
        <el-pagination
          class="page"
          background
          :current-page="formInline.pageNum"
          :page-sizes="[10, 20, 30]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formInline.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- b分页 -->

    <!-- 新增分类 -->
    <el-dialog
      :title="title"
      :visible.sync="AdddialogVisible"
      center
      width="35%"
    >
      <el-form
        size="small "
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input clearable v-model="ruleForm.name"></el-input>
        </el-form-item>

        <el-form-item label="是否显示" prop="is_open">
          <el-switch v-model="ruleForm.is_open"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="offDialog()">取 消</el-button>
        <el-button size="small" type="primary" @click="addDialog()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import {
  typeListApi,
  typeSaveApi,
  typeDeleteApi,
} from "@/api/help_component.js";

export default {
  name: "addType",
  components: {},
  props: {},
  data() {
    return {
      title: "",
      formInline: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },

      AdddialogVisible: false,
      titleList: [],
      ruleForm: {
        name: "",
        is_open: false,
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
      },
    };
  },

  created() {
    this.typeList();
  },
  methods: {
    // 删除
    async delet(ID) {
      console.log(ID);
      const res = await typeDeleteApi({ id: ID });
      if (res.status == 1) {
        this.typeList();
        this.$message.success("删除成功");
      } else {
        this.$message.error(res.message);
      }
      console.log(res);
    },

    //点击编辑
    edit(row) {
      this.title = "编辑分类";
      this.AdddialogVisible = true;
      this.ruleForm = JSON.parse(JSON.stringify(row));
      console.log(row);
    },

    //  保存或更新分类
    async typeSave() {
      const res = await typeSaveApi(this.ruleForm);
      if (res.status == 1) {
        this.typeList();
        this.offDialog();
        if (this.ruleForm.id) {
          this.$message.success("编辑成功");
        } else {
          this.$message.success("添加成功");
        }
      } else {
        this.$message.error(res.message);
      }
      //   console.log("保存或更新分类", res.status);
    },

    // 列表数据
    async typeList() {
      // total_page
      const res = await typeListApi({ ...this.formInline });
      console.log("头部tab", res.data.total_page);
      this.titleList = res.data.list;
      this.formInline.total = res.data.total_count;
    },

    add() {
      this.title = "新增分类";
      this.AdddialogVisible = true;
      this.ruleForm = {
        name: "",
        is_open: false,
      };
    },

    // 新增+编辑分类提交
    async addDialog() {
      await this.$refs.ruleForm.validate();
      this.typeSave();
      this.closeDialog();
    },

    bagck() {
      this.$emit("bagck");
    },
    // 取消弹框
    offDialog() {
      this.closeDialog();
      this.typeList();
    },

    // 关闭弹窗
    closeDialog() {
      this.AdddialogVisible = false;
      this.$refs.ruleForm.resetFields();
      // 表格置空
    },

    // a分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.formInline.pageSize = val;
      this.typeList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.formInline.pageNum = val;
      this.typeList();
    },
    // b分页

    // a表头样式 02
    tableHeaderCellStyle2({ columnIndex, rowIndex }) {
      // console.log(columnIndex)
      // console.log(rowIndex)
      if (columnIndex === 0) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "15px 0px  0 15px",
        };
      } else if (columnIndex === 5) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "0px 15px  15px 0",
        };
      } else {
        return { backgroundColor: "#f7f7f7" };
      }
    },

    tableCellStyle2({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          // backgroundColor: 'red',
          borderRadius: "20px 0px  0 20px",
        };
      } else if (columnIndex === 5) {
        return {
          // backgroundColor: '#red',
          borderRadius: "0px 20px  20px 0",
        };
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// 分页
.page {
  display: flex;
  justify-content: flex-end;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    border-radius: 5px;
    background-color: #fff; // 进行修改未选中背景和字体
    border: 1px solid #e5e5e5;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0077ff; // 进行修改选中项背景和字体
    color: #fff;
  }
}
</style>