import axios from 'axios';
import Vue from 'vue'
import router from "../router";
import { JSEncrypt } from 'jsencrypt'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 10000
})

// 新建一个JSEncrypt对象
// eslint-disable-next-line no-var
var encryptor = new JSEncrypt()
// 设置公钥
const publicKey =
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDxO0lLfjt/+bm61ff2HN2IfadcGBLMRujG3qS++AzZwkrZ7uSKNs9KxB5CjUEtIaJLB6U4UWx8vwb9A4yuYbfVSHWGFdd2AHFBosV1S/n8QwGF/2Rk1w/5a+gYvXN3Gdrlaoj9btXCP11VX4XMaTv5YJNp0HAzc7t4rY4puPGDAQIDAQAB'
encryptor.setPublicKey(publicKey) // publicKey为公钥

service.interceptors.request.use((config) => {
    config.headers['sign'] = encryptor.encrypt(
        `${{
            device: 'alliance',
            versionNumber: '1.0.0',
            timestamp: new Date().getTime()
        }}`
    )
    config.headers.MERCHANTS_TOKEN = localStorage.getItem('MERCHANTS_TOKEN')
    config.headers.CREATOR_TOKEN = localStorage.getItem('MERCHANTS_TOKEN')
    return config;
});

service.interceptors.response.use(
    (response) => {
        if (response.data.code == 401) {
            router.push("/login")
        }
        if (response.status == 200) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response.data);
        }
    },
    (error) => {
        Vue.prototype.$message({
            message: error.response.data.message,
            type: 'error',
            duration: 1000
        })
        return Promise.reject(error);
    }
);

export default service
