import service from "../../request";

export function tabs(query) {
    return service({
        url: '/api/merchants/videoJobRes/tabs',
        method: 'get',
        params: query
    })
}

export function list(query) {
    return service({
        url: '/api/merchants/videoJobRes/list',
        method: 'get',
        params: query
    })
}

export function auditPass(data) {
    return service({
        url: '/api/merchants/videoJobRes/auditPass',
        method: 'post',
        data: data
    })
}

export function auditRefuse(data) {
    return service({
        url: '/api/merchants/videoJobRes/auditRefuse',
        method: 'post',
        data: data
    })
}
