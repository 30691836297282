import service from "../request";

export function authorizerInfo(query) {
    return service({
        url: '/api/merchants/merchantsUser/authorizerInfo',
        method: 'get',
        params: query
    })
}

export function apiQueryAuth(query) {
    return service({
        url: '/api/merchants/oplatform/apiQueryAuth',
        method: 'get',
        params: query
    })
}


export function getVersioninfo(query) {
    return service({
        url: '/api/merchants/oplatform/getVersioninfo',
        method: 'get',
        params: query
    })
}

export function onlineTemplate(query) {
    return service({
        url: '/api/merchants/oplatform/onlineTemplate',
        method: 'get',
        params: query
    })
}

export function commit(query) {
    return service({
        url: '/api/merchants/oplatform/commit',
        method: 'get',
        params: query
    })
}

export function getQrcode(query) {
    return service({
        url: '/api/merchants/oplatform/getQrcode',
        method: 'get',
        params: query
    })
}

export function getWxccodeUnlimit(query) {
    return service({
        url: '/api/merchants/oplatform/getWxccodeUnlimit',
        method: 'get',
        params: query
    })
}


export function bindTester(query,data) {
    return service({
        url: '/api/merchants/oplatform/bindTester',
        method: 'post',
        params: query,
        data: data
    })
}

export function unbindTester(query,data) {
    return service({
        url: '/api/merchants/oplatform/unbindTester',
        method: 'post',
        params: query,
        data: data
    })
}

export function submitAudit(query) {
    return service({
        url: '/api/merchants/oplatform/submitAudit',
        method: 'get',
        params: query
    })
}

export function release(query) {
    return service({
        url: '/api/merchants/oplatform/release',
        method: 'get',
        params: query
    })
}

export function getLatestAuditstatus(query) {
    return service({
        url: '/api/merchants/oplatform/getLatestAuditstatus',
        method: 'get',
        params: query
    })
}

