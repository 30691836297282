import VideoJobLayout from "../views/layout/VideoJobLayout";
import video_job_gk from "../views/video_job/gk";
import video_job_zy from "../views/video_job/zy";
import video_job_sh from "../views/video_job/sh";
import video_job_trill_list from "../views/video_job/trill_list";
import video_job_add from "../views/video_job/add";
import video_job_tutorial from "../views/video_job/videoJobTutorial";

const template = [
    {
        path:"/video_job_gk",
        name:'概况',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: VideoJobLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/video_job_gk",
                name:'概况',
                component: video_job_gk,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'video_job'
                    }
                }
            }
        ]
    },
    {
        path:"/video_job_zy",
        name:'视频作业',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: VideoJobLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/video_job_zy",
                name:'视频作业',
                component: video_job_zy,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'video_job'
                    }
                }
            },
            {
                path:"/video_job_add",
                name:'新增视频作业',
                isHidden : true,
                component: video_job_add,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'video_job'
                    }
                }
            },
        ]
    },
    {
        path:"/video_job_sh",
        name:'作业审核',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: VideoJobLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/video_job_sh",
                name:'作业审核',
                component: video_job_sh,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'video_job'
                    }
                }
            }
        ]
    },
    {
        path:"/video_job_trill_list",
        name:'抖音号列表',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: VideoJobLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/video_job_trill_list",
                name:'抖音号列表',
                component: video_job_trill_list,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'video_job'
                    }
                }
            }
        ]
    },
    {
        path:"/video_job_tutorial",
        name:'如何领取视频',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: VideoJobLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/video_job_tutorial",
                name:'如何领取视频',
                component: video_job_tutorial,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'video_job'
                    }
                }
            }
        ]
    }
]

export default template;
