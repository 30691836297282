import service from "../request";

export function list(query) {
    return service({
        url: '/api/merchants/buildRecFeedback/list',
        method: 'get',
        params: query
    })
}

export function state(query) {
    return service({
        url: '/api/merchants/buildRecFeedback/state',
        method: 'get',
        params: query
    })
}

export function auditPass(data) {
    return service({
        url: '/api/merchants/buildRecFeedback/auditPass',
        method: 'post',
        data: data
    })
}

export function auditDismiss(data) {
    return service({
        url: '/api/merchants/buildRecFeedback/auditDismiss',
        method: 'post',
        data: data
    })
}
