<template>
  <div style="background: white;padding: 10px;margin: 10px">

  <el-button size="small" type="primary" @click="addExchangeCode">生成会员兑换码</el-button>

  <el-tabs size="small" v-model="queryParams.exchangeStatus" @tab-click="handleClick">
    <el-tab-pane label="未兑换" name="0"></el-tab-pane>
    <el-tab-pane label="已兑换" name="1"></el-tab-pane>
    <el-tab-pane label="已作废" name="2"></el-tab-pane>
  </el-tabs>

    <div>
      <el-input size="small" placeholder="请输入会员兑换码" v-model="queryParams.id" clearable style="width: 160px;margin-left: 10px"></el-input>
      <el-input size="small" placeholder="请输入所属经纪人昵称/手机号" v-model="queryParams.agentSearch" clearable style="width: 220px;margin-left: 10px"></el-input>

      <span v-if="queryParams.exchangeStatus === '0' ">
      <el-date-picker
          size="small"
          style="margin-left: 10px"
          v-model="queryParams.minCreateTime"
          default-time="00:00:00"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="生成时间">
      </el-date-picker>
      <i  style="margin-left: 5px" class="el-icon-minus"></i>
      <el-date-picker
          size="small"
          style="margin-left: 10px"
          v-model="queryParams.maxCreateTime"
          default-time="23:59:59"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="生成时间">
      </el-date-picker>
      </span>


      <span v-if="queryParams.exchangeStatus === '1' ">
        <el-input size="small" placeholder="请输入兑换人昵称/手机号" v-model="queryParams.userSearch" clearable style="width: 200px;margin-left: 10px"></el-input>
        <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="queryParams.minExchangeTime"
            default-time="00:00:00"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="兑换时间">
        </el-date-picker>
        <i  style="margin-left: 5px" class="el-icon-minus"></i>
        <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="queryParams.maxExchangeTime"
            default-time="23:59:59"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="兑换时间">
        </el-date-picker>
      </span>

      <span v-if="queryParams.exchangeStatus === '2' ">
        <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="queryParams.minCancelTime"
            default-time="00:00:00"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="作废时间">
        </el-date-picker>
        <i  style="margin-left: 5px" class="el-icon-minus"></i>
        <el-date-picker
            size="small"
            style="margin-left: 10px"
            v-model="queryParams.maxCancelTime"
            default-time="23:59:59"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="作废时间">
        </el-date-picker>
      </span>

      <el-button type="primary" icon="el-icon-search" size="small" @click="search()" style="margin-left: 10px">搜索</el-button>
    </div>

    <el-table
        size="small"
        :data="tableData"
        :loading="listLoading"
        border
        style="width: 100%;margin-top: 10px"
        :max-height="autoHeight"
    >
      <el-table-column align="center" prop="id" label="会员兑换码" >
      </el-table-column>
      <el-table-column align="center" prop="sklm_members_level" label="会员等级" >
        <template slot-scope="scope">
          {{scope.row.sklm_members_level.members_name}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="gen_card_quota" label="会员有效期" >
        <template slot-scope="scope">
          {{formattingEffectiveDays(scope.row.effective_days)}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="gen_card_quota" label="视频生成卡数量" >
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="生成时间">
      </el-table-column>
      <el-table-column align="center" prop="agent_id" label="所属经纪人" >
        <template slot-scope="scope">
          <div style="display: flex;justify-content: center;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.agent_avatar_url"
                fit="cover">
            </el-image>
            <div style="margin-left: 10px; text-align: left">
              <div>{{scope.row.agent_nick_name}}</div>
              <div>{{scope.row.agent_phone_number}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="exchange_time" label="兑换时间"  v-if="queryParams.exchangeStatus === '1' ">
      </el-table-column>
      <el-table-column align="center" prop="open_id" label="兑换人"  v-if="queryParams.exchangeStatus === '1' ">
        <template slot-scope="scope">
          <div style="display: flex;justify-content: center;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.wxuser_avatar_url"
                fit="cover">
            </el-image>
            <div style="margin-left: 10px; text-align: left">
              <div>{{scope.row.wxuser_nick_name}}</div>
              <div>{{scope.row.wxuser_phone_number}}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="exchange_time" label="作废时间" v-if="queryParams.exchangeStatus === '2' ">
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作" v-if="queryParams.exchangeStatus === '0' ">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="doExchangeCodeCancel(scope.row)">作废</el-button>
        </template>
      </el-table-column>

    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        size="small"
        title="生成会员兑换码"
        :visible.sync="dialogVisible"
        width="30%">

      <el-form size="small" :model="form"   :rules="rules" ref="ruleForm" label-width="120px">
        <el-form-item label="会员等级:" required prop="membersLevelId">
          <el-select style="width: 100%" v-model="form.membersLevelId" placeholder="请选择">
            <el-option
                v-for="item in membersLevelList"
                :key="item.id"
                :label="item.members_name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="生成数量:" required prop="quantity">
          <el-input-number v-model.number="form.quantity" :min="0" :max="999" controls-position="right" style="width: 100%" />
        </el-form-item>
        <el-form-item label="经纪人用户ID:" required prop="agentId">
          <el-input v-model="form.agentId" placeholder="请输入经纪人的用户 openId"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="doExchangeCodeAdd('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>

</div>
</template>

<script>

import {sklmMembersLevelList, exchangeCodeList, exchangeCodeAdd ,exchangeCodeCancel} from "../../api/sklmUser";
import {wxUserLabelDel} from "../../api/userManage";

export default {
  name: "exchangeCode",
  data() {
    return {
      membersLevelList:[],
      rules: {
        membersLevelId: [
          { required: true, message: '请选择会员等级', trigger: 'change' },
        ],
        quantity: [
          { required: true, message: '请输入生成数量', trigger: 'blur' },
        ],
        agentId: [
          { required: true, message: '请输入经纪人用户ID', trigger: 'blur' },
        ]
      },
      form:{
        membersLevelId: '',
        quantity: 1,
        agentId: null
      },
      dialogVisible: false,
      listLoading:false,
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        exchangeStatus: '0',
        page:1,
        limit:5,
        sidx: 'mmec.create_time',
        order: 'desc',
        id:'',
        agentSearch: '',
        userSearch:'' ,
        minCreateTime: '',
        maxCreateTime: '',
        minExchangeTime: '',
        maxExchangeTime: '',
        minCancelTime: '',
        maxCancelTime: ''
      },
      tableData:[],
      total:0
    };
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.sklmMembersLevelList()
    this.initData()
  },
  methods: {
    doExchangeCodeCancel(row){
      this.$confirm("确认作废该兑换码?", "作废兑换码", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
            exchangeCodeCancel({ id: row.id }).then((res) => {
              if (res.code == "0") {
                this.$message({
                  type: "success",
                  message: "作废成功!",
                });
                this.initData();
              }
            });
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    doExchangeCodeAdd(formName){
      console.log(JSON.stringify(this.form))
        this.$refs[formName].validate((valid) => {
          if (valid) {
            exchangeCodeAdd(this.form).then(res=> {
              if(res.code==0) {
                this.$message.success({
                  duration: 1000,
                  message:  '生成成功'
                });
                this.initData()
                this.dialogVisible = false
              }else{
                this.$message.error({message: res.message});
              }
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        })
    },
    sklmMembersLevelList() {
      let queryParams = {
        page : 1,
        limit : 100
      }
      sklmMembersLevelList(queryParams).then((response) => {
        let membersLevelList = response.data.list;
        this.membersLevelList = membersLevelList;
        if(membersLevelList != 0){
          this.form.membersLevelId = membersLevelList[0].id
        }
      });
    },
    addExchangeCode(){
      this.form.agentId = null
      this.dialogVisible = true
      this.$refs['ruleForm'].resetFields();
    },
    initQuery(){
      this.queryParams.page = 1
      this.queryParams.id = ''
      this.queryParams.agentSearch = ''
      this.queryParams.userSearch = ''
      this.queryParams.minCreateTime = ''
      this.queryParams.maxCreateTime = ''
      this.queryParams.minExchangeTime = ''
      this.queryParams.maxExchangeTime = ''
      this.queryParams.minCancelTime = ''
      this.queryParams.maxCancelTime = ''
      this.initData()
    },
    search(){
      this.queryParams.page = 1
      this.initData()
    },
    formattingEffectiveDays(effectiveDays){
      console.log("effectiveDays:" + effectiveDays)
      if(effectiveDays>=365){
        return effectiveDays/365 + "年"
      }
      if(effectiveDays>=30){
        return effectiveDays/30 + "月"
      }
      return effectiveDays + '天'
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    handleClick(tab, event) {
      let queryParams = this.queryParams
      let active = tab.index
      if(active=== '0'){
        queryParams.sidx = 'mmec.create_time'
      }else if(active === '1'){
        queryParams.sidx = 'mmec.exchange_time'
      }else{
        queryParams.sidx = 'mmec.exchange_time'
      }
      this.initQuery()
    },
    initData(){
      exchangeCodeList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
      });
    }
  }
}
</script>

<style scoped>

</style>
