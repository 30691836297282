<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <el-input clearable size="small" placeholder="请输入模板id" v-model="queryParams.id"  style="width: 200px;margin-left: 10px">
          <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
        </el-input>
      </el-col>
    </el-row>


    <el-table
        size="small"
        :data="tableData"
        style="width: 100%">
      <el-table-column
          label="文件名">
        <template slot-scope="scope">
            <div style="display: flex;flex-direction: row;align-items: center;">
              <img class="point" src="../../assets/material/fidder.png" style="width: 40px;height: 40px;cursor: pointer;" @click="tem_video_list(scope.row)"/>
              <div class="point" style="padding: 10px;cursor: pointer;" @click="tem_video_list(scope.row)">
                <div>模板ID:{{scope.row.id }}</div>
                <div>模板名称:{{scope.row.name }}</div>
              </div>
            </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="创建时间">
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { buildTemList } from "../../api/mediaProducing";
import {mapMutations} from 'vuex';

export default {
  name: "my_cloud_clip_video",
  data(){
    return{
      multipleSelection:[],
      queryParams:{
        page: 1,
        limit: 5,
        sidx: 'create_time',
        order: 'desc',
        id: ''
      },
      total:0,
      tableData: []
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    ...mapMutations(['GLOBAL_TEM_ID']),
    doSearch(){
      this.queryParams.page = 1
      this.initData();
    },
    tem_video_list(row){
      this.GLOBAL_TEM_ID(row.template_id)
      this.$router.push("/tem_video_list");
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true;
      buildTemList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>
  .point:hover{
    opacity: 0.8;
    color: dodgerblue;
  }
</style>
