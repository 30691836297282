<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div style="display: flex;justify-content: space-between;align-items: center">
      <div>
        <el-input
            size="small"
            placeholder="昵称"
            v-model="queryParams.nick_name"
            clearable
            style="width: 120px;"
        >
        </el-input>
        <el-input
            size="small"
            placeholder="手机号"
            v-model="queryParams.phone_number"
            clearable
            style="width: 120px;margin-left: 10px"
        >
        </el-input>
        <el-button
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="initData()"
        >搜索</el-button>
      </div>

      <div style="display: flex;align-items: center;text-align: center">
        <div>是否开启"申请会员"功能:</div>
        <el-switch
            @change="auditSwitchChange"
            style="margin-left: 10px"
            v-model="audit_switch"
            active-color="#13ce66"
            inactive-color="#ff4949">
        </el-switch>
      </div>

    </div>


    <div style="margin-top: 10px">
      <el-button
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="batchPassDialog()"
      >批量通过</el-button>

      <el-button
          type="danger"
          icon="el-icon-search"
          size="small"
          @click="batchRejectDialog()"
      >批量驳回</el-button>
    </div>



    <el-table
        ref="multipleTable"
        :data="tableData"
        :loading="listLoading"
        border
        style="width: 100%;margin-top: 10px"
        :max-height="autoHeight"
        @selection-change="handleSelectionChange"
    >
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>

      <el-table-column align="center" prop="open_id" label="用户ID" >
      </el-table-column>

      <el-table-column align="center" prop="name" label="头像">
        <template slot-scope="scope">
          <div v-if="scope.row.avatar_url !== ''">
            <el-image
                style="width: 50px;height: 50px"
                :src="scope.row.avatar_url"
                fit="cover">
            </el-image>
          </div>
          <div v-else>
            尚未授权
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="nick_name" label="昵称">
        <template slot-scope="scope">
          <div v-if="scope.row.nick_name !== ''">
            {{scope.row.nick_name}}
          </div>
          <div v-else>
            尚未授权
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="phone_number" label="手机号">
        <template slot-scope="scope">
          <div v-if="scope.row.phone_number !== ''">
            {{scope.row.phone_number}}
          </div>
          <div v-else>
            尚未授权
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="apply_reason" label="申请理由">
      </el-table-column>

      <el-table-column prop="create_time" label="申请时间">
      </el-table-column>


      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="passDialog(scope.row)">审核通过</el-button>
          <el-button type="text" size="small" @click="rejectDialog(scope.row)">审核驳回</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page_no"
                    :page-size="page_size"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        :title="passDialogTitle"
        :visible.sync="passDialogVisible"
        width="30%">
      <el-form label-width="130px">
        <el-form-item label="用户等级:">
          <el-select v-model="membersLevelId" placeholder="请选择">
            <el-option
                v-for="item in membersLevelList"
                :key="item.id"
                :label="item.members_name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
         <span slot="footer" class="dialog-footer">
          <el-button @click="passDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doPass">确 定</el-button>
        </span>
    </el-dialog>

    <el-dialog
        :title="rejectDialogTitle"
        :visible.sync="rejectDialogVisible"
        width="30%">
      <el-input
          type="textarea"
          :rows="6"
          maxlength="100"
          show-word-limit
          placeholder="请输入驳回原因"
          v-model="dismissReason">
      </el-input>
      <span slot="footer" class="dialog-footer">
          <el-button @click="rejectDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="doReject">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  sklmMembersAuditList,
  pass,
  reject,
  sklmMembersLevelList,
  generateLimitGet,
  generateLimitSaveOrUpdate
} from "../../api/sklmUser";

export default {
  name: "userCheck",
  data(){
    return{
      audit_switch: false,
      multipleSelection:[],
      auditIds:[],
      passDialogTitle:'',
      rejectDialogTitle:'',
      dismissReason:'',
      rejectDialogVisible:false,
      membersLevelId: null,
      passDialogVisible: false,
      membersLevelList:[],
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        nick_name:'',
        phone_number:'',
        audit_status: 0,
        sidx : "sma.create_time",
        order : "desc",
      },
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 100
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
    this.sklmMembersLevelList()
    this.generateLimitGet()
  },
  methods:{
    auditSwitchChange(){
      let body = {
        audit_switch: this.audit_switch
      }
      generateLimitSaveOrUpdate(body).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message:  '修改成功'
          });
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    generateLimitGet(){
      generateLimitGet({}).then(res=> {
        if(res.data != null) {
          this.audit_switch = res.data.audit_switch
        }
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    batchPassDialog(){
      if(this.multipleSelection.length == 0){
        this.$message.error("请勾选有效的数据");
        return;
      }
      console.log(JSON.stringify(this.multipleSelection))
      this.auditIds = this.multipleSelection.map(item => {
        return item.id;
      });
      this.passDialogTitle = "批量审核通过"
      this.passDialogVisible = true
    },
    batchRejectDialog(){
      if(this.multipleSelection.length == 0){
        this.$message.error("请勾选有效的数据");
        return;
      }
      console.log(JSON.stringify(this.multipleSelection))
      this.auditIds = this.multipleSelection.map(item => {
        return item.id;
      });
      this.rejectDialogTitle = "批量审核驳回"
      this.rejectDialogVisible = true
    },
    passDialog(row){
      this.auditIds = []
      this.auditIds.push(row.id)
      this.passDialogTitle = "审核通过"
      this.passDialogVisible = true
    },
    rejectDialog(row){
      this.auditIds = []
      this.auditIds.push(row.id)
      this.rejectDialogTitle = "审核驳回"
      this.rejectDialogVisible = true
    },
    doPass(){
      console.log(JSON.stringify(this.auditIds))
      if(this.membersLevelId ==null || this.membersLevelId == ''){
        this.$message.error("请选择有效的会员身份");
        return;
      }
      pass({},{
        membersLevelId: this.membersLevelId,
        auditIds:this.auditIds
      }).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '操作成功'
          });
          this.initData()
          this.passDialogVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    doReject(){
      console.log(JSON.stringify(this.auditIds))
      if(this.dismissReason ==null || this.dismissReason == ''){
        this.$message.error("请输入有效的驳回理由");
        return;
      }
      reject({},{
        dismissReason:this.dismissReason,
        auditIds:this.auditIds
      }).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '操作成功'
          });
          this.initData()
          this.rejectDialogVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    sklmMembersLevelList() {
          let queryParams = {
            page : 1,
            limit : 100
          }
          sklmMembersLevelList(queryParams).then((response) => {
            let membersLevelList = response.data.list;
            this.membersLevelList = membersLevelList;
            if(membersLevelList != 0){
              this.membersLevelId = membersLevelList[0].id
            }
          });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams
          queryParams.page = this.page_no,
          queryParams.limit = this.page_size,
              sklmMembersAuditList(queryParams).then((response) => {
            this.tableData = response.data.list;
            this.total = response.data.total_count;
            this.listLoading = false;
          });
    },
  }
}
</script>

<style scoped>

</style>
