<template>
  <div>
    <el-button size="mini" type="primary" @click="dialogVisible =true">调整IP分成</el-button>
    <el-table
        Style="margin-top: 10px"
        border
        :data="tableData">
      <el-table-column align="center" label="IP">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center;">
            <img
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.classify_icon"
                fit="cover"/>
            <div style="margin-left: 10px">{{scope.row.classify_name}}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="share_ratio" label="分成比例">
        <template slot-scope="scope">
          {{scope.row.share_ratio | numberFormat}}
        </template>
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>

    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

    <el-dialog
        title="调整IP分成"
        :visible.sync="dialogVisible"
        width="40%" append-to-body>
      <el-form  :model="form" :rules="rules" ref="ruleForm" label-width="100px" size="mini">
        <el-form-item label="请选择IP:" prop="classify_id">
          <el-select v-model="form.classify_id" placeholder="请选择" style="width: 100%">
            <el-option
                v-for="item in options"
                :key="item.classify_id"
                :label="item.classify_name"
                :value="item.classify_id">
              <div style="display: flex;align-items: center;justify-content: space-between">
                <div>{{ item.classify_name }}</div>
                <img
                    style="width: 20px;height: 20px;border-radius: 50%"
                    :src="item.classify_icon"
                    fit="cover"/>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分成比例:" prop="quota">
          <el-input-number
              style="width: 100%"
              :controls="false" :precision="2" :min="0" :max="100"
              v-model="form.share_ratio" placeholder="请输入分成比例"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="subitForm('ruleForm')">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>

import {classifyLimit,  saveOrUpdate} from "../../../api/classify_limit";

export default {
  name: "ClassifyLimit",
  components: {
  },
  props: {
    agentInfo: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  filters: {
    numberFormat: function (value) {
      return value.toFixed(2)
    }
  },
  data(){
    return{
      queryParams:{
        agent_id: '',
        page: 1,
        limit: 10
      },
      total:0,
      listLoading:false,
      tableData: [],
      options: [],
      rules:{
        classify_id: [
          { required: true, message: '请选择IP', trigger: 'change' }
        ],
        share_ratio: [
          { required: true, message: '请输入分成比例', trigger: 'blur' },
        ]
      },
      form:{
        agent_id: '',
        classify_id: '',
        share_ratio: 0.00
      },
      dialogVisible:false,
      activeName: 'first'
    }
  },
  mounted(){
    this.queryParams.agent_id = this.agentInfo.open_id
    this.form.agent_id = this.agentInfo.open_id
    this.initData()
    this.initOptions()
  },
  methods:{
    edit(row){
      this.form = row
      this.dialogVisible = true
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      classifyLimit(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
    subitForm(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(JSON.stringify(this.form))
          let body = {
            agent_id: this.form.agent_id,
            classify_id: this.form.classify_id,
            share_ratio: this.form.share_ratio
          }
          console.log(body)
          saveOrUpdate(body).then((res) => {
            if(res.status == 1){
              this.$message.success("操作成功")
              this.initData();
              this.dialogVisible = false
            }else {
              this.$message.error(res.message);
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    initOptions(){
      let queryParams = {
        page: 1,
        limit: 999999
      }
      classifyLimit(queryParams).then((res) => {
        this.options = res.data.list;
      });
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog__body{
  padding-top: 0px;
}
</style>
