import service from "../request";

export function merchantsConfigUpdate(data) {
    return service({
        url: '/api/merchants/config/update',
        method: 'post',
        data: data
    })
}

export function merchantsConfig(query) {
    return service({
        url: '/api/merchants/config/info',
        method: 'get',
        params: query
    })
}
