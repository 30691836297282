<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <div class="div_title">{{ title }}</div>

    <el-form
      :model="form"
      :rules="rules"
      :label-position="labelPosition"
      ref="ruleForm"
      label-width="150px"
      size="small"
    >
      <div class="child_title">基础信息</div>
      <el-form-item prop="class_name" required label="课程名称:">
        <el-input
          style="width: 600px"
          v-model="form.class_name"
          maxlength="30"
          show-word-limit
          placeholder="请输入课程名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="cover" required label="封面图片: ">
        <el-upload
          class="avatar-uploader"
          action=""
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :http-request="uploadImg"
        >
          <img v-if="form.cover" :src="form.cover" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <span style="color: rgba(166, 166, 166, 1); font-size: 12px"
          >最多上传5张图片，建议上传尺寸为800*800px的jpg、png图片</span
        >
      </el-form-item>
      <el-form-item prop="sell_price" required label="售卖价格">
        <el-input
          style="width: 100px"
          v-model="form.sell_price"
          placeholder="请输入售卖价格"
          clearable
        ></el-input>
        <span style="margin-left: 10px">元</span>
      </el-form-item>
      <el-form-item prop="agent_income" required label="分销设置">
        <span style="font-size: 14px; font-weight: 400; color: rgba(0, 0, 0, 1)"
          >经纪人收益</span
        >
        <el-input
          style="margin-left: 10px; width: 100px"
          v-model="form.agent_income"
          placeholder="请输入"
          clearable
        ></el-input>
        <span style="margin-left: 10px">元</span>
      </el-form-item>
      <el-form-item required label="会员免费">
        <el-select
          v-model="form.free_member_ids"
          multiple
          clearable
          placeholder="所有会员不免费"
        >
          <el-option
            v-for="item in freeMemberOptions"
            :key="item.id"
            :label="item.members_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="virtual_sales" required label="虚拟销量">
        <el-input
          style="width: 190px"
          v-model="form.virtual_sales"
          placeholder="请输入虚拟销量"
          clearable
        ></el-input>
        <span
          style="
            margin-left: 5px;
            color: rgba(166, 166, 166, 1);
            font-size: 12px;
          "
          >如设置虚拟销量100，实际销量50，则展示给用户看到的在学人数为150</span
        >
      </el-form-item>
      <el-form-item prop="custom_sort" required label="排序">
        <el-input
          style="width: 100px"
          v-model="form.custom_sort"
          placeholder="请输入序号"
          clearable
        ></el-input>
        <span
          style="
            margin-left: 5px;
            color: rgba(166, 166, 166, 1);
            font-size: 12px;
          "
          >序号越高排序越前，若序号相同则创建时间越晚排序越前</span
        >
      </el-form-item>
      <el-form-item required label="课程详情">
        <div
          ref="editor"
          @input="editorChange"
          style="margin-top: 20px; width: 100%"
        />
      </el-form-item>
      <div class="child_title">授课信息</div>
      <el-form-item prop="teacher" required label="授课老师">
        <el-select v-model="form.teacher" multiple placeholder="请选择">
          <el-option
            v-for="item in teacherList"
            :key="item.teacher_id"
            :label="item.nick_name"
            :value="item.teacher_id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item required label="课程有效期">
        <el-radio-group v-model="form.effective">
          <div>
            <el-radio :label="3"
              >购买后
              <el-input
                style="margin-left: 5px; width: 100px"
                v-model="form.valid_days"
                placeholder="请输入"
                clearable
              ></el-input>
              内有效
            </el-radio>
          </div>
          <div style="margin-top: 10px">
            <el-radio :label="2">
              截止
              <el-date-picker
                style="margin-left: 5px"
                v-model="form.valid_time"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
              前有效
            </el-radio>
          </div>
          <div style="margin-top: 10px">
            <el-radio :label="1">永久有效</el-radio>
          </div>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="class_hours_num" required label="预计更新课时">
        <el-input
          style="width: 120px"
          v-model="form.class_hours_num"
          placeholder="请输入课时"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item prop="update_com_time" required label="预计更新完成时间">
        <el-date-picker
          v-model="form.update_com_time"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <div class="child_title">售后保障</div>
      <el-form-item required label="无理由退款">
        <el-radio-group v-model="form.guarantee">
          <el-radio :label="1">不支持无理由退款</el-radio>
          <el-radio :label="2">结课前可退</el-radio>
          <el-radio :label="3">购后7天内，未学可退</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item>
        <el-button @click="goBack">取消</el-button>
        <el-button type="primary" @click="submit">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { courseClassInfo, saveOrUpdate } from "../../api/course/courseClass";
import { getOssAccessInfo } from "../../api/common";
import { courseTeacherList } from "../../api/course/courseTeacher";
import { sklmMembersLevelList } from "../../api/sklmUser";
var OSS = require("ali-oss");
import E from "wangeditor";
import Sha1 from "js-sha1";

export default {
  name: "video_guest_info",
  data() {
    return {
      labelPosition: "right",
      course_class_id: -1,
      title: "新建视频课",
      form: {
        cover: "",
        custom_sort: 0,
        guarantee: 1,
        effective: 1,
        teacher: [],
        details: "",
      },
      rules: {
        class_name: [
          { required: true, message: "请输入课程名称", trigger: "blur" },
        ],
        cover: [
          { required: true, message: "请上传封面图片", trigger: "change" },
        ],
        sell_price: [
          { required: true, message: "请输入售卖价格", trigger: "blur" },
        ],
        agent_income: [
          { required: true, message: "请输入经纪人收益", trigger: "blur" },
        ],
        virtual_sales: [
          { required: true, message: "请输入虚拟销量", trigger: "blur" },
        ],
        custom_sort: [
          { required: true, message: "请输入排序", trigger: "blur" },
        ],
        class_hours_num: [
          {
            required: true,
            message: "请输入预计更新课时数量",
            trigger: "blur",
          },
        ],
        update_com_time: [
          {
            required: true,
            message: "请选择预计更新完成时间",
            trigger: "change",
          },
        ],
        teacher: [
          { required: true, message: "请选择授课老师", trigger: "change" },
        ],
      },
      freeMemberOptions: [],
      teacherList: [],
    };
  },
  mounted() {
    this.doSklmMembersLevelList();
    this.doCourseTeacherList();
    let course_class_id = this.$route.query.course_class_id;
    if (course_class_id != -1) {
      this.title = "编辑视频课";
      this.initData(course_class_id);
    }
    this.course_class_id = course_class_id;
  },
  methods: {
    initEditor() {
      var editor = new E(this.$refs.editor);
      editor.config.zIndex = 10;
      editor.config.customUploadImg = function (files, insert) {
        files.forEach((image) => {
          getOssAccessInfo().then((res) => {
            const client = new OSS({
              region: "oss-cn-hangzhou",
              accessKeyId: res.data.access_key_id,
              accessKeySecret: res.data.access_key_secret,
              bucket: "cjfx-res",
              stsToken: res.data.security_token,
            });
            let randomStr = "";
            for (var i = 0; i < 9; i++) {
              randomStr = randomStr + Math.floor(Math.random() * 10).toString();
            }
            let fileName =
              "/merchantscenter/course/" +
              Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
              image.type;
            console.log(fileName);
            client.put(fileName, image).then((res) => {
              console.log(JSON.stringify(res));
              let imgUrl =
                "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
              insert(imgUrl);
            });
          });
        });
      };
      editor.config.onchange = (html) => {
        this.form.details = html;
      };
      editor.create();
      editor.txt.html(this.form.details);
    },
    editorChange(e) {
      this.form.details = e.target.innerHTML;
    },
    doSklmMembersLevelList() {
      let queryParams = {
        page: 1,
        limit: 9999,
      };
      sklmMembersLevelList(queryParams).then((response) => {
        this.freeMemberOptions = response.data.list;
      });
    },
    doCourseTeacherList() {
      let queryParams = {
        page: 1,
        limit: 9999,
      };
      courseTeacherList(queryParams).then((res) => {
        this.teacherList = res.data.list;
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/course/class/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.form.cover = "https://store.paitk.com" + fileName;
          console.log(JSON.stringify(this.form));
          this.$message.success("头像上传成功");
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          saveOrUpdate(this.form).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    initData(course_class_id) {
      courseClassInfo({ id: course_class_id }).then((res) => {
        this.form = res.data;
        this.initEditor();
      });
    },
  },
};
</script>

<style scoped>
.div_title {
  font-size: 24px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

.child_title {
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}
</style>
