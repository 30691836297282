<template>
  <div style="padding: 20px">
  <div><p style="font-size:12pt; line-height:200%; margin:0pt; text-align:center"><span
      style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">充值服务协议</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">按照本协议的规定提供基于互联网的充值服务，为获得充值服务，服务使用人(以下称“用户”)同意本协议的全部条款并按照页面上的提示完成全部的充值程序。用户在进行充值程序过程中使用充值服务即表示用户完全接受本协议项下的全部条款。</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">用户在使用</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">提供的各项服务之前，应仔细阅读本服务协议，尤其是重点提示条款，如用户不同意本充值用户协议及/或对其的修改，请停止使用</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">提供的服务。</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">1. 服务内容</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">的充值系统提供多种第三方支付渠道的充值方式，包括但不限于支付宝、微信支付等不同的充值方式，具体充值方式根据用户自选需求及各投放渠道政策有所差异。用户使用该平台进行充值，可以为其使用的账号充值。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">2. 操作方法</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">2.1 用户可以在此充值系统上自由选择具体的充值方式，并按相应页面提示的程序完成充值。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">2.2 用户使用各种充值方式进行充值后，应保留充值订单号或</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">交易流水凭证</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">以作为今后发生问题核对依据凭证（用户就充值服务投诉，但未有</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">交易流水凭证</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">或其他有效凭证佐证的，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">将不会作出补偿或赔偿）。</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">3. 风险提示</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">3.1 在使用充值方式时，用户务必仔细确认自己的账号并仔细选择相关操作选项。若因为用户自身</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">登录</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">账号错误、操作不当或不了解充值计费方式等因素造成充错账号、错选充值种类等情形而损害自身权益的，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">将不会作补偿或赔偿。</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">3.2 若用户以非法的方式，或使用非</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">所指定的充值方式进行充值，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">不保证该充值顺利或者正确完成。若因此造成用户权益受损时，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">不会作补偿或赔偿，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">同时保留随时终止该用户账号资格及使用各项充值服务的权利。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">3.3 用户不得通过</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">充值进行任何违法犯罪活动，不得利用充值漏洞谋利，否则</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">有权终止服务，追缴非法获利，情节严重的，依法移交司法机关。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">4. 充值成功后</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">充值成功后，充值所增加的账号内</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">短视频生成卡</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">或云空间容量</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">可由用户自由使用，用户充值成功，即完成在线交付，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">不提供退还或逆向兑换服务。</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">5. 渠道商服务手续费用</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">各充值方式对应的渠道商可能会按其标准制定相关充值渠道手续费用（可能会在充值操作页面或渠道商其他页面上提示），并在用户充值时收取。</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">特此提醒：请用户务必注意各充值方式的渠道商服务手续费，并按自己的需求选取充值方式。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">6. 若因</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">充值方式原因造成用户充值失实并经查证属实的，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">将根据用户充值情况作出变动措施：</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">6.1 因充值方式原因，造成系统充值额小于用户实际充值额的，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">予以补其差额；</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">6.2 因充值方式原因，造成系统充值额大于用户实际充值额的，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">有权追回差额；</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">6.3 因渠道商收取服务手续费用等原因造成系统充值额小于用户实际充值额的，不属于用户充值失实。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">7. 服务变更、中断或终止</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">7.1 </span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">可以根据业务需求随时变更服务或更新本协议的条款，但应在相关的页面上及时提示修改内容。修改后的服务协议一旦在页面上公布即代替原来的服务协议。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">7.2 如因系统维护或升级的需要而需暂停网络充值服务，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">将尽可能事先进行通告。</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">7.3 如发生下列任何一种情形，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户：</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">(a) 用户提供的</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">个人/企业</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">资料不真实；</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">(b) 用户违反本协议中规定的使用规则。</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">7.4 除前款所述情形外，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络充值服务的权利，对于充值服务的中断或终止而造成的损失，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">无需对用户或第三方承担责任。</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">8. 免责声明和损害赔偿</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">8.1发生下列情况时，</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">免于承担法律责任：</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">（a）用户因使用第三方支付渠道充值过程中发生的相关争议；</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">（b）由于用户将密码告知他人导致的用户财产损失；</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">（c）因用户个人故意或重大过失，或本协议之外的第三方所造成的用户财产损失；</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">（d）由不可抗力及不可预见的情势导致的各种情况和纠纷；不可抗力和不可预见情势包括：黑客攻击、电信部门技术调整导致重大影响、政府管制导致的暂时关闭、病毒侵袭。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">8.2 用户同意保障和维护</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">及其他用户的利益，如因用户违反有关法律、法规或本协议项下的条款而给虎牙或其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">9. 其他</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">9.1 本协议的订立、执行和解释及争议的解决均应适用中国法律。</span></p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">9.2 本协议的签署地点为</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">浙江省杭州市</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">，若用户与</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt; font-weight:bold">发生争议的，双方同意将争议提交协议签署地有管辖权的人民法院诉讼解决。</span>
    </p>
    <p style="font-size:12pt; line-height:200%; margin:0pt; text-indent:24pt"><span
        style="color:#333333; font-family:微软雅黑; font-size:12pt">9.3 本协议项下所有的通知均可通过重要页面公告、电子邮件或常规的信件传送等方式进行；该等通知于发送之日视为已送达收件人。</span>
    </p>
    <p style="margin:0pt; orphans:0; text-align:justify; widows:0"><span style="font-family:DengXian; font-size:12pt">&#xa0;</span>
    </p></div>
</div>
</template>

<script>
export default {
  name: "recharge"
}
</script>

<style scoped>

</style>
