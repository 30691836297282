import { render, staticRenderFns } from "./system_notice.vue?vue&type=template&id=7dd9ed47&scoped=true"
import script from "./system_notice.vue?vue&type=script&lang=js"
export * from "./system_notice.vue?vue&type=script&lang=js"
import style0 from "./system_notice.vue?vue&type=style&index=0&id=7dd9ed47&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd9ed47",
  null
  
)

export default component.exports