<template>
  <div>

    <el-button size="small" type="primary" @click="applyTicket">申请开票</el-button>

    <el-table
        border
        size="small"
        :data="tableData"
        @selection-change="handleSelectionChange"
        style="width: 100%;margin-top: 10px">
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column
          align="center"
          prop="out_trade_no"
          label="订单编号">
      </el-table-column>
      <el-table-column
          align="center"
          prop="total_amount"
          label="金额">
        <template slot-scope="scope">
          ¥ {{scope.row.total_amount | numFilter }}
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="update_time"
          label="下单时间">
      </el-table-column>
      <el-table-column
          align="center"
          prop="order_type"
          label="订单类型">
        <template slot-scope="scope">
          <span v-if="scope.row.order_type === 1">生成卡订单</span>
          <span v-if="scope.row.order_type === 2">扩容订单</span>
          <span v-if="scope.row.order_type === 3">系统订单</span>
          <span v-if="scope.row.order_type === 4">平台抽成余额充值</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="pay_way"
          label="支付方式">
        <template slot-scope="scope">
          <span v-if="scope.row.pay_way === 1">微信</span>
          <span v-if="scope.row.pay_way === 2">支付宝</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="trade_no"
          label="流水号">
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        size="small"
        title="发票申请"
        :visible.sync="dialogVisible"
        width="80%">
      <div>
        <el-form size="small" :rules="rules" ref="ruleForm" :model="dialogForm" label-width="125px">
          <el-form-item label="">
            <div>每个订单仅支持一次开票，请认真核对填写信息，发票预计15个工作日开具完成，开具完成后您可在开票历史中下载发票。</div>
          </el-form-item>
          <el-form-item label="发票类型:" prop="ticket_type">
            <el-select v-model="ticket_type" placeholder="请选择发票类型">
              <el-option label="增值税电子普通发票" value="1"></el-option>
              <el-option label="增值税电子专用发票" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="订单数量:" prop="name">
            <el-input :disabled="true" v-model="dialogForm.order_num"></el-input>
          </el-form-item>
          <el-form-item label="开票金额:" prop="name">
            <el-input :disabled="true" v-model="dialogForm.total_amount"></el-input>
          </el-form-item>
          <el-form-item label="发票抬头:" prop="name">
            <el-input :disabled="true" v-model="dialogForm.company_name"></el-input>
          </el-form-item>
          <el-form-item label="公司税号:" prop="name">
            <el-input :disabled="true" v-model="dialogForm.credit_code"></el-input>
          </el-form-item>
          <el-form-item label="公司地址:">
            <el-input v-model="dialogForm.company_address"></el-input>
          </el-form-item>
          <el-form-item label="公司电话:">
            <el-input v-model="dialogForm.company_phone"></el-input>
          </el-form-item>
          <el-form-item label="公司开户行:">
            <el-input v-model="dialogForm.company_open_bank"></el-input>
          </el-form-item>
          <el-form-item label="开户行账号:">
            <el-input v-model="dialogForm.company_open_bank_no"></el-input>
          </el-form-item>

          <el-form-item>
            <div style="width:100%;display: flex;margin-top: 20px">
              <div style="flex: 1;text-align: center">
                <el-button>取消</el-button>
              </div>
              <div style="flex: 1">
                <el-button type="primary" @click="sureApllay">提交申请</el-button>
              </div>
            </div>
          </el-form-item>
        </el-form>

      </div>
    </el-dialog>


  </div>
</template>

<script>

import { ticketOrderList ,submitApplications } from "../../../api/merchants_ticket_order";
import {enterpriseCertInfo} from "../../../api/merchantsUser";

export default {
  name: "TicketOrderList",
  filters:{
    numFilter (value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    },
  },
  data() {
    return {
      ticket_type: "1",
      dialogVisible: false,
      dialogForm:{},
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        ticket_type: [
          { required: true, message: '请选择发票类型', trigger: 'change' }
        ]
      },
      queryParams:{
        serviceStates:0,
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading: false,
      tableData: [],
      multipleSelection: [],
      enterpriseCertInfo:{}
    }
  },
  mounted(){
    this.initData()
    this.doEnterpriseCertInfo()
  },
  methods:{
    doEnterpriseCertInfo(){
      enterpriseCertInfo({}).then((res) => {
        this.enterpriseCertInfo = res.data;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    sureApllay(){
      let dialogForm = this.dialogForm;
      console.log(JSON.stringify(dialogForm))
      dialogForm.ticket_type = this.ticket_type
      submitApplications(dialogForm).then(res=>{
        console.log(JSON.stringify(res))
        if (res.code == "0") {
          this.$message.success({
            duration: 1000,
            message: '提交成功'
          });
          this.dialogVisible = false
          this.initData()
        }else{
          this.$message.error(res.message);
        }
      })
    },
    applyTicket(){
        if(this.multipleSelection.length <=0){
          this.$message.error("请选择有效的订单");
          return
        }
        console.log(JSON.stringify(this.multipleSelection))
        this.dialogForm = this.enterpriseCertInfo
        this.dialogForm.ticket_type = "1"
        this.dialogForm.order_num = this.multipleSelection.length
         let total_amount = 0.00;
         this.multipleSelection.forEach(function(value,index,array){
           total_amount = total_amount + value.total_amount
         });
        this.dialogForm.ids = this.multipleSelection.map(item => {
          return item.id;
        });
        this.dialogForm.total_amount = total_amount
        this.dialogVisible = true
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      ticketOrderList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    }

  }
}
</script>

<style scoped>
/deep/ .el-dialog__body{
  padding-top: 0px;
}

.el-form-item{
  margin: 10px;
}
</style>
