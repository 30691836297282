<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <div>
      <el-input
        size="small"
        placeholder="openId"
        v-model="queryParams.open_id"
        clearable
        style="width: 280px"
      >
      </el-input>
      <el-input
        size="small"
        placeholder="昵称"
        v-model="queryParams.nick_name"
        clearable
        style="width: 120px; margin-left: 10px"
      >
      </el-input>
      <el-input
        size="small"
        placeholder="手机号"
        v-model="queryParams.phone_number"
        clearable
        style="width: 120px; margin-left: 10px"
      >
      </el-input>
      <el-select
        @change="selectTrigger()"
        style="margin-left: 10px; width: 100px"
        v-model="queryParams.registered"
        placeholder="请选择"
        size="small"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        @change="selectTrigger()"
        style="margin-left: 10px; width: 100px"
        v-model="queryParams.user_type"
        placeholder="请选择"
        size="small"
      >
        <el-option
          v-for="item in options1"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-select
        @change="selectTrigger()"
        style="margin-left: 10px; width: 100px"
        v-model="queryParams.membersLevelId"
        placeholder="会员等级"
        size="small"
      >
        <el-option
          v-for="item in queryMembersLevelList"
          :key="item.id"
          :label="item.members_name"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-select
        @change="selectTrigger()"
        style="margin-left: 10px; width: 100px"
        v-model="queryParams.recommend"
        placeholder="推荐人"
        size="small"
      >
        <el-option
          v-for="item in recommendOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-input
        size="small"
        placeholder="请输入生成卡数量"
        v-model="queryParams.gen_card_quota_start"
        clearable
        style="width: 150px; margin-left: 10px"
      ></el-input>
      -
      <el-input
        size="small"
        placeholder="请输入生成卡数量"
        v-model="queryParams.gen_card_quota_end"
        clearable
        style="width: 150px"
      ></el-input>

      <el-input
        size="small"
        placeholder="经纪人昵称"
        v-model="queryParams.agentNickName"
        clearable
        style="width: 120px; margin-left: 10px"
      >
      </el-input>

      <el-button
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="search()"
        >搜索</el-button
      >
    </div>

    <el-table
      :data="tableData"
      :loading="listLoading"
      border
      style="width: 100%; margin-top: 10px"
      :max-height="autoHeight"
    >
      <el-table-column align="center" label="基本信息" width="380">
        <template slot-scope="scope">
          <div style="display: flex; flex-direction: row; align-items: center">
            <el-image
              v-if="scope.row.avatar_url"
              style="width: 50px; height: 50px"
              :src="scope.row.avatar_url"
              fit="cover"
            >
            </el-image>
            <div
              style="
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                text-align: left;
              "
            >
              <div>{{ scope.row.nick_name }}</div>
              <div>手机号:{{ scope.row.phone_number }}</div>
              <div>openId:{{ scope.row.open_id }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="" label="APP会员信息" width="200">
        <template slot-scope="scope">
          <div v-if="scope.row.ext_obj == null">暂无会员身份</div>

          <div v-else>
            <div>VIP会员</div>
            <div>{{ scope.row.expireTime }}到期</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="name" label="会员信息" width="200">
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.sklm_members_audit_entity != null &&
              scope.row.sklm_members_audit_entity.members_level_id != -2 &&
              scope.row.sklm_members_audit_entity.members_level_id != -1
            "
            style="display: flex; flex-direction: column"
          >
            <div>
              用户等级:
              {{
                scope.row.sklm_members_audit_entity.members_level.members_name
              }}
            </div>
            <div>
              会员到期时间:
              {{
                parseDateFull(
                  scope.row.sklm_members_audit_entity.expiration_time
                )
              }}
            </div>
          </div>
          <div v-else>暂无会员身份</div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="agent"
        label="用户履约金金额"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.per_money | numberFormat }}
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="agent"
        label="是否为经纪人"
        width="120"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.agent == 1">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="gen_card_quota"
        label="生成卡余额"
        width="100"
      >
      </el-table-column>

      <el-table-column
        align="center"
        prop="agent_earnings"
        label="可提现金额(元)"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.agent_earnings | numberFormat }}
          <!--          <span style="color: #409EFF;cursor: pointer" v-if="scope.row.agent_earnings > 0" @click="balanceVisibleShow(scope.row)">{{scope.row.agent_earnings | numberFormat }}</span>-->
          <!--          <span v-else>{{scope.row.agent_earnings | numberFormat}}</span>-->
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="reg_com_bal"
        label="团长余额(元)"
        width="120"
      >
        <template slot-scope="scope">
          {{ scope.row.reg_com_bal | numberFormat }}
          <!--          <span style="color: #409EFF;cursor: pointer" v-if="scope.row.agent_earnings > 0" @click="balanceVisibleShow(scope.row)">{{scope.row.agent_earnings | numberFormat }}</span>-->
          <!--          <span v-else>{{scope.row.agent_earnings | numberFormat}}</span>-->
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="ext_obj.sxtBalance"
        label="随心推余额"
        width="100"
      >
      </el-table-column>

      <el-table-column align="center" label="注册时间/最近活跃时间" width="180">
        <template slot-scope="scope">
          <div>{{ scope.row.create_time }}</div>
          <div>{{ scope.row.update_time }}</div>
        </template>
      </el-table-column>

      <el-table-column
        align="center"
        prop="disable"
        label="是否禁用"
        width="100"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.disable == 1">是</el-tag>
          <el-tag v-else type="danger">否</el-tag>
        </template>
      </el-table-column>

      <el-table-column align="center" label="推荐人" width="200">
        <template slot-scope="scope">
          <div
            v-if="scope.row.agent_sklm_wx_user != null"
            style="display: flex; flex-direction: row; align-items: center"
          >
            <div style="width: 50px; height: 50px">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.agent_sklm_wx_user.avatar_url"
                fit="cover"
              >
              </el-image>
            </div>
            <div
              style="
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                text-align: left;
              "
            >
              <div>{{ scope.row.agent_sklm_wx_user.nick_name }}</div>
              <div>
                <el-tag
                  size="small"
                  v-if="scope.row.agent_sklm_wx_user.agent == 1"
                  >经纪人</el-tag
                >
                <el-tag size="small" v-else type="danger">非经纪人</el-tag>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="经纪人" width="200">
        <template slot-scope="scope">
          <div
            v-if="scope.row.broker_sklm_wx_user != null"
            style="display: flex; flex-direction: row; align-items: center"
          >
            <div style="width: 50px; height: 50px">
              <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.broker_sklm_wx_user.avatar_url"
                fit="cover"
              >
              </el-image>
            </div>
            <div
              style="
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                text-align: left;
              "
            >
              <div>{{ scope.row.broker_sklm_wx_user.nick_name }}</div>
              <div>
                <el-tag
                  size="small"
                  v-if="scope.row.broker_sklm_wx_user.agent == 1"
                  >经纪人</el-tag
                >
                <el-tag size="small" v-else type="danger">非经纪人</el-tag>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="dkRatio(scope.row)"
            >抖客运营配置</el-button
          >

          <el-button type="text" size="small" @click="handleEditCode(scope.row)"
            >修改邀请码</el-button
          >
          <el-button type="text" size="small" @click="handleEdit(scope.row)"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="genCardQuota(scope.row)"
            >生成卡管理</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="doUserTimeCardResd(scope.row)"
            >生成卡记录</el-button
          >

          <el-button type="text" size="small" @click="sxtAdd(scope.row)"
            >随心推管理</el-button
          >

          <el-button type="text" size="small" @click="sxtList(scope.row)"
            >随心推记录</el-button
          >
          <el-button
            v-if="scope.row.agent !== 1"
            type="text"
            size="small"
            @click="doSetBroker(scope.row)"
            >设为经纪人</el-button
          >
          <el-button
            v-if="scope.row.agent == 1"
            type="text"
            size="small"
            @click="doSetBroker(scope.row)"
            >取消经纪人</el-button
          >
          <!--          <el-button type="text" size="small" @click="balanceVisibleShow(scope.row)">余额调整</el-button>-->
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page_no"
            :page-size="page_size"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog
      title="视频生成卡管理"
      :visible.sync="genCardQuotaVisible"
      width="30%"
    >
      <el-form
        :model="genCardQuotaForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <el-form-item label="类型" prop="operationType">
          <el-radio-group v-model="genCardQuotaForm.operationType">
            <el-radio :label="1">充值</el-radio>
            <el-radio :label="2">扣减</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="数量" prop="genCardQuota">
          <el-input
            v-model.number="genCardQuotaForm.genCardQuota"
            placeholder="请输入数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="details">
          <el-input
            v-model="genCardQuotaForm.details"
            placeholder="备注信息"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="genCardQuotaVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="genCardQuotaSure"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="生成卡记录"
      :visible.sync="genCardQuotaResVisible"
      width="60%"
      @close="offSxt()"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="充值/扣减记录" name="first">
          <el-table :data="tableDataResOne" style="width: 100%">
            <el-table-column align="center" prop="create_time" label="时间">
            </el-table-column>
            <el-table-column
              prop="gen_card_quota"
              label="生成卡余额"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.gen_card_quota > 0">
                  +{{ scope.row.gen_card_quota }}
                </div>
                <div v-else>{{ scope.row.gen_card_quota }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="caption" label="方式">
            </el-table-column>
            <el-table-column align="center" prop="details" label="备注">
            </el-table-column>
          </el-table>
          <el-row style="margin-top: 24px">
            <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                  background
                  @current-change="handleCurrentChangeResOne"
                  :current-page="page_no_ResOne"
                  :page-size="page_size_ResOne"
                  :total="total_ResOne"
                  layout="total, prev, pager, next"
                ></el-pagination>
              </span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="实际消耗记录" name="second">
          <el-table :data="tableDataResTwo" style="width: 100%">
            <el-table-column
              align="center"
              prop="create_time"
              label="时间"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="gen_card_quota"
              label="生成卡余额"
              align="center"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.gen_card_quota > 0">
                  +{{ scope.row.gen_card_quota }}
                </div>
                <div v-else>{{ scope.row.gen_card_quota }}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="creator_tem_id"
              label="模板编号"
            >
            </el-table-column>
            <el-table-column align="center" prop="details" label="模板标题">
            </el-table-column>
          </el-table>
          <el-row style="margin-top: 24px">
            <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                  background
                  @current-change="handleCurrentChangeResTwo"
                  :current-page="page_no_ResTwo"
                  :page-size="page_size_ResTwo"
                  :total="total_ResTwo"
                  layout="total, prev, pager, next"
                ></el-pagination>
              </span>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog title="修改用户信息" :visible.sync="editVisible" width="40%">
      <el-form
        :label-position="labelPosition"
        label-width="140px"
        :model="editForm"
      >
        <el-form-item label="昵称/手机号:">
          <span v-if="editForm.nick_name">{{ editForm.nick_name }}</span
          ><span v-else>昵称未授权</span>
          <span v-if="editForm.phone_number">{{ editForm.phone_number }}</span
          ><span v-else>手机号未授权</span>
        </el-form-item>
        <el-form-item label="用户等级:">
          <div
            v-if="
              editForm.sklm_members_audit_entity != null &&
              editForm.sklm_members_audit_entity.members_level_id != -2 &&
              editForm.sklm_members_audit_entity.members_level_id != -1
            "
          >
            <div style="display: flex; flex-direction: column">
              <el-select
                v-model="editForm.sklm_members_audit_entity.members_level_id"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in membersLevelList"
                  :key="item.id"
                  :label="item.members_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-else>暂无会员身份</div>
        </el-form-item>
        <el-form-item label="会员到期时间:">
          <div
            v-if="
              editForm.sklm_members_audit_entity != null &&
              editForm.sklm_members_audit_entity.members_level_id != -2 &&
              editForm.sklm_members_audit_entity.members_level_id != -1
            "
          >
            <div style="display: flex; flex-direction: column">
              <el-date-picker
                v-model="editForm.sklm_members_audit_entity.expiration_time"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </div>
          </div>
          <div v-else>暂无会员身份</div>
        </el-form-item>
        <el-form-item label="是否禁用:">
          <el-radio-group v-model="editForm.disable">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
          <div style="color: rgba(166, 166, 166, 1); font-size: 12px">
            禁用后，部分功能将受限
          </div>
        </el-form-item>

        <el-form-item label="选择身份:">
          <el-select
            style="margin-left: 10px; width: 100px"
            v-model="editForm.user_type"
            placeholder="选择身份"
            size="small"
          >
            <el-option
              v-for="item in options1"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="editVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="sureEdit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="修改邀请码" :visible.sync="editCodeVisible" width="40%">
      <el-form
        :label-position="labelPosition"
        label-width="140px"
        :model="editForm"
      >
        <el-form-item label="邀请码:" prop="balance">
          <el-input
            v-model="editCodeForm.invitation_code"
            placeholder="请输入邀请码"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="editCodeVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="sureCodeEdit"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog :title="balanceTitle" :visible.sync="balanceVisible" width="30%">
      <el-form
        :model="balanceForm"
        :rules="balanceRules"
        ref="balanceRuleForm"
        label-width="100px"
      >
        <el-form-item label="类型:" prop="operationType">
          <el-radio-group v-model="balanceForm.operationType">
            <el-radio :label="1">充值</el-radio>
            <el-radio :label="2">扣减</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="金额:" prop="balance">
          <el-input
            v-model="balanceForm.balance"
            placeholder="请输入调整金额"
            clearable
            @input="formatBalance(balanceForm.balance, 'balance')"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="details">
          <el-input
            v-model="balanceForm.details"
            placeholder="备注信息"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="balanceVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="doBalanceUpdate"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog title="随心推管理" :visible.sync="sxtVisible" width="30%">
      <el-form
        :model="sxtForm"
        :rules="sxtruleForm"
        ref="sxtruleForm"
        label-width="100px"
      >
        <el-form-item label="类型" prop="rec_type">
          <el-radio-group v-model="sxtForm.rec_type">
            <el-radio :label="6">充值</el-radio>
            <el-radio :label="7">扣减</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="数量" prop="sxt_balance">
          <el-input
            v-model.number="sxtForm.sxt_balance"
            placeholder="请输入数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="sxtForm.remark" placeholder="备注信息"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="sxtVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="genSxtSbumt()"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="随心推记录"
      :visible.sync="sxtListVisible"
      width="60%"
      @close="offSxt()"
    >
      <el-table
        :loading="sxtlistLoading"
        :data="sxtTableData"
        style="width: 100%"
      >
        <el-table-column align="center" prop="pay_time" label="时间">
        </el-table-column>
        <el-table-column prop="sxt_balance" label="生成卡余额" align="center">
          <!-- <template slot-scope="scope">
                <div v-if="scope.row.gen_card_quota > 0">
                  +{{ scope.row.gen_card_quota }}
                </div>
                <div v-else>{{ scope.row.gen_card_quota }}</div>
              </template> -->
        </el-table-column>
        <el-table-column align="center" prop="rec_type" label="方式">
        </el-table-column>
        <el-table-column align="center" prop="remark" label="备注">
        </el-table-column>
      </el-table>
      <el-row style="margin-top: 24px">
        <el-col :span="24" style="text-align: right">
          <span class="page-tool">
            <el-pagination
              background
              @current-change="sxtHandleCurrentChangeResOne"
              :current-page="sxt_page_no_ResOne"
              :page-size="sxt_page_size_ResOne"
              :total="sxt_total_ResTwo"
              layout="total, prev, pager, next"
            ></el-pagination>
          </span>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog title="抖客运营配置" :visible.sync="dkRatioVisible" width="40%">
      <el-radio-group v-model="dkForm.ratioInfo.lever_source">
        <el-radio :label="1">跟随系统</el-radio>
        <el-radio :label="2">
          自定义推广等级
          <el-select
            style="margin-top: 20px; width: 140px"
            v-model="dkForm.ratioInfo.user_lever"
            placeholder="选择推广等级"
            size="small"
          >
            <el-option
              v-for="item in dkForm.ratioBase"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-radio>
      </el-radio-group>

      <el-divider></el-divider>

      <div class="flex-left">
        <div style="width: 150px">用户分成比例:</div>
        <div class="mag-10">{{ dkForm.ratioCom.user_ratio || "-" }}%</div>
        <el-avatar size="medium" :src="dkForm.ratioCom.wx_ava"></el-avatar>
        <div class="mag-10">{{ dkForm.ratioCom.wx_name || "-" }}</div>
      </div>

      <div class="flex-left mag-top-10">
        <div style="width: 150px">团长分成比例:</div>
        <div class="mag-10">{{ dkForm.ratioCom.team_ratio || "-" }}%</div>
        <el-avatar size="medium" :src="dkForm.ratioCom.agent_ava"></el-avatar>
        <div class="mag-10">{{ dkForm.ratioCom.agent_name || "-" }}</div>
      </div>

      <div class="flex-left mag-top-10">
        <div style="width: 150px">合作伙伴分成比例:</div>
        <div class="mag-10">{{ dkForm.ratioCom.cha_ratio || "-" }}%</div>
        <el-avatar size="medium" :src="dkForm.ratioCom.channel_ava"></el-avatar>
        <div class="mag-10">{{ dkForm.ratioCom.cha_le_name || "-" }}</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dkRatioVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="dkEdit()"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dkRatioinfo, dkRatioUpdate } from "../../api/sklmUser";

import {
  sklmMembersLevelList,
  sklmUserList,
  userTimeCard,
  userTimeCardResd,
  sklmUserUpdate,
  balanceUpdate,
  updateAgent,
  setBroker,
  merchantsResSave,
  merchantsResList,
} from "../../api/sklmUser";

export default {
  name: "userList",
  filters: {
    numberFormat: function (value) {
      if (!value) return value;
      if (typeof value === "string") {
        value = Number(value);
      }
      return value.toFixed(2);
    },
  },
  data() {
    return {
      dkRatioVisible: false,
      dkForm: {
        ratioInfo: {
          lever_source: 1,
        },
        ratioBase: [],
        ratioCom: {
          user_ratio: "",
        },
      },

      dkInfo: {
        userId: "",
        userType: "USER", //用户: USER 经纪人:TEAM 合伙人:CHA
      },

      balanceVisible: false,
      balanceForm: {
        operationType: 1,
        balance: null,
        details: "",
      },
      balanceRules: {
        operationType: [
          { required: true, message: "请输入选择操作类型", trigger: "blur" },
        ],
        balance: [
          { required: true, message: "请输入调整金额", trigger: "blur" },
        ],
        details: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
        ],
      },
      balanceTitle: "",
      recommendOptions: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "有推荐人",
        },
        {
          value: "2",
          label: "无推荐人",
        },
      ],
      options: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "1",
          label: "已授权",
        },
        {
          value: "2",
          label: "未授权",
        },
      ],

      options1: [
        {
          value: 0,
          label: "普通",
        },
        {
          value: 1,
          label: " 工作室",
        },
      ],
      membersLevelList: [],
      queryMembersLevelList: [
        {
          id: null,
          members_name: "全部",
        },
      ],
      labelPosition: "right",
      editForm: {
        user_type: "",
      },
      editVisible: false,
      editCodeForm: {},
      editCodeVisible: false,
      sxtForm: {
        rec_type: 6,
        sxt_balance: "",
        remark: "",
      }, //随心推余额管理
      sxtVisible: false,

      sxtruleForm: {
        rec_type: [
          { required: true, message: "请输入选择操作类型", trigger: "blur" },
        ],
        sxt_balance: [
          { required: true, message: "请输入随心推数量", trigger: "blur" },
          { type: "number", message: "随心推数量必须为数字值" },
        ],
        remark: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
        ],
      },

      sxtListVisible: false, //随心推记录
      sxtActiveName: "first", //随心推记录
      sxtTableData: [], //随心推记录列表
      sxtlistLoading: false,

      open_id: "",
      activeName: "first",
      genCardQuotaResVisible: false,
      genCardQuotaVisible: false,
      genCardQuotaForm: {},
      rules: {
        operationType: [
          { required: true, message: "请输入选择操作类型", trigger: "blur" },
        ],
        genCardQuota: [
          { required: true, message: "请输入生成卡数量", trigger: "blur" },
          { type: "number", message: "生成卡数量必须为数字值" },
        ],
        details: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
        ],
      },
      autoHeight: "",
      autoWidth: "",
      queryParams: {
        recommend: "",
        nick_name: "",
        open_id: "",
        phone_number: "",
        registered: "1",
        agentId: "",
        agentNickName: "",
        membersLevelId: null,
        gen_card_quota_start: null,
        gen_card_quota_end: null,
        user_type: 0,
      },
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 100,
      tableDataResOne: [],
      page_no_ResOne: 1,
      page_size_ResOne: 10,
      total_ResOne: 100,
      tableDataResTwo: [],
      page_no_ResTwo: 1,
      page_size_ResTwo: 10,
      total_ResTwo: 100,

      sxt_page_no_ResOne: 1,
      sxt_page_size_ResOne: 10,
      sxt_total_ResTwo: "",
    };
  },
  created() {
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.initData();
    this.sklmMembersLevelList();
  },
  methods: {
    dkRatio(row) {
      this.dkRatioVisible = true;
      // console.log("dk id", row);
      this.dkInfo.userId = row.open_id;
      this.getDkRatioinfo();
    },
    getDkRatioinfo() {
      dkRatioinfo(this.dkInfo).then((res) => {
        console.log("抖客", res);
        this.dkForm = res.data;
      });
    },

    dkEdit() {
      console.log("抖客提交", this.dkForm.ratioInfo);

      dkRatioUpdate(this.dkForm.ratioInfo).then((res) => {
        if (res.code == 0) {
          this.$message.success("保存成功");
          this.dkRatioVisible = false;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 关闭弹层后 默认第一页
    offSxt() {
      this.sxt_page_no_ResOne = 1;
      this.page_no_ResOne = 1;
    },

    sxtList(row) {
      this.sxtListVisible = true;
      this.open_id = row.open_id;
      this.sxtTnitDataResOne();
    },

    sxtTnitDataResOne() {
      this.sxtlistLoading = true;
      let queryParams = {};
      (queryParams.pageNum = this.sxt_page_no_ResOne),
        (queryParams.pageSize = this.sxt_page_size_ResOne),
        (queryParams.userId = this.open_id);

      merchantsResList(queryParams).then((response) => {
        console.log(response);
        this.sxtTableData = response.data.list;
        this.sxt_total_ResTwo = response.data.total_count;
        this.listLoading = false;
      });
    },

    sxtAdd(row) {
      this.sxtVisible = true;
      this.sxtForm.user_id = row.open_id;
    },

    genSxtSbumt() {
      console.log("suixintui");

      console.log(JSON.stringify(this.sxtForm));
      this.$refs["sxtruleForm"].validate((valid) => {
        if (valid) {
          merchantsResSave({}, this.sxtForm).then((res) => {
            console.log(res);
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.sxtForm = {
                rec_type: 6,
                sxt_balance: "",
                remark: "",
              };
              this.initData();
              this.sxtVisible = false;
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    formatBalance(val, key) {
      console.log("formatBalance");
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.balanceForm[key] = temp;
    },
    doBalanceUpdate() {
      console.log(JSON.stringify(this.balanceForm));
      this.$refs["balanceRuleForm"].validate((valid) => {
        if (valid) {
          balanceUpdate(this.balanceForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.initData();
              this.balanceVisible = false;
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    search() {
      this.page_no = 1;
      this.initData();
    },
    selectTrigger() {
      this.page_no = 1;
      this.initData();
    },
    setAgent(row) {
      this.editForm = row;
      this.$confirm(
        "确定将 " + row.nick_name + " 设为经纪人吗?",
        "设为经纪人",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.editForm.agent = 1;
          sklmUserUpdate({}, this.editForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.initData();
            } else {
              this.$message.error({ message: res.message });
            }
          });
        })
        .catch(() => {});
    },
    cancelAgent(row) {
      this.editForm = row;
      this.$confirm(
        "确定取消 " + row.nick_name + " 经纪人身份吗?",
        "取消经纪人",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.editForm.agent = 2;
          sklmUserUpdate({}, this.editForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.initData();
            } else {
              this.$message.error({ message: res.message });
            }
          });
        })
        .catch(() => {});
    },
    sureEdit() {
      console.log(JSON.stringify(this.editForm));
      sklmUserUpdate({}, this.editForm).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
          this.initData();
          this.editVisible = false;
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    sureCodeEdit() {
      console.log(JSON.stringify(this.editCodeForm));
      updateAgent({}, this.editCodeForm).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
          this.initData();
          this.editCodeVisible = false;
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    doSetBroker(row) {
      console.log(JSON.stringify(row));
      let body = {
        open_id: row.open_id,
      };
      setBroker({}, body).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
          this.initData();
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    sklmMembersLevelList() {
      let queryParams = {
        page: 1,
        limit: 100,
      };
      sklmMembersLevelList(queryParams).then((response) => {
        let membersLevelList = response.data.list;
        let item = {
          id: -1,
          members_name: "注册用户",
        };
        membersLevelList.push(item);
        this.membersLevelList = membersLevelList;

        let queryMembersLevelList = this.queryMembersLevelList.concat(
          this.membersLevelList
        );

        let item3 = {
          id: -3,
          members_name: "会员到期",
        };
        queryMembersLevelList.push(item3);

        let item2 = {
          id: -2,
          members_name: "非会员",
        };
        queryMembersLevelList.push(item2);

        this.queryMembersLevelList = queryMembersLevelList;
      });
    },
    handleEdit(row) {
      this.editForm = JSON.parse(JSON.stringify(row));
      this.editVisible = true;
    },
    handleEditCode(row) {
      this.editCodeForm = {
        open_id: row.open_id,
        invitation_code: row.be_invitation_code,
      };
      this.editCodeVisible = true;
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d;
    },
    doUserTimeCardResd(row) {
      this.genCardQuotaResVisible = true;
      this.open_id = row.open_id;
      this.initDataResOne();
      this.initDataResTwo();
    },
    genCardQuotaSure() {
      console.log(JSON.stringify(this.genCardQuotaForm));
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          userTimeCard({}, this.genCardQuotaForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.initData();
              this.genCardQuotaVisible = false;
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    genCardQuota(row) {
      this.genCardQuotaVisible = true;
      let genCardQuotaForm = {
        openId: row.open_id,
        operationType: 1,
      };
      this.genCardQuotaForm = genCardQuotaForm;
    },
    balanceVisibleShow(row) {
      this.balanceTitle = "调整用户 '" + row.nick_name + "' 余额";
      this.balanceVisible = true;
      let balanceForm = {
        openId: row.open_id,
        operationType: 1,
      };
      this.balanceForm = balanceForm;
    },
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 200 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    handleCurrentChangeResOne(val) {
      console.log(`当前页: ${val}`);
      this.page_no_ResOne = val;
      this.initDataResOne();
    },
    handleCurrentChangeResTwo(val) {
      console.log(`当前页: ${val}`);
      this.page_no_ResTwo = val;
      this.initDataResTwo();
    },

    // 随心推记录
    sxtHandleCurrentChangeResOne(val) {
      console.log(`当前页: ${val}`);
      this.sxt_page_no_ResOne = val;
      // console.log(this.sxt_page_no_ResOne);
      this.sxtTnitDataResOne();
    },

    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams;
      (queryParams.page = this.page_no),
        (queryParams.limit = this.page_size),
        (queryParams.sidx = "swu.create_time"),
        (queryParams.order = "desc");
      sklmUserList(queryParams).then((response) => {
        console.log("hsm", response);
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
    initDataResOne() {
      this.listLoading = true;
      let queryParams = {};
      (queryParams.page = this.page_no_ResOne),
        (queryParams.limit = this.page_size_ResOne),
        (queryParams.openId = this.open_id);
      (queryParams.creatorTemId = -1),
        (queryParams.sidx = "create_time"),
        (queryParams.order = "desc");
      userTimeCardResd(queryParams).then((response) => {
        this.tableDataResOne = response.data.list;
        this.total_ResOne = response.data.total_count;
        this.listLoading = false;
      });
    },
    initDataResTwo() {
      this.listLoading = true;
      let queryParams = {};
      (queryParams.page = this.page_no_ResTwo),
        (queryParams.limit = this.page_size_ResTwo),
        (queryParams.openId = this.open_id);
      (queryParams.isCreatorTemId = 1),
        (queryParams.sidx = "create_time"),
        (queryParams.order = "desc");
      userTimeCardResd(queryParams).then((response) => {
        this.tableDataResTwo = response.data.list;
        this.total_ResTwo = response.data.total_count;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style scoped>
.el-radio-group {
  display: flex;
  flex-direction: column;
}

.flex-left {
  display: flex;
  align-items: center;
}

.mag-10 {
  margin: 0 10px;
}

.mag-top-10 {
  margin-top: 10px;
}
</style>
