import service from "../request";

export function state(query) {
    return service({
        url: '/api/merchants/trillMcnInfo/state',
        method: 'get',
        params: query
    })
}

export function list(query) {
    return service({
        url: '/api/merchants/trillMcnInfo/list',
        method: 'get',
        params: query
    })
}

export function auditPass(data) {
    return service({
        url: '/api/merchants/trillMcnInfo/auditPass',
        method: 'post',
        data: data
    })
}

export function auditDismiss(data) {
    return service({
        url: '/api/merchants/trillMcnInfo/auditDismiss',
        method: 'post',
        data: data
    })
}

export function unbindPass(data) {
    return service({
        url: '/api/merchants/trillMcnInfo/unbindPass',
        method: 'post',
        data: data
    })
}

export function unbindCheck(data) {
    return service({
        url: '/api/merchants/trillMcnInfo/unbindCheck',
        method: 'post',
        data: data
    })
}

export function unbindDismiss(data) {
    return service({
        url: '/api/merchants/trillMcnInfo/unbindDismiss',
        method: 'post',
        data: data
    })
}


export function changeIpPass(data) {
    return service({
        url: '/api/merchants/trillMcnInfo/changeIpPass',
        method: 'post',
        data: data
    })
}

export function changeIpDismiss(data) {
    return service({
        url: '/api/merchants/trillMcnInfo/changeIpDismiss',
        method: 'post',
        data: data
    })
}


export function synchronous(data) {
    return service({
        url: '/api/merchants/trillBinding/synchronous',
        method: 'post',
        data: data
    })
}

export function updateUserInfo(data) {
    return service({
        url: '/api/merchants/trillBinding/updateUserInfo',
        method: 'post',
        data: data
    })
}
