import service from "../request";

export function list(query) {
    return service({
        url: '/api/merchants/trillMemberLevel/list',
        method: 'get',
        params: query
    })
}

export function update(query,data) {
    return service({
        url: '/api/merchants/trillMemberLevel/saveOrUpdate',
        method: 'post',
        params: query,
        data: data
    })
}

export function del(query) {
    return service({
        url: '/api/merchants/trillMemberLevel/del',
        method: 'get',
        params: query
    })
}

export function memberInfo(query) {
    return service({
        url: '/api/merchants/trillMemberLevel/info',
        method: 'get',
        params: query
    })
}

export function memberInfoUpdate(data) {
    return service({
        url: '/api/merchants/trillMemberLevel/info',
        method: 'post',
        data: data
    })
}
