<template>
  <div class="main_container">
    <div class="main_info">
      <div class="main_info_left">

        <div class="home_container_one">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">种草任务</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in grassTaskList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
        </div>

        <div class="home_container">
          <span style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">账号数据</span>
          <div class="flex_container">
            <div class="flex_chid" v-for="(item,index) in accountList" :key="index">
              <span class="flex_lable">{{item.label}}</span>
              <span class="flex_value">{{item.value}}</span>
            </div>
          </div>
        </div>

        <div>
          <div class="home_container" style="background: rgba(255, 255, 255, 1);">
            <div style="display: flex;align-items: center;justify-content: space-between">
              <div style="color: rgba(56, 56, 56, 1);font-size: 18px;font-weight: bold">订单数据</div>
              <div>
                当前周期
                <el-date-picker
                    @change="datePickerChange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    size="small"
                    v-model="queryDate"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </div>
            </div>
            <div id="myChart" style="width: 100%;height: 400px;margin-top: 50px"></div>
          </div>
        </div>


      </div>
      <div class="main_info_right">
        <div class="info_item" style="display: flex;flex-direction: column;justify-content: space-around">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div class="item_name">系统到期时间</div>
            <div style="color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 500;">{{grassTask.expire_time}}</div>
            <div  style="font-size: 14px;font-weight: 400;display: flex;align-items: center" >
              <span @click="renewalShow" style="color: rgba(0, 119, 255, 1);font-size: 14px;margin-left: 10px;cursor: pointer">立即续期</span>
              <img src="../../assets/home/right.png" style="width: 20px;height: 20px" />
            </div>
          </div>
        </div>

        <div class="info_item" style="margin-top: 10px">
          <div class="item_name">功能设置</div>
          <div style="display: flex;justify-content: space-between;margin-top: 5px">
            <div style="font-size: 14px;font-weight: 500;color: rgba(0, 0, 0, 1);">课程模块是否开启</div>
            <el-switch
                @change="merchantConfigUpdate"
                style="margin-left: 5px"
                v-model="merchantConfig.task_center_sw"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </div>
          <div style="font-size: 12px;font-weight: 400;color: rgba(166, 166, 166, 1);">开启后，用户可以在小程序上看到课程模块</div>
        </div>


        <div class="info_item" style="margin-top: 10px">
          <div style="display: flex;justify-content: space-between;align-items: center">
            <div class="item_name">教程与帮助</div>
          </div>
          <div class="right_item_view" @click="ai_document">
            <div>课程系统使用文档</div>
            <div style="margin-top: 10px; font-size: 12px;font-weight: 400;color: rgba(166, 166, 166, 1);">创建课程、用户购买/观看、课程营销工具，全流程使用说明</div>
          </div>
        </div>


      </div>
    </div>
    <GrassTaskSituation ref="renewal"></GrassTaskSituation>
  </div>
</template>

<script>
import {merchantsInfo, creatorUser, cloudInfo ,enterpriseCertInfo} from "../../api/merchantsUser";
import { grassTask ,account ,douyinMember ,grassTaskInfo } from "../../api/grass_task_situation";

import store from "../../store";

import GrassTaskSituation from "../../components/grass_task_situation";
import {merchantsConfig, merchantsConfigUpdate} from "../../api/merchant_config";

export default {
  name: "overview.vue",
  components: {
    GrassTaskSituation
  },
  filters:{
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else if(fileSize < (1024 * 1024 * 1024 * 1024)){
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'TB';
      }
    },
    phoneDesensitization(value){
      if(value==null){
        value = ''
      }
      let char = '*'
      return value.replace(/(\d{3})\d*(\d{4})/, `$1${new Array(5).join(char)}$2`)
    },
    numFilter (value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    },
    numFilterOne (value) {
      let realVal = parseFloat(value).toFixed(1)
      return realVal
    },
    numFilterZeo (value) {
      let realVal = parseFloat(value).toFixed(0)
      return Number(realVal)
    }
  },
  data(){
    return{
      grassTaskList:[],
      accountList:[],
      enterpriseCertInfo:{
        certification_status:null,
        company_name:''
      },
      surveyList:[],
      queryDate: null,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      clipList:[],
      expertList:[],
      profileList:[],
      tab_type:'1',
      userInfo:{},
      memberInfo: null,
      creatorUser:{},
      invitationCode:'',
      percentage:0,
      cloudInfo:{
        videoCloudSize: 0,
        materialCloudSize: 0,
        percentage: 0,
        useCloudSize: 0,
        cloudSize: 0
      },
      grassTask:{
        is_expire: false,
        expire_time: ''
      },
      merchantConfig:{
        task_center_sw: true
      }
    }
  },
  created(){
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    this.queryDate= [
      start,end
    ]
  },
  mounted(){
    this.doGrassTask()
    this.doAccount()
    this.doGrassTaskInfo()
    this.merchantConfigInfo()
    this.initCloudInfo()
    this.initData()
    this.homeProfileData()
    this.doEnterpriseCertInfo()
  },
  methods:{
    merchantConfigUpdate(){
      merchantsConfigUpdate(this.merchantConfig).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '操作成功'
          });
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    merchantConfigInfo(){
      merchantsConfig().then((res) => {
        this.merchantConfig = res.data
      });
    },
    renewalShow(){
      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data))
        let certification_status = res.data.certification_status
        if(certification_status ===2 || certification_status ===3){
          this.$refs.renewal.show(2)
        }else{
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/authentication_protocol");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    doGrassTaskInfo(){
      grassTaskInfo().then((res) => {
        this.grassTask = res.data;
      });
    },
    doGrassTask(){
      grassTask().then((res) => {
        this.grassTaskList = res.data.list;
      });
    },
    doAccount(){
      account().then((res) => {
        this.accountList = res.data.list;
      });
    },
    checkPermissions(id){
      let state = false
      if (store.state.ACCOUNT_CENTER_MENU != null){
        let children = store.state.ACCOUNT_CENTER_MENU.children
        children.forEach(function (item, index) {
          if(item.id === id){
            state = true
          }
        });
      }
      return  state;
    },
    merchantsInfoCustom(){
      this.$router.push("/merchantsInfoCustom");
    },
    authenticationProtocol(){
      this.$router.push("/authentication_protocol");
    },
    certificationDetails(){
      this.$router.push("/certification_details");
    },
    recertification(){
      this.$router.push("/upload_information");
    },
    doEnterpriseCertInfo(){
      enterpriseCertInfo({}).then((res) => {
        this.enterpriseCertInfo = res.data;
      });
    },
    datePickerChange(val) {
      console.log("datePickerChange")
      console.log(JSON.stringify(val))
      this.queryDate = val
      this.homeProfileData()
    },
    homeProfileData(){
      douyinMember({
        queryDate: this.queryDate
      }).then((res) => {
        this.profileList = res.data.resDtoList;
        let legendData = res.data.legendData
        let xAxisData = res.data.xAxisData
        let seriesInfoDtoList = res.data.seriesInfoDtoList
        this.drawLine(legendData,xAxisData,seriesInfoDtoList);
      });
    },
    rulesCenter(){
      this.$router.push("/rules_center_user_agreement");
    },
    ai_document(){
      window.open('https://vodm2020.feishu.cn/docx/BjgpdgTYGo8BqdxBtYocV1LSn4d', '_blank');
    },
    my_cloud_info(){
      this.$router.push("/my_cloud_info");
    },
    initCloudInfo(){
      cloudInfo().then((res)=>{
        this.cloudInfo = res.data
      })
    },
    cardResdCustom(){
      this.$router.push("/cardResdCustom");
    },
    initData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
        this.memberInfo = res.data.memberInfo;
      });
      creatorUser().then((res)=>{
        this.creatorUser = res.data
        this.invitationCode = 'https://vodmapi.paitk.com/api/creator/creatorUser/invitationCode?id=' + res.data.creatorUser.id + "&times=" + new Date().getTime()
        this.percentage = res.data.storageSpaceInfo.gFileSize/ res.data.storageSpaceInfo.storageSpace  * 100
      })
    },
    changeTabType(tab, event){
      console.log(tab, event);
    },
    drawLine(legendData,xAxisData,seriesInfoDtoList){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        title: { text: '' },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legendData
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: seriesInfoDtoList
      });
    }
  }
}
</script>

<style scoped>


.main_container{
  height: 100%;
  padding: 10px;
}

.main_info{
  display: flex;
}

.main_info_left{
  width: 60%;
}

.main_info_right{
  width: 40%;
  margin-left: 10px;
}

.info_item{
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  padding: 20px;
  flex: 1;
}


.item_name{
  font-size: 20px;
  font-weight: 500;
}

.left_item_view{
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  margin: 5px;
  padding: 10px;
}

.right_item_view{
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 5px;
  flex: 1;
  padding: 10px;
  margin-top: 10px;
}

.home_container_one{
  background: white;
  padding: 10px;
  border-radius: 10px;
}

.home_container {
  background: white;
  padding: 10px;
  margin-top: 10px;
  border-radius: 10px;
}

.flex_container {
  display: flex;
  flex-wrap: wrap;
}

.flex_chid {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.flex_lable {
  color: rgba(128, 128, 128, 1);
  font-size: 14px;
}

.flex_value {
  color: rgba(56, 56, 56, 1);
  font-size: 36px;
  font-weight: bold;
}


</style>
