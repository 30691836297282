<template>
  <!-- 1:待审核 -->
  <div>
    <!-- 待审核 -->
    <el-form
      size="small"
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
    >
      <el-form-item>
        <el-input
          clearable
          style="width: 320px"
          v-model="formInline.queryOne"
          placeholder="提现单号"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-input
          clearable
          v-model="formInline.queryTwo"
          placeholder="用户昵称/手机号"
        ></el-input>
      </el-form-item>

      <el-date-picker
        style="margin-right: 10px"
        size="small"
        v-model="startTime"
        type="daterange"
        range-separator="—"
        start-placeholder="发起提现时间"
        end-placeholder="发起提现时间"
        :default-time="['00:00:00', '23:59:59']"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker>

      <el-button class="btn1" type="primary" size="mini" @click="Submit"
        >查询</el-button
      >

      <el-button class="btn2" type="primary" @click="onSubmit" size="mini"
        >导出数据
        <i class="my-file"></i>
      </el-button>
    </el-form>

    <!-- 按钮 -->
    <el-button class="btn1" size="small" type="primary" @click="zfbAll"
      >批量支付宝打款</el-button
    >
    <el-button size="small" type="success" @click="offlineAll"
      >批量线下打款</el-button
    >
    <el-button size="small" type="danger" @click="turnAll"
      >批量审核驳回</el-button
    >
    <!-- 按钮 -->

    <!-- 表格 -->
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      style="width: 100%; margin: 20px 0px"
      :header-cell-style="tableHeaderCellStyle"
      :cell-style="tableCellStyle"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        header-align="center"
        align="center"
        type="selection"
        width="55"
      >
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="out_trade_no"
        label="提现单号"
      >
      </el-table-column>

      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="用户信息"
      >
        <!--  #default="{ row }" -->
        <template #default="{ row }">
          <div class="flex-left">
            <el-avatar size="medium" :src="row.user_ava"></el-avatar>
            <div style="margin-left: 5px">
              <div>{{ row.user_nick_name }}</div>
              <div>{{ row.user_phone }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="user_type"
        label="用户类型"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.user_type == 1">创作者</div>
          <div v-if="scope.row.user_type == 2">团长</div>
        </template>
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="balance"
        label="提现金额(元)"
      >
        <template slot-scope="scope">
          {{ scope.row.balance | numberFormat }}
        </template>
      </el-table-column>

      <el-table-column header-align="left" align="left" label="提现支付宝">
        <!--  #default="{ row }" -->
        <template #default="{ row }">
          <div class="flex-left">
            <el-avatar size="medium" :src="row.acc_avatar"></el-avatar>
            <div style="margin-left: 5px">{{ row.acc_nick_name }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="create_time"
        label="发起提现时间"
      >
      </el-table-column>

      <el-table-column
        prop="address2"
        label="操作"
        header-align="center"
        align="center"
      >
        <!-- #default="{ row }" -->
        <template #default="{ row }">
          <el-link
            :underline="false"
            type="primary"
            @click="pass(row.out_trade_no)"
            >支付宝打款</el-link
          >

          <el-link
            style="margin: 0 10px"
            :underline="false"
            type="primary"
            @click="turn(row.out_trade_no)"
            >线下打款</el-link
          >

          <el-link
            :underline="false"
            type="primary"
            @click="rejected(row.out_trade_no)"
            >审核驳回</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 表格 -->

    <!-- a分页 -->
    <div class="page">
      <div class="box" />
      <div class="box" />
      <div class="box box-right">
        <el-pagination
          class="page"
          background
          :current-page="formInline.page"
          :page-sizes="[10, 20, 30]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formInline.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- b分页 -->

    <!-- 提示 支付宝打款 -->
    <el-dialog title="提示" :visible.sync="weChatPassVisible" width="30%">
      <div style="display: flex; justify-content: center">
        <div>
          <i class="el-icon-warning" style="font-size: 40px; color: gold"></i>
        </div>
        <div
          style="
            margin-left: 10px;
            color: rgba(56, 56, 56, 1);
            font-size: 16px;
            font-weight: 400;
          "
        >
          <div>是否执行支付宝打款？</div>
          <div>点击“确定”，发起对该用户的打款操作！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="weChatPassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doWeChatPass">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 提示 线下打款 -->
    <el-dialog title="提示" :visible.sync="offlinePassVisible" width="30%">
      <div style="display: flex; justify-content: center">
        <div>
          <i class="el-icon-warning" style="font-size: 40px; color: gold"></i>
        </div>
        <div
          style="
            margin-left: 10px;
            color: rgba(56, 56, 56, 1);
            font-size: 16px;
            font-weight: 400;
          "
        >
          <div>请确认是否已执行线下打款？</div>
          <div>点击“确定”，提现申请将变更为打款成功！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offlinePassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doOfflinePass">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 提示 审核驳回 -->
    <el-dialog title="提示" :visible.sync="rejectedVisible" width="30%">
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入驳回原因"
        v-model="remark"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offReject()">取 消</el-button>
        <el-button type="primary" @click="doRejected">确 定</el-button>
      </span>
    </el-dialog>

    <!--提示： 批量支付宝打款 -->
    <el-dialog
      title="提示-批量微信打款"
      :visible.sync="batchWeChatPassVisible"
      width="30%"
    >
      <div style="display: flex; justify-content: center">
        <div>
          <i class="el-icon-warning" style="font-size: 40px; color: gold"></i>
        </div>
        <div
          style="
            margin-left: 10px;
            color: rgba(56, 56, 56, 1);
            font-size: 16px;
            font-weight: 400;
          "
        >
          <div>
            是否<span style="color: green">对{{ userId.length }}条数据</span
            >执行批量支付宝打款？
          </div>
          <div>点击“确定”，发起对选中用户的打款操作！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchWeChatPassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchWeChatPass">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 提示：批量线下打款 -->
    <el-dialog
      title="提示-批量线下打款"
      :visible.sync="batchOfflinePassVisible"
      width="30%"
    >
      <div style="display: flex; justify-content: center">
        <div>
          <i class="el-icon-warning" style="font-size: 40px; color: gold"></i>
        </div>
        <div
          style="
            margin-left: 10px;
            color: rgba(56, 56, 56, 1);
            font-size: 16px;
            font-weight: 400;
          "
        >
          <div>
            请确认<span style="color: green">{{ userId.length }}条数据</span
            >是否已批量执行线下打款？
          </div>
          <div>点击“确定”，提现申请将变更为打款成功！</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchOfflinePassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchOfflinePass">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 提示：批量批量驳回 -->
    <el-dialog
      title="提示-批量驳回"
      :visible.sync="batchRejectedVisible"
      width="30%"
    >
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入驳回原因"
        v-model="remark"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="offReject()">取 消</el-button>
        <el-button type="primary" @click="doBatchRejected">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
 
<script>
import {
  withdrawListApi,
  batchAlipayApi,
  batchOfflineApi,
  batchRejectApi,
} from "@/api/app_wallet_withdrawal.js";
export default {
  name: "reviewed",

  filters: {
    numberFormat: function (value) {
      return value.toFixed(2);
    },
  },

  props: {
    getTab: {
      typeof: Function,
      default() {
        return "Default function";
      },
    },
  },
  data() {
    return {
      loading: false,
      weChatPassVisible: false, //支付宝弹框
      offlinePassVisible: false, //线下打款弹框
      rejectedVisible: false, //驳回弹框

      batchWeChatPassVisible: false, //批量支付宝弹框
      batchOfflinePassVisible: false, //批量线下弹框
      batchRejectedVisible: false, //批量驳回

      remark: "", //驳回理由

      //  queryParams:{
      //   page: 1,
      //   limit: 10,
      //   sidx : "create_time",
      //   order : "desc",
      // },

      // 查询
      formInline: {
        queryOne: "",
        queryTwo: "",
        page: 1,
        limit: 10,
        total: 0,
        sidx: "create_time",
        order: "desc",
      },
      tableData: [],
      startTime: [], //发起提现时间
      multipleSelection: [], // 多选选中的列表

      // 当前用户id
      userId: [], // 多选选中的列表
    };
  },

  created() {
    this.getList();
  },

  methods: {
    //  驳回取消变空
    offReject() {
      this.rejectedVisible = false;
      this.batchRejectedVisible = false;
      this.remark = "";
    },

    // 支付宝打款接口方法
    async batchFn() {
      const res = await batchAlipayApi(this.userId);
      // console.log(res);
      if (res.code == 0) {
        this.weChatPassVisible = false;
        this.getList();
        this.getTab();
        this.$message.success({ message: "操作成功" });
      } else {
        this.$message.error({ message: res.message });
      }
    },

    // 线下打款方法接口
    async batchOffFn() {
      const res = await batchOfflineApi(this.userId);
      // console.log(res);
      if (res.code == 0) {
        this.offlinePassVisible = false;
        this.getList();
        this.getTab();
        this.$message.success({ message: "操作成功" });
      } else {
        this.$message.error({ message: res.message });
      }
    },

    // 审核驳回接口
    async batchRejectFn() {
      const res = await batchRejectApi({
        ids: this.userId,
        remark: this.remark,
      });
      // console.log(res);
      if (res.code == 0) {
        this.rejectedVisible = false;
        this.batchRejectedVisible = false;
        this.getList();
        this.getTab();
        this.$message.success({ message: "操作成功" });
        this.remark = "";
      } else {
        this.$message.error({ message: res.message });
      }
    },

    // 多选框----------------------------------
    handleSelectionChange(val) {
      let arr = val;
      let arrlist = arr.map((item) => {
        return item.out_trade_no;
      });
      this.userId = arrlist;
      // console.log(this.userId);
    },

    // 获取列表
    async getList() {
      const time = {
        txTimeStart: this.startTime[0],
        txTimeEnd: this.startTime[1],
      };

      const res = await withdrawListApi({
        state: 0,
        ...this.formInline,
        ...time,
      });
      // console.log(res.data.list);
      this.tableData = res.data.list;
      this.formInline.total = res.data.total_count;
    },

    // 审核驳回弹框-----------------------------------
    rejected(out_trade_no) {
      this.rejectedVisible = true;
      this.userId = [out_trade_no];
    },

    // 审核驳回提交
    async doRejected() {
      console.log("审核驳回提交", this.userId);
      this.batchRejectFn();
    },

    // 线下弹框-----------------------------------
    turn(out_trade_no) {
      this.offlinePassVisible = true;
      this.userId = [out_trade_no];
    },

    // 线下打款
    async doOfflinePass() {
      console.log("线下打款");
      this.batchOffFn();
    },

    // 支付打款弹框-------------------------
    pass(out_trade_no) {
      this.weChatPassVisible = true;
      this.userId = [out_trade_no];
    },
    // 支付宝打款
    async doWeChatPass() {
      // console.log("支付宝打款");
      this.batchFn();
    },

    //弹框：批量支付宝----------------------------------
    zfbAll() {
      console.log("批量支付宝打款");
      if (this.userId.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchWeChatPassVisible = true;
    },
    // 提交：批量支付宝
    async doBatchWeChatPass() {
      this.batchFn();
    },

    //弹框：批量线下打款 ----------------------------------
    offlineAll() {
      console.log("批量线下打款");
      if (this.userId.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchOfflinePassVisible = true;
    },
    // 提交：批量线下打款
    async doBatchOfflinePass() {
      console.log("提交");
      this.batchOffFn();
    },

    //弹框：批量审核驳回 ----------------------------------
    turnAll() {
      console.log("批量审核驳回");
      if (this.userId.length <= 0) {
        this.$message.error("请勾选有效的数据!");
        return;
      }
      this.batchRejectedVisible = true;
    },
    // 提交：批量审核驳回
    doBatchRejected() {
      if (this.remark == "") {
        this.$message.error("请输入正确的驳回原因!");
        return;
      }
      this.batchRejectFn();
    },

    Submit() {
      console.log("查询");
      if (this.startTime === null) {
        this.startTime = ["", ""];
      }
      this.formInline.page = 1;
      this.getList();
    },

    onSubmit() {
      console.log("导出数据");
    },

    // a分页
    handleSizeChange(val) {
      this.formInline.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.getList();
    },
    // b分页

    // a表头样式
    tableHeaderCellStyle({ columnIndex, rowIndex }) {
      // console.log(columnIndex)
      // console.log(rowIndex)
      if (columnIndex === 0) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "15px 0px  0 15px",
        };
      } else if (columnIndex === 6) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "0px 15px  15px 0",
        };
      } else {
        return { backgroundColor: "#f7f7f7" };
      }
    },

    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          // backgroundColor: 'red',
          borderRadius: "20px 0px  0 20px",
        };
      } else if (columnIndex === 6) {
        return {
          // backgroundColor: '#red',
          borderRadius: "0px 20px  20px 0",
        };
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

// 导出
::v-deep .my-file {
  background: url("~@/assets/derive.png") center no-repeat; // 注意此处的url，在这里引入自己的图片
  font-size: 12px;
  background-size: cover;
}

::v-deep .my-file:before {
  content: "替";
  font-size: 15px;
  visibility: hidden;
}

// 查询按钮
.btn1 {
  background-color: #0077ff;
  border-color: #0077ff;
  // font-size: 14px;
}
.btn1:hover {
  background: #66b1ff;
  color: #fff;
  border-color: #66b1ff;
}

.btn2 {
  background-color: #eeeeee;
  width: 110px;
  border-color: #eeeeee;
  font-size: 14px;
  color: #666666;
}

.btn2:hover {
  background-color: #fbf8f8;
  width: 110px;
  border-color: #bdbdbd;
  font-size: 14px;
  color: rgb(76, 76, 76);
}

// 分页
.page {
  display: flex;
  justify-content: flex-end;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    border-radius: 5px;
    background-color: #fff; // 进行修改未选中背景和字体
    border: 1px solid #e5e5e5;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0077ff; // 进行修改选中项背景和字体
    color: #fff;
  }
}
</style>