<template>
    <el-dialog
        size="small"
        :visible.sync="dialogVisible"
        width="50%"
    >
      <div class="media_info">
        <h2 style="text-align: center;margin-top: -50px;">请选择{{this.mediaType}}类型文件</h2>
        <el-button
            class="filter-item"
            type="primary"
            icon="el-icon-back"
            size="small"
            @click="previousPage"
            v-show="superiorId!==-1"
        >返回上级</el-button>
        <el-table
            row-key="id"
            ref="multipleTable"
            :data="tableData"
            v-loading="listLoading"
            border
            @selection-change="handleSelectionChange"
            @select="selectOne"
            @select-all="selectAll"
            style="width: 100%;margin-top: 10px"
        >
          <el-table-column type="selection" :selectable="selectInit" width="50"> </el-table-column>
          <el-table-column prop="name" label="名称">
            <template slot-scope="scope">
              <span v-if="scope.row.file_type == 1" @click="nextFolder(scope.row)" class="row_name">{{scope.row.name}}</span>
              <span v-if="scope.row.file_type == 2" class="row_name_file">{{scope.row.name!=''?scope.row.name:scope.row.media_id}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="media_type" label="类型">
            <template slot-scope="scope">
              <el-tag type="warning" v-if="scope.row.file_type == 1">
                {{
                  scope.row.media_type == "image"
                      ? "图片文件夹"
                      : scope.row.media_type == "video"
                      ? "视频文件夹"
                      : scope.row.media_type == "audio"
                          ? "音频文件夹"
                          : scope.row.media_type == "text"
                              ? "文字文件夹" : "其他文件夹"
                }}
              </el-tag>

              <el-tag type="success" v-if="scope.row.file_type == 2">
                {{
                  scope.row.media_type == "image"
                      ? "图片"
                      : scope.row.media_type == "video"
                      ? "视频"
                      : scope.row.media_type == "audio"
                          ? "音频"
                          : scope.row.media_type == "text"
                              ? "文字" : "其他"
                }}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-row style="margin-top: 24px">
          <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-size="limit"
                    :total="totalCount"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" @click="confirm" size="small">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>

import { folderList,temReplaceMediaCheck,temReplaceMediaAdd } from "../../api/material";

export default {
  name: "BindingMedia",
  data() {
    return {
      dialogVisible:false,
      superiorIdArr:[],
      superiorId : -1,
      page: 1,
      limit: 10,
      tableData: [],
      totalCount: 0,
      multipleSelection: [],
      selectionIds: [],
      listLoading: false,
      templateId:'',
      templateMaterialId:'',
      mediaType:''
    };
  },
  watch:{
    multipleSelection(val) {
      console.log("multipleSelection")
    }
  },
  mounted(){
  },
  methods:{
    initData(row){
      console.log("initData")
      console.log("bindingInfo:",JSON.stringify(row))
      this.templateId = row.templateId
      console.log("templateId:",this.templateId)
      this.templateMaterialId = row.TemplateMaterialId
      console.log("templateMaterialId:",this.templateMaterialId)
      if(row.Type=="Text"){
        this.mediaType = "text"
      }
      if(row.Type=="Audio"){
        this.mediaType = "audio"
      }
      if(row.Type=="Video"){
        this.mediaType = "video"
      }
      if(row.Type=="Image"){
        this.mediaType = "image"
      }
      console.log("mediaType:",this.mediaType)
      this.doTemReplaceMediaCheck(row)
    },
    doTemReplaceMediaCheck(row){
      this.selectionIds = []
      temReplaceMediaCheck({
        "template_id": row.templateId,
        "template_material_id": row.TemplateMaterialId
      })
          .then((res) => {
            console.log(res);
            if (res.code == "0") {
              console.log("成功");
              if(res.data){
                this.selectionIds = res.data.replace_media
              }
              this.page = 1
              this.superiorId = -1
              this.superiorIdArr = []
              this.getList();
            }else{
              this.$message.error(res.message);
            }
          })
    },
    confirm(){
      console.log(JSON.stringify(this.selectionIds))
      temReplaceMediaAdd({
        "template_id": this.templateId,
        "template_material_id": this.templateMaterialId,
        "replace_media":this.selectionIds,
        "media_type":this.mediaType
      })
          .then((res) => {
            console.log(res);
            if (res.code == "0") {
              console.log("成功");
              this.$refs.multipleTable.clearSelection();
              this.$parent.initData();
              this.dialogVisible = false
            }else{
              this.$message.error(res.message);
            }
          })
    },
    cancel(){
      this.$refs.multipleTable.clearSelection();
      this.dialogVisible = false
    },
    selectInit(row,index){
      console.log("rowMediaType:",row.media_type)
      return true
    },
    previousPage(){
      this.superiorId = this.superiorIdArr.pop()
      this.page = 1
      console.log("previousPage:",this.superiorId)
      this.getList()
    },
    nextFolder(row){
      this.page = 1
      this.superiorIdArr.push(this.superiorId)
      this.superiorId = row.id
      console.log("superiorIdArr:",this.superiorIdArr)
      this.getList()
    },
    //分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getList();
    },
    handleSelectionChange(val) {
      // this.multipleSelection = val
      console.log("当前选中:",JSON.stringify(this.selectionIds))
    },
    remove(selectionIds,val) {
      let index = selectionIds.indexOf(val);
      if (index > -1) {
        selectionIds.splice(index, 1);
      }
      console.log("remove:",val)
      console.log("selectionIds:",this.selectionIds)
    },
    selectOne(selection, row){
      let check = null;
      selection.map((item) => {
        if(item.id === row.id){
          check = true;
        }
      })
      if(check){
        console.log("选中:",row.id)
        this.selectionIds.push(row.id)
      }else{
        console.log("取消选中:",row.id)
        this.remove(this.selectionIds,row.id)
      }
    },
    selectAll(selection){
      let _this = this;
      console.log(selection)
      if (selection.length == 0){
        console.log("取消选择")
        _this.tableData.map((item) => {
          _this.remove(_this.selectionIds,item.id)
        })
      }else {
        console.log("全部选择")
        _this.tableData.forEach((item) => {
          let index = _this.selectionIds.indexOf(item.id);
          if (index > -1) {
            console.log("00000")
          }else{
            _this.selectionIds.push(item.id)
          }
        });
      }
    },
    selectCheck(){
      let _this = this;
      _this.$nextTick(()=>{
        console.log("_this.$refs",_this.$refs)
        _this.tableData.map((item) => {
          _this.selectionIds.find(value => {
            if(value == item.id){
              _this.$refs.multipleTable.toggleRowSelection(item, true)
            }
          })
        });
      })
    },
    // 获取列表数据
    getList() {
      this.listLoading = true;
      let queryParams = {
        superiorId: this.superiorId,
        page: this.page,
        limit: this.limit,
        sidx: "create_time",
        order: "desc",
      };
      folderList(queryParams).then((response) => {
        console.log(response)
        this.tableData = response.data.list;
        this.totalCount = response.data.total_count;
        this.listLoading = false;
        this.selectCheck()
        this.dialogVisible = true
      });
    },
  }
}
</script>

<style scoped>
.row_name{
  color: #1482f0;
}

.row_name_file{
  color: #8c939d;
}
</style>
