<template>
  <!-- 2:打款中 :inline="true" -->
  <div>
    <!-- 打款中2 -->
    <el-form size="small" :model="formInline" class="demo-form-inline">
      <div style="display: flex">
        <el-form-item style="margin-right: 20px">
          <el-input
            clearable
            style="width: 320px"
            v-model="formInline.queryOne"
            placeholder="提现单号"
          ></el-input>
        </el-form-item>

        <el-form-item style="margin-right: 20px">
          <el-input
            clearable
            v-model="formInline.queryTwo"
            placeholder="用户昵称/手机号"
          ></el-input>
        </el-form-item>

        <el-date-picker
          style="margin-right: 10px"
          size="small"
          v-model="startTime"
          type="daterange"
          range-separator="—"
          start-placeholder="发起提现时间"
          end-placeholder="发起提现时间"
          :default-time="['00:00:00', '23:59:59']"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </div>

      <el-date-picker
        style="margin-right: 10px"
        size="small"
        v-model="auditTime"
        type="daterange"
        range-separator="—"
        start-placeholder="审核时间"
        end-placeholder="审核时间"
        :default-time="['00:00:00', '23:59:59']"
        value-format="yyyy-MM-dd HH:mm:ss"
      >
      </el-date-picker>
      <el-button class="btn1" type="primary" size="mini" @click="Submit"
        >查询</el-button
      >

      <el-button class="btn2" type="primary" @click="onSubmit" size="mini"
        >导出数据
        <i class="my-file"></i>
      </el-button>
    </el-form>

    <!-- 表格 -->
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      style="width: 100%; margin: 20px 0px"
      :header-cell-style="tableHeaderCellStyle"
      :cell-style="tableCellStyle"
    >
      <el-table-column
        header-align="center"
        align="center"
        prop="out_trade_no"
        label="提现单号"
      >
      </el-table-column>

      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="用户信息"
      >
        <!--  #default="{ row }" -->
        <template #default="{ row }">
          <div class="flex-left">
            <el-avatar size="medium" :src="row.user_ava"></el-avatar>
            <div style="margin-left: 5px">
              <div>{{ row.user_nick_name }}</div>
              <div>{{ row.user_phone }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

        <el-table-column
        header-align="center"
        align="center"
        prop="user_type"
        label="用户类型"
      >
        <template slot-scope="scope">
          <div v-if="scope.row.user_type == 1">创作者</div>
          <div v-if="scope.row.user_type == 2">团长</div>
        </template>
      </el-table-column>


      <el-table-column
        header-align="center"
        align="center"
        prop="balance"
        label="提现金额(元)"
      >
        <template slot-scope="scope">
          {{ scope.row.balance | numberFormat }}
        </template>
      </el-table-column>

      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="提现支付宝"
      >
        <!--  #default="{ row }" -->
        <template #default="{ row }">
          <div class="flex-left">
            <el-avatar size="medium" :src="row.acc_avatar"></el-avatar>
            <div style="margin-left: 5px">{{ row.acc_nick_name }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="create_time"
        label="发起提现时间"
      >
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="audit_time"
        label="审核时间"
      >
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="打款商户号"
      >
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="bus_status"
        label="打款进度"
      >
      </el-table-column>
    </el-table>
    <!-- 表格 -->

    <!-- a分页 -->
    <div class="page">
      <div class="box" />
      <div class="box" />
      <div class="box box-right">
        <el-pagination
          class="page"
          background
          :current-page="formInline.page"
          :page-sizes="[10, 20, 30]"
          :page-size="100"
          layout="total, sizes, prev, pager, next, jumper"
          :total="formInline.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <!-- b分页 -->
  </div>
</template>
 
<script>
import { withdrawListApi } from "@/api/app_wallet_withdrawal.js";

export default {
  name: "remit",

  filters: {
    numberFormat: function (value) {
      return value.toFixed(2);
    },
  },

  data() {
    return {
      loading: false,

      // 查询
      formInline: {
        queryOne: "",
        queryTwo: "",
        page: 1,
        limit: 10,
        total: 0,
        sidx: "create_time",
        order: "desc",
      },
      tableData: [
        {
          name: "hh",
        },
        {
          name: "hh",
        },
      ],
      startTime: "", //发起提现时间
      auditTime: "", //审核时间
      multipleSelection: [], // 多选选中的列表
    };
  },

  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      const time = {
        txTimeStart: this.startTime[0],
        txTimeEnd: this.startTime[1],
        auditTimeStart: this.auditTime[0],
        auditTimeEnd: this.auditTime[1],
      };

      const res = await withdrawListApi({
        state: 1,
        ...this.formInline,
        ...time,
      });
      // console.log(res.data.list);
      this.tableData = res.data.list;
      this.formInline.total = res.data.total_count;
    },

    Submit() {
      console.log("查询", this.startTime, this.auditTime);
      if (this.startTime === null) {
        this.startTime = ["", ""];
      }

      if (this.auditTime === null) {
        this.auditTime = ["", ""];
      }
      this.formInline.page = 1;
      this.getList();
    },

    onSubmit() {
      console.log("导出数据");
    },

    // a分页
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
      this.formInline.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.formInline.page = val;
      this.getList();
    },
    // b分页

    // a表头样式
    tableHeaderCellStyle({ columnIndex, rowIndex }) {
      // console.log(columnIndex)
      // console.log(rowIndex)
      if (columnIndex === 0) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "15px 0px  0 15px",
        };
      } else if (columnIndex === 7) {
        return {
          backgroundColor: "#f7f7f7",
          borderRadius: "0px 15px  15px 0",
        };
      } else {
        return { backgroundColor: "#f7f7f7" };
      }
    },

    tableCellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        return {
          // backgroundColor: 'red',
          borderRadius: "20px 0px  0 20px",
        };
      } else if (columnIndex === 7) {
        return {
          // backgroundColor: '#red',
          borderRadius: "0px 20px  20px 0",
        };
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-left {
  display: flex;
  align-items: center;
}

// 导出
::v-deep .my-file {
  background: url("~@/assets/derive.png") center no-repeat; // 注意此处的url，在这里引入自己的图片
  font-size: 12px;
  background-size: cover;
}

::v-deep .my-file:before {
  content: "替";
  font-size: 15px;
  visibility: hidden;
}

// 查询按钮
.btn1 {
  background-color: #0077ff;

  border-color: #0077ff;
  font-size: 14px;
}
.btn1:hover {
  background: #66b1ff;
  color: #fff;
  border-color: #66b1ff;
}

.btn2 {
  background-color: #eeeeee;
  width: 110px;
  border-color: #eeeeee;
  font-size: 14px;
  color: #666666;
}

.btn2:hover {
  background-color: #fbf8f8;
  width: 110px;
  border-color: #bdbdbd;
  font-size: 14px;
  color: rgb(76, 76, 76);
}

// 分页
.page {
  display: flex;
  justify-content: flex-end;
}

::v-deep {
  .el-pagination.is-background .el-pager li:not(.disabled) {
    border-radius: 5px;
    background-color: #fff; // 进行修改未选中背景和字体
    border: 1px solid #e5e5e5;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #0077ff; // 进行修改选中项背景和字体
    color: #fff;
  }
}
</style>