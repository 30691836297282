import service from '../request'

export function userList(query,data) {
    return service({
        url: '/api/creator/wxUserApply/userList',
        method: 'get',
        params: query,
        data: data
    })
}

export function userUpdate(data) {
    return service({
        url: '/api/creator/wxUserApply/userUpdate',
        method: 'post',
        data: data
    })
}


export function checkPending(query,data) {
    return service({
        url: '/api/creator/wxUserApply/checkPending',
        method: 'get',
        params: query,
        data: data
    })
}

export function wxUserLabelList(query,data) {
    return service({
        url: '/api/creator/wxUserLabel/list',
        method: 'get',
        params: query,
        data: data
    })
}

export function wxUserLabelAdd(data) {
    return service({
        url: '/api/creator/wxUserLabel/add',
        method: 'post',
        data: data
    })
}

export function wxUserLabelUpdate(data) {
    return service({
        url: '/api/creator/wxUserLabel/update',
        method: 'post',
        data: data
    })
}

export function wxUserLabelDel(data) {
    return service({
        url: '/api/creator/wxUserLabel/del',
        method: 'post',
        data: data
    })
}

export function pass(data) {
    return service({
        url: '/api/creator/wxUserApply/pass',
        method: 'post',
        data: data
    })
}

export function passByMerchants(data) {
    return service({
        url: '/api/creator/wxUserApply/passByMerchants',
        method: 'post',
        data: data
    })
}

export function rejected(data) {
    return service({
        url: '/api/creator/wxUserApply/rejected',
        method: 'post',
        data: data
    })
}


export function userTimeCard(data) {
    return service({
        url: '/api/creator/wxUserApply/userTimeCard',
        method: 'post',
        data: data
    })
}

export function userTimeCardByMerchants(data) {
    return service({
        url: '/api/creator/wxUserApply/userTimeCardByMerchants',
        method: 'post',
        data: data
    })
}

export function userTimeCardResd(query,data) {
    return service({
        url: '/api/creator/wxUserApply/userTimeCardResd',
        method: 'get',
        params: query,
        data: data
    })
}
