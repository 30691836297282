<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <el-table
        size="small"
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="date"
          label="用户信息"
          align="center">
        <template slot-scope="scope">
          <div style="display: flex;align-items: center">
            <img :src="scope.row.avatar_url" style="width: 50px;height: 50px;border-radius: 50%" />
            <div style="display: flex;flex-direction: column;justify-content: space-around;text-align: left;margin-left: 10px">
              <div>{{scope.row.nick_name}}</div>
              <div>{{scope.row.phone_number}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="reason"
          label="申请理由"
          align="center">
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="申请时间"
          align="center">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
      >
        <template slot-scope="scope">
          <div>
            <el-button  @click="passUpdate(scope.row)" type="text" size="small"
            >审核通过</el-button>
            <el-button  @click="rejectedUpdate(scope.row)" type="text" size="small"
            >审核驳回</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

    <el-dialog title="审核通过" :visible.sync="passOpen" width="30%">
      <el-form
          size="small"
          ref="form"
          :model="passForm"
          label-width="120px"
          style="padding-right: 20px"
      >
        <el-form-item label="昵称手机号:" prop="name">
          <span>{{passForm.nick_name}}</span>
          <span style="margin-left: 5px">{{passForm.phone_number}}</span>
        </el-form-item>
        <el-form-item label="用户等级" prop="name">
          <el-select v-model="passForm.user_label" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.label_name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="passSubmitForm" size="small">确 认</el-button>
        <el-button @click="passCancel" size="small">取 消</el-button>
      </div>
    </el-dialog>


    <el-dialog size="small" title="审核驳回" :visible.sync="rejectedOpen" width="30%">
      <el-form
          ref="form"
          :model="rejectedForm"
          label-width="120px"
          style="padding-right: 20px"
      >
        <span style="margin-left: 5px">{{rejectedForm.nick_name}}</span>
        <span style="margin-left: 5px">{{rejectedForm.phone_number}}</span>

        <el-input
            style="margin-top: 20px"
            type="textarea"
            maxlength="30"
            show-word-limit
            placeholder="请输入驳回原因,最多可输入30个汉字"
            v-model="rejectedForm.rejected_reason">
        </el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="rejectedSubmitForm" size="small">确 认</el-button>
        <el-button @click="rejectedCancel" size="small">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { checkPending ,wxUserLabelList ,passByMerchants ,rejected} from  "../../api/userManage";
export default {
  name: "clip_user_audit",
  data(){
    return{
      rejectedOpen:false,
      rejectedForm:{
      },
      options:[],
      passOpen:false,
      passForm: {
      },
      queryParams:{
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc",
      },
      total:0,
      tableData: [],
    }
  },
  created() {
    this.initOptions()
    this.initData();
  },
  methods:{
    rejectedSubmitForm(){
      let body = {
        id: this.rejectedForm.id,
        rejected_reason: this.rejectedForm.rejected_reason
      }
      rejected(body).then((response) => {
        this.rejectedCancel()
        this.initData()
      });
    },
    rejectedCancel(){
      this.rejectedOpen = false
      this.rejectedForm = {}
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    passSubmitForm(){
      if(this.passForm.user_label == ''){
        this.$message.error("请选择用户标签!");
        return;
      }
      let body = {
        user_label: this.passForm.user_label,
        id: this.passForm.id
      }
      passByMerchants(body).then((res) => {
        if(res.status == 1) {
          this.passCancel()
          this.initData()
        }else{
          this.$message.error(res.message);
        }
      });
    },
    passCancel(){
      this.passOpen = false
      this.passForm = {}
    },
    initOptions(){
      let queryParams = {
        page: 1,
        limit: 999,
        sidx: "create_time",
        order: "desc",
      };
      wxUserLabelList(queryParams).then((response) => {
        this.options = response.data.list;
      });
    },
    passUpdate(row){
      this.passOpen = true
      if(row.user_label !== ''){
        row.user_label = JSON.parse(row.user_label)
      }else{
        row.user_label = []
      }
      this.passForm = row
    },
    rejectedUpdate(row){
      this.rejectedOpen = true
      row.rejected_reason = ''
      this.rejectedForm = row
    },
    initData(){
      checkPending(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
      });
    }
  }
}
</script>

<style scoped>

</style>
