<template>
  <div class="mini_program">
    <div>
      微信小程序
      <div style="padding-left: 20px;padding-top: 20px;padding-bottom: 100px" v-if="authorizerInfo.authorizer == true">
        代码管理
        <el-form class="custom_form_item" ref="form" label-width="100px" :label-position="labelPosition"
                 :model="authorizerInfo">
<!--          <el-form-item label="自动更新：">-->
<!--            有新版本时自动上传代码提交微信审核-->
<!--            <el-switch-->
<!--                style="margin-left: 10px"-->
<!--                v-model="value"-->
<!--                active-color="#13ce66"-->
<!--                inactive-color="#ff4949">-->
<!--            </el-switch>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="自动发布：">-->
<!--            审核通过后系统会自动发布最新版本小程序-->
<!--            <el-switch-->
<!--                style="margin-left: 10px"-->
<!--                v-model="value"-->
<!--                active-color="#13ce66"-->
<!--                inactive-color="#ff4949">-->
<!--            </el-switch>-->
<!--          </el-form-item>-->
          <el-form-item label="体验者：">
            <el-input v-model="wechatid" placeholder="请输入微信号" style="width: 20%" size="small"></el-input>
            <el-button type="primary" round size="mini" style="margin-left: 10px" @click="doBindTester">确定</el-button>
            <el-button type="danger" round size="mini" @click="doUnbindTester">解绑</el-button>
          </el-form-item>
          <el-form-item label="上传代码：">
            <el-button type="primary" round size="mini" @click="doCommit">上传</el-button>
            <span>
              <span style="margin: 10px">最新版本号：{{ onlineTemplate.user_version }} </span>
              <span style="margin: 10px">当前体验版本号：{{ versionInfo.exp_info.exp_version }} </span>
            </span>
          </el-form-item>
          <el-form-item label="提交审核：">
            <el-button type="primary" round size="small" @click="submitAudit">审核</el-button>
            <span>
              <span style="margin: 10px">最新审核版本：{{ latestAudits.user_version }} </span>
              <span style="margin: 10px">版本描述：{{ latestAudits.user_desc }} </span>
              <span style="margin: 10px">提交时间：{{
                  formatDate(latestAudits.submit_audit_time * 1000, "YYYY-mm-dd HH:MM:SS")
                }} </span>
              <span style="margin: 10px">审核状态：
                <span v-if="latestAudits.status == 0">审核成功</span>
                <span v-if="latestAudits.status == 1">
                  <span>审核被拒绝</span>
                  <div v-html="latestAudits.reason"></div>
                </span>
                <span v-if="latestAudits.status == 2">审核中</span>
                <span v-if="latestAudits.status == 3">已撤回</span>
                <span v-if="latestAudits.status == 4">审核延后</span>
              </span>

            </span>
          </el-form-item>
          <el-form-item label="发布小程序：">
            <el-button type="primary" round size="small" @click="release">发布</el-button>
            <span style="margin: 10px">当前线上版本号：{{ versionInfo.release_info.release_version }} </span>
          </el-form-item>
        </el-form>


        <el-divider></el-divider>
        小程序基本信息
        <el-form class="custom_form_item" label-width="120px" :label-position="labelPosition" :model="authorizerInfo">
          <el-form-item label="小程序名称：">
            {{ authorizerInfo.authorizer_info.nick_name }}
          </el-form-item>
          <el-form-item label="小程序头像：">
            <el-image
                style="width: 100px; height: 100px"
                :src="authorizerInfo.authorizer_info.head_img"
                fit="cover">
            </el-image>
          </el-form-item>
          <el-form-item label="主体信息：">
            {{ authorizerInfo.authorizer_info.principal_name }}
          </el-form-item>
          <el-form-item label="小程序简介：">
            {{ authorizerInfo.authorizer_info.signature }}
          </el-form-item>
          <el-form-item label="微信认证：">
            {{ authorizerInfo.authorizer_info.nick_name }}
          </el-form-item>
          <el-form-item label="小程序原始ID：">
            {{ authorizerInfo.authorizer_info.user_name }}
          </el-form-item>
          <el-form-item label="AppId：">
            {{ authorizerInfo.authorization_info.authorizer_appid }}
          </el-form-item>
          <el-form-item label="线上正式码：">
            <el-image
                style="width: 120px; height: 120px"
                :src="onlineQrcode"
                fit="cover">
            </el-image>
          </el-form-item>
          <el-form-item label="体验二维码：">
            <el-image
                style="width: 120px; height: 120px"
                :src="testQrcode"
                fit="cover">
            </el-image>
          </el-form-item>
        </el-form>
      </div>

      <div style="display: flex; padding: 10px" v-if="authorizerInfo.authorizer == false">
        <div class="item_info">
          <div class="item_info_text">已经拥有微信小程序</div>
          <el-button  type="primary" style="width: 70%" round
                     @click="componentLoginPage">授权微信小程序
          </el-button>
        </div>
        <div class="item_info">
          <div class="item_info_text">还没有微信小程序</div>
          <el-button type="primary" style="width: 70%" round
                     @click="jumpWeixin">去小程序官方注册
          </el-button>
        </div>
      </div>
    </div>

    <!--    新增编辑微信商户弹窗-->
    <el-dialog
        :title="wechatPayTitle"
        :visible.sync="wechatPayVisible"
        width="60%">

      <el-form :rules="wechatPayFormRules" ref="wechatPayForm" class="wechatPayForm" label-width="160px"
               :label-position="labelPosition" :model="wechatPayForm">
        <el-form-item label="支付顺序：" prop="custom_sort">
          <el-input v-model="wechatPayForm.custom_sort" placeholder="请输入支付顺序 => 序号大优先使用" size="small"></el-input>
        </el-form-item>
        <el-form-item label="商户号：" prop="merchant_id">
          <el-input v-model="wechatPayForm.merchant_id" placeholder="请输入商户号" size="small"></el-input>
        </el-form-item>
        <el-form-item label="商户名称：" prop="merchant_name">
          <el-input v-model="wechatPayForm.merchant_name" placeholder="请输入商户名称" size="small"></el-input>
        </el-form-item>
        <el-form-item label="商户证书序列号：" prop="merchant_serial_number">
          <el-input v-model="wechatPayForm.merchant_serial_number" placeholder="请粘贴商户证书序列号" size="small"></el-input>
        </el-form-item>
        <el-form-item label="API-V3密钥：" prop="api_v3_key">
          <el-input v-model="wechatPayForm.api_v3_key" placeholder="请输入API-V3密钥" size="small"></el-input>
        </el-form-item>
        <el-form-item label="商户私钥：" prop="private_key">
          <el-input
              type="textarea"
              autosize
              v-model="wechatPayForm.private_key" placeholder="请输入商户私钥" size="small"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="wechatPayVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitWechatPayForm">确 定</el-button>
      </span>
    </el-dialog>


    <TalentMatrix ref="talentMatrix"></TalentMatrix>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {componentLoginPage} from "../../api/home";
import {wechatPayList, wechatPaySaveOrUpdate} from "../../api/wechatPay";
import {
  authorizerInfo,
  apiQueryAuth,
  getVersioninfo,
  onlineTemplate,
  commit,
  getQrcode,
  getWxccodeUnlimit,
  bindTester,
  unbindTester,
  release,
  submitAudit,
  getLatestAuditstatus
} from "../../api/mini_program";
import TalentMatrix from "../../components/talent_matrix";
import {enterpriseCertInfo, merchantsInfo} from "../../api/merchantsUser";
import {delStencil} from "../../api/clip_tem_list";

export default {
  name: "index",
  components: {
    TalentMatrix
  },
  data() {
    return {
      userInfo: {
        talent_matrix: false
      },
      wechatPayFormRules: {
        custom_sort: [
          {required: true, message: '请输入支付序号', trigger: 'blur'}
        ],
        merchant_id: [
          {required: true, message: '请输入商户号', trigger: 'blur'}
        ],
        merchant_name: [
          {required: true, message: '请输入商户名称', trigger: 'blur'}
        ],
        merchant_serial_number: [
          {required: true, message: '请输入商户证书序列号', trigger: 'blur'}
        ],
        api_v3_key: [
          {required: true, message: '请输入商户证书序列号', trigger: 'blur'}
        ],
        private_key: [
          {required: true, message: '请输入商户私钥', trigger: 'blur'}
        ]
      },
      wechatPayTitle: "",
      wechatPayVisible: false,
      wechatPayForm: {},
      wechatPayList: [],
      wechatid: '',
      testQrcode: '',
      onlineQrcode: '',
      labelPosition: 'right',
      authorizerInfo: {
        authorizer_info: {},
        authorization_info: {},
      },
      versionInfo: {
        release_info: {
          release_version: '未提交'
        },
        exp_info: {
          exp_version: "未提交"
        }
      },
      onlineTemplate: {},
      latestAudits: {}
    }
  },
  created() {
    this.doAuthorizerInfo();
    this.doOnlineTemplate();
    this.doGetVersioninfo();
  },
  mounted() {
    this.initData()
    let query = this.$route.query;
    console.log(JSON.stringify(query))
    let auth_code = this.$route.query.auth_code
    if (auth_code != null) {
      this.doApiQueryAuth(auth_code)
    }
    this.doGetQrcode()
    this.doGetLatestAuditstatus();
    this.doWechatPayList()
  },
  methods: {
    initData() {
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
    },
    submitWechatPayForm() {
      this.$refs['wechatPayForm'].validate((valid) => {
        if (valid) {
          wechatPaySaveOrUpdate(this.wechatPayForm).then(res => {
            this.$message.success({
              duration: 1000,
              message: '操作成功'
            });
            this.doWechatPayList()
            this.wechatPayVisible = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addWechatPay() {
      this.wechatPayVisible = true
      this.wechatPayForm = {}
      this.wechatPayTitle = '新增微信商户'
    },
    editWechatPay(row) {
      this.wechatPayTitle = '编辑微信商户'
      this.wechatPayForm = row
      this.wechatPayVisible = true
    },
    delWechatPay(row) {
      this.$confirm("确认删除: " + row.merchant_name + " 收款商户?", "删除收款商户", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            row.is_delete = 1
            wechatPaySaveOrUpdate(row).then(res => {
              this.$message.success({
                duration: 1000,
                message: "收款商户: " + row.merchant_name + "删除成功"
              });
              this.doWechatPayList()
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    doWechatPayList() {
      wechatPayList({}).then((response) => {
        this.wechatPayList = response.data.list
      });
    },
    formatDate(millisecond, template) {
      var res = "";
      try {
        var date = new Date(millisecond);
        var opt = {
          "Y+": date.getFullYear().toString(), // 年
          "m+": (date.getMonth() + 1).toString(), // 月
          "d+": date.getDate().toString(), // 日
          "H+": date.getHours().toString(), // 时
          "M+": date.getMinutes().toString(), // 分
          "S+": date.getSeconds().toString(), // 秒
        };
        template = template || "YYYY-mm-dd";
        for (var k in opt) {
          var ret = new RegExp("(" + k + ")").exec(template);
          if (ret) {
            template = template.replace(
                ret[1],
                ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
            );
          }
        }
        res = template;
      } catch (error) {
        console.warn("ERROR formatDate", error);
      }
      return res;
    },
    doGetLatestAuditstatus() {
      getLatestAuditstatus({}).then(res => {
        this.latestAudits = res.data
      })
    },
    submitAudit() {
      submitAudit({}).then(res => {
        if (res.data.errcode == 0) {
          this.$message.success({
            duration: 1000,
            message: '提交成功,请耐心等待'
          });
        }
        if (res.data.errcode == 85009) {
          this.$message.error({message: '重复提交,请耐心等待'});
        }
        this.doGetLatestAuditstatus();
      })
    },
    release() {
      release({}).then(res => {
        if (res.data.errcode == 85020) {
          this.$message.error({message: '审核中请等待'});
        }
        if (res.data.errcode == 85052) {
          this.$message.error({message: '已发布,请勿重复操作'});
        }
        this.doGetVersioninfo();
      })
    },
    doBindTester() {
      bindTester({}, {
        wechatid: this.wechatid
      }).then(res => {
        if (res.code == 0) {
          if (res.data.errcode == 0) {
            this.$message.success({
              duration: 1000,
              message: '绑定成功'
            });
          } else if (res.data.errcode == 85004) {
            this.$message.error({message: '该用户已是体验成员'});
          } else if (res.data.errcode == 85001) {
            this.$message.error({message: '用户不存在'});
          } else {
            this.$message.error({message: '绑定失败'});
          }
        } else {
          this.$message.error({message: '绑定失败'});
        }
      })
    },
    doUnbindTester() {
      unbindTester({}, {
        wechatid: this.wechatid
      }).then(res => {
        if (res.code == 0) {
          if (res.data.errcode == 0) {
            this.$message.success({
              duration: 1000,
              message: '解绑成功'
            });
          } else {
            this.$message.error({message: '解绑失败'});
          }
        } else {
          this.$message.error({message: '解绑失败'});
        }
      })
    },
    doGetQrcode() {
      getQrcode({}).then((response) => {
        this.testQrcode = response.data
      });
      getWxccodeUnlimit({}).then((response) => {
        this.onlineQrcode = response.data
      });
    },
    doCommit() {
      commit({}).then((res) => {
        console.log(JSON.stringify(res))
        if(res.data.errcode == 48001){
          this.$message.error({message: '公众号/小程序尚未将对应的权限集授权给第三方平台,请检查是否完成认证'});
          return
        }
        if(res.data.errcode == 61007){
          this.$message.error({message: '公众号/小程序尚未将对应的权限集授权给第三方平台,请检查是否完成认证'});
          return
        }
        this.$message.success({
          duration: 1000,
          message: '代码上传成功'
        });
        this.doGetVersioninfo();
        this.doGetQrcode()
      });
    },
    doOnlineTemplate() {
      onlineTemplate({}).then((response) => {
        this.onlineTemplate = response.data
      });
    },
    doGetVersioninfo() {
      getVersioninfo({}).then((response) => {
        if (response.data.release_info) {
          this.versionInfo.release_info = response.data.release_info
        }
        if (response.data.exp_info) {
          this.versionInfo.exp_info = response.data.exp_info
        }
      });
    },
    componentLoginPage() {
      if(this.userInfo.talent_matrix === false){
        this.$confirm("你还未开通达人矩阵系统，无法绑定小程序?", "提示", {
          confirmButtonText: "去开通",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              enterpriseCertInfo({}).then((res) => {
                console.log(JSON.stringify(res.data))
                let certification_status = res.data.certification_status
                if(certification_status ===2 || certification_status ===3){
                  this.$refs.talentMatrix.show(1)
                }else{
                  this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
                    confirmButtonText: "去认证",
                    cancelButtonText: "取消",
                    type: "warning",
                  }).then(() => {
                    this.$router.push("/authentication_protocol");
                  }).catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消",
                    })
                  })
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
      }else{
        let path = window.location.href
        console.log(path)
        let query = {
          REDIRECT_URL: path + "?merchantsId=" + this.USER_INFO.id
        }
        componentLoginPage(query).then((response) => {
          let url = response.data;
          console.log(url)
          window.open(url, '_blank');
        });
      }
    },
    jumpWeixin() {
      if(this.userInfo.talent_matrix === false){
        this.$confirm("你还未开通达人矩阵系统，无法绑定小程序?", "提示", {
          confirmButtonText: "去开通",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              enterpriseCertInfo({}).then((res) => {
                console.log(JSON.stringify(res.data))
                let certification_status = res.data.certification_status
                if(certification_status ===2 || certification_status ===3){
                  this.$refs.talentMatrix.show(1)
                }else{
                  this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
                    confirmButtonText: "去认证",
                    cancelButtonText: "取消",
                    type: "warning",
                  }).then(() => {
                    this.$router.push("/authentication_protocol");
                  }).catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消",
                    })
                  })
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
      }else{
        window.open('https://mp.weixin.qq.com', '_blank');
      }
    },
    doApiQueryAuth(authorization_code) {
      let query = {
        authorization_code: authorization_code
      }
      apiQueryAuth(query).then((response) => {
        console.log(JSON.stringify(response))
        this.doAuthorizerInfo();
      });
    },
    doAuthorizerInfo() {
      authorizerInfo({}).then((response) => {
        let authorizerInfo = response.data;
        if (authorizerInfo.authorizer_info == null) {
          console.log('没有授权信息')
          this.authorizer = false
          authorizerInfo.authorizer = false
        } else {
          console.log('展示展示授权信息')
          this.authorizer = true
          authorizerInfo.authorizer = true
        }
        this.authorizerInfo = authorizerInfo
        console.log(JSON.stringify(authorizerInfo))
      });
    },
  },
  computed: {
    ...mapState(['USER_INFO'])
  },
}
</script>

<style scoped>
.mini_program {
  background-color: white;
  margin: 10px;
  padding: 10px;
}

.item_info {
  width: 500px;
  height: 200px;
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.item_info_text {
  color: rgba(56, 56, 56, 1);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom_form_item .el-form-item {
  margin-bottom: 5px;
}

</style>
