import service from "../request";
// 系统公告:
// 新增或修改
export function saveOrUpdate(data) {
    return service({
        url: '/api/merchants/systemNotice/saveOrUpdate',
        method: 'post',
        data: data
    })
}


// 列表 0：草稿箱 2：已推送
export function noticeList(query) {
    return service({
        url: '/api/merchants/systemNotice/noticeList',
        method: 'get',
        params: query
    })
}

// 详情
export function noticeDetail(query) {
    return service({
        url: '/api/merchants/systemNotice/noticeDetail',
        method: 'get',
        params: query
    })
}


// 删除
export function deletePush(data) {
    return service({
        url: '/api/merchants/systemNotice/deletePush',
        method: 'post',
        data: data
    })
}

// 推送
export function pushNotice(data) {
    return service({
        url: '/api/merchants/systemNotice/pushNotice',
        method: 'post',
        data: data
    })
}