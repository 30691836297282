<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <el-tabs v-model="queryParams.status" @tab-click="handleClick">
      <el-tab-pane label="待处理" name="0"></el-tab-pane>
      <el-tab-pane label="退款中" name="1"></el-tab-pane>
    </el-tabs>

    <div>
      <el-input
          size="small"
          placeholder="源订单id"
          v-model="queryParams.out_trade_no"
          clearable
          style="width: 120px;"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="昵称/手机号"
          v-model="queryParams.query"
          clearable
          style="width: 120px;margin-left: 10px"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="退款订单id"
          v-model="queryParams.out_refund_no"
          clearable
          style="width: 120px;margin-left: 10px"
      >
      </el-input>
      <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          size="small"
          @click="search()"
      >搜索</el-button>
    </div>

    <el-table
        :data="tableData"
        :loading="listLoading"
        border
        style="width: 100%;margin-top: 10px"
        :max-height="autoHeight"
    >
      <el-table-column align="center" prop="out_trade_no" label="源订单id">
      </el-table-column>
      <el-table-column align="center" label="用户信息" >
        <template slot-scope="scope">
          <div style="display: flex">
            <el-image
                style="width: 50px;height: 50px"
                :src="scope.row.avatar_url"
                fit="cover">
            </el-image>
            <div style="flex: 1">
              <div>{{scope.row.nick_name}}</div>
              <div>{{scope.row.phone_number}}</div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="out_refund_no" label="退款id" >
      </el-table-column>
      <el-table-column align="center" prop="total" label="退款金额(元)" >
        <template slot-scope="scope">
          {{scope.row.refund | numberFormat}}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="reason" label="退款原因" >
      </el-table-column>
      <el-table-column align="center" prop="create_time" label="提交时间">
      </el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="detail(scope.row)">退款详情</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        size="small"
        title="退款详情"
        :visible.sync="dialogVisible"
        width="60%">
      <div>
        <div>
          <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">源订单ID：{{wechatPay.out_trade_no}}</div>
          <div style="display: flex;justify-content: space-between">
            <div style="font-size: 14px;font-weight: 500;color: rgba(56, 56, 56, 1);">{{wechatPay.members_name}}</div>
            <div style="font-size: 14px;font-weight: 500;color: rgba(56, 56, 56, 1);">支付金额：¥{{wechatPay.total | numberFormat}}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">会员有效期：{{wechatPay.effective_days | formattingEffectiveDays}}</div>
            <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">生成卡数量：{{wechatPay.gen_card_quota}}</div>
          </div>
          <div style="display: flex;justify-content: space-between">
            <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">支付时间：{{wechatPay.success_time}}</div>
            <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">交易单号：{{wechatPay.transaction_id}}</div>
          </div>
        </div>
        <el-divider></el-divider>
        <div>
          <span style="font-size: 14px;font-weight: 500;color: rgba(56, 56, 56, 1);">退款详情</span>
          <div style="padding: 10px">
            <div style="margin-bottom: 10px;" v-for="(item,index) in refundList" :key="index">
              <div class="refund_item">退款ID：{{item.out_refund_no}}</div>
              <div class="refund_item">申请退款时间：{{item.create_time}}</div>
              <div class="refund_item">申请退款金额：¥{{item.refund | numberFormat }}</div>
              <div class="refund_item" v-if="item.executing_state === 1">
                <div>已退款金额：¥{{item.refund | numberFormat }}</div>
                <div>审核通过时间：{{item.success_time}}</div>
                <div>退款完成时间：{{item.success_time}}</div>
              </div>
              <div class="refund_item" v-if="item.executing_state === 2">
                <div>审核驳回时间：{{item.refuse_time}}</div>
                <div>驳回理由：{{item.refuse_reason}}</div>
              </div>
              <div class="refund_item" v-if="item.executing_state === 0">
                <el-form size="small" ref="ruleForm" :rules="rules" :model="operationForm" label-width="100px">
                  <el-form-item label="退款审核:" required prop="type">
                    <el-radio-group v-model="operationForm.type">
                      <el-radio :label="1">审核通过</el-radio>
                      <el-radio :label="2">审核驳回</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="驳回原因:" required prop="refuseReason" v-if="operationForm.type===2">
                    <el-input
                        maxlength="100"
                        show-word-limit
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 10}"
                        placeholder="请输入驳回原因"
                        v-model="operationForm.refuseReason">
                    </el-input>
                  </el-form-item>
                </el-form>
              </div>

              <div style="text-align: right">
                <el-button v-if="index === (refundList.length - 1)" size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button v-if="item.executing_state === 0" size="small" type="primary" @click="confirm(item)">确 定</el-button>
              </div>

            </div>
          </div>
        </div>
      </div>
      <el-dialog
          width="30%"
          :visible.sync="innerVisible"
          append-to-body>
        <el-result icon="warning" title="提示">
          <template slot="extra">
            <div style="color: red">{{innerMsg}}</div>
          </template>
        </el-result>
      </el-dialog>
    </el-dialog>


  </div>
</template>

<script>
import { wechatPayRefundList ,wechatPayRefundInfo ,pass ,rejected} from "../../api/wechatPayRefund";

export default {
  name: "WechatPayRefund",
  filters: {
    formattingEffectiveDays(effectiveDays){
      console.log("effectiveDays:" + effectiveDays)
      if(effectiveDays>=365){
        return effectiveDays/365 + "年"
      }
      if(effectiveDays>=30){
        return effectiveDays/30 + "月"
      }
      return effectiveDays + '天'
    },
    numberFormat: function (value) {
      return value.toFixed(2)
    },
  },
  data(){
    return{
      innerMsg:'',
      innerVisible: false,
      ruleForm: null,
      wechatPay:{
        total: 0.00
      },
      refundList:[],
      rules: {},
      operationForm:{
        type: 1,
        outRefundNo: '',
        refuseReason: ''
      },
      dialogVisible:false,
      tableData:[],
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        status: '0',
        out_trade_no:'',
        query:'',
        out_refund_no:'',
        page:1,
        limit:10,
        sidx: 'swpr.create_time',
        order: 'desc'
      },
      total:0,
      listLoading:false
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
  },
  methods:{
    confirm(item){
      console.log(JSON.stringify(item))
      this.operationForm.outRefundNo = item.out_refund_no
      console.log(JSON.stringify(this.operationForm))
      if(this.operationForm.type === 1){
        console.log("审核通过")
        pass(this.operationForm).then(res=>{
          console.log(JSON.stringify(res))
          if (res.code == "0") {
            this.$message.success({
              duration: 1000,
              message: '操作成功'
            });
            this.dialogVisible = false
            this.initData()
          }else{
            // this.$message.error(res.message);
            this.innerMsg = res.message
            this.innerVisible = true
          }
        })
      }
      if(this.operationForm.type === 2){
        console.log("审核驳回")
        rejected(this.operationForm).then(res=>{
          console.log(JSON.stringify(res))
          if (res.code == "0") {
            this.$message.success({
              duration: 1000,
              message: '驳回成功'
            });
            this.dialogVisible = false
            this.initData()
          }else{
            this.$message.error(res.message);
          }
        })
      }
    },
    detail(row){
      console.log(JSON.stringify(row))
      wechatPayRefundInfo({id : row.out_trade_no}).then((res) => {
          this.wechatPay = res.data.wechatPay
          this.refundList = res.data.refundList
          this.operationForm.outRefundNo = row.id
          this.dialogVisible = true
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.queryParams.page = 1
      this.total = 0
      this.tableData= []
      this.initData()
    },
    search(){
      this.queryParams.page = 1
      this.initData()
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 200) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true;
      wechatPayRefundList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

/deep/ .el-dialog__body{
  padding-top: 0px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.refund_item{
  font-size: 14px;
  font-weight: 400;
  color: rgba(56, 56, 56, 1);
}
</style>
