<template>
<div>
  <el-table
      :data="tableData"
      v-loading="listLoading"
      border
      style="width: 100%;"
      max-height="650"
  >
    <el-table-column align="center" prop="create_time" label="套餐/有效期">
      <template slot-scope="scope">
        <div>
         {{scope.row.cloud_size | formatFileSize }}
        </div>
        <div>
          {{scope.row.start_time }} 至 {{scope.row.end_time }}
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="create_time" label="付款金额">
      <template slot-scope="scope">
        <span v-if="scope.row.pay_way != 3">¥ {{scope.row.total_amount}}</span>
        <span v-else>-</span>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="create_time" label="订单信息">
      <template slot-scope="scope">
        <div>订单编号:{{scope.row.out_trade_no}}</div>
        <div>下单时间:{{scope.row.create_time}}</div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="create_time" label="支付方式/流水号">
      <template slot-scope="scope">
        <div>
          <span v-if="scope.row.pay_way === 1">微信</span>
          <span v-if="scope.row.pay_way === 2">支付宝</span>
          <span v-if="scope.row.pay_way === 3">平台新增</span>
          <span v-if="scope.row.pay_way === 4">注册赠送</span>
          <span v-if="scope.row.pay_way === 5">开通达人矩阵系统赠送</span>
          <span v-if="scope.row.pay_way === 6">续费达人矩阵系统赠送</span>
          <span v-if="scope.row.pay_way === 7">开通Ai云剪辑系统赠送</span>
          <span v-if="scope.row.pay_way === 8">续费Ai云剪辑系统赠送</span>
        </div>
        <div>{{scope.row.trade_no}}</div>
      </template>
    </el-table-column>
  </el-table>

  <el-row style="margin-top: 24px">
    <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="queryParams.page"
              :page-size="queryParams.limit"
              :total="totalCount"
              layout="total, prev, pager, next"
          ></el-pagination>
        </span>
    </el-col>
  </el-row>
</div>
</template>

<script>
import { cloudPackageRecord } from "../../../api/my_cloud_info";

export default {
  name: "cloud_record",
  filters: {
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024*1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024*1024*1024)) {
        temp = fileSize / (1024*1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else {
        temp = fileSize / (1024*1024*1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      }
    },
    numFilter (value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    }
  },
  data(){
    return{
      queryParams:{
        page: 1,
        limit: 10,
        sidx: 'create_time',
        order: 'desc'
      },
      tableData: [],
      listLoading: false,
      totalCount:0
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true;
      cloudPackageRecord(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.totalCount = response.data.total_count;
        this.listLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
