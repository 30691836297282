import service from "../request";

export function account(query) {
    return service({
        url: '/api/merchants/overview/electricity/account',
        method: 'get',
        params: query
    })
}

export function manage(query) {
    return service({
        url: '/api/merchants/overview/electricity/manage',
        method: 'get',
        params: query
    })
}

export function electricityInfo(query) {
    return service({
        url: '/api/merchants/overview/electricity/info',
        method: 'get',
        params: query
    })
}

export function getPayImg(data) {
    return service({
        url: '/api/merchants/overview/electricity/getPayImg',
        method: 'post',
        data: data
    })
}

export function check(query) {
    return service({
        url: '/api/merchants/overview/electricity/check',
        method: 'get',
        params: query
    })
}
