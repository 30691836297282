<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <el-button
        class="filter-item"
        type="primary"
        size="small"
        @click="handleAdd"
    >新建分类</el-button>

    <el-table
        size="small"
        :data="tableData"
        style="width: 100%">
      <el-table-column
          prop="systematic_name"
          label="分类名称"
          align="center">
      </el-table-column>
      <el-table-column
          prop="sort"
          label="排序"
          align="center">
      </el-table-column>
      <el-table-column
          prop="show_status"
          label="是否显示"
          align="center">
        <template slot-scope="scope">
          <el-tag  v-if="scope.row.show_status == true" effect="dark">是</el-tag>
          <el-tag v-else type="danger" effect="dark">否</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="创建时间"
          align="center">
      </el-table-column>
      <el-table-column
          prop="template_num"
          label="模板数量"
          align="center">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
      >
        <template slot-scope="scope">
          <div>
            <el-button  @click="handleUpdate(scope.row)" type="text" size="small"
            >修改</el-button>
            <el-button  @click="handleDel(scope.row)" v-show="scope.row.template_num==0" type="text" size="small"
            >删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>



    <el-dialog :title="title" :visible.sync="open" width="50%">
      <el-form
          ref="form"
          :model="form"
          label-width="100px"
          style="padding-right: 20px"
      >
        <el-form-item label="分类名称" prop="name">
          <el-input v-model="form.systematic_name" placeholder="请输入分类名称" />
        </el-form-item>
        <el-form-item label="排序" prop="name">
          <el-input style="width: 30%" v-model="form.sort" type="number" placeholder="请输入排序" />
          <span style="color: #8c939d;margin-left: 10px">数字越大排序越靠前,0为默认排序(按上架时间排序)</span>
        </el-form-item>
        <el-form-item label="是否显示: " prop="name">
          <el-radio-group v-model="form.show_status">
            <el-radio :label="true">显示</el-radio>
            <el-radio :label="false">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 认</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import { ctrClaifitionTemList,ctrClaifitionTemAdd,ctrClaifitionTemUpdate,ctrClaifitionTemDel } from "../../api/tem_class";

export default {
  name: "clip_tet_class",
  data(){
    return{
      open: false,
      title: "",
      form: {},
      queryParams:{
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc",
      },
      total:0,
      tableData: [],
    }
  },
  created() {
    this.initData();
  },
  methods:{
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    handleDel(val) {
      this.$confirm("确认删除该分类?", "删除分类", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            ctrClaifitionTemDel({ id: val.id }).then((res) => {
              if (res.code == "0") {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.initData();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    // 编辑模板
    handleUpdate(row) {
      this.open = true;
      this.title = "编辑分类";
      this.form = row;
    },
    initData(){
      ctrClaifitionTemList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
    submitForm(){
      console.log(JSON.stringify(this.form));
      if (this.form.id) {
        ctrClaifitionTemUpdate(this.form).then((res) => {
          if (res.code == "0") {
            this.open = false;
            this.form = {}
            this.$message.success("编辑成功");
            this.initData();
          }
        });
      } else {
        ctrClaifitionTemAdd(this.form).then((res) => {
          if (res.code == "0") {
            this.open = false;
            this.form = {}
            this.$message.success("添加成功");
            this.initData();
          }
        });
      }
    },
    cancel() {
      this.open = false;
      this.form = {}
    },
    // 添加模板
    handleAdd() {
      this.open = true;
      this.title = "新建分类";
      this.form = {
        show_status:true,
        sort:0
      };
    },
  }
}
</script>

<style scoped>

</style>
