<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <el-button
      size="small"
      style="margin-top: 10px"
      type="primary"
      @click="save()"
      >修改</el-button
    >
    <div
      ref="editor"
      @input="editorChange"
      style="margin-top: 20px; width: 100%"
    />
  </div>
</template>

<script>
import { getOssAccessInfo } from "../../api/common.js";
import {
  tutorialInfo,
  tutorialUpdate,
} from "../../api/video_job/video_job_tutorial";

import Sha1 from "js-sha1";
var OSS = require("ali-oss");

import E from "wangeditor";

export default {
  name: "editBanner",
  data() {
    return {
      labelPosition: "right",
      info: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData(id) {
      let _this = this;
      tutorialInfo({ id: id }).then((response) => {
        _this.info = response.data;
        var editor = new E(_this.$refs.editor);
        editor.config.zIndex = 10;
        editor.config.customUploadImg = function (files, insert) {
          files.forEach((image) => {
            getOssAccessInfo().then((res) => {
              const client = new OSS({
                region: "oss-cn-hangzhou",
                accessKeyId: res.data.access_key_id,
                accessKeySecret: res.data.access_key_secret,
                bucket: "cjfx-res",
                stsToken: res.data.security_token,
              });
              console.log(client);
              let randomStr = "";
              for (var i = 0; i < 9; i++) {
                randomStr =
                  randomStr + Math.floor(Math.random() * 10).toString();
              }
              let fileName =
                "/merchantscenter/video_job_tutorial/" +
                Sha1(
                  _this.$store.state.token + new Date().getTime() + randomStr
                ) +
                image.type;
              console.log(fileName);
              client.put(fileName, image).then((res) => {
                console.log(JSON.stringify(res));
                let imgUrl =
                  "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
                insert(imgUrl);
              });
            });
          });
        };
        editor.config.onchange = (html) => {
          this.info.content = html;
        };
        editor.create();
        editor.txt.html(this.info.content);
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/video_job_tutorial/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.bannerForm.img_url =
            "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
          console.log(JSON.stringify(this.bannerForm));
          this.$message.success("上传成功");
        });
      });
    },
    save() {
      tutorialUpdate(this.info).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "更新成功",
          });
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    editorChange(e) {
      this.bannerForm.content = e.target.innerHTML;
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
