import Vue from 'vue'
import VueRouter from 'vue-router'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)
import store from "../store";


import routes from "./routes_all";
import {merchantsInfo} from "../api/merchantsUser";

let router =  new VueRouter({
    mode: process.env.VUE_APP_MODE === 'production' ? 'history' : 'hash',
    base: process.env.VUE_APP_MODE === 'production' ? '' : '/alliance',
    routes
})

router.beforeEach((to, from, next) => {

    if(to.path != '/login_options' && to.path != '/data_report' && to.path != '/register'){
        if(to.path == '/'){
            store.commit('INIT_MENU',false)
        }else{
            store.commit('INIT_MENU',true)
        }
    }

    let headInfo = to.meta.headInfo
    if(!headInfo){
        headInfo = {
                showHeader: true,
                showMenu: true,
                showUserInfo: true
        }
    }
    store.commit("HEAD_INFO",headInfo)
    store.commit("ACTIVE_PATH",to.path)
    if(headInfo.showHeader == true){
        merchantsInfo({}).then((res) => {
            // console.log("merchantsInfo:" + JSON.stringify(res))
            store.commit("USER_INFO",res.data.merchantsInfo)
            store.commit("CREATOR_USER_INFO",res.data.creatorUser)
        });
    }

    next()
})

export default router
