import service from '../request'

// 分类列表
export function typeListApi(query) {
    return service({
        url: '/api/merchants/sklmHelpCenter/typeList',
        method: 'get',
        params: query
    })
}


// 帮助中心列表
export function CenterListApi(query) {
    return service({
        url: '/api/merchants/sklmHelpCenter/list',
        method: 'get',
        params: query
    })
}


// 保存或更新分类
export function typeSaveApi(data) {
    return service({
        url: '/api/merchants/sklmHelpCenter/typeSave',
        method: 'post',
        data: data
    })
}


// 分类删除
export function typeDeleteApi(data) {
    return service({
        url: '/api/merchants/sklmHelpCenter/typeDelete',
        method: 'post',
        data: data
    })
}

// 保存或更新帮助中心
export function klmHelpCenterApi(data) {
    return service({
        url: '/api/merchants/sklmHelpCenter/save',
        method: 'post',
        data: data
    })
}


// 帮助中心删除
export function sklmHelpCenterApi(data) {
    return service({
        url: '/api/merchants/sklmHelpCenter/delete',
        method: 'post',
        data: data
    })
}



