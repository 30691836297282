<template>
  <div style="padding: 10px;margin: 10px">

    <div style="display: flex;background: white;justify-content: space-between;align-items: center;padding: 10px">
      <div>
        <div style="color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 500;">是否自动提现</div>
        <div style="color: rgba(56, 56, 56, 1);font-size: 14px;font-weight: 400;">开启后用户发起提现后无需后台审核，系统自动执行微信打款操作</div>
      </div>
      <el-switch
          v-model="ruleInfo.automatic"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>

    <div style="padding: 10px;margin-top: 10px;background: white;">
       <div style="display: flex;justify-content: space-between;align-items: center">
         <div style="color: rgba(56, 56, 56, 1);font-size: 16px;font-weight: 500;">
          微信打款设置
         </div>
         <el-button type="primary" @click="addRules" size="small">保存</el-button>
       </div>
      <div style="margin-top: 5px; color: rgba(56, 56, 56, 1);font-size: 14px;font-weight: 400;">单笔最高可申请提现金额为<el-input style="width: 80px;margin-left: 10px;margin-right: 10px" size="small" v-model.number="ruleInfo.single_apply_highest_amount" placeholder="0"></el-input> 元</div>
      <div style="margin-top: 5px;color: rgba(56, 56, 56, 1);font-size: 14px;font-weight: 400;">单笔最低可申请提现金额为<el-input style="width: 80px;margin-left: 10px;margin-right: 10px" size="small" v-model.number="ruleInfo.single_apply_minimum_amount" placeholder="0"></el-input>元</div>
      <div style="margin-top: 5px;color: rgba(56, 56, 56, 1);font-size: 14px;font-weight: 400;">每次提现系统将会收取<el-input style="width: 80px;margin-left: 10px;margin-right: 10px" size="small" v-model.number="ruleInfo.commission_fee_ratio" placeholder="0"></el-input>%的提现通道服务费，每人每天仅可发起提现<el-input style="width: 80px;margin-left: 10px;margin-right: 10px" size="small" v-model.number="ruleInfo.day_limit_number" placeholder="0"></el-input>次。</div>

      <el-divider></el-divider>
      <div>
        备注
        <div>
          <div :index="index"  v-for="(item,index) in ruleInfo.remarks" :key="index">
            <el-input  style="margin-top: 10px;width: 60%" v-model="ruleInfo.remarks[index]" placeholder="请输入备注"></el-input>
            <i class="el-icon-delete" style="font-size: 20px;margin-left: 10px" @click="delItem(index)"></i>
          </div>
          <el-button style="width: 60%;margin-top: 10px;" type="primary" @click="addRemarks" size="small">新增备注</el-button>
        </div>
      </div>


    </div>

 </div>
</template>

<script>
import { ruleInfo, ruleUpdate} from "../../api/financial_center";

export default {
  name: "ruleInfo",
  data(){
    return {
      ruleInfo:{
        automatic: false,
        single_apply_highest_amount: 0,
        single_apply_minimum_amount: 0,
        commission_fee_ratio: 0,
        day_limit_number: 0,
        remarks: []
      }
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    addRemarks(){
      this.ruleInfo.remarks.push("")
    },
    delItem(index){
      this.ruleInfo.remarks.splice(index, 1);
    },
    addRules(){
      ruleUpdate(this.ruleInfo).then((res) => {
        if(res.code==0) {
          this.$message.success({message: "操作成功"});
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    initData() {
      ruleInfo({}).then((res) => {
        if(res.data != null){
          this.ruleInfo = res.data;
        }
      });
    },
  }
  }
</script>

<style scoped>
  .del_item{
    position: absolute;
    left:0; right:0; top:0; bottom:0;
    margin:auto;
  }
</style>
