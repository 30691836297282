<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div>
      <div style="display: flex;justify-content: space-between;padding: 10px">
        <div style="display: flex">
          <el-image
              style="width: 80px; height: 80px"
              :src="grassTaskInfo.trill_product.img"
              fit="cover">
          </el-image>
          <div style="margin-left: 10px; display: flex;flex-direction: column;justify-content: space-between">
            <div>{{grassTaskInfo.trill_product.name}}</div>
            <div>{{grassTaskInfo.task_start_time }} 至 {{grassTaskInfo.task_end_time }}</div>
          </div>
        </div>
        <div>
          <el-button size="small" v-if="grassTaskInfo.task_state == 1" type="success">进行中</el-button>
          <div v-if="grassTaskInfo.task_state == 2" style="text-align: right">
            <el-button size="small"  type="warning">已结束</el-button>
            <div style="margin-top: 10px">{{grassTaskInfo.task_end_time}}</div>
          </div>
          <div v-if="grassTaskInfo.task_state == 3" style="text-align: right">
            <el-button size="small"  type="warning">强制结束</el-button>
            <div style="margin-top: 10px">{{parseDateFull(grassTaskInfo.finish_time)}}</div>
          </div>
        </div>
      </div>
      <div style="display: flex;margin-top: 20px">
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.limit_num}}</div>
          <div class="info_item_text">可参与人数</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.participation}}</div>
          <div class="info_item_text">已报名人数</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.linkCount}}</div>
          <div class="info_item_text">已回传链接</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.playCount}}</div>
          <div class="info_item_text">总播放量</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.diggCount}}</div>
          <div class="info_item_text">总点赞数</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.commentCount}}</div>
          <div class="info_item_text">总评论数</div>
        </div>
        <div class="info_item">
          <div class="info_item_value">{{grassTaskInfo.shareCount}}</div>
          <div class="info_item_text">总分享数</div>
        </div>
      </div>
    </div>

    <el-divider></el-divider>
    <div>

      <div style="margin-top: 10px;text-align:left;padding-right: 10px">
        <el-button size="small" v-if="grassTaskInfo.task_state != 1" @click="batchPassVisible = true">批量通过</el-button>
        <el-button size="small" v-if="grassTaskInfo.task_state != 1" @click="batchRejectedVisible = true">批量驳回</el-button>
        <el-input
            size="small"
            placeholder="用户昵称"
            v-model="queryParams.userName"
            clearable
            style="width: 120px;margin-left: 10px"
        >
        </el-input>
        <el-input
            size="small"
            placeholder="抖音昵称"
            v-model="queryParams.tiktokName"
            clearable
            style="width: 120px;margin-left: 10px"
        >
        </el-input>
        <span style="margin-left: 10px; color: rgba(166, 166, 166, 1);">生成视频:</span>
        <el-select size="small" v-model="queryParams.generate" placeholder="请选择" style="margin-left: 5px">
          <el-option
              v-for="item in generate_options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>

        <span style="margin-left: 10px; color: rgba(166, 166, 166, 1);">回传链接:</span>
        <el-select size="small" v-model="queryParams.comes_back" placeholder="请选择" style="margin-left: 5px">
          <el-option
              v-for="item in comes_back_options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>

        <el-button
            size="small"
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-search"
            @click="initData"
        >查询</el-button>
      </div>

      <el-table
          :data="tableData"
          :loading="listLoading"
          border
          style="margin-top: 10px;width: 100%"
          :max-height="autoHeight"
          :max-width="autoWidth"
          row-key="id"
          ref="multipleTable"
          @selection-change="handleSelectionChange"
          @select="selectOne"
          @select-all="selectAll"
      >
        <el-table-column
            v-if="grassTaskInfo.task_state != 1"
            type="selection"
            :selectable="handleDisable"
            width="55">
        </el-table-column>
        <el-table-column align="center" label="用户信息" width="250">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: center;align-items: center">
              <el-image
                  style="width: 48px; height: 48px;border-radius: 50%;"
                  :src="scope.row.wx_user_info.avatar_url"
                  fit="cover">
              </el-image>
              <div style="margin-left: 10px; text-align: left">
                <div>{{scope.row.wx_user_info.nick_name}}</div>
                <div>手机号：{{scope.row.wx_user_info.phone_number}}</div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="抖音信息"  width="250">
          <template slot-scope="scope">
              <div style="display: flex;align-items: center">
                <el-image
                    style="width: 48px; height: 48px;border-radius: 50%;"
                    :src="scope.row.trill_user_info.avatar"
                    fit="cover">
                </el-image>
                <div style="margin-left: 10px; text-align: left">
                  <div>{{scope.row.trill_user_info.nickname}}</div>
                  <div>粉丝: {{scope.row.trill_user_info.fans_number}}</div>
                </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="时间信息"  width="250">
          <template slot-scope="scope">
            <div style="text-align: left">
              <div>报名任务：{{parseDateFull(scope.row.create_time)}}</div>
              <div>生成视频：
                <span v-if="scope.row.job_id_update_time !=null">{{parseDateFull(scope.row.job_id_update_time)}}</span>
                <span v-else>-</span>
              </div>
              <div>回传链接：
                <span v-if="scope.row.item_id_update_time !=null">{{parseDateFull(scope.row.item_id_update_time)}}</span>
                <span v-else>-</span>
              </div>
              <div v-if="grassTaskInfo.task_state != 1">结算时间：
                <span v-if="scope.row.settlement_time !=null">{{parseDateFull(scope.row.settlement_time)}}</span>
                <span v-else>-</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="发布详情"  width="400">
          <template slot-scope="scope">
              <div v-if="scope.row.tiktokVideo !== null" style="display: flex;">
                <img :src="scope.row.tiktokVideo.cover" width="100px"/>
                <div style="padding: 10px; display: flex;flex-direction: column;justify-content: space-between;text-align: left">
                  <div>{{scope.row.tiktokVideo.title}}</div>
                  <div>已发布 {{ parseFull(scope.row.tiktokVideo.create_time * 1000)}}</div>
                  <div class="issue_bottom">
                    <div class="issue_item">
                      <img class="issue_icon" src="../../assets/grass_task/look.png"/>
                      <div>
                        {{scope.row.tiktokVideo.play_count}}
                      </div>
                    </div>
                    <div class="issue_item">
                      <img class="issue_icon" src="../../assets/grass_task/good.png" />
                      <div>{{scope.row.tiktokVideo.digg_count}}
                      </div>
                    </div>

                    <div class="issue_item">
                      <img class="issue_icon" src="../../assets/grass_task/commit.png" />
                      <div>
                        {{scope.row.tiktokVideo.comment_count}}
                      </div>
                    </div>

                    <div class="issue_item">
                      <img class="issue_icon" src="../../assets/grass_task/share.png" />
                      <div>
                        {{scope.row.tiktokVideo.share_count}}
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="审核状态"  width="250" v-if="grassTaskInfo.task_state != 1">
          <template slot-scope="scope">
            <div v-if="scope.row.task_status == 1">
              <div>审核通过</div>
              <div>{{parseFull(scope.row.settlement_time)}}</div>
            </div>
            <div v-if="scope.row.task_status == 2">
              <div>审核不通过</div>
              <div>{{parseFull(scope.row.settlement_time)}}</div>
              <div>{{scope.row.dismiss_reason}}</div>
            </div>
            <div v-if="scope.row.operation_type == 1">
              人工审核
            </div>
            <div v-if="scope.row.operation_type == 2">
              系统审核
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作"  width="250">
          <template slot-scope="scope">
            <el-button
                v-if="scope.row.tiktokVideo !=null"
                @click.native.prevent="jumpDouyin(scope.row)"
                type="text"
                size="small">
              跳转到抖音
            </el-button>
            <el-button
                v-if="scope.row.task_status == 0 && scope.row.task_status != 1 && grassTaskInfo.task_state != 1"
                @click.native.prevent="doPassVisible(scope.row)"
                type="text"
                size="small">
              审核通过
            </el-button>
            <el-button
                v-if="scope.row.task_status == 0 && scope.row.task_status != 2 && grassTaskInfo.task_state != 1"
                @click.native.prevent="doRejectedVisible(scope.row)"
                type="text"
                size="small">
              审核驳回
            </el-button>
          </template>
        </el-table-column>

      </el-table>

      <el-row style="margin-top: 24px;margin-right: 20px">
        <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="this.queryParams.page"
                    :page-size="this.queryParams.limit"
                    :total="this.total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
        </el-col>
      </el-row>
    </div>

    <!--审核通过-->
    <el-dialog
        title="审核通过"
        :visible.sync="passVisible"
        width="30%">
      <div style="text-align: center">
        <div>确认审核通过?</div>
        <div>审核通过后,任务奖励立即结算</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passVisible = false">取 消</el-button>
        <el-button type="primary" @click="doGrassTaskUserPass()">确 定</el-button>
      </span>
    </el-dialog>

    <!--批量审核通过-->
    <el-dialog
        title="批量审核通过"
        :visible.sync="batchPassVisible"
        width="30%">
      <div style="text-align: center">
        <div>确认批量审核通过?</div>
        <div>批量审核通过后,任务奖励立即结算</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchPassVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchPass()">确 定</el-button>
      </span>
    </el-dialog>



<!--审核驳回-->
    <el-dialog
        title="审核驳回"
        :visible.sync="rejectedVisible"
        width="30%">
      <div style="text-align: center">
        <span style="margin-right: 10px">驳回原因:</span>
        <el-select v-model="dismiss_reason" placeholder="请选择">
          <el-option
              v-for="item in dismiss_reason_options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rejectedVisible = false">取 消</el-button>
        <el-button type="primary" @click="doGrassTaskUserRejected">确 定</el-button>
      </span>
    </el-dialog>

    <!--批量审核驳回-->
    <el-dialog
        title="批量审核驳回"
        :visible.sync="batchRejectedVisible"
        width="30%">
      <div style="text-align: center">
        <span style="margin-right: 10px">驳回原因:</span>
        <el-select v-model="dismiss_reason" placeholder="请选择">
          <el-option
              v-for="item in dismiss_reason_options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="batchRejectedVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBatchRejected">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {
  batchPass, batchRejected,
  grassTaskInfo,
  grassTaskUserList,
  grassTaskUserPass,
  grassTaskUserRejected
} from "../../api/grass_task";

export default {
  name: "taskDetails",
  data(){
    return{
      batchPassVisible:false,
      batchRejectedVisible:false,
      generate_options:[
        {
          value: 1,
          label: '全部'
        },
        {
          value: 2,
          label: '已生成'
        },
        {
          value: 3,
          label: '未生成'
        }
      ],
      comes_back_options:[
        {
          value: 1,
          label: '全部'
        },
        {
          value: 2,
          label: '已回传'
        },
        {
          value: 3,
          label: '未回传'
        }
      ],
      dismiss_reason_options: [],
      dismiss_reason: '',
      dialogObj: {},
      passVisible:false,
      rejectedVisible:false,
      grassTaskInfo:{},
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        sidx : "sgtu.create_time",
        order : "desc",
        page: 1,
        limit: 5,
        grass_task_id:"",
        generate:1,
        comes_back:1
      },
      total:0,
      tableData:[
      ],
      selectionIds:[],
      multipleSelection:[]
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted() {
    let id = this.$route.query.id
    this.queryParams.grass_task_id = id
    this.doGrassTaskInfo()
    this.initData();
  },
  methods:{
    handleDisable(row,index){
      if (row.task_status != 1 && row.task_status != 2) {
        return true
      }else{
        return false
      }
    },
    handleSelectionChange(val) {
      console.log("当前选中:",JSON.stringify(val))
      this.multipleSelection = val;
    },
    remove(selectionIds,val) {
      let index = selectionIds.indexOf(val);
      if (index > -1) {
        selectionIds.splice(index, 1);
      }
      console.log("remove:",val)
      console.log("selectionIds:",this.selectionIds)
    },
    selectOne(selection, row) {
      let check = null;
      selection.map((item) => {
        if(item.id === row.id){
          check = true;
        }
      })
      if(check){
        console.log("选中:",row.id)
        this.selectionIds.push(row.id)
      }else{
        console.log("取消选中:",row.id)
        this.remove(this.selectionIds,row.id)
      }
    },
    selectAll(selection) {
      let _this = this;
      console.log(selection)
      if (selection.length == 0){
        console.log("取消选择")
        _this.tableData.map((item) => {
          _this.remove(_this.selectionIds,item.id)
        })
      }else {
        console.log("全部选择")
        _this.tableData.forEach((item) => {
          let index = _this.selectionIds.indexOf(item.id);
          if (index > -1) {
            console.log("已经含有啦")
          }else{
            _this.selectionIds.push(item.id)
          }
        });
      }
    },
    selectCheck(){
      let _this = this;
      _this.$nextTick(()=>{
        console.log("_this.$refs",_this.$refs)
        _this.tableData.map((item) => {
          _this.selectionIds.find(value => {
            if(value == item.id){
              _this.$refs.multipleTable.toggleRowSelection(item, true)
            }
          })
        });
      })
    },
    initDismissReasonOptions(){
      let dismiss_reason_options = [{
        value: '任务视频链接错误/未回传',
        label: '任务视频链接错误/未回传'
      }, {
        value: '未按任务要求发布视频',
        label: '未按任务要求发布视频'
      }, {
        value: '任务视频已删除/未公开',
        label: '任务视频已删除/未公开'
      }];
      if(this.grassTaskInfo.task_state == 3){ //该任务为强制结束任务
        dismiss_reason_options.push({
          value: '平台提前结束任务',
          label: '平台提前结束任务'
        })
      }
      this.dismiss_reason_options = dismiss_reason_options
    },
    doBatchPass(){
      console.log("当前选中:",JSON.stringify(this.selectionIds))
      let body = {
        ids : this.selectionIds
      }
      batchPass(body).then((res) => {
        if(res.code==0) {
          this.$message.success({message: "批量操作成功"});
          this.initData()
          this.batchPassVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    doBatchRejected(){
      console.log("当前选中:",JSON.stringify(this.selectionIds))
      let body = {
        ids : this.selectionIds,
        dismiss_reason: this.dismiss_reason
      }
      batchRejected(body).then((res) => {
        if(res.code==0) {
          this.$message.success({message: "批量操作成功"});
          this.initData()
          this.batchRejectedVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    doGrassTaskUserPass(){
      let body = {
        id : this.dialogObj.id
      }
      grassTaskUserPass(body).then((res) => {
        if(res.code==0) {
          this.initData()
          this.passVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    doGrassTaskUserRejected(){
      let body = {
        id : this.dialogObj.id,
        dismiss_reason: this.dismiss_reason
      }
      grassTaskUserRejected(body).then((res) => {
        if(res.code==0) {
          this.initData()
          this.rejectedVisible = false
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    jumpDouyin(row){
      console.log(JSON.stringify(row))
      window.open(row.tiktokVideo.share_url , "_blank");
    },
    doPassVisible(row){
      console.log(JSON.stringify(row))
      this.dialogObj = row
      this.passVisible = true
    },
    doRejectedVisible(row){
      console.log(JSON.stringify(row))
      this.dialogObj = row
      this.rejectedVisible = true
    },
    parseFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m ;
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m ;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    doGrassTaskInfo(){
      let queryParams = {
        id : this.queryParams.grass_task_id
      }
      grassTaskInfo(queryParams).then((response) => {
        this.grassTaskInfo = response.data;
        this.initDismissReasonOptions()
      });
    },
    initData() {
      this.listLoading = true;
      grassTaskUserList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
  }
}
</script>

<style scoped>
  .info_item{
    border-radius:50%;
    width: 110px;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(245, 245, 245, 1);
  }
  .info_item_value{
    color: rgba(0, 119, 255, 1);
    font-size: 18px;
    font-weight: 500;
  }
  .info_item_text{
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    font-weight: 400;
  }

  .issue_item{
    display: flex;
  }

  .issue_icon {
    width: 20px;
    height: 20px;
  }

  .issue_bottom {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    color: #333333;
  }

</style>
