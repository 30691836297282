import common from "./common";
import home from "./home";
import template from "./template";
import user from "./user";
import account from "./account";
import mini_program from "./mini_program";
import trill_goods from "./trill_goods";
import marketing from "./marketing";
import financialCenter from "./financialCenter"
import pageZx from "./page_zx"
import grassTask from "./grassTask"
import electricityMcn from "./electricityMcn"
import accountCenter from "./accountCenter"
import course from "./course"
import videoJob from "./video_job"

const routes = [
    ...common,
    ...home,
    ...trill_goods,
    ...template,
    ...user,
    ...marketing,
    ...pageZx,
    ...financialCenter,
    ...account,
    ...mini_program,
    ...grassTask,
    ...electricityMcn,
    ...accountCenter,
    ...course,
    ...videoJob
]

export default routes
