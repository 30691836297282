<template>
  <div>
    <el-button size="mini" type="primary" @click="dialogVisible = true">调整余额</el-button>
    <el-table Style="margin-top: 10px" border :data="tableData">
      <el-table-column align="center" prop="create_time" label="时间"></el-table-column>
      <el-table-column align="center" prop="balance" label="余额"></el-table-column>
      <el-table-column align="center" prop="remark" label="备注"></el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination background @current-change="handleCurrentChange" :current-page="queryParams.page"
            :page-size="queryParams.limit" :total="total" layout="total, prev, pager, next"></el-pagination>
        </span>
      </el-col>
    </el-row>


    <el-dialog size="small" width="30%" title="调整余额" :visible.sync="dialogVisible" append-to-body>
      <el-form size="small" :model="form" :rules="formRules" ref="balanceFormRulesInfo" label-width="100px">
        <el-form-item label="调整方式:" prop="rec_type">
          <el-radio-group v-model="form.rec_type">
            <el-radio :label="5">充值</el-radio>
            <el-radio :label="6">扣减</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="调整金额:" prop="balance">
          <el-input v-model="form.balance" placeholder="请输入金额"></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="remark">
          <el-input v-model="form.remark" placeholder="备注信息"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doBalanceUpdate">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { sxtblanceAdjust, getPutAccountApi } from "../../api/channel_user";
export default {
  name: "PutAccountSxtBlance",
  components: {
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    }
  },
  filters: {
    numberFormat: function (value) {
      return value.toFixed(2)
    }
  },
  data() {
    return {
      queryParams: {
        mcnUserId: '',
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total: 0,
      listLoading: false,
      tableData: [],
      form: {

      },
      formRules: {
        balance: [
          { required: true, message: '请输入有效的金额', trigger: 'blur' },
        ],
        remark: [
          { required: true, message: '请输入备注', trigger: 'blur' },
        ],
      },
      dialogVisible: false
    }
  },
  mounted() {
    this.queryParams.channel_user_id = this.userInfo.id
    let form = {
      channel_user_id: this.userInfo.id,
      balance: 0,
      rec_type: 5,
      remark: ""
    }
    this.form = form
    this.initData();
  },
  methods: {
    doBalanceUpdate() {
      let _this = this
      console.log(JSON.stringify(_this.form))
      this.$refs['balanceFormRulesInfo'].validate((valid) => {
        if (valid) {
          sxtblanceAdjust(_this.form).then(res => {
            if (res.code == 0) {
              _this.$message.success({
                duration: 1000,
                message: '操作成功'
              });
              _this.queryParams.page = 1
              _this.initData()
              _this.$parent.$parent.initData()
              _this.dialogVisible = false
            } else {
              this.$message.error({ message: res.message });
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData() {
      this.listLoading = true
      getPutAccountApi(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>
/deep/ .el-dialog__body {
  padding-top: 0px;
}
</style>
