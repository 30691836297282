<template>
  <div
    class="commission"
    style="background: white; padding: 20px; margin: 10px"
  >
    <!-- 电商mcn->用户提成设置 -->
    <div class="one">
      <div class="text">经纪人等级</div>
      <el-button
        type="primary"
        size="small"
        @click="add"
        style="background-color: #4561aa; width: 80px; border: none"
        >保存
      </el-button>
    </div>
    <!-- 输入框----   :rules="rules" -->
    <el-form
      size="small"
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <div
        class="text1"
        v-for="(item, index) in ruleForm.agentLevList"
        :key="item.agent_lever_id"
      >
        <div class="my-left2 my-text">{{ item.lever_name }}</div>
        <div class="my-text">团队自然月度已结算销售额指标</div>

        <div v-if="index === 0" class="my-text" style="padding: 0 10px">
          {{ ruleForm.agentLevList[0].sales }}
        </div>

        <!-- 对每个el-input进行校验 全部是必填    prop="sales"  :rules="[{ required: true, message: '请输入销售额' }]"-->
        <div v-else>
          <el-form-item
            label-width="10px"
            :prop="'agentLevList.' + index + '.sales'"
            :rules="{
              required: true,
              message: '请输入销售额',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="ruleForm.agentLevList[index].sales"
              clearable
            ></el-input>
          </el-form-item>
        </div>

        <div class="my-right my-text">元</div>
      </div>

      <!-- 非直播订单--------------------------------------------------------- -->
      <div class="one">
        <div class="text">非直播订单</div>
      </div>

      <div
        v-for="(item, index) in ruleForm.others"
        :key="item.master_lever_id"
        style="margin-bottom: 15px"
      >
        <div class="text1">
          <div class="my-left2 my-text" style="width: 145px">
            达人等级:{{ item.lever_name }}
          </div>
          <div class="my-text">自然月度已结算销售额指标</div>

          <!-- 做判断 有无输入框 -->
          <div v-if="index === 0" class="my-text" style="padding: 0 10px">
            {{ ruleForm.others[0].sales }}
          </div>

          <div v-else>
            <el-form-item
              label-width="10px"
              :prop="'others.' + index + '.sales'"
              :rules="{
                required: true,
                message: '请输入销售额',
                trigger: 'blur',
              }"
            >
              <el-input
                clearable
                v-model="ruleForm.others[index].sales"
              ></el-input>
            </el-form-item>
          </div>

          <div class="my-text" style="padding: 0 10px">元</div>

          <!-- <div class="my-text">基础比例</div>
          <el-form-item
            label-width="10px"
            :prop="'others.' + index + '.master_ratio'"
            :rules="{
              required: true,
              message: '请输入提成比例',
              trigger: 'blur',
            }"
          >
            <el-input
              clearable
              v-model="ruleForm.others[index].master_ratio"
            ></el-input>
          </el-form-item>
          <div class="my-text" style="padding: 0 10px">%</div> -->

          <div class="my-text">提成比例</div>
          <el-form-item
            label-width="10px"
            :prop="'others.' + index + '.master_ratio'"
            :rules="{
              required: true,
              message: '请输入提成比例',
              trigger: 'blur',
            }"
          >
            <el-input
              clearable
              v-model="ruleForm.others[index].master_ratio"
            ></el-input>
          </el-form-item>
          <div class="my-text" style="padding: 0 10px">%</div>

          <div class="my-text">升级比例</div>
          <el-form-item
            label-width="10px"
            :prop="'others.' + index + '.master_ratio_up'"
            :rules="{
              required: true,
              message: '请输入升级比例',
              trigger: 'blur',
            }"
          >
            <el-input
              clearable
              v-model="ruleForm.others[index].master_ratio_up"
            ></el-input>
          </el-form-item>
          <div class="my-text" style="padding: 0 10px">%</div>
        </div>
        <!-- 第二行------------------------------------ -->
        <div class="text1">
          <div class="my-text" style="width: 150px">等级提成比例:</div>
          <!-- v-for="myitem in item.agent_radio_list" :key="myitem.agentLeverId" -->
          <div
            style="display: flex; align-items: center"
            v-for="(myitem, myindex) in item.agent_radio_list"
            :key="myitem.agentLeverId"
          >
            <div class="my-text" style="margin-left: 29px">
              <!-- {{myitem.leverName}} -->
              经纪人{{ myitem.lever_name }}等级提成比例
            </div>
            <el-form-item label-width="10px">
              <el-input
                style="width: 80px"
                v-model="item.agent_radio_list[myindex].agent_ratio"
                clearable
              ></el-input>
            </el-form-item>
            <div class="my-text" style="padding: 0 10px">%</div>
          </div>
        </div>

        <!-- 第三行------------------------------------ -->
        <div class="text1">
          <div class="my-text" style="width: 150px">等级提成比例-升级:</div>
          <!-- <div style="margin-left: 98px"></div> -->
          <!-- v-for="myitem in item.agent_radio_list" :key="myitem.agentLeverId" -->
          <div
            style="display: flex; align-items: center"
            v-for="(myitem, myindex) in item.agent_radio_list_up"
            :key="myitem.agentLeverId"
          >
            <div class="my-text" style="margin-left: 29px">
              <!-- {{myitem.leverName}} -->
              经纪人{{ myitem.lever_name }}等级提成比例
            </div>
            <el-form-item label-width="10px">
              <el-input
                style="width: 80px"
                v-model="item.agent_radio_list_up[myindex].agent_ratio_up"
                clearable
              ></el-input>
            </el-form-item>
            <div class="my-text" style="padding: 0 10px">%</div>
          </div>
        </div>
        <el-divider></el-divider>
      </div>

      <!-- 直播订单--------------------------------------------------------- -->
      <div class="one">
        <div class="text">直播订单</div>
      </div>

      <div
        v-for="(item, index) in ruleForm.live"
        :key="item.master_lever_id"
        style="margin-bottom: 15px"
      >
        <div class="text1">
          <div class="my-left2 my-text" style="width: 145px">
            达人等级:{{ item.lever_name }}
          </div>
          <div class="my-text">自然月度已结算销售额指标</div>

          <!-- 做判断 有无输入框 -->
          <div v-if="index === 0" class="my-text" style="padding: 0 10px">
            {{ ruleForm.live[0].sales }}
          </div>

          <div v-else>
            <el-form-item
              label-width="10px"
              :prop="'live.' + index + '.sales'"
              :rules="{
                required: true,
                message: '请输入销售额',
                trigger: 'blur',
              }"
            >
              <el-input
                clearable
                v-model="ruleForm.live[index].sales"
              ></el-input>
            </el-form-item>
          </div>

          <div class="my-text" style="padding: 0 10px">元</div>

          <!-- <div class="my-text">基础比例</div>
          <el-form-item
            label-width="10px"
            :prop="'live.' + index + '.master_ratio'"
            :rules="{
              required: true,
              message: '请输入提成比例',
              trigger: 'blur',
            }"
          >
            <el-input
              clearable
              v-model="ruleForm.others[index].master_ratio"
            ></el-input>
          </el-form-item>
          <div class="my-text" style="padding: 0 10px">%</div> -->

          <div class="my-text">提成比例</div>
          <el-form-item
            label-width="10px"
            :prop="'live.' + index + '.master_ratio'"
            :rules="{
              required: true,
              message: '请输入提成比例',
              trigger: 'blur',
            }"
          >
            <el-input
              clearable
              v-model="ruleForm.live[index].master_ratio"
            ></el-input>
          </el-form-item>
          <div class="my-text" style="padding: 0 10px">%</div>

          <div class="my-text">升级比例</div>
          <el-form-item
            label-width="10px"
            :prop="'live.' + index + '.master_ratio_up'"
            :rules="{
              required: true,
              message: '请输入升级比例',
              trigger: 'blur',
            }"
          >
            <el-input
              clearable
              v-model="ruleForm.live[index].master_ratio_up"
            ></el-input>
          </el-form-item>
          <div class="my-text" style="padding: 0 10px">%</div>
        </div>
        <!-- 第二行------------------------------------ -->
        <div class="text1">
          <div class="my-text" style="width: 150px">等级提成比例:</div>
          <!-- v-for="myitem in item.agent_radio_list" :key="myitem.agentLeverId" -->
          <div
            style="display: flex; align-items: center"
            v-for="(myitem, myindex) in item.agent_radio_list"
            :key="myitem.agentLeverId"
          >
            <div class="my-text" style="margin-left: 29px">
              <!-- {{myitem.leverName}} -->
              经纪人{{ myitem.lever_name }}等级提成比例
            </div>
            <el-form-item label-width="10px" prop="name">
              <el-input
                style="width: 80px"
                v-model="item.agent_radio_list[myindex].agent_ratio"
                clearable
              ></el-input>
            </el-form-item>
            <div class="my-text" style="padding: 0 10px">%</div>
          </div>
        </div>

        <!-- 第三行------------------------------------ -->
        <div class="text1">
          <div class="my-text" style="width: 150px">等级提成比例-升级:</div>
          <!-- <div style="margin-left: 98px"></div> -->
          <!-- v-for="myitem in item.agent_radio_list" :key="myitem.agentLeverId" -->
          <div
            style="display: flex; align-items: center"
            v-for="(myitem, myindex) in item.agent_radio_list_up"
            :key="myitem.agentLeverId"
          >
            <div class="my-text" style="margin-left: 29px">
              <!-- {{myitem.leverName}} -->
              经纪人{{ myitem.lever_name }}等级提成比例
            </div>
            <el-form-item label-width="10px">
              <el-input
                style="width: 80px"
                v-model="item.agent_radio_list_up[myindex].agent_ratio_up"
                clearable
              ></el-input>
            </el-form-item>
            <div class="my-text" style="padding: 0 10px">%</div>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  user_commission,
  user_commission_update,
} from "../../api/user_commission";

export default {
  name: "user-commission",
  components: {},
  data() {
    return {
      ruleForm: {
        agentLevList: [],
        live: [],
        others: [],
      },
    };
  },

  created() {
    this.initData();
  },
  methods: {
    async add() {
      const agentLevLists = this.ruleForm.agentLevList.every(
        (item) => item.sales !== ""
      ); //经纪人等级,销售额指标
      const lives = this.ruleForm.live.every((item) => item.sales !== ""); //直播订单 1-1
      const live_master_ratio = this.ruleForm.live.every(
        (item) => item.master_ratio !== ""
      ); //直播订单 1-2
      const agent_ratio = this.ruleForm.live.every((item) =>
        item.agent_radio_list.every((agent) => agent.agent_ratio !== "")
      ); //直播订单 2-1
      const lives2 = this.ruleForm.others.every((item) => item.sales !== ""); //直播订单 1-1
      const live_master_ratio2 = this.ruleForm.others.every(
        (item) => item.master_ratio !== ""
      ); //直播订单 1-2
      const agent_ratio2 = this.ruleForm.others.every((item) =>
        item.agent_radio_list.every((agent) => agent.agent_ratio !== "")
      ); //直播订单 2-1
      if (
        agentLevLists &&
        lives &&
        live_master_ratio &&
        agent_ratio &&
        lives2 &&
        live_master_ratio2 &&
        agent_ratio2
      ) {
        console.log("this.ruleForm", this.ruleForm);
        const res = await user_commission_update({
          ...this.ruleForm,
        });
        if (res.status === 1) {
          this.$message.success("保存成功");
          this.initData();
        }
      } else {
        this.$message.error("输入框内容不能空");
      }
    },

    async initData() {
      const res = await user_commission();
      console.log(res);
      (this.ruleForm.agentLevList = res.data.agentLevList),
        (this.ruleForm.live = res.data.live),
        (this.ruleForm.others = res.data.others);
    },
  },
};
</script>

<style scoped lang="scss">
.my-left {
  margin-right: 25px;
}

.my-left2 {
  margin-right: 35px;
}

.my-right {
  margin-left: 10px;
  font-size: 16px;
  color: #000000;
}

.my-text {
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
}

.commission {
  .one {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text {
    font-size: 24px;
    color: #000000;
    margin: 18px 0px;
  }

  .text1 {
    display: flex;
    align-items: center;
  }

  .el-input {
    width: 150px;
    margin: 16px 5px 0 10;
  }
}

::v-deep.el-input--small .el-input__inner {
  // margin: 10px 0px 0 0;
  box-sizing: border-box;
  // margin-top: 15px;
}
</style>
