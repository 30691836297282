import service from "../request";


export function channelList(query) {
    return service({
        url: '/api/merchants/channel/list',
        method: 'get',
        params: query
    })
}

export function add(data) {
    return service({
        url: '/api/merchants/channel/add',
        method: 'post',
        data: data
    })
}

export function update(data) {
    return service({
        url: '/api/merchants/channel/update',
        method: 'post',
        data: data
    })
}

export function blanceAdjust(data) {
    return service({
        url: '/api/merchants/channel/blance/adjust',
        method: 'post',
        data: data
    })
}

export function blanceRes(query) {
    return service({
        url: '/api/merchants/channel/blance/res',
        method: 'get',
        params: query
    })
}
// 投放账户管理
export function getPutAccountApi(query) {
    return service({
        url: '/api/merchants/channel/sxt_balance/res',
        method: 'get',
        params: query
    })
}
export function sxtblanceAdjust(data) {
    return service({
        url: '/api/merchants/channel/sxt_balance/adjust',
        method: 'post',
        data: data
    })
}
