import service from "../request";

export function classifyLimit(query) {
    return service({
        url: '/api/merchants/classifyAgentLimit/list',
        method: 'get',
        params: query
    })
}

export function saveOrUpdate(data) {
    return service({
        url: '/api/merchants/classifyAgentLimit/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function equip(data) {
    return service({
        url: '/api/merchants/classifyAgentLimit/equip',
        method: 'post',
        data: data
    })
}

export function equipRes(query) {
    return service({
        url: '/api/merchants/classifyAgentLimit/equipRes',
        method: 'get',
        params: query
    })
}


export function merchantClassifyLimit(query) {
    return service({
        url: '/api/merchants/classifyLimit/info',
        method: 'get',
        params: query
    })
}

export function merchantEquipRes(query) {
    return service({
        url: '/api/merchants/classifyLimit/res',
        method: 'get',
        params: query
    })
}
