import Layout from "../views/layout/Layout";
import miniProgram from "../views/mini_program/index";
import weChatPay from "../views/we_chat_pay/index";
const mini_program = [
    {
        path:"/miniProgram",
        name:'小程序设置',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/xcxss.png',
        component: Layout,
        isMenu:true,
        children:[
            {
                path:"/miniProgram",
                name:'微信小程序',
                component: miniProgram,
            },
            {
                path:"/we_chat_pay",
                name:'微信支付',
                component: weChatPay,
            }
        ]
    }
]

export default mini_program;
