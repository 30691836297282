<template>
<div>
  <el-table
      border
      size="small"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
    <el-table-column
        align="center"
        prop="out_trade_no"
        label="订单编号">
    </el-table-column>
    <el-table-column
        align="center"
        prop="total_amount"
        label="金额">
      <template slot-scope="scope">
        ¥ {{scope.row.total_amount | numFilter }}
      </template>
    </el-table-column>
    <el-table-column
        align="center"
        prop="update_time"
        label="下单时间">
    </el-table-column>
    <el-table-column
        align="center"
        prop="order_type"
        label="订单类型">
      <template slot-scope="scope">
        <span v-if="scope.row.order_type === 1">生成卡订单</span>
        <span v-if="scope.row.order_type === 2">扩容订单</span>
        <span v-if="scope.row.order_type === 3">系统订单</span>
      </template>
    </el-table-column>
    <el-table-column
        prop="pay_way"
        label="支付方式">
    </el-table-column>
    <el-table-column
        align="center"
        prop="trade_no"
        label="流水号">
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import {ticketOrderList} from "../../../api/merchants_ticket_order";

export default {
  name: "ticket_orders_info",
  filters:{
    numFilter (value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    },
  },
  data(){
    return{
      queryParams:{
        recId: -1,
        page: 1,
        limit: 999999,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading: false,
      tableData: []
    }
  },
  methods:{
    initData(recId){
      this.listLoading = true
      let queryParams = this.queryParams;
      queryParams.recId = recId
      ticketOrderList(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
