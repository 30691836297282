<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <el-row>
      <el-button-group>
        <el-button @click="doSubmitMediaProducingJobRequest" round size="small" type="plain"
          style="background: rgba(255, 141, 26, 1);color: white">
          <!--        <i class="el-icon-delete el-icon&#45;&#45;left"></i>-->
          生成视频</el-button>
        <el-button @click="batchGen(1)" round size="small" type="plain"
          style="background: rgba(255, 141, 26, 1);color: white">
          <!--        <i class="el-icon-delete el-icon&#45;&#45;left"></i>-->
          批量生成视频
        </el-button>
        <el-tooltip class="item" effect="dark" content="关联的视频素材从头开始截取后进行合成" placement="bottom">
          <el-button round size="small" type="plain" style="background: rgba(255, 141, 26, 1);color: white">
            <i class="el-icon-info"></i>
          </el-button>
        </el-tooltip>
      </el-button-group>

      <el-button-group style="margin-left: 10px">
        <el-button @click="doSubmitMediaProducingJobRequestByConfig" round size="small" type="plain"
          style="background: rgba(0, 119, 255, 1);color: white">
          <!--        <i class="el-icon-delete el-icon&#45;&#45;left"></i>-->
          智能截取</el-button>
        <el-button @click="batchGen(2)" round size="small" type="plain"
          style="background: rgba(0, 119, 255, 1);color: white">
          <!--        <i class="el-icon-delete el-icon&#45;&#45;left"></i>-->
          批量智能截取
        </el-button>
        <el-tooltip class="item" effect="dark" content="关联的视频素材随机截取后进行合成" placement="bottom">
          <el-button round size="small" type="plain" style="background: rgba(0, 119, 255, 1);color: white">
            <i class="el-icon-info"></i>
          </el-button>
        </el-tooltip>
      </el-button-group>

      <el-button-group style="margin-left: 10px">
        <el-button @click="batchView()" round size="small" type="plain"
          style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1)">
          <!--        <i class="el-icon-delete el-icon&#45;&#45;left"></i>-->
          批量观看</el-button>
        <el-button @click="batchDownload()" size="small" type="plain"
          style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1)">
          批量下载</el-button>
        <el-button v-if="queryParams.isRecycleBin == 0" @click="batchDel(1)" round size="small" type="plain"
          style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1)">
          <!--        <i class="el-icon-delete el-icon&#45;&#45;left"></i>-->
          批量放入回收站
        </el-button>
        <el-button v-if="queryParams.isRecycleBin == 1" @click="batchDel(0)" round size="small" type="plain"
          style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1)"><i
            class="el-icon-delete el-icon--left"></i>批量移出回收站</el-button>
      </el-button-group>

    </el-row>

    <div style="color: #8c939d;padding: 20px">
      温馨提示：云快剪小程序和达人矩阵系统合成视频时仅支持“智能截取”的方式进行视频合成，请确保模版可以进行“智能截取”后再上架到云快剪小程序或达人矩阵系统，否则会造成模版无法在小程序端合成视频。</div>

    <el-tabs v-model="queryParams.isRecycleBin" @tab-click="handleClick">
      <el-tab-pane label="视频列表" name="0"></el-tab-pane>
      <el-tab-pane label="回收站" name="1"></el-tab-pane>
    </el-tabs>

    <el-table size="small" row-key="media_id" :data="tableData" style="width: 100%"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" :reserve-selection="true" width="55">
      </el-table-column>
      <el-table-column prop="date" label="视频预览" align="center">
        <template slot-scope="scope">
          <div style="position: relative;text-align: center" @click="view(scope.row)">
            <el-image v-if="scope.row.status == 'Success'" style="width: 100px;" :src="getImgUrl(scope.row.media_url)">
              <div slot="placeholder" class="image-slot">
                <el-image style="width: 100px;" :src="require('../../assets/template/defaut.jpg')" />
              </div>
            </el-image>
            <el-image v-if="scope.row.status == ''" style="width: 100px;"
              :src="require('../../assets/template/defaut.jpg')" />
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="name" label="合成类型" align="center">
        <template slot-scope="scope">
          <span style="color: rgba(255, 141, 26, 1)" v-if="scope.row.clips_param">生成视频</span>
          <span style="color: rgba(0, 119, 255, 1)" v-else>智能截取</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status == ''" type="success" effect="dark">合成中</el-tag>
          <el-tag v-if="scope.row.status == 'Success'" effect="dark">成功</el-tag>
          <el-tag v-if="scope.row.status == 'Failed'" type="danger" effect="dark">失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="提交时间" align="center">
      </el-table-column>
      <el-table-column prop="update_time" label="生成时间" align="center">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <!--          <el-button v-if="scope.row.status == 'Success'" type="text" size="small" @click="view(scope.row)">查看</el-button>-->
          <el-button v-if="scope.row.is_recycle_bin == 0" type="text" size="small"
            @click="doRecycleBin(scope.row.job_id, 1)">移入回收站</el-button>
          <el-button v-if="scope.row.is_recycle_bin == 1" type="text" size="small"
            @click="doRecycleBin(scope.row.job_id, 0)">移出回收站</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination background @current-change="handleCurrentChange" :current-page="queryParams.page"
            :page-size="queryParams.limit" :total="total" layout="total, prev, pager, next"></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog align="center" title="视频预览" :visible.sync="dialogVisible" :before-close="closeView" width="30%">
      <video id="videoInfo" style="width: 80%;" :src="videoUrl" controls="controls"></video>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeView">取 消</el-button>
        <el-button type="primary" @click="closeView">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="批量查看" :visible.sync="batchViewVisible" :before-close="batchViewClose" width="80%">
      <div style="display:flex;flex-wrap: wrap;">
        <div v-for="(item, index) in this.multipleSelection" :key="index" style="width: 20%">
          <video style="width: 90%" :src="item.media_id" controls="controls"></video>
          <el-button style="margin-bottom: 10px" type="danger" size="small"
            @click="moveRecycle(item, index)">移入回收站</el-button>
        </div>
      </div>
      <div style="display: flex;justify-content: center; width: 100%">
        <el-button type="primary" @click="playAll">同步播放</el-button>
        <el-button type="success" @click="pauseAll">同步暂停</el-button>
      </div>
    </el-dialog>

    <!-- 批量生成 -->
    <el-dialog :title="batchGenTitle" :visible.sync="batchGenOpen" width="30%">
      <el-form ref="batchGenForm" :model="batchGenForm" label-width="100px" style="padding-right: 20px">
        <el-form-item label="生成数量" prop="name">
          <el-input v-model="batchGenForm.num" type="number" number="true" placeholder="请输入生成数量" clearable
            @on-keydown="changeaa(event)" />
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitBatchGen">确 认</el-button>
        <el-button @click="cancelBatchGen">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getMediaProducingJob, submitMediaProducingJobRequestByMerchants, submitJobRequestByConfigByMerchants, submitBatchByMerchants, isRecycleBin } from "../../api/mediaProducingJob";
import $ from 'jquery'
import { zipFiles } from '@/utils/download.js'
export default {
  name: "material_gen_video",
  data() {
    return {
      batchGenTitle: '',
      batchGenOpen: false,
      batchGenForm: {
        type: 0,
        num: 0
      },
      batchViewVisible: false,
      activeName: 'second',
      multipleSelection: [],
      queryParams: {
        page: 1,
        limit: 10,
        sidx: 'create_time',
        order: 'desc',
        templateId: '',
        isRecycleBin: ''
      },
      total: 0,
      tableData: [],
      videoUrl: '',
      dialogVisible: false
    };
  },
  computed: {
    ...mapState(['GLOBAL_TEM_ID'])
  },
  mounted() {
    let templateId = this.$store.state.GLOBAL_TEM_ID
    this.queryParams.templateId = templateId
    this.initData();
  },
  methods: {
    getImgUrl(url) {
      return url + "?x-oss-process=video/snapshot,t_0,f_png,m_fast,w_360,h_640";
    },
    batchDel(type) {
      if (this.multipleSelection.length <= 0) {
        this.$message.error("请选择有效的数据");
        return
      }
      for (let i = 0; i < this.multipleSelection.length; i++) {
        const job_id = this.multipleSelection[i].job_id;
        this.doRecycleBin(job_id, type);
      }
    },
    doRecycleBin(job_id, type) {
      let parameter = {
        jobId: job_id,
        isRecycleBin: type
      };
      isRecycleBin(parameter).then((res) => {
        if (res.code == "0") {
          this.initData(this.templateId)
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    submitBatchGen() {
      let body = this.batchGenForm;
      body.templateId = this.queryParams.templateId
      console.log(JSON.stringify(body))
      submitBatchByMerchants(body).then((res) => {
        console.log(res)
        if (res.code == "0") {
          this.$message.success("提交合成成功,请耐心等待");
          this.batchGenOpen = false;
          this.initData()
        } else {
          this.$message.error(res.message);
        }
      });
    },
    cancelBatchGen() {
      this.batchGenOpen = false;
    },
    batchGen(type) {
      if (type == 1) {
        this.batchGenTitle = "批量生成视频"
      } else {
        this.batchGenTitle = "批量智能截取"
      }
      this.batchGenForm.type = type
      this.batchGenOpen = true
    },
    doSubmitMediaProducingJobRequestByConfig() {
      let body = {
        "template_id": this.queryParams.templateId
      };
      submitJobRequestByConfigByMerchants(body).then((res) => {
        console.log(res)
        if (res.code == "0") {
          this.$message.success("随机截取提交合成成功,请耐心等待");
          this.initData()
        } else {
          this.$message.error(res.message);
        }
      });
    },
    doSubmitMediaProducingJobRequest() {
      let body = {
        "template_id": this.queryParams.templateId
      };
      submitMediaProducingJobRequestByMerchants(body).then((res) => {
        console.log(res)
        if (res.code == "0") {
          this.$message.success("提交合成成功,请耐心等待");
          this.initData()
        } else {
          this.$message.error(res.message);
        }
      });
    },
    batchViewClose() {
      this.pauseAll();
      this.batchViewVisible = false;
      this.initData();
    },
    moveRecycle(item, index) {
      let parameter = {
        jobId: item.job_id,
        isRecycleBin: 1
      };
      isRecycleBin(parameter).then((res) => {
        if (res.code == "0") {
          this.multipleSelection.splice(index, 1);
          this.$message.success(res.message);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    playAll() {
      $('video').trigger('play'); //打开所有的视频播放
    },
    pauseAll() {
      $('video').trigger('pause'); //关闭所有的视频播放
    },
    batchView() {
      if (this.multipleSelection.length <= 0) {
        this.$message.error("请选择有效的数据");
        return
      }
      this.batchViewVisible = true
    },
    // 批量下载
    batchDownload() {
      if (this.multipleSelection.length <= 0) {
        this.$message.error("请选择有效的数据");
        return
      }
      const data = this.multipleSelection.map((item, index) => {
        let mediaType = item.media_url.split('.')
        let suffix = mediaType.pop() || 'mp4'
        return { name: `video-${index + 1}.${suffix}`, url: item.media_url }
      })
      zipFiles('视频素材下载.zip', data)
    },
    closeView() {
      document.getElementById('videoInfo').pause();
      this.dialogVisible = false
    },
    view(row) {
      this.videoUrl = row.media_url
      this.dialogVisible = true
    },
    handleClick(tab, event) {
      console.log(tab, event);
      this.tableData = []
      this.queryParams.page = 1
      this.initData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData() {
      getMediaProducingJob(this.queryParams).then((res) => {
        if (res.code == "0") {
          this.tableData = res.data.list
          this.total = res.data.total_count;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  }
}
</script>

<style scoped></style>
