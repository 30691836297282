<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div style="padding: 10px">
        <el-button
          class="filter-item"
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="handleAdd"
          >新增等级</el-button
        >
      </div>
      <div>
        是否开启购买抖音会员功能
        <el-switch
          @change="merchantConfigUpdate"
          v-model="merchantConfig.trill_member_sw"
          active-color="#13ce66"
          inactive-color="#ff4949"
        >
        </el-switch>
      </div>
    </div>

    <el-table
      :data="tableData"
      :loading="listLoading"
      border
      style="width: 100%; margin-top: 10px"
      :max-height="autoHeight"
    >
      <el-table-column align="center" prop="member_name" label="会员名称">
      </el-table-column>

      <el-table-column align="center" prop="effective_days" label="会员有效期">
        <template slot-scope="scope">
          {{ formattingEffectiveDays(scope.row.effective_days) }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="selling_price" label="售价(元)">
      </el-table-column>

      <el-table-column align="center" prop="whether_open" label="是否售卖">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.whether_open"
            active-color="#13ce66"
            inactive-color="#ff4949"
            disabled
          >
          </el-switch>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="create_time" label="创建时间">
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleEdit(scope.row)"
            >修改</el-button
          >
          <el-button type="text" size="small" @click="handleDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page_no"
            :page-size="page_size"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="130px">
        <el-form-item label="会员名称:" prop="member_name">
          <el-input
            v-model="form.member_name"
            placeholder="请输入会员名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="会员有效期:">
          <el-radio-group v-model="effective_days_type">
            <el-radio :label="0">天</el-radio>
            <el-radio :label="1">月</el-radio>
            <el-radio :label="2">年</el-radio>
          </el-radio-group>
          <el-input
            v-model.number="effective_days_value"
            placeholder="请输入会员有效期"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="售价:" prop="selling_price">
          <el-input
            v-model="form.selling_price"
            placeholder="请输入售价"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item label="是否售卖:" prop="whether_open">
          <el-switch
            v-model="form.whether_open"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>

    <el-divider></el-divider>
    <div style="padding: 10px">
      <el-row>
        会员介绍详情
        <el-button
          Style="float: right"
          class="filter-item"
          type="primary"
          size="small"
          @click="memberInfoSave"
          >保存</el-button
        >
      </el-row>

      <div
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="position: relative; margin-top: 10px"
          :index="index"
          v-for="(item, index) in memberInfo.content"
          :key="index"
        >
          <img :src="item" class="avatar" />
          <i class="el-icon-minus del_item" @click="delMemberItem(index)"></i>
        </div>
        <div style="margin-top: 10px">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
            :http-request="uploadImg"
          >
            <i class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  list,
  update,
  del,
  memberInfo,
  memberInfoUpdate,
} from "../../api/douyin_member";
import { getOssAccessInfo } from "../../api/common";
import Sha1 from "js-sha1";
import {
  merchantsConfig,
  merchantsConfigUpdate,
} from "../../api/merchant_config";
var OSS = require("ali-oss");

export default {
  name: "douyin_member",
  data() {
    return {
      merchantConfig: {
        trill_member_sw: false,
      },
      effective_days_type: 0,
      effective_days_value: 0,
      form: {},
      rules: {
        member_name: [
          { required: true, message: "请输入会员名称", trigger: "blur" },
        ],
        selling_price: [
          { required: true, message: "请输入售价", trigger: "blur" },
        ],
      },
      dialogTitle: "",
      dialogVisible: false,
      autoHeight: "",
      autoWidth: "",
      queryParams: {},
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 100,
      memberInfo: {
        content: [],
      },
      gen_limit_form: {
        gen_limit: 0,
        members_limit: false,
        agent_settlement_time: 7,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.initData();
    this.getMemberInfo();
    this.merchantConfigInfo();
  },
  methods: {
    merchantConfigUpdate() {
      merchantsConfigUpdate(this.merchantConfig).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    merchantConfigInfo() {
      merchantsConfig().then((res) => {
        this.merchantConfig = res.data;
      });
    },
    getMemberInfo() {
      memberInfo({}).then((res) => {
        if (res.code == 0) {
          if (res.data.id != null) {
            this.memberInfo = res.data;
          }
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    memberInfoSave() {
      memberInfoUpdate(this.memberInfo).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "保存成功",
          });
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    delMemberItem(index) {
      this.memberInfo.content.splice(index, 1);
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/banner/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          let url = "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
          console.log(url);
          this.memberInfo.content.push(url);
          this.$message.success("图片上传成功");
        });
      });
    },
    submit() {
      console.log(JSON.stringify(this.form));
      if (this.form.member_name == null || this.form.member_name == "") {
        this.$message.error("请输入有效的会员名称");
        return;
      }
      let effectiveDays = this.getEffectiveDays();
      if (effectiveDays == null || effectiveDays <= 0) {
        this.$message.error("请设置正确的会员有效期");
        return;
      }
      if (this.form.selling_price == null || this.form.selling_price <= 0) {
        this.$message.error("请输入有效的售价");
        return;
      }
      this.form.effective_days = this.getEffectiveDays();
      console.log(JSON.stringify(this.form));
      update({}, this.form).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: this.dialogTitle + "操作成功",
          });
          this.initData();
          this.dialogVisible = false;
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    getEffectiveDays() {
      let effectiveDays = 0;
      if (this.effective_days_type == 0) {
        effectiveDays = this.effective_days_value;
      }
      if (this.effective_days_type == 1) {
        effectiveDays = this.effective_days_value * 30;
      }
      if (this.effective_days_type == 2) {
        effectiveDays = this.effective_days_value * 365;
      }
      return effectiveDays;
    },
    formattingEffectiveDays(effectiveDays) {
      console.log("effectiveDays:" + effectiveDays);
      if (effectiveDays >= 365) {
        return effectiveDays / 365 + "年";
      }
      if (effectiveDays >= 30) {
        return effectiveDays / 30 + "月";
      }
      return effectiveDays + "天";
    },
    parsingEffectiveDays(effectiveDays) {
      console.log("effectiveDays:" + effectiveDays);
      if (effectiveDays >= 365) {
        this.effective_days_type = 2;
        this.effective_days_value = effectiveDays / 365;
        return;
      }
      if (effectiveDays >= 30) {
        this.effective_days_type = 1;
        this.effective_days_value = effectiveDays / 30;
        return;
      }
      this.effective_days_type = 0;
      this.effective_days_value = effectiveDays;
    },
    handleAdd() {
      this.dialogTitle = "新增等级";
      this.dialogVisible = true;
      this.effective_days_type = 0;
      this.effective_days_value = 0;
      this.form = {};
    },
    handleEdit(row) {
      this.dialogTitle = "修改等级";
      this.dialogVisible = true;
      this.form = JSON.parse(JSON.stringify(row));
      console.log(JSON.stringify(row));
      this.parsingEffectiveDays(row.effective_days);
    },
    handleDel(row) {
      this.$confirm("确认删除该会员信息?", "删除会员信息", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({ id: row.id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.initData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 250 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams;
      (queryParams.page = this.page_no),
        (queryParams.limit = this.page_size),
        list(queryParams).then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total_count;
          this.listLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 351px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 351px;
  display: block;
}

.del_item {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  border: 1px dashed #757070;
  border-radius: 6px;
  position: absolute;
  left: 360px;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
