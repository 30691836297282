import service from "../request";


export function batchWeChatPass(data) {
    return service({
        url: '/api/merchants/withdrawal/batchWeChatPass',
        method: 'post',
        data: data
    })
}

export function batchOfflinePass(data) {
    return service({
        url: '/api/merchants/withdrawal/batchOfflinePass',
        method: 'post',
        data: data
    })
}

export function batchRejected(data) {
    return service({
        url: '/api/merchants/withdrawal/batchRejected',
        method: 'post',
        data: data
    })
}


export function withdrawalList(query) {
    return service({
        url: '/api/merchants/withdrawal/list',
        method: 'get',
        params: query
    })
}

export function statistical(query) {
    return service({
        url: '/api/merchants/withdrawal/statistical',
        method: 'get',
        params: query
    })
}

export function ruleInfo(query) {
    return service({
        url: '/api/merchants/withdrawal/ruleInfo',
        method: 'get',
        params: query
    })
}

export function ruleUpdate(data) {
    return service({
        url: '/api/merchants/withdrawal/ruleUpdate',
        method: 'post',
        data: data
    })
}
