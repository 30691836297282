import RulesCenterLayOut from "../views/layout/RulesCenterLayOut";
import UserAgreement from "../views/agreement/user";
import RechargeAgreement from "../views/agreement/recharge";
import TalentMatrixAgreement from "../views/agreement/talent_matrix";
import PrivacyAgreement from "../views/agreement/privacy";
import AuthenticationProtocolInfo from "../views/agreement/AuthenticationProtocolInfo";
const rulesCenter = [
    {
        path:"/rules_center_user_agreement",
        name:'用户协议',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/wdwj.png',
        component: RulesCenterLayOut,
        isSingleMenu:true,
        isMenu:true,
        children:[
            {
                path:"/rules_center_user_agreement",
                name:'用户协议',
                component: UserAgreement,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'rulesCenter'
                    }
                }
            }
        ]
    },
    {
        path:"/rules_center_privacy_agreement",
        name:'隐私政策',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/wdwj.png',
        component: RulesCenterLayOut,
        isSingleMenu:true,
        isMenu:true,
        children:[
            {
                path:"/rules_center_privacy_agreement",
                name:'隐私政策',
                component: PrivacyAgreement,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'rulesCenter'
                    }
                }
            }
        ]
    },
    {
        path:"/rules_center_talent_matrix_agreement",
        name:'达人矩阵营销系统年度合作协议',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/wdwj.png',
        component: RulesCenterLayOut,
        isSingleMenu:true,
        isMenu:true,
        children:[
            {
                path:"/rules_center_talent_matrix_agreement",
                name:'达人矩阵营销系统年度合作协议',
                component: TalentMatrixAgreement,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'rulesCenter'
                    }
                }
            }
        ]
    },
    {
        path:"/rules_center_recharge_agreement",
        name:'充值服务协议',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/wdwj.png',
        component: RulesCenterLayOut,
        isSingleMenu:true,
        isMenu:true,
        children:[
            {
                path:"/rules_center_recharge_agreement",
                name:'充值服务协议',
                component: RechargeAgreement,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'rulesCenter'
                    }
                }
            }
        ]
    },
    {
        path:"/rules_center_authentication_protocol",
        name:'视客联盟认证服务协议',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/wdwj.png',
        component: RulesCenterLayOut,
        isSingleMenu:true,
        isMenu:true,
        children:[
            {
                path:"/rules_center_authentication_protocol",
                name:'视客联盟认证服务协议',
                component: AuthenticationProtocolInfo,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'rulesCenter'
                    }
                }
            }
        ]
    },
]

export default rulesCenter;
