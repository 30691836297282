import service from "../request";

// 获取分成比例设置
export function user_commission() {
    return service({
        url: '/api/merchants/mcnRatio/info',
        method: 'get'
    })
}


// 更新分成比例设置
export function user_commission_update(data) {
    return service({
        url: '/api/merchants/mcnRatio/update',
        method: 'post',
        data: data
    })
}
