import GrassTaskMenuLayout from "../views/layout/GrassTaskMenuLayout";
import grass_task_situation from "../views/grass_task/grass_task_situation";
import grassTask from "../views/grass_task";
import addGrassTask from "../views/grass_task/add";
import taskDetails from "../views/grass_task/taskDetails";
import TiktokList from "../views/user/TiktokList";
import douyin_member from "../views/user/douyin_member";
import douyin_member_res from "../views/user/douyin_member_res";

const template = [
    {
        path:"/grass_task_situation",
        name:'概况',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: GrassTaskMenuLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/grass_task_situation",
                name:'概况',
                component: grass_task_situation,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'grassTask'
                    }
                }
            }
        ]
    },
    {
        path:"/grass_task",
        name:'种草任务',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: GrassTaskMenuLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/grass_task",
                name:'种草任务',
                component: grassTask,
                meta:{
                    keepAlive: true,
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'grassTask'
                    }
                }
            },
            {
                path:"/add_grass_task",
                name:'新增种草任务',
                isHidden : true,
                component: addGrassTask,
                meta:{
                    keepAlive: true,
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'grassTask'
                    }
                }
            },
            {
                path:"/task_details",
                name:'任务详情',
                isHidden : true,
                component: taskDetails,
                meta:{
                    keepAlive: true,
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'grassTask'
                    }
                }
            }
        ]
    },
    {
        path:"/tiktokList",
        name:'抖音号列表',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: GrassTaskMenuLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/tiktokList",
                name:'抖音号列表',
                component: TiktokList,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'grassTask'
                    }
                }
            }
        ]
    },
    {
        path:"/",
        name:'抖音号列表',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: GrassTaskMenuLayout,
        isMenu:true,
        children:[
            {
                path:"/douyin_member",
                name:'抖音会员',
                component: douyin_member,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'grassTask'
                    }
                }
            },
            {
                path:"/douyin_member_res",
                name:'抖音会员购买记录',
                component: douyin_member_res,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'grassTask'
                    }
                }
            }
        ]
    },
]

export default template;
