<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <el-tabs v-model="queryParams.audit_status" @tab-click="handleClick" style="width: 100%">
      <el-tab-pane
          v-for="(item) in tabs"
          :key="item.value"
          :label="item.label"
          :name="item.value + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <div style="display: flex;flex-direction: row;align-items: center">
      <el-input
          size="small"
          placeholder="所属用户昵称/手机号"
          v-model="queryParams.userSearch"
          clearable
          style="width: 200px;margin-left: 5px"
      >
      </el-input>

      <el-input
          size="small"
          placeholder="模板ID"
          v-model="queryParams.creator_tem_id"
          clearable
          style="width: 200px;margin-left: 5px"
      >
      </el-input>

      <el-button
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="queryParamsChange"
      >搜索</el-button>
    </div>

    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%;margin-top: 15px"
        :max-height="autoHeight">
      <el-table-column prop="auth_number" label="用户信息">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <el-image
                style="width: 50px;height: 50px;border-radius: 50%"
                :src="scope.row.avatar_url"
                fit="cover">
            </el-image>
            <div style="display: flex;flex-direction: column">
              <span style="margin-left: 5px">{{scope.row.nick_name}}</span>
              <span style="margin-left: 5px">{{scope.row.phone_number}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="creator_tem_id" label="模板ID"></el-table-column>
      <el-table-column label="用户生成的视频">
        <template slot-scope="scope">
          <el-image
              @click="preview(scope.row.media_url)"
              style="width: 50px;"
              :src="getVideoImg(scope.row.media_url)"
              fit="cover">
          </el-image>
        </template>
      </el-table-column>
      <el-table-column prop="problem_desc" label="问题描述"></el-table-column>
      <el-table-column prop="video_link" label="视频链接">
        <template slot-scope="scope">
          <div class="video_link" @click="video_link(scope.row.video_link)">
            {{scope.row.video_link}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="凭证截图">
        <template slot-scope="scope">
          <div style="display: flex">
            <div v-for="(item,index) in scope.row.credentials" :key="index" style="flex: 1">
              <el-image
                  style="width: 50px;"
                  :src="item"
                  fit="cover"
                  :preview-src-list="getSrcList(item)"
              >
              </el-image>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="applay_time" label="申请时间"></el-table-column>
      <el-table-column prop="dismiss_time" label="驳回时间" v-if="queryParams.audit_status==2"></el-table-column>
      <el-table-column prop="dismiss_reason" label="驳回理由" v-if="queryParams.audit_status==2"></el-table-column>
      <el-table-column prop="pass_time" label="通过时间" v-if="queryParams.audit_status==1"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="160" v-if="queryParams.audit_status==0">
        <template slot-scope="scope">
          <el-button
              @click.native.prevent="doAuditPass(scope.row)"
              type="text"
              size="small">
            审核通过
          </el-button>
          <el-button
              @click.native.prevent="doAuditDismiss(scope.row)"
              type="text"
              size="small">
            审核驳回
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

    <el-dialog title="视频预览" :visible.sync="previewVisible"
               :before-close="batchViewClose" width="30%" style="text-align: center">
      <video
          style="width: 90%"
          :src="previewUrl"
          controls="controls"
      ></video>
    </el-dialog>


    <el-dialog size="small" title="反馈驳回" :visible.sync="auditDismissVisible" width="30%">
      <el-form
          ref="form"
          :model="auditDismissForm"
          label-width="120px"
          style="padding-right: 20px"
      >
        <el-input
            style="margin-top: 20px"
            type="textarea"
            maxlength="30"
            show-word-limit
            placeholder="请输入驳回原因,最多可输入30个汉字"
            v-model="auditDismissForm.dismiss_reason">
        </el-input>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="auditDismissFormSubmit" size="small">确 认</el-button>
        <el-button @click="auditDismissVisible = false" size="small">取 消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>

import {list ,state ,auditPass ,auditDismiss} from "../../api/build_rec_feedback";
import $ from 'jquery'

export default {
  name: "build_rec_feedback",
  data(){
    return{
      tabs:[],
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        audit_status: 0,
        userSearch: '',
        creator_tem_id: '',
        page: 1,
        limit: 5
      },
      total:0,
      listLoading: false,
      tableData: [],
      previewVisible:false,
      previewUrl: '',
      auditDismissVisible: false,
      auditDismissForm:{}
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
  },
  methods:{
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    auditDismissFormSubmit(){
      auditDismiss(this.auditDismissForm).then((res) => {
        if (res.code == "0") {
          this.$message.success("操作成功");
          this.auditDismissVisible = false
          this.initData()
        }else{
          this.$message.error("操作失败!");
        }
      }).catch(() => {});
    },
    doAuditPass(row){
      this.$confirm("确定审核通过？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        auditPass(row).then((res) => {
          if (res.code == "0") {
            this.$message.success("操作成功");
            this.initData()
          }else{
            this.$message.error("操作失败!");
          }
        }).catch(() => {});
      }).catch(() => {});
    },
    doAuditDismiss(row){
      let auditDismissForm = row
      auditDismissForm.dismiss_reason = ''
      this.auditDismissForm = auditDismissForm
      this.auditDismissVisible = true
    },
    queryParamsChange(){
      this.queryParams.page = 1
      this.initData()
    },
    video_link(link){
      window.open(link, '_blank');
    },
    batchViewClose(){
      this.pauseAll()
      this.previewVisible = false;
    },
    pauseAll(){
      $('video').trigger('pause'); //关闭所有的视频播放
    },
    preview(url){
      this.previewUrl = url
      this.previewVisible = true
    },
    getVideoImg(src){
      return src + "?x-oss-process=video/snapshot,t_0,f_png,m_fast,w_360,h_640";
    },
    getSrcList(src){
      let srcList = []
      srcList.push(src)
      return srcList
    },
    handleClick(tab, event) {
      this.tableData = []
      this.queryParams.page = 1;
      this.initData()
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 260) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    doState(){
      state({}).then((res) => {
        this.tabs = res.data
      });
    },
    initData(){
      this.listLoading = true
      list(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
        this.doState();
      });
    },
  }
}
</script>

<style scoped>

.video_link{
  cursor: pointer;
}

.video_link:hover{
  color: #8cc5ff;
}


</style>
