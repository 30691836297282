<template>
  <div style="padding: 20px">
  <div><p style="font-size:22pt; line-height:150%; margin:12pt 0pt 3pt; orphans:0; text-align:center; widows:0"><span
      style="font-family:宋体; font-size:22pt; font-weight:bold">达人</span><span
      style="font-family:宋体; font-size:22pt; font-weight:bold">矩阵营销系统年度合作协议</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">一</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">. </span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">导语</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(一)【</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">审慎阅读</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">】</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">当你点击同意本协议之前，请务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，你应重点阅读。</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">如你对协议有任何疑问，可向</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">客服咨询。</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:normal">(二)【</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">签约流程】</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:normal">当你按照页面提示完成操作后，即表示你已充分阅读、理解并接受本协议的全部内容。</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">阅读本协议的过程中，如果你不同意本协议或其中任何条款约定，你应立即停止签约流程。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:Helvetica; font-size:12pt">&#xa0;</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">二</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">. </span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">协议变更与生效</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(一)【</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">协议变更】</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">可能根据国家法律法规变化、业务调整、产品更新及维护交易秩序、保护消费者权益等需要对协议内容进行变更，变更后的协议内容将更符合业务实际及更好的保障双方权益，变更后的协议将按照法定程序在</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">规则中心进行公示。</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">如你不同意变更内容，你有权于变更内容确定的生效日前联系</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">反馈意见，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">将根据你的反馈酌情调整变更事项。</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(二)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">【协议生效】</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">变更后的协议版本至公布期满之日起生效，将替代变更前的协议版本，你可在</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">规则中心查阅最新版协议条款。</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(三)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">【民事权利能力和民事行为能力</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">】</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">你确认，你具有法律规定的完全民事权利能力和民事行为能力，能够独立承担民事责任。若你不具备与前述行为相适应的民事行为能力，则你及你的监护人应依照法律规定承担因此而导致的后果。</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(四)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">【补充协议】</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">因你与</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">签署的本协议列明的条款并不能完整罗列并覆盖你与</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，与本服务相关的规则均为本协议的补充协议，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">与你均需遵守，与本协议不可分割且具有同等法律效力。</span></p>
    <p style=" margin:0pt 0pt 10.5pt"><span
        style="color:#333333; font-family:Helvetica; font-size:12pt">&#xa0;</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">三</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">. </span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">定义</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(一)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">商</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">户</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">（以下或称为</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">“</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">你</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">”</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">）：指购买</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">的主体。</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(二)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">杭州</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">知视云</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">科技有限公司（以下或称为</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">“</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">”</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">：指为</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">商家提供技术支持服务的公司。</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(三)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">：</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">是</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">提供的</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">技术服务，由</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">杭州知视云科技有限公司开发的</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">saas</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">小程序系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">。</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">&#xa0;</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">四</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">. </span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">服务费及服务期限相关规则</span></p>
    <p style="font-size:9pt; line-height:150%; margin:0pt 0pt 0pt -21pt; orphans:0; text-indent:21pt; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">1、</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">(一)在你同意本协议并按照本协议约定支付服务费用后，本产品即订购成功。一旦你订购成功本产品，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">将为你开通服务，服务期自</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">为你开通之日起开始计算。收费标准如下表格所示（以下标准价格均为含税价格，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们提供</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">普通发票，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你如果</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">需</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们开具</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">增值税专用发票，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你需要</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">补发票税金</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">，3</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">%</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">。</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）：</span></p>
    <table cellspacing="0" cellpadding="0" style="border-collapse:collapse; margin-left:0pt; width:375pt">
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">服务内容</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">有效期</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">标准价格</span></p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">达人矩阵营销系统</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">1</span><span
              style="color:#333333; font-family:宋体; font-size:9pt">年</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">￥</span><span
              style="color:#333333; font-family:宋体; font-size:9pt">50000</span><span
              style="color:#333333; font-family:宋体; font-size:9pt">.00</span></p></td>
      </tr>
      <tr>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">短视频生成卡</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">永久</span></p></td>
        <td style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">以充值页面为准，充值越多单价越低</span></p></td>
      </tr>
      <tr>
        <td colspan="3"
            style=" border-bottom-color:#dddddd; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#dddddd; border-left-style:solid; border-left-width:0.75pt; border-right-color:#dddddd; border-right-style:solid; border-right-width:0.75pt; border-top-color:#dddddd; border-top-style:solid; border-top-width:0.75pt; padding:3.38pt 7.12pt 3.38pt 4.12pt; vertical-align:middle">
          <p style="line-height:14.25pt; margin:0pt; text-align:center"><span
              style="color:#333333; font-family:宋体; font-size:9pt">备注：短视频生成卡可用于云端短视频的合成，合成1次扣除1个短视频生成卡，短视频生成卡</span><span
              style="color:#333333; font-family:宋体; font-size:9pt">先充值后使用，充值后不</span><span
              style="color:#333333; font-family:宋体; font-size:9pt">支持退款</span><span
              style="color:#333333; font-family:宋体; font-size:9pt">。</span></p></td>
      </tr>
      <tr style="height:0pt">
        <td style="width:109.45pt; border:none"></td>
        <td style="width:54.95pt; border:none"></td>
        <td style="width:209.85pt; border:none"></td>
      </tr>
    </table>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:normal">(二)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">你支付本服务的服务费时，可选择在线支付</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">-</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">微信</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">/</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">支付宝扫码进行支付，亦可选择对公转账至如下账户支付所需订购内容的服务费用：</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:normal">&#xa0;</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:normal"> </span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">户名：</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">杭州知视云科技有限公司</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">&#xa0;</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold"> </span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">&#xa0;</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold"> </span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">&#xa0;</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">开户行：</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">招商银行股份有限公司杭州九堡支行</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">&#xa0;</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold"> </span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">&#xa0;</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold"> </span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">&#xa0;</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">账号：</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold"> </span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">571916449210201</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">对公账户打款后你需要立即联系视客联盟客服手动开通服务，你了解并清楚线下对接时可能会出现客服人员无法立即帮你开通服务的情况。</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(三)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">你在订购本产品时应当慎重考虑，如你订购本服务并支付服务费，除</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">书面同意外，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">不因本协议的中止、终止或你单方面退出而将已支付的服务费用退还给你。</span>
    </p>
    <p style=" margin:0pt"><span style="color:#333333; font-family:宋体; font-size:9pt">(四)如你需要发票，在开通服务后</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">可开具项目为“＊</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">技术服务费</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">”的</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">普通</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">发票。</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你如果需我们开具增值税专用发票，你需要补发票税金</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">(</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">3</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">%</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">,</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">开票路径：商</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">户</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">后台-</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">订单记录</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">-</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">申请开票</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">。</span></p>
    <p style="font-size:10.5pt; line-height:200%; margin:0pt; orphans:0; widows:0"><span
        style="font-family:宋体; font-size:10.5pt">&#xa0;</span></p>
    <p style="margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">五. </span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">使用条款</span>
    </p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">一</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">同意遵守</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">矩阵营销系统服务条款，在合同期限内</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">仅拥有</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">的使用权及相关服务；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（二）你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">应当合法使用</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">，不得利用该产品进行侵权、违法、违规和违反本协议目的之行为，否则</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">有权单方面终止</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">对该产品的使用许可，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">不承担任何责任，并且</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">有权不退还</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">已支付的费用；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（三）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">未经</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">授权，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">不得将</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">及对应账号以任何有偿及无偿的方式进行转让、赠与或者允许第三方企业使用；</span></p>
    <p style="margin:0pt 0pt 0pt 36pt; orphans:0; text-indent:-36pt; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（四）</span><span style="font:7.0pt 'Times New Roman'">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">同意</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">保留对产品服务地址、功能升级、数据参数、使用方式等调整的权利；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（五）我们会</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">在合同约定的服务到期10个工作日之前提醒</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">，建议</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">在服务到期前及时备份储存，并及时续费产品，以保证软件服务的正常使用；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（六）你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">所使用的</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">账户到期后15日内需明确是否继续使用。若不继续使用，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">不再提供</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">的使用权及相关服务；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（七）你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">在使用</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">过程中与第三方发生的纠纷和进一步导致的经济、法律责任均与乙方无关。</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">&#xa0;</span>
    </p>
    <p style="margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">六</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">. 你的权利与义务</span>
    </p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">一</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">同意并接受本服务合同的相关规定。</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">承诺提供的信息不违反法律法规，或侵犯第三方权益的内容，否则</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">有权终止为</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">提供关于</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">的一切服务支持；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">二</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">保证</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">企业认证</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">所提供的公司名称与营业执照或公司证明的名称一致，并承诺营业执照相关证件真实有效，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">有权签署本合同；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（三）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">甲乙双方将允许对方在宣传册、海报、网站等媒介载体上使用各自产品视听标识信息作为宣传使用。</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（四）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">不得对</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">进行仿制研发或提供第三方公司进行仿制研发，若</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">存在此类违约行为，</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">有权即刻终止本协议服务内容且无需退</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">还你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">所支付的款项，另</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">外你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">需</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">要</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">向</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">支付违约金1,000,000元（人民币壹佰万整）。</span></p>
    <p style="margin:0pt"><span style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">&#xa0;</span></p>
    <p style="margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">七</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">. </span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">我们</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">的权利与义务</span>
    </p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">（一</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">应参照本年度合作框架合同条款提供相应服务，同时向</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">进行解释说明</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">的功能</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">与使用，你可以联系客服获取咨询服务</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">二</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">应在收到</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">提供的全部资料及款项后，保证在3个工作日内完成</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">视客联盟达人矩阵营销系统</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">的开通。</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">三</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">应采取相应的管理、物理及技术措施保护</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">使用软件服务器数据的安全性；</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">四</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">承诺为</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">提供软件售后服务和软件日常维护工作，保证软件的正常运行和使用；</span></p>
    <p style="font-size:10.5pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
        style="font-family:宋体; font-size:10.5pt; font-weight:bold">&#xa0;</span></p>
    <p style="margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">八</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">. </span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">保密条款</span>
    </p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">双方必须严格保守对因本合同项下的服务自另一方所获取的商业秘密，在未取得另一方书面同意的情况下，不得以任何形式向第三方披露。</span>
    </p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">&#xa0;</span>
    </p>
    <p style="margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">九</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">. </span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">知识产权</span>
    </p>
    <p style="margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（一）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">双方确认并同意，由双方在合作期限内进行合作而产生的任何知识产权均应由双方共同享有。</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">二</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">有权对协议项下所有由</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">我们</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">参与创作、表演或其他而形成的作品进行重新剪辑、编辑、演绎、改编、注释、整理、修改、翻译、汇编或任何形式的修改、再组合、再创造，且无需经过</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你的</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">同意并无需另行向</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">你</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">支付任何费用。</span></p>
    <p style="margin:0pt; orphans:0; widows:0"><span style="color:#333333; font-family:宋体; font-size:9pt">&#xa0;</span>
    </p>
    <p style="margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">十</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">. </span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">协议变更解除</span>
    </p>
    <p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（一）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">经本协议双方同意，可以变更或解除本协议；</span></p>
    <p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">二</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">由于战争或其他军事行动、地震、水灾、火灾、台风等自然灾害不能预见并对其发生或后果不能合理预防或改变的不可抗力致使本协议的全部或部分义务不能履行的，遭受不可抗力的一方有权通知另一方解除协议并在24小时内将事件的情况用电报、传真或电话通知另一方，并在15日内就不可抗力事件的详细情况以合法的书面形式通知另一方。</span>
    </p>
    <p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">（</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">三</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">）</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">由于一方在本协议约定的期限内没有履行协议，本协议任何一方有权通知对方解除协议。</span></p>
    <p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">&#xa0;</span></p>
    <p style="margin:0pt"><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">十一</span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">. </span><span
        style=" color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">禁止商业贿赂</span>
    </p>
    <p style="font-size:9pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
        style="color:#333333; font-family:宋体; font-size:9pt">任何一方不得以任何形式向相关部门或相关负责人及其家人提供或索要钱物或服务等，范围包括礼品、娱乐活动票券、旅行、现金或商品形式的回扣等，但不仅限于此。甲乙双方均同意坚持公平、公正、公开的原则，坚决拒绝商业索贿、行贿及其他不正当商业行为。</span>
    </p>
    <p style="font-size:10.5pt; line-height:150%; margin:0pt; orphans:0; widows:0"><span
        style="font-family:宋体; font-size:10.5pt">&#xa0;</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">十二</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">.</span><span
        style="color:#333333; font-family:inherit; font-size:9pt; font-weight:bold">&#xa0;</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">其他</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(一)</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">【法律适用】</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">本协议适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(二)【</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">管辖法院】因本协议的解释、履行发生的争议，双方首先应当协商解决。双方不能通过友好协商解决争议的，任何一方均可将争议提交杭州市互联网法院审理。</span>
    </p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(三)【</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">条款独立性</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">】如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余条款则仍具有法律效力。</span></p>
    <p style=" margin:0pt"><span
        style="color:#333333; font-family:宋体; font-size:9pt">(四)【</span><span
        style="color:#333333; font-family:宋体; font-size:9pt; font-weight:bold">权利放弃</span><span
        style="color:#333333; font-family:宋体; font-size:9pt">】本协议任何一方于另一方过失或违约时放弃本协议规定的权利的，不得视为其对一方的其他或以后同类之过失或违约行为弃权。</span>
    </p></div>
</div>
</template>

<script>
export default {
  name: "talent_matrix"
}
</script>

<style scoped>

</style>
