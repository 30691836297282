import routes from "./routes"
import clip from "./clip"
import my_cloud from "./my_cloud"
import material from "./material"
import rulesCenter from "./rulesCenter"

const routes_all = [
    ...routes,
    ...clip,
    ...my_cloud,
    ...material,
    ...rulesCenter
]

export default routes_all
