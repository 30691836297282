import ClipLayout from "../views/layout/ClipLayout";
import clip_tem from "../views/clip/clip_tem";
import clip_general_situation from "../views/clip/clip_general_situation";
import clip_tem_list from "../views/clip/clip_tem_list";
import clip_tet_class from "../views/clip/clip_tet_class";
import clip_user_audit from "../views/clip/clip_user_audit";
import clip_user_label from "../views/clip/clip_user_label";
import clip_user_list from "../views/clip/clip_user_list";
const template = [
    {
        path:"/clip_tem",
        name:'模板详情',
        component: clip_tem,
        isHidden : true,
        meta:{
            headInfo: {
                showHeader: false,
                showMenu:false,
                showUserInfo:true,
                menu_item_active: 'clip'
            }
        }
    },
    {
        path:"/clip_general_situation",
        name:'概况',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: ClipLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/clip_general_situation",
                name:'概况',
                component: clip_general_situation,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            }
        ]
    },
    {
        path:"/clip_tem_list",
        name:'模板列表',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/mblb.png',
        component: ClipLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/clip_tem_list",
                name:'模板列表',
                component: clip_tem_list,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            }
        ]
    },
    {
        path:"/clip_manger",
        name:'云快剪系统',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/ykjxt.png',
        component: ClipLayout,
        isMenu:true,
        children:[
            {
                path:"/clip_user_list",
                name:'成员列表',
                component: clip_user_list,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            },
            {
                path:"/clip_user_audit",
                name:'成员审核',
                component: clip_user_audit,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            },
            {
                path:"/clip_user_label",
                name:'成员标签',
                component: clip_user_label,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            },
            {
                path:"/clip_tet_class",
                name:'模板分类设置',
                component: clip_tet_class,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            }
        ]
    }
]

export default template;
