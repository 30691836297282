<template>
  <div class="mini_program">
        <div>
          微信支付
          <el-button style="margin-left: 20px" type="primary" round size="small" @click="addWechatPay">新增收款商户</el-button>
          <el-table
              :data="wechatPayList"
              style="width: 100%">
            <el-table-column
                align="center"
                prop="custom_sort"
                label="支付顺序/序号大优先使用">
            </el-table-column>
            <el-table-column
                align="center"
                prop="merchant_id"
                label="商户号">
            </el-table-column>
            <el-table-column
                align="center"
                prop="merchant_name"
                label="商户名称">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.merchant_name" placement="top">
                  <div style="overflow: hidden;
                      text-overflow:ellipsis;
                      white-space: nowrap;">
                    {{scope.row.merchant_name}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="merchant_serial_number"
                label="商户证书序列号">
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" :content="scope.row.merchant_serial_number" placement="top">
                  <div style="overflow: hidden;
                      text-overflow:ellipsis;
                      white-space: nowrap;">
                    {{scope.row.merchant_serial_number}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="api_v3_key"
                label="API-V3密钥">
              <template slot-scope="scope">
                <el-tooltip style="width: 200px" class="item" effect="dark" :content="scope.row.api_v3_key" placement="top">
                  <div style="overflow: hidden;
                      text-overflow:ellipsis;
                      white-space: nowrap;">
                    {{scope.row.api_v3_key}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
                align="center"
                prop="private_key"
                label="商户私钥:">
              <template slot-scope="scope">
                <el-tooltip  class="item" effect="dark" placement="top-end">
                  <div slot="content" style="width: 610px;text-align: center">
                    {{scope.row.private_key}}
                  </div>
                  <div style="overflow: hidden;
                      text-overflow:ellipsis;
                      white-space: nowrap;">
                    {{scope.row.private_key}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" >
              <template slot-scope="scope">
                <el-button
                    @click.native.prevent="editWechatPay(scope.row)"
                    type="text"
                    size="small">
                  编辑
                </el-button>
                <el-button
                    @click.native.prevent="delWechatPay(scope.row)"
                    type="text"
                    size="small">
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>

    <!--    新增编辑微信商户弹窗-->
    <el-dialog
        :title="wechatPayTitle"
        :visible.sync="wechatPayVisible"
        width="60%">

      <el-form :rules="wechatPayFormRules" ref="wechatPayForm" class="wechatPayForm" label-width="160px" :label-position="labelPosition" :model="wechatPayForm">
        <el-form-item label="支付顺序：" prop="custom_sort">
          <el-input v-model="wechatPayForm.custom_sort" placeholder="请输入支付顺序 => 序号大优先使用" size="small"></el-input>
        </el-form-item>
        <el-form-item label="商户号：" prop="merchant_id">
          <el-input v-model="wechatPayForm.merchant_id" placeholder="请输入商户号" size="small"></el-input>
        </el-form-item>
        <el-form-item label="商户名称：" prop="merchant_name">
          <el-input v-model="wechatPayForm.merchant_name" placeholder="请输入商户名称" size="small"></el-input>
        </el-form-item>
        <el-form-item label="商户证书序列号：" prop="merchant_serial_number">
          <el-input v-model="wechatPayForm.merchant_serial_number" placeholder="请粘贴商户证书序列号"  size="small"></el-input>
        </el-form-item>
        <el-form-item label="API-V3密钥：" prop="api_v3_key">
          <el-input v-model="wechatPayForm.api_v3_key" placeholder="请输入API-V3密钥" size="small"></el-input>
        </el-form-item>
        <el-form-item label="商户私钥：" prop="private_key">
          <el-input
              type="textarea"
              autosize
              v-model="wechatPayForm.private_key" placeholder="请输入商户私钥"  size="small"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="wechatPayVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submitWechatPayForm">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import { mapState } from 'vuex'
import {componentLoginPage} from "../../api/home";
import {wechatPayList ,wechatPaySaveOrUpdate ,del} from "../../api/wechatPay";
import { authorizerInfo ,apiQueryAuth ,getVersioninfo ,onlineTemplate ,commit ,getQrcode ,getWxccodeUnlimit ,bindTester ,unbindTester ,release ,submitAudit ,getLatestAuditstatus } from "../../api/mini_program";
export default {
  name: "index",
  data(){
    return{
      wechatPayFormRules:{
        custom_sort: [
          { required: true, message: '请输入支付序号', trigger: 'blur' }
        ],
        merchant_id: [
          { required: true, message: '请输入商户号', trigger: 'blur' }
        ],
        merchant_name: [
          { required: true, message: '请输入商户名称', trigger: 'blur' }
        ],
        merchant_serial_number: [
          { required: true, message: '请输入商户证书序列号', trigger: 'blur' }
        ],
        api_v3_key: [
          { required: true, message: '请输入商户证书序列号', trigger: 'blur' }
        ],
        private_key: [
          { required: true, message: '请输入商户私钥', trigger: 'blur' }
        ]
      },
      wechatPayTitle:"",
      wechatPayVisible:false,
      wechatPayForm:{},
      wechatPayList:[],
      wechatid: '',
      testQrcode:'',
      onlineQrcode:'',
      labelPosition: 'right',
      authorizerInfo: {
        authorizer_info: {},
        authorization_info: {},
      },
      versionInfo:{
        release_info:{
          release_version:'未提交'
        },
        exp_info:{
          exp_version : "未提交"
        }
      },
      onlineTemplate:{},
      latestAudits:{}
    }
  },
  created() {
    this.doAuthorizerInfo();
    this.doOnlineTemplate();
    this.doGetVersioninfo();
  },
  mounted(){
    let query = this.$route.query;
    console.log(JSON.stringify(query))
    let auth_code = this.$route.query.auth_code
    if(auth_code != null){
      this.doApiQueryAuth(auth_code)
    }
    this.doGetQrcode()
    this.doGetLatestAuditstatus();
    this.doWechatPayList()
  },
  methods:{
    submitWechatPayForm(){
      this.$refs['wechatPayForm'].validate((valid) => {
        if (valid) {
          wechatPaySaveOrUpdate(this.wechatPayForm).then(res=> {
            this.$message.success({
              duration: 1000,
              message: '操作成功'
            });
            this.doWechatPayList()
            this.wechatPayVisible = false
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    addWechatPay(){
      this.wechatPayVisible = true
      this.wechatPayForm = {}
      this.wechatPayTitle = '新增微信商户'
    },
    editWechatPay(row){
      this.wechatPayTitle = '编辑微信商户'
      this.wechatPayForm = row
      this.wechatPayVisible = true
    },
    delWechatPay(row){
      this.$confirm("确认删除: "+ row.merchant_name +" 收款商户?", "删除收款商户", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        })
          .then(() => {
            row.is_delete = 1
            del(row).then(res=> {
              this.$message.success({
                duration: 1000,
                message: "收款商户: " + row.merchant_name + "删除成功"
              });
              this.doWechatPayList()
            })
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
    },
    doWechatPayList(){
      wechatPayList({}).then((response) => {
        this.wechatPayList = response.data.list
      });
    },
    formatDate(millisecond, template) {
      var res = "";
      try {
        var date = new Date(millisecond);
        var opt = {
          "Y+": date.getFullYear().toString(), // 年
          "m+": (date.getMonth() + 1).toString(), // 月
          "d+": date.getDate().toString(), // 日
          "H+": date.getHours().toString(), // 时
          "M+": date.getMinutes().toString(), // 分
          "S+": date.getSeconds().toString(), // 秒
        };
        template = template || "YYYY-mm-dd";
        for (var k in opt) {
          var ret = new RegExp("(" + k + ")").exec(template);
          if (ret) {
            template = template.replace(
                ret[1],
                ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
            );
          }
        }
        res = template;
      } catch (error) {
        console.warn("ERROR formatDate", error);
      }
      return res;
    },
    doGetLatestAuditstatus(){
      getLatestAuditstatus({}).then(res=> {
          this.latestAudits = res.data
      })
    },
    submitAudit(){
      submitAudit({}).then(res=> {
        if(res.data.errcode == 0){
          this.$message.success({
            duration: 1000,
            message: '提交成功,请耐心等待'
          });
        }
        if(res.data.errcode==85009) {
          this.$message.error({message: '重复提交,请耐心等待'});
        }
        this.doGetLatestAuditstatus();
      })
    },
    release(){
      release({}).then(res=> {
        if(res.data.errcode==85020) {
          this.$message.error({message: '审核中请等待'});
        }
        if(res.data.errcode==85052) {
          this.$message.error({message: '已发布,请勿重复操作'});
        }
        this.doGetVersioninfo();
      })
    },
    doBindTester(){
      bindTester({},{
        wechatid:this.wechatid
      }).then(res=> {
        if(res.code==0){
          if(res.data.errcode == 0) {
            this.$message.success({
              duration: 1000,
              message: '绑定成功'
            });
          }else if(res.data.errcode == 85004){
            this.$message.error({message: '该用户已是体验成员'});
          }else if(res.data.errcode == 85001){
            this.$message.error({message: '用户不存在'});
          }else{
            this.$message.error({message: '绑定失败'});
          }
        }else{
          this.$message.error({message: '绑定失败'});
        }
      })
    },
    doUnbindTester(){
      unbindTester({},{
        wechatid:this.wechatid
      }).then(res=> {
        if(res.code==0){
          if(res.data.errcode == 0) {
            this.$message.success({
            duration: 1000,
            message: '解绑成功'
          });
          }else{
            this.$message.error({message: '解绑失败'});
          }
        }else{
          this.$message.error({message: '解绑失败'});
        }
      })
    },
    doGetQrcode(){
      getQrcode({}).then((response) => {
        this.testQrcode = response.data
      });
      getWxccodeUnlimit({}).then((response) => {
        this.onlineQrcode = response.data
      });
    },
    doCommit(){
      commit({}).then((response) => {
        this.$message.success({
          duration: 1000,
          message: '代码上传成功'
        });
        this.doGetVersioninfo();
        this.doGetQrcode()
      });
    },
    doOnlineTemplate(){
      onlineTemplate({}).then((response) => {
        this.onlineTemplate = response.data
      });
    },
    doGetVersioninfo(){
      getVersioninfo({}).then((response) => {
        if(response.data.release_info){
          this.versionInfo.release_info = response.data.release_info
        }
        if(response.data.exp_info){
          this.versionInfo.exp_info = response.data.exp_info
        }
      });
    },
    componentLoginPage(){
      let path = window.location.href
      console.log(path)
      let query = {
        REDIRECT_URL : path + "?merchantsId=" + this.USER_INFO.id
      }
      componentLoginPage(query).then((response) => {
        let url = response.data;
        console.log(url)
        window.open(url, '_blank');
      });
    },
    jumpWeixin(){
      window.open('https://mp.weixin.qq.com', '_blank');
    },
    doApiQueryAuth(authorization_code){
      let query = {
        authorization_code : authorization_code
      }
      apiQueryAuth(query).then((response) => {
        console.log(JSON.stringify(response))
        this.doAuthorizerInfo();
      });
    },
    doAuthorizerInfo(){
      authorizerInfo({}).then((response) => {
        let authorizerInfo = response.data;
        if(authorizerInfo.authorizer_info == null){
          console.log('没有授权信息')
          this.authorizer = false
          authorizerInfo.authorizer = false
        }else {
          console.log('展示展示授权信息')
          this.authorizer = true
          authorizerInfo.authorizer = true
        }
        this.authorizerInfo = authorizerInfo
        console.log(JSON.stringify(authorizerInfo))
      });
    },
  },
  computed: {
    ...mapState(['USER_INFO'])
  },
}
</script>

<style scoped>
.mini_program{
  background-color: white;
  margin: 10px;
  padding: 10px;
}

.item_info{
  width: 500px;
  height: 200px;
  border: 1px solid rgba(229, 229, 229, 1);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.item_info_text{
  color: rgba(56, 56, 56, 1);
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.custom_form_item .el-form-item{
  margin-bottom: 5px;
}

</style>
