import service from "../request";

export function ratioUpdate (data) {
    return service({
        url: '/api/merchants/trillBinding/ratioUpdate',
        method: 'post',
        data: data
    })
}


export function ratioInfo (query) {
    return service({
        url: '/api/merchants/trillBinding/ratio/info',
        method: 'get',
        params: query
    })
}

export function changeTie (data) {
    return service({
        url: '/api/merchants/trillBinding/change_tie',
        method: 'post',
        data: data
    })
}

export function commission (data) {
    return service({
        url: '/api/merchants/trillBinding/commission',
        method: 'post',
        data: data
    })
}


export function statistics (query) {
    return service({
        url: '/api/merchants/trillBinding/statistics',
        method: 'get',
        params: query
    })
}

export function list (query) {
    return service({
        url: '/api/merchants/trillBinding/list',
        method: 'get',
        params: query
    })
}

export function del (query) {
    return service({
        url: '/api/merchants/trillBinding/del',
        method: 'get',
        params: query
    })
}


// 获取抖音用户标签
export function staffLabelInfoApi (query, data) {
    return service({
        url: '/api/merchants/staffLabel/info',
        method: 'get',
        params: query,
        data: data
    })
}

// 新增更新抖音标签
export function infoupdateApi (query, data) {
    return service({
        url: '/api/merchants/staffLabel/info/update',
        method: 'post',
        params: query,
        data: data
    })
}

// 获取创作者列表
export function staffLabelCtListApi (query) {
    return service({
        url: '/api/merchants/staffLabel/ctList',
        method: 'get',
        params: query
    })
}

