<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <el-button type="primary" size="small" @click="balanceVisibleShow"
      >调整用户余额</el-button
    >
    <el-button type="primary" size="small" @click="agentVisibleShow"
      >调整团长余额</el-button
    >

    <div style="margin-top: 10px">
      <el-input
        size="small"
        placeholder="请输入用户昵称/手机号/openId"
        v-model="queryParams.userSearch"
        clearable
        style="width: 250px"
      ></el-input>

      <el-date-picker
        size="small"
        style="margin-left: 10px"
        v-model="queryParams.minExchangeTime"
        default-time="00:00:00"
        type="datetime"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="调整时间"
      >
      </el-date-picker>
      <i style="margin-left: 5px" class="el-icon-minus"></i>
      <el-date-picker
        size="small"
        style="margin-left: 10px"
        v-model="queryParams.maxExchangeTime"
        default-time="23:59:59"
        type="datetime"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="调整时间"
      >
      </el-date-picker>

      <el-button
        type="primary"
        icon="el-icon-search"
        size="small"
        @click="search()"
        style="margin-left: 10px"
        >搜索</el-button
      >
    </div>

    <el-table
      size="small"
      :data="tableData"
      :loading="listLoading"
      border
      style="width: 100%; margin-top: 10px"
      :max-height="autoHeight"
    >
      <el-table-column align="center" prop="id" label="用户信息" width="360">
        <template slot-scope="scope">
          <div style="display: flex; flex-direction: row; align-items: center">
            <el-image
              v-if="scope.row.sklm_wx_user.avatar_url"
              style="width: 50px; height: 50px; border-radius: 50px"
              :src="scope.row.sklm_wx_user.avatar_url"
              fit="cover"
            >
            </el-image>
            <div
              style="
                margin-left: 10px;
                display: flex;
                flex-direction: column;
                text-align: left;
              "
            >
              <div>{{ scope.row.sklm_wx_user.nick_name }}</div>
              <div>手机号:{{ scope.row.sklm_wx_user.phone_number }}</div>
              <div>openId:{{ scope.row.sklm_wx_user.open_id }}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="old_balance" label="调整前金额(元)">
        <template slot-scope="scope">
          {{ scope.row.old_balance | numberFormat }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="record_type" label="调整类型">
      </el-table-column>

      <el-table-column align="center" prop="record_type" label="用户类型">
        <template slot-scope="scope">
          <div v-if="scope.row.balance_type==1">用户余额</div>
          <div v-if="scope.row.balance_type==2">团长余额</div>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="balance" label="调整金额(元)">
        <template slot-scope="scope">
          {{ scope.row.balance | numberFormat }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="new_balance" label="调整后金额(元)">
        <template slot-scope="scope">
          {{ scope.row.new_balance | numberFormat }}
        </template>
      </el-table-column>

      <el-table-column align="center" prop="create_time" label="调整时间">
      </el-table-column>

      <el-table-column align="center" prop="details" label="备注信息">
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="queryParams.page"
            :page-size="queryParams.limit"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog :title="balanceTitle" :visible.sync="balanceVisible" width="30%">
      <el-form
        :model="balanceForm"
        :rules="balanceRules"
        ref="balanceRuleForm"
        label-width="100px"
      >
        <el-form-item label="用户ID:" prop="openId">
          <el-input
            v-model="balanceForm.openId"
            placeholder="请输入用户openid"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="类型:" prop="operationType">
          <el-radio-group v-model="balanceForm.operationType">
            <el-radio :label="1">充值</el-radio>
            <el-radio :label="2">扣减</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="金额:" prop="balance">
          <el-input
            v-model="balanceForm.balance"
            placeholder="请输入调整金额"
            clearable
            @input="formatBalance(balanceForm.balance, 'balance')"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="details">
          <el-input
            v-model="balanceForm.details"
            placeholder="备注信息"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="balanceVisible = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="doBalanceUpdate"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog :title="agentTitle" :visible.sync="agentVisible" width="30%">
      <el-form
        :model="balanceForm"
        :rules="balanceRules"
        ref="balanceRuleForm2"
        label-width="100px"
      >
        <el-form-item label="用户ID:" prop="openId">
          <el-input
            v-model="balanceForm.openId"
            placeholder="请输入用户openid"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="类型:" prop="operationType">
          <el-radio-group v-model="balanceForm.operationType">
            <el-radio :label="1">充值</el-radio>
            <el-radio :label="2">扣减</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="金额:" prop="balance">
          <el-input
            v-model="balanceForm.balance"
            placeholder="请输入调整金额"
            clearable
            @input="formatBalance(balanceForm.balance, 'balance')"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注:" prop="details">
          <el-input
            v-model="balanceForm.details"
            placeholder="备注信息"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="agentVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="aegntUpdate"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  balanceUpdate,
  sklmBalanceResList,
  agentbalanceUpdate,
} from "../../api/sklmUser";

export default {
  name: "userBalance",
  filters: {
    numberFormat: function (value) {
      return value.toFixed(2);
    },
  },
  data() {
    return {
      balanceVisible: false,
      balanceForm: {
        openId: "",
        operationType: 1,
        balance: null,
        details: "",
      },
      balanceRules: {
        openId: [
          { required: true, message: "请输入用户openId", trigger: "blur" },
        ],
        operationType: [
          { required: true, message: "请输入选择操作类型", trigger: "blur" },
        ],
        balance: [
          { required: true, message: "请输入调整金额", trigger: "blur" },
        ],
        details: [
          { required: true, message: "请输入备注信息", trigger: "blur" },
        ],
      },
      balanceTitle: "",
      agentTitle: "",
      agentVisible: false,
      listLoading: false,
      autoHeight: "",
      autoWidth: "",
      queryParams: {
        page: 1,
        limit: 5,
        sidx: "sbr.create_time",
        order: "desc",
        userSearch: "",
        minExchangeTime: null,
        maxExchangeTime: null,
      },
      tableData: [],
      total: 0,
    };
  },
  created() {
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.initData();
  },
  methods: {
    formatBalance(val, key) {
      console.log("formatBalance");
      let temp = val.toString();
      temp = temp.replace(/。/g, ".");
      temp = temp.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      temp = temp.replace(/^\./g, ""); //验证第一个字符是数字
      temp = temp.replace(/\.{2,}/g, ""); //只保留第一个, 清除多余的
      temp = temp.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      temp = temp.replace(/^()*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      this.balanceForm[key] = temp;
    },
    doBalanceUpdate() {
      console.log(JSON.stringify(this.balanceForm));
      this.$refs["balanceRuleForm"].validate((valid) => {
        if (valid) {
          balanceUpdate(this.balanceForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.initData();
              this.balanceVisible = false;
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    balanceVisibleShow() {
      this.balanceTitle = "调整用户余额";
      this.balanceVisible = true;
      let balanceForm = {
        operationType: 1,
      };
      this.balanceForm = balanceForm;
    },

    aegntUpdate() {
      console.log(JSON.stringify(this.balanceForm));
      this.$refs["balanceRuleForm2"].validate((valid) => {
        if (valid) {
          agentbalanceUpdate(this.balanceForm).then((res) => {
            if (res.code == 0) {
              this.$message.success({
                duration: 1000,
                message: "操作成功",
              });
              this.initData();
              this.agentVisible = false;
            } else {
              this.$message.error({ message: res.message });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    agentVisibleShow() {
      this.agentTitle = "调整团长余额";
      this.agentVisible = true;
      let balanceForm = {
        operationType: 1,
      };
      this.balanceForm = balanceForm;
    },

    search() {
      this.queryParams.page = 1;
      this.initData();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 250 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },
    initData() {
      sklmBalanceResList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
      });
    },
  },
};
</script>

<style scoped>
</style>
