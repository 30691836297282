import Layout from "../views/layout/Layout";
import BannerList from "../views/marketing/bannerList";
import PlateType from "../views/marketing/plate_type";
import sklmCustomerService from "../views/merchants/sklmCustomerService";
import posters from "../views/marketing/posters";

const router = [
    {
        path:"/page_zx",
        name:'页面装修',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/ymzx.png',
        component: Layout,
        isMenu:true,
        children:[
            {
                path:"/posters",
                name:'首页板块配置',
                component: posters
            },
            {
                path:"/plate_type",
                name:'首页板块配置',
                component: PlateType
            },
            {
                path:"/banner",
                name:'banner管理',
                component: BannerList,
                meta: {
                    keepAlive: true
                }
            },
            {
                path:"/sklmCustomerService",
                name:'客服设置',
                component: sklmCustomerService,
            }
        ]
    }
]

export default router;
