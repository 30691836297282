import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import createPersistedState from "vuex-persistedstate"

import router from "../router";
import { optionMenu } from "../api/merchantsUser.js"

const store = new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.localStorage
    })],
    state: {
        HOME_PAGE_MENU: null,
        MATRIX_MENU: null,
        ACCOUNT_CENTER_MENU: null,
        CLOUD_CLIP_MENU: null,
        CLOUD_MENU: null,
        GRASS_TASK_MENU: null,
        ELECTRICTY_MCN_MENU: null,
        COURSE_MENU: null,
        VIDEO_JOB_MENU: null,
        MERCHANTS_TOKEN: '',
        CREATOR_TOKEN: '',
        USER_INFO: {},
        CREATOR_USER_INFO: {},
        HEAD_INFO: {
            showHeader: false,
            showMenu: false,
            showUserInfo: false,
            menu_item_active: ''
        },
        ACTIVE_PATH: '',
        UPLOAD_LIST: []
    },
    mutations: {
        INIT_MENU(state, isLogin) {
            optionMenu().then(res => {
                if (res.code == "0") {
                    // console.log(JSON.stringify(res.data))
                    state.HOME_PAGE_MENU = res.data.homePage
                    state.MATRIX_MENU = res.data.matrix
                    state.ACCOUNT_CENTER_MENU = res.data.accountCenter
                    state.CLOUD_CLIP_MENU = res.data.cloudClip
                    state.CLOUD_MENU = res.data.cloud
                    state.GRASS_TASK_MENU = res.data.grassTask
                    state.ELECTRICTY_MCN_MENU = res.data.electricityMcn
                    state.COURSE_MENU = res.data.course
                    state.VIDEO_JOB_MENU = res.data.videoJobMenu
                    if (isLogin == false) {
                        if (res.data.matrix != null) {
                            let menu = res.data.matrix
                            let path = ''
                            if (menu.children[0].has_children === false) {
                                path = menu.children[0].path
                            } else {
                                path = menu.children[0].children[0].path
                            }
                            router.push(path);
                            return
                        }
                        if (res.data.cloudClip != null) {
                            let menu = res.data.cloudClip
                            let path = ''
                            if (menu.children[0].has_children === false) {
                                path = menu.children[0].path
                            } else {
                                path = menu.children[0].children[0].path
                            }
                            router.push(path);
                            return
                        }
                        if (res.data.cloud != null) {
                            let menu = res.data.cloud
                            let path = ''
                            if (menu.children[0].has_children === false) {
                                path = menu.children[0].path
                            } else {
                                path = menu.children[0].children[0].path
                            }
                            router.push(path);
                            return
                        }
                        if (res.data.grassTask != null) {
                            let menu = res.data.grassTask
                            let path = ''
                            if (menu.children[0].has_children === false) {
                                path = menu.children[0].path
                            } else {
                                path = menu.children[0].children[0].path
                            }
                            router.push(path);
                            return
                        }
                        if (res.data.electricityMcn != null) {
                            let menu = res.data.electricityMcn
                            let path = ''
                            if (menu.children[0].has_children === false) {
                                path = menu.children[0].path
                            } else {
                                path = menu.children[0].children[0].path
                            }
                            router.push(path);
                            return
                        }
                        if (res.data.course != null) {
                            let menu = res.data.course
                            let path = ''
                            if (menu.children[0].has_children === false) {
                                path = menu.children[0].path
                            } else {
                                path = menu.children[0].children[0].path
                            }
                            router.push(path);
                            return
                        }
                        if (res.data.videoJobMenu != null) {
                            let menu = res.data.videoJobMenu
                            let path = ''
                            if (menu.children[0].has_children === false) {
                                path = menu.children[0].path
                            } else {
                                path = menu.children[0].children[0].path
                            }
                            router.push(path);
                            return
                        }
                    }
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        INIT_DATA(state, info) {
            let token = info.MERCHANTS_TOKEN
            state.MERCHANTS_TOKEN = token;
            state.CREATOR_TOKEN = token;
            localStorage.setItem('MERCHANTS_TOKEN', token);
            localStorage.setItem('CREATOR_TOKEN', token);
            state.UPLOAD_LIST = []
            state.USER_INFO = info.merchantsInfo;
            state.CREATOR_USER_INFO = info.creatorUser;
            this.commit('INIT_MENU', false)
        },
        UPLOAD_PROGRESS(state, info) {
            let index = state.UPLOAD_LIST.findIndex(item => item.uid == info.uid)
            let tem = state.UPLOAD_LIST[index]
            if (!info.checkpoint && (tem.media_type === 'image' || info.percentage == 1)) {
                tem.percentage = 100
                tem.transmissionSpeed = 0
                tem.fileSize = tem.file_size
                return tem
            }

            if (info.checkpoint && tem.uid == info.checkpoint.file.uid) {
                tem.percentage = Math.trunc(info.percentage * 100)
                // if (tem.percentage > 98) {
                //     console.log("第一个判断---", tem.file_all_name, info)
                // }
                tem.fileSize = info.checkpoint.fileSize
                tem.partSize = info.checkpoint.partSize
                let dateNow = Date.now();
                let stay = dateNow - tem.timestamp
                tem.timestamp = dateNow
                if (info.percentage == 1) {
                    tem.transmissionSpeed = 0
                } else {
                    tem.transmissionSpeed = info.checkpoint.partSize / stay / 1000
                }
                return tem;
            }
            console.log('不可能执行这个地方-----------');
            return tem

            // state.UPLOAD_LIST = state.UPLOAD_LIST.map(tem => {
            //     if (info.checkpoint && tem.uid == info.checkpoint.file.uid) {
            //         tem.percentage = Math.trunc(info.percentage * 100)
            //         if(tem.percentage > 98) {
            //             console.log("第一个判断---", tem.file_all_name, info)
            //         }
            //         tem.fileSize = info.checkpoint.fileSize
            //         tem.partSize = info.checkpoint.partSize
            //         let dateNow = Date.now();
            //         let stay = dateNow - tem.timestamp
            //         tem.timestamp = dateNow
            //         if (info.percentage == 1) {
            //             tem.transmissionSpeed = 0
            //         } else {
            //             tem.transmissionSpeed = info.checkpoint.partSize / stay / 1000
            //         }
            //         return tem;
            //     } else if (tem.media_type === 'image' || info.percentage == 1) {
            //         console.log("执行else if---", tem.file_all_name, info)
            //         tem.percentage = 100
            //         tem.transmissionSpeed = 0
            //         tem.fileSize = tem.file_size
            //         return tem
            //     } else {
            //         console.log("上传进度执行else---", tem.file_all_name, info)
            //         return tem;
            //     }
            // });
        },
        UPLOAD_LIST_PUSH_ITEM(state, info) {
            state.UPLOAD_LIST.push(info);
        },
        LOGIN_OPTION_INIT(state, info) {
            state.LOGIN_OPTION = info;
        },
        UPLOAD_LIST_DEL_ITEM(state, info) {
            state.UPLOAD_LIST = state.UPLOAD_LIST.filter((item) => {
                return item.uid != info.uid;
            });
        },
        UPLOAD_LIST_DEL_ALL(state) {
            state.UPLOAD_LIST = []
        },
        SET_TOKEN(state, token) {
            state.MERCHANTS_TOKEN = token;
            state.CREATOR_TOKEN = token;
            localStorage.setItem('MERCHANTS_TOKEN', token);
            localStorage.setItem('CREATOR_TOKEN', token);
        },
        DEL_TOKEN(state) {
            state.MERCHANTS_TOKEN = '';
            state.USER_INFO = {};
            localStorage.removeItem('MERCHANTS_TOKEN');
        },
        USER_INFO(state, info) {
            state.USER_INFO = info;
        },
        CREATOR_USER_INFO(state, info) {
            state.CREATOR_USER_INFO = info;
        },
        HEAD_INFO(state, info) {
            state.HEAD_INFO = info;
        },
        ACTIVE_PATH(state, info) {
            state.ACTIVE_PATH = info;
        },
        GLOBAL_TEM_ID(state, info) {
            state.GLOBAL_TEM_ID = info;
        }
    }
});

export default store
