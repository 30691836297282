import Layout from "../views/layout/Layout";
import Withdrawal from "../views/financial_center/withdrawal";
import RuleInfo from "../views/financial_center/ruleInfo";

import AppWalletWithdrawal from "../views/financial_center/app_wallet_withdrawal.vue";
import  PushMessage  from '@/views/financial_center/push_message.vue'
import helpCenter from "@/views/financial_center/help_center.vue"
import systemNotice from "@/views/financial_center/system_notice.vue"

const router = [
    {
        path:"/financialCenter",
        name:'财务中心',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/cwzx.png',
        component: Layout,
        isMenu:true,
        children:[
            {
                path:"/withdrawal",
                name:'提现列表',
                isHidden : false,
                component: Withdrawal
            },
            {
                path:"/ruleInfo",
                name:'提现设置',
                isHidden : false,
                component: RuleInfo
            },
            {
                path:"/appAlletWithdrawal",
                name:'APP钱包提现',
                isHidden : false,
                component: AppWalletWithdrawal
            },
            {
                path:"/PushMessage",
                name:'系统消息推送',
                isHidden : false,
                component: PushMessage
            },
            {
                path:"/helpCenter",
                name:'帮助中心',
                isHidden : false,
                component: helpCenter
            },
            {
                path:"/systemNotice",
                name:'系统公告',
                isHidden : false,
                component: systemNotice
            },
             
        ]
    }
]

export default router;
