import service from "../../request";

export function tabs(query) {
    return service({
        url: '/api/merchants/videoJob/tabs',
        method: 'get',
        params: query
    })
}

export function list(query) {
    return service({
        url: '/api/merchants/videoJob/list',
        method: 'get',
        params: query
    })
}

export function del(query) {
    return service({
        url: '/api/merchants/videoJob/del',
        method: 'get',
        params: query
    })
}

export function info(query) {
    return service({
        url: '/api/merchants/videoJob/info',
        method: 'get',
        params: query
    })
}


export function saveOrUpdate(data) {
    return service({
        url: '/api/merchants/videoJob/saveOrUpdate',
        method: 'post',
        data: data
    })
}
