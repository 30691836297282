<template>
  <div>
    <div style="background: white;padding: 10px;margin: 10px">
      视频生成卡
      <div style="display: flex;margin-top: 10px">
        <div class="item_info">
          <div style="color: rgba(56, 56, 56, 1);font-size: 24px;font-weight: bold">{{userInfo.gen_card_num}}</div>
          <div class="item_text">生成卡余额 <span @click="doRecharge" style="float: right;color: rgba(0, 119, 255, 1);cursor: pointer">充值</span></div>
        </div>
        <div class="item_info">
          <div style="color: rgba(56, 56, 56, 1);font-size: 24px;font-weight: bold">{{userInfo.freeze_gen_card_num}}</div>
          <div class="item_text">已冻结生成卡</div>
        </div>
        <div class="item_info">
          <div style="color: rgba(56, 56, 56, 1);font-size: 24px;font-weight: bold">{{userInfo.consume_gen_card_num}}</div>
          <div class="item_text">累计消耗生成卡</div>
        </div>
<!--        <div class="item_info">-->
<!--          <div style="color: rgba(56, 56, 56, 1);font-size: 24px;font-weight: bold">{{userInfo.failure_gen_card_num}}</div>-->
<!--          <div class="item_text">累计失效生成卡</div>-->
<!--        </div>-->
      </div>
    </div>

    <div style="height: 100%; background: white;padding: 10px;margin: 10px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="充值/扣减记录" name="first">
          <el-table
              :data="tableDataOne"
              style="width: 100%"
              :max-height="autoHeight">
            <el-table-column
                prop="create_time"
                label="时间"
                align="center">
            </el-table-column>
            <el-table-column
                prop="gen_card_quota"
                label="生成卡余额"
                align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.gen_card_quota > 0"> +{{scope.row.gen_card_quota}}</div>
                <div v-else>{{scope.row.gen_card_quota}}</div>
              </template>
            </el-table-column>
            <el-table-column
                prop="caption"
                label="方式"
                align="center">
            </el-table-column>
            <el-table-column
                prop="details"
                label="备注"
                align="center">
            </el-table-column>
          </el-table>
          <el-row style="margin-top: 24px;margin-right: 20px">
            <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page_no_one"
                    :page-size="page_size_one"
                    :total="total_one"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="冻结/解冻记录" name="second">
          <el-table
              :data="tableDataResOne"
              style="width: 100%"
              :max-height="autoHeight"
          >
            <el-table-column
                prop="create_time"
                label="时间"
                align="center">
            </el-table-column>
            <el-table-column
                prop="system_type"
                label="系统"
                align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.system_type == 1">
                     达人矩阵
                </div>
                <div v-if="scope.row.system_type == 2">
                    云快剪
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="gen_card_quota"
                label="生成卡余额"
                align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.gen_card_quota > 0"> +{{scope.row.gen_card_quota}}</div>
                <div v-else>{{scope.row.gen_card_quota}}</div>
              </template>
            </el-table-column>
            <el-table-column
                prop="caption"
                label="内容"
                align="center">
            </el-table-column>
            <el-table-column
                prop="details"
                label="详情"
                align="center">
            </el-table-column>
            <el-table-column
                prop="gen_card_quota"
                label="用户"
                align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.system_type == 1">
                  <img :src="scope.row.sklm_wx_user_entity.avatar_url" class="avatar">
                  <div>{{scope.row.sklm_wx_user_entity.nick_name}}</div>
                </div>
                <div v-if="scope.row.system_type == 2">
                  <img :src="scope.row.vodm_wx_user_entity.avatar_url" class="avatar">
                  <div>{{scope.row.vodm_wx_user_entity.nick_name}}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top: 24px;margin-right: 20px">
            <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChangeResOne"
                    :current-page="page_no_ResOne"
                    :page-size="page_size_ResOne"
                    :total="total_ResOne"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="实际消耗记录" name="third">
          <el-table
              :data="tableDataResTwo"
              style="width: 100%"
              :max-height="autoHeight"
          >
            <el-table-column
                prop="create_time"
                label="时间"
                align="center">
            </el-table-column>
            <el-table-column
                prop="system_type"
                label="系统"
                align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.system_type == 1">
                  达人矩阵
                </div>
                <div v-if="scope.row.system_type == 2">
                  Ai云剪辑
                </div>
                <div v-if="scope.row.system_type == 3">
                  云快剪
                </div>
              </template>
            </el-table-column>
            <el-table-column
                prop="caption"
                label="类型"
                align="center">
            </el-table-column>
            <el-table-column
                prop="gen_card_quota"
                label="生成卡余额"
                align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.gen_card_quota > 0"> +{{scope.row.gen_card_quota}}</div>
                <div v-else>{{scope.row.gen_card_quota}}</div>
              </template>
            </el-table-column>
            <el-table-column
                prop="creator_tem_id"
                label="模板编号"
                align="center">
            </el-table-column>
            <el-table-column
                prop="details"
                label="模板标题"
                align="center">
            </el-table-column>
            <el-table-column
                prop="gen_card_quota"
                label="用户"
                align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.system_type == 1">
                  <img :src="scope.row.sklm_wx_user_entity.avatar_url" class="avatar">
                  <div>{{scope.row.sklm_wx_user_entity.nick_name}}</div>
                </div>
                <div v-if="scope.row.system_type == 2">
                    系统生成
                </div>
                <div v-if="scope.row.system_type == 3">
                  <img :src="scope.row.vodm_wx_user_entity.avatar_url" class="avatar">
                  <div>{{scope.row.vodm_wx_user_entity.nick_name}}</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-row style="margin-top: 24px;margin-right: 20px">
            <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChangeResTwo"
                    :current-page="page_no_ResTwo"
                    :page-size="page_size_ResTwo"
                    :total="total_ResTwo"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
            </el-col>
          </el-row>
        </el-tab-pane>
<!--        <el-tab-pane label="会员到期失效" name="four">-->
<!--          <el-table-->
<!--              :data="tableDataResFour"-->
<!--              style="width: 100%"-->
<!--              :max-height="autoHeight"-->
<!--          >-->
<!--            <el-table-column-->
<!--                prop="create_time"-->
<!--                label="时间"-->
<!--                align="center">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                prop="gen_card_quota"-->
<!--                label="失效生成卡数量"-->
<!--                align="center">-->
<!--              <template slot-scope="scope">-->
<!--                {{scope.row.gen_card_quota}}-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                prop="caption"-->
<!--                label="内容"-->
<!--                align="center">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                prop="details"-->
<!--                label="详情"-->
<!--                align="center">-->
<!--            </el-table-column>-->
<!--            <el-table-column-->
<!--                prop="gen_card_quota"-->
<!--                label="用户"-->
<!--                align="center">-->
<!--              <template slot-scope="scope">-->
<!--                <img :src="scope.row.sklm_wx_user_entity.avatar_url" class="avatar">-->
<!--                <div>{{scope.row.sklm_wx_user_entity.nick_name}}</div>-->
<!--              </template>-->
<!--            </el-table-column>-->
<!--          </el-table>-->
<!--          <el-row style="margin-top: 24px;margin-right: 20px">-->
<!--            <el-col :span="24" style="text-align: right">-->
<!--              <span class="page-tool">-->
<!--                <el-pagination-->
<!--                    background-->
<!--                    @current-change="handleCurrentChangeResFour"-->
<!--                    :current-page="page_no_ResFour"-->
<!--                    :page-size="page_size_ResFour"-->
<!--                    :total="total_ResFour"-->
<!--                    layout="total, prev, pager, next"-->
<!--                ></el-pagination>-->
<!--              </span>-->
<!--            </el-col>-->
<!--          </el-row>-->
<!--        </el-tab-pane>-->
      </el-tabs>

    </div>


    <el-dialog
        title="生成卡充值"
        :visible.sync="rechargeDialogVisible"
        width="40%">
      <div>
        <div>生成卡</div>
        <div style="margin-top: 10px; display: flex;align-items: center">
          <div><el-input @input="changeGenNum"
                         size="small" v-model.number="rechargeForm.gen_num" placeholder="请输入生成卡数量" clearable></el-input></div>
          <div style="margin-left: 10px">生成卡单价{{userInfo.gen_card_price}}元，最低 {{userInfo.gen_card_lowest}} 起充</div>
        </div>
        <div style="margin-top: 20px">支付方式</div>
        <div style="display: flex;margin-top: 10px">
          <div @click="changePayType(1)" class="check_type" :class="{'active_check_type' : rechargeForm.pay_way === 1}">
            <img src="../../assets/pay/wechat.png" class="pay-img" />
            <span style="margin-left: 5px">微信</span>
          </div>
          <div @click="changePayType(2)" class="check_type" style="margin-left: 20px" :class="{'active_check_type' : rechargeForm.pay_way === 2}">
            <img src="../../assets/pay/alipay.png" class="pay-img" />
            <span style="margin-left: 5px">支付宝</span>
          </div>
        </div>
        <div style="margin-top: 10px">
          <el-checkbox v-model="rechargeForm.checked"></el-checkbox><span style="margin-left: 10px">同意</span><span @click="rechargeAgreement" style="margin-left: 5px;color: rgba(0, 119, 255, 1);">《充值服务协议》</span>
        </div>
        <div style="margin-top: 20px;display: flex;flex-direction: column;align-items: center;">
          <div style="display: flex;align-items: center">
            <img v-if="rechargeForm.pay_way === 1" src="../../assets/pay/wechat.png" class="pay-img" />
            <img v-if="rechargeForm.pay_way === 2" src="../../assets/pay/alipay.png" class="pay-img" />
            <div style="margin-left: 5px">请使用<span v-if="rechargeForm.pay_way === 1">微信</span><span v-if="rechargeForm.pay_way === 2">支付宝</span>扫码，支付<span style="color: rgba(255, 87, 51, 1);">
              {{ rechargeForm.gen_num * userInfo.gen_card_price}}
            </span>元</div>
          </div>
          <div style="margin-top: 20px" v-if="!payImg">
            <el-button type="primary" size="small" round @click="getQrCode" :disabled="rechargeForm.gen_num <=0 || rechargeForm.checked == false">获取支付二维码</el-button>
          </div>
          <div style="margin-top: 20px" v-else>
            <el-image
                style="width: 180px; height: 180px"
                :src="payImg"
                fit="fill"></el-image>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        width="30%"
        title="充值成功"
        :visible.sync="dialogSuccessVisible"
        append-to-body
        center>
      <div style="font-size: 14px;display: flex;align-items: center;justify-content: center">
        <i class="el-icon-success" style="font-size: 30px;color: #13ce66"></i>
        充值成功
      </div>
      <div style="text-align: center;margin-top: 10px;font-size: 14px">
        支付方式:
        <span v-if="outTradeInfo.pay_way == 1">微信支付</span>
        <span v-if="outTradeInfo.pay_way == 2">支付宝支付</span>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="dialogSuccessVisible = false">确 定</el-button>
          </span>
    </el-dialog>


    <el-dialog
        title="生成卡充值"
        :visible.sync="generalVisible"
        width="40%">
      <div>
        <div>生成卡套餐</div>
        <div style="margin-top: 10px; display: flex;align-items: center">
          <div style="display: flex;justify-content: flex-start;flex-wrap: wrap;">
            <div class="dialog_item"  v-for="(item,index) in genPackageList" :key="index" @click="choseItem(item)">
              <div class="dialog_item_info" :class="{'dialog_item_info_check' : generalForm.pack_id === item.id}">
                <span class="dialog_item_left">{{item.quantity}}</span>
                <span class="dialog_item_right"><span style="font-size: 5px;">¥</span>{{ (item.selling_price / item.quantity) | numFilter }}/张</span>
              </div>
            </div>
          </div>
        </div>
        <div style="margin-top: 20px">支付方式</div>
        <div style="display: flex;margin-top: 10px">
          <div @click="changeGenPayType(1)" class="check_type" :class="{'active_check_type' : generalForm.pay_way === 1}">
            <img src="../../assets/pay/wechat.png" class="pay-img" />
            <span style="margin-left: 5px">微信</span>
          </div>
          <div @click="changeGenPayType(2)" class="check_type" style="margin-left: 20px" :class="{'active_check_type' : generalForm.pay_way === 2}">
            <img src="../../assets/pay/alipay.png" class="pay-img" />
            <span style="margin-left: 5px">支付宝</span>
          </div>
        </div>
        <div style="margin-top: 10px">
          <el-checkbox v-model="generalForm.checked"></el-checkbox><span style="margin-left: 10px">同意</span><span @click="rechargeAgreement" style="margin-left: 5px;color: rgba(0, 119, 255, 1);">《充值服务协议》</span>
        </div>
        <div style="margin-top: 20px;display: flex;flex-direction: column;align-items: center;">
          <div style="display: flex;align-items: center">
            <img v-if="generalForm.pay_way === 1" src="../../assets/pay/wechat.png" class="pay-img" />
            <img v-if="generalForm.pay_way === 2" src="../../assets/pay/alipay.png" class="pay-img" />
            <div style="margin-left: 5px">请使用<span v-if="generalForm.pay_way === 1">微信</span><span v-if="generalForm.pay_way === 2">支付宝</span>扫码，支付<span style="color: rgba(255, 87, 51, 1);">
              {{ generalForm.selling_price }}
            </span>元</div>
          </div>
          <div style="margin-top: 20px" v-if="!payImg">
            <el-button @click="generalBuy" type="primary" size="small" round  :disabled="generalForm.checked == false">获取支付二维码</el-button>
          </div>
          <div style="margin-top: 20px" v-else>
            <el-image
                style="width: 180px; height: 180px"
                :src="payImg"
                fit="fill"></el-image>
          </div>
        </div>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import {
  merchantsInfo,
  merchantsCardResd,
  genCardRecharge,
  genCardRechargeCheck,
  genPackageList,
  genPackRecharge,
  enterpriseCertInfo
} from "../../api/merchantsUser";
import {userTimeCardResd} from "../../api/sklmUser";

export default {
  name: "CardResd",
  filters: {
    numFilter (value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    }
  },
  data(){
    return{
      generalVisible: false,
      generalForm:{
        pack_id:'',
        pay_way:1,
        selling_price:'',
        checked:false
      },
      genPackageList:[],
      autoHeight:'',
      autoWidth:'',
      interval: null,
      outTradeInfo:{
        out_trade_no:"",
        trade_no:"",
        pay_way:1,
        merchant_id:-1,
        trade_status:"WAIT_PAY",
        total_amount:0.00,
        create_time:null,
        update_time:null,
        gen_card_price:0.02,
        gen_num:0
      },
      dialogSuccessVisible:false,
      out_trade_no: '',
      payImg:'',
      rechargeForm:{
        gen_num:"",
        pay_way:1,
        checked:false,
      },
      rechargeDialogVisible:false,
      userInfo:{
        gen_pay_type:1
      },
      activeName: 'first',
      tableDataOne:[],
      page_no_one:1,
      page_size_one:10,
      total_one:0,
      tableDataResOne: [],
      page_no_ResOne: 1,
      page_size_ResOne: 5,
      total_ResOne: 0,
      tableDataResFour: [],
      page_no_ResFour: 1,
      page_size_ResFour: 5,
      total_ResFour: 0,
      tableDataResTwo: [],
      page_no_ResTwo: 1,
      page_size_ResTwo: 5,
      total_ResTwo: 0
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData()
    this.doMerchantsCardResd();
    this.initDataResOne();
    this.initDataResTwo();
    this.initDataResFour();
    this.initGenPackageList()
  },
  methods:{
    doRecharge(){

      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data))
        let certification_status = res.data.certification_status
        if(certification_status ===2 || certification_status ===3){
          console.log(JSON.stringify(this.userInfo))
          if(this.userInfo.gen_pay_type == 1){
            this.generalVisible = true
          }else{
            this.rechargeDialogVisible = true
          }
        }else{
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/authentication_protocol");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });

    },
    generalBuy(){
        console.log(JSON.stringify(this.generalForm))
      if(this.generalForm.checked == false){
        this.$message.error("请确认已阅读充值服务协议!");
        return
      }
      genPackRecharge(this.generalForm).then(res=>{
        console.log(JSON.stringify(res))
        let _this = this
        if (res.code == "0") {
          _this.payImg = res.data.base64
          _this.out_trade_no = res.data.out_trade_no
          clearInterval(_this.interval);
          _this.interval = setInterval(function(){
            _this.doGenCardRechargeCheck()
          }, 1000);
        }else{
          this.$message.error(res.message);
        }
      })
    },
    choseItem(row){
      console.log("choseItem:" + JSON.stringify(row))
      this.generalForm.pack_id = row.id
      this.generalForm.selling_price = row.selling_price
    },
    initGenPackageList(){
      genPackageList().then((response) => {
        this.genPackageList = response.data.list;
        this.generalForm.pack_id = response.data.list[0].id
        this.generalForm.selling_price = response.data.list[0].selling_price
      });
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 370) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    rechargeAgreement(){
      const routeUrl = this.$router.resolve({
        path: "/recharge_agreement"
      });
      window.open(routeUrl.href, "_blank");
    },
    doGenCardRechargeCheck(){
      console.log("定时检查订单")
      let _this = this
      let parms = {
        outTradeNo : this.out_trade_no
      }
      genCardRechargeCheck(parms).then(res=> {
        if (res.code == "0") {
            console.log(JSON.stringify(res.data))
           _this.outTradeInfo = res.data
          if(res.data.trade_status == "SUCCESS"){
            clearInterval(_this.interval);
            _this.payImg = ''
            _this.initData()
            _this.doMerchantsCardResd();
            _this.initDataResOne();
            _this.initDataResTwo();
            _this.initDataResFour();
            _this.rechargeDialogVisible = false
            _this.generalVisible = false
            _this.dialogSuccessVisible = true
          }
        }
        })
    },
    getQrCode(){
      let _this = this
      if(this.rechargeForm.gen_num < this.userInfo.gen_card_lowest){
        this.$message.error("尚未达到最低充值数量!");
        return
      }
      if(this.rechargeForm.checked == false){
        this.$message.error("请确认已阅读充值服务协议!");
        return
      }
      genCardRecharge(this.rechargeForm).then(res=>{
        console.log(JSON.stringify(res))
        let _this = this
        if (res.code == "0") {
          _this.payImg = res.data.base64
          _this.out_trade_no = res.data.out_trade_no
          clearInterval(_this.interval);
          _this.interval = setInterval(function(){
            _this.doGenCardRechargeCheck()
          }, 1000);
        }else{
          this.$message.error(res.message);
        }
      })
    },
    changePayType(type){
      this.rechargeForm.pay_way = type
      this.payImg = ''
    },
    changeGenPayType(type){
      this.generalForm.pay_way = type
      this.payImg = ''
    },
    changeGenNum(value){
      console.log("changeGenNum")
      this.payImg = ''
    },
    initData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no_one = val;
      this.doMerchantsCardResd();
    },
    doMerchantsCardResd(){
      let queryParams = {}
          queryParams.page = this.page_no_one,
          queryParams.limit = this.page_size_one,
              queryParams.orderType = 2,
              queryParams.sidx = 'create_time',
              queryParams.order = 'desc'
      merchantsCardResd(queryParams).then((res) => {
        this.tableDataOne = res.data.list;
        this.total_one = res.data.total_count;
      });
    },
    handleCurrentChangeResOne(val) {
      console.log(`当前页: ${val}`);
      this.page_no_ResOne = val;
      this.initDataResOne();
    },
    handleCurrentChangeResTwo(val) {
      console.log(`当前页: ${val}`);
      this.page_no_ResTwo = val;
      this.initDataResTwo();
    },
    handleCurrentChangeResFour(val){
      console.log(`当前页: ${val}`);
      this.page_no_ResFour = val;
      this.initDataResFour();
    },
    initDataResOne() {
      let queryParams = {}
      queryParams.page = this.page_no_ResOne,
      queryParams.limit = this.page_size_ResOne,
          queryParams.orderType = 1,
          queryParams.sidx = 'create_time',
          queryParams.order = 'desc'
      merchantsCardResd(queryParams).then((response) => {
        this.tableDataResOne = response.data.list;
        this.total_ResOne = response.data.total_count;
      });
    },
    initDataResTwo() {
      let queryParams = {}
      queryParams.page = this.page_no_ResTwo,
      queryParams.limit = this.page_size_ResTwo,
      queryParams.isCreatorTemId = 1
          queryParams.sidx = 'create_time',
          queryParams.order = 'desc'
      userTimeCardResd(queryParams).then((response) => {
        this.tableDataResTwo = response.data.list;
        this.total_ResTwo = response.data.total_count;
      });
    },
    initDataResFour() {
      let queryParams = {}
          queryParams.page = this.page_no_ResFour,
          queryParams.limit = this.page_size_ResFour,
          queryParams.orderType = 3,
          queryParams.sidx = 'create_time',
          queryParams.order = 'desc'
      merchantsCardResd(queryParams).then((response) => {
        this.tableDataResFour = response.data.list;
        this.total_ResFour = response.data.total_count;
      });
    },
  }
}
</script>

<style scoped>
.item_info{
  background: rgba(245, 245, 245, 1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 20px;
  width: 180px;
  margin-right: 10px;
}
.item_text{
  margin-top: 5px;
  color: rgba(166, 166, 166, 1);
  font-size: 14px;
}

.avatar {
  width: 60px;
  width: 60px;
}

.check_type{
  width: 146px;
  height: 50px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.active_check_type{
  border: 1px solid rgba(255, 87, 51, 1);
}

.pay-img{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.dialog_item{
  width: 200px;
  height: 50px;
  margin-top: 10px;
  margin-right: 10px;
}

.dialog_item_info{
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dialog_item_info_check{
  border: 1px solid rgba(255, 87, 51, 1);
}


.dialog_item_left{
  font-size: 14px;
  font-weight: 400;
}

.dialog_item_right{
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 87, 51, 1);
}

</style>
