<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div class="plate_type_info">
      <div>
        <div class="plate_type_name">会员制系统</div>
        <div class="plate_type_tips">开启后，用户必须为会员身份时，才能加入IP项目、查看IP商品和素材</div>
      </div>
      <el-switch
          @change="merchantConfigUpdate"
          v-model="merchantConfig.member_system"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>
    <el-divider></el-divider>
    <div class="plate_type_info">
      <div>
        <div class="plate_type_name">保证金是否需要缴纳</div>
        <div class="plate_type_tips">开启后,用户必须缴纳足额保证金后,才能看到首页商品板块</div>
      </div>
      <el-switch
          @change="merchantConfigUpdate"
          v-model="merchantConfig.deposit_check"
          active-color="#13ce66"
          inactive-color="#ff4949">
      </el-switch>
    </div>

  </div>
</template>

<script>

import { merchantsConfig, merchantsConfigUpdate } from "../../api/merchant_config";

export default {
  name: "ele_fun_config",
  data(){
    return{
      merchantConfig:{
        member_system: false,
        deposit_check: false
      }
    }
  },
  mounted(){
    this.merchantConfigInfo()
  },
  methods:{
    merchantConfigUpdate(){
      merchantsConfigUpdate(this.merchantConfig).then(res=> {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '操作成功'
          });
        }else{
          this.$message.error({message: res.message});
        }
      })
    },
    merchantConfigInfo(){
      merchantsConfig().then((res) => {
        this.merchantConfig = res.data
      });
    }
  }
}
</script>

<style scoped>
/* el-divider 修改高度&虚线效果 */
.el-divider--horizontal{
  margin: 8px 0;
  background: 0 0;
  border-top: 2px dashed #e8eaec;
}
.plate_type_info{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.plate_type_name{
  font-size: 16px;
  font-weight: 500;
  color: rgba(56, 56, 56, 1);
}
.plate_type_tips{
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(56, 56, 56, 1);
}

.avatar-uploader{
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
