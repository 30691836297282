import service from "../../request";

export function courseClassHourList(query) {
    return service({
        url: '/api/merchants/courseClassHour/list',
        method: 'get',
        params: query
    })
}

export function saveOrUpdate(query,data) {
    return service({
        url: '/api/merchants/courseClassHour/saveOrUpdate',
        method: 'post',
        data: data
    })
}

export function courseClassHourDel(query) {
    return service({
        url: '/api/merchants/courseClassHour/del',
        method: 'get',
        params: query
    })
}
