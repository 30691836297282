<template>
  <div style="background: white; padding: 10px; margin: 10px">
    <el-button type="primary" @click="add" size="small">新增客服</el-button>

    <el-table
      :data="tableData"
      :loading="listLoading"
      border
      style="margin-top: 10px"
      :max-height="autoHeight"
    >
      <el-table-column align="center" prop="we_chat_id" label="微信号">
      </el-table-column>

      <el-table-column align="center" label="微信二维码">
        <template slot-scope="scope">
          <el-image
            style="width: 60px; height: 60px"
            :src="scope.row.we_chat_pictures"
            fit="cover"
          >
          </el-image>
        </template>
      </el-table-column>

      <el-table-column align="center" prop="create_time" label="创建时间">
      </el-table-column>

      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            @click.native.prevent="handleEdit(scope.row)"
            type="text"
            size="small"
          >
            编辑
          </el-button>
          <el-button
            @click.native.prevent="handleDel(scope.row)"
            type="text"
            size="small"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px; margin-right: 20px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page_no"
            :page-size="page_size"
            :total="total"
            layout="total, prev, pager, next"
          ></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="微信号:">
          <el-input size="small" v-model="form.we_chat_id"></el-input>
        </el-form-item>
        <el-form-item label="微信二维码:">
          <div>
            <el-upload
              class="avatar-uploader"
              action=""
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :http-request="uploadImg"
            >
              <img
                v-if="form.we_chat_pictures"
                :src="form.we_chat_pictures"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span style="color: rgba(166, 166, 166, 1); font-size: 12px"
              >建议上传尺寸为500*500px的jpg、png图片</span
            >
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button size="small" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { list, saveOrUpdate, del } from "../../api/sklmCustomerService";
import { getOssAccessInfo } from "../../api/common";
import Sha1 from "js-sha1";
import {
  merchantsConfig,
  merchantsConfigUpdate,
} from "../../api/merchant_config";

var OSS = require("ali-oss");

export default {
  name: "ip_service",
  data() {
    return {
      merchantConfig: {
        online_service: true,
        we_chat_service: true,
        agent_service: true,
      },
      form: {
        we_chat_id: "",
        we_chat_pictures: "",
      },
      dialogTitle: "",
      dialogVisible: false,
      autoHeight: "",
      autoWidth: "",
      queryParams: {
        module: 2,
      },
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 100,
    };
  },
  created() {
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
  },
  mounted() {
    this.initData();
    this.merchantConfigInfo();
  },
  methods: {
    merchantConfigUpdate(val) {
      merchantsConfigUpdate(this.merchantConfig).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    merchantConfigInfo() {
      merchantsConfig().then((res) => {
        this.merchantConfig = res.data;
      });
    },
    handleDel(val) {
      this.$confirm("确认删除该客服?", "删除客服", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          del({ id: val.id }).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.initData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    submit() {
      if (this.form.we_chat_id == "") {
        this.$message.error("微信号不能为空!");
        return;
      }
      if (this.form.we_chat_pictures == "") {
        this.$message.error("微信图片不能为空!");
        return;
      }
      let form = this.form;
      form.module = 2;
      saveOrUpdate({}, form).then((res) => {
        if (res.code == 0) {
          this.$message.success({
            duration: 1000,
            message: "操作成功",
          });
          this.initData();
          this.dialogVisible = false;
        } else {
          this.$message.error({ message: res.message });
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res));
      console.log(JSON.stringify(file));
    },
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      // if (!isLt2M) {
      //   this.$message.error("上传头像图片大小不能超过 2MB!");
      // }
      // return isJPG && isLt2M;
      return true;
    },
    uploadImg(res) {
      console.log(res);
      console.log(res.file);
      let newFile = res.file;
      getOssAccessInfo().then((res) => {
        console.log(JSON.stringify(res));
        const client = new OSS({
          region: "oss-cn-hangzhou",
          accessKeyId: res.data.access_key_id,
          accessKeySecret: res.data.access_key_secret,
          bucket: "cjfx-res",
          stsToken: res.data.security_token,
        });
        console.log(client);
        let randomStr = "";
        for (var i = 0; i < 9; i++) {
          randomStr = randomStr + Math.floor(Math.random() * 10).toString();
        }
        let fileName =
          "/merchantscenter/banner/" +
          Sha1(this.$store.state.token + new Date().getTime() + randomStr) +
          newFile.type;
        console.log(fileName);
        client.put(fileName, newFile).then((res) => {
          console.log(JSON.stringify(res));
          this.form.we_chat_pictures =
            "http://cjfx-res.oss-cn-hangzhou.aliyuncs.com" + fileName;
          console.log(JSON.stringify(this.form));
          this.$message.success("微信二维码上传成功");
        });
      });
    },
    add() {
      this.dialogTitle = "新增客服";
      this.form = {
        we_chat_id: "",
        we_chat_pictures: "",
      };
      this.dialogVisible = true;
    },
    handleEdit(row) {
      this.form = row;
      this.dialogTitle = "编辑客服";
      this.dialogVisible = true;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    getHeight() {
      this.autoHeight = parseInt(window.innerHeight) - 250 + "px";
      this.autoWidth = parseInt(window.innerWidth) + "px";
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams;
      (queryParams.page = this.page_no),
        (queryParams.limit = this.page_size),
        list(queryParams).then((response) => {
          this.tableData = response.data.list;
          this.total = response.data.total_count;
          this.listLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.avatar-uploader {
  border: 1px dashed #757070;
  border-radius: 6px;
  width: 200px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
