import MyCloudLayout from "../views/layout/MyCloudLayout";
import ClipLayout from "../views/layout/ClipLayout";
import my_cloud_clip_video from "../views/my_cloud/my_cloud_clip_video";
import tem_video_list from "../views/my_cloud/tem_video_list";
import my_cloud_my_file from "../views/my_cloud/my_cloud_my_file";
import my_cloud_info from "../views/my_cloud/my_cloud_info";
import TrillGoodsEdit from "../views/trill_goods/edit";
import taskDetails from "../views/grass_task/taskDetails";
const template = [
    {
        path:"/my_cloud_my_file",
        name:'我的文件',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/wdwj.png',
        component: ClipLayout,
        isSingleMenu:true,
        isMenu:true,
        children:[
            {
                path:"/my_cloud_my_file",
                name:'我的文件',
                component: my_cloud_my_file,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            }
        ]
    },
    {
        path:"/my_cloud_clip_video",
        name:'云剪辑视频',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/yjjsp.png',
        component: ClipLayout,
        isMenu:true,
        isSingleMenu:true,
        children:[
            {
                path:"/my_cloud_clip_video",
                name:'云剪辑视频',
                component: my_cloud_clip_video,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'clip'
                    }
                }
            },
            {
                path:"/tem_video_list",
                name:'模板视频列表',
                isHidden : true,
                component: tem_video_list,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:true,
                        showUserInfo:true,
                        menu_item_active: 'cloud'
                    }
                }
            }
        ]
    },
    {
        path:"/my_cloud_info",
        name:'我的文件',
        iconClass:'el-icon-s-platform',
        iconImg:'https://merchants-center.oss-cn-hangzhou.aliyuncs.com/icon/wdwj.png',
        component: MyCloudLayout,
        isSingleMenu:false,
        isMenu:false,
        children:[
            {
                path:"/my_cloud_info",
                name:'我的文件',
                component: my_cloud_info,
                meta:{
                    headInfo: {
                        showHeader: true,
                        showMenu:false,
                        showUserInfo:true,
                        menu_item_active: 'other'
                    }
                }
            }
        ]
    },
]

export default template;
