import service from "../request";

// mcn标示申请列表
export function trillMcnMarkList(query) {
    return service({
        url: '/api/merchants/trillMcnMark/list',
        method: 'get',
        params: query
    })
}

// mcn标示申请取消通过
export function cancelSure(data) {
    return service({
        url: '/api/merchants/trillMcnMark/cancelSure',
        method: 'post',
        data: data
    })
}


// mcn标示申请驳回
export function applyReject(data) {
    return service({
        url: '/api/merchants/trillMcnMark/applyReject',
        method: 'post',
        data: data
    })
}

// mcn标示通过
export function applySure(data) {
    return service({
        url: '/api/merchants/trillMcnMark/applySure',
        method: 'post',
        data: data
    })
}

// mcn标示申请取消驳回
export function cancelReject(data) {
    return service({
        url: '/api/merchants/trillMcnMark/cancelReject',
        method: 'post',
        data: data
    })
}
