<template>
  <div style="background: white;padding: 10px;margin: 10px">
    <div>
      <el-button size="small" type="primary" @click="add()">新建视频课</el-button>
    </div>

    <el-tabs v-model="queryParams.custom_state" @tab-click="initData">
      <el-tab-pane
          v-for="(item) in customStateList"
          :key="item.value"
          :label="item.label"
          :name="item.value + ''"
      >
      </el-tab-pane>
    </el-tabs>

    <div style="margin-top: 10px">
      <el-input
          size="small"
          placeholder="课程名称"
          v-model="queryParams.nick_name"
          clearable
          style="width: 120px;"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="授课老师"
          v-model="queryParams.nick_name"
          clearable
          style="width: 120px;margin-left: 10px"
      >
      </el-input>
      <el-button
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="initData()"
      >搜索</el-button>
    </div>

    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%;margin-top: 10px">
      <el-table-column align="center" label="课程信息">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center">
            <img
                style="width: 60px;height: 60px"
                :src="scope.row.cover"
            />
            <div style="margin-left: 10px;text-align: left">
              {{scope.row.class_name}}
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="已更新/预计更新课时">
        <template slot-scope="scope">
          <span> {{scope.row.extend.updateClassHoursNum}}/{{scope.row.class_hours_num}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="授课老师">
        <template slot-scope="scope">
          <div v-for="(item,index) in scope.row.extend.teacherList" :key="index" class="teacher_info">
            <div style="padding: 5px">
              <img
                  style="width: 60px;"
                  :src="item.head_picture"
              />
              <span style="margin-left: 5px">{{item.nick_name}}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="sell_price" label="价格"></el-table-column>
      <el-table-column align="center" prop="virtual_sales" label="销量"></el-table-column>
      <el-table-column align="center" prop="custom_sort" label="排序"></el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作" >
        <template slot-scope="scope">
          <el-button
              type="text"
              @click="course_class_hour(scope.row)"
              size="small">
            课程管理
          </el-button>
          <el-button
              type="text"
              @click="edit(scope.row)"
              size="small">
            编辑
          </el-button>
          <el-button
              v-if="queryParams.custom_state == 1"
              type="text"
              @click="down(scope.row)"
              size="small">
            下架
          </el-button>
          <el-button
              v-if="queryParams.custom_state == 0"
              type="text"
              @click="shelf(scope.row)"
              size="small">
            上架
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { courseClassList ,courseClassState ,saveOrUpdate} from "../../api/course/courseClass";
import moment from 'moment';

export default {
  name: "video_guest",
  data(){
    return{
      customStateList: [
        {
          value: 1,
          label: '已上架'
        },
        {
          value: 1,
          label: '已下架'
        }
      ],
      dialogTitle:'',
      dialogVisible:false,
      form:{
      },
      rules:{},
      queryParams:{
        custom_state: '1',
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading:false,
      tableData: []
    }
  },
  mounted(){
    this.doCourseClassState()
    this.initData()
  },
  methods:{
    shelf(row){
      this.$confirm("确认上架该视频课?", "上架视频课", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let form = {
          course_class_id : row.course_class_id,
          custom_state: 1,
          shelf_time : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }
        saveOrUpdate(form).then(res=> {
          if(res.code==0) {
            this.doCourseClassState()
            if(this.tableData.length <= 1){
              this.queryParams.page = this.queryParams.page -1
            }
            this.initData()
            this.$message.success({
              duration: 1000,
              message: '上架成功'
            });
          }else{
            this.$message.error({message: res.message});
          }
        })
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    down(row){
      this.$confirm("确认下架该视频课?", "下架视频课", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let form = {
          course_class_id : row.course_class_id,
          custom_state: 0,
          down_time : moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        }
        saveOrUpdate(form).then(res=> {
          if(res.code==0) {
            this.doCourseClassState()
            if(this.tableData.length <= 1){
              this.queryParams.page = this.queryParams.page -1
            }
            this.initData()
            this.$message.success({
              duration: 1000,
              message: '下架成功'
            });
          }else{
            this.$message.error({message: res.message});
          }
        })
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    add(){
      this.$router.push("/video_guest_info?course_class_id=-1")
    },
    edit(row){
      this.$router.push("/video_guest_info?course_class_id=" + row.course_class_id)
    },
    course_class_hour(row){
      this.$router.push("/course_class_hour?course_class_id=" + row.course_class_id)
    },
    initData(){
      this.listLoading = true
      courseClassList(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
    doCourseClassState(){
      courseClassState().then((res) => {
        this.customStateList = res.data.list
      });
    }
  }
}
</script>

<style scoped>
  .teacher_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
  }
</style>
