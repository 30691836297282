<template>
  <div style="background-color: white;">
    <div style="width: 100%;padding-top: 40px; text-align: center">可用总容量：<span style="font-size: 24px;font-weight: 400;">{{ cloudInfo.cloudSize | formatFileSize }}</span></div>
    <div style="padding-left: 20%;padding-right:20%;margin-top: 20px;margin-bottom: 20px">
      <span style="font-size: 16px;font-weight: 400;">已使用：{{cloudInfo.useCloudSize | formatFileSize }}</span>
      <el-progress :percentage="cloudInfo.percentage"></el-progress>
    </div>

    <div class="content_head" v-if="userInfo.talent_matrix === false">
      <div style="flex: 1;text-align: right">
        <img  src="../../assets/people.png" style="height: 100%"/>
      </div>
      <div style="flex: 1; display: flex;flex-direction: column;justify-content: space-around;padding-left: 20px">
        <div style="font-size: 24px;font-weight: 400;">开通达人矩阵系统尊享1T超大容量</div>
        <div style="font-size: 14px;font-weight: 400;color: rgba(128, 128, 128, 1);">容量有效期与达人矩阵系统有效期一致</div>
        <div>¥<span style="font-size: 24px;font-weight: 500;color: rgba(0, 0, 0, 1);">150,000</span> 元/年
          <el-button type="primary" round size="mini" @click="talentMatrix(1)">立即开通</el-button>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="content_title">马上扩充云空间容量</div>
      <div class="wrap">
        <div class="space_plan_item" v-for="(item,index) in list" :key="index">
          <div class="item_gb">{{item.cloud_size | formatFileSizeZero}}</div>
          <div class="item_money"><span style="font-size: 20px;">¥</span>{{item.selling_price}}/年</div>
          <el-button type="primary" round size="small" @click="buyItem(item)">立即购买</el-button>
        </div>
      </div>
      <div style="margin-top: 50px">2
        <p class="question">容量套餐是什么？</p>
        <p class="answer">答：容量套餐是视客联盟提供的存储容量扩展服务，当您的云空间容量不足时，可以通过购买容量套餐进行容量扩充，存储更多文件，视客联盟提供2GB的永久空间容量。</p>
        <p class="question">容量套餐的有效期如何计算？</p>
        <p class="answer">答：当您成功购买容量套餐后，扩展容量立即生效，容量套餐的有效期为一年，有效期按照自然年进行计算。
          <br/>当购买一个容量套餐时，如：
          <br/>您于2022年2月20日12:12:12购买一年的容量套餐， 则可使用至2023年2月20日 12:12:12；
          <br/>当购买多个容量套餐时，容量进行叠加，如：
          <br/>您于2022年2月20日12:12:12购买200G的容量套餐，2022年3月5日12:12:12再购买500G的容量套餐，在2022年3月5日12:12:12到2023年2月20日12:12:12期间，您可使用的容量套餐总和为700G。在2023年2月20日12:12:12到2023年3月5日12:12:12期间，您可使用的容量套餐为未到期的500G；</p>
        <p class="question">容量套餐到期后，容量该如何处理？</p>
        <p class="answer">答：如您已购买的容量套餐到期，未进行续费，到期后所购买的容量将被收回。当收回容量时您云空间中已存储的文件，小于当前可使用容量，则文件将不受任何影响，仍可正常使用。当收回容量时您云空间中已存储的文件，超出或等于当前可使用容量，超出可使用容量部分的文件不会被删除，但你将无法向云空间中存储文件，云剪辑功能不受影响。</p>
      </div>
    </div>
    <el-dialog
        title="容量套餐购买"
        :visible.sync="rechargeDialogVisible"
        width="40%">
      <div style="padding-left: 20px;padding-right: 20px">
        <div>容量套餐</div>
        <div style="display: flex;justify-content: flex-start;flex-wrap: wrap;">
          <div class="dialog_item"  v-for="(item,index) in list" :key="index" @click="choseItem(item)">
              <div class="dialog_item_info" :class="{'dialog_item_info_check' : rechargeForm.pack_id === item.id}">
                <span class="dialog_item_left">{{item.cloud_size | formatFileSizeZero}}</span>
                <span class="dialog_item_right"><span style="font-size: 5px;">¥</span>{{item.selling_price }}/年</span>
              </div>
          </div>
        </div>

        <div style="margin-top: 20px">支付方式</div>
        <div style="display: flex;margin-top: 10px">
          <div @click="changePayType(1)" class="check_type" :class="{'active_check_type' : rechargeForm.pay_way === 1}">
            <img src="../../assets/pay/wechat.png" class="pay-img" />
            <span style="margin-left: 5px">微信</span>
          </div>
          <div @click="changePayType(2)" class="check_type" style="margin-left: 20px" :class="{'active_check_type' : rechargeForm.pay_way === 2}">
            <img src="../../assets/pay/alipay.png" class="pay-img" />
            <span style="margin-left: 5px">支付宝</span>
          </div>
        </div>
        <div style="margin-top: 10px">
          <el-checkbox v-model="rechargeForm.checked"></el-checkbox><span style="margin-left: 10px">同意</span><span @click="rechargeAgreement" style="margin-left: 5px;color: rgba(0, 119, 255, 1);">《充值服务协议》</span>
        </div>
        <div style="margin-top: 20px;display: flex;flex-direction: column;align-items: center;">
          <div style="display: flex;align-items: center">
            <img v-if="rechargeForm.pay_way === 1" src="../../assets/pay/wechat.png" class="pay-img" />
            <img v-if="rechargeForm.pay_way === 2" src="../../assets/pay/alipay.png" class="pay-img" />
            <div style="margin-left: 5px">请使用<span v-if="rechargeForm.pay_way === 1">微信</span><span v-if="rechargeForm.pay_way === 2">支付宝</span>扫码，支付<span style="color: rgba(255, 87, 51, 1);">
              {{rechargeForm.selling_price}}
            </span>元</div>
          </div>
          <div style="margin-top: 20px" v-if="!payImg">
            <el-button type="primary" size="small" round @click="getQrCode" :disabled="rechargeForm.gen_num <=0 || rechargeForm.checked == false">获取支付二维码</el-button>
          </div>
          <div style="margin-top: 20px" v-else>
            <el-image
                style="width: 180px; height: 180px"
                :src="payImg"
                fit="fill"></el-image>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        width="30%"
        title="扩容成功"
        :visible.sync="dialogSuccessVisible"
        append-to-body
        center>
      <div style="font-size: 14px;display: flex;align-items: center;justify-content: center">
        <i class="el-icon-success" style="font-size: 30px;color: #13ce66"></i>
        扩容成功
      </div>
      <div style="text-align: center;margin-top: 10px;font-size: 14px">
        支付方式:
        <span v-if="outTradeInfo.pay_way == 1">微信支付</span>
        <span v-if="outTradeInfo.pay_way == 2">支付宝支付</span>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button size="small" type="primary" @click="dialogSuccessVisible = false">确 定</el-button>
          </span>
    </el-dialog>

    <TalentMatrix ref="talentMatrix"></TalentMatrix>
  </div>
</template>

<script>

import { cloudPackageList ,cloudPackageRecharge ,cloudPackageCheck} from "../../api/my_cloud_info";
import {creatorUser, merchantsInfo, cloudInfo, enterpriseCertInfo} from "../../api/merchantsUser";
import TalentMatrix from "../../components/talent_matrix";

export default {
  name: "my_cloud_info",
  components: {
    TalentMatrix
  },
  filters: {
    formatFileSizeZero(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(0);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        fileSize = fileSize.toFixed(0);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        fileSize = fileSize.toFixed(0);
        return temp + 'MB';
      } else if(fileSize < (1024 * 1024 * 1024 * 1024)){
        temp = fileSize / (1024 * 1024 * 1024);
        fileSize = fileSize.toFixed(0);
        return temp + 'GB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024 * 1024);
        fileSize = fileSize.toFixed(0);
        return temp + 'TB';
      }
    },
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else if(fileSize < (1024 * 1024 * 1024 * 1024)){
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'TB';
      }
    },
    numberFormat: function (value) {
      return value.toFixed(2)
    },
    numFilterZeo (value) {
      let realVal = parseFloat(value).toFixed(0)
      return Number(realVal)
    }
  },
  data(){
    return{
      userInfo: {
        talent_matrix: null
      },
      creatorUser:{},
      percentage:0,
      payImg:'',
      out_trade_no:'',
      rechargeDialogVisible:false,
      list:[],
      interval: null,
      rechargeForm:{
        selling_price:0.00,
        pack_id:'',
        pay_way:1,
        checked: false
      },
      outTradeInfo:{},
      dialogSuccessVisible:false,
      cloudInfo:{
        videoCloudSize: 0,
        materialCloudSize: 0,
        percentage: 0,
        useCloudSize: 0,
        cloudSize: 0
      }
    }
  },
  mounted(){
    this.initCloudInfo()
    this.initBaseData()
    this.initData()
  },
  methods:{
    talentMatrix(type){

      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data))
        let certification_status = res.data.certification_status
        if(certification_status ===2 || certification_status ===3){
          this.$refs.talentMatrix.show(type)
        }else{
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/authentication_protocol");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });

    },
    initCloudInfo(){
      cloudInfo().then((res)=>{
        this.cloudInfo = res.data
      })
    },
    initBaseData(){
      merchantsInfo({}).then((res) => {
        this.userInfo = res.data.merchantsInfo;
      });
      creatorUser().then((res)=>{
        this.creatorUser = res.data
        this.percentage = res.data.storageSpaceInfo.gFileSize/ res.data.storageSpaceInfo.storageSpace  * 100
      })
    },
    initData(){
      cloudPackageList().then((response) => {
        this.list = response.data.list;
      });
    },
    choseItem(row){
      this.rechargeForm.pack_id = row.id
      this.rechargeForm.selling_price = row.selling_price
    },
    rechargeAgreement(){
      const routeUrl = this.$router.resolve({
        path: "/recharge_agreement"
      });
      window.open(routeUrl.href, "_blank");
    },
    buy(){
      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data))
        let certification_status = res.data.certification_status
        if(certification_status ===2 || certification_status ===3){
          this.rechargeForm.pack_id = this.list[0].id
          this.rechargeForm.selling_price = this.list[0].selling_price
          this.rechargeDialogVisible = true
        }else{
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/authentication_protocol");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    buyItem(item){
      enterpriseCertInfo({}).then((res) => {
        console.log(JSON.stringify(res.data))
        let certification_status = res.data.certification_status
        if(certification_status ===2 || certification_status ===3){
          this.rechargeForm.pack_id = item.id
          this.rechargeForm.selling_price = item.selling_price
          this.rechargeDialogVisible = true
        }else{
          this.$confirm("您还未完成企业认证,完成企业认证后再试!", "提示", {
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            this.$router.push("/authentication_protocol");
          }).catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            })
          })
        }
      });
    },
    changePayType(type){
      this.rechargeForm.pay_way = type
      this.payImg = ''
    },
    getQrCode(){
      console.log(JSON.stringify(this.rechargeForm))
      if(this.rechargeForm.checked == false){
        this.$message.error("请确认已阅读充值服务协议!");
        return
      }
      cloudPackageRecharge(this.rechargeForm).then(res=>{
        console.log(JSON.stringify(res))
        let _this = this
        if (res.code == "0") {
          _this.payImg = res.data.base64
          _this.out_trade_no = res.data.out_trade_no
          clearInterval(_this.interval);
          _this.interval = setInterval(function(){
            _this.check()
          }, 1000);
        }else{
          this.$message.error(res.message);
        }
      })
    },
    check(){
      console.log("定时检查订单")
      let _this = this
      let parms = {
        outTradeNo : this.out_trade_no
      }
      cloudPackageCheck(parms).then(res=> {
        if (res.code == "0") {
          console.log(JSON.stringify(res.data))
          _this.outTradeInfo = res.data
          if(res.data.trade_status == "SUCCESS"){
            clearInterval(_this.interval);
            _this.initData()
            _this.payImg = ''
            _this.rechargeDialogVisible = false
            _this.dialogSuccessVisible = true
          }
        }
      })
    },
  }
}
</script>

<style scoped>

.content{
  padding-left: 10%;
  padding-right: 10%;
}

.content_head{
  height: 200px;
  background: rgba(207, 229, 255, 1);
  display: flex;
  justify-content: center;
}

.content_title{
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 30px;
}

.wrap{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.space_plan_item{
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 230px;
  height: 230px;
}

.item_gb{
  font-size: 36px;
  font-weight: 400;
}

.item_money{
  font-size: 24px;
  font-weight: 400;
  color: rgba(255, 141, 26, 1);
}

.question{
  font-size: 18px;
  font-weight: 500;
}

.answer{
  font-size: 14px;
  font-weight: 400;
}

.check_type{
  width: 146px;
  height: 50px;
  opacity: 1;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.active_check_type{
  border: 1px solid rgba(255, 87, 51, 1);
}

.pay-img{
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.dialog_item{
  width: 200px;
  height: 50px;
  margin-top: 10px;
  margin-right: 10px;
}

.dialog_item_info{
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 229, 229, 1);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.dialog_item_info_check{
  border: 1px solid rgba(255, 87, 51, 1);
}


.dialog_item_left{
  font-size: 14px;
  font-weight: 400;
}

.dialog_item_right{
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 87, 51, 1);
}

</style>
