<template>
  <div>
    <TrillSettleOrdersList ref="child"/>
  </div>
</template>

<script>
import { trillSettleOrdersUpload} from "../../api/easyExcel";
import TrillSettleOrdersList from "./trillSettle_orders_list";
export default {
  components: {
    TrillSettleOrdersList
  },
  name: "trillSettle_orders",
  methods:{
    upload(res){
      let newFile = res.file
      let formData = new FormData()
      formData.append('file', newFile)
      trillSettleOrdersUpload(formData).then((res) => {
        if(res.code==0) {
          this.$message.success({
            duration: 1000,
            message: '文件上传成功'
          });
          this.$refs.child.initData();
        }else{
          this.$message.error({message: res.message});
        }
      });
    },
    handleAvatarSuccess(res, file) {
      console.log(JSON.stringify(res))
      console.log(JSON.stringify(file))
    },
    beforeAvatarUpload(file) {
      console.log("fileType:" + file.type)
      const isExcel = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        this.$message.error("上传文件非模板格式!");
        return false
      }
      return true
    },
    downloadTemplate(){
      window.open("https://merchantscenter.paitk.com/excel_template/%E7%BB%93%E7%AE%97%E8%AE%A2%E5%8D%95%E6%A8%A1%E7%89%88.xlsx",'_blank');
    }
  }
}
</script>

<style scoped>
.info_head{
  display: flex;
}

.info_head_title{
  line-height: 40px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  color: rgba(56, 56, 56, 1);
  vertical-align: center;
}

.template{
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 119, 255, 1);
}

.tips{
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(166, 166, 166, 1);
}
</style>
