<template>
  <div  style="background: white;border-radius: 10px;padding: 20px;margin-top: 10px">

    <el-button size="small" type="primary" @click="doAddRole">添加成员身份</el-button>

    <el-table
        border
        size="small"
        :data="tableData"
        style="width: 100%;margin-top: 10px">
      <el-table-column
          align="center"
          prop="name"
          label="身份名称">
      </el-table-column>
      <el-table-column
          align="center"
          prop="account_number"
          label="绑定账号数量">
      </el-table-column>
      <el-table-column
          align="center"
          prop="create_time"
          label="添加时间">
      </el-table-column>

      <el-table-column align="center" label="操作" >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="edit(scope.row)">
            编辑
          </el-button>
          <el-button type="text" size="small" v-if="scope.row.account_number === 0"  @click="delRow(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>


    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        @open="dialogShow"
        width="40%">

    <div style="width: 100%">
      <el-form size="small" :label-position="labelPosition" label-width="100px" :model="dialogForm" :rules="rules" ref="ruleForm">
        <el-form-item label="成员身份:" prop="name">
          <el-input v-model="dialogForm.name" clearable></el-input>
        </el-form-item>
      </el-form>

      <el-tree ref="tree" v-if="dialogVisible" :data="treeData" :props="defaultProps" node-key="id"  show-checkbox
               @node-click="handleNodeClick"
               @check-change="handleCheckChange"
               @check="handleCurrentCheck"
      ></el-tree>

    </div>

      <span slot="footer" class="dialog-footer">
       <el-button @click="dialogVisible = false">取 消</el-button>
       <el-button type="primary" @click="addRole('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import { list, add ,del ,menusAll} from "../../api/merchantsRole";

export default {
  name: "Collaboration",
  data() {
    return {
      treeData:[],
      defaultProps:{
        children: 'children',
        label: 'name'
      },
      dialogTitle:"",
      labelPosition: 'right',
      dialogVisible: false,
      dialogForm:{
        name:'',
        check_menus:[]
      },
      rules: {
        name: [
          { required: true, message: '请输入身份名称', trigger: 'blur' }
        ]
      },
      queryParams:{
        serviceStates:0,
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc"
      },
      total:0,
      listLoading: false,
      tableData: []
    }
  },
  mounted(){
    this.initData()
    this.doMenusAll()
  },
  methods:{
    handleCurrentCheck(nodeObj, selectedObj){
      console.log(nodeObj,selectedObj)
      console.log(selectedObj.checkedKeys)
      this.dialogForm.check_menus = selectedObj.checkedKeys
      console.log(selectedObj.checkedNodes) //这是选中的节点的key数组
      console.log(selectedObj.checkedNodes) //这是选中的节点数组
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    handleNodeClick(data) {
      console.log(data);
    },
    doMenusAll(){
      menusAll({}).then((response) => {
        this.treeData = response.data.list;
      });
    },
    doAddRole(){
      this.dialogForm = {}
      this.dialogTitle = "新增成员身份"
      this.dialogVisible = true
    },
    dialogShow(){
      let check_menus = this.dialogForm.check_menus
      check_menus.forEach((id) => {
        this.$nextTick(() => {
          this.$refs['tree'].setChecked(id,true,false)
      })
        // setTimeout(() => {
        //   this.$refs.tree.setChecked(id,false,false)
        // }, 0);
      });
    },
    edit(row){
      this.dialogForm = {}
      this.dialogTitle = "编辑成员身份"
      this.dialogForm = JSON.parse(JSON.stringify(row))
      this.dialogVisible = true
    },
    delRow(row){
      this.$confirm("确定删除 " + row.name + " 身份?", "删除成员身份", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
            del({roleId : row.id}).then((res) => {
              if(res.code==0) {
                this.$message.success({
                  duration: 1000,
                  message: '操作成功'
                });
                this.initData()
              }else{
                this.$message.error({message: res.message});
              }
            });
          })
      .catch(() => {});
    },
    addRole(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          add(this.dialogForm).then((res) => {
            if(res.code==0) {
              this.$message.success({
                duration: 1000,
                message: '操作成功'
              });
              this.dialogForm = {}
              this.initData()
              this.dialogVisible = false
            }else{
              this.$message.error({message: res.message});
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      list(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
