<template>
  <div style="padding: 20px">
  <div><p style="background-color:#ffffff; margin:0pt; text-align:center"><span
      style="color:#353535; font-family:宋体; font-size:13.5pt">视客联盟</span><span
      style="color:#353535; font-family:宋体; font-size:13.5pt">认证服务协议</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span style="color:#353535; font-family:宋体; font-size:10.5pt">【首部及导言】</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">为使用</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">服务（以下简称</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">“</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">本服务</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">”</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">），你应当阅读并遵守《</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">认证</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">服务协议》（以下简称</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">“</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">本协议</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">”</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">），请你务必审慎阅读、充分理解各条款内容，特别是免除或限制责任的相应条款，以及开通或使用某项服务的单独协议，并选择接受或不接受。限制、免责条款可能以加粗形式提示你注意。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">除非你已阅读并接受本协议所有条款，否则你无权使用</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">服务。你对本服务的登录、查看、发布信息等行为即视为你已阅读并同意本协议的约束。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">如果你未满</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">18</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">周岁，请在法定监护人的陪同下阅读本协议及其他上述协议，并特别注意未成年人使用条款。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span style="color:#353535; font-family:宋体; font-size:10.5pt">一、【协议的范围】</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">1.1</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">【协议适用主体范围】</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">本协议是你与</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">之间关于你使用</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证服务所订立的协议。</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">“</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">”</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">是指</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">杭州知视云科技有限</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">公司及其相关服务可能存在的运营关联单位。</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">“</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">用户</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">”</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">是指申请</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证服务及</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">/</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">或已经认证成功的用户，在本协议中更多地称为</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">“</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">你</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">”</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">1.2</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">【协议关系及冲突条款】</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">本协议被视为</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">《视客联盟用户协议》《视客联盟隐私声明》</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">本协议内容同时包括</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">可能不断发布的关于本服务的相关协议、服务声明、业务规则及公告指引等内容（以下统称为</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">“</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">专项规则</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">”</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">）。上述内容一经正式发布，即为本协议不可分割的组成部分，你同样应当遵守。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span style="color:#353535; font-family:宋体; font-size:10.5pt">二、【术语定义】</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证服务：是指符合一定条件的用户可以对</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号申请</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">企业</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证。</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">根据用户的申请及其提交的资料和信息，自行或者委托第三方审核机构进行审核，并根据审核情况确定认证结果和认证信息的服务。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证审核：指用户向</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">提出申请，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">根据法律规定及双方的约定和保证，基于用户提交的资料和信息，对用户的</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号进行帐号资质审核。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.2.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号资质审核：指用户向</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">提出申请，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">根据法律规定及双方的约定和保证，对用户提交的主体资质证明或其所拥有的权利证明资料和信息进行甄别及核实的过程；</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.3 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">第三方审核机构：指</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">委托对用户进行认证审核的第三方机构。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.4 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">订单：指用户向</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">发起的本服务申请，每发起一次申请为一个订单。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.5 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">新订单：指用户以获得认证成功的审核结果为目的发起的本服务申请，在新订单的审核过程中，用户自发补充或者根据</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">及</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">/</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">或</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">委托第三方审核机构的要求所补充的资料作为同一订单的审核范围。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.6 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">补充订单：指用户认证成功后，申请变更、修改用户信息所产生的订单</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">,</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">在补充订单审核过程中，用户自发补充或者根据</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">及</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">/</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">或</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">委托第三方审核机构的要求所补充的资料同样作为同一订单的审核范围。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.7 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证结果：是指</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">及其委托的第三方审核机构按照用户的申请进行认证审核后，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">向用户输出的认证结果，认证结果为帐号资质审核结果，认证结果只包括成功和失败两种情形。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.7.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号资质审核结果：指</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">及其委托的第三方审核机构根据用户的申请，基于用户提交的资料和信息，对用户进行帐号资质审核后，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">向用户输出的结果。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.8 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证成功：指</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">自行或委托第三方审核机构对用户提交的资料和信息进行甄别及核实，在完成所有审核流程后，由</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">作出认证成功的判断。针对认证成功的用户，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">将根据本协议确定用户的认证帐号名称，生成认证标识及认证信息，开通相应的高级功能及高级权限。未按期完成认证或者认证失败的用户不属于认证成功用户。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.8.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号资质审核成功：指</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">自行或委托第三方审核机构对用户提交的资料和信息进行甄别及核实，在完成帐号资质审核流程后，由</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">作出帐号资质审核成功的判断。资质审核成功帐号可以申请</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">开通高级功能，但在用户帐号名称审核成功之前，该帐号不属于认证成功帐号，不会生成认证标识及认证信息，也不会开通高级权限。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.9 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">系统通知用户调整申请内容：指用户在申请认证过程中，因不满足认证成功条件，而被</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">以系统通知的方式要求作调整或补充的情形。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.10 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证失败：指</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">直接或委托第三方审核机构对用户的资料进行甄别及核实，在完成所有审核流程后，由</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">作出认证失败判断，包括但不限于以下任一情形：</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.10.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">由于用户原因，经</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">系统通知用户调整申请内容，用户仍未能满足帐号资质审核及帐号名称审核要求的；</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.10.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt"> </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号资质审核结果为失败的；</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2.10.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">3</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt"> </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">因其他原因而不能认证成功的情形。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span style="color:#353535; font-family:宋体; font-size:10.5pt">三、【用户的权利义务】</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">3.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">用户应保证其提供给</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">或者第三方审核机构的所有资料和信息的真实性、合法性、准确性和有效性。如用户提供服务或内容需要取得相关法律法规规定的许可或进行备案的，用户应当主动进行明确说明并提交相应的许可或备案证明。否则，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">有权拒绝或终止提供本服务，并依照本协议对违规帐号进行处罚。因此给</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">或第三方造成损害的，你应当依法予以赔偿。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">3.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">用户同意授权</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">将其提交的资料和信息委托第三方审核机构进行甄别核实，第三方审核机构有权出于认证审核之必要查阅、使用及保存上述资料及信息，并有权受</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">委托以自身名义与用户就认证审核事宜进行联系与沟通，用户应当配合第三方审核机构在认证审核过程中所提出的相关需求。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">3.3 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">用户不得通过非</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">授权的其他任何渠道使用本服务，如非因</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">或者第三方审核机构原因导致的认证失败、认证流程过长等后果，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">及第三方审核机构不承担责任。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">3.4 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">用户理解并且同意，用户提交的资料和信息是作出认证结果的重要依据，任何资料和信息的变更将可能影响认证结果，用户应当在资料和信息变更时及时提出补充认证申请，否则</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">有权依照本协议约定对用户进行处罚。</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">因此遭受损失的，你也应当一并赔偿。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">3.5 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">帐号资质审核成功后，用户可以申请为其开通高级功能，用户使用高级功能时应遵守法律、行政法规和本协议或相关服务条款的规定，否则，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">有权不经通知随时视行为情节对违规用户的帐号采取包括但不限于限制、停止使用高级功能等措施。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">3.6 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证成功后，用户有权以认证帐号名称和认证信息所公示的身份</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">进行</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">运营，并通过认证标识区别于其他非认证</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号用户。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">四、【</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">的权利义务】</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">4.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">保护用户信息是</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">的一项基本原则，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">将会采取合理的措施保护用户所提交的一切资料及信息。除法律法规规定的情形外，未经用户许可</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">不会向其他第三方公开、透露上述资料及信息。用户基于申请本服务的需要，授权</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">将其提交的资料和信息委托第三方审核机构进行甄别核实，在本协议许可的范围内，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">及第三</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">方审核机构对相关资料及信息采用专业加密存储与传输方式进行传输及使用，保障用户的信息安全。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">4.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">为依法保障</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证服务的规范有序，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">有权根据相关法律法规的规定及行业标准，针对用户的不同认证需求制定不同的认证审核标准及要求，并有权在必要时对相关标准及要求进行修改。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">4.3 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">有权根据用户提交的资料及信息，并结合第三方审核机构甄别核实的情况独立判断，分别确定审核结果，并根据审核情况分阶段确定帐号资质审核结果以及用户的认证帐号名称，生成认证标识及认证信息，开通相应的高级功能及高级权限。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span style="color:#353535; font-family:宋体; font-size:10.5pt">六、【认证服务规范】</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">6.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">【认证流程】</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">用户申请本服务需要登录申请页面按照</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">提示提交有关资料及信息。你在申请本服务时应仔细阅读并同意本协议，你对本服务的接受、提交资料和信息等行为即视为你已阅读并同意受本协议的约束。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">用户在完成在线申请及资料提交流程后，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">会在</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">15</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">个工作日内展开认证审核工作，用户应积极配合</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">及第三方审核公司的审核需求，并有权随时了解、查询审核进度。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.3 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">用户同意</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">可自行委托第三方审核机构对其提交的资料和信息进行甄别核实，并在完成所有审核流程后，由</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">独立作出帐号资质审核成功或者失败、认证成功或者认证失败的判断，并以前述判断为依据确定用户的认证帐号名称，生成认证标识及认证信息，开通相应的高级功能及高级权限等，用户应当予以配合。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.4 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">审核完成后，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">将反馈以下审核结果：</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.4.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号资质审核成功，用户获得申请</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">开通高级功能的权利；</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.4.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">2</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt"> </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证失败，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">将告知用户认证失败的原因。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">5</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt"> </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">用户向</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">或者第三方审核机构提供的资料和信息如有变更的，应当及时采取以下措施：</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">5</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">如处于认证审核过程中的资料和信息发生变更，用户应立即通知</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">或负责审核订单的第三方审核机构更新有关资料及信息；</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">5</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">如认证成功后资料和信息发生变更，用户应及时申请补充订单变更有关资料及信息；</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.1.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">5</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">.3 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">如认证成功后</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">发现资料和信息存在错误，用户应及时申请补充订单更正有关资料及信息。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">6.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">【认证规则】</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.2.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">用户理解并同意：</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证服务内容仅限于对用户提交的资料及信息进行甄别与核实，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">将对前述资料及信息进行合理、谨慎的形式审查，但在</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">的合法权限和合理能力范围内，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">无法实质审查用户的实际经营、运营以及推广等行为，并不为此提供任何担保。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.2.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">有权自行或委托第三方审核机构甄别核实包括但不限于以下内容：</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.2.2.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">用户是否拥有合法主体资质，以及是否取得提供服务或内容相应的权利或授权；</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.2.2.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">帐号运营者主体身份，以及是否经过用户明确授权；</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.2.2.3 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">其他</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">或第三方审核机构认为需要审核的内容。用户应就上述内容提供相应的证明文件予以证明并承担真实性、合法性、准确性的瑕疵担保责任。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">3</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt"> </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认证信息内容由</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">根据审核情况进行确定，并在认证帐号审核成功后生成，主要包括用户主体资质和权利等可有效证明的信息。</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">有权根据平台规范运营的需要调整认证信息的内容及格式。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">6.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">4</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt"> </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">为保障</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">安全运营及</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">其他用户的合法权益，若</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">发现或经他人举报用户的帐号存在任何违反法律法规及本协议等各项规范规则的情况，或</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">认为或经第三方通知用户的帐号存在或可能存在上述情况，不管该帐号是否已经认证成功，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">均有权采取拒绝提供认证服务等方式停止为该用户提供本服务。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">七、【法律责任】</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">7.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">如果</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">发现或收到他人投诉用户违反法律法规或本协议约定的，</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">有权不经通知随时视行为情节对违规用户的帐号处以包括但不限于拒绝提供本服务、终止本服务资质审核、责令用户补充资质审查资料、责令用户修改认证信息、责令用户再次申请认证、强制修改帐号名称及认证信息直至取消认证等措施。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">7.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">用户理解并同意，因用户违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，用户应当独立承担责任；</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">因此遭受损失的，用户也应当一并赔偿。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">八、【其他】</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">8.1 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">你使用本服务即视为你已阅读并同意受本协议的约束。</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">有权在必要时修改本协议条款。你可以在</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">规则中心</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">页面查阅最新版本的条款。本协议条款变更后，如果你继续使用</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">认证服务，即视为你已接受修改后的协议。如果你不接受修改后的协议，应当停止使用</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">认证服务。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">8.2 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">本协议签订地为中华人民共和国</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">浙江省杭州市</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">8.3 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt; font-weight:bold">8.4 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">若你和</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">视客联盟</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt; font-weight:bold">之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，你同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</span>
    </p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">8.5 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">8.6 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</span></p>
    <p style="background-color:#ffffff; margin:0pt"><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">8.7 </span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">你在使用本服务过程中，如发现相关内容存在违反相关法律法规或者侵犯了你的权利，请及时向我们投诉或反馈（</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">zhishiyunkj</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">@163.</span><span
        style="color:#353535; font-family:'Helvetica Neue'; font-size:10.5pt">com</span><span
        style="color:#353535; font-family:宋体; font-size:10.5pt">），我们将依法处理。</span></p></div>
</div>
</template>

<script>
export default {
  name: "AuthenticationProtocolInfo"
}
</script>

<style scoped>

</style>
