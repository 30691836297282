<template>
  <div style="background: white;padding: 10px;margin: 10px">

    <div class="tips">
      提示：IP商品由运营中心总部统一管理，小程序主无法进行编辑操作，仅展示上架中状态的商品。
    </div>

    <div style="margin-top: 10px;text-align:left;padding-right: 10px">
      <el-select @change="productTypeChange" v-model="queryParams.productType" placeholder="请选择商品来源" size="small">
        <el-option
            v-for="item in productTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>

      <el-input
          size="small"
          placeholder="商品名称"
          v-model="queryParams.productName"
          clearable
          style="width: 160px;margin-left: 10px"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="到手价"
          v-model="queryParams.minDiscountPrice"
          clearable
          style="width: 100px;margin-left: 20px"
      >
      </el-input>
      <i style="margin-left: 5px" class="el-icon-minus"></i>
      <el-input
          size="small"
          placeholder="到手价"
          v-model="queryParams.maxDiscountPrice"
          clearable
          style="width: 100px;margin-left: 5px"
      >
      </el-input>
      <el-input
          size="small"
          placeholder="佣金率"
          v-model="queryParams.minCommissionRate"
          clearable
          style="width: 100px;margin-left: 20px"
      >
      </el-input>
      <i style="margin-left: 5px" class="el-icon-minus"></i>
      <el-input
          size="small"
          placeholder="佣金率"
          v-model="queryParams.maxCommissionRate"
          clearable
          style="width: 100px;margin-left: 5px"
      >
      </el-input>
      <el-button
          size="small"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-search"
          @click="initData"
      >搜索</el-button>

    </div>



    <el-table
        :data="tableData"
        :loading="listLoading"
        border
        style="margin-top: 10px;"
        :max-height="autoHeight"
    >
      <el-table-column align="center" label="基本信息" width="300">
        <template slot-scope="scope">
          <div style="display:flex;" v-if="scope.row.commodity_module === 1">
            <el-image
                style="width: 30%; height: 100px"
                :src="scope.row.img"
                fit="cover">
            </el-image>
            <div style="width: 70%;padding:5px;display: flex;flex-direction: column;justify-content: center;text-align: left">
              <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{scope.row.name}}</div>
              <div>到手价: {{ formatNumber(scope.row.discount_price) }}</div>
              <div>总销量: {{scope.row.sell_num}}</div>
<!--              <div>库存: {{scope.row.total_stock_num}}</div>-->
            </div>
          </div>
          <div style="display:flex;" v-else>
            <el-image
                style="width: 30%; height: 100px"
                :src="scope.row.product_img"
                fit="cover">
            </el-image>
            <div style="width: 70%;text-align: left;margin: 5px;">
              <div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{scope.row.product_name}}</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="佣金率/公开佣金率" >
        <template slot-scope="scope">
          <div v-if="scope.row.commodity_module === 1">
            <div>{{scope.row.commission_rate}} %</div>
            <div>{{scope.row.open_commission_rate}} %</div>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </el-table-column>

      <el-table-column align="center" label="商品来源" >
        <template slot-scope="scope">
          <img
              v-if="scope.row.product_type == 1"
              style="width: 50px;height: 50px;border-radius: 50%"
              src="../../assets/dy.png"
              fit="cover"/>
          <img
              v-if="scope.row.product_type == 2"
              style="width: 50px;height: 50px;border-radius: 50%"
              src="../../assets/ks.png"
              fit="cover"/>
        </template>
      </el-table-column>

      <el-table-column align="center" label="排序" >
        <template slot-scope="scope">
          {{scope.row.custom_sort}}
        </template>
      </el-table-column>

      <el-table-column align="center" label="时间信息" >
        <template slot-scope="scope">
          <div v-if="scope.row.commodity_module === 1">
            <div>商品推广结束时间: {{ parseDateFull(scope.row.activity_end_time)}}</div>
            <div>商品信息更新时间: {{ parseDateFull(scope.row.goods_update_time) }}</div>
          </div>
          <div v-else>
            -
          </div>
        </template>
      </el-table-column>

    </el-table>

    <el-row style="margin-top: 24px;margin-right: 20px">
      <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="page_no"
                    :page-size="page_size"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
      </el-col>
    </el-row>
  </div>
</template>

<script>

import { ipGoodsList } from "../../api/trill_goods"

export default {
  name: "IpGoods",
  data(){
    return{
      productTypeOptions:[
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '抖音'
        },
        // {
        //   value: 2,
        //   label: '快手'
        // }
      ],
      productState: 1,
      tabs:[],
      customSortIndex: null,
      autoHeight:'',
      autoWidth:'',
      queryParams:{
        productType: ''
      },
      listLoading: false,
      tableData: [],
      page_no: 1,
      page_size: 10,
      total: 0
    }
  },
  created() {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  mounted(){
    this.initData();
  },
  activated(){
    console.log("activated")
    this.initData();
  },
  methods:{
    productTypeChange(){
      this.page_no = 1;
      this.initData();
    },
    formatNumber(val) {
      return (val/100).toFixed(2)
    },
    parseDateFull(time) {
      var x = new Date(time);
      var z = {
        y: x.getFullYear(),
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
      };
      if (z.M < 10) {
        z.M = "0" + z.M;
      }
      if (z.d < 10) {
        z.d = "0" + z.d;
      }
      if (z.h < 10) {
        z.h = "0" + z.h;
      }
      if (z.m < 10) {
        z.m = "0" + z.m;
      }
      return z.y + "-" + z.M + "-" + z.d + " " + z.h + ":" + z.m ;
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page_no = val;
      this.initData();
    },
    initData() {
      this.listLoading = true;
      let queryParams = this.queryParams
      queryParams.productState = this.productState,
          queryParams.page = this.page_no,
          queryParams.limit = this.page_size,
          queryParams.sidx = "mtp.custom_sort"
      queryParams.order = "desc"
      ipGoodsList(queryParams).then((response) => {
        this.tableData = response.data.list;
        this.total = response.data.total_count;
        this.listLoading = false;
      });
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 255) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    addGoods(){
      this.$router.push("/goodsAdd")
    },
    addCommodityModule(){
      this.$router.push("/commodity_module")
    }
  }
}
</script>

<style scoped>
  .tips{
    border-radius: 6px;
    background: rgba(219, 230, 255, 1);
    padding: 10px;
  }
</style>
