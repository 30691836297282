<template>
  <div class="content" style="background: white;padding: 10px;margin: 10px">
    <el-row style="margin-top: 10px;margin-right: 20px">
      <el-col :span="18" style="text-align: left">
        <el-button @click="handleAdd()" round size="small" type="primary"><i
            class="el-icon-upload el-icon--left"></i>上传</el-button>

        <el-button-group style="margin-left: 10px">
          <el-button @click="toaddFolder" round size="small" type="plain"
            style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1)"><i
              class="el-icon-folder-add el-icon--left"></i>新建文件夹</el-button>
          <el-button @click="textMedia" round size="small" type="plain"
            style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1)"><i
              class="el-icon-document el-icon--left"></i>新建文本素材</el-button>
        </el-button-group>

        <el-button-group style="margin-left: 10px">
          <el-button @click="handleDel(multipleSelection)" :disabled="multipleSelection.length === 0" round size="small"
            type="plain" style="background: rgba(237, 245, 255, 1);color: rgba(0, 119, 255, 1)"><i
              class="el-icon-delete el-icon--left"></i>删除</el-button>
        </el-button-group>

      </el-col>
      <el-col :span="6" style="text-align: right">
        <el-input size="small" placeholder="请输入文件名" v-model="queryParams.name" style="width: 200px;margin-left: 10px">
          <el-button slot="append" icon="el-icon-search" @click="doSearch"></el-button>
        </el-input>
      </el-col>
    </el-row>

    <div style="display: flex;align-items: center;justify-content: space-between;padding: 10px">
      <div style="display: flex;align-items: center;">
        <div v-if="this.breadCrumbList.length > 0">
          <el-button class="filter-item" type="text" icon="el-icon-back" @click="previousPage">返回上级</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-button class="filter-item" type="text" @click="allPage">全部文件
            <i class="el-icon-arrow-right el-icon--right"></i>
          </el-button>
        </div>
        <el-breadcrumb separator-class="el-icon-arrow-right" style="margin-left: 10px">
          <el-breadcrumb-item v-for="(item, index) in breadCrumbList" :key="index">
            <span :class="{ 'breadcrumb_active': item.id != queryParams.superiorId }" @click="backFolder(item, index)">{{
              item.name }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-badge :is-dot="showDot">
        <div @click="uploadListVisible = true" class="btn-upload-list" style="margin-left: auto">
          <i class="el-icon-sort el-icon--left"></i>
          <el-button type="text"><span style="color: #303133">传输列表</span></el-button>
        </div>
      </el-badge>
    </div>

    <el-table :data="tableData" v-loading="listLoading" border @selection-change="handleSelectionChange"
      style="width: 100%;" :max-height="autoHeight" :max-width="autoWidth">
      <el-table-column type="selection" width="50"> </el-table-column>
      <el-table-column label="文件名">
        <template slot-scope="scope">
          <div style="display: flex;flex-direction: row;align-items: center;cursor: pointer" @click="rowClick(scope.row)">
            <div>
              <img :class="fileImgClass(scope.row)" class="file_img" :src="getImgUrl(scope.row)" />
            </div>
            <div class="file_name" :class="getFileNameClass(scope.row)">
              {{ scope.row.name != '' ? scope.row.name : scope.row.media_id }}</div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="file_size" label="大小" width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.file_type == 1">
            -
          </span>
          <span v-if="scope.row.file_type == 2">
            {{ scope.row.file_size | formatFileSize }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" type="text" size="small"
            v-if="scope.row.media_type != 'text'">重命名</el-button>
          <el-button @click="handleDel([scope.row.id])" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-row style="margin-top: 24px">
      <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination background @current-change="handleCurrentChange" :current-page="queryParams.page"
            :page-size="queryParams.limit" :total="totalCount" layout="total, prev, pager, next"></el-pagination>
        </span>
      </el-col>
    </el-row>

    <el-dialog title="新建文件夹" :visible.sync="open" width="30%">
      <el-form ref="form" :model="form" label-width="100px" style="padding-right: 20px">
        <el-form-item label="名称" prop="folder_name">
          <el-input @keyup.enter.native="submitForm" v-model="form.name" placeholder="请输入文件夹名称" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="新增文本素材" :visible.sync="textMediaShow" width="30%">
      <el-input placeholder="请输入文本素材" v-model="content" clearable>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="textMediaShow = false">取 消</el-button>
        <el-button size="small" type="primary" @click="textMediaAdd">确 定</el-button>
      </span>
    </el-dialog>


    <Fodder :superiorId="queryParams.superiorId" :mediaType="form.mediaType" :dialogVisible="showFodder"
      @close="colseDialog" @sureClose="sureCloseDialog" @resInfo="resInfo" @showDot="handleDot" @closeDot="handleDot"/>

    <UploadList :dialogVisible="uploadListVisible" @close="uploadListVisible = false"></UploadList>


    <el-dialog title="预览" :visible.sync="previewVisible" :before-close="batchViewClose" width="30%"
      style="text-align: center">
      <img v-if="previewRow.media_type == 'image'" style="width: 100%" :src="previewRow.input_url" />
      <video v-if="previewRow.media_type == 'video'" style="width: 100%" :src="previewRow.input_url"
        controls="controls"></video>
      <audio v-if="previewRow.media_type == 'audio'" style="width: 100%" :src="previewRow.input_url"
        controls="controls"></audio>
    </el-dialog>

    <el-dialog title="重命名" :visible.sync="editVisible" :before-close="editVisibleClose" width="30%" size="small">
      <el-form ref="form" :model="editRow" label-width="80px" size="small">
        <el-form-item label="名称:">
          <el-input v-model="editRow.name"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="editVisibleClose" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEdit" size="small">确 定</el-button>
      </span>

    </el-dialog>


  </div>
</template>

<script>
import { folderList, addFolder, delFolder, folderType, creatorUser, folderRename } from "../../api/material";
import { cloudInfo } from "../../api/merchantsUser";
import { mapState } from 'vuex'
import Fodder from "./fodderDialog.vue";
import UploadList from "./upload_list.vue";
import $ from 'jquery'

export default {
  name: "my_cloud_my_file",
  components: { Fodder, UploadList },
  filters: {
    formatFileSize(fileSize) {
      var temp;
      if (fileSize < 1024) {
        fileSize = fileSize.toFixed(2);
        return fileSize + 'B';
      } else if (fileSize < (1024 * 1024)) {
        temp = fileSize / 1024;
        temp = temp.toFixed(2);
        return temp + 'KB';
      } else if (fileSize < (1024 * 1024 * 1024)) {
        temp = fileSize / (1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'MB';
      } else {
        temp = fileSize / (1024 * 1024 * 1024);
        temp = temp.toFixed(2);
        return temp + 'GB';
      }
    },
    numFilter(value) {
      let realVal = parseFloat(value).toFixed(2)
      return realVal
    }
  },
  computed: {
    ...mapState(['UPLOAD_LIST']),
  },
  data() {
    return {
      uploadTimer: '', // 上传标志
      showDot: false, // 展示dot,正在上传的标志
      editVisible: false,
      editRow: {},
      previewVisible: false,
      previewRow: {},
      autoHeight: '',
      autoWidth: '',
      breadCrumbList: [],
      textMediaShow: false,
      content: '',
      uploadListVisible: false,
      queryParams: {
        name: '',
        superiorId: -1,
        page: 1,
        limit: 10,
        sidx: "create_time",
        order: "desc",
      },
      radioGroup: [],
      tableData: [],
      totalCount: 999999,
      open: false,
      showFodder: false,
      title: "",
      form: {
        media_type: "other",
        name: "",
        superior_id: -1,
        file_type: 1
      },
      old_form: {},
      multipleSelection: [],
      listLoading: false,
      cloudInfo: {
        videoCloudSize: 0,
        materialCloudSize: 0,
        percentage: 0,
        useCloudSize: 0,
        cloudSize: 0
      }
    };
  },
  created() {
    this.getHeight();
    this.getFolderType();
  },
  mounted() {
    this.initCloudInfo()
    this.queryParams.page = 1
    this.getList()
  },
  methods: {
    // 展示dot
    handleDot(value) {
      this.showDot = value
    },
    onSubmitEdit() {
      // console.log(JSON.stringify(this.editRow))
      addFolder(this.editRow).then((res) => {
        if (res.code == '0') {
          this.$message.success('修改成功')
          this.getList();
          this.editVisible = false
        } else {
          this.$message.error(res.message)
        }
      });
    },
    handleEdit(row) {
      this.editRow = JSON.parse(JSON.stringify(row))
      this.editVisible = true
    },
    editVisibleClose() {
      this.editRow = {}
      this.editVisible = false
    },
    rowClick(row) {
      if (row.file_type == 1) {
        this.nextFolder(row)
      } else {
        this.preview(row)
      }
    },
    playAll() {
      $('video').trigger('play'); //打开所有的视频播放
    },
    pauseAll() {
      $('video').trigger('pause'); //关闭所有的视频播放
      $('audio').trigger('pause'); //关闭所有的音频播放
    },
    batchViewClose() {
      this.pauseAll();
      this.previewVisible = false;
    },
    preview(row) {
      if (row.file_type == 2) { //文件
        if (row.media_type == "image" || row.media_type == "video" || row.media_type == "audio") {
          this.previewVisible = true
          this.previewRow = row
        }
      }
    },
    fileImgClass(row) {
      if (row.file_type == 2) { //文件
        if (row.media_type == "image") {
          return "file_img_point"
        }
        if (row.media_type == "video") {
          return "file_img_point"
        }
      } else {
        return ""
      }
    },
    getFileNameClass(row) {
      if (row.file_type == 1) { //文件夹
        return "fidder_name"
      } else {
        return "file_name"
      }
    },
    getImgUrl(row) {
      let url = '';
      if (row.file_type == 1) { //文件夹
        url = require('../../assets/material/fidder.png')
      } else { //文件类型
        if (row.media_type == "image") {
          url = row.input_url
        }
        if (row.media_type == "video") {
          url = row.input_url + "?x-oss-process=video/snapshot,t_0,f_png,m_fast,w_360,h_640";
        }
        if (row.media_type == "text") {
          url = require('../../assets/material/text.png');
        }
        if (row.media_type == "audio") {
          url = require('../../assets/material/mp3.png');
        }
        if (row.media_type == "other") {
          url = "";
        }
      }
      return url
    },
    getHeight() {
      this.autoHeight = (parseInt(window.innerHeight) - 250) + 'px';
      this.autoWidth = (parseInt(window.innerWidth)) + 'px';
    },
    textMedia() {
      this.content = ''
      this.textMediaShow = true
    },
    initCloudInfo() {
      cloudInfo().then((res) => {
        this.cloudInfo = res.data
      })
    },
    doSearch() {
      if (this.queryParams.name != '') {
        this.queryParams.superiorId = ''
      } else {
        this.queryParams.superiorId = -1
      }
      this.queryParams.page = 1
      this.getList();
    },
    textMediaAdd() {
      if (this.content == '') {
        this.$message.error("请输入有效的文本素材");
        return;
      }
      let body = {};
      body.media_type = 'text',
        body.superior_id = this.queryParams.superiorId,
        body.name = this.content,
        body.file_type = 2
      body.media_id = this.content
      console.log(body)
      addFolder(body).then((res) => {
        if (res.code == '0') {
          this.textMediaShow = false
          this.content = ''
          this.$message({
            message: '文本素材上传成功',
            type: 'success'
          });
          this.queryParams.page = 1
          this.getList()
        } else {
          this.$message.error(res.message)
        }
      });
    },
    allPage() {
      this.breadCrumbList = []
      this.queryParams.page = 1
      this.queryParams.superiorId = -1
      this.form.superior_id = -1
      this.getList()
    },
    previousPage() {
      let length = this.breadCrumbList.length - 1;
      let superiorId = -1;
      if (length != 0) {
        this.breadCrumbList = this.breadCrumbList.slice(0, length);
        let lastItem = this.breadCrumbList[length - 1]
        console.log("lastItem:" + JSON.stringify(lastItem))
        superiorId = lastItem.id;
      } else {
        console.log("length:" + length)
        this.breadCrumbList = []
      }
      this.queryParams.page = 1
      this.queryParams.superiorId = superiorId
      this.form.superior_id = superiorId
      this.getList()
    },
    backFolder(row, index) {
      console.log(JSON.stringify(row))
      this.breadCrumbList = this.breadCrumbList.slice(0, index + 1);
      this.queryParams.page = 1
      this.queryParams.superiorId = row.id
      this.form.superior_id = row.id
      this.getList()
    },
    nextFolder(row) {
      console.log(JSON.stringify(row))
      if (row.file_type == 1) { //文件夹
        this.breadCrumbList.push(row)
        this.queryParams.page = 1
        this.queryParams.superiorId = row.id
        this.form.superior_id = row.id
        this.getList()
      }
    },
    //获取文件夹类型
    getFolderType() {
      folderType().then((res) => {
        this.radioGroup = res.data
      });
    },
    // 添加素材
    handleAdd() {
      cloudInfo().then((res) => {
        let cloudInfo = res.data
        if (cloudInfo.useCloudSize >= cloudInfo.cloudSize) {
          this.$message.warning('储存空间不足,请联系客服')
          return false
        } else {
          this.showFodder = true;
        }
      })
    },
    colseDialog() {
      this.showFodder = false;
    },
    sureCloseDialog() {
      this.showFodder = false;
      console.log("sureCloseDialog")
      this.getList()
    },
    resInfo() {
      this.getList()
    },
    //新建文件夹
    toaddFolder() {
      this.form.name = ''
      this.open = true;
    },
    // 删除素材
    handleDel(row) {
      this.$confirm("确认删除素材?", "删除素材", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delFolder(row).then((res) => {
            if (res.code == "0") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleChange(value) {
      console.log(value);
    },
    // 提交弹框数据
    submitForm: function () {
      console.log(JSON.stringify(this.form));
      let body = this.form;
      addFolder(body).then((res) => {
        if (res.code == '0') {
          this.$message.success('添加成功')
          this.getList();
        } else {
          this.$message.error(res.message)

        }
        this.open = false;
      });
    },
    // 关闭弹窗
    cancel() {
      this.open = false;
    },
    //分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.getList();
    },
    //
    handleSelectionChange(val) {
      this.multipleSelection = val.map((item) => {
        return item.id;
      });
    },
    // 获取列表数据
    getList() {
      this.listLoading = true;
      folderList(this.queryParams).then((response) => {
        // console.log(response)
        this.tableData = response.data.list;
        this.totalCount = response.data.total_count;
        this.listLoading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-upload-list {
  border: 1px solid #f1f1f1;
  padding: 0 8px;
  border-radius: 10px;
}

.content {
  padding: 20px;
}

::v-deep.el-table {

  td,
  th {
    text-align: center;
  }
}

::v-deep.el-radio__label {
  line-height: 30px;
}

.row_name {
  color: #1482f0;
  cursor: default;
}

.row_name:hover {
  color: #0000ff;
}

.row_name_file {
  color: #8c939d;
}

.breadcrumb_active {
  color: dodgerblue;
  cursor: pointer;
}

.file_img {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.file_img_point {
  cursor: pointer;
}

.file_name {
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fidder_name {
  color: #1482f0;
  cursor: pointer;
}

.file_name {
  cursor: pointer;
}
</style>
