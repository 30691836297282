<template>
<div>
  <el-table
      :data="tableData"
      v-loading="listLoading"
      border
      style="width: 100%;"
      max-height="650"
  >
    <el-table-column align="center" prop="create_time" label="系统/有效期">
      <template slot-scope="scope">
        <div v-if="scope.row.order_type == 1">
         达人矩阵系统
        </div>
        <div v-if="scope.row.order_type == 2">
          Ai云剪辑系统
        </div>
        <div v-if="scope.row.order_type == 3">
          种草任务系统
        </div>
        <div v-if="scope.row.order_type == 4">
          电商MCN系统
        </div>
        <div v-if="scope.row.order_type == 5">
          视频作业系统
        </div>
        <div>
          有效开始: {{scope.row.start_time }}
        </div>
        <div>
          有效结束: {{scope.row.end_time }}
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="effective_days" label="时长" >
      <template slot-scope="scope">
        {{formattingEffectiveDays(scope.row.effective_days)}}
      </template>
    </el-table-column>
    <el-table-column align="center" prop="total_amount" label="价格">
    </el-table-column>
    <el-table-column align="center" prop="total_amount" label="实付金额">
    </el-table-column>
    <el-table-column align="center" prop="create_time" label="订单信息">
      <template slot-scope="scope">
        <div>订单编号:{{scope.row.out_trade_no}}</div>
        <div>下单时间:{{scope.row.create_time}}</div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="create_time" label="支付方式/流水号">
      <template slot-scope="scope">
        <div>
          <span v-if="scope.row.pay_way === 1">微信</span>
          <span v-if="scope.row.pay_way === 2">支付宝</span>
          <span v-if="scope.row.pay_way === 3">平台新增</span>
          <span v-if="scope.row.pay_way === 4">注册赠送</span>
          <span v-if="scope.row.pay_way === 5">开通达人矩阵系统赠送</span>
        </div>
        <div>{{scope.row.trade_no}}</div>
      </template>
    </el-table-column>
  </el-table>

  <el-row style="margin-top: 24px">
    <el-col :span="24" style="text-align: right">
        <span class="page-tool">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="queryParams.page"
              :page-size="queryParams.limit"
              :total="totalCount"
              layout="total, prev, pager, next"
          ></el-pagination>
        </span>
    </el-col>
  </el-row>
</div>
</template>

<script>
import { record } from "../../../api/masterMatrix";

export default {
  name: "cloud_record",
  filters: {
  },
  data(){
    return{
      queryParams:{
        page: 1,
        limit: 10,
        sidx: 'create_time',
        order: 'desc'
      },
      tableData: [],
      listLoading: false,
      totalCount:0
    }
  },
  mounted(){
    this.initData()
  },
  methods:{
    formattingEffectiveDays(effectiveDays){
      console.log("effectiveDays:" + effectiveDays)
      if(effectiveDays>=365){
        return effectiveDays/365 + "年"
      }
      if(effectiveDays>=30){
        return effectiveDays/30 + "月"
      }
      return effectiveDays + '天'
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true;
      record(this.queryParams).then((response) => {
        this.tableData = response.data.list;
        this.totalCount = response.data.total_count;
        this.listLoading = false;
      });
    }
  }
}
</script>

<style scoped>

</style>
