<template>
<div>
  <el-table
      border
      size="small"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
    <el-table-column align="center" label="IP">
      <template slot-scope="scope">
        <div style="display: flex;align-items: center;">
          <img
              style="width: 50px;height: 50px;border-radius: 50%"
              :src="scope.row.classify_icon"
              fit="cover"/>
          <div style="margin-left: 10px">{{scope.row.classify_name}}</div>
        </div>
      </template>
    </el-table-column>
    <el-table-column align="center" prop="quota" label="总名额"></el-table-column>
    <el-table-column align="center" prop="residue" label="剩余名额"></el-table-column>
    <el-table-column align="center" prop="applying" label="申请中"></el-table-column>
    <el-table-column align="center" prop="bound" label="已绑定"></el-table-column>
  </el-table>

  <el-row style="margin-top: 24px;margin-right: 20px">
    <el-col :span="24" style="text-align: right">
              <span class="page-tool">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page="queryParams.page"
                    :page-size="queryParams.limit"
                    :total="total"
                    layout="total, prev, pager, next"
                ></el-pagination>
              </span>
    </el-col>
  </el-row>


</div>
</template>

<script>
import { classifyLimit } from "../../../api/classify_limit";

export default {
  name: "LimitList",
  props: {
    agentInfo: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  data(){
    return{
      queryParams:{
        agent_id: '',
        page: 1,
        limit: 10
      },
      total:0,
      listLoading:false,
      tableData: []
    }
  },
  mounted(){
    this.queryParams.agent_id = this.agentInfo.open_id
    this.initData()
  },
  methods: {
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.queryParams.page = val;
      this.initData();
    },
    initData(){
      this.listLoading = true
      classifyLimit(this.queryParams).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total_count;
        this.listLoading = false;
      });
    },
  }
}
</script>

<style scoped>

</style>
