<template>
<div style="padding: 10px">


  <div style="padding: 10px">
    <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="handleAdd"
    >新增分类</el-button>
  </div>

  <el-table
      :data="tableData"
      :loading="listLoading"
      border
      style="width: 100%;"
      max-height="650"
  >
    <el-table-column prop="id" label="分类" >
    </el-table-column>
    <el-table-column prop="name" label="排序">
    </el-table-column>

    <el-table-column prop="dismiss_number" label="前端是否显示">
    </el-table-column>

    <el-table-column prop="remark" label="模板可见性">
    </el-table-column>

    <el-table-column prop="remark" label="模板数量">
    </el-table-column>

    <el-table-column fixed="right" label="操作">
      <template slot-scope="scope">

        <el-button type="text" size="small" @click="handleRecycle(scope.row)">修改</el-button>
        <el-button type="text" size="small" @click="handleRecycle(scope.row)">删除</el-button>
      </template>
    </el-table-column>

  </el-table>
</div>
</template>

<script>
export default {
  name: "templateClassify"
}
</script>

<style scoped>

</style>
